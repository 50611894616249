import { observer } from 'mobx-react'
import React, { useEffect } from 'react'
import {
    CostCentreSelector,
    RoleSelector,
    Selector,
} from '../../Components/Selector'
import Checkbox from '../../Components/Widgets/Checkbox'
import { FormLabel, FormRow, FormSection } from '../../forms'
import ProjectCollection from '../../State/Collections/ProjectCollection'
import StaffCollection from '../../State/Collections/StaffCollection'
import LayoutStore from '../../State/LayoutStore'
import { formatNumber2, isEmail } from '../../utils'
import { RadioButton, HoursInput } from '../../widgets'
import InviteStaffModal from './InviteStaffModal'
import {
    canEditStaff,
    canViewCostCentres,
    canViewRoles,
    usingNewLogin,
} from '../../State/Permissions/HasPermissions'
import SessionStore from '../../State/SessionStore'
import { Label } from '../../Components/ui/label'
import { RadioGroup, RadioGroupItem } from '../../Components/ui/radio-group'
import InviteStaffMemberButton from './InviteStaffMemberButton'
import DataStore from '../../State/DataStore'

export default observer(({ id }) => {
    const isEditable = canEditStaff(SessionStore.user)
    const labelWidth = '10em'
    const staff = StaffCollection.staffsById[id]
    if (!staff) return <div>Staff Member not found.</div>
    return (
        <div>
            <div className="form-horizontal">
                <FormSection>
                    <FormRow>
                        <FormLabel style={{ width: labelWidth }}>
                            First name:
                        </FormLabel>
                        <input
                            className="staff-member-component__first-name-input input-base ml-[5em]"
                            type="text"
                            value={staff.firstName || ''}
                            disabled={!isEditable}
                            onChange={(e) =>
                                staff.update({ firstName: e.target.value })
                            }
                        />
                    </FormRow>

                    <FormRow>
                        <FormLabel style={{ width: labelWidth }}>
                            Last name:
                        </FormLabel>
                        <input
                            className="staff-member-component__last-name-input input-base ml-[5em]"
                            type="text"
                            value={staff.lastName || ''}
                            disabled={!isEditable}
                            onChange={(e) =>
                                staff.update({ lastName: e.target.value })
                            }
                        />
                    </FormRow>

                    <FormRow>
                        <FormLabel style={{ width: labelWidth }}></FormLabel>
                        <Checkbox
                            className="staff-member-component__has-login-input"
                            value={staff.hasLogin}
                            label="Provide Staff Member with Login?"
                            disabled={!isEditable}
                            onChange={(v) => staff.update({ hasLogin: v })}
                            variant="secondary"
                        />
                    </FormRow>

                    <FormRow
                        style={{
                            opacity: staff.hasLogin ? 1 : 0.5,
                        }}
                    >
                        <FormLabel style={{ width: labelWidth }}>
                            Email:
                        </FormLabel>
                        <input
                            className="staff-member-component__email-input input-base ml-[7.2em]"
                            type="email"
                            value={staff.email || ''}
                            disabled={!isEditable || !staff.hasLogin}
                            onChange={(e) =>
                                staff.update({ email: e.target.value })
                            }
                        />
                        {isEditable &&
                        staff.hasLogin &&
                        staff.email &&
                        !staff.isArchived ? (
                            <>
                                {usingNewLogin() ? (
                                    <InviteStaffMemberButton staff={staff} />
                                ) : (
                                    <button
                                        className="btn btn-sm btn-default"
                                        style={{ marginLeft: '1em' }}
                                        onClick={async () => {
                                            await DataStore.startSave()
                                            LayoutStore.openModal(
                                                <InviteStaffModal
                                                    modalId={'invite-staff'}
                                                    staff={staff}
                                                />
                                            )
                                        }}
                                    >
                                        Get invite...
                                    </button>
                                )}
                            </>
                        ) : null}
                    </FormRow>

                    <FormRow>
                        <FormLabel style={{ width: labelWidth }}>
                            Staff type:
                        </FormLabel>
                        <RadioGroup
                            className="text-base flex gap-8 ml-6"
                            defaultValue="employee"
                            onValueChange={(v) =>
                                staff.update({ staffType: v })
                            }
                            value={staff.staffType}
                        >
                            <div className="flex items-center space-x-2">
                                <RadioGroupItem
                                    value="employee"
                                    id="employee"
                                />
                                <Label htmlFor="employee">Employee</Label>
                            </div>
                            <div className="flex items-center space-x-2">
                                <RadioGroupItem
                                    value="contactor"
                                    id="contactor"
                                />
                                <Label htmlFor="contactor">Contactor</Label>
                            </div>
                        </RadioGroup>
                    </FormRow>

                    {canViewRoles(SessionStore.user) ? (
                        <FormRow
                            style={{
                                maxWidth: 'max-content',
                            }}
                        >
                            <FormLabel style={{ width: labelWidth }}>
                                Staff Role:
                            </FormLabel>
                            <RoleSelector
                                selectedRole={staff.role}
                                isEditable={isEditable}
                                onChange={(role) =>
                                    staff.update({ roleId: role.id })
                                }
                                variant="secondary"
                            />
                        </FormRow>
                    ) : null}

                    <FormRow
                        style={{
                            maxWidth: 'max-content',
                        }}
                    >
                        <FormLabel style={{ width: labelWidth }}>
                            Status:
                        </FormLabel>
                        <Selector
                            options={[false, true]}
                            optionLabel={(v) => (v ? 'Archived' : 'Active')}
                            isEditable={isEditable}
                            value={staff.isArchived}
                            onChange={(v) => staff.update({ isArchived: v })}
                            variant="secondary"
                        />
                    </FormRow>

                    {canViewCostCentres(SessionStore.user) ? (
                        <FormRow
                            style={{
                                maxWidth: 'max-content',
                            }}
                        >
                            <FormLabel style={{ width: labelWidth }}>
                                Cost Centre:
                            </FormLabel>
                            <CostCentreSelector
                                selectedCostCentre={staff.costCentre}
                                isEditable={isEditable}
                                onChange={(v) =>
                                    staff.update({ costCentreId: v.id })
                                }
                                variant="secondary"
                            />
                        </FormRow>
                    ) : null}
                </FormSection>
            </div>
        </div>
    )
})
