import { endOfMonth, format, startOfMonth } from 'date-fns'
import { observer } from 'mobx-react'
import React, { useState } from 'react'
import ResourceScheduleStore from './ResourceScheduleStore'
import SidebarTitle from '../../Components/ForecastSidebar/SidebarTitle'
import SidebarNavigation from '../../Components/ForecastSidebar/SidebarNavigation'
import PhaseCollection from '../../State/Collections/PhaseCollection'
import { qf } from '../../Queries/queryFormatter'
import { toJS } from 'mobx'
import RenderOnQueries from '../Layout/RenderOnQueries'
import ResourceScheduleProjectSidebar from './ResourceScheduleProjectSidebar'
import ProjectCollection from '../../State/Collections/ProjectCollection'
import RoleCollection from '../../State/Collections/RoleCollection'
import StaffCollection from '../../State/Collections/StaffCollection'
import ResourceScheduleStaffSidebar from './ResourceScheduleStaffSidebar'
import RevenueForecastStore from '../RevenueForecastPage/RevenueForecastStore'

export default observer(() => {
    const [store, setStore] = useState(ResourceScheduleStore)
    const row = store.selectedObject
    const groups = row?.groups || []
    const period = store.selectedPeriod
    const project = row?.project
    const phase = row?.phase
    const role = row?.role
    const staff = row?.staff
    if (!period || !store.selectedProjectId) {
        return (
            <div style={{ padding: '1em' }}>
                Please select a cell containing at least a project or phase.
            </div>
        )
    }
    let RenderedProgress = <div>Something went wrong.</div>
    // if (
    //     (groups.includes('staff') || groups.includes('role')) &&
    //     !groups.includes('project') &&
    //     !groups.includes('phase')
    // ) {
    //     RenderedProgress = (
    //         <ResourceScheduleStaffSidebar
    //             store={store}
    //             month={store.selectedMonth}
    //             row={row}
    //         />
    //     )
    // } else if (store.selectedProjectId) {
    RenderedProgress = <ResourceScheduleProjectSidebar />
    // }
    return RenderedProgress
})
