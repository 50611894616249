import cuid from 'cuid'
import { addDays, addMonths, endOfMonth, startOfMonth, subDays } from 'date-fns'
import tuple from 'immutable-tuple'
import _ from 'lodash'
import { observer } from 'mobx-react'
import React, { useState } from 'react'
import Modal from '../../Components/Modal'
import { Selector } from '../../Components/Selector'
import DateValue from '../../Components/Widgets/DateValue'
import { FormLabel, FormRow, FormSection } from '../../forms'
import { router } from '../../App'

const copyProject = ({ project, name, jobNumber, startDate }) => {
    const navigate = router.navigate
    const dateDiff = project.startDate && startDate - project.startDate
    const pr2 = project.clone({ name, jobNumber }, { trackUpdates: true })
    project.rates
        .filter((r) => !r.phaseId)
        .forEach((r) => {
            const r2 = r.clone({ projectId: pr2.id }, { trackUpdates: true })
        })
    project.phases.forEach((ph) => {
        const ph2 = ph.clone(
            {
                projectId: pr2.id,
                startDate:
                    dateDiff && ph.startDate
                        ? new Date(ph.startDate.getTime() + dateDiff)
                        : ph.startDate,
                endDate:
                    dateDiff && ph.endDate
                        ? new Date(ph.endDate.getTime() + dateDiff)
                        : ph.endDate,
            },
            { trackUpdates: true }
        )
        ph.budgets.forEach((phb) => {
            const phb2 = phb.clone(
                { projectId: pr2.id, phaseId: ph2.id },
                { trackUpdates: true }
            )
        })
        ph.expenses.forEach((phe) => {
            const phe2 = phe.clone(
                {
                    projectId: pr2.id,
                    phaseId: ph2.id,
                    startDate:
                        dateDiff && phe.startDate
                            ? new Date(phe.startDate.getTime() + dateDiff)
                            : phe.startDate,
                    endDate:
                        dateDiff && phe.endDate
                            ? new Date(phe.endDate.getTime() + dateDiff)
                            : phe.endDate,
                },
                { trackUpdates: true }
            )
        })
        ph.tasks.forEach((t) => {
            const t2 = t.clone(
                {
                    projectId: pr2.id,
                    phaseId: ph2.id,
                },
                { trackUpdates: true }
            )
        })
        ph.rates.forEach((r) => {
            const r2 = r.clone(
                {
                    projectId: pr2.id,
                    phaseId: ph2.id,
                },
                { trackUpdates: true }
            )
        })
        navigate({ to: '/projects/' + pr2.id })
    })
}

export default observer(({ project, modalId }) => {
    const [name, setName] = useState('New Project')
    const [jobNumber, setJobNumber] = useState('')
    const [startDate, setStartDate] = useState(new Date())
    return (
        <Modal
            modalId={modalId}
            heading="Copy Project"
            onSave={() => copyProject({ project, name, jobNumber, startDate })}
            saveLabel="Copy Project"
        >
            <p>
                You are creating a new project using <em>{project.title}</em>
                {' as a template.'}
            </p>

            <div>
                <FormSection>
                    <FormRow className="flex items-center">
                        <FormLabel style={{ width: '6em' }}>
                            Job Code:
                        </FormLabel>
                        <input
                            type="text"
                            className="job-code-input sm-input input-base"
                            value={jobNumber || ''}
                            onChange={(e) => setJobNumber(e.target.value)}
                            style={{ width: '7em', marginRight: '3em' }}
                        />
                        <FormLabel style={{ width: '8em' }}>
                            Project Name:
                        </FormLabel>
                        <div
                            style={{
                                position: 'relative',
                                display: 'inline-block',
                                width: '17em',
                            }}
                        >
                            <input
                                type="text"
                                className="project-name-input lg-input input-base"
                                style={{ width: '16em' }}
                                value={name || ''}
                                onChange={(e) => setName(e.target.value)}
                            />
                        </div>
                    </FormRow>
                    {project.startDate ? (
                        <FormRow>
                            <FormLabel
                                style={{ width: '6em' }}
                                className="inline-block"
                            >
                                Start Date:
                            </FormLabel>
                            <DateValue
                                className="start-date-input"
                                style={{
                                    width: '16em',
                                    display: 'inline-block',
                                    position: 'fixed',
                                }}
                                value={startDate}
                                onChange={(d) => setStartDate(d)}
                            />
                        </FormRow>
                    ) : null}
                </FormSection>
            </div>
        </Modal>
    )
})
