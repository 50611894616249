import { data } from 'jquery'
import { observer } from 'mobx-react'
import React, { Suspense, useCallback, useEffect } from 'react'
import PageHeader from '../Layout/PageHeader'
import ProjectCollection from '../../State/Collections/ProjectCollection'
import LayoutStore from '../../State/LayoutStore'
import InvoiceCollection from '../../State/Collections/InvoiceCollection'
import RoleCollection from '../../State/Collections/RoleCollection'
import DeleteRoleModal from './DeleteRoleModal'
import {
    canDeleteRoles,
    canEditRoles,
} from '../../State/Permissions/HasPermissions'
import SessionStore from '../../State/SessionStore'
import HeaderButton from '../../Components/ui/HeaderButton'

export default observer(({ extraContent, id }) => {
    const role = RoleCollection.rolesById[id]
    if (!role) return null
    return (
        <PageHeader
            heading={
                <div>
                    <div>{role?.name || 'New Role'}</div>
                </div>
            }
            rightButtons={
                <div className="flex items-center gap-2">
                    {canEditRoles(SessionStore.user) ? (
                        <HeaderButton
                            label={role.isArchived ? 'Unarchive' : 'Archive'}
                            icon={<i className="fa fa-archive" />}
                            onClick={() =>
                                role.update({ isArchived: !role.isArchived })
                            }
                        />
                    ) : null}
                    {canDeleteRoles(SessionStore.user) ? (
                        <HeaderButton
                            label="Delete"
                            icon={<i className="fa fa-trash" />}
                            onClick={() =>
                                LayoutStore.openModal(
                                    <DeleteRoleModal
                                        role={role}
                                        modalId="deleteRole"
                                    />
                                )
                            }
                        />
                    ) : null}
                </div>
            }
            extraContent={extraContent}
        />
    )
})
