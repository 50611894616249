import { observer } from 'mobx-react'
import React from 'react'
import LoadingSpinner from '../../Components/LoadingSpinner'
import Table from '../../Components/Table'
import ReportCollection from '../../State/Collections/ReportCollection'
import SessionStore from '../../State/SessionStore'
import RenderOnQueries from '../Layout/RenderOnQueries'
import { qf } from '../../Queries/queryFormatter'
import { mergeQueries } from '../../Queries/mergeQueries'
import FetchStore from '../../Queries/FetchStore'
import UpdateReportModal from '../../Components/UpdateReportModal'

export default observer(({ id }) => {
    const report =
        ReportCollection.reportsById[id] ||
        SessionStore.organisation.defaultInvoiceReport
    const dateRange = report.dateRange
    const dateRangeFilters = []
    if (isFinite(dateRange[0])) {
        dateRangeFilters.push(
            `${SessionStore.settings.reportInvoiceDateType} >= ${qf(
                dateRange[0]
            )}`
        )
    }
    if (isFinite(dateRange[1])) {
        dateRangeFilters.push(
            `${SessionStore.settings.reportInvoiceDateType} <= ${qf(
                dateRange[1]
            )}`
        )
    }
    return (
        <RenderOnQueries
            key={report.queryKey}
            loading={<LoadingSpinner />}
            queryIds={[
                mergeQueries([
                    {
                        id: 'invoiceReport' + report.queryKey,
                        collection: 'invoices',
                        fields: ['ref'],
                        filters: [
                            ...report.filters
                                .map((f) =>
                                    report.columnOptionsById[
                                        f.column
                                    ]?.queryFilters?.(f)
                                )
                                .flat(),
                            ...dateRangeFilters,
                        ].filter((v) => v),
                    },
                    ...report.tableColumns
                        .map((c) => c.queries?.(report) || [])
                        .flat()
                        .filter((q) => q),
                ]),
            ]}
        >
            <UpdateReportModal report={report} />
            <InvoiceListTable report={report} />
        </RenderOnQueries>
    )
})

const InvoiceListTable = observer(({ report }) => {
    return (
        <Table
            key={report.queryKey}
            tableStore={report.tableStore}
            columns={report.tableColumns}
            rows={FetchStore.getResponse(
                'invoiceReport' + report.queryKey
            )?.invoices?.invoices.filter(
                (invoice) =>
                    !invoice.deletedAt &&
                    ((invoice.ref &&
                        invoice.ref
                            .toLowerCase()
                            .includes(report.searchFilter.toLowerCase())) ||
                        (invoice.project?.title &&
                            invoice.project?.title
                                .toLowerCase()
                                .includes(report.searchFilter.toLowerCase())))
            )}
            sortBy={[
                ['issuedOn', 'desc'],
                ['description', 'desc'],
            ]}
            showTotals={true}
            // filters={report.filters}
        />
    )
})
