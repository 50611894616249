import { date } from 'hamjest/lib/matchers/IsDate'
import _ from 'lodash'
import { observer } from 'mobx-react'
import React, { useEffect, useState } from 'react'
import Table from '../../Components/Table'
import ChangeLogCollection from '../../State/Collections/ChangeLogCollection'
import InvoiceLineItemCollection from '../../State/Collections/InvoiceLineItemCollection'
import ProjectCollection from '../../State/Collections/ProjectCollection'
import {
    canEditProjectExpenses,
    canEditProjectInvoices,
    canViewProjectExpenses,
    canViewProjectInvoices,
} from '../../State/Permissions/HasPermissions'
import SessionStore from '../../State/SessionStore'
import { PhaseSelector } from '../../Components/Selector'
import CellComponent from '../../Components/CellComponent'
import { Link } from '@tanstack/react-router'

const changeLogColumns = [
    {
        id: 'date',
        label: 'Date',
        width: 20,
        type: 'date',
        aggregate: 'none',
        editable: (r) =>
            r.modelType === 'changeLogItem' &&
            (canEditProjectInvoices(SessionStore.user, r.project) ||
                canEditProjectExpenses(SessionStore.user, r.project)),
        value: (r) => r.date,
        onChange: (r) => (v) => r.update({ date: v }),
    },
    {
        label: 'Title',
        width: 20,
        type: 'text',
        editable: (r) =>
            r.modelType === 'changeLogItem' &&
            (canEditProjectInvoices(SessionStore.user, r.project) ||
                canEditProjectExpenses(SessionStore.user, r.project)),
        value: (p) => p.title,
        onChange: (r) => (v) => r.update({ title: v }),
        component: (props) => {
            const { value, group, stores } = props
            const { row, table } = stores
            return (
                <div>
                    {row.rowObject.modelType === 'invoiceLineItem' ? (
                        <Link to={`/invoices/${row.rowObject.invoice.id}`}>
                            {value}
                        </Link>
                    ) : (
                        CellComponent.text(props)
                    )}
                </div>
            )
        },
    },
    {
        label: 'Phase',
        width: 20,
        type: 'phase',
        aggregate: 'none',
        editable: (r) =>
            r.modelType === 'changeLogItem' &&
            (canEditProjectInvoices(SessionStore.user, r.project) ||
                canEditProjectExpenses(SessionStore.user, r.project)),
        value: (p) => p.phase,
        onChange: (r) => (v) => r.update({ phaseId: v.id }),
        component: ({ value, onChange, stores, editable }) => {
            const project = stores?.row?.rowObject?.project
            return editable ? (
                <PhaseSelector
                    options={project.phases}
                    {...{ selectedPhase: value, onChange }}
                    variant="secondary"
                    className="!border-none"
                />
            ) : (
                value?.title || 'No Phase'
            )
        },
    },
    {
        label: 'Revenue',
        width: 20,
        type: 'currency',
        permissions: (r) =>
            canViewProjectInvoices(SessionStore.user, r.project),
        editable: (r) => {
            return (
                r.modelType === 'changeLogItem' &&
                canEditProjectInvoices(SessionStore.user, r.project)
            )
        },
        value: (p) => p.revenue,
        onChange: (r) => (v) => r.update({ revenue: v }),
    },
    {
        label: 'Expenses',
        width: 20,
        type: 'currency',
        permissions: (r) =>
            canViewProjectExpenses(SessionStore.user, r.project),
        editable: (r) =>
            r.modelType === 'changeLogItem' &&
            canEditProjectExpenses(SessionStore.user, r.project),
        value: (p) => p.expenses,
        onChange: (r) => (v) => r.update({ expenses: v }),
    },
    {
        label: 'Percent Progress',
        width: 10,
        type: 'number',
        aggregate: 'none',
        permissions: (r) =>
            canViewProjectExpenses(SessionStore.user, r.project),
        editable: (r) =>
            r.modelType === 'changeLogItem' &&
            canEditProjectExpenses(SessionStore.user, r.project),
        value: (p) => p.percentProgress ?? '',
        onChange: (r) => (v) => r.update({ percentProgress: v || null }),
    },
    {
        label: '',
        width: 4,
        type: 'button',
        aggregate: 'none',
        permissions: (r) =>
            canEditProjectInvoices(SessionStore.user, r.project) &&
            canEditProjectExpenses(SessionStore.user, r.project),
        editable: (r) =>
            r.modelType === 'changeLogItem' &&
            canEditProjectInvoices(SessionStore.user, r.project) &&
            canEditProjectExpenses(SessionStore.user, r.project),
        value: (r) =>
            r.modelType === 'changeLogItem' ? (
                <i className="fa fa-times" style={{ marginRight: 0 }} />
            ) : null,
        onClick: (r) => () => {
            r.update({ deletedAt: new Date() })
        },
    },
]

export default observer(({ id }) => {
    const project = ProjectCollection.projectsById[id]
    useEffect(() => {
        ;(project?.changeLog || []).forEach((cli, i) => (cli.position = i))
    }, [project])
    if (!project) return null
    const [rows, setRows] = useState(
        [
            ...project.changeLog,
            ...(canViewProjectInvoices(SessionStore.user, project)
                ? project.invoices
                      .map((inv) =>
                          inv.phasesWithRevenue.map((ph) => {
                              const lineItems =
                                  InvoiceLineItemCollection
                                      .lineItemsByInvoicePhaseId[
                                      'i' + inv.id + 'ph' + ph?.id
                                  ] || []
                              return {
                                  id: 'i' + inv.id + 'ph' + ph?.id,
                                  modelType: 'invoiceLineItem',
                                  type: inv.type,
                                  date: inv.issueDate,
                                  title: 'Invoice: ' + inv.ref,
                                  phase: ph,
                                  phaseId: ph?.id,
                                  project: project,
                                  invoice: inv,
                                  revenue: _.sum(
                                      lineItems.map((li) => li.amount)
                                  ),
                              }
                          })
                      )
                      .flat()
                : []),
        ].sort((a, b) => a?.date?.getTime() - b?.date?.getTime())
    )
    useEffect(() => {
        setRows(
            [
                ...project.changeLog,
                ...(canViewProjectInvoices(SessionStore.user, project)
                    ? project.invoices
                          .map((inv) =>
                              inv.phasesWithRevenue.map((ph) => {
                                  const lineItems =
                                      InvoiceLineItemCollection
                                          .lineItemsByInvoicePhaseId[
                                          'i' + inv.id + 'ph' + ph?.id
                                      ] || []
                                  return {
                                      id: 'i' + inv.id + 'ph' + ph?.id,
                                      modelType: 'invoiceLineItem',
                                      type: inv.type,
                                      date: inv.issueDate,
                                      title: 'Invoice: ' + inv.ref,
                                      phase: ph,
                                      project: project,
                                      invoice: inv,
                                      revenue: _.sum(
                                          lineItems.map((li) => li.amount)
                                      ),
                                  }
                              })
                          )
                          .flat()
                    : []),
            ].sort((a, b) => a?.date?.getTime() - b?.date?.getTime())
        )
    }, [project.id])
    return (
        <div>
            <Table
                columns={changeLogColumns}
                rows={rows.filter((r) => !r.deletedAt)}
                showTotals={true}
            />
            {canEditProjectInvoices(SessionStore.user, project) &&
            canEditProjectExpenses(SessionStore.user, project) ? (
                <button
                    className="btn btn-default plus-btn border-[#ccc]"
                    style={{ marginTop: '2em' }}
                    onClick={() => {
                        const cli = ChangeLogCollection.add(
                            {
                                projectId: project.id,
                                phaseId: project.rootPhaseId,
                                date: new Date(),
                            },
                            { trackUpdates: true }
                        )
                        cli.position = project.changeLog.length
                        setRows([...rows, cli])
                    }}
                >
                    + Add Item
                </button>
            ) : null}
        </div>
    )
})
