import _ from 'lodash'
import { observer } from 'mobx-react'
import React, { useState } from 'react'
import Modal from '../../Components/Modal'
import DataStore from '../../State/DataStore'
import { useNavigate } from '@tanstack/react-router'

export default observer(({ staff, modalId }) => {
    const navigate = useNavigate()
    return (
        <Modal
            modalId={modalId}
            heading="Delete Staff Member"
            saveButtons={[
                {
                    label: 'Archive Staff',
                    onClick: () => {
                        staff.update({ isArchived: true })
                        DataStore.saveModel(staff)
                        navigate({ to: '/staff/' })
                    },
                },
                {
                    label: 'Delete Staff',
                    onClick: () => {
                        staff.update({ deletedAt: new Date() })
                        DataStore.saveModel(staff)
                        navigate({ to: '/staff/' })
                    },
                },
            ]}
        >
            <p style={{ margin: '1em' }}>
                Are you sure you wish to delete <em>{staff.fullName}</em>?
            </p>
            <p style={{ margin: '1em' }}>
                We recommend you "Archive" staff to retain historical timesheet
                information. You will not be charged for any archived staff.
            </p>
        </Modal>
    )
})
