import { observer } from 'mobx-react'
import React, { useEffect, useState } from 'react'
import Table from '../../Components/Table'
import SupplierCollection from '../../State/Collections/SupplierCollection'
import { canEditSuppliers } from '../../State/Permissions/HasPermissions'
import SessionStore from '../../State/SessionStore'

const columns = [
    {
        id: 'name',
        label: 'Name',
        type: 'text',
        width: 25,
        editable: (row) => canEditSuppliers(SessionStore.user),
        value: (row) => {
            return row.name
        },
        onChange: (row) => (val) => row.update({ name: val }),
    },
    {
        label: '',
        width: 4,
        type: 'button',
        permissions: (r) => canEditSuppliers(SessionStore.user),
        editable: (r) => canEditSuppliers(SessionStore.user),
        value: (p) => <i className="fa fa-times" style={{ marginRight: 0 }} />,
        onClick: (r) => () => {
            r.update({ deletedAt: new Date() })
        },
    },
]

export default observer(() => {
    const [suppliers, setSuppliers] = useState([
        ...(SupplierCollection?.activeSuppliers?.sort((a, b) =>
            a.name?.localeCompare?.(b.name)
        ) || []),
    ])
    useEffect(() => {
        setSuppliers([
            ...(SupplierCollection?.activeSuppliers?.sort((a, b) =>
                a.name?.localeCompare?.(b.name)
            ) || []),
        ])
    }, [SupplierCollection?.activeSuppliers?.length])
    return (
        <>
            <Table columns={columns} rows={suppliers} newRowDirection={'asc'} />
        </>
    )
})
