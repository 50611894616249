import { observable, computed, action, makeObservable } from 'mobx'
import Collection from './Collection'
import OverheadExpenseModel from '../Models/OverheadExpenseModel'

class OverheadExpenseCollection extends Collection {
    constructor() {
        super({
            collection: 'overheadExpenses',
            modelClass: OverheadExpenseModel,
        })
        this.addLookup('overheadExpensesByCostCentreId', 'manyByKey', {
            key: (t) => t.costCentreId,
            filter: (t) => !t.deletedAt,
        })
        this.addLookup('activeOverheads', 'list', {
            filter: (t) => !t.deletedAt,
        })
        makeObservable(this)
    }
    get overheadExpensesById() {
        return this.modelsById
    }
    get overheadExpenses() {
        return this.models
    }
    @action.bound
    addOverheadExpense(data) {
        this.add(data)
    }
    @action.bound
    addOverheadExpenses(data) {
        this.addMany(data)
    }
}
export default new OverheadExpenseCollection()
export const OverheadExpenseCollectionClass = OverheadExpenseCollection
