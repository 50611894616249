import { observer } from 'mobx-react'
import React, { useEffect, useState } from 'react'
import SessionStore from '../../State/SessionStore'
import * as Sentry from '@sentry/react'
import { useNavigate } from '@tanstack/react-router'
import { canViewRoute } from '../../State/Permissions/HasPermissions'

export default observer(({ email, redirect }) => {
    const navigate = useNavigate()
    const [impersonateState, setImpersonateState] = useState('idle')
    useEffect(() => {
        SessionStore.impersonate(email)
            .then(() => {
                setImpersonateState('success')
                navigate({
                    to:
                        redirect ||
                        (canViewRoute(SessionStore.user, 'projectList')
                            ? '/projects'
                            : '/timesheets'),
                    replace: true,
                })
            })
            .catch(function (error) {
                Sentry.captureException(error)
                setImpersonateState('error')
            })
    }, [])
    return (
        <div
            className="flex flex-align-items-center flex-justify-content-center"
            style={{
                alignSelf: 'center',
                textAlign: 'center',
            }}
        >
            {impersonateState !== 'error'
                ? `Impersonating ${email}...`
                : 'Impersonate Failed'}
        </div>
    )
})
