import { observable, computed, action, makeObservable } from 'mobx'
import DailyAllocationCollection from '../Collections/DailyAllocationCollection'
import PhaseCollection from '../Collections/PhaseCollection'
import ProjectCollection from '../Collections/ProjectCollection'
import TaskCollection from '../Collections/TaskCollection'
import TimeEntryCollection from '../Collections/TimeEntryCollection'
import Model from './Model'

class TaskModel extends Model {
    @observable name = null
    @observable isDefault = null
    @observable isBillable = null
    @observable isVariation = null
    @observable projectId = null
    @observable phaseId = null

    constructor(data, options) {
        super()
        makeObservable(this)
        this.collection = TaskCollection
        this.init(data, options)
    }

    @computed
    get dailyAllocations() {
        return DailyAllocationCollection.dailyAllocationsByTaskId[this.id]
    }

    @computed
    get timeEntries() {
        return TimeEntryCollection.timeEntriesByTaskId[this.id]
    }

    @computed
    get phase() {
        return PhaseCollection.phasesById[this.phaseId]
    }

    @computed
    get project() {
        return ProjectCollection.projectsById[this.projectId]
    }
}

export default TaskModel
