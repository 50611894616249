import ContactCollection, {
    ContactCollectionClass,
} from './Collections/ContactCollection'
import CostCentreCollection, {
    CostCentreCollectionClass,
} from './Collections/CostCentreCollection'
import SupplierCollection, {
    SupplierCollectionClass,
} from './Collections/SupplierCollection'
import DailyAllocationCollection, {
    DailyAllocationCollectionClass,
} from './Collections/DailyAllocationCollection'
import AllocationCollection, {
    AllocationCollectionClass,
} from './Collections/AllocationCollection'
import MonthlyAllocationCollection, {
    MonthlyAllocationCollectionClass,
} from './Collections/MonthlyAllocationCollection'
import WeeklyAllocationCollection, {
    WeeklyAllocationCollectionClass,
} from './Collections/WeeklyAllocationCollection'
import PhaseCollection, {
    PhaseCollectionClass,
} from './Collections/PhaseCollection'
import ProjectCollection, {
    ProjectCollectionClass,
} from './Collections/ProjectCollection'
import InvoiceCollection, {
    InvoiceCollectionClass,
} from './Collections/InvoiceCollection'
import InvoiceDescriptionTemplateCollection, {
    InvoiceDescriptionTemplateCollectionClass,
} from './Collections/InvoiceDescriptionTemplateCollection'
import OrganisationCollection, {
    OrganisationCollectionClass,
} from './Collections/OrganisationCollection'
import OrganisationHolidayCollection, {
    OrganisationHolidayCollectionClass,
} from './Collections/OrganisationHolidayCollection'
import OrganisationSubscriptionCollection, {
    OrganisationSubscriptionCollectionClass,
} from './Collections/OrganisationSubscriptionCollection'
import ReportCollection, {
    ReportCollectionClass,
} from './Collections/ReportCollection'
import OverheadExpenseCollection, {
    OverheadExpenseCollectionClass,
} from './Collections/OverheadExpenseCollection'
import ProjectExpenseAllocationCollection, {
    ProjectExpenseAllocationCollectionClass,
} from './Collections/ProjectExpenseAllocationCollection'
import ProjectExpenseItemCollection, {
    ProjectExpenseItemCollectionClass,
} from './Collections/ProjectExpenseItemCollection'
import ProjectRateCollection, {
    ProjectRateCollectionClass,
} from './Collections/ProjectRateCollection'
import ResourceScheduleReportCollection, {
    ResourceScheduleReportCollectionClass,
} from './Collections/ResourceScheduleReportCollection'
import RevenueForecastReportCollection, {
    RevenueForecastReportCollectionClass,
} from './Collections/RevenueForecastReportCollection'
import RevenueTargetCollection, {
    RevenueTargetCollectionClass,
} from './Collections/RevenueTargetCollection'
import RoleCollection, {
    RoleCollectionClass,
} from './Collections/RoleCollection'
import RoleRateCollection, {
    RoleRateCollectionClass,
} from './Collections/RoleRateCollection'
import StaffCollection, {
    StaffCollectionClass,
} from './Collections/StaffCollection'
import StaffRateCollection, {
    StaffRateCollectionClass,
} from './Collections/StaffRateCollection'
import TaskCollection, {
    TaskCollectionClass,
} from './Collections/TaskCollection'
import PermissionCollection, {
    PermissionCollectionClass,
} from './Collections/PermissionCollection'
import TimeEntryCollection, {
    TimeEntryCollectionClass,
} from './Collections/TimeEntryCollection'
import ProjectExpenseCollection, {
    ProjectExpenseCollectionClass,
} from './Collections/ProjectExpenseCollection'
import BudgetCollection, {
    BudgetCollectionClass,
} from './Collections/BudgetCollection'
import ProjectNoteCollection, {
    ProjectNoteCollectionClass,
} from './Collections/ProjectNoteCollection'
import ChangeLogCollection, {
    ChangeLogCollectionClass,
} from './Collections/ChangeLogCollection'
import InvoiceLineItemCollection, {
    InvoiceLineItemCollectionClass,
} from './Collections/InvoiceLineItemCollection'
import InvoiceLineItemAggregateCollection, {
    InvoiceLineItemAggregateCollectionClass,
} from './Aggregates/InvoiceLineItemAggregateCollection'
import ProjectExpenseAggregateCollection, {
    ProjectExpenseAggregateCollectionClass,
} from './Aggregates/ProjectExpenseAggregateCollection'
import TimeEntryAggregateCollection, {
    TimeEntryAggregateCollectionClass,
} from './Aggregates/TimeEntryAggregateCollection'
import DailyAllocationAggregateCollection, {
    DailyAllocationAggregateCollectionClass,
} from './Aggregates/DailyAllocationAggregateCollection'
import AllocationAggregateCollection, {
    AllocationAggregateCollectionClass,
} from './Aggregates/AllocationAggregateCollection'
import MonthlyAllocationAggregateCollection, {
    MonthlyAllocationAggregateCollectionClass,
} from './Aggregates/MonthlyAllocationAggregateCollection'
import RevenueTargetAggregateCollection, {
    RevenueTargetAggregateCollectionClass,
} from './Aggregates/RevenueTargetAggregateCollection'
import PhaseAggregateCollection, {
    PhaseAggregateCollectionClass,
} from './Aggregates/PhaseAggregateCollection'
import BudgetAggregateCollection, {
    BudgetAggregateCollectionClass,
} from './Aggregates/BudgetAggregateCollection'
import MonthlyResourceCellCollection, {
    MonthlyResourceCellCollectionClass,
} from './Collections/MonthlyResourceCellCollection'
import ResourceRowCollection, {
    ResourceRowCollectionClass,
} from './Collections/ResourceRowCollection'
import MonthlyRevenueCellCollection, {
    MonthlyRevenueCellCollectionClass,
} from './Collections/MonthlyRevenueCellCollection'
import RevenueRowCollection, {
    RevenueRowCollectionClass,
} from './Collections/RevenueRowCollection'
import MonthlyExpenseCellCollection, {
    MonthlyExpenseCellCollectionClass,
} from './Collections/MonthlyExpenseCellCollection'
import ExpenseRowCollection, {
    ExpenseRowCollectionClass,
} from './Collections/ExpenseRowCollection'

export const collections = [
    BudgetCollection,
    ChangeLogCollection,
    ContactCollection,
    CostCentreCollection,
    SupplierCollection,
    DailyAllocationCollection,
    AllocationCollection,
    MonthlyAllocationCollection,
    WeeklyAllocationCollection,
    InvoiceCollection,
    InvoiceLineItemCollection,
    InvoiceDescriptionTemplateCollection,
    OrganisationCollection,
    OrganisationHolidayCollection,
    OrganisationSubscriptionCollection,
    ReportCollection,
    OverheadExpenseCollection,
    PhaseCollection,
    ProjectCollection,
    ProjectExpenseCollection,
    ProjectExpenseAllocationCollection,
    ProjectExpenseItemCollection,
    ProjectNoteCollection,
    ProjectRateCollection,
    ResourceScheduleReportCollection,
    RevenueForecastReportCollection,
    RevenueTargetCollection,
    RoleCollection,
    RoleRateCollection,
    StaffCollection,
    StaffRateCollection,
    TaskCollection,
    PermissionCollection,
    TimeEntryCollection,
    MonthlyResourceCellCollection,
    ResourceRowCollection,
    MonthlyRevenueCellCollection,
    RevenueRowCollection,
    MonthlyExpenseCellCollection,
    ExpenseRowCollection,
]

export const aggregateCollections = [
    InvoiceLineItemAggregateCollection,
    ProjectExpenseAggregateCollection,
    TimeEntryAggregateCollection,
    DailyAllocationAggregateCollection,
    AllocationAggregateCollection,
    MonthlyAllocationAggregateCollection,
    RevenueTargetAggregateCollection,
    PhaseAggregateCollection,
    BudgetAggregateCollection,
]

export const CollectionLookup = Object.fromEntries([
    ...collections.map((c) => [c.collection, c]),
    ['staffMembers', StaffCollection],
    ['staffs', StaffCollection],
])
export const AggregateCollectionLookup = Object.fromEntries(
    aggregateCollections.map((c) => [c.collection, c])
)

export const autoSaveCollections = [
    BudgetCollection,
    ChangeLogCollection,
    ContactCollection,
    CostCentreCollection,
    SupplierCollection,
    DailyAllocationCollection,
    AllocationCollection,
    MonthlyAllocationCollection,
    WeeklyAllocationCollection,
    InvoiceCollection,
    InvoiceLineItemCollection,
    InvoiceDescriptionTemplateCollection,
    OrganisationCollection,
    OrganisationHolidayCollection,
    // OrganisationSubscriptionCollection,
    // ReportCollection,
    OverheadExpenseCollection,
    PhaseCollection,
    ProjectCollection,
    ProjectExpenseCollection,
    ProjectExpenseAllocationCollection,
    ProjectExpenseItemCollection,
    ProjectNoteCollection,
    ProjectRateCollection,
    // ResourceScheduleReportCollection,
    // RevenueForecastReportCollection,
    RevenueTargetCollection,
    RoleCollection,
    RoleRateCollection,
    RevenueTargetCollection,
    StaffCollection,
    StaffRateCollection,
    TaskCollection,
    PermissionCollection,
    TimeEntryCollection,
    MonthlyResourceCellCollection,
    ResourceRowCollection,
    MonthlyRevenueCellCollection,
    RevenueRowCollection,
    MonthlyExpenseCellCollection,
    ExpenseRowCollection,
]

export const collectionClasses = [
    BudgetCollectionClass,
    ChangeLogCollectionClass,
    ContactCollectionClass,
    CostCentreCollectionClass,
    SupplierCollectionClass,
    DailyAllocationCollectionClass,
    AllocationCollectionClass,
    MonthlyAllocationCollectionClass,
    WeeklyAllocationCollectionClass,
    InvoiceCollectionClass,
    InvoiceLineItemCollectionClass,
    InvoiceDescriptionTemplateCollectionClass,
    OrganisationCollectionClass,
    OrganisationHolidayCollectionClass,
    OrganisationSubscriptionCollectionClass,
    ReportCollectionClass,
    OverheadExpenseCollectionClass,
    PhaseCollectionClass,
    ProjectCollectionClass,
    ProjectExpenseCollectionClass,
    ProjectExpenseAllocationCollectionClass,
    ProjectExpenseItemCollectionClass,
    ProjectNoteCollectionClass,
    ProjectRateCollectionClass,
    ResourceScheduleReportCollectionClass,
    RevenueForecastReportCollectionClass,
    RevenueTargetCollectionClass,
    RoleCollectionClass,
    RoleRateCollectionClass,
    StaffCollectionClass,
    StaffRateCollectionClass,
    TaskCollectionClass,
    PermissionCollectionClass,
    TimeEntryCollectionClass,
    MonthlyResourceCellCollectionClass,
    ResourceRowCollectionClass,
    MonthlyRevenueCellCollectionClass,
    RevenueRowCollectionClass,
    MonthlyExpenseCellCollectionClass,
    ExpenseRowCollectionClass,
]

export const aggregateCollectionClasses = [
    InvoiceLineItemAggregateCollectionClass,
    ProjectExpenseAggregateCollectionClass,
    TimeEntryAggregateCollectionClass,
    DailyAllocationAggregateCollectionClass,
    AllocationAggregateCollectionClass,
    MonthlyAllocationCollectionClass,
    RevenueTargetAggregateCollectionClass,
    PhaseAggregateCollectionClass,
    BudgetAggregateCollectionClass,
]

export const CollectionClassLookup = Object.fromEntries(
    collectionClasses.map((c) => [new c().collection, c])
)
export const AggregateCollectionClassLookup = Object.fromEntries(
    aggregateCollectionClasses.map((c) => [new c().collection, c])
)
