import { observable, computed, action, makeObservable } from 'mobx'
import Collection from '../Collections/Collection'
import TimeEntryAggregateModel from './TimeEntryAggregateModel'
import Aggregate from './Aggregate'
import bind from 'bind-decorator'
import tuple from 'immutable-tuple'

class TimeEntryAggregate extends Aggregate {
    constructor() {
        super({ collectionClass: TimeEntryAggregateCollection })
        makeObservable(this)
    }
}

class TimeEntryAggregateCollection extends Collection {
    constructor() {
        super({
            collection: 'timeEntries',
            modelClass: TimeEntryAggregateModel,
        })
        this.addLookup('timeEntriesByProjectId', 'manyByKey', {
            key: (t) => t.projectId,
            filter: (t) => !t.deletedAt,
        })
        this.addLookup('timeEntriesByPhaseId', 'manyByKey', {
            key: (t) => t.phaseId,
            filter: (t) => !t.deletedAt,
        })
        this.addLookup('timeEntriesByStaffId', 'manyByKey', {
            key: (t) => t.staffId,
            filter: (t) => !t.deletedAt,
        })
        this.addLookup('timeEntriesByRoleId', 'manyByKey', {
            key: (t) => t.roleId || t.staff?.roleId,
            filter: (t) => !t.deletedAt,
        })
        this.addLookup('timeEntriesByMonth', 'manyByKey', {
            key: (t) => t.month,
            filter: (t) => !t.deletedAt,
        })
        this.addLookup('timeEntriesByModelTuple', 'manyByMany', {
            keys: (t) => [
                tuple(t.project?.id, undefined, undefined, undefined),
                tuple(undefined, t.phase?.id, undefined, undefined),
                tuple(undefined, undefined, t.role?.id, undefined),
                tuple(undefined, undefined, undefined, t.staff?.id),

                tuple(t.project?.id, t.phase?.id, undefined, undefined),
                tuple(t.project?.id, undefined, t.role?.id, undefined),
                tuple(t.project?.id, undefined, undefined, t.staff?.id),
                tuple(undefined, t.phase?.id, t.role?.id, undefined),
                tuple(undefined, t.phase?.id, undefined, t.staff?.id),
                tuple(undefined, undefined, t.role?.id, t.staff?.id),

                tuple(t.project?.id, t.phase?.id, t.role?.id, undefined),
                tuple(t.project?.id, t.phase?.id, undefined, t.staff?.id),
                tuple(t.project?.id, undefined, t.role?.id, t.staff?.id),
                tuple(undefined, t.phase?.id, t.role?.id, t.staff?.id),

                tuple(t.project?.id, t.phase?.id, t.role?.id, t.staff?.id),
            ],
            filter: (t) => !t.deletedAt,
        })
        makeObservable(this)
    }
    get timeEntriesById() {
        return this.modelsById
    }
    get timeEntries() {
        return this.models
    }
    get timeEntriesById() {
        return this.modelsById
    }
    @action.bound
    addTimeEntry(data) {
        this.add(data)
    }
    @action.bound
    addTimeEntries(data) {
        this.addMany(data)
    }
}

export default new TimeEntryAggregate()
export const TimeEntryAggregateCollectionClass = TimeEntryAggregateCollection
