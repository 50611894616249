import React, { useEffect, useState } from 'react'
import { makeRequest } from '../../Queries/makeRequest'
import Pusher from 'pusher-js'
// Pusher.logToConsole = true

const MigratePage = ({ id }) => {
    var pusher = new Pusher(process.env.REACT_APP_PUSHER_KEY, {
        cluster: 'ap1',
    })
    const [migrationState, setMigrationState] = useState([])
    const [isMigrating, setIsMigrating] = useState(false)
    const [isCompleted, setIsCompleted] = useState(false)

    useEffect(() => {
        var channel = pusher.subscribe(id)
        channel.bind('progress', function (data) {
            setMigrationState(data)
        })
        channel.bind('error', function (data) {
            setMigrationState(data)
            setIsCompleted(true)
        })
        channel.bind('completed', function (data) {
            setMigrationState(data)
            setIsCompleted(true)
        })
    }, [])

    return (
        <div
            className="flex flex-align-items-center flex-justify-content-center"
            style={{
                textAlign: 'center',
                alignSelf: 'center',
            }}
        >
            <style>
                {`.loading::after {
                    display: inline-block;
                    animation: dotty steps(1,end) 1s infinite;
                    content: '   ';
                }
                
                @keyframes dotty {
                    0%   { content: '   '; }
                    25%  { content: '.  '; }
                    50%  { content: '.. '; }
                    75%  { content: '...'; }
                    100% { content: '   '; }
                }`}
            </style>
            <section
                className={'login-widget login-widget--login-mode'}
                style={{ width: '50em' }}
            >
                <header className="text-align-center">
                    <div
                        style={{ width: '10em', height: '10em' }}
                        className="logo2 s75"
                    >
                        <img
                            src={
                                new URL(
                                    '/public/coincraft_logo_130.png',
                                    import.meta.url
                                )
                            }
                            alt="Coincraft Logo"
                        />
                    </div>
                    <h3>Migrate Data</h3>
                    <div>This may take serveral minutes...</div>
                </header>
                {isMigrating ? (
                    <h4
                        style={{
                            display: 'flex',
                            alignContent: 'center',
                            justifyContent: 'center',
                            textAlign: 'center',
                            paddingTop: '3em',
                        }}
                    >
                        <span style={{ paddingLeft: '1em' }}>
                            {migrationState}
                        </span>
                        <span
                            className={!isCompleted ? 'loading' : ''}
                            style={{
                                width: '1em',
                                alignSelf: 'left',
                                justifySelf: 'flex-start',
                                textAlign: 'left',
                            }}
                        ></span>
                    </h4>
                ) : (
                    <button
                        onClick={() => {
                            makeRequest({
                                path: `/migrate/${id}`,
                                method: 'post',
                            })
                            setIsMigrating(true)
                        }}
                    >
                        Start Migration
                    </button>
                )}
            </section>
        </div>
    )
}

export default MigratePage
