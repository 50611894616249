import { observable, computed, action, makeObservable } from 'mobx'
import Collection from './Collection'
import ProjectExpenseAllocationModel from '../Models/ProjectExpenseAllocationModel'

class ProjectExpenseAllocationCollection extends Collection {
    constructor() {
        super({
            collection: 'projectExpenseAllocations',
            modelClass: ProjectExpenseAllocationModel,
        })
        this.addLookup('expenseAllocationsByExpenseId', 'manyByKey', {
            key: (t) => t.expenseId,
            filter: (t) => !t.deletedAt,
        })
        this.addLookup('expenseAllocationsByProjectId', 'manyByKey', {
            key: (t) => t.projectId,
            filter: (t) => !t.deletedAt,
        })
        this.addLookup('expenseAllocationsByPhaseId', 'manyByKey', {
            key: (t) => t.phaseId,
            filter: (t) => !t.deletedAt,
        })
        makeObservable(this)
    }
    get projectExpenseAllocationsById() {
        return this.modelsById
    }
    get projectExpenseAllocations() {
        return this.models
    }
    @action.bound
    addProjectExpenseAllocation(data) {
        this.add(data)
    }
    @action.bound
    addProjectExpenseAllocations(data) {
        this.addMany(data)
    }
}

export default new ProjectExpenseAllocationCollection()
export const ProjectExpenseAllocationCollectionClass =
    ProjectExpenseAllocationCollection
