import { observer } from 'mobx-react'
import React, { Suspense, useCallback, useEffect } from 'react'
import SupplierCollection from '../../State/Collections/SupplierCollection'

import PageHeader from '../Layout/PageHeader'
import { canEditSuppliers } from '../../State/Permissions/HasPermissions'
import SessionStore from '../../State/SessionStore'

export default observer(({ extraContent }) => {
    return (
        <PageHeader
            heading={
                <div>
                    <div>{'Suppliers'}</div>
                </div>
            }
            extraContent={
                canEditSuppliers(SessionStore.user) ? (
                    <button
                        className="btn btn-default add-timesheet-entry-button plus-btn mt-6"
                        onClick={() => {
                            const ph = SupplierCollection.add(
                                {},
                                { trackUpdates: true }
                            )
                            ph.isNew = true
                        }}
                    >
                        + Add Supplier
                    </button>
                ) : null
            }
        />
    )
})
