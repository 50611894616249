import _ from 'lodash'
import { makeObservable, observable, computed, action, autorun } from 'mobx'
import TimeEntryCollection from '../../State/Collections/TimeEntryCollection'
import {
    addDays,
    addWeeks,
    format,
    getWeek,
    isSameDay,
    parse,
    startOfDay,
    startOfWeek,
    subWeeks,
} from 'date-fns'
import SessionStore from '../../State/SessionStore'
import tuple from 'immutable-tuple'
import StaffCollection from '../../State/Collections/StaffCollection'
import Textarea from 'react-textarea-autosize'
import { canEditStaffTime } from '../../State/Permissions/HasPermissions'
import { router } from '../../App'

class TimesheetsDailyStore {
    @observable extraTime = []
    @observable searchParams = {}
    constructor() {
        makeObservable(this)
    }
    @action.bound
    reset() {
        this.extraTime = []
    }
    @action.bound
    setSearchParams(params) {
        this.searchParams = params
    }
    @computed get staffId() {
        return canEditStaffTime(SessionStore.user)
            ? this.searchParams?.staffId || SessionStore.userId
            : SessionStore.userId
    }
    @computed get date() {
        return this.searchParams?.date
            ? parse(this.searchParams?.date, 'yyyy-MM-dd', new Date())
            : startOfDay(new Date())
    }
    @action.bound
    addExtraTime(timeEntries) {
        this.extraTime.push(...timeEntries)
    }
    @computed
    get timeEntries() {
        return [
            ...new Set([
                ...TimeEntryCollection.timeEntries.filter(
                    (t) =>
                        t.staffId === this.staffId &&
                        isSameDay(t.date, this.date) &&
                        !t.deletedAt
                ),
                ...this.extraTime.filter(
                    (t) =>
                        t.staffId === this.staffId &&
                        isSameDay(t.date, this.date) &&
                        !t.deletedAt
                ),
            ]),
        ]
    }
    @action.bound
    clearBlankTimeEntries() {
        this.timeEntries
            .filter((te) => !te.numMinutes && !te.notes)
            .forEach((te) => te.update({ deletedAt: new Date() }))
    }
    @computed get costCentres() {
        return [
            ...new Set(
                this.timeEntries.map(
                    (t) =>
                        t.project?.costCentre || {
                            id: t.costCentreId,
                            name: 'Missing Cost Centre',
                        }
                )
            ),
        ]
    }
    @computed get projects() {
        return [...new Set(this.timeEntries.map((t) => t.project))]
    }
    @computed get projectsByCostCentreId() {
        return _.groupBy(
            [...new Set(this.timeEntries.map((t) => t.project))],
            (pr) => pr?.costCentreId
        )
    }
    @computed get phasesByProjectId() {
        return _.mapValues(
            _.groupBy(this.timeEntries, (ph) => ph?.projectId),
            (tes) => [
                ...new Set(tes.map((te) => te.phase || te.project?.rootPhase)),
            ]
        )
    }
    @computed get rowsByPhaseId() {
        return _.groupBy(
            this.timeEntries.filter((te) => {
                return (
                    te.numMinutes ||
                    (te.notes !== '' && te.notes !== null) ||
                    new Date() - (te.createdAt || te.updatedAt) <
                        1000 * 60 * 60 * 12 ||
                    (!this.timeEntries.find(
                        (t) =>
                            t.id !== te.id &&
                            t.date.getTime() === te.date.getTime() &&
                            t.taskId === te.taskId &&
                            t.isBillable === te.isBillable &&
                            t.isVariation === te.isVariation &&
                            t.isOvertime === te.isOvertime &&
                            t.isLocked === te.isLocked &&
                            (t.numMinutes ||
                                (t.notes !== '' && t.notes !== null))
                    ) &&
                        this.timeEntries.find(
                            (t) =>
                                t.date.getTime() === te.date.getTime() &&
                                t.taskId === te.taskId &&
                                t.isBillable === te.isBillable &&
                                t.isVariation === te.isVariation &&
                                t.isOvertime === te.isOvertime &&
                                t.isLocked === te.isLocked
                        ).id === te.id)
                )
            }),
            (te) =>
                tuple(
                    te.projectId,
                    te.phase ? te.phaseId : te.project?.rootPhase?.id
                )
        )
    }
    @computed
    get staff() {
        return StaffCollection.staffsById[this.staffId] || SessionStore.user
    }
    @computed
    get startOfWeek() {
        return startOfWeek(this.date, { weekStartsOn: 1 })
    }
    @computed
    get daysOfWeek() {
        return [...Array(7)].map((v, i) => addDays(this.startOfWeek, i))
    }
    @computed
    get dailyTotals() {
        const totals = this.daysOfWeek.map((day) =>
            _.sum(
                (
                    TimeEntryCollection.timeEntriesByStaffIdDate[
                        tuple(this.staffId, day.getTime())
                    ] || []
                ).map((te) => te.numMinutes)
            )
        )
        return totals
    }
    @computed
    get currentDayTotal() {
        return _.sum(
            (
                TimeEntryCollection.timeEntriesByStaffIdDate[
                    tuple(this.staffId, this.date.getTime())
                ] || []
            ).map((te) => te.numMinutes)
        )
    }
    @action.bound
    shiftPrevWeek() {
        router.navigate({
            search: (prev) => ({
                ...prev,
                date: format(subWeeks(this.date, 1), 'yyyy-MM-dd'),
            }),
        })
    }
    @action.bound
    shiftNextWeek() {
        router.navigate({
            search: (prev) => ({
                ...prev,
                date: format(addWeeks(this.date, 1), 'yyyy-MM-dd'),
            }),
        })
    }
    @action.bound
    shiftThisWeek() {
        router.navigate({
            search: (prev) =>
                _.omitBy(
                    {
                        ...prev,
                        date: null,
                    },
                    (v) => v === null
                ),
        })
    }
}

export default new TimesheetsDailyStore()
