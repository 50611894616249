import { action, makeObservable, observable } from 'mobx'

class ContactStore {
    @observable searchFilter = ''
    constructor() {
        makeObservable(this)
    }
    @action.bound
    updateSearchFilter(val) {
        this.searchFilter = val
    }
}

export default new ContactStore()
