import React from 'react'
import { observer } from 'mobx-react'
import {
    addMonths,
    differenceInBusinessDays,
    endOfMonth,
    format,
    startOfMonth,
} from 'date-fns'
import UtilisationProgressSlider from './UtilisationProgressSlider'
import {
    getAvailableHoursInDateRange,
    getHoursInMonth,
} from '../../Utils/forecastHelpers'
import StaffCollection from '../../State/Collections/StaffCollection'
import RoleCollection from '../../State/Collections/RoleCollection'
import ResourceRowCollection from '../../State/Collections/ResourceRowCollection'
import RevenueForecastStore from '../../Pages/RevenueForecastPage/RevenueForecastStore'
import ProjectCollection from '../../State/Collections/ProjectCollection'
import PhaseCollection from '../../State/Collections/PhaseCollection'
import RevenueRowCollection from '../../State/Collections/RevenueRowCollection'
import fetchData from '../../Queries/fetchData'
import { qf } from '../../Queries/queryFormatter'

export default observer(
    ({
        staffRow,
        row,
        month,
        title,
        showOtherHours = true,
        editable,
        updateRevenue,
        hoursData = 'actualsProjected',
        label,
        onChange,
        staff,
        availability,
        totalHours,
        selectedHours,
        key,
    }) => {
        const monthId = format(month, 'yyyy-MM')
        staff ??= StaffCollection.staffById[(staffRow || row)?.staffId]
        const role = staff?.role || RoleCollection.rolesById[staffRow?.roleId]
        availability ??= (staffRow || row)?.getAvailabilityInMonth(monthId)
        const timeInMonth =
            totalHours ??
            (showOtherHours ? staffRow : row)?.getHoursInMonth(monthId)
        const maxHours = Math.max(availability, timeInMonth)
        selectedHours ??= row?.getHoursInMonth(monthId)
        const otherHours = timeInMonth - selectedHours
        onChange ??= (v) => {
            const prevHours = row.getHoursInMonth(monthId)
            const diff = v - prevHours
            row.setHoursInMonth(monthId, v)
            if (staffRow && diff !== 0) {
                staffRow.projectedHours[monthId] =
                    staffRow.getHoursInMonth(monthId) + diff
            }
        }
        return (
            <div
                key={
                    key ??
                    monthId +
                        ((staffRow || row)?.roleId || '') +
                        ((staffRow || row)?.staffId || '')
                }
                style={{ marginTop: '-0.25em' }}
            >
                <div>{label || row?.label || staffRow?.label}</div>
                <UtilisationProgressSlider
                    editable={editable}
                    total={availability}
                    maxAmount={maxHours}
                    prevAmount={otherHours}
                    amount={selectedHours}
                    onChange={(v) => onChange(v)}
                />
            </div>
        )
    }
)
