import tuple from 'immutable-tuple'
import { isDateOnOrBetweenRange, isDateRangeOverlapping } from './dateHelpers'
import { addDays, subDays } from 'date-fns'
import _ from 'lodash'
import getCombinedRateInDateRange from './getCombinedRateInDateRange'
import AllocationCollection from '../State/Collections/AllocationCollection'

export function editAllocationsInDateRange(
    allocations,
    dateRange,
    value,
    valueType
) {
    //get unique project, phase, staff, role
    const uniqIds = new Set()
    const matchingAllocations = []
    allocations.forEach((al) => {
        uniqIds.add(tuple(al.projectId, al.phaseId, al.role?.id, al.staffId))
        if (
            al.modelType !== 'allocation' ||
            !isDateOnOrBetweenRange(al.date, dateRange)
        )
            return
        matchingAllocations.push(al)
    })

    const totalAmount = _.sum(
        matchingAllocations.map((al) => {
            return al[valueType]
        })
    )
    if (matchingAllocations.length && totalAmount) {
        const amountRatio = value / totalAmount
        matchingAllocations.forEach((al) =>
            al.update({ hours: al.hours * amountRatio })
        )
    } else {
        const splitValue = value / uniqIds.size
        ;[...uniqIds].forEach((ids) => {
            const al = AllocationCollection.add(
                {
                    projectId: ids[0],
                    phaseId: ids[1],
                    roleId: ids[2],
                    staffId: ids[3],
                    numMinutes: splitValue * 60,
                    date: dateRange[1],
                },
                { trackUpdates: true }
            )
            al.update({
                numMinutes:
                    (splitValue /
                        (valueType !== 'hours' ? al[valueType + 'Rate'] : 1)) *
                    60,
            })
        })
    }
}
