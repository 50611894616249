import stringify from 'fast-json-stable-stringify'
import _ from 'lodash'

export const getPermissionKey = (permissions) => {
    return stringify(normalizePermissions(permissions))
}

const normalizePermissions = (permissions = {}) => {
    permissions = _.cloneDeep(permissions)
    if (!permissions) return {}
    delete permissions.id
    delete permissions.uuid
    const revHidden = permissions.canViewRevenueForecast === 'hidden'
    const staffHidden = permissions.staffPermissions === 'hidden'
    permissions.canViewOperationalExpenses ??= false
    permissions.canViewResourceSchedule ??= false
    permissions.canViewRevenueForecast =
        revHidden || !permissions.canViewRevenueForecast
            ? false
            : permissions.canViewRevenueForecast
    permissions.canViewStaffExpenses =
        revHidden || !permissions.canViewStaffExpenses
            ? false
            : permissions.canViewStaffExpenses
    permissions.canViewWeeklyPlanner ??= false
    permissions.chargeOutRate =
        staffHidden || !permissions.chargeOutRate
            ? 'hidden'
            : permissions.chargeOutRate
    permissions.contacts = ['view', 'edit'].includes(permissions.contacts)
        ? permissions.contacts
        : 'view'
    permissions.costCentres = ['view', 'edit'].includes(permissions.costCentres)
        ? permissions.costCentres
        : 'view'
    permissions.costRate =
        staffHidden || !permissions.costRate ? 'hidden' : permissions.costRate
    permissions.editStaffTime =
        staffHidden || !permissions.editStaffTime
            ? false
            : permissions.editStaffTime
    permissions.holidays ??= 'hidden'
    permissions.invoiceSettings ??= 'hidden'
    permissions.operationalExpenses ??= 'hidden'
    permissions.organisationSettings ??= 'hidden'
    permissions.overtimeRate =
        staffHidden || !permissions.overtimeRate
            ? 'hidden'
            : permissions.overtimeRate
    permissions.payRate =
        staffHidden || !permissions.payRate ? 'hidden' : permissions.payRate
    permissions.rolePermissions ??= 'hidden'
    permissions.staffPermissions ??= 'hidden'
    permissions.projects = permissions.projects.map((pp) => {
        delete pp.id
        delete pp.uuid
        const p = pp.permissions
        if (!p) return pp
        const pHidden = p.projectPermissions === 'hidden'
        p.projectDates = pHidden || !p.projectDates ? 'view' : p.projectDates
        p.projectExpenseBudgets =
            pHidden || !p.projectExpenseBudgets
                ? 'hidden'
                : p.projectExpenseBudgets
        p.projectExpenses =
            pHidden || !p.projectExpenses ? 'hidden' : p.projectExpenses
        p.projectFees = pHidden || !p.projectFees ? 'hidden' : p.projectFees
        p.projectForecastedHours =
            pHidden || !p.projectForecastedHours
                ? 'view'
                : p.projectForecastedHours
        p.projectForecastedRevenue =
            pHidden || !p.projectForecastedRevenue
                ? 'hidden'
                : p.projectForecastedRevenue
        p.projectHoursBudgets =
            pHidden || !p.projectHoursBudgets ? 'view' : p.projectHoursBudgets
        p.projectInvoices =
            pHidden || !p.projectInvoices ? 'hidden' : p.projectInvoices
        p.projectNotes = pHidden || !p.projectNotes ? 'hidden' : p.projectNotes
        p.projectPermissions =
            pHidden || !p.projectPermissions ? 'hidden' : p.projectPermissions
        p.projectPhasesTasks =
            pHidden || !p.projectPhasesTasks ? 'view' : p.projectPhasesTasks
        p.projectRates = pHidden || !p.projectRates ? 'hidden' : p.projectRates
        p.projectStaffBudgets =
            pHidden || !p.projectStaffBudgets ? 'view' : p.projectStaffBudgets
        p.projectTimesheets =
            pHidden || !p.projectTimesheets ? true : p.projectTimesheets
        return pp
    })
    return permissions
}
