import React from 'react'
import _ from 'lodash'
import cuid from 'cuid'
import Shape from '@doodle3d/clipper-js'
import { niceScale } from '../../Utils/niceScale'
import { FormatNumber } from '../../Utils/Localisation/NumberFormatter'

export default ({
    revenue,
    expenses,
    prospectiveRevenue,
    prospectiveExpenses,
    graphWidth,
    totalWidth,
    height,
    padding = 12,
    sizeRatio = 1,
    formatter = (n) => FormatNumber(n, { decimals: 2, compact: true }),
    ...props
}) => {
    revenue = revenue.map((v) => v || 0)
    expenses = expenses.map((v) => v || 0)
    graphWidth = graphWidth * sizeRatio || 0
    totalWidth = totalWidth * sizeRatio || 0
    height = height * sizeRatio || 0
    padding = padding * sizeRatio || 0
    const widthStep = graphWidth / (revenue.length - 1) || 0
    const graphStart = totalWidth - graphWidth || 0
    const maxVal = _.max([
        ...revenue,
        ...expenses,
        // add revenue to expenses if revenue is negative
        ...expenses.map((v, i) => v - revenue[i]),
    ])
    const scale = niceScale([0, maxVal || 1000], 5)
    const maxScale = _.max(scale) || 1000
    const revenuePoints = revenue.map((v, i) => ({
        X: graphStart + widthStep * i || 0,
        Y: height - (Math.max(v, 0) / maxScale) * height + padding || 0,
    }))
    const expensePoints = expenses.map((v, i) => {
        // add revenue to expenses if revenue is negative
        v -= revenue[i] < 0 ? revenue[i] : 0
        return {
            X: graphStart + widthStep * i || 0,
            Y: height - (Math.max(v, 0) / maxScale) * height + padding || 0,
        }
    })
    const prospectiveRevenuePoints =
        prospectiveRevenue &&
        revenue.map((v, i) => ({
            X: graphStart + widthStep * i || 0,
            Y:
                height -
                    (Math.max(v - prospectiveRevenue[i], 0) / maxScale) *
                        height +
                    padding || 0,
        }))
    const prospectiveExpensePoints =
        prospectiveExpenses &&
        expenses.map((v, i) => {
            // add revenue to expenses if revenue is negative
            v -= revenue[i] < 0 ? revenue[i] : 0
            return {
                X: graphStart + widthStep * i || 0,
                Y:
                    height -
                        (Math.max(v - prospectiveExpenses[i], 0) / maxScale) *
                            height +
                        padding || 0,
            }
        })
    const revenueFillPoints = [
        { X: graphStart || 0, Y: height + padding || 0 },
        ...revenuePoints,
        { X: totalWidth || 0, Y: height + padding || 0 },
    ]
    const expenseFillPoints = [
        { X: graphStart || 0, Y: height + padding || 0 },
        ...expensePoints,
        { X: totalWidth || 0, Y: height + padding || 0 },
    ]
    const prospectiveExpenseFillPoints = prospectiveExpenses && [
        { X: graphStart || 0, Y: height + padding || 0 },
        ...prospectiveExpensePoints,
        { X: totalWidth || 0, Y: height + padding || 0 },
    ]
    const prospectiveRevenueFillPoints = prospectiveRevenue && [
        { X: graphStart || 0, Y: height + padding || 0 },
        ...prospectiveRevenuePoints,
        { X: totalWidth || 0, Y: height + padding || 0 },
    ]
    const revenueShape = new Shape([revenueFillPoints], true)
    const expenseShape = new Shape([expenseFillPoints], true)
    const prospectiveRevenueShape =
        prospectiveRevenue && new Shape([prospectiveRevenueFillPoints], true)
    const prospectiveExpenseShape =
        prospectiveExpenses && new Shape([prospectiveExpenseFillPoints], true)
    const grey = revenueShape.intersect(expenseShape)
    const gold = revenueShape.difference(expenseShape)
    const red = expenseShape.difference(revenueShape)
    const whiteGrey =
        prospectiveExpenses && expenseShape.difference(prospectiveExpenseShape)
    const whiteGold =
        prospectiveRevenue && revenueShape.difference(prospectiveRevenueShape)
    return (
        <div style={{ textAlign: 'right' }}>
            <svg
                // ref={target}
                style={{
                    width: totalWidth + padding * 2,
                    height: height + padding * 2,
                    display: 'inline-block',
                    ...props.style,
                }}
                key="cashflowchart"
            >
                {scale
                    // .filter((s) => s)
                    .map((s, i) => {
                        return (
                            <React.Fragment key={'y' + i}>
                                {s ? (
                                    <text
                                        x={graphStart - padding * 9}
                                        y={
                                            height -
                                            (s / maxScale) * height +
                                            4 +
                                            padding
                                        }
                                        textAnchor="end"
                                        style={{
                                            fill: '#444',
                                            opacity: 0.8,
                                            fontSize: `${sizeRatio}em`,
                                        }}
                                    >
                                        {formatter(s)}
                                    </text>
                                ) : null}
                                <line
                                    x1={graphStart - padding * 8}
                                    x2={totalWidth + padding * 2}
                                    y1={
                                        height -
                                        (s / maxScale) * height +
                                        padding
                                    }
                                    y2={
                                        height -
                                        (s / maxScale) * height +
                                        padding
                                    }
                                    style={{
                                        stroke: '#444',
                                        strokeWidth: 0.5,
                                        opacity: 0.6,
                                    }}
                                />
                            </React.Fragment>
                        )
                    })}
                {grey.paths.map((path, i) => {
                    return (
                        <polygon
                            key={'grey-path' + i}
                            points={path
                                .map((coord) => `${coord.X},${coord.Y}`)
                                .join(' ')}
                            style={{ fill: '#444', opacity: 0.8 }}
                        />
                    )
                })}
                {gold.paths.map((path, i) => {
                    return (
                        <polygon
                            key={'gold-path' + i}
                            points={path
                                .map((coord) => `${coord.X},${coord.Y}`)
                                .join(' ')}
                            style={{ fill: '#ffca01', opacity: 0.8 }}
                        />
                    )
                })}
                {red.paths.map((path, i) => {
                    return (
                        <polygon
                            key={'red-path' + i}
                            points={path
                                .map((coord) => `${coord.X},${coord.Y}`)
                                .join(' ')}
                            style={{ fill: '#f15a29', opacity: 0.8 }}
                        />
                    )
                })}
                {whiteGold
                    ? whiteGold.paths.map((path, i) => {
                          return (
                              <polygon
                                  key={'white-gold-path' + i}
                                  points={path
                                      .map((coord) => `${coord.X},${coord.Y}`)
                                      .join(' ')}
                                  style={{ fill: 'white', opacity: 0.25 }}
                              />
                          )
                      })
                    : null}
                {whiteGrey
                    ? whiteGrey.paths.map((path, i) => {
                          return (
                              <polygon
                                  key={'white-grey-path' + i}
                                  points={path
                                      .map((coord) => `${coord.X},${coord.Y}`)
                                      .join(' ')}
                                  style={{ fill: 'white', opacity: 0.25 }}
                              />
                          )
                      })
                    : null}
                {/* <polygon
                points={prospectiveExpenseFillPoints
                    .map((coord) => `${coord.X},${coord.Y}`)
                    .join(' ')}
                style={{ fill: 'white', opacity: 0.25 }}
            /> */}
                {prospectiveExpensePoints ? (
                    <polyline
                        key="prospective-expense"
                        points={prospectiveExpensePoints
                            .map((coord) => `${coord.X},${coord.Y}`)
                            .join(' ')}
                        style={{
                            fill: 'none',
                            stroke: '#444',
                            strokeWidth: 1 * sizeRatio,
                            opacity: 0.5,
                        }}
                    />
                ) : null}
                {prospectiveRevenuePoints ? (
                    <polyline
                        key="prospective-revenue"
                        points={prospectiveRevenuePoints
                            .map((coord) => `${coord.X},${coord.Y}`)
                            .join(' ')}
                        style={{
                            fill: 'none',
                            stroke: '#ffca01',
                            strokeWidth: 1 * sizeRatio,
                            opacity: 0.5,
                        }}
                    />
                ) : null}
                <polyline
                    key="expense"
                    points={expensePoints
                        .map((coord) => `${coord.X},${coord.Y}`)
                        .join(' ')}
                    style={{
                        fill: 'none',
                        stroke: '#444',
                        strokeWidth: 2.5 * sizeRatio,
                        opacity: 1,
                    }}
                />
                <polyline
                    key="revenue"
                    points={revenuePoints
                        .map((coord) => `${coord.X},${coord.Y}`)
                        .join(' ')}
                    style={{
                        fill: 'none',
                        stroke: '#ffca01',
                        strokeWidth: 2.5 * sizeRatio,
                        opacity: 1,
                    }}
                />
                {prospectiveExpensePoints
                    ? prospectiveExpensePoints.map((coord, i) => {
                          return (
                              <circle
                                  key={'exp-circle' + i}
                                  cx={coord.X}
                                  cy={coord.Y}
                                  r={4 * sizeRatio}
                                  style={{
                                      fill:
                                          coord.Y <
                                          prospectiveExpensePoints[i].Y
                                              ? '#f15a29'
                                              : '#444',
                                      opacity: 1,
                                  }}
                              />
                          )
                      })
                    : null}
                {prospectiveRevenuePoints
                    ? prospectiveRevenuePoints.map((coord, i) => {
                          return (
                              <circle
                                  key={'rev-circle' + i}
                                  cx={coord.X}
                                  cy={coord.Y}
                                  r={3 * sizeRatio}
                                  style={{
                                      fill: '#ffca01',
                                      opacity: 1,
                                  }}
                              />
                          )
                      })
                    : null}
                {expensePoints.map((coord, i) => {
                    return (
                        <circle
                            key={'exp-p' + i}
                            cx={coord.X}
                            cy={coord.Y}
                            r={7 * sizeRatio}
                            style={{
                                fill:
                                    coord.Y < revenuePoints[i].Y
                                        ? '#f15a29'
                                        : '#444',
                                stroke: 'white',
                                strokeWidth: 2.5 * sizeRatio,
                                opacity: 1,
                            }}
                        />
                    )
                })}
                {revenuePoints.map((coord, i) => {
                    return (
                        <circle
                            key={'rev-p' + i}
                            cx={coord.X}
                            cy={coord.Y}
                            r={7 * sizeRatio}
                            style={{
                                fill: '#ffca01',
                                stroke: 'white',
                                strokeWidth: 2.5 * sizeRatio,
                                opacity: 1,
                            }}
                        />
                    )
                })}
            </svg>
        </div>
    )
}
