import _ from 'lodash'
import React from 'react'
import { endOfMonth, format, startOfMonth } from 'date-fns'
import { capitalCase } from 'change-case'
import { RevenueForecastReportPhaseColumns } from './RevenueForecastReportPhaseColumns'
import { RevenueForecastReportStaffColumns } from './RevenueForecastReportStaffColumns'
import { RevenueForecastReportOverheadColumns } from './RevenueForecastReportOverheadColumns'
import {
    canViewRevenueForecastOperationalExpenses,
    canViewRevenueForecastStaffExpenses,
} from '../../State/Permissions/HasPermissions'
import SessionStore from '../../State/SessionStore'
import { FormatCurrency } from '../../Utils/Localisation/CurrencyFormatter'

const cellStyles = (row, stores, monthStart, isPastMonth) => {
    return {
        ...(isPastMonth
            ? {
                  backgroundImage: `url(${new URL(
                      '~/public/diag.png',
                      import.meta.url
                  )})`,
              }
            : {}),
    }
}

export const RevenueForecastReportProfitColumns = (store) => ({
    title: (monthStartDate) => ({
        id: 'title',
        label: 'Title',
        type: 'text',
        width: 18,
        editable: (row) => false,
        value: (row, stores) => {
            return capitalCase(row)
        },
        component: ({ value, group, stores }) => {
            const { row, table } = stores
            if (row.group === 'totals') return 'Total'
            return (
                <div style={{ paddingLeft: `${1 * row.groupLevel}rem` }}>
                    {value}
                </div>
            )
        },
    }),
    profit: (monthStartDate) => {
        const monthStart = startOfMonth(monthStartDate)
        const monthEnd = endOfMonth(monthStart)
        const monthId = format(monthStart, 'yyyy-MM')
        const isPastMonth = monthEnd < startOfMonth(new Date())
        const revenueCell = RevenueForecastReportPhaseColumns(store).revenue(
            monthStartDate,
            ['status']
        )
        const staffCostCell =
            RevenueForecastReportStaffColumns(store).cost(monthStartDate)
        const overheadCostCell = RevenueForecastReportOverheadColumns(
            store
        ).cost(monthStartDate, store)
        return {
            label: format(monthStartDate, 'MMM yy'),
            type: 'currency',
            width: 8,
            value: (row) => {
                const revenue = store.getRevenueInMonth('', monthId)
                const staffCost = canViewRevenueForecastStaffExpenses(
                    SessionStore.user
                )
                    ? _.sum(
                          store.getStaffRows().map((ph) => {
                              return staffCostCell.value(ph)
                          })
                      )
                    : 0
                const overheadCost = canViewRevenueForecastOperationalExpenses(
                    SessionStore.user
                )
                    ? _.sum(
                          [...new Set(store.getOverheadRows())].map((ph) => {
                              return overheadCostCell.value(ph)
                          })
                      )
                    : 0
                return revenue - staffCost - overheadCost
            },
            data: (row) => {
                return [
                    ...(revenueCell.data?.(row) || []),
                    ...(staffCostCell.data?.(row) || []),
                    ...(overheadCostCell.data?.(row) || []),
                ]
            },
            editable: () => false,
            style: (row, stores) => {
                return cellStyles(row, stores, monthStart, isPastMonth)
            },
            format: (v) => FormatCurrency(v, { decimals: 0 }),
            aggregate: 'sum',
        }
    },
    profitMargin: (monthStartDate) => {
        const monthStart = startOfMonth(monthStartDate)
        const monthEnd = endOfMonth(monthStart)
        const monthId = format(monthStart, 'yyyy-MM')
        const isPastMonth = monthEnd < startOfMonth(new Date())
        const revenueCell = RevenueForecastReportPhaseColumns(store).revenue(
            monthStartDate,
            ['status']
        )
        const staffCostCell =
            RevenueForecastReportStaffColumns(store).cost(monthStartDate)
        const overheadCostCell =
            RevenueForecastReportOverheadColumns(store).cost(monthStartDate)
        return {
            label: format(monthStartDate, 'MMM yy'),
            type: 'percent',
            width: 8,
            value: (row) => {
                const revenue = store.getRevenueInMonth('', monthId)
                const staffCost = canViewRevenueForecastStaffExpenses(
                    SessionStore.user
                )
                    ? _.sum(
                          store.getStaffRows().map((ph) => {
                              return staffCostCell.value(ph)
                          })
                      )
                    : 0
                const overheadCost = canViewRevenueForecastOperationalExpenses(
                    SessionStore.user
                )
                    ? _.sum(
                          [...new Set(store.getOverheadRows())].map((ph) => {
                              return overheadCostCell.value(ph)
                          })
                      )
                    : 0
                return (revenue - staffCost - overheadCost) / revenue
            },
            data: (row) => {
                return [
                    ...(revenueCell.data?.(row) || []),
                    ...(staffCostCell.data?.(row) || []),
                    ...(overheadCostCell.data?.(row) || []),
                ]
            },
            editable: () => false,
            style: (row, stores) => {
                return cellStyles(row, stores, monthStart, isPastMonth)
            },
            aggregate: 'sum',
        }
    },
    markup: (monthStartDate) => {
        const monthStart = startOfMonth(monthStartDate)
        const monthEnd = endOfMonth(monthStart)
        const monthId = format(monthStart, 'yyyy-MM')
        const isPastMonth = monthEnd < startOfMonth(new Date())
        const revenueCell = RevenueForecastReportPhaseColumns(store).revenue(
            monthStartDate,
            ['status']
        )
        const staffCostCell =
            RevenueForecastReportStaffColumns(store).cost(monthStartDate)
        const overheadCostCell =
            RevenueForecastReportOverheadColumns(store).cost(monthStartDate)
        return {
            label: format(monthStartDate, 'MMM yy'),
            type: 'percent',
            width: 8,
            value: (row) => {
                const revenue = store.getRevenueInMonth('', monthId)
                const staffCost = canViewRevenueForecastStaffExpenses(
                    SessionStore.user
                )
                    ? _.sum(
                          store.getStaffRows().map((ph) => {
                              return staffCostCell.value(ph)
                          })
                      )
                    : 0
                const overheadCost = canViewRevenueForecastOperationalExpenses(
                    SessionStore.user
                )
                    ? _.sum(
                          [...new Set(store.getOverheadRows())].map((ph) => {
                              return overheadCostCell.value(ph)
                          })
                      )
                    : 0
                return revenue / (staffCost + overheadCost) - 1
            },
            data: (row) => {
                return [
                    ...(revenueCell.data?.(row) || []),
                    ...(staffCostCell.data?.(row) || []),
                    ...(overheadCostCell.data?.(row) || []),
                ]
            },
            editable: () => false,
            style: (row, stores) => {
                return cellStyles(row, stores, monthStart, isPastMonth)
            },
            aggregate: 'sum',
        }
    },
    total: (monthStartDate, store) => {
        return {
            id: 'total',
            label: (
                <span>
                    Total
                    <span
                        style={{ fontSize: '1.5em', float: 'right' }}
                        onClick={(e) => e.stopPropagation()}
                    >
                        <i
                            className={`fa fa-caret-left`}
                            style={{ cursor: 'pointer' }}
                            onClick={() => store.shiftDates(-1)}
                        />
                        <i
                            className={`fa fa-caret-right`}
                            style={{ marginRight: 0, cursor: 'pointer' }}
                            onClick={() => store.shiftDates(1)}
                        />
                    </span>
                </span>
            ),
            type: 'text',
            width: 18,
            editable: (row) => false,
            value: (row) => {
                return ''
            },
        }
    },
})
