import { addDays, endOfMonth, startOfMonth, subDays } from 'date-fns'
import _ from 'lodash'
import AllocationCollection from '../State/Collections/AllocationCollection'
import ProjectExpenseAllocationCollection from '../State/Collections/ProjectExpenseAllocationCollection'
import RevenueTargetCollection from '../State/Collections/RevenueTargetCollection'
import { logRound } from './niceScale'

const getPhaseRevenueAmounts = {
    total: (phase) => phase.fee,
    remaining: (phase) =>
        phase.fee -
        _.sum(
            phase.invoiceLineItems
                .filter((li) => li.billingType === 'agreedFee')
                .map((ili) => ili.amount)
        ) -
        _.sum(phase.changeLog.map((cli) => cli.revenue)),
}

const getBudgetResourceAmounts = {
    total: (budget) => budget.hours,
    remaining: (budget) =>
        budget.hours -
        _.sum(
            budget.phase
                .getAggregateTimeEntries([
                    'projectId',
                    'phaseId',
                    'staffId',
                    'isBillable',
                    'isVariation',
                    'beenInvoiced',
                    'month',
                ])
                .filter(
                    (te) =>
                        !te.isVariation &&
                        (!budget.staffId || te.staff === budget.staff) &&
                        (!budget.roleId || te.role === budget.role)
                )
                .map((te) => te.numMinutes / 60)
        ),
}

const getExpenseAmounts = {
    total: (expense) => expense.cost,
    remaining: (expense) =>
        expense.cost - _.sum(expense.invoiceLineItems.map((ili) => ili.amount)),
}

const getPhaseDate = {
    startDate: (phase) => phase.startDate,
    endDate: (phase) => phase.endDate,
    now: (phase) => _.max([new Date(), phase.startDate]),
}

const getFutureRevenueTargets = (phase, fromDate = new Date()) => {
    const monthStart = startOfMonth(fromDate)
    return phase.revenueTargets.filter((rt) => rt.date >= monthStart)
}

const getFutureAllocations = (phase, budget, fromDate = new Date()) => {
    const monthStart = startOfMonth(fromDate)
    let allocations = phase.allocations.filter(
        (al) =>
            al.date >= monthStart &&
            (!budget.staffId || al.staff === budget.staff) &&
            (!budget.roleId || al.role === budget.role)
    )
    if (allocations[0]?.startDate < monthStart) {
        allocations[0] = splitAllocationAtDate(allocations[0], monthStart)
    }
    return allocations
}

const getFutureExpenseAllocations = (expense, fromDate = new Date()) => {
    const monthStart = startOfMonth(fromDate)
    return expense.allocations.filter((al) => (rt) => rt.date >= monthStart)
}

const splitAllocationAtDate = (allocation, date) => {
    const alStart1 = allocation.start
    const alEnd1 = subDays(date, 1)
    const alStart2 = date
    const alEnd2 = allocation.end
    const hoursPerDuration =
        allocation.hours / _.max([allocation.endDate - allocation.startDate, 1])
    const alHours1 = logRound(hoursPerDuration * (alEnd1 - alStart1))
    const alHours2 = allocation.hours - alHours1
    allocation.update({
        endDate: alEnd1,
        hours: alHours1,
    })
    const al2 = allocation.clone(
        {
            startDate: alStart2,
            endDate: alEnd2,
            hours: alHours2,
        },
        { trackUpdates: true }
    )
    return al2
}

export const autoAdjustPhaseRevenueTargets = (
    phases,
    remainingOrTotal,
    updateFrom,
    updateTo
) => {
    phases.forEach((ph) => {
        let amount = getPhaseRevenueAmounts[remainingOrTotal](ph)
        const fromDate = getPhaseDate[updateFrom](ph)
        const toDate = getPhaseDate[updateTo](ph)
        const amountPerDuration = amount / _.max([toDate - fromDate, 1])
        let revenueTargets = getFutureRevenueTargets(
            ph,
            updateFrom === 'startDate' ? new Date(0) : new Date()
        )
        let date = fromDate
        while (date < toDate) {
            const prevDate = date
            date = endOfMonth(addDays(date, 1))
            let rtAmount = logRound(
                amountPerDuration * _.max([date - prevDate, 1])
            )
            if (date > toDate) {
                date = toDate
                rtAmount = amount
            }
            const rt =
                revenueTargets.shift() ||
                RevenueTargetCollection.add(
                    {
                        projectId: ph.project.id,
                        phaseId: ph.id,
                    },
                    { trackUpdates: true }
                )
            rt.update({
                date: date,
                amount: rtAmount,
                deletedAt: null,
            })
            amount -= rtAmount
        }
        revenueTargets.forEach((rt) =>
            rt.update({ deletedAt: new Date(), amount: 0 })
        )
    })
}

export const autoAdjustPhaseExpenseAllocations = (
    expenses,
    remainingOrTotal,
    updateFrom,
    updateTo
) => {
    expenses.forEach((e) => {
        let amount = getExpenseAmounts[remainingOrTotal](e)
        const fromDate = getPhaseDate[updateFrom](e)
        const toDate = getPhaseDate[updateTo](e)
        const amountPerDuration = amount / _.max([toDate - fromDate, 1])
        let expenseAllocations = getFutureExpenseAllocations(
            e,
            updateFrom === 'startDate' ? new Date(0) : new Date()
        )
        let date
        while (!date || date < toDate) {
            date ??= fromDate
            const prevDate = date
            date = endOfMonth(addDays(date, 1))
            let eaAmount = logRound(
                amountPerDuration * _.max([date - prevDate, 1])
            )
            if (date > toDate) {
                date = toDate
                eaAmount = amount
            }
            const ea =
                expenseAllocations.shift() ||
                ProjectExpenseAllocationCollection.add(
                    {
                        projectId: e.projectId,
                        phaseId: e.phaseId,
                        expenseId: e.id,
                    },
                    { trackUpdates: true }
                )
            ea.update({
                date: date,
                amount: eaAmount,
                deletedAt: null,
            })
            amount -= eaAmount
        }
        expenseAllocations.forEach((rt) =>
            rt.update({ deletedAt: new Date(), amount: 0 })
        )
    })
}

export const autoAdjustPhaseBudgetAllocations = (
    budgets,
    remainingOrTotal,
    updateFrom,
    updateTo
) => {
    budgets.forEach((e) => {
        budgets.forEach((phb) => {
            let amount = getBudgetResourceAmounts[remainingOrTotal](phb)
            const fromDate = getPhaseDate[updateFrom](phb.phase)
            const toDate = getPhaseDate[updateTo](phb.phase)
            const amountPerDuration = amount / _.max([toDate - fromDate, 1])
            let allocations = getFutureAllocations(
                phb.phase,
                phb,
                updateFrom === 'startDate' ? new Date(0) : new Date()
            )
            let date
            while (!date || date < toDate) {
                date ??= fromDate
                const prevDate = date
                date = endOfMonth(addDays(date, 1))
                let alAmount = logRound(
                    amountPerDuration * _.max([date - prevDate, 1])
                )
                if (date > toDate) {
                    date = toDate
                    alAmount = amount
                }
                const al =
                    allocations.shift() ||
                    AllocationCollection.add(
                        {
                            projectId: phb.project.id,
                            phaseId: phb.phase.id,
                            staffId: phb.staffId,
                            roleId: phb.roleId,
                        },
                        { trackUpdates: true }
                    )
                al.update({
                    date: date,
                    numMinutes: alAmount * 60,
                    deletedAt: null,
                })
                amount -= alAmount
            }
            allocations.forEach((al) =>
                al.update({ deletedAt: new Date(), hours: 0 })
            )
        })
    })
}
