export default {
    name: 'New Report',
    type: 'expense',
    columns: [
        'project',
        'projectPhase',
        'expenseName',
        'staffMember',
        'date',
        'recordedExpense',
        'invoicedExpense',
        'plannedExpense',
        'notes',
    ],
    filters: [
        {
            column: 'project',
            operator: 'in',
            value: [],
        },
    ],
    groupBy: ['project', 'projectPhase', 'expenseName'],
    sortBy: [['label', 'asc']],
    options: {
        dateRange: 'thisMonth',
    },
}
