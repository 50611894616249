import { createFileRoute } from '@tanstack/react-router'
import SettingsPageHeader from '../../Pages/SettingsPage/SettingsPageHeader'
import SettingsPage from '../../Pages/SettingsPage/SettingsPage'
import PageBody from '../../Pages/Layout/PageBody'
import { canViewRoute } from '../../State/Permissions/HasPermissions'
import SessionStore from '../../State/SessionStore'
import PermissionDenied from '../../Pages/PermissionDenied'
import LoadingSpinner from '../../Components/LoadingSpinner'
import ErrorComponent from '../../Components/ErrorComponent'

export const Route = createFileRoute('/_private/settings')({
    beforeLoad: ({ params }) => {
        if (!canViewRoute(SessionStore.user, 'settings', params))
            throw new Error('Permission Denied')
    },
    errorComponent: ({ error, reset }) => {
        if (error.message === 'Permission Denied') return <PermissionDenied />
        return <ErrorComponent error={error} reset={reset} />
    },
    component: Wrapper,
    pendingComponent: LoadingSpinner,
})

function Wrapper() {
    return (
        <>
            <SettingsPageHeader label="Settings" />
            <PageBody>
                <SettingsPage />
            </PageBody>
        </>
    )
}
