import React from 'react'
import { NavigationSidebar } from '@2/layout/navigation-sidebar'
import { TopBar } from '@2/layout/topbar'

export const Layout = ({ children }) => {
    return (
        <>
            <div className="flex overflow-hidden bg-background h-screen print:overflow-visible">
                <div className="flex-none print:hidden">
                    <NavigationSidebar />
                </div>
                <div className="flex-auto flex flex-col overflow-hidden border-l border-border print:overflow-visible">
                    <div className="flex-none w-full">
                        <TopBar />
                    </div>
                    <div className="flex-auto w-full overflow-auto print:overflow-visible">
                        {children}
                        <div className="h-28 w-full print:hidden"></div>
                    </div>
                </div>
            </div>
        </>
    )
}
