import { observable, computed, action, makeObservable } from 'mobx'
import PhaseCollection from '../Collections/PhaseCollection'
import ProjectCollection from '../Collections/ProjectCollection'
import MonthlyResourceCellCollection from '../Collections/MonthlyResourceCellCollection'
import Model from './Model'
import { endOfMonth, parse, startOfMonth } from 'date-fns'
import RoleCollection from '../Collections/RoleCollection'
import StaffCollection from '../Collections/StaffCollection'
import bind from 'bind-decorator'
import ResourceRowCollection from '../Collections/ResourceRowCollection'

class MonthlyResourceCellModel extends Model {
    @observable month = null
    @observable hours = 0
    @observable cost = 0
    @observable chargeOut = 0
    @observable status = null
    @observable projectId = null
    @observable phaseId = null
    @observable phaseTitle = null
    @observable staffRoleId = null
    @observable staffId = null
    @observable ownerId = null
    @observable costCentreId = null
    @observable type = null

    constructor(data, options) {
        super()
        makeObservable(this)
        this.collection = MonthlyResourceCellCollection
        this.init(data, options)
        const rowId = `('${this.status}', '${this.projectId}', '${
            this.phaseId
        }', '${this.staffRoleId || null}', '${this.staffId}')`
        const row = ResourceRowCollection.rowsById[rowId]
        if (!row) {
            ResourceRowCollection.addRow(
                {
                    id: rowId,
                    status: this.status,
                    projectId: this.projectId,
                    phaseId: this.phaseId,
                    phaseTitle: this.phaseTitle,
                    staffRoleId: this.staffRoleId,
                    staffId: this.staffId,
                    groups: ['status', 'project', 'phase', 'role', 'staff'],
                },
                { trackUpdates: false }
            )
        }
    }

    @computed
    get phase() {
        return PhaseCollection.phasesById[this.phaseId]
    }

    @computed
    get project() {
        return ProjectCollection.projectsById[this.projectId]
    }

    @computed
    get role() {
        return RoleCollection.rolesById[this.staffRoleId]
    }

    @computed
    get staff() {
        return StaffCollection.staffById[this.staffId]
    }

    serialize() {
        return {
            id: this.id,
            month: this.month,
            hours: this.hours,
            projectId: this.projectId,
            phaseId: this.phaseId,
            staffRoleId: this.staffRoleId,
            staffId: this.staffId,
        }
    }

    serializeUpdates() {
        return this.serialize()
    }

    @computed
    get date() {
        return startOfMonth(parse(this.month, 'yyyy-MM', new Date()))
    }
    @computed
    get startDate() {
        return this.date
    }
    @computed get endDate() {
        return endOfMonth(this.date)
    }
    @bind
    getRateInDateRange(rateType, dateRange) {
        if (this.staff) {
            return this.staff.getRateInDateRange(rateType, dateRange)
        } else if (this.role) {
            return this.role.getRateInDateRange(rateType, dateRange)
        } else {
            return null
        }
    }
    @computed
    get costRate() {
        return this.getRateInDateRange('cost', [this.startDate, this.endDate])
    }
    @computed
    get chargeOutRate() {
        return this.getRateInDateRange('chargeOut', [
            this.startDate,
            this.endDate,
        ])
    }
}

export default MonthlyResourceCellModel
