import React, { useCallback, useEffect } from 'react'
import _ from 'lodash'
import { observer } from 'mobx-react'

const TextInput = ({
    value,
    onChange,
    onBlur,
    isEditable = true,
    isValid = true,
    nullReadOnlyValue,
    style,
    formatter,
    parser,
    ...props
}) => {
    const errorStyles = {
        color: '#dc2d11',
        border: '2px solid #dc2d11',
    }

    const [inputValue, setInputValue] = React.useState(
        formatter ? formatter(value) : value
    )

    const handleChange = (event) => {
        setInputValue(event.target.value)
        const parsedVal = parser
            ? parser(event.target.value)
            : event.target.value
        onChange && onChange(parsedVal)
    }

    const handleBlur = (event) => {
        // Format the value when the input loses focus
        const parsedVal = parser
            ? parser(event.target.value)
            : event.target.value
        const formattedValue = formatter ? formatter(parsedVal) : parsedVal
        setInputValue(formattedValue)
        onBlur && onBlur(parsedVal)
    }

    const inputRef = React.useRef(null)

    useEffect(() => {
        if (inputRef?.current === document.activeElement) return
        setInputValue(formatter ? formatter(value) : value)
    }, [value])

    if (isEditable) {
        return (
            <input
                type="text"
                className="input-base"
                value={inputValue || ''}
                style={{
                    padding: '0.5rem',
                    ...(!isValid ? errorStyles : {}),
                    ...style,
                }}
                onChange={handleChange}
                onBlur={handleBlur}
                ref={inputRef}
                {...props}
            />
        )
    } else {
        if (value == null && nullReadOnlyValue != null) {
            if (_.isString(nullReadOnlyValue)) {
                return <span style={{ ...style }}>{nullReadOnlyValue}</span>
            } else {
                return nullReadOnlyValue
            }
        } else {
            return <span style={{ ...style }}>{inputValue}</span>
        }
    }
}

export default observer(TextInput)
