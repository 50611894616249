import { observer } from 'mobx-react'
import React, { useState } from 'react'
import PageTabs from './PageTabs'

export default observer(
    ({
        header,
        heading,
        leftButtons,
        filter,
        rightButtons,
        extraContent,
        subHeading,
        tabs = null,
        selectedTab,
    }) => {
        return (
            <div
                className="coincraft-page-layout__page-header relative w-full print:h-auto print:block text-white bg-444 pl-6 pr-8 pt-4 pb-4 min-h-32"
                style={{ zIndex: 999 }}
            >
                {header}
                {heading ? (
                    <div>
                        <div className="flex justify-between lg:flex-row flex-col">
                            <div className="flex items-center flex-1 basis-auto">
                                <div>
                                    <div className="heading text-4xl inline-block mr-2">
                                        {heading}
                                    </div>
                                    <div>{subHeading}</div>
                                </div>
                                {leftButtons != null && leftButtons.length > 0
                                    ? leftButtons
                                    : null}
                            </div>
                            <div className="buttons flex items-center justify-end lg:justify-start flex-wrap">
                                {filter != null ? (
                                    <div className="p-[0.5em] inline-block relative dont-print">
                                        {filter}
                                    </div>
                                ) : null}
                                {rightButtons}
                            </div>
                        </div>

                        {extraContent}
                    </div>
                ) : null}

                {tabs && tabs.length > 0 && (
                    <PageTabs tabs={tabs} selectedTab={selectedTab} />
                )}
            </div>
        )
    }
)
