import { spanSetFromPointSet } from '../Spanner'

export default (rates = [], rateType, beforeIntialValue) => {
    const rateName =
        rateType === 'availability' ? 'weeklyAvailability' : rateType + 'Rate'
    if (!rates || !rates.length) return []
    return spanSetFromPointSet(
        rates.map((rate) => ({
            point: rate.date.getTime(),
            value: rate[rateName] || 0,
        })),
        beforeIntialValue
    )
}
