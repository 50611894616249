import _ from 'underscore'
import React from 'react'
import CreateReactClass from 'create-react-class'

import PropTypes from 'prop-types'

export var Checkbox = CreateReactClass({
    propTypes: {
        id: PropTypes.string,
        label: PropTypes.node,
        disabled: PropTypes.bool,

        labelPosition: PropTypes.oneOf(['left', 'right']),

        // Either `valueLink` or (`value` and `onChange`).
        valueLink: PropTypes.object,
        value: PropTypes.bool,
        indeterminate: PropTypes.bool,

        /**
     * Usually you should use `onChange` and not `onClick`. Though if you want
     * to do an `event.stopPropagation`, use both: use `onChange` as normal and
     * additionally call `event.stopPropagation()` from `onClick`.  This is
     * because the `onClick` events of parents will be called before the
     * `onChange` event of this component.
     *
     * Ie.

        <Checkbox
          ...
          onChange={function(value, event) {
            // Do stuff with value (and optionally event) as normal
          }}
          onClick={function(event) {
            event.stopPropagation();
          }}
        />
     */
        onChange: PropTypes.func,
        onClick: PropTypes.func,

        className: PropTypes.string,
    },

    getDefaultProps: function () {
        return {
            className: '',
            disabled: false,
            indeterminate: false,
            labelPosition: 'right',
        }
    },

    getInitialState: function () {
        return {}
    },

    statics: {
        nextId: 1,
        getNextId: function () {
            return Checkbox.nextId++
        },
    },

    render: function () {
        let id =
            this.props.id != null
                ? this.props.id
                : 'coincraft-checkbox-' + Checkbox.getNextId()

        let value =
            this.props.valueLink != null
                ? this.props.valueLink.value
                : this.props.value

        let checkbox = (
            <input
                className="coincraft-checkbox__input"
                id={id}
                type="checkbox"
                checked={value || ''}
                disabled={this.props.disabled}
                onChange={this.handleChange}
                onClick={this.props.onClick}
                style={{ cursor: 'pointer' }}
                tabIndex="0"
                ref={(input) => {
                    if (input) {
                        input.indeterminate = this.props.indeterminate
                    }
                }}
            />
        )

        let label =
            this.props.label != null ? (
                <label
                    htmlFor={id}
                    className={`${
                        this.props.labelPosition === 'right'
                            ? 'ml-2.5'
                            : 'mr-2.5'
                    }`}
                >
                    {this.props.label}
                </label>
            ) : null

        return (
            <div
                className={`coincraft-checkbox ${this.props.className}`}
                style={{ display: 'inline-block', ...this.props.style }}
            >
                {this.props.labelPosition === 'right' ? (
                    <div className="inline-flex flex-align-items-center">
                        {checkbox}
                        {label}
                    </div>
                ) : (
                    <div className="inline-flex flex-align-items-center">
                        {label}
                        {checkbox}
                    </div>
                )}
            </div>
        )
    },

    handleChange: function (event) {
        if (!this.props.disabled) {
            if (this.props.valueLink != null) {
                this.props.valueLink.requestChange(event.target.checked)
            } else {
                this.props.onChange(event.target.checked, event)
            }
        }
    },
})
