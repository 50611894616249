import { observer } from 'mobx-react'
import React, { Suspense, useCallback, useEffect, useState } from 'react'
import PageHeader from '../Layout/PageHeader'
import BillingPageStore from './BillingPageStore'
import SessionStore from '../../State/SessionStore'
import { FormatCurrency } from '../../Utils/Localisation/CurrencyFormatter'
import { makeRequest } from '../../Queries/makeRequest'
import { FormatDate } from '../../Utils/Localisation/DateFormatter'
import * as Sentry from '@sentry/react'

export default observer(({ label }) => {
    const subscription = SessionStore.subscription
    if (!subscription) {
        return null
    }
    useEffect(() => {
        BillingPageStore.setBillingPeriod(
            subscription.subscriptionPeriod || 'yearly'
        )
    }, [subscription.subscriptionPeriod])
    return (
        <PageHeader
            heading={
                <div>
                    <div>{label}</div>
                </div>
            }
            leftButtons={[
                <button
                    style={{ marginRight: '1em' }}
                    className="btn btn-default edit-controls__delete-button bg-white/90 m-[0.5em] ml-0 text-[#444] py-2 px-4 text-sm rounded-[6px] border [&:not(:disabled]:border-white cursor-pointer [&:not(:disabled):hover]:bg-white/70 disabled:opacity-50 disabled:cursor-not-allowed"
                    disabled={BillingPageStore.billingPeriod === 'yearly'}
                    onClick={() => BillingPageStore.setBillingPeriod('yearly')}
                >
                    Yearly (15% discount)
                </button>,
                <button
                    style={{ marginRight: '1em' }}
                    className="btn btn-default edit-controls__delete-button bg-white/90 m-[0.5em] ml-0 text-[#444] py-2 px-4 text-sm rounded-[6px] border [&:not(:disabled]:border-white cursor-pointer[&:not(:disabled):hover]:bg-white/70 disabled:opacity-50 disabled:cursor-not-allowed"
                    disabled={BillingPageStore.billingPeriod === 'monthly'}
                    onClick={() => BillingPageStore.setBillingPeriod('monthly')}
                >
                    Monthly
                </button>,
                !subscription.hasSubscription ? (
                    <button
                        className="btn btn-primary edit-controls__delete-button"
                        onClick={() => {
                            let handler = window.StripeCheckout.configure({
                                key: process.env
                                    .REACT_APP_STRIPE_PUBLISHABLE_KEY,
                                token: function (token) {
                                    try {
                                        BillingPageStore.setIsSubscribing(true)
                                        makeRequest({
                                            path: `api/v1.5/subscription/add-customer`,
                                            method: 'post',
                                            data: {
                                                stripeToken: token.id,
                                                period: BillingPageStore.billingPeriod,
                                            },
                                        }).then(({ data }) => {
                                            SessionStore.subscription.update(
                                                data
                                                    .organisationSubscriptions[0]
                                            )
                                            BillingPageStore.setIsSubscribing(
                                                false
                                            )
                                            window.location.reload()
                                        })
                                    } catch (e) {
                                        Sentry.captureException(e)
                                        console.error(e)
                                    }
                                },
                            })

                            handler.open({
                                name: 'Coincraft Subscription',
                                description:
                                    'Please enter your payment details.',
                                amount: BillingPageStore.grandTotal * 100,
                                email: SessionStore.user?.email,
                            })
                        }}
                    >
                        Subscribe!
                    </button>
                ) : null,
            ]}
            extraContent={
                <div
                    style={{
                        marginTop: '1em',
                        fontSize: '1.5em',
                        fontWeight: '300',
                    }}
                >
                    {`Next Payment: ${FormatCurrency(
                        BillingPageStore.grandTotal,
                        {
                            decimals: 2,
                        }
                    )}`}
                    {SessionStore.subscription.nextPaymentDate
                        ? ` - ${FormatDate(
                              SessionStore.subscription.nextPaymentDate
                          )}`
                        : ''}
                </div>
            }
        />
    )
})
