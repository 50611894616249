import { parse } from 'date-fns'
import { observable, computed, action, makeObservable } from 'mobx'
import PhaseCollection from '../Collections/PhaseCollection'
import ProjectCollection from '../Collections/ProjectCollection'
import ProjectExpenseAggregateCollection from './ProjectExpenseAggregateCollection'
import ProjectExpenseAllocationCollection from '../Collections/ProjectExpenseAllocationCollection'
import Model from '../Models/Model'

class ProjectExpenseAggregateModel extends Model {
    @observable projectId = null
    @observable phaseId = null
    @observable name = null
    @observable quantity = null
    @observable unitCost = null
    @observable billable = null
    @observable cost = null
    @observable startDate = null
    @observable endDate = null

    constructor(data, options) {
        super()
        makeObservable(this)
        this.collection = ProjectExpenseAggregateCollection
        this.init(data, options)
    }
    @computed
    get project() {
        return ProjectCollection.modelsById[this.projectId]
    }
    @computed
    get phase() {
        return PhaseCollection.modelsById[this.phaseId]
    }
    @computed
    get allocations() {
        return ProjectExpenseAllocationCollection.expenseAllocationsByExpenseId[
            this.id
        ]
    }
}

export default ProjectExpenseAggregateModel
