import { useState } from 'react'
import { Button } from '@2/components/ui/button'
import { Input } from '@2/components/ui/input'
import { MarqueeButton } from '@2/components/ui/marquee-button'
import { ScrollArea } from '@2/components/ui/scroll-area'
import { ChevronDown, ChevronRight, Search } from 'lucide-react'
import { useAutoAnimate } from '@formkit/auto-animate/react'
import { Link } from '@tanstack/react-router'

export const ExpandableList = ({
    title,
    items = [],
    isExpanded,
    onToggle,
    route,
}) => {
    const [searchQuery, setSearchQuery] = useState('')
    const [parent, enableAnimations] = useAutoAnimate(/* optional config */)

    const filteredItems = items.filter((item) =>
        item.label.toLowerCase().includes(searchQuery.toLowerCase())
    )

    return (
        <div
            ref={parent}
            className={`mb-2 ${isExpanded ? 'flex-grow' : 'flex-shrink-0'} flex flex-col`}
        >
            <Button
                variant="ghost"
                size="sm"
                className="w-full justify-between mb-2 flex-none"
                onClick={onToggle}
            >
                <span className="font-semibold">{title}</span>
                {isExpanded ? (
                    <ChevronDown size={16} />
                ) : (
                    <ChevronRight size={16} />
                )}
            </Button>
            {isExpanded && (
                <div className="px-1 relative h-full w-full flex flex-col">
                    <div className="relative flex-shrink-0 w-full -mt-2 mb-2">
                        <Input
                            type="text"
                            placeholder="Search..."
                            value={searchQuery}
                            onChange={(e) => setSearchQuery(e.target.value)}
                            className="pl-7 py-1 h-8 text-sm"
                        />
                        <Search className="absolute left-2 top-1/2 transform -translate-y-1/2 h-3 w-3 text-gray-400" />
                    </div>
                    <ScrollArea className="flex-auto w-full max-h-[50rem]">
                        <div className="space-2">
                            {filteredItems.map((item) => (
                                <MarqueeButton
                                    key={item.id}
                                    variant="ghost"
                                    size="xs"
                                    className="w-full justify-start"
                                    onClick={() => item.onClick?.()}
                                >
                                    {item.route ? (
                                        <Link
                                            to={item.route}
                                            className="w-full justify-start"
                                        >
                                            {item.label}
                                        </Link>
                                    ) : (
                                        item.label
                                    )}
                                </MarqueeButton>
                            ))}
                        </div>
                    </ScrollArea>
                </div>
            )}
        </div>
    )
}
