export const niceScale = (range, intervals) => {
    const length = range[1] - range[0]
    let intervalLength = logFloor(length / intervals)
    const intervalValues = []
    let i = 0
    let latestIntervalValue = 0
    while (latestIntervalValue < range[1]) {
        let intervalValue = range[0] + intervalLength * i
        intervalValues.push(intervalValue)
        i++
        latestIntervalValue = intervalValue
    }
    return intervalValues
}

const roundingScale = (num) => {
    const scaleLog = Math.floor(Math.log10(num))
    const scale = Math.pow(10, scaleLog)
    return scale
}

export const logRound = (num) => {
    const scale = roundingScale(num)
    return Math.round(num / scale) * scale
}

export const logFloor = (num) => {
    const scale = roundingScale(num)
    return Math.floor(num / scale) * scale
}

export const logCiel = (num) => {
    const scale = roundingScale(num)
    return Math.ceil(num / scale) * scale
}
