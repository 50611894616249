import { observer } from 'mobx-react'
import React, { Suspense, useCallback, useEffect, useState } from 'react'
import PageHeader from '../Layout/PageHeader'
import ReportCollection from '../../State/Collections/ReportCollection'
import SessionStore from '../../State/SessionStore'
import ReportHeader, {
    ReportSearch,
} from '../../Components/Reports/ReportHeader'
import ProjectCollection from '../../State/Collections/ProjectCollection'
import CostCentreCollection from '../../State/Collections/CostCentreCollection'
import PhaseCollection from '../../State/Collections/PhaseCollection'
import Checkbox from '../../Components/Widgets/Checkbox'
import { canCreateProjects } from '../../State/Permissions/HasPermissions'
import ProjectListStore from './ProjectListStore'
import { Selector } from '../../Components/Selector'
import { format } from 'date-fns'
import HeaderButton from '../../Components/ui/HeaderButton'
import { useNavigate } from '@tanstack/react-router'

export default observer((props) => {
    const navigate = useNavigate()
    const report =
        ReportCollection.reportsById[props.report] ||
        SessionStore.organisation.defaultProjectReport
    return (
        <PageHeader
            heading={
                <div>
                    <div>{'Projects'}</div>
                    <div className="text-sm font-normal px-2.5 py-1.5">
                        {isFinite(report.dateRange[0])
                            ? `(${format(
                                  report.dateRange[0],
                                  'dd MMMM yyyy'
                              )} - ${format(
                                  report.dateRange[1],
                                  'dd MMMM yyyy'
                              )})`
                            : `(All time prior to: ${format(
                                  new Date(),
                                  'dd MMMM yyyy'
                              )})`}
                    </div>
                </div>
            }
            leftButtons={
                canCreateProjects(SessionStore.user)
                    ? [
                          <button
                              key={0}
                              className="project-list__add-new-project-button page-header__button--primary plus-btn"
                              onClick={() => {
                                  const project =
                                      ProjectCollection.createProject()
                                  navigate({
                                      to: `/projects/${project.id}`,
                                  })
                              }}
                          >
                              + New Project
                          </button>,
                      ]
                    : null
            }
            rightButtons={
                <div className="flex gap-2 items-center">
                    <div className="export-button-dropdown inline-flex items-center dont-print gap-2">
                        <div className="flex items-center">
                            <HeaderButton
                                label="Export"
                                icon={<i className="fa fa-table" />}
                                onClick={() => {
                                    ProjectListStore.downloadCSV()
                                }}
                                className="border-r-0 rounded-tr-none rounded-br-none"
                            />
                            <Selector
                                className=" border! border-white bg-transparent hover:bg-white/25 transition-colors rounded-[6px] rounded-tl-none rounded-bl-none"
                                style={{
                                    maxWidth: '8em',
                                    margin: 0,
                                    fontSize: '1em',
                                    background: 'transparent',
                                }}
                                value={ProjectListStore.exportGroup}
                                options={['Projects', 'Phases']}
                                optionLabel={function (group) {
                                    return <div>{group}</div>
                                }}
                                onChange={(group) =>
                                    ProjectListStore.setExportGroup(group)
                                }
                            />
                        </div>
                    </div>
                    <HeaderButton
                        label="Print"
                        icon={<i className="fa fa-print" />}
                        onClick={() => window.print()}
                    />
                </div>
            }
            filter={
                <ReportSearch
                    report={report}
                    placeholder="Filter by project name or job code"
                />
            }
            extraContent={
                <ReportHeader
                    report={report}
                    reportOptions={ReportCollection.reportsByType['project']}
                    baseUrl={'/projects'}
                    filterOptions={({ filter, columns, report }) => {
                        return (
                            <Checkbox
                                label="Apply to Phases"
                                className="is-variation-checkbox"
                                value={filter.group === 'phase'}
                                onChange={(v) =>
                                    v
                                        ? report.updateFilter(
                                              filter,
                                              'group',
                                              'phase'
                                          )
                                        : report.updateFilter(
                                              filter,
                                              'group',
                                              'project'
                                          )
                                }
                            />
                        )
                    }}
                    expandClick={() => ProjectListStore.toggleExpandAll()}
                    expandLabel={
                        ProjectListStore.expandAll
                            ? '- Collapse All'
                            : '+ Expand All'
                    }
                />
            }
        />
    )
})
