import 'core-js/actual'
import 'fix-date' // Fix Date.now() for Safari 15
import React from 'react'
import * as serviceWorker from './serviceWorker'
import './index.css'
import { createRoot } from 'react-dom/client'
import App from './App.js'

const root = document.getElementById('root')
const reactRoot = createRoot(root)

reactRoot.render(<App />)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
