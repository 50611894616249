import { observable, computed, action, makeObservable } from 'mobx'
import Collection from './Collection'
import PermissionModel from '../Models/PermissionModel'
import { permissionPresets } from '../Permissions/PermissionPresets'
import StaffCollection from './StaffCollection'

class PermissionCollection extends Collection {
    constructor() {
        super({ collection: 'permissions', modelClass: PermissionModel })
        makeObservable(this)
    }
    get permissionsById() {
        return this.modelsById
    }
    get permissions() {
        return this.models
    }
    @action.bound
    addPermission(data) {
        this.add(data)
    }
    @action.bound
    addPermissions(data) {
        this.addMany(data)
    }
    @action.bound
    createPermission(settings) {
        settings ??= permissionPresets.timesheet
        return this.add(
            {
                name: 'New Permission',
                settings,
            },
            { trackUpdates: true }
        )
    }
    @action.bound
    createPermissionFromStaff(staff) {
        const permission = this.createPermission(staff.permissions)
        staff.update({ permissionsId: permission.id })
        const matchingStaff =
            StaffCollection.staffByPermissionKey[permission.key] || []
        matchingStaff.forEach((s) => {
            s.update({ permissionsId: permission.id })
        })
        return permission
    }
}

export default new PermissionCollection()
export const PermissionCollectionClass = PermissionCollection
