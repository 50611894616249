import { observer } from 'mobx-react'
import React from 'react'
import Table from '../../Components/Table'
import ReportCollection from '../../State/Collections/ReportCollection'
import StaffCollection from '../../State/Collections/StaffCollection'
import SessionStore from '../../State/SessionStore'
import LoadingSpinner from '../../Components/LoadingSpinner'
import RenderOnQueries from '../Layout/RenderOnQueries'
import { mergeQueries } from '../../Queries/mergeQueries'
import FetchStore from '../../Queries/FetchStore'
import UpdateReportModal from '../../Components/UpdateReportModal'

export default observer(({ id }) => {
    const report =
        ReportCollection.reportsById[id] ||
        SessionStore.organisation.defaultStaffReport
    return (
        <RenderOnQueries
            key={'staff-table' + report.queryKey}
            loading={<LoadingSpinner />}
            queryIds={[
                mergeQueries([
                    {
                        id: 'staffReport' + report.queryKey,
                        collection: 'staff',
                        fields: ['firstName', 'lastName'],
                        filters: [
                            ...report.filters
                                .map((f) => {
                                    return report.columnOptionsById[
                                        f.column
                                    ]?.queryFilters?.(f)
                                })
                                .flat(),
                        ].filter((v) => v),
                    },
                    ...report.tableColumns
                        .map((c) => c.queries?.(report) || [])
                        .flat()
                        .filter((q) => q?.collection === 'staff'),
                ]),
                ...report.tableColumns
                    .map((c) => c.queries?.(report) || [])
                    .flat()
                    .filter((q) => q?.collection !== 'staff'),
            ]}
        >
            <UpdateReportModal report={report} />
            <Table
                tableStore={report.tableStore}
                columns={report.tableColumns}
                rows={FetchStore.getResponse(
                    'staffReport' + report.queryKey
                )?.staff?.staffs.filter(
                    (staff) =>
                        !staff.deletedAt &&
                        staff.fullName
                            .toLowerCase()
                            .includes(report.searchFilter.toLowerCase())
                )}
                sortBy={report.sortBy}
                showTotals={true}
                // filters={report.filters}
            />
        </RenderOnQueries>
    )
})
