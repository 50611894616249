import { useDataTable } from '@2/hooks/use-data-table'
import { DataTableAdvancedToolbar } from '@2/components/data-table/advanced/data-table-advanced-toolbar'
import { DataTable } from '@2/components/data-table/data-table'
import _ from 'lodash'

import { getColumns } from './project-report-columns'
import { ProjectReportActions } from './project-report-actions'
import { useProjectReportStore } from './project-report-store'
import * as React from 'react'

export function ProjectReportTable() {
    // Feature flags for showcasing some additional features. Feel free to remove them.
    // const { featureFlags } = useTasksTable()

    // const { data, pageCount } = React.use(tasksPromise)

    // Memoize the columns so they don't re-render on every render
    // const columns = React.useMemo(() => getColumns(), [])

    /**
     * This component can render either a faceted filter or a search filter based on the `options` prop.
     *
     * @prop options - An array of objects, each representing a filter option. If provided, a faceted filter is rendered. If not, a search filter is rendered.
     *
     * Each `option` object has the following properties:
     * @prop {string} label - The label for the filter option.
     * @prop {string} value - The value for the filter option.
     * @prop {React.ReactNode} [icon] - An optional icon to display next to the label.
     * @prop {boolean} [withCount] - An optional boolean to display the count of the filter option.
     */
    // const data = ProjectListStore.queryData || []

    const data = useProjectReportStore((state) => state.reportData)
    const columnsInStage = useProjectReportStore(
        (state) => state.projectReport.columns
    )
    const isLoadingReportData = useProjectReportStore(
        (state) => state.isLoadingReportData
    )
    const [isAlertChangeColumns, setIsAlertChangeColumns] =
        React.useState(false)
    const columns = getColumns({ setIsAlertChangeColumns }).sort((a, b) => {
        const indexA = columnsInStage.indexOf(a?.accessorKey)
        const indexB = columnsInStage.indexOf(b?.accessorKey)
        return indexA - indexB
    })

    const typeFilterMap: { [key: string]: string } = {
        text: 'text',
        progressBar: 'percent',
        percent: 'percent',
        date: 'date',
        number: 'number',
        currency: 'number',
        project: 'select',
        costCentre: 'select',
        contact: 'select',
        staffMember: 'select',
        status: 'select',
        staffs: 'select',
    }

    const filterFields: any = columns
        .filter((column: any) => column.accessorKey !== 'expand')
        .map((column: any) => {
            return {
                label: column?.meta?.title,
                value: column.accessorKey,
                typeFilter: typeFilterMap[column?.meta?.type],
                ...column?.meta?.filterOptions,
            }
        })

    const { table } = useDataTable({
        data,
        columns,
        // pageCount,
        // defaultPerPage: 100000,
        // defaultSort: 'createdAt.desc',
        filterFields,
        enableAdvancedFilter: true,
        sortingFns: {
            sortingTextFn: (rowA, rowB, columnId): any => {
                const valueA = rowA.getValue(columnId)
                const valueB = rowB.getValue(columnId)
                const isNumberA = /^\d/.test(valueA)
                const isNumberB = /^\d/.test(valueB)

                if (isNumberA && !isNumberB) return -1
                if (!isNumberA && isNumberB) return 1

                return valueA.localeCompare(valueB)
            },
            sortingProgressFn: (rowA: any, rowB: any, columnId): any => {
                const valueA = rowA.getValue(columnId)
                const valueB = rowB.getValue(columnId)

                const ratioA = valueA.numerator / valueA.denominator
                const ratioB = valueB.numerator / valueB.denominator

                const isUndefinedA = isNaN(ratioA) || valueA.denominator === 0
                const isUndefinedB = isNaN(ratioB) || valueB.denominator === 0

                if (isUndefinedA && !isUndefinedB) return 1
                if (!isUndefinedA && isUndefinedB) return -1

                return ratioA - ratioB
            },
            sortingDateFn: (rowA, rowB, columnId) => {
                const dateA = rowA.getValue(columnId)
                const dateB = rowB.getValue(columnId)

                if (!dateA) return 1
                if (!dateB) return -1

                const timeA = new Date(dateA as Date).getTime()
                const timeB = new Date(dateB as Date).getTime()

                return timeA - timeB
            },
        },
    })

    return (
        <DataTable
            table={table}
            showTotals={true}
            loadingReportData={isLoadingReportData}
        >
            <DataTableAdvancedToolbar
                table={table}
                filterFields={filterFields}
                isAlertChangeColumns={isAlertChangeColumns}
                setIsAlertChangeColumns={setIsAlertChangeColumns}
            >
                <ProjectReportActions table={table} />
            </DataTableAdvancedToolbar>
        </DataTable>
    )
}
