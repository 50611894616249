import { createFileRoute } from '@tanstack/react-router'
import StaffListPage from '../../Pages/StaffListPage/StaffListPage'
import StaffListPageHeader from '../../Pages/StaffListPage/StaffListPageHeader'
import PageBody from '../../Pages/Layout/PageBody'
import SessionStore from '../../State/SessionStore'
import { qf } from '../../Queries/queryFormatter'
import LoadingSpinner from '../../Components/LoadingSpinner'
import ErrorComponent from '../../Components/ErrorComponent'
import fetchData from '../../Queries/fetchData'
import { canViewRoute } from '../../State/Permissions/HasPermissions'
import PermissionDenied from '../../Pages/PermissionDenied'

export const Route = createFileRoute('/_private/staff/')({
    beforeLoad: ({ params }) => {
        if (!canViewRoute(SessionStore.user, 'staffList', params))
            throw new Error('Permission Denied')
    },
    errorComponent: ({ error, reset }) => {
        if (error.message === 'Permission Denied') return <PermissionDenied />
        return <ErrorComponent error={error} reset={reset} />
    },
    pendingComponent: LoadingSpinner,
    loader: async ({ location }) => await loader(location.search),
    loaderDeps: ({ search: { report } }) => ({
        report,
    }),
    component: Wrapper,
})

const loader = async ({ report }) => {
    const reportId = report || SessionStore.organisation?.defaultStaffReportId
    const collections = [
        reportId
            ? {
                  collection: 'reports',
                  fields: [
                      'name',
                      'type',
                      'columns',
                      'filters',
                      'groupBy',
                      'sortBy',
                      'options',
                  ],
                  filters: [`id == ${qf(reportId)}`],
              }
            : null,
        {
            collection: 'reports',
            fields: ['name', 'type'],
        },
    ].filter(Boolean)

    return await Promise.all(collections.map(fetchData))
}

function Wrapper() {
    const { report } = Route.useSearch()
    return (
        <>
            <StaffListPageHeader id={report} />
            <PageBody>
                <StaffListPage id={report} />
            </PageBody>
        </>
    )
}
