import classNames from 'classnames'
import { observer } from 'mobx-react'
import React, { useEffect, useState } from 'react'
import ReportCollection from '../../State/Collections/ReportCollection'
import LayoutStore from '../../State/LayoutStore'
import { caseInsensitiveContains } from '../../utils'
import {
    Dropdown3,
    Dropdown3ListItem,
    SmallDeleteButton,
    FilterTextBox,
} from '../../widgets'
import { operatorLabels } from '../Filters'
import Inputs from '../Inputs'
import {
    CostCentreMultiselect,
    ExpenseMultiselect,
    OverheadMultiselect,
    ProjectMultiselect,
    StaffMultiselect,
    StatusMultiselect,
} from '../MultiSelect'
import pluralize from 'pluralize'
import RenameReportModal from './RenameReportModal'
import DataStore from '../../State/DataStore'
import SaveAsReportModal from './SaveAsReportModal'
import Checkbox from '../Widgets/Checkbox'
import CostCentreCollection from '../../State/Collections/CostCentreCollection'
import ProjectCollection from '../../State/Collections/ProjectCollection'
import StaffCollection from '../../State/Collections/StaffCollection'
import OverheadExpenseCollection from '../../State/Collections/OverheadExpenseCollection'
import { Selector } from '../Selector'
import HeaderButton from '../ui/HeaderButton'
import { useNavigate } from '@tanstack/react-router'
import _ from 'lodash'

export default observer(({ store, report, reportOptions = [] }) => {
    const [expandReportEditor, setExpandReportEditor] = useState(false)
    const navigate = useNavigate()

    return (
        <>
            <div className="report-selector flex items-center gap-2 mt-7">
                {'Report: '}
                <Selector
                    className="flex-0-0-auto page-header__button"
                    style={{ marginLeft: '1em' }}
                    value={report}
                    options={reportOptions}
                    searchMatcher={function (report, searchText) {
                        return caseInsensitiveContains(report.name, searchText)
                    }}
                    optionLabel={function (report) {
                        return <div>{report.name}</div>
                    }}
                    onChange={(report) =>
                        navigate({
                            search: (prev) => ({
                                ...prev,
                                report: report.id,
                            }),
                        })
                    }
                />
                <div className="flex items-center gap-2">
                    <HeaderButton
                        label={expandReportEditor ? 'Hide ' : 'Edit '}
                        icon={
                            <i
                                className={classNames(
                                    'fa m-0',
                                    expandReportEditor
                                        ? 'fa-angle-double-up'
                                        : 'fa-angle-double-down'
                                )}
                            />
                        }
                        onClick={() =>
                            setExpandReportEditor(!expandReportEditor)
                        }
                    />
                    {expandReportEditor ? (
                        <span>
                            <ReportSaveDropdown report={report} />
                            {!report.systemReport ? (
                                <ReportActionsDropdown report={report} />
                            ) : null}
                        </span>
                    ) : null}
                </div>
            </div>
            {expandReportEditor ? (
                <ReportEditor store={store} report={report} />
            ) : null}
        </>
    )
})

const ReportSaveDropdown = observer(({ report }) => {
    return (
        <Dropdown3
            // isExpanded={this.props.isExpanded}
            // onToggle={this.handleToggle}
            toggleElement={
                <button className="primary flex items-center gap-1 py-2 px-4 border border-white bg-transparent hover:bg-white/25 transition-colors rounded-[6px] text-xs">
                    Save
                    <i className="rw-i rw-i-caret-down" />
                </button>
            }
            contentStyle={{
                width: '14em',
                color: '#444',
                borderRadius: '0.5em',
            }}
        >
            {!report.systemReport ? (
                <Dropdown3ListItem
                    style={{ padding: '0.5em 1em', height: '2.5em' }}
                    className="report-save-dropdown__save-option"
                    onClick={() => DataStore.saveModel(report)}
                >
                    Save
                </Dropdown3ListItem>
            ) : null}
            <Dropdown3ListItem
                style={{ padding: '0.5em 1em', height: '2.5em' }}
                onClick={() => {
                    LayoutStore.openModal(
                        <SaveAsReportModal
                            report={report}
                            modalId={'saveAsReport'}
                        />
                    )
                }}
            >
                Save As...
            </Dropdown3ListItem>
        </Dropdown3>
    )
})

const ReportActionsDropdown = observer(({ report }) => {
    const navigate = useNavigate()
    return (
        <Dropdown3
            // isExpanded={this.props.isExpanded}
            // onToggle={this.handleToggle}
            toggleElement={
                <button className="primary flex items-center gap-1 ml-2 py-2 px-4 border border-white bg-transparent hover:bg-white/25 transition-colors rounded-[6px] text-xs">
                    Actions
                    <i
                        className="rw-i rw-i-caret-down"
                        style={{ marginLeft: '0.5em' }}
                    />
                </button>
            }
            contentStyle={{
                width: '18em',
                color: '#444',
                borderRadius: '0.5em',
            }}
        >
            <Dropdown3ListItem
                style={{ padding: '0.5em 1em', height: '2.5em' }}
                onClick={() => {
                    report.makeDefault()
                    DataStore.saveModel(report)
                }}
            >
                Set As Default
            </Dropdown3ListItem>
            <Dropdown3ListItem
                style={{ padding: '0.5em 1em', height: '2.5em' }}
                onClick={() => {
                    LayoutStore.openModal(
                        <RenameReportModal
                            report={report}
                            modalId={'renameReport'}
                        />
                    )
                }}
            >
                Rename
            </Dropdown3ListItem>
            <Dropdown3ListItem
                style={{ padding: '0.5em 1em', height: '2.5em' }}
                onClick={() => {
                    report.update({ deletedAt: new Date() })
                    DataStore.saveModel(report)
                    navigate({
                        search: (prev) =>
                            _.omitBy(
                                {
                                    ...prev,
                                    report: null,
                                },
                                (v) => v === null
                            ),
                    })
                }}
            >
                Delete
            </Dropdown3ListItem>
        </Dropdown3>
    )
})

const ReportEditor = observer(({ report, store }) => {
    return (
        <div
            className="dont-print"
            style={{
                backgroundColor: 'rgba(255, 255, 255, 0.1)',
                borderRadius: '1em',
                margin: '1em 0',
            }}
        >
            <div>
                <ReportFilterList
                    store={store}
                    report={report}
                    filters={report.filters}
                />
            </div>
        </div>
    )
})

const ReportFilterList = observer(({ filters, store, report }) => {
    return (
        <div
            className="report-selector"
            style={{
                width: '100%',
                marginTop: '1em',
                display: 'block',
                padding: '1em',
            }}
        >
            <div className="py-[1em] px-[0.5em] flex items-end flex-wrap">
                <div style={{ marginRight: '2em' }}>
                    {'Revenue Data: '}
                    <Selector
                        className="flex-0-0-auto page-header__button"
                        style={{ margin: '0 0.5em' }}
                        value={filters.revenueData}
                        onChange={(revenueData) =>
                            store.updateFilters({ revenueData })
                        }
                        options={[
                            {
                                label: 'Actuals + Projected',
                                value: 'actualsProjected',
                            },
                            { label: 'Actuals', value: 'actuals' },
                            { label: 'Projected', value: 'projected' },
                        ]}
                    />
                </div>
                <div style={{ marginRight: '2em' }}>
                    {'Profit %: '}
                    <Selector
                        className="flex-0-0-auto page-header__button"
                        style={{ margin: '0 0.5em' }}
                        value={filters.profitDataType}
                        onChange={(profitDataType) =>
                            store.updateFilters({ profitDataType })
                        }
                        options={[
                            {
                                label: 'Profit Margin',
                                value: 'margin',
                            },
                            { label: 'Markup', value: 'markup' },
                        ]}
                    />
                </div>
                <div style={{ marginRight: '2em' }}>
                    {'Invoice: '}
                    <Selector
                        className="flex-0-0-auto page-header__button"
                        style={{ margin: '0 0.5em' }}
                        value={filters.invoiceData}
                        onChange={(invoiceData) =>
                            store.updateFilters({ invoiceData })
                        }
                        options={[
                            { label: 'Work Completion', value: 'endDate' },
                            { label: 'Issue Date', value: 'issuedOn' },
                            { label: 'Due Date', value: 'dueDate' },
                        ]}
                    />
                </div>
                <div style={{ marginRight: '2em' }}>
                    {'Totals: '}
                    <Selector
                        className="flex-0-0-auto page-header__button"
                        style={{ margin: '0 0.5em' }}
                        value={filters.totalData}
                        onChange={(totalData) =>
                            store.updateFilters({ totalData })
                        }
                        options={[
                            {
                                label: 'Revenue / Total Fee',
                                value: 'revenueTotalFee',
                            },
                            { label: 'Remaining Fee', value: 'remaining' },
                        ]}
                    />
                </div>
                <div style={{ marginRight: '2em' }}>
                    {'Contractor Expense: '}
                    <Selector
                        className="flex-0-0-auto page-header__button"
                        style={{ margin: '0 0.5em' }}
                        value={filters.contractorExpense}
                        onChange={(contractorExpense) =>
                            store.updateFilters({ contractorExpense })
                        }
                        options={[
                            {
                                label: 'Available Hours',
                                value: 'availableHours',
                            },
                            {
                                label: 'Allocated Hours',
                                value: 'allocatedHours',
                            },
                        ]}
                    />
                </div>
                <div style={{ marginRight: '2em' }}>
                    {'Status Group: '}
                    <Selector
                        className="flex-0-0-auto page-header__button"
                        style={{ margin: '0 0.5em' }}
                        value={filters.statusGroup || 'phase'}
                        onChange={(statusGroup) =>
                            store.updateFilters({ statusGroup })
                        }
                        options={[
                            {
                                label: 'Project',
                                value: 'project',
                            },
                            {
                                label: 'Phase',
                                value: 'phase',
                            },
                        ]}
                    />
                </div>
                <div style={{ marginRight: '2em' }}>
                    <Checkbox
                        value={filters.showExpenses}
                        onChange={(showExpenses) =>
                            store.updateFilters({ showExpenses })
                        }
                    />
                    {' Show project expenses.'}
                </div>
            </div>
            <div className="flex" style={{ width: '100%', fontSize: '0.95em' }}>
                <div style={{ width: '20%', padding: '0.5em' }}>
                    Cost Centres:
                    <CostCentreMultiselect
                        selectedCostCentres={
                            filters.costCentres?.map(
                                (cId) =>
                                    CostCentreCollection.costCentresById[cId]
                            ) || []
                        }
                        onChange={(costCentres) =>
                            store.updateFilters({
                                costCentres: costCentres.map((cc) => cc.id),
                            })
                        }
                        onBlur={() => null}
                        styles={{
                            container: (provided, state) => ({
                                ...provided,
                                color: '#444',
                            }),
                            input: (provided, state) => ({
                                ...provided,
                                color: '#444',
                            }),
                            menu: (provided, state) => ({
                                ...provided,
                                fontSize: '0.9em',
                                color: '#444',
                            }),
                        }}
                    />
                </div>
                <div style={{ width: '20%', padding: '0.5em' }}>
                    Status:
                    <StatusMultiselect
                        selectedStatuses={filters.projectStatus}
                        onChange={(projectStatuses) => {
                            return store.updateFilters({
                                projectStatus: projectStatuses,
                            })
                        }}
                        onBlur={() => null}
                        styles={{
                            container: (provided, state) => ({
                                ...provided,
                                color: '#444',
                            }),
                            input: (provided, state) => ({
                                ...provided,
                                color: '#444',
                            }),
                            menu: (provided, state) => ({
                                ...provided,
                                fontSize: '0.9em',
                                color: '#444',
                            }),
                        }}
                    />
                </div>
                <div style={{ width: '20%', padding: '0.5em' }}>
                    Projects:
                    <ProjectMultiselect
                        selectedProjects={
                            filters.projects?.map(
                                (cId) => ProjectCollection.projectsById[cId]
                            ) || []
                        }
                        onChange={(projects) =>
                            store.updateFilters({
                                projects: projects.map((p) => p.id),
                            })
                        }
                        onBlur={() => null}
                        styles={{
                            container: (provided, state) => ({
                                ...provided,
                                color: '#444',
                            }),
                            input: (provided, state) => ({
                                ...provided,
                                color: '#444',
                            }),
                            menu: (provided, state) => ({
                                ...provided,
                                fontSize: '0.9em',
                                color: '#444',
                            }),
                        }}
                    />
                </div>
                <div style={{ width: '20%', padding: '0.5em' }}>
                    Staff:
                    <StaffMultiselect
                        selectedStaffMembers={
                            filters.staff?.map(
                                (cId) => StaffCollection.staffsById[cId]
                            ) || []
                        }
                        onChange={(staff) =>
                            store.updateFilters({
                                staff: staff.map((st) => st.id),
                            })
                        }
                        onBlur={() => null}
                        styles={{
                            container: (provided, state) => ({
                                ...provided,
                                color: '#444',
                            }),
                            input: (provided, state) => ({
                                ...provided,
                                color: '#444',
                            }),
                            menu: (provided, state) => ({
                                ...provided,
                                fontSize: '0.9em',
                                color: '#444',
                            }),
                        }}
                    />
                </div>
                <div style={{ width: '20%', padding: '0.5em' }}>
                    Project Owner:
                    <StaffMultiselect
                        selectedStaffMembers={
                            filters.projectOwners?.map(
                                (cId) => StaffCollection.staffsById[cId]
                            ) || []
                        }
                        onChange={(staff) =>
                            store.updateFilters({
                                projectOwners: staff.map((st) => st.id),
                            })
                        }
                        onBlur={() => null}
                        styles={{
                            container: (provided, state) => ({
                                ...provided,
                                color: '#444',
                            }),
                            input: (provided, state) => ({
                                ...provided,
                                color: '#444',
                            }),
                            menu: (provided, state) => ({
                                ...provided,
                                fontSize: '0.9em',
                                color: '#444',
                            }),
                        }}
                    />
                </div>
                <div style={{ width: '20%', padding: '0.5em' }}>
                    Expenses:
                    <OverheadMultiselect
                        selectedExpenses={filters.expenses
                            ?.map(
                                (eId) =>
                                    OverheadExpenseCollection
                                        .overheadExpensesById[eId]
                            )
                            .filter(Boolean)}
                        onChange={(expenses) =>
                            store.updateFilters({
                                expenses: expenses.map((e) => e.id),
                            })
                        }
                        onBlur={() => null}
                        styles={{
                            container: (provided, state) => ({
                                ...provided,
                                color: '#444',
                            }),
                            input: (provided, state) => ({
                                ...provided,
                                color: '#444',
                            }),
                            menu: (provided, state) => ({
                                ...provided,
                                fontSize: '0.9em',
                                color: '#444',
                            }),
                        }}
                    />
                </div>
            </div>
        </div>
    )
})

export const ReportSearch = observer(({ report, placeholder }) => {
    return (
        <FilterTextBox
            style={{ width: '19em' }}
            value={report.searchFilter || ''}
            onChange={(val) => report.updateSearchFilter(val)}
            placeholder={placeholder}
        />
    )
})
