import * as Sentry from '@sentry/react'
import PageBody from '../Pages/Layout/PageBody'
export default ({ error, reset }) => {
    Sentry.captureException(error)
    return (
        <PageBody>
            <div>
                There was an error!
                <button
                    className="btn btn-default"
                    style={{
                        padding: '0.25em 0.5em',
                        marginLeft: '0.5em',
                        fontSize: '0.9em',
                    }}
                    onClick={() => reset()}
                >
                    Try again
                </button>
            </div>
        </PageBody>
    )
}
