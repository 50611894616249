import React, { useEffect, useState } from 'react'
import { observer } from 'mobx-react'
import { endOfMonth, format, startOfMonth } from 'date-fns'
import RenderOnQueries from '../Layout/RenderOnQueries'
import SessionStore from '../../State/SessionStore'
import PhaseCollection from '../../State/Collections/PhaseCollection'
import ProjectForecastsSidebar from '../ProjectPage/forecasts/ProjectForecastsSidebar'
import ProjectForecastsStore from '../ProjectPage/forecasts/ProjectForecastsStore'
import ProjectCollection from '../../State/Collections/ProjectCollection'
import { queryClient } from '../../App'
import RevenueForecastStore from './RevenueForecastStore'
import SidebarTitle from '../../Components/ForecastSidebar/SidebarTitle'
import SidebarNavigation from '../../Components/ForecastSidebar/SidebarNavigation'
import { ProjectSidebarContent } from '../ResourceSchedulePage/ResourceScheduleProjectSidebar'

export default observer(() => {
    const [store, setStore] = useState(RevenueForecastStore)
    if (
        store.selectedMonth &&
        store.selectedObject &&
        store.selectedProjectId
    ) {
        const month = store.selectedMonth && startOfMonth(store.selectedMonth)
        const project = ProjectCollection.projectsById[store.selectedProjectId]
        const phase = PhaseCollection.phasesById[store.selectedPhaseId]
        const periodId = format(month, 'yyyy-MM')
        const periodType = 'month'
        const queryId = `sidebar${periodType}${periodId}${store.selectedProjectId}${store.selectedPhaseId}`

        return (
            <RenderOnQueries
                queryIds={[
                    {
                        id: queryId,
                        baseURL: process.env.REACT_APP_NODE_SERVER_URL,
                        path: `/project-forecast-sidebar`,
                        method: 'POST',
                        staleTime: 0,
                        data: {
                            projectId: store.selectedProjectId,
                            organisationId: SessionStore.organisationId,
                            userId: SessionStore.user?.id,
                            invoiceType:
                                SessionStore.organisation
                                    .defaultRevenueForecastReport.filters
                                    .invoiceData,
                            periodType: periodType,
                            dataType:
                                RevenueForecastStore.report.filters.revenueData,
                            selectedPeriod: periodId,
                            phaseId: store.selectedPhaseId,
                        },
                    },
                    {
                        id: store.selectedProjectId + 'phases',
                        collection: 'phases',
                        fields: [
                            'id',
                            'name',
                            'jobNumber',
                            'projectId',
                            'fee',
                            'isRootPhase',
                            'position',
                            'status',
                        ],
                        filters: [`projectId == "${store.selectedProjectId}"`],
                    },
                    {
                        collection: 'staffRates',
                        fields: [
                            'staffId',
                            'date',
                            'payRate',
                            'chargeOutRate',
                            'costRate',
                            'weeklyAvailability',
                            'overtimeRate',
                        ],
                    },
                    {
                        collection: 'roleRates',
                        fields: [
                            'roleId',
                            'date',
                            'payRate',
                            'chargeOutRate',
                            'costRate',
                            'overtimeRate',
                        ],
                    },
                    {
                        collection: 'projectRates',
                        filters: [`projectId == "${store.selectedProjectId}"`],
                        fields: [
                            'projectId',
                            'itemType',
                            'itemId',
                            'phaseId',
                            'date',
                            'costRate',
                            'chargeOutRate',
                        ],
                    },
                ]}
            >
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        height: '100%',
                    }}
                    key={queryId}
                >
                    <div>
                        <SidebarTitle
                            title={project?.title}
                            subTitle={phase?.title}
                        />
                        <SidebarNavigation
                            title={format(month, 'MMM yy')}
                            onLeftClick={() => store.shiftSelectedPeriod(-1)}
                            onRightClick={() => store.shiftSelectedPeriod(1)}
                        />
                        <ProjectSidebarContent
                            periodType={periodType}
                            period={month}
                            periodId={periodId}
                            projectId={store.selectedProjectId}
                            phaseId={store.selectedPhaseId}
                            queryId={queryId}
                            onUpdateRevenue={({
                                period,
                                revenue,
                                ...models
                            }) => {
                                RevenueForecastStore.setEditedRevenueData(
                                    [
                                        models.phase?.status,
                                        models.project?.id,
                                        models.phase?.id,
                                    ]
                                        .filter((k) => k)
                                        .join(','),
                                    period,
                                    revenue,
                                    false
                                )
                            }}
                        />
                    </div>
                </div>
            </RenderOnQueries>
        )
    }
})
