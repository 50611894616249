import { observer } from 'mobx-react'
import React from 'react'
import LayoutStore from '../../State/LayoutStore'

export default observer(({ children }) => {
    return (
        <div
            className="coincraft-page-layout__sidebar relative h-full max-w-xs border-l border-gray-300 flex dont-print overflow-visible"
            style={{
                boxShadow: '0px -2px 10px 0px rgb(0 0 0 / 24%)',
                background: '#fefefe',
                width: LayoutStore.showSidebar ? 'auto' : '0px',
                paddingBottom: '6em',
            }}
        >
            <div
                className="coincraft-page-layout__sidebar-toggle flex-none w-8 bg-gray-500 cursor-pointer absolute top-[8em] right-full h-14 flex items-center justify-center rounded-l-[0.5em]"
                style={{
                    background: 'rgb(255 222 102)',
                    boxShadow: 'rgba(0, 0, 0, 0.24) -1px 0px 2px 0px inset',
                    zIndex: 10000,
                }}
                onClick={() => LayoutStore.toggleSidebar()}
            >
                <i
                    style={{ margin: 0 }}
                    className={`text-[#666] fa fa-chevron-${
                        LayoutStore.showSidebar ? 'right' : 'left'
                    }`}
                />
            </div>
            <div className="coincraft-page-layout__sidebar-content pb-28 flex-auto overflow-x-hidden w-[325px] overflow-y-auto">
                {children}
            </div>
        </div>
    )
})
