import { endOfMonth, format, startOfMonth, subMonths } from 'date-fns'
import { observer } from 'mobx-react'
import React, { useState } from 'react'
import ProjectForecastsStore from './ProjectForecastsStore'
import SidebarNavigation from '../../../Components/ForecastSidebar/SidebarNavigation'
import SidebarTitle from '../../../Components/ForecastSidebar/SidebarTitle'
import PhaseCollection from '../../../State/Collections/PhaseCollection'
import { Checkbox } from 'antd'
import {
    getPhaseExpenseProgressToDate,
    getPhaseRevenueProgressToDate,
    setPhaseExpenseProgressForMonth,
    setPhaseRevenueProgressForMonth,
} from '../../../Utils/forecastHelpersOld'
import RevenueProgressInput from '../../../Components/ForecastSidebar/RevenueProgressInput'
import ExpenseProgressInput from '../../../Components/ForecastSidebar/ExpenseProgressInput'
import UtilisationProgressInput from '../../../Components/ForecastSidebar/UtilisationProgressInput'
import { setHoursInMonth } from '../../../Utils/forecastHelpers'
import {
    canEditRevenueTargets,
    canEditStaffAllocations,
    canViewProjectFees,
    canViewRevenueTargets,
    canViewStaffAllocations,
    canViewStaffCostRate,
    shouldUpdateHoursFromRevenue,
    shouldUpdateRevenueFromHours,
} from '../../../State/Permissions/HasPermissions'
import SessionStore from '../../../State/SessionStore'
import RenderOnQueries from '../../Layout/RenderOnQueries'
import ResourceScheduleStore from '../../ResourceSchedulePage/ResourceScheduleStore'
import sortPhases from '../../../Utils/sortPhases'
import ResourceRowCollection from '../../../State/Collections/ResourceRowCollection'
import { queryClient } from '../../../App'
import m from 'husl'
import { FormatCurrency } from '../../../Utils/Localisation/CurrencyFormatter'
import { Link } from '@tanstack/react-router'

export default observer(({ onUpdateRevenue, onUpdateResources }) => {
    const [store, setStore] = useState(ProjectForecastsStore)
    const selectedProjectOrPhase = store.selectedProjectOrPhase
    const project = selectedProjectOrPhase?.project || selectedProjectOrPhase
    const phase = selectedProjectOrPhase?.project && selectedProjectOrPhase
    const period =
        store.selectedPeriod && store.startOfPeriod(store.selectedPeriod)
    if (selectedProjectOrPhase && period) {
        return (
            <div
                className="flex flex-col h-full"
                key={
                    selectedProjectOrPhase?.id +
                    format(store.selectedPeriod, store.periodFormat())
                }
            >
                <div>
                    <SidebarTitle
                        title={project?.title}
                        subTitle={phase?.title}
                    />
                    <SidebarNavigation
                        title={format(
                            store.selectedPeriod,
                            store.periodDisplayFormat()
                        )}
                        onLeftClick={() => store.shiftSelectedDates(-1)}
                        onRightClick={() => store.shiftSelectedDates(1)}
                    />
                    <SidebarProgress
                        store={store}
                        period={period}
                        selectedProjectOrPhase={selectedProjectOrPhase}
                        onUpdateResources={onUpdateResources}
                        onUpdateRevenue={onUpdateRevenue}
                    />
                </div>
            </div>
        )
    }
    return (
        <div style={{ padding: '1em' }}>
            Please select a revenue cell from project or phase.
        </div>
    )
})

const SidebarProgress = observer(
    ({
        store,
        period,
        selectedProjectOrPhase,
        onUpdateRevenue = null,
        onUpdateResources = null,
    }) => {
        const periodId = format(period, store.periodFormat())
        const prevPeriodId = format(
            store.subPeriods(period, 1),
            store.periodFormat()
        )
        const project =
            selectedProjectOrPhase?.project || selectedProjectOrPhase
        const phase = selectedProjectOrPhase?.project && selectedProjectOrPhase
        const allPhases = phase
            ? [phase]
            : project?.phases.filter((ph) => !ph?.isRootPhase) || []
        return (
            <div style={{ padding: '1em' }} key={periodId + project.id}>
                {canViewProjectFees(SessionStore.user, project) &&
                canViewRevenueTargets(SessionStore.user, project) ? (
                    <>
                        <h4>
                            Revenue
                            {canEditStaffAllocations(SessionStore.user) ? (
                                <Checkbox
                                    onChange={(e) => {
                                        const { checked } = e.target
                                        store.setUpdateExpensesWithRevenue(
                                            checked
                                        )
                                    }}
                                    checked={store.updateExpensesWithRevenue}
                                    style={{
                                        width: '45%',
                                        float: 'right',
                                        fontSize: '0.65em',
                                    }}
                                >
                                    Update Expenses With Revenue
                                </Checkbox>
                            ) : null}
                        </h4>
                        <div style={{ padding: '1em' }}>
                            {!phase ? (
                                <RevenueProgressInput
                                    key={periodId + project.id}
                                    label={project.title}
                                    editable={canEditRevenueTargets(
                                        SessionStore.user
                                    )}
                                    month={period}
                                    fee={project.fee}
                                    previousRevenue={store.getRevenueToDateInPeriod(
                                        prevPeriodId,
                                        null
                                    )}
                                    currentRevenue={store.getRevenueInPeriod(
                                        periodId,
                                        null
                                    )}
                                    onChange={(v) => {
                                        store.setEditedRevenueInPeriod(
                                            periodId,
                                            null,
                                            v
                                        )
                                        if (store.updateExpensesWithRevenue) {
                                            allPhases.forEach((phase) => {
                                                const progress =
                                                    store.getRevenueProgressInPeriod(
                                                        periodId,
                                                        phase.id
                                                    )
                                                if (isFinite(progress)) {
                                                    store.setEditedCostProgressInPeriod(
                                                        periodId,
                                                        phase.id,
                                                        null,
                                                        null,
                                                        progress
                                                    )
                                                    if (
                                                        typeof onUpdateResources !==
                                                            'undefined' &&
                                                        onUpdateResources
                                                    )
                                                        store
                                                            .getLeafModels(
                                                                phase.id,
                                                                null,
                                                                null
                                                            )
                                                            .forEach(
                                                                ({
                                                                    project,
                                                                    phase,
                                                                    status,
                                                                    role,
                                                                    staff,
                                                                }) => {
                                                                    onUpdateResources(
                                                                        {
                                                                            period: periodId,
                                                                            project,
                                                                            phase,
                                                                            status,
                                                                            role,
                                                                            staff,
                                                                            cost: store.getCostInPeriod(
                                                                                periodId,
                                                                                phase.id,
                                                                                role?.id ||
                                                                                    'norole',
                                                                                staff?.id ||
                                                                                    'nostaff'
                                                                            ),
                                                                            hours: store.getHoursInPeriod(
                                                                                periodId,
                                                                                phase.id,
                                                                                role?.id ||
                                                                                    'norole',
                                                                                staff?.id ||
                                                                                    'nostaff'
                                                                            ),
                                                                        }
                                                                    )
                                                                }
                                                            )
                                                }
                                            })
                                        }
                                        if (
                                            typeof onUpdateRevenue !==
                                                'undefined' &&
                                            onUpdateRevenue
                                        )
                                            onUpdateRevenue({
                                                project,
                                                period: periodId,
                                                value: v,
                                            })
                                    }}
                                />
                            ) : null}
                            <div style={{ padding: '2em 0' }}>
                                {allPhases.map((phase) => {
                                    return (
                                        <RevenueProgressInput
                                            key={periodId + phase.id}
                                            label={phase.title}
                                            editable={canEditRevenueTargets(
                                                SessionStore.user
                                            )}
                                            month={period}
                                            fee={phase.fee}
                                            previousRevenue={store.getRevenueToDateInPeriod(
                                                prevPeriodId,
                                                phase.id
                                            )}
                                            currentRevenue={store.getRevenueInPeriod(
                                                periodId,
                                                phase.id
                                            )}
                                            onChange={(v) => {
                                                store.setEditedRevenueInPeriod(
                                                    periodId,
                                                    phase.id,
                                                    v
                                                )
                                                if (
                                                    store.updateExpensesWithRevenue
                                                ) {
                                                    const progress =
                                                        store.getRevenueProgressInPeriod(
                                                            periodId,
                                                            phase.id
                                                        )
                                                    if (isFinite(progress)) {
                                                        store.setEditedCostProgressInPeriod(
                                                            periodId,
                                                            phase.id,
                                                            null,
                                                            null,
                                                            progress
                                                        )
                                                        if (
                                                            typeof onUpdateResources !==
                                                                'undefined' &&
                                                            onUpdateResources
                                                        )
                                                            store
                                                                .getLeafModels(
                                                                    phase.id,
                                                                    null,
                                                                    null
                                                                )
                                                                .forEach(
                                                                    ({
                                                                        project,
                                                                        phase,
                                                                        status,
                                                                        role,
                                                                        staff,
                                                                    }) => {
                                                                        onUpdateResources(
                                                                            {
                                                                                period: periodId,
                                                                                project,
                                                                                phase,
                                                                                status,
                                                                                role,
                                                                                staff,
                                                                                cost: store.getCostInPeriod(
                                                                                    periodId,
                                                                                    phase.id,
                                                                                    role?.id ||
                                                                                        'norole',
                                                                                    staff?.id ||
                                                                                        'nostaff'
                                                                                ),
                                                                                hours: store.getHoursInPeriod(
                                                                                    periodId,
                                                                                    phase.id,
                                                                                    role?.id ||
                                                                                        'norole',
                                                                                    staff?.id ||
                                                                                        'nostaff'
                                                                                ),
                                                                            }
                                                                        )
                                                                    }
                                                                )
                                                    }
                                                }
                                                if (
                                                    typeof onUpdateRevenue !==
                                                        'undefined' &&
                                                    onUpdateRevenue
                                                )
                                                    onUpdateRevenue({
                                                        project,
                                                        phase,
                                                        period: periodId,
                                                        value: v,
                                                    })
                                            }}
                                        />
                                    )
                                })}
                            </div>
                        </div>
                    </>
                ) : null}
                {canViewStaffAllocations(SessionStore.user, project) &&
                canViewStaffCostRate(SessionStore.user) ? (
                    <>
                        <h4>
                            Expenses
                            {canEditRevenueTargets(
                                SessionStore.user,
                                project
                            ) ? (
                                <Checkbox
                                    onChange={(e) => {
                                        const { checked } = e.target
                                        store.setUpdateRevenueWithExpenses(
                                            checked
                                        )
                                    }}
                                    checked={store.updateRevenueWithExpenses}
                                    style={{
                                        width: '45%',
                                        float: 'right',
                                        fontSize: '0.65em',
                                    }}
                                >
                                    Update Revenue With Expenses
                                </Checkbox>
                            ) : null}
                        </h4>
                        <div style={{ padding: '1em' }}>
                            {!phase ? (
                                <ExpenseProgressInput
                                    editable={canEditStaffAllocations(
                                        SessionStore.user,
                                        project
                                    )}
                                    month={period}
                                    onChange={(v) => {
                                        store.setEditedCostInPeriod(
                                            periodId,
                                            null,
                                            null,
                                            null,
                                            v
                                        )
                                        if (store.updateRevenueWithExpenses) {
                                            allPhases.forEach((phase) => {
                                                const progress =
                                                    store.getCostProgressInPeriod(
                                                        periodId,
                                                        phase.id,
                                                        null,
                                                        null
                                                    )

                                                if (isFinite(progress)) {
                                                    store.setEditedRevenueProgressInPeriod(
                                                        periodId,
                                                        phase.id,
                                                        progress
                                                    )
                                                    if (
                                                        typeof onUpdateRevenue !==
                                                            'undefined' &&
                                                        onUpdateRevenue
                                                    )
                                                        onUpdateRevenue({
                                                            project,
                                                            phase,
                                                            period: periodId,
                                                            value: store.getRevenueInPeriod(
                                                                periodId,
                                                                phase.id
                                                            ),
                                                        })
                                                }
                                            })
                                        }
                                        if (
                                            typeof onUpdateResources !==
                                                'undefined' &&
                                            onUpdateResources
                                        )
                                            store
                                                .getLeafModels(null, null, null)
                                                .forEach(
                                                    ({
                                                        project,
                                                        phase,
                                                        status,
                                                        role,
                                                        staff,
                                                    }) => {
                                                        onUpdateResources({
                                                            period: periodId,
                                                            project,
                                                            phase,
                                                            status,
                                                            role,
                                                            staff,
                                                            cost: store.getCostInPeriod(
                                                                periodId,
                                                                phase.id,
                                                                role?.id ||
                                                                    'norole',
                                                                staff?.id ||
                                                                    'nostaff'
                                                            ),
                                                            hours: store.getHoursInPeriod(
                                                                periodId,
                                                                phase.id,
                                                                role?.id ||
                                                                    'norole',
                                                                staff?.id ||
                                                                    'nostaff'
                                                            ),
                                                        })
                                                    }
                                                )
                                    }}
                                    onTimeChange={(v) => {
                                        store.setEditedHoursInPeriod(
                                            periodId,
                                            null,
                                            null,
                                            null,
                                            v
                                        )
                                        if (store.updateRevenueWithExpenses) {
                                            allPhases.forEach((phase) => {
                                                const progress =
                                                    store.getCostProgressInPeriod(
                                                        periodId,
                                                        phase.id,
                                                        null,
                                                        null
                                                    )

                                                if (isFinite(progress)) {
                                                    store.setEditedRevenueProgressInPeriod(
                                                        periodId,
                                                        phase.id,
                                                        progress
                                                    )
                                                    if (
                                                        typeof onUpdateRevenue !==
                                                            'undefined' &&
                                                        onUpdateRevenue
                                                    )
                                                        onUpdateRevenue({
                                                            project,
                                                            phase,
                                                            period: periodId,
                                                            value: store.getRevenueInPeriod(
                                                                periodId,
                                                                phase.id
                                                            ),
                                                        })
                                                }
                                            })
                                        }
                                        if (
                                            typeof onUpdateResources !==
                                                'undefined' &&
                                            onUpdateResources
                                        )
                                            store
                                                .getLeafModels(null, null, null)
                                                .forEach(
                                                    ({
                                                        project,
                                                        phase,
                                                        status,
                                                        role,
                                                        staff,
                                                    }) => {
                                                        onUpdateResources({
                                                            period: periodId,
                                                            project,
                                                            phase,
                                                            status,
                                                            role,
                                                            staff,
                                                            cost: store.getCostInPeriod(
                                                                periodId,
                                                                phase.id,
                                                                role?.id ||
                                                                    'norole',
                                                                staff?.id ||
                                                                    'nostaff'
                                                            ),
                                                            hours: store.getHoursInPeriod(
                                                                periodId,
                                                                phase.id,
                                                                role?.id ||
                                                                    'norole',
                                                                staff?.id ||
                                                                    'nostaff'
                                                            ),
                                                        })
                                                    }
                                                )
                                    }}
                                    costBudget={store.getCostBudget(
                                        null,
                                        null,
                                        null
                                    )}
                                    hoursBudget={store.getHoursBudget(
                                        null,
                                        null,
                                        null
                                    )}
                                    previousCost={store.getCostToDateInPeriod(
                                        prevPeriodId,
                                        null,
                                        null,
                                        null
                                    )}
                                    previousHours={store.getHoursToDateInPeriod(
                                        prevPeriodId,
                                        null,
                                        null,
                                        null
                                    )}
                                    currentCost={store.getCostInPeriod(
                                        periodId,
                                        null,
                                        null,
                                        null
                                    )}
                                    currentHours={store.getHoursInPeriod(
                                        periodId,
                                        null,
                                        null,
                                        null
                                    )}
                                    key={periodId + project.id}
                                    label={project.title}
                                />
                            ) : null}
                            <div style={{ padding: '2em 0' }}>
                                {allPhases.map((phase) => {
                                    return (
                                        <ExpenseProgressInput
                                            editable={canEditStaffAllocations(
                                                SessionStore.user,
                                                project
                                            )}
                                            month={period}
                                            onChange={(v) => {
                                                store.setEditedCostInPeriod(
                                                    periodId,
                                                    phase.id,
                                                    null,
                                                    null,
                                                    v
                                                )
                                                if (
                                                    store.updateRevenueWithExpenses
                                                ) {
                                                    const progress =
                                                        store.getCostProgressInPeriod(
                                                            periodId,
                                                            phase.id,
                                                            null,
                                                            null
                                                        )

                                                    if (isFinite(progress)) {
                                                        store.setEditedRevenueProgressInPeriod(
                                                            periodId,
                                                            phase.id,
                                                            progress
                                                        )
                                                        if (
                                                            typeof onUpdateRevenue !==
                                                                'undefined' &&
                                                            onUpdateRevenue
                                                        )
                                                            onUpdateRevenue({
                                                                project,
                                                                phase,
                                                                period: periodId,
                                                                value: store.getRevenueInPeriod(
                                                                    periodId,
                                                                    phase.id
                                                                ),
                                                            })
                                                    }
                                                }
                                                if (
                                                    typeof onUpdateResources !==
                                                        'undefined' &&
                                                    onUpdateResources
                                                )
                                                    store
                                                        .getLeafModels(
                                                            phase.id,
                                                            null,
                                                            null
                                                        )
                                                        .forEach(
                                                            ({
                                                                project,
                                                                phase,
                                                                status,
                                                                role,
                                                                staff,
                                                            }) => {
                                                                onUpdateResources(
                                                                    {
                                                                        period: periodId,
                                                                        project,
                                                                        phase,
                                                                        status,
                                                                        role,
                                                                        staff,
                                                                        cost: store.getCostInPeriod(
                                                                            periodId,
                                                                            phase.id,
                                                                            role?.id ||
                                                                                'norole',
                                                                            staff?.id ||
                                                                                'nostaff'
                                                                        ),
                                                                        hours: store.getHoursInPeriod(
                                                                            periodId,
                                                                            phase.id,
                                                                            role?.id ||
                                                                                'norole',
                                                                            staff?.id ||
                                                                                'nostaff'
                                                                        ),
                                                                    }
                                                                )
                                                            }
                                                        )
                                            }}
                                            onTimeChange={(v) => {
                                                store.setEditedHoursInPeriod(
                                                    periodId,
                                                    phase.id,
                                                    null,
                                                    null,
                                                    v,
                                                    store.updateRevenueWithExpenses
                                                )
                                                if (
                                                    store.updateRevenueWithExpenses
                                                ) {
                                                    const progress =
                                                        store.getCostProgressInPeriod(
                                                            periodId,
                                                            phase.id,
                                                            null,
                                                            null
                                                        )

                                                    if (isFinite(progress)) {
                                                        store.setEditedRevenueProgressInPeriod(
                                                            periodId,
                                                            phase.id,
                                                            progress
                                                        )
                                                        if (
                                                            typeof onUpdateRevenue !==
                                                                'undefined' &&
                                                            onUpdateRevenue
                                                        )
                                                            onUpdateRevenue({
                                                                project,
                                                                phase,
                                                                period: periodId,
                                                                value: store.getRevenueInPeriod(
                                                                    periodId,
                                                                    phase.id
                                                                ),
                                                            })
                                                    }
                                                }

                                                if (
                                                    typeof onUpdateResources !==
                                                        'undefined' &&
                                                    onUpdateResources
                                                )
                                                    store
                                                        .getLeafModels(
                                                            phase.id,
                                                            null,
                                                            null
                                                        )
                                                        .forEach(
                                                            ({
                                                                project,
                                                                phase,
                                                                status,
                                                                role,
                                                                staff,
                                                            }) => {
                                                                onUpdateResources(
                                                                    {
                                                                        period: periodId,
                                                                        project,
                                                                        phase,
                                                                        status,
                                                                        role,
                                                                        staff,
                                                                        cost: store.getCostInPeriod(
                                                                            periodId,
                                                                            phase.id,
                                                                            role?.id ||
                                                                                'norole',
                                                                            staff?.id ||
                                                                                'nostaff'
                                                                        ),
                                                                        hours: store.getHoursInPeriod(
                                                                            periodId,
                                                                            phase.id,
                                                                            role?.id ||
                                                                                'norole',
                                                                            staff?.id ||
                                                                                'nostaff'
                                                                        ),
                                                                    }
                                                                )
                                                            }
                                                        )
                                            }}
                                            costBudget={store.getCostBudget(
                                                phase.id,
                                                null,
                                                null
                                            )}
                                            hoursBudget={store.getHoursBudget(
                                                phase.id,
                                                null,
                                                null
                                            )}
                                            previousCost={store.getCostToDateInPeriod(
                                                prevPeriodId,
                                                phase.id,
                                                null,
                                                null
                                            )}
                                            previousHours={store.getHoursToDateInPeriod(
                                                prevPeriodId,
                                                phase.id,
                                                null,
                                                null
                                            )}
                                            currentCost={store.getCostInPeriod(
                                                periodId,
                                                phase.id,
                                                null,
                                                null
                                            )}
                                            currentHours={store.getHoursInPeriod(
                                                periodId,
                                                phase.id,
                                                null,
                                                null
                                            )}
                                            key={periodId + phase.id}
                                            label={phase.title}
                                        />
                                    )
                                })}
                            </div>
                        </div>
                    </>
                ) : null}
                <RenderOnQueries
                    queryIds={[
                        {
                            id: `staff-util-${project.id}-${periodId}`,
                            baseURL: process.env.REACT_APP_NODE_SERVER_URL,
                            path: `/staff-utilisation/without-project`,
                            method: 'POST',
                            data: {
                                period: periodId,
                                periodType: store.periodType,
                                projectId: project.id,
                                organisationId: SessionStore.organisationId,
                                userId: SessionStore.user?.id,
                            },
                        },
                    ]}
                >
                    <StaffUtilisation
                        store={store}
                        project={project}
                        phase={phase}
                        period={period}
                        periodId={periodId}
                        allPhases={allPhases}
                        onUpdateResources={onUpdateResources}
                    />
                </RenderOnQueries>
                <RenderOnQueries
                    queryIds={[
                        {
                            id: `rev-source-${project.id}-${periodId}`,
                            baseURL: process.env.REACT_APP_NODE_SERVER_URL,
                            path: `/revenue-sources/`,
                            method: 'POST',
                            data: {
                                period: periodId,
                                periodType: store.periodType,
                                projectId: project.id,
                                organisationId: SessionStore.organisationId,
                                userId: SessionStore.user?.id,
                                invoiceDateType:
                                    SessionStore.settings.reportInvoiceDateType,
                            },
                        },
                    ]}
                >
                    <RevenueSources
                        store={store}
                        project={project}
                        phase={phase}
                        period={period}
                        periodId={periodId}
                        allPhases={allPhases}
                    />
                </RenderOnQueries>
            </div>
        )
    }
)

const StaffUtilisation = observer(
    ({
        store,
        project,
        phase,
        allPhases,
        period,
        periodId,
        onUpdateResources,
    }) => {
        const staffUtilData = queryClient.getQueryData([
            `staff-util-${project.id}-${periodId}`,
        ])?.data
        const staff =
            [
                ...((phase
                    ? store.staffByPhase[phase.id]
                    : store.projectStaff) || []),
            ].filter((s) => s) || []
        const periodStart = store.startOfPeriod(period)
        const periodEnd = store.endOfPeriod(period)
        return (
            <>
                <h4>Utilisation</h4>
                <div style={{ padding: '1em' }}>
                    {staff
                        .filter((s) => s && s.id !== 'nostaff')
                        .map((st) => {
                            const selectedHours = store.getHoursInPeriod(
                                periodId,
                                phase?.id,
                                st?.role?.id || 'norole',
                                st?.id
                            )
                            return (
                                <UtilisationProgressInput
                                    key={
                                        st.id +
                                        project.id +
                                        phase?.id +
                                        periodId
                                    }
                                    label={st.fullName}
                                    editable={canEditStaffAllocations(
                                        SessionStore.user,
                                        project
                                    )}
                                    month={period}
                                    staff={st}
                                    availability={st.getAvailableHoursInDateRange(
                                        [periodStart, periodEnd]
                                    )}
                                    totalHours={
                                        selectedHours +
                                        (staffUtilData[st.id]?.hours ?? 0)
                                    }
                                    selectedHours={selectedHours}
                                    onChange={(v) => {
                                        store.setEditedHoursInPeriod(
                                            periodId,
                                            phase?.id,
                                            st?.role?.id,
                                            st?.id,
                                            v
                                        )
                                        if (store.updateRevenueWithExpenses) {
                                            allPhases.forEach((phase) => {
                                                const progress =
                                                    store.getCostProgressInPeriod(
                                                        periodId,
                                                        phase.id,
                                                        null,
                                                        null
                                                    )
                                                if (isFinite(progress)) {
                                                    store.setEditedRevenueProgressInPeriod(
                                                        periodId,
                                                        phase.id,
                                                        progress
                                                    )
                                                    if (
                                                        typeof onUpdateRevenue !==
                                                            'undefined' &&
                                                        onUpdateRevenue
                                                    )
                                                        onUpdateRevenue({
                                                            project,
                                                            phase,
                                                            period: periodId,
                                                            value: store.getRevenueInPeriod(
                                                                periodId,
                                                                phase.id
                                                            ),
                                                        })
                                                }
                                            })
                                        }
                                        if (
                                            typeof onUpdateResources !==
                                                'undefined' &&
                                            onUpdateResources
                                        )
                                            store
                                                .getLeafModels(
                                                    phase?.id,
                                                    st?.role?.id,
                                                    st?.id
                                                )
                                                .forEach(
                                                    ({
                                                        project,
                                                        phase,
                                                        status,
                                                        role,
                                                        staff,
                                                    }) => {
                                                        onUpdateResources({
                                                            period: periodId,
                                                            project,
                                                            phase,
                                                            status,
                                                            role,
                                                            staff,
                                                            cost: store.getCostInPeriod(
                                                                periodId,
                                                                phase.id,
                                                                role?.id ||
                                                                    'norole',
                                                                staff?.id ||
                                                                    'nostaff'
                                                            ),
                                                            hours: store.getHoursInPeriod(
                                                                periodId,
                                                                phase.id,
                                                                role?.id ||
                                                                    'norole',
                                                                staff?.id ||
                                                                    'nostaff'
                                                            ),
                                                        })
                                                    }
                                                )
                                    }}
                                />
                            )
                        })}
                </div>
            </>
        )
    }
)

const RevenueSources = observer(
    ({ store, project, phase, allPhases, period, periodId }) => {
        const sources = queryClient.getQueryData([
            `rev-source-${project.id}-${periodId}`,
        ])?.data
        if (!sources?.invoices && !sources?.changeLogs) return null
        return (
            <div
                style={{
                    borderTop: '1px solid #ccc',
                    paddingTop: '1em',
                    marginTop: '1em',
                }}
            >
                <h4>Revenue Sources</h4>
                <div style={{ padding: '1em' }}>
                    {sources?.invoices?.length ? (
                        <div>
                            <div style={{ fontWeight: 'bold' }}>Invoices</div>
                            {sources?.invoices?.map((source) => {
                                return (
                                    <div
                                        key={source.id}
                                        style={{
                                            display: 'flex',
                                            justifyContent: 'space-between',
                                            padding: '0.5em 0',
                                        }}
                                    >
                                        <Link to={`/invoices/${source.id}`}>
                                            {source.ref}
                                        </Link>
                                        <div>
                                            {FormatCurrency(source.amount)}
                                        </div>
                                    </div>
                                )
                            })}
                        </div>
                    ) : null}
                    {sources?.changeLogs?.length ? (
                        <div>
                            <div style={{ fontWeight: 'bold' }}>Change Log</div>
                            {sources?.changeLogs?.map((source) => {
                                return (
                                    <div
                                        key={source.id}
                                        style={{
                                            display: 'flex',
                                            justifyContent: 'space-between',
                                            padding: '0.5em 0',
                                        }}
                                    >
                                        <div>{source.title}</div>
                                        <div>
                                            {FormatCurrency(
                                                (source.revenue || 0) -
                                                    (source.expenses || 0)
                                            )}
                                        </div>
                                    </div>
                                )
                            })}
                        </div>
                    ) : null}
                </div>
            </div>
        )
    }
)
