import { observable, computed, action, makeObservable } from 'mobx'
import Collection from '../Collections/Collection'
import DailyAllocationAggregateModel from './DailyAllocationAggregateModel'
import Aggregate from './Aggregate'
import bind from 'bind-decorator'
import tuple from 'immutable-tuple'

class DailyAllocationAggregate extends Aggregate {
    constructor() {
        super({ collectionClass: DailyAllocationAggregateCollection })
        makeObservable(this)
    }
}

class DailyAllocationAggregateCollection extends Collection {
    constructor() {
        super({
            collection: 'dailyAllocations',
            modelClass: DailyAllocationAggregateModel,
        })
        this.addLookup('dailyAllocationsByProjectId', 'manyByKey', {
            key: (t) => t.projectId,
            filter: (t) => !t.deletedAt,
        })
        this.addLookup('dailyAllocationsByPhaseId', 'manyByKey', {
            key: (t) => t.phaseId,
            filter: (t) => !t.deletedAt,
        })
        this.addLookup('dailyAllocationsByStaffId', 'manyByKey', {
            key: (t) => t.staffId,
            filter: (t) => !t.deletedAt,
        })
        this.addLookup('dailyAllocationsByRoleId', 'manyByKey', {
            key: (t) => t.roleId || t.staff?.roleId,
            filter: (t) => !t.deletedAt,
        })
        this.addLookup('dailyAllocationsByMonth', 'manyByKey', {
            key: (t) => t.month,
            filter: (t) => !t.deletedAt,
        })
        this.addLookup('dailyAllocationsByModelTuple', 'manyByMany', {
            keys: (t) => [
                tuple(t.project?.id, undefined, undefined, undefined),
                tuple(undefined, t.phase?.id, undefined, undefined),
                tuple(undefined, undefined, t.role?.id, undefined),
                tuple(undefined, undefined, undefined, t.staff?.id),

                tuple(t.project?.id, t.phase?.id, undefined, undefined),
                tuple(t.project?.id, undefined, t.role?.id, undefined),
                tuple(t.project?.id, undefined, undefined, t.staff?.id),
                tuple(undefined, t.phase?.id, t.role?.id, undefined),
                tuple(undefined, t.phase?.id, undefined, t.staff?.id),
                tuple(undefined, undefined, t.role?.id, t.staff?.id),

                tuple(t.project?.id, t.phase?.id, t.role?.id, undefined),
                tuple(t.project?.id, t.phase?.id, undefined, t.staff?.id),
                tuple(t.project?.id, undefined, t.role?.id, t.staff?.id),
                tuple(undefined, t.phase?.id, t.role?.id, t.staff?.id),

                tuple(t.project?.id, t.phase?.id, t.role?.id, t.staff?.id),
            ],
            filter: (t) => !t.deletedAt,
        })
        makeObservable(this)
    }
    get dailyAllocationsById() {
        return this.modelsById
    }
    get dailyAllocations() {
        return this.models
    }
    get dailyAllocationsById() {
        return this.modelsById
    }
    @action.bound
    addDailyAllocation(data) {
        this.add(data)
    }
    @action.bound
    addDailyAllocations(data) {
        this.addMany(data)
    }
}

export default new DailyAllocationAggregate()
export const DailyAllocationAggregateCollectionClass =
    DailyAllocationAggregateCollection
