import { Link } from '@tanstack/react-router'
import { type ColumnDef } from '@tanstack/react-table'
import Formatter from '@/Components/Formatter'
import SessionStore from '@/State/SessionStore'
import {
    canViewProjectInvoices,
    canViewProjectStaffCost,
    canViewProjectFees,
    canViewProjectExpenseBudgets,
    canViewCostCentres,
    canViewContacts,
    canViewPrimaryContact,
    canViewProjectNotes,
    canViewStaffAllocations,
    canViewProjectExpenses,
    canViewProjectStaffPay,
    canViewProjectStaffChargeOut,
    canViewRevenueTargets,
} from '@/State/Permissions/HasPermissions'
import ProgressBar from '@/Components/Widgets/ProgresBar2'
import ProjectCollection from '@/State/Collections/ProjectCollection'
import CostCentreCollection from '@/State/Collections/CostCentreCollection'
import ContactCollection from '@/State/Collections/ContactCollection'
import StaffCollection from '@/State/Collections/StaffCollection'
import CellComponent from '@/Components/CellComponent'
import { DataTableColumnHeader } from '@/version2/components/data-table/data-table-column-header'
import { ChevronRight } from 'lucide-react'
import _ from 'lodash'
import { formatNumber0 } from '@/utils'
const renderValue = (value, type, permissions, row) => {
    if (permissions !== undefined && !permissions(row)) {
        value = null
    }
    switch (type) {
        case 'currency':
            return CellComponent.currency({
                value,
                formattedValue: Formatter.currency(value),
            })
        case 'percent':
            return CellComponent.percent({
                value,
                formattedValue: Formatter.percent(value),
            })
        case 'date': {
            return Formatter.date(value ? new Date(value) : null)
        }
        case 'number': {
            return CellComponent.number({
                value,
                formattedValue: Formatter.number(value),
            })
        }
        case 'status': {
            return Formatter.status(value)
        }
        case 'progressBar':
            return (
                <ProgressBar
                    width="100%"
                    height="1.5em"
                    numerator={formatNumber0(value?.numerator)}
                    denominator={formatNumber0(value?.denominator)}
                    formatNumber={Formatter.number}
                />
            )
        default:
            return value ? String(value) : ''
    }
}

export function getColumns({ setIsAlertChangeColumns = null }): ColumnDef<any>[] {
    return [
        {
            id: 'expand',
            header: ({ table }) => {
                const isAllExpanded = table.getIsAllRowsExpanded()
                return (
                    <div
                        className="cursor-pointer"
                        onClick={() => table.toggleAllRowsExpanded()}
                    >
                        <ChevronRight
                            size={18}
                            className={`transition-transform duration-300 ${isAllExpanded ? 'rotate-90' : ''}`}
                        />
                    </div>
                )
            },
            cell: ({ row }) => {
                return row.getCanExpand() ? (
                    <div
                        className="cursor-pointer"
                        onClick={() => row.toggleExpanded()}
                    >
                        <ChevronRight
                            size={18}
                            className={`transition-transform duration-300 ${row.getIsExpanded() ? 'rotate-90' : ''
                                }`}
                        />
                    </div>
                ) : null
            },
            size: 34,
            minSize: 34,
        },
        {
            accessorKey: 'name',
            header: ({ column }) => (
                <DataTableColumnHeader column={column} setIsAlertChangeColumns={setIsAlertChangeColumns} title="Name" />
            ),
            cell: ({ getValue, row }) => {
                const value = getValue()
                return (
                    <div
                        style={{
                            paddingLeft: `${1 * row.original.groupLevel}rem`,
                            width: '100%',
                        }}
                    >
                        {row.original.children?.length ? (
                            <Link to={`/projects/${row.original.projectId}`}>
                                {value}
                            </Link>
                        ) : (
                            value
                        )}
                    </div>
                )
            },
            size: 260,
            minSize: 260,
            maxSize: 260,
            meta: {
                type: 'text',
                title: 'Name',
                visible: true,
                filterOptions: {
                    placeholder: 'Filter by project name',
                    isDefaultFilter: true,
                },
            },
            sortingFn: 'sortingTextFn',
            filterFn: (row, columnId, filterValue) => {
                const value = row.getValue(columnId) as string
                if (row.original.children?.length) {
                    return value
                        .toLowerCase()
                        .includes((filterValue as string).toLowerCase())
                }
                return true
            },
            aggregationFn: 'titleDefault'
        },
        {
            accessorKey: 'actualVsBudgetedHours',
            header: ({ column }) => (
                <DataTableColumnHeader
                    column={column} setIsAlertChangeColumns={setIsAlertChangeColumns}
                    title="Actual / Budgeted Hours"
                />
            ),
            cell: ({ getValue, row, column }) => {
                return renderValue(
                    getValue(),
                    column.columnDef?.meta?.type,
                    column.columnDef?.meta?.permissions,
                    row.original
                )
            },
            size: 240,
            minSize: 240,
            maxSize: 240,
            meta: {
                type: 'progressBar',
                title: 'Actual / Budgeted Hours',
                visible: true,
            },
            sortingFn: 'sortingProgressFn',
            aggregationFn: 'progressBar'
        },
        {
            accessorKey: 'actualVsBudgetedExpenses',
            header: ({ column }) => (
                <DataTableColumnHeader
                    column={column} setIsAlertChangeColumns={setIsAlertChangeColumns}
                    title="Actual (Cost) / Budgeted Expenses"
                />
            ),
            cell: ({ getValue, row, column }) => {
                return renderValue(
                    getValue(),
                    column.columnDef?.meta?.type,
                    column.columnDef?.meta?.permissions,
                    row.original
                )
            },
            size: 240,
            minSize: 240,
            maxSize: 240,
            meta: {
                type: 'progressBar',
                title: 'Actual (Cost) / Budgeted Expenses',
                visible: true,
                permissions: (row) =>
                    canViewProjectStaffCost(
                        SessionStore.user,
                        ProjectCollection.projectsById[row?.projectId] || row
                    ) &&
                    canViewProjectExpenseBudgets(
                        SessionStore.user,
                        ProjectCollection.projectsById[row?.projectId] || row
                    ),
            },
            sortingFn: 'sortingProgressFn',
            aggregationFn: 'progressBar'
        },
        {
            accessorKey: 'revenueVsFee',
            header: ({ column }) => (
                <DataTableColumnHeader column={column} setIsAlertChangeColumns={setIsAlertChangeColumns} title="Revenue / Fee" />
            ),
            cell: ({ getValue, row, column }) => {
                return renderValue(
                    getValue(),
                    column.columnDef?.meta?.type,
                    column.columnDef?.meta?.permissions,
                    row.original
                )
            },
            size: 240,
            minSize: 240,
            maxSize: 240,
            meta: {
                type: 'progressBar',
                title: 'Revenue / Fee',
                visible: true,
                permissions: (row) =>
                    canViewProjectFees(
                        SessionStore.user,
                        ProjectCollection.projectsById[row?.projectId] || row
                    ) &&
                    canViewProjectInvoices(
                        SessionStore.user,
                        ProjectCollection.projectsById[row?.projectId] || row
                    ),
            },
            sortingFn: 'sortingProgressFn',
            aggregationFn: 'progressBar'
        },
        {
            accessorKey: 'profit',
            header: ({ column }) => (
                <DataTableColumnHeader
                    column={column} setIsAlertChangeColumns={setIsAlertChangeColumns}
                    title="Profit (cost + project expenses)"
                />
            ),
            cell: ({ getValue, row, column }) => {
                return renderValue(
                    getValue(),
                    column.columnDef?.meta?.type,
                    column.columnDef?.meta?.permissions,
                    row.original
                )
            },
            meta: {
                type: 'currency',
                title: 'Profit (cost + project expenses)',
                visible: true,
                permissions: (row) =>
                    canViewProjectInvoices(
                        SessionStore.user,
                        ProjectCollection.projectsById[row?.projectId] || row
                    ) &&
                    canViewProjectStaffCost(
                        SessionStore.user,
                        ProjectCollection.projectsById[row?.projectId] || row
                    ) &&
                    canViewProjectExpenses(
                        SessionStore.user,
                        ProjectCollection.projectsById[row?.projectId] || row
                    ),
            },
        },
        {
            accessorKey: 'profitMargin',
            header: ({ column }) => (
                <DataTableColumnHeader
                    column={column} setIsAlertChangeColumns={setIsAlertChangeColumns}
                    title="Profit margin (cost + project expenses)"
                />
            ),
            cell: ({ getValue, row, column }) => {
                return renderValue(
                    getValue(),
                    column.columnDef?.meta?.type,
                    column.columnDef?.meta?.permissions,
                    row.original
                )
            },
            meta: {
                type: 'percent',
                title: 'Profit margin (cost + project expenses)',
                visible: true,
                permissions: (row) =>
                    canViewProjectInvoices(
                        SessionStore.user,
                        ProjectCollection.projectsById[row?.projectId] || row
                    ) &&
                    canViewProjectStaffCost(
                        SessionStore.user,
                        ProjectCollection.projectsById[row?.projectId] || row
                    ) &&
                    canViewProjectExpenses(
                        SessionStore.user,
                        ProjectCollection.projectsById[row?.projectId] || row
                    ),
            },
            aggregationFn: 'mean'
        },
        {
            accessorKey: 'project',
            header: ({ column }) => (
                <DataTableColumnHeader column={column} setIsAlertChangeColumns={setIsAlertChangeColumns} title="Project" />
            ),
            cell: ({ getValue, row, column }) =>
                renderValue(
                    getValue(),
                    column.columnDef?.meta?.type,
                    column.columnDef?.meta?.permissions,
                    row.original
                ),
            meta: {
                type: 'project',
                title: 'Project',
                visible: false,
                filterOptions: {
                    options: _.sortBy(
                        ProjectCollection.projects.map((project: any) => {
                            return {
                                label: project.jobNumber
                                    ? `${project.jobNumber}: ${project.name}`
                                    : project.name,
                                value: project.id,
                                status: project.status,
                            }
                        }),
                        (project: any) => _.toLower(project.label)
                    ),
                    groupBy: (sm) => sm.status,
                    sortGroups: (a, b) => {
                        const statusOrder = [
                            'active',
                            'prospective',
                            'onHold',
                            'archived',
                        ]
                        return (
                            statusOrder.indexOf(a.label) -
                            statusOrder.indexOf(b.label)
                        )
                    },
                },
            },
            sortingFn: 'sortingTextFn',
        },
        {
            accessorKey: 'startDate',
            header: ({ column }) => (
                <DataTableColumnHeader column={column} setIsAlertChangeColumns={setIsAlertChangeColumns} title="Start Date" />
            ),
            cell: ({ getValue, row, column }) =>
                renderValue(
                    getValue(),
                    column.columnDef?.meta?.type,
                    column.columnDef?.meta?.permissions,
                    row.original
                ),
            meta: {
                type: 'date',
                title: 'Start Date',
                visible: false,
            },
            sortingFn: 'sortingDateFn',
            aggregationFn: 'min'
        },
        {
            accessorKey: 'endDate',
            header: ({ column }) => (
                <DataTableColumnHeader column={column} setIsAlertChangeColumns={setIsAlertChangeColumns} title="End Date" />
            ),
            cell: ({ getValue, row, column }) => {
                return renderValue(
                    getValue(),
                    column.columnDef?.meta?.type,
                    column.columnDef?.meta?.permissions,
                    row.original
                )
            },
            meta: {
                type: 'date',
                title: 'End Date',
                visible: false,
            },
            sortingFn: 'sortingDateFn',
            aggregationFn: 'max'
        },
        {
            accessorKey: 'fee',
            header: ({ column }) => (
                <DataTableColumnHeader column={column} setIsAlertChangeColumns={setIsAlertChangeColumns} title="Fee" />
            ),
            cell: ({ getValue, row, column }) =>
                renderValue(
                    getValue(),
                    column.columnDef?.meta?.type,
                    column.columnDef?.meta?.permissions,
                    row.original
                ),
            size: 100,
            minSize: 100,
            maxSize: 100,
            meta: {
                type: 'currency',
                title: 'Fee',
                visible: false,
                permissions: (row) =>
                    canViewProjectFees(
                        SessionStore.user,
                        ProjectCollection.projectsById[row?.projectId] || row
                    ),
            },
        },
        {
            accessorKey: 'expenseBudget',
            header: ({ column }) => (
                <DataTableColumnHeader column={column} setIsAlertChangeColumns={setIsAlertChangeColumns} title="Expense Budget" />
            ),
            cell: ({ getValue, row, column }) => {
                return renderValue(
                    getValue(),
                    column.columnDef?.meta?.type,
                    column.columnDef?.meta?.permissions,
                    row.original
                )
            },
            meta: {
                type: 'currency',
                title: 'Expense Budget',
                visible: false,
                permissions: (row) =>
                    canViewProjectExpenseBudgets(
                        SessionStore.user,
                        ProjectCollection.projectsById[row?.projectId] || row
                    ),
            },
        },
        {
            accessorKey: 'costCentre',
            header: ({ column }) => (
                <DataTableColumnHeader column={column} setIsAlertChangeColumns={setIsAlertChangeColumns} title="Cost centre" />
            ),
            cell: ({ getValue, row, column }) => {
                return renderValue(
                    getValue(),
                    column.columnDef?.meta?.type,
                    column.columnDef?.meta?.permissions,
                    row.original
                )
            },
            meta: {
                type: 'costCentre',
                title: 'Cost centre',
                visible: false,
                filterOptions: {
                    options: _.sortBy(
                        CostCentreCollection.costCentres.map(
                            (costCentre: any) => {
                                return {
                                    label: costCentre.name,
                                    value: costCentre.id,
                                }
                            }
                        ),
                        (costCentre: any) => _.toLower(costCentre.label)
                    ),
                },
                permissions: (row) => canViewCostCentres(SessionStore.user),
            },
            sortingFn: 'sortingTextFn',
        },
        {
            accessorKey: 'contact',
            header: ({ column }) => (
                <DataTableColumnHeader column={column} setIsAlertChangeColumns={setIsAlertChangeColumns} title="Contact" />
            ),
            cell: ({ getValue, row, column }) => {
                return renderValue(
                    getValue(),
                    column.columnDef?.meta?.type,
                    column.columnDef?.meta?.permissions,
                    row.original
                )
            },
            meta: {
                type: 'contact',
                title: 'Contact',
                visible: false,
                filterOptions: {
                    options: _.sortBy(
                        ContactCollection.contacts.map((contact: any) => {
                            return {
                                label: `(${contact.organisationName})`,
                                value: contact.id,
                            }
                        }),
                        (contact: any) => _.toLower(contact.label)
                    ),
                },
                permissions: (row) => canViewContacts(SessionStore.user),
            },
            sortingFn: 'sortingTextFn',
        },
        {
            accessorKey: 'invoiceContact',
            header: ({ column }) => (
                <DataTableColumnHeader
                    column={column} setIsAlertChangeColumns={setIsAlertChangeColumns}
                    title="Primary Contact"
                />
            ),
            cell: ({ getValue, row, column }) => {
                return renderValue(
                    getValue(),
                    column.columnDef?.meta?.type,
                    column.columnDef?.meta?.permissions,
                    row.original
                )
            },
            meta: {
                type: 'contact',
                title: 'Primary Contact',
                visible: false,
                filterOptions: {
                    options: _.sortBy(
                        ContactCollection.contacts.map((contact: any) => {
                            return {
                                label: `(${contact.organisationName})`,
                                value: contact.id,
                            }
                        }),
                        (contact: any) => _.toLower(contact.label)
                    ),
                },
                permissions: (row) =>
                    canViewContacts(SessionStore.user) &&
                    canViewPrimaryContact(),
            },
            sortingFn: 'sortingTextFn',
        },
        {
            accessorKey: 'owner',
            header: ({ column }) => (
                <DataTableColumnHeader column={column} setIsAlertChangeColumns={setIsAlertChangeColumns} title="Project Owner" />
            ),
            cell: ({ getValue, row, column }) => {
                return renderValue(
                    getValue(),
                    column.columnDef?.meta?.type,
                    column.columnDef?.meta?.permissions,
                    row.original
                )
            },
            meta: {
                type: 'staffMember',
                title: 'Project Owner',
                visible: false,
                filterOptions: {
                    options: _.sortBy(
                        StaffCollection.staffs.map((staff) => {
                            return {
                                label: `${staff.firstName} ${staff.lastName}`,
                                value: staff.id,
                                isArchived: staff.isArchived,
                            }
                        }),
                        (staff: any) => _.toLower(staff.label)
                    ),
                    groupBy: (sm) => (sm.isArchived ? 'Archived' : 'Active'),
                    sortGroups: (a, b) => (a.label === 'Archived' ? 1 : -1),
                },
            },
            sortingFn: 'sortingTextFn',
        },
        {
            accessorKey: 'status',
            header: ({ column }) => (
                <DataTableColumnHeader column={column} setIsAlertChangeColumns={setIsAlertChangeColumns} title="Status" />
            ),
            cell: ({ getValue, row, column }) => {
                return renderValue(
                    getValue(),
                    column.columnDef?.meta?.type,
                    column.columnDef?.meta?.permissions,
                    row.original
                )
            },
            meta: {
                type: 'status',
                title: 'Status',
                visible: false,
                filterOptions: {
                    options: [
                        { label: 'Active', value: 'active' },
                        { label: 'On Hold', value: 'onHold' },
                        { label: 'Prospective', value: 'prospective' },
                        { label: 'Archived', value: 'archived' },
                    ],
                },
            },
            filterFn: (row, columnId, filterValue) => {
                const rowValue = row.getValue(columnId)
                return (
                    typeof rowValue === 'string' &&
                    filterValue.includes(
                        rowValue.toLowerCase().replace(/\s+/g, '')
                    )
                )
            },
        },
        {
            accessorKey: 'remainingFee',
            header: ({ column }) => (
                <DataTableColumnHeader column={column} setIsAlertChangeColumns={setIsAlertChangeColumns} title="Remaining fee" />
            ),
            cell: ({ getValue, row, column }) => {
                return renderValue(
                    getValue(),
                    column.columnDef?.meta?.type,
                    column.columnDef?.meta?.permissions,
                    row.original
                )
            },
            meta: {
                type: 'currency',
                title: 'Remaining fee',
                visible: false,
                permissions: (row) =>
                    canViewProjectFees(
                        SessionStore.user,
                        ProjectCollection.projectsById[row?.projectId] || row
                    ) &&
                    canViewProjectInvoices(
                        SessionStore.user,
                        ProjectCollection.projectsById[row?.projectId] || row
                    ),
            },
        },
        {
            accessorKey: 'remainingExpenseProjectBudget',
            header: ({ column }) => (
                <DataTableColumnHeader
                    column={column} setIsAlertChangeColumns={setIsAlertChangeColumns}
                    title="Remaining Expense Budget"
                />
            ),
            cell: ({ getValue }) => getValue(),
            size: 100,
            minSize: 100,
            maxSize: 100,
            meta: {
                type: 'currency',
                title: 'Remaining Expense Budget',
                visible: false,
                permissions: (row) =>
                    canViewProjectExpenseBudgets(
                        SessionStore.user,
                        ProjectCollection.projectsById[row?.projectId] || row
                    ) &&
                    canViewProjectExpenses(
                        SessionStore.user,
                        ProjectCollection.projectsById[row?.projectId] || row
                    ) &&
                    canViewProjectStaffCost(
                        SessionStore.user,
                        ProjectCollection.projectsById[row?.projectId] || row
                    ),
            },
        },
        {
            accessorKey: 'latestEvent',
            header: ({ column }) => (
                <DataTableColumnHeader column={column} setIsAlertChangeColumns={setIsAlertChangeColumns} title="Latest Event" />
            ),
            cell: ({ getValue, row, column }) => {
                return renderValue(
                    getValue(),
                    column.columnDef?.meta?.type,
                    column.columnDef?.meta?.permissions,
                    row.original
                )
            },
            size: 100,
            minSize: 100,
            maxSize: 100,
            meta: {
                type: 'date',
                title: 'Latest Event',
                visible: false,
            },
            sortingFn: 'sortingDateFn',
        },
        {
            accessorKey: 'latestNote',
            header: ({ column }) => (
                <DataTableColumnHeader column={column} setIsAlertChangeColumns={setIsAlertChangeColumns} title="Latest Note" />
            ),
            cell: ({ getValue, row, column }) => {
                return renderValue(
                    getValue(),
                    column.columnDef?.meta?.type,
                    column.columnDef?.meta?.permissions,
                    row.original
                )
            },
            size: 350,
            minSize: 350,
            maxSize: 350,
            meta: {
                type: 'text',
                title: 'Latest Note',
                visible: false,
                permissions: (row) =>
                    canViewProjectNotes(
                        SessionStore.user,
                        ProjectCollection.projectsById[row?.projectId] || row
                    ),
            },
            sortingFn: 'sortingTextFn',
        },
        {
            accessorKey: 'currentPhases',
            header: ({ column }) => (
                <DataTableColumnHeader column={column} setIsAlertChangeColumns={setIsAlertChangeColumns} title="Current Phases" />
            ),
            cell: ({ getValue, row, column }) => {
                return renderValue(
                    getValue(),
                    column.columnDef?.meta?.type,
                    column.columnDef?.meta?.permissions,
                    row.original
                )
            },
            size: 100,
            minSize: 100,
            maxSize: 100,
            meta: {
                type: 'text',
                title: 'Current Phases',
                visible: false,
            },
            sortingFn: 'sortingTextFn',
        },
        {
            accessorKey: 'activePhases',
            header: ({ column }) => (
                <DataTableColumnHeader column={column} setIsAlertChangeColumns={setIsAlertChangeColumns} title="Active Phases" />
            ),
            cell: ({ getValue, row, column }) => {
                return renderValue(
                    getValue(),
                    column.columnDef?.meta?.type,
                    column.columnDef?.meta?.permissions,
                    row.original
                )
            },
            size: 100,
            minSize: 100,
            maxSize: 100,
            meta: {
                type: 'text',
                title: 'Active Phases',
                visible: false,
            },
            sortingFn: 'sortingTextFn',
        },
        {
            accessorKey: 'prospectivePhases',
            header: ({ column }) => (
                <DataTableColumnHeader
                    column={column} setIsAlertChangeColumns={setIsAlertChangeColumns}
                    title="Prospective Phases"
                />
            ),
            cell: ({ getValue, row, column }) => {
                return renderValue(
                    getValue(),
                    column.columnDef?.meta?.type,
                    column.columnDef?.meta?.permissions,
                    row.original
                )
            },
            size: 100,
            minSize: 100,
            maxSize: 100,
            meta: {
                type: 'text',
                title: 'Prospective Phases',
                visible: false,
            },
            sortingFn: 'sortingTextFn',
        },
        {
            accessorKey: 'staffMembers',
            header: ({ column }) => (
                <DataTableColumnHeader
                    column={column} setIsAlertChangeColumns={setIsAlertChangeColumns}
                    title="Staff members assigned"
                />
            ),
            cell: ({ getValue, row, column }) => {
                return renderValue(
                    getValue(),
                    column.columnDef?.meta?.type,
                    column.columnDef?.meta?.permissions,
                    row.original
                )
            },
            size: 100,
            minSize: 100,
            maxSize: 100,
            meta: {
                type: 'staffs',
                title: 'Staff members assigned',
                visible: false,
                filterOptions: {
                    options: _.sortBy(
                        StaffCollection.staffs.map((staff) => {
                            return {
                                label: `${staff.firstName} ${staff.lastName}`,
                                value: staff.id,
                                isArchived: staff.isArchived,
                            }
                        }),
                        (staff: any) => _.toLower(staff.label)
                    ),
                    groupBy: (sm) => (sm.isArchived ? 'Archived' : 'Active'),
                    sortGroups: (a, b) => (a.label === 'Archived' ? 1 : -1),
                },
            },
            sortingFn: 'sortingTextFn',
        },
        {
            accessorKey: 'progress',
            header: ({ column }) => (
                <DataTableColumnHeader column={column} setIsAlertChangeColumns={setIsAlertChangeColumns} title="Progress" />
            ),
            cell: ({ getValue, row, column }) => {
                return renderValue(
                    getValue(),
                    column.columnDef?.meta?.type,
                    column.columnDef?.meta?.permissions,
                    row.original
                )
            },
            size: 100,
            minSize: 100,
            maxSize: 100,
            meta: {
                type: 'percent',
                title: 'Progress',
                visible: false,
                permissions: (row) =>
                    canViewProjectInvoices(
                        SessionStore.user,
                        ProjectCollection.projectsById[row?.projectId] || row
                    ) &&
                    canViewProjectFees(
                        SessionStore.user,
                        ProjectCollection.projectsById[row?.projectId] || row
                    ),
            },
            aggregationFn: 'mean'
        },
        {
            accessorKey: 'revenue',
            header: ({ column }) => (
                <DataTableColumnHeader column={column} setIsAlertChangeColumns={setIsAlertChangeColumns} title="Revenue" />
            ),
            cell: ({ getValue, row, column }) => {
                return renderValue(
                    getValue(),
                    column.columnDef?.meta?.type,
                    column.columnDef?.meta?.permissions,
                    row.original
                )
            },
            size: 100,
            minSize: 100,
            maxSize: 100,
            meta: {
                type: 'currency',
                title: 'Revenue',
                visible: false,
                permissions: (row) =>
                    canViewProjectInvoices(
                        SessionStore.user,
                        ProjectCollection.projectsById[row?.projectId] || row
                    ),
            },
        },
        {
            accessorKey: 'revenueWithVariations',
            header: ({ column }) => (
                <DataTableColumnHeader
                    column={column} setIsAlertChangeColumns={setIsAlertChangeColumns}
                    title="Revenue (Variations)"
                />
            ),
            cell: ({ getValue, row, column }) => {
                return renderValue(
                    getValue(),
                    column.columnDef?.meta?.type,
                    column.columnDef?.meta?.permissions,
                    row.original
                )
            },
            size: 100,
            minSize: 100,
            maxSize: 100,
            meta: {
                type: 'currency',
                title: 'Revenue (Variations)',
                visible: false,
                permissions: (row) =>
                    canViewProjectInvoices(
                        SessionStore.user,
                        ProjectCollection.projectsById[row?.projectId] || row
                    ),
            },
        },
        {
            accessorKey: 'revenueWithVariationsReimbursements',
            header: ({ column }) => (
                <DataTableColumnHeader
                    column={column} setIsAlertChangeColumns={setIsAlertChangeColumns}
                    title="Revenue (Variations + Reimbursements)"
                />
            ),
            cell: ({ getValue, row, column }) => {
                return renderValue(
                    getValue(),
                    column.columnDef?.meta?.type,
                    column.columnDef?.meta?.permissions,
                    row.original
                )
            },
            size: 100,
            minSize: 100,
            maxSize: 100,
            meta: {
                type: 'currency',
                title: 'Revenue (Variations + Reimbursements)',
                visible: false,
                permissions: (row) =>
                    canViewProjectInvoices(
                        SessionStore.user,
                        ProjectCollection.projectsById[row?.projectId] || row
                    ),
            },
        },
        {
            accessorKey: 'recordedHours',
            header: ({ column }) => (
                <DataTableColumnHeader column={column} setIsAlertChangeColumns={setIsAlertChangeColumns} title="Recorded Hours" />
            ),
            cell: ({ getValue, row, column }) => {
                return renderValue(
                    getValue(),
                    column.columnDef?.meta?.type,
                    column.columnDef?.meta?.permissions,
                    row.original
                )
            },
            size: 100,
            minSize: 100,
            maxSize: 100,
            meta: {
                type: 'number',
                title: 'Recorded Hours',
                visible: false,
            },
        },
        {
            accessorKey: 'recordedHoursVariation',
            header: ({ column }) => (
                <DataTableColumnHeader
                    column={column} setIsAlertChangeColumns={setIsAlertChangeColumns}
                    title="Recorded Hours (Variation)"
                />
            ),
            cell: ({ getValue, row, column }) => {
                return renderValue(
                    getValue(),
                    column.columnDef?.meta?.type,
                    column.columnDef?.meta?.permissions,
                    row.original
                )
            },
            size: 100,
            minSize: 100,
            maxSize: 100,
            meta: {
                type: 'number',
                title: 'Recorded Hours (Variation)',
                visible: false,
            },
        },
        {
            accessorKey: 'hoursBudgeted',
            header: ({ column }) => (
                <DataTableColumnHeader column={column} setIsAlertChangeColumns={setIsAlertChangeColumns} title="Hours budgeted" />
            ),
            cell: ({ getValue, row, column }) => {
                return renderValue(
                    getValue(),
                    column.columnDef?.meta?.type,
                    column.columnDef?.meta?.permissions,
                    row.original
                )
            },
            size: 100,
            minSize: 100,
            maxSize: 100,
            meta: {
                type: 'number',
                title: 'Hours budgeted',
                visible: false,
            },
        },
        {
            accessorKey: 'hoursAllocated',
            header: ({ column }) => (
                <DataTableColumnHeader
                    column={column} setIsAlertChangeColumns={setIsAlertChangeColumns}
                    title="Hours allocated"
                />
            ),
            cell: ({ getValue, row, column }) => {
                return renderValue(
                    getValue(),
                    column.columnDef?.meta?.type,
                    column.columnDef?.meta?.permissions,
                    row.original
                )
            },
            size: 100,
            minSize: 100,
            maxSize: 100,
            meta: {
                type: 'number',
                title: 'Hours allocated',
                visible: false,
                permissions: (row) =>
                    canViewStaffAllocations(
                        SessionStore.user,
                        ProjectCollection.projectsById[row?.projectId] || row
                    ),
            },
        },
        {
            accessorKey: 'expenses',
            header: ({ column }) => (
                <DataTableColumnHeader
                    column={column} setIsAlertChangeColumns={setIsAlertChangeColumns}
                    title="Expenses (cost)"
                />
            ),
            cell: ({ getValue, row, column }) => {
                return renderValue(
                    getValue(),
                    column.columnDef?.meta?.type,
                    column.columnDef?.meta?.permissions,
                    row.original
                )
            },
            size: 100,
            minSize: 100,
            maxSize: 100,
            meta: {
                type: 'currency',
                title: 'Expenses (cost)',
                visible: false,
                permissions: (row) =>
                    canViewProjectStaffCost(
                        SessionStore.user,
                        ProjectCollection.projectsById[row?.projectId] || row
                    ),
            },
        },
        {
            accessorKey: 'projectExpenses',
            header: ({ column }) => (
                <DataTableColumnHeader
                    column={column} setIsAlertChangeColumns={setIsAlertChangeColumns}
                    title="Project Expenses"
                />
            ),
            cell: ({ getValue, row, column }) => {
                return renderValue(
                    getValue(),
                    column.columnDef?.meta?.type,
                    column.columnDef?.meta?.permissions,
                    row.original
                )
            },
            size: 100,
            minSize: 100,
            maxSize: 100,
            meta: {
                type: 'currency',
                title: 'Project Expenses',
                visible: false,
                permissions: (row) =>
                    canViewProjectExpenses(
                        SessionStore.user,
                        ProjectCollection.projectsById[row?.projectId] || row
                    ),
            },
        },
        {
            accessorKey: 'billableProjectExpenses',
            header: ({ column }) => (
                <DataTableColumnHeader
                    column={column} setIsAlertChangeColumns={setIsAlertChangeColumns}
                    title="Billable Project Expenses"
                />
            ),
            cell: ({ getValue, row, column }) => {
                return renderValue(
                    getValue(),
                    column.columnDef?.meta?.type,
                    column.columnDef?.meta?.permissions,
                    row.original
                )
            },
            size: 100,
            minSize: 100,
            maxSize: 100,
            meta: {
                type: 'currency',
                title: 'Billable Project Expenses',
                visible: false,
                permissions: (row) =>
                    canViewProjectExpenses(
                        SessionStore.user,
                        ProjectCollection.projectsById[row?.projectId] || row
                    ),
            },
        },
        {
            accessorKey: 'allProjectExpenses',
            header: ({ column }) => (
                <DataTableColumnHeader
                    column={column} setIsAlertChangeColumns={setIsAlertChangeColumns}
                    title="All Project Expenses"
                />
            ),
            cell: ({ getValue, row, column }) => {
                return renderValue(
                    getValue(),
                    column.columnDef?.meta?.type,
                    column.columnDef?.meta?.permissions,
                    row.original
                )
            },
            size: 100,
            minSize: 100,
            maxSize: 100,
            meta: {
                type: 'currency',
                title: 'All Project Expenses',
                visible: false,
                permissions: (row) =>
                    canViewProjectExpenses(
                        SessionStore.user,
                        ProjectCollection.projectsById[row?.projectId] || row
                    ),
            },
        },
        {
            accessorKey: 'expensesVariation',
            header: ({ column }) => (
                <DataTableColumnHeader
                    column={column} setIsAlertChangeColumns={setIsAlertChangeColumns}
                    title="Expenses (cost) + Variation Cost"
                />
            ),
            cell: ({ getValue, row, column }) => {
                return renderValue(
                    getValue(),
                    column.columnDef?.meta?.type,
                    column.columnDef?.meta?.permissions,
                    row.original
                )
            },
            size: 100,
            minSize: 100,
            maxSize: 100,
            meta: {
                type: 'currency',
                title: 'Expenses (cost) + Variation Cost',
                visible: false,
                permissions: (row) =>
                    canViewProjectStaffCost(
                        SessionStore.user,
                        ProjectCollection.projectsById[row?.projectId] || row
                    ),
            },
        },
        {
            accessorKey: 'expensesVariationProject',
            header: ({ column }) => (
                <DataTableColumnHeader
                    column={column} setIsAlertChangeColumns={setIsAlertChangeColumns}
                    title="Expenses (cost) + Variation Cost + Project Expenses"
                />
            ),
            cell: ({ getValue, row, column }) => {
                return renderValue(
                    getValue(),
                    column.columnDef?.meta?.type,
                    column.columnDef?.meta?.permissions,
                    row.original
                )
            },
            size: 100,
            minSize: 100,
            maxSize: 100,
            meta: {
                type: 'currency',
                title: 'Expenses (cost) + Variation Cost + Project Expenses',
                visible: false,
                permissions: (row) =>
                    canViewProjectStaffCost(
                        SessionStore.user,
                        ProjectCollection.projectsById[row?.projectId] || row
                    ) &&
                    canViewProjectExpenses(
                        SessionStore.user,
                        ProjectCollection.projectsById[row?.projectId] || row
                    ),
            },
        },
        {
            accessorKey: 'expensesProject',
            header: ({ column }) => (
                <DataTableColumnHeader
                    column={column} setIsAlertChangeColumns={setIsAlertChangeColumns}
                    title="Expenses (cost) + Project Expenses"
                />
            ),
            cell: ({ getValue, row, column }) => {
                return renderValue(
                    getValue(),
                    column.columnDef?.meta?.type,
                    column.columnDef?.meta?.permissions,
                    row.original
                )
            },
            size: 100,
            minSize: 100,
            maxSize: 100,
            meta: {
                type: 'currency',
                title: 'Expenses (cost) + Project Expenses',
                visible: false,
                permissions: (row) =>
                    canViewProjectStaffCost(
                        SessionStore.user,
                        ProjectCollection.projectsById[row?.projectId] || row
                    ) &&
                    canViewProjectExpenses(
                        SessionStore.user,
                        ProjectCollection.projectsById[row?.projectId] || row
                    ),
            },
        },
        {
            accessorKey: 'labourExpense',
            header: ({ column }) => (
                <DataTableColumnHeader column={column} setIsAlertChangeColumns={setIsAlertChangeColumns} title="Labour expense" />
            ),
            cell: ({ getValue, row, column }) => {
                return renderValue(
                    getValue(),
                    column.columnDef?.meta?.type,
                    column.columnDef?.meta?.permissions,
                    row.original
                )
            },
            size: 100,
            minSize: 100,
            maxSize: 100,
            meta: {
                type: 'currency',
                title: 'Labour expense',
                visible: false,
                permissions: (row) =>
                    canViewProjectStaffPay(
                        SessionStore.user,
                        ProjectCollection.projectsById[row?.projectId] || row
                    ),
            },
        },
        {
            accessorKey: 'chargeOut',
            header: ({ column }) => (
                <DataTableColumnHeader column={column} setIsAlertChangeColumns={setIsAlertChangeColumns} title="Charge-out" />
            ),
            cell: ({ getValue, row, column }) => {
                return renderValue(
                    getValue(),
                    column.columnDef?.meta?.type,
                    column.columnDef?.meta?.permissions,
                    row.original
                )
            },
            size: 100,
            minSize: 100,
            maxSize: 100,
            meta: {
                type: 'currency',
                title: 'Charge-out',
                visible: false,
                permissions: (row) =>
                    canViewProjectStaffChargeOut(
                        SessionStore.user,
                        ProjectCollection.projectsById[row?.projectId] || row
                    ),
            },
        },
        {
            accessorKey: 'chargeOutVariation',
            header: ({ column }) => (
                <DataTableColumnHeader
                    column={column} setIsAlertChangeColumns={setIsAlertChangeColumns}
                    title="Charge-out (Variations)"
                />
            ),
            cell: ({ getValue, row, column }) => {
                return renderValue(
                    getValue(),
                    column.columnDef?.meta?.type,
                    column.columnDef?.meta?.permissions,
                    row.original
                )
            },
            size: 100,
            minSize: 100,
            maxSize: 100,
            meta: {
                type: 'currency',
                title: 'Charge-out (Variations)',
                visible: false,
                permissions: (row) =>
                    canViewProjectStaffChargeOut(
                        SessionStore.user,
                        ProjectCollection.projectsById[row?.projectId] || row
                    ),
            },
        },
        {
            accessorKey: 'actualVsBudgetedHoursVariation',
            header: ({ column }) => (
                <DataTableColumnHeader
                    column={column} setIsAlertChangeColumns={setIsAlertChangeColumns}
                    title="Actual (Variation) / Budgeted Hours"
                />
            ),
            cell: ({ getValue, row, column }) => {
                return renderValue(
                    getValue(),
                    column.columnDef?.meta?.type,
                    column.columnDef?.meta?.permissions,
                    row.original
                )
            },
            size: 240,
            minSize: 240,
            maxSize: 240,
            meta: {
                type: 'progressBar',
                title: 'Actual (Variation) / Budgeted Hours',
                visible: false,
            },
            sortingFn: 'sortingProgressFn',
            aggregationFn: 'progressBar'
        },
        {
            accessorKey: 'actualProjectVsBudgetedExpenses',
            header: ({ column }) => (
                <DataTableColumnHeader
                    column={column} setIsAlertChangeColumns={setIsAlertChangeColumns}
                    title="Actual (Cost + Project) / Budgeted Expenses"
                />
            ),
            cell: ({ getValue, row, column }) => {
                return renderValue(
                    getValue(),
                    column.columnDef?.meta?.type,
                    column.columnDef?.meta?.permissions,
                    row.original
                )
            },
            size: 240,
            minSize: 240,
            maxSize: 240,
            meta: {
                type: 'progressBar',
                title: 'Actual (Cost + Project) / Budgeted Expenses',
                visible: false,
                permissions: (row) =>
                    canViewProjectStaffCost(
                        SessionStore.user,
                        ProjectCollection.projectsById[row?.projectId] || row
                    ) &&
                    canViewProjectExpenses(
                        SessionStore.user,
                        ProjectCollection.projectsById[row?.projectId] || row
                    ) &&
                    canViewProjectExpenseBudgets(
                        SessionStore.user,
                        ProjectCollection.projectsById[row?.projectId] || row
                    ),
            },
            sortingFn: 'sortingProgressFn',
            aggregationFn: 'progressBar'
        },
        {
            accessorKey: 'actualVariationVsBudgetedExpenses',
            header: ({ column }) => (
                <DataTableColumnHeader
                    column={column} setIsAlertChangeColumns={setIsAlertChangeColumns}
                    title="Actual (Cost) / Budgeted Expenses"
                />
            ),
            cell: ({ getValue, row, column }) => {
                return renderValue(
                    getValue(),
                    column.columnDef?.meta?.type,
                    column.columnDef?.meta?.permissions,
                    row.original
                )
            },
            size: 240,
            minSize: 240,
            maxSize: 240,
            meta: {
                type: 'progressBar',
                title: 'Actual (Cost) / Budgeted Expenses',
                visible: false,
                permissions: (row) =>
                    canViewProjectStaffCost(
                        SessionStore.user,
                        ProjectCollection.projectsById[row?.projectId] || row
                    ) &&
                    canViewProjectExpenseBudgets(
                        SessionStore.user,
                        ProjectCollection.projectsById[row?.projectId] || row
                    ),
            },
            sortingFn: 'sortingProgressFn',
            aggregationFn: 'progressBar'
        },
        {
            accessorKey: 'actualVariationProjectVsBudgetedExpenses',
            header: ({ column }) => (
                <DataTableColumnHeader
                    column={column} setIsAlertChangeColumns={setIsAlertChangeColumns}
                    title="Actual (Cost + Variation + Project) / Budgeted Expenses"
                />
            ),
            cell: ({ getValue, row, column }) => {
                return renderValue(
                    getValue(),
                    column.columnDef?.meta?.type,
                    column.columnDef?.meta?.permissions,
                    row.original
                )
            },
            size: 240,
            minSize: 240,
            maxSize: 240,
            meta: {
                type: 'progressBar',
                title: 'Actual (Cost + Variation + Project) / Budgeted Expenses',
                visible: false,
                permissions: (row) =>
                    canViewProjectStaffCost(
                        SessionStore.user,
                        ProjectCollection.projectsById[row?.projectId] || row
                    ) &&
                    canViewProjectExpenses(
                        SessionStore.user,
                        ProjectCollection.projectsById[row?.projectId] || row
                    ) &&
                    canViewProjectExpenseBudgets(
                        SessionStore.user,
                        ProjectCollection.projectsById[row?.projectId] || row
                    ),
            },
            sortingFn: 'sortingProgressFn',
            aggregationFn: 'progressBar'
        },
        {
            accessorKey: 'revenueVsChargeOut',
            header: ({ column }) => (
                <DataTableColumnHeader
                    column={column} setIsAlertChangeColumns={setIsAlertChangeColumns}
                    title="Revenue / Charge Out"
                />
            ),
            cell: ({ getValue, row, column }) => {
                return renderValue(
                    getValue(),
                    column.columnDef?.meta?.type,
                    column.columnDef?.meta?.permissions,
                    row.original
                )
            },
            size: 240,
            minSize: 240,
            maxSize: 240,
            meta: {
                type: 'progressBar',
                title: 'Revenue / Charge Out',
                visible: false,
                permissions: (row) =>
                    canViewProjectFees(
                        SessionStore.user,
                        ProjectCollection.projectsById[row?.projectId] || row
                    ) &&
                    canViewProjectStaffChargeOut(
                        SessionStore.user,
                        ProjectCollection.projectsById[row?.projectId] || row
                    ),
            },
            sortingFn: 'sortingProgressFn',
            aggregationFn: 'progressBar'
        },
        {
            accessorKey: 'percentRevenueVsFee',
            header: ({ column }) => (
                <DataTableColumnHeader
                    column={column} setIsAlertChangeColumns={setIsAlertChangeColumns}
                    title="Percent Revenue / Fee"
                />
            ),
            cell: ({ getValue, row, column }) => {
                return renderValue(
                    getValue(),
                    column.columnDef?.meta?.type,
                    column.columnDef?.meta?.permissions,
                    row.original
                )
            },
            size: 100,
            minSize: 100,
            maxSize: 100,
            meta: {
                type: 'percent',
                title: 'Percent Revenue / Fee',
                visible: false,
                permissions: (row) =>
                    canViewProjectFees(
                        SessionStore.user,
                        ProjectCollection.projectsById[row?.projectId] || row
                    ) &&
                    canViewProjectInvoices(
                        SessionStore.user,
                        ProjectCollection.projectsById[row?.projectId] || row
                    ),
            },
            aggregationFn: 'mean'
        },
        {
            accessorKey: 'costProjectVsFee',
            header: ({ column }) => (
                <DataTableColumnHeader
                    column={column} setIsAlertChangeColumns={setIsAlertChangeColumns}
                    title="Expenses (Cost + Project) / Fee"
                />
            ),
            cell: ({ getValue, row, column }) => {
                return renderValue(
                    getValue(),
                    column.columnDef?.meta?.type,
                    column.columnDef?.meta?.permissions,
                    row.original
                )
            },
            size: 240,
            minSize: 240,
            maxSize: 240,
            meta: {
                type: 'progressBar',
                title: 'Expenses (Cost + Project) / Fee',
                visible: false,
                permissions: (row) =>
                    canViewProjectStaffCost(
                        SessionStore.user,
                        ProjectCollection.projectsById[row?.projectId] || row
                    ) &&
                    canViewProjectExpenses(
                        SessionStore.user,
                        ProjectCollection.projectsById[row?.projectId] || row
                    ) &&
                    canViewProjectFees(
                        SessionStore.user,
                        ProjectCollection.projectsById[row?.projectId] || row
                    ),
            },
            sortingFn: 'sortingProgressFn',
            aggregationFn: 'progressBar'
        },
        {
            accessorKey: 'costVsFee',
            header: ({ column }) => (
                <DataTableColumnHeader
                    column={column} setIsAlertChangeColumns={setIsAlertChangeColumns}
                    title="Expense (cost) / Fee"
                />
            ),
            cell: ({ getValue, row, column }) => {
                return renderValue(
                    getValue(),
                    column.columnDef?.meta?.type,
                    column.columnDef?.meta?.permissions,
                    row.original
                )
            },
            size: 240,
            minSize: 240,
            maxSize: 240,
            meta: {
                type: 'progressBar',
                title: 'Expense (cost) / Fee',
                visible: false,
                permissions: (row) =>
                    canViewProjectStaffCost(
                        SessionStore.user,
                        ProjectCollection.projectsById[row?.projectId] || row
                    ) &&
                    canViewProjectFees(
                        SessionStore.user,
                        ProjectCollection.projectsById[row?.projectId] || row
                    ),
            },
            sortingFn: 'sortingProgressFn',
            aggregationFn: 'progressBar'
        },
        {
            accessorKey: 'payVsFee',
            header: ({ column }) => (
                <DataTableColumnHeader
                    column={column} setIsAlertChangeColumns={setIsAlertChangeColumns}
                    title="Expense (labour) / Fee"
                />
            ),
            cell: ({ getValue, row, column }) => {
                return renderValue(
                    getValue(),
                    column.columnDef?.meta?.type,
                    column.columnDef?.meta?.permissions,
                    row.original
                )
            },
            size: 240,
            minSize: 240,
            maxSize: 240,
            meta: {
                type: 'progressBar',
                title: 'Expense (labour) / Fee',
                visible: false,
                permissions: (row) =>
                    canViewProjectStaffPay(
                        SessionStore.user,
                        ProjectCollection.projectsById[row?.projectId] || row
                    ) &&
                    canViewProjectFees(
                        SessionStore.user,
                        ProjectCollection.projectsById[row?.projectId] || row
                    ),
            },
            sortingFn: 'sortingProgressFn',
            aggregationFn: 'progressBar'
        },
        {
            accessorKey: 'chargeOutVsFee',
            header: ({ column }) => (
                <DataTableColumnHeader
                    column={column} setIsAlertChangeColumns={setIsAlertChangeColumns}
                    title="Charge Out / Fee"
                />
            ),
            cell: ({ getValue, row, column }) => {
                return renderValue(
                    getValue(),
                    column.columnDef?.meta?.type,
                    column.columnDef?.meta?.permissions,
                    row.original
                )
            },
            size: 240,
            minSize: 240,
            maxSize: 240,
            meta: {
                type: 'progressBar',
                title: 'Charge Out / Fee',
                visible: false,
                permissions: (row) =>
                    canViewProjectStaffChargeOut(
                        SessionStore.user,
                        ProjectCollection.projectsById[row?.projectId] || row
                    ) &&
                    canViewProjectFees(
                        SessionStore.user,
                        ProjectCollection.projectsById[row?.projectId] || row
                    ),
            },
            sortingFn: 'sortingProgressFn',
            aggregationFn: 'progressBar'
        },
        {
            accessorKey: 'chargeOutVariationVsFee',
            header: ({ column }) => (
                <DataTableColumnHeader
                    column={column} setIsAlertChangeColumns={setIsAlertChangeColumns}
                    title="Charge Out (Variations) / Fee"
                />
            ),
            cell: ({ getValue, row, column }) => {
                return renderValue(
                    getValue(),
                    column.columnDef?.meta?.type,
                    column.columnDef?.meta?.permissions,
                    row.original
                )
            },
            size: 240,
            minSize: 240,
            maxSize: 240,
            meta: {
                type: 'progressBar',
                title: 'Charge Out (Variations) / Fee',
                visible: false,
                permissions: (row) =>
                    canViewProjectStaffChargeOut(
                        SessionStore.user,
                        ProjectCollection.projectsById[row?.projectId] || row
                    ) &&
                    canViewProjectFees(
                        SessionStore.user,
                        ProjectCollection.projectsById[row?.projectId] || row
                    ),
            },
            sortingFn: 'sortingProgressFn',
            aggregationFn: 'progressBar'
        },
        {
            accessorKey: 'percentOfTotalHours',
            header: ({ column }) => (
                <DataTableColumnHeader
                    column={column} setIsAlertChangeColumns={setIsAlertChangeColumns}
                    title="Percentage of total hours"
                />
            ),
            cell: ({ getValue, row, column }) => {
                return renderValue(
                    getValue(),
                    column.columnDef?.meta?.type,
                    column.columnDef?.meta?.permissions,
                    row.original
                )
            },
            size: 100,
            minSize: 100,
            maxSize: 100,
            meta: {
                type: 'percent',
                title: 'Percentage of total hours',
                visible: false,
            },
        },
        {
            accessorKey: 'percentOfTotalRevenue',
            header: ({ column }) => (
                <DataTableColumnHeader
                    column={column} setIsAlertChangeColumns={setIsAlertChangeColumns}
                    title="Percentage of total revenue"
                />
            ),
            cell: ({ getValue, row, column }) => {
                return renderValue(
                    getValue(),
                    column.columnDef?.meta?.type,
                    column.columnDef?.meta?.permissions,
                    row.original
                )
            },
            size: 100,
            minSize: 100,
            maxSize: 100,
            meta: {
                type: 'percent',
                title: 'Percentage of total revenue',
                visible: false,
                permissions: (row) =>
                    canViewProjectInvoices(
                        SessionStore.user,
                        ProjectCollection.projectsById[row?.projectId] || row
                    ),
            },
        },
        {
            accessorKey: 'percentOfTotalExpenses',
            header: ({ column }) => (
                <DataTableColumnHeader
                    column={column} setIsAlertChangeColumns={setIsAlertChangeColumns}
                    title="Percentage of total expenses (cost)"
                />
            ),
            cell: ({ getValue, row, column }) => {
                return renderValue(
                    getValue(),
                    column.columnDef?.meta?.type,
                    column.columnDef?.meta?.permissions,
                    row.original
                )
            },
            size: 100,
            minSize: 100,
            maxSize: 100,
            meta: {
                type: 'percent',
                title: 'Percentage of total expenses (cost)',
                visible: false,
                permissions: (row) =>
                    canViewProjectStaffCost(
                        SessionStore.user,
                        ProjectCollection.projectsById[row?.projectId] || row
                    ) &&
                    canViewProjectExpenses(
                        SessionStore.user,
                        ProjectCollection.projectsById[row?.projectId] || row
                    ),
            },
        },
        {
            accessorKey: 'percentOfTotalExpensesLabour',
            header: ({ column }) => (
                <DataTableColumnHeader
                    column={column} setIsAlertChangeColumns={setIsAlertChangeColumns}
                    title="Percentage of total expenses (labour)"
                />
            ),
            cell: ({ getValue, row, column }) => {
                return renderValue(
                    getValue(),
                    column.columnDef?.meta?.type,
                    column.columnDef?.meta?.permissions,
                    row.original
                )
            },
            size: 100,
            minSize: 100,
            maxSize: 100,
            meta: {
                type: 'percent',
                title: 'Percentage of total expenses (labour)',
                visible: false,
                permissions: (row) =>
                    canViewProjectStaffPay(
                        SessionStore.user,
                        ProjectCollection.projectsById[row?.projectId] || row
                    ),
            },
        },
        {
            accessorKey: 'profitCost',
            header: ({ column }) => (
                <DataTableColumnHeader column={column} setIsAlertChangeColumns={setIsAlertChangeColumns} title="Profit (cost)" />
            ),
            cell: ({ getValue, row, column }) => {
                return renderValue(
                    getValue(),
                    column.columnDef?.meta?.type,
                    column.columnDef?.meta?.permissions,
                    row.original
                )
            },
            size: 100,
            minSize: 100,
            maxSize: 100,
            meta: {
                type: 'currency',
                title: 'Profit (cost)',
                visible: false,
                permissions: (row) =>
                    canViewProjectInvoices(
                        SessionStore.user,
                        ProjectCollection.projectsById[row?.projectId] || row
                    ) &&
                    canViewProjectStaffCost(
                        SessionStore.user,
                        ProjectCollection.projectsById[row?.projectId] || row
                    ),
            },
        },
        {
            accessorKey: 'profitLabour',
            header: ({ column }) => (
                <DataTableColumnHeader
                    column={column} setIsAlertChangeColumns={setIsAlertChangeColumns}
                    title="Profit (labour)"
                />
            ),
            cell: ({ getValue, row, column }) => {
                return renderValue(
                    getValue(),
                    column.columnDef?.meta?.type,
                    column.columnDef?.meta?.permissions,
                    row.original
                )
            },
            size: 100,
            minSize: 100,
            maxSize: 100,
            meta: {
                type: 'currency',
                title: 'Profit (labour)',
                visible: false,
                permissions: (row) =>
                    canViewProjectInvoices(
                        SessionStore.user,
                        ProjectCollection.projectsById[row?.projectId] || row
                    ) &&
                    canViewProjectStaffPay(
                        SessionStore.user,
                        ProjectCollection.projectsById[row?.projectId] || row
                    ),
            },
        },
        {
            accessorKey: 'profitFee',
            header: ({ column }) => (
                <DataTableColumnHeader column={column} setIsAlertChangeColumns={setIsAlertChangeColumns} title="Profit (fee)" />
            ),
            cell: ({ getValue, row, column }) => {
                return renderValue(
                    getValue(),
                    column.columnDef?.meta?.type,
                    column.columnDef?.meta?.permissions,
                    row.original
                )
            },
            size: 100,
            minSize: 100,
            maxSize: 100,
            meta: {
                type: 'currency',
                title: 'Profit (fee)',
                visible: false,
                permissions: (row) =>
                    canViewProjectFees(
                        SessionStore.user,
                        ProjectCollection.projectsById[row?.projectId] || row
                    ) &&
                    canViewProjectStaffCost(
                        SessionStore.user,
                        ProjectCollection.projectsById[row?.projectId] || row
                    ) &&
                    canViewProjectExpenses(
                        SessionStore.user,
                        ProjectCollection.projectsById[row?.projectId] || row
                    ),
            },
        },
        {
            accessorKey: 'profitFeeVariation',
            header: ({ column }) => (
                <DataTableColumnHeader
                    column={column} setIsAlertChangeColumns={setIsAlertChangeColumns}
                    title="Profit (fee, variation & project expenses)"
                />
            ),
            cell: ({ getValue, row, column }) => {
                return renderValue(
                    getValue(),
                    column.columnDef?.meta?.type,
                    column.columnDef?.meta?.permissions,
                    row.original
                )
            },
            size: 100,
            minSize: 100,
            maxSize: 100,
            meta: {
                type: 'currency',
                title: 'Profit (fee, variation & project expenses)',
                visible: false,
                permissions: (row) =>
                    canViewProjectFees(
                        SessionStore.user,
                        ProjectCollection.projectsById[row?.projectId] || row
                    ) &&
                    canViewProjectStaffCost(
                        SessionStore.user,
                        ProjectCollection.projectsById[row?.projectId] || row
                    ) &&
                    canViewProjectExpenses(
                        SessionStore.user,
                        ProjectCollection.projectsById[row?.projectId] || row
                    ),
            },
        },
        {
            accessorKey: 'profitCostVariationProject',
            header: ({ column }) => (
                <DataTableColumnHeader
                    column={column} setIsAlertChangeColumns={setIsAlertChangeColumns}
                    title="Profit (cost, variation & project expenses)"
                />
            ),
            cell: ({ getValue, row, column }) => {
                return renderValue(
                    getValue(),
                    column.columnDef?.meta?.type,
                    column.columnDef?.meta?.permissions,
                    row.original
                )
            },
            size: 100,
            minSize: 100,
            maxSize: 100,
            meta: {
                type: 'currency',
                title: 'Profit (cost, variation & project expenses)',
                visible: false,
                permissions: (row) =>
                    canViewProjectInvoices(
                        SessionStore.user,
                        ProjectCollection.projectsById[row?.projectId] || row
                    ) &&
                    canViewProjectStaffCost(
                        SessionStore.user,
                        ProjectCollection.projectsById[row?.projectId] || row
                    ) &&
                    canViewProjectExpenses(
                        SessionStore.user,
                        ProjectCollection.projectsById[row?.projectId] || row
                    ),
            },
        },
        {
            accessorKey: 'profitMarginCost',
            header: ({ column }) => (
                <DataTableColumnHeader
                    column={column} setIsAlertChangeColumns={setIsAlertChangeColumns}
                    title="Profit margin (cost)"
                />
            ),
            cell: ({ getValue, row, column }) => {
                return renderValue(
                    getValue(),
                    column.columnDef?.meta?.type,
                    column.columnDef?.meta?.permissions,
                    row.original
                )
            },
            size: 100,
            minSize: 100,
            maxSize: 100,
            meta: {
                type: 'percent',
                title: 'Profit margin (cost)',
                visible: false,
                permissions: (row) =>
                    canViewProjectInvoices(
                        SessionStore.user,
                        ProjectCollection.projectsById[row?.projectId] || row
                    ) &&
                    canViewProjectStaffCost(
                        SessionStore.user,
                        ProjectCollection.projectsById[row?.projectId] || row
                    ),
            },
            aggregationFn: 'mean'
        },
        {
            accessorKey: 'profitMarginLabour',
            header: ({ column }) => (
                <DataTableColumnHeader
                    column={column} setIsAlertChangeColumns={setIsAlertChangeColumns}
                    title="Profit margin (labour)"
                />
            ),
            cell: ({ getValue, row, column }) => {
                return renderValue(
                    getValue(),
                    column.columnDef?.meta?.type,
                    column.columnDef?.meta?.permissions,
                    row.original
                )
            },
            size: 100,
            minSize: 100,
            maxSize: 100,
            meta: {
                type: 'percent',
                title: 'Profit margin (labour)',
                visible: false,
                permissions: (row) =>
                    canViewProjectInvoices(
                        SessionStore.user,
                        ProjectCollection.projectsById[row?.projectId] || row
                    ) &&
                    canViewProjectStaffPay(
                        SessionStore.user,
                        ProjectCollection.projectsById[row?.projectId] || row
                    ),
            },
            aggregationFn: 'mean'
        },
        {
            accessorKey: 'profitMarginFee',
            header: ({ column }) => (
                <DataTableColumnHeader
                    column={column} setIsAlertChangeColumns={setIsAlertChangeColumns}
                    title="Profit margin (fee)"
                />
            ),
            cell: ({ getValue, row, column }) => {
                return renderValue(
                    getValue(),
                    column.columnDef?.meta?.type,
                    column.columnDef?.meta?.permissions,
                    row.original
                )
            },
            size: 100,
            minSize: 100,
            maxSize: 100,
            meta: {
                type: 'percent',
                title: 'Profit margin (fee)',
                visible: false,
                permissions: (row) =>
                    canViewProjectFees(
                        SessionStore.user,
                        ProjectCollection.projectsById[row?.projectId] || row
                    ) &&
                    canViewProjectStaffCost(
                        SessionStore.user,
                        ProjectCollection.projectsById[row?.projectId] || row
                    ) &&
                    canViewProjectExpenses(
                        SessionStore.user,
                        ProjectCollection.projectsById[row?.projectId] || row
                    ),
            },
        },
        {
            accessorKey: 'profitMarginFeeVariation',
            header: ({ column }) => (
                <DataTableColumnHeader
                    column={column} setIsAlertChangeColumns={setIsAlertChangeColumns}
                    title="Profit margin (fee, variation & project expenses)"
                />
            ),
            cell: ({ getValue, row, column }) => {
                return renderValue(
                    getValue(),
                    column.columnDef?.meta?.type,
                    column.columnDef?.meta?.permissions,
                    row.original
                )
            },
            size: 100,
            minSize: 100,
            maxSize: 100,
            meta: {
                type: 'percent',
                title: 'Profit margin (fee, variation & project expenses)',
                visible: false,
                permissions: (row) =>
                    canViewProjectFees(
                        SessionStore.user,
                        ProjectCollection.projectsById[row?.projectId] || row
                    ) &&
                    canViewProjectStaffCost(
                        SessionStore.user,
                        ProjectCollection.projectsById[row?.projectId] || row
                    ) &&
                    canViewProjectExpenses(
                        SessionStore.user,
                        ProjectCollection.projectsById[row?.projectId] || row
                    ),
            },
        },
        {
            accessorKey: 'netMultiplier',
            header: ({ column }) => (
                <DataTableColumnHeader column={column} setIsAlertChangeColumns={setIsAlertChangeColumns} title="Net Multiplier" />
            ),
            cell: ({ getValue, row, column }) => {
                return renderValue(
                    getValue(),
                    column.columnDef?.meta?.type,
                    column.columnDef?.meta?.permissions,
                    row.original
                )
            },
            size: 100,
            minSize: 100,
            maxSize: 100,
            meta: {
                type: 'number',
                title: 'Net Multiplier',
                visible: false,
                permissions: (row) =>
                    canViewProjectInvoices(
                        SessionStore.user,
                        ProjectCollection.projectsById[row?.projectId] || row
                    ) &&
                    canViewProjectStaffCost(
                        SessionStore.user,
                        ProjectCollection.projectsById[row?.projectId] || row
                    ) &&
                    canViewProjectExpenses(
                        SessionStore.user,
                        ProjectCollection.projectsById[row?.projectId] || row
                    ),
            },
            aggregationFn: 'mean'
        },
        {
            accessorKey: 'markup',
            header: ({ column }) => (
                <DataTableColumnHeader
                    column={column} setIsAlertChangeColumns={setIsAlertChangeColumns}
                    title="Markup (cost + project expenses)"
                />
            ),
            cell: ({ getValue, row, column }) => {
                return renderValue(
                    getValue(),
                    column.columnDef?.meta?.type,
                    column.columnDef?.meta?.permissions,
                    row.original
                )
            },
            size: 100,
            minSize: 100,
            maxSize: 100,
            meta: {
                type: 'percent',
                title: 'Markup (cost + project expenses)',
                visible: false,
                permissions: (row) =>
                    canViewProjectInvoices(
                        SessionStore.user,
                        ProjectCollection.projectsById[row?.projectId] || row
                    ) &&
                    canViewProjectStaffCost(
                        SessionStore.user,
                        ProjectCollection.projectsById[row?.projectId] || row
                    ) &&
                    canViewProjectExpenses(
                        SessionStore.user,
                        ProjectCollection.projectsById[row?.projectId] || row
                    ),
            },
            aggregationFn: 'mean'
        },
        {
            accessorKey: 'markupCost',
            header: ({ column }) => (
                <DataTableColumnHeader column={column} setIsAlertChangeColumns={setIsAlertChangeColumns} title="Markup (cost)" />
            ),
            cell: ({ getValue, row, column }) => {
                return renderValue(
                    getValue(),
                    column.columnDef?.meta?.type,
                    column.columnDef?.meta?.permissions,
                    row.original
                )
            },
            size: 100,
            minSize: 100,
            maxSize: 100,
            meta: {
                type: 'percent',
                title: 'Markup (cost)',
                visible: false,
                permissions: (row) =>
                    canViewProjectInvoices(
                        SessionStore.user,
                        ProjectCollection.projectsById[row?.projectId] || row
                    ) &&
                    canViewProjectStaffCost(
                        SessionStore.user,
                        ProjectCollection.projectsById[row?.projectId] || row
                    ),
            },
            aggregationFn: 'mean'
        },
        {
            accessorKey: 'markupLabour',
            header: ({ column }) => (
                <DataTableColumnHeader
                    column={column} setIsAlertChangeColumns={setIsAlertChangeColumns}
                    title="Markup (labour)"
                />
            ),
            cell: ({ getValue, row, column }) => {
                return renderValue(
                    getValue(),
                    column.columnDef?.meta?.type,
                    column.columnDef?.meta?.permissions,
                    row.original
                )
            },
            size: 100,
            minSize: 100,
            maxSize: 100,
            meta: {
                type: 'percent',
                title: 'Markup (labour)',
                visible: false,
                permissions: (row) =>
                    canViewProjectInvoices(
                        SessionStore.user,
                        ProjectCollection.projectsById[row?.projectId] || row
                    ) &&
                    canViewProjectStaffPay(
                        SessionStore.user,
                        ProjectCollection.projectsById[row?.projectId] || row
                    ),
            },
            aggregationFn: 'mean'
        },
        {
            accessorKey: 'revenuePerHour',
            header: ({ column }) => (
                <DataTableColumnHeader
                    column={column} setIsAlertChangeColumns={setIsAlertChangeColumns}
                    title="Revenue per hour"
                />
            ),
            cell: ({ getValue, row, column }) => {
                return renderValue(
                    getValue(),
                    column.columnDef?.meta?.type,
                    column.columnDef?.meta?.permissions,
                    row.original
                )
            },
            size: 100,
            minSize: 100,
            maxSize: 100,
            meta: {
                type: 'currency',
                title: 'Revenue per hour',
                visible: false,
                permissions: (row) =>
                    canViewProjectInvoices(
                        SessionStore.user,
                        ProjectCollection.projectsById[row?.projectId] || row
                    ),
            },
            aggregationFn: 'mean'
        },
        {
            accessorKey: 'revenueVariationPerHour',
            header: ({ column }) => (
                <DataTableColumnHeader
                    column={column} setIsAlertChangeColumns={setIsAlertChangeColumns}
                    title="Revenue per hour (variations)"
                />
            ),
            cell: ({ getValue, row, column }) => {
                return renderValue(
                    getValue(),
                    column.columnDef?.meta?.type,
                    column.columnDef?.meta?.permissions,
                    row.original
                )
            },
            size: 100,
            minSize: 100,
            maxSize: 100,
            meta: {
                type: 'currency',
                title: 'Revenue per hour (variations)',
                visible: false,
                permissions: (row) =>
                    canViewProjectInvoices(
                        SessionStore.user,
                        ProjectCollection.projectsById[row?.projectId] || row
                    ),
            },
        },
        {
            accessorKey: 'revenueVariationReimbursementPerHour',
            header: ({ column }) => (
                <DataTableColumnHeader
                    column={column} setIsAlertChangeColumns={setIsAlertChangeColumns}
                    title="Revenue per hour (variations + reimbursements)"
                />
            ),
            cell: ({ getValue, row, column }) => {
                return renderValue(
                    getValue(),
                    column.columnDef?.meta?.type,
                    column.columnDef?.meta?.permissions,
                    row.original
                )
            },
            size: 100,
            minSize: 100,
            maxSize: 100,
            meta: {
                type: 'currency',
                title: 'Revenue per hour (variations + reimbursements)',
                visible: false,
                permissions: (row) =>
                    canViewProjectInvoices(
                        SessionStore.user,
                        ProjectCollection.projectsById[row?.projectId] || row
                    ),
            },
        },
        {
            accessorKey: 'completionDate',
            header: ({ column }) => (
                <DataTableColumnHeader
                    column={column} setIsAlertChangeColumns={setIsAlertChangeColumns}
                    title="Completion date"
                />
            ),
            cell: ({ getValue, row, column }) => {
                return renderValue(
                    getValue(),
                    column.columnDef?.meta?.type,
                    column.columnDef?.meta?.permissions,
                    row.original
                )
            },
            size: 100,
            minSize: 100,
            maxSize: 100,
            meta: {
                type: 'date',
                title: 'Completion date',
                visible: false,
                permissions: (row) =>
                    canViewProjectFees(
                        SessionStore.user,
                        ProjectCollection.projectsById[row?.projectId] || row
                    ) &&
                    canViewProjectInvoices(
                        SessionStore.user,
                        ProjectCollection.projectsById[row?.projectId] || row
                    ),
            },
            sortingFn: 'sortingDateFn',
        },
        {
            accessorKey: 'latestInvoicedPhases',
            header: ({ column }) => (
                <DataTableColumnHeader
                    column={column} setIsAlertChangeColumns={setIsAlertChangeColumns}
                    title="Latest Invoiced Phases"
                />
            ),
            cell: ({ getValue, row, column }) => {
                return renderValue(
                    getValue(),
                    column.columnDef?.meta?.type,
                    column.columnDef?.meta?.permissions,
                    row.original
                )
            },
            size: 150,
            minSize: 150,
            maxSize: 150,
            meta: {
                type: 'text',
                title: 'Latest Invoiced Phases',
                visible: false,
                permissions: (row) =>
                    canViewProjectInvoices(SessionStore.user, row),
            },
            sortingFn: 'sortingTextFn',
        },
        {
            accessorKey: 'latestRevenueDate',
            header: ({ column }) => (
                <DataTableColumnHeader
                    column={column} setIsAlertChangeColumns={setIsAlertChangeColumns}
                    title="Latest Revenue Date"
                />
            ),
            cell: ({ getValue, row, column }) => {
                return renderValue(
                    getValue(),
                    column.columnDef?.meta?.type,
                    column.columnDef?.meta?.permissions,
                    row.original
                )
            },
            size: 100,
            minSize: 100,
            maxSize: 100,
            meta: {
                type: 'date',
                title: 'Latest Revenue Date',
                visible: false,
                permissions: (row) =>
                    canViewProjectInvoices(SessionStore.user, row),
            },
            sortingFn: 'sortingDateFn',
        },
        {
            accessorKey: 'latestTimeEntryDate',
            header: ({ column }) => (
                <DataTableColumnHeader
                    column={column} setIsAlertChangeColumns={setIsAlertChangeColumns}
                    title="Latest Time Entry Date"
                />
            ),
            cell: ({ getValue, row, column }) => {
                return renderValue(
                    getValue(),
                    column.columnDef?.meta?.type,
                    column.columnDef?.meta?.permissions,
                    row.original
                )
            },
            size: 100,
            minSize: 100,
            maxSize: 100,
            meta: {
                type: 'date',
                title: 'Latest Time Entry Date',
                visible: false,
            },
            sortingFn: 'sortingDateFn',
        },
        {
            accessorKey: 'latestTimeEntryDateInRange',
            header: ({ column }) => (
                <DataTableColumnHeader
                    column={column} setIsAlertChangeColumns={setIsAlertChangeColumns}
                    title="Latest Time Entry Date In Range"
                />
            ),
            cell: ({ getValue, row, column }) => {
                return renderValue(
                    getValue(),
                    column.columnDef?.meta?.type,
                    column.columnDef?.meta?.permissions,
                    row.original
                )
            },
            size: 100,
            minSize: 100,
            maxSize: 100,
            meta: {
                type: 'date',
                title: 'Latest Time Entry Date In Range',
                visible: false,
            },
            sortingFn: 'sortingDateFn',
        },
        {
            accessorKey: 'projectedHours',
            header: ({ column }) => (
                <DataTableColumnHeader
                    column={column} setIsAlertChangeColumns={setIsAlertChangeColumns}
                    title="Projected Hours"
                />
            ),
            cell: ({ getValue, row, column }) => {
                return renderValue(
                    getValue(),
                    column.columnDef?.meta?.type,
                    column.columnDef?.meta?.permissions,
                    row.original
                )
            },
            size: 100,
            minSize: 100,
            maxSize: 100,
            meta: {
                type: 'number',
                title: 'Projected Hours',
                visible: false,
                permissions: (row) =>
                    canViewStaffAllocations(
                        SessionStore.user,
                        ProjectCollection.projectsById[row?.projectId] || row
                    ),
            },
        },
        {
            accessorKey: 'futureHours',
            header: ({ column }) => (
                <DataTableColumnHeader column={column} setIsAlertChangeColumns={setIsAlertChangeColumns} title="Future Hours" />
            ),
            cell: ({ getValue, row, column }) => {
                return renderValue(
                    getValue(),
                    column.columnDef?.meta?.type,
                    column.columnDef?.meta?.permissions,
                    row.original
                )
            },
            size: 100,
            minSize: 100,
            maxSize: 100,
            meta: {
                type: 'number',
                title: 'Future Hours',
                visible: false,
                permissions: (row) =>
                    canViewStaffAllocations(
                        SessionStore.user,
                        ProjectCollection.projectsById[row?.projectId] || row
                    ),
            },
        },
        {
            accessorKey: 'futureLabour',
            header: ({ column }) => (
                <DataTableColumnHeader
                    column={column} setIsAlertChangeColumns={setIsAlertChangeColumns}
                    title="Future Labour Expense"
                />
            ),
            cell: ({ getValue, row, column }) => {
                return renderValue(
                    getValue(),
                    column.columnDef?.meta?.type,
                    column.columnDef?.meta?.permissions,
                    row.original
                )
            },
            size: 100,
            minSize: 100,
            maxSize: 100,
            meta: {
                type: 'currency',
                title: 'Future Labour Expense',
                visible: false,
                permissions: (row) =>
                    canViewStaffAllocations(
                        SessionStore.user,
                        ProjectCollection.projectsById[row?.projectId] || row
                    ) &&
                    canViewProjectStaffPay(
                        SessionStore.user,
                        ProjectCollection.projectsById[row?.projectId] || row
                    ),
            },
        },
        {
            accessorKey: 'futureCost',
            header: ({ column }) => (
                <DataTableColumnHeader column={column} setIsAlertChangeColumns={setIsAlertChangeColumns} title="Future Expense" />
            ),
            cell: ({ getValue, row, column }) => {
                return renderValue(
                    getValue(),
                    column.columnDef?.meta?.type,
                    column.columnDef?.meta?.permissions,
                    row.original
                )
            },
            size: 100,
            minSize: 100,
            maxSize: 100,
            meta: {
                type: 'currency',
                title: 'Future Expense',
                visible: false,
                permissions: (row) =>
                    canViewStaffAllocations(
                        SessionStore.user,
                        ProjectCollection.projectsById[row?.projectId] || row
                    ) &&
                    canViewProjectStaffCost(
                        SessionStore.user,
                        ProjectCollection.projectsById[row?.projectId] || row
                    ),
            },
        },
        {
            accessorKey: 'futureChargeOut',
            header: ({ column }) => (
                <DataTableColumnHeader
                    column={column} setIsAlertChangeColumns={setIsAlertChangeColumns}
                    title="Future Charge Out"
                />
            ),
            cell: ({ getValue, row, column }) => {
                return renderValue(
                    getValue(),
                    column.columnDef?.meta?.type,
                    column.columnDef?.meta?.permissions,
                    row.original
                )
            },
            size: 100,
            minSize: 100,
            maxSize: 100,
            meta: {
                type: 'currency',
                title: 'Future Charge Out',
                visible: false,
                permissions: (row) =>
                    canViewStaffAllocations(
                        SessionStore.user,
                        ProjectCollection.projectsById[row?.projectId] || row
                    ) &&
                    canViewProjectStaffChargeOut(
                        SessionStore.user,
                        ProjectCollection.projectsById[row?.projectId] || row
                    ),
            },
        },
        {
            accessorKey: 'futureRevenue',
            header: ({ column }) => (
                <DataTableColumnHeader column={column} setIsAlertChangeColumns={setIsAlertChangeColumns} title="Future Revenue" />
            ),
            cell: ({ getValue, row, column }) => {
                return renderValue(
                    getValue(),
                    column.columnDef?.meta?.type,
                    column.columnDef?.meta?.permissions,
                    row.original
                )
            },
            size: 100,
            minSize: 100,
            maxSize: 100,
            meta: {
                type: 'currency',
                title: 'Future Revenue',
                visible: false,
                permissions: (row) =>
                    canViewRevenueTargets(
                        SessionStore.user,
                        ProjectCollection.projectsById[row?.projectId] || row
                    ),
            },
        },
        {
            accessorKey: 'projectedRevenue',
            header: ({ column }) => (
                <DataTableColumnHeader
                    column={column} setIsAlertChangeColumns={setIsAlertChangeColumns}
                    title="Projected Revenue"
                />
            ),
            cell: ({ getValue, row, column }) => {
                return renderValue(
                    getValue(),
                    column.columnDef?.meta?.type,
                    column.columnDef?.meta?.permissions,
                    row.original
                )
            },
            size: 100,
            minSize: 100,
            maxSize: 100,
            meta: {
                type: 'currency',
                title: 'Projected Revenue',
                visible: false,
                permissions: (row) =>
                    canViewProjectInvoices(
                        SessionStore.user,
                        ProjectCollection.projectsById[row?.projectId] || row
                    ) &&
                    canViewRevenueTargets(
                        SessionStore.user,
                        ProjectCollection.projectsById[row?.projectId] || row
                    ),
            },
        },
        {
            accessorKey: 'projectedExpense',
            header: ({ column }) => (
                <DataTableColumnHeader
                    column={column} setIsAlertChangeColumns={setIsAlertChangeColumns}
                    title="Projected Expense (Cost)"
                />
            ),
            cell: ({ getValue, row, column }) => {
                return renderValue(
                    getValue(),
                    column.columnDef?.meta?.type,
                    column.columnDef?.meta?.permissions,
                    row.original
                )
            },
            size: 100,
            minSize: 100,
            maxSize: 100,
            meta: {
                type: 'currency',
                title: 'Projected Expense (Cost)',
                visible: false,
                permissions: (row) =>
                    canViewStaffAllocations(
                        SessionStore.user,
                        ProjectCollection.projectsById[row?.projectId] || row
                    ) &&
                    canViewProjectStaffCost(
                        SessionStore.user,
                        ProjectCollection.projectsById[row?.projectId] || row
                    ),
            },
        },
        {
            accessorKey: 'projectedLabourExpense',
            header: ({ column }) => (
                <DataTableColumnHeader
                    column={column} setIsAlertChangeColumns={setIsAlertChangeColumns}
                    title="Projected Labour Expense"
                />
            ),
            cell: ({ getValue, row, column }) => {
                return renderValue(
                    getValue(),
                    column.columnDef?.meta?.type,
                    column.columnDef?.meta?.permissions,
                    row.original
                )
            },
            size: 100,
            minSize: 100,
            maxSize: 100,
            meta: {
                type: 'currency',
                title: 'Projected Labour Expense',
                visible: false,
                permissions: (row) =>
                    canViewStaffAllocations(
                        SessionStore.user,
                        ProjectCollection.projectsById[row?.projectId] || row
                    ) &&
                    canViewProjectStaffPay(
                        SessionStore.user,
                        ProjectCollection.projectsById[row?.projectId] || row
                    ),
            },
        },
        {
            accessorKey: 'projectedChargeOut',
            header: ({ column }) => (
                <DataTableColumnHeader
                    column={column} setIsAlertChangeColumns={setIsAlertChangeColumns}
                    title="Projected Charge Out"
                />
            ),
            cell: ({ getValue, row, column }) => {
                return renderValue(
                    getValue(),
                    column.columnDef?.meta?.type,
                    column.columnDef?.meta?.permissions,
                    row.original
                )
            },
            size: 100,
            minSize: 100,
            maxSize: 100,
            meta: {
                type: 'currency',
                title: 'Projected Charge Out',
                visible: false,
                permissions: (row) =>
                    canViewStaffAllocations(
                        SessionStore.user,
                        ProjectCollection.projectsById[row?.projectId] || row
                    ) &&
                    canViewProjectStaffChargeOut(
                        SessionStore.user,
                        ProjectCollection.projectsById[row?.projectId] || row
                    ),
            },
        },
        {
            accessorKey: 'projectedProfit',
            header: ({ column }) => (
                <DataTableColumnHeader
                    column={column} setIsAlertChangeColumns={setIsAlertChangeColumns}
                    title="Projected Profit"
                />
            ),
            cell: ({ getValue, row, column }) => {
                return renderValue(
                    getValue(),
                    column.columnDef?.meta?.type,
                    column.columnDef?.meta?.permissions,
                    row.original
                )
            },
            size: 100,
            minSize: 100,
            maxSize: 100,
            meta: {
                type: 'currency',
                title: 'Projected Profit',
                visible: false,
                permissions: (row) =>
                    canViewProjectInvoices(
                        SessionStore.user,
                        ProjectCollection.projectsById[row?.projectId] || row
                    ) &&
                    canViewRevenueTargets(
                        SessionStore.user,
                        ProjectCollection.projectsById[row?.projectId] || row
                    ) &&
                    canViewStaffAllocations(
                        SessionStore.user,
                        ProjectCollection.projectsById[row?.projectId] || row
                    ) &&
                    canViewProjectStaffCost(
                        SessionStore.user,
                        ProjectCollection.projectsById[row?.projectId] || row
                    ),
            },
        },
        {
            accessorKey: 'projectedProfitMargin',
            header: ({ column }) => (
                <DataTableColumnHeader
                    column={column} setIsAlertChangeColumns={setIsAlertChangeColumns}
                    title="Projected Profit Margin"
                />
            ),
            cell: ({ getValue, row, column }) => {
                return renderValue(
                    getValue(),
                    column.columnDef?.meta?.type,
                    column.columnDef?.meta?.permissions,
                    row.original
                )
            },
            size: 100,
            minSize: 100,
            maxSize: 100,
            meta: {
                type: 'percent',
                title: 'Projected Profit Margin',
                visible: false,
                permissions: (row) =>
                    canViewProjectInvoices(
                        SessionStore.user,
                        ProjectCollection.projectsById[row?.projectId] || row
                    ) &&
                    canViewRevenueTargets(
                        SessionStore.user,
                        ProjectCollection.projectsById[row?.projectId] || row
                    ) &&
                    canViewStaffAllocations(
                        SessionStore.user,
                        ProjectCollection.projectsById[row?.projectId] || row
                    ) &&
                    canViewProjectStaffCost(
                        SessionStore.user,
                        ProjectCollection.projectsById[row?.projectId] || row
                    ),
            },
            aggregationFn: 'mean'
        },
        {
            accessorKey: 'profitVariationProject',
            header: ({ column }) => (
                <DataTableColumnHeader
                    column={column} setIsAlertChangeColumns={setIsAlertChangeColumns}
                    title="Profit (Cost + Variation + Project)"
                />
            ),
            cell: ({ getValue, row, column }) => {
                return renderValue(
                    getValue(),
                    column.columnDef?.meta?.type,
                    column.columnDef?.meta?.permissions,
                    row.original
                )
            },
            size: 100,
            minSize: 100,
            maxSize: 100,
            meta: {
                type: 'currency',
                title: 'Profit (Cost + Variation + Project)',
                visible: false,
                permissions: (row) =>
                    canViewProjectInvoices(
                        SessionStore.user,
                        ProjectCollection.projectsById[row?.projectId] || row
                    ) &&
                    canViewProjectStaffCost(
                        SessionStore.user,
                        ProjectCollection.projectsById[row?.projectId] || row
                    ) &&
                    canViewProjectExpenses(
                        SessionStore.user,
                        ProjectCollection.projectsById[row?.projectId] || row
                    ),
            },
        },
        {
            accessorKey: 'profitMarginVariationProject',
            header: ({ column }) => (
                <DataTableColumnHeader
                    column={column} setIsAlertChangeColumns={setIsAlertChangeColumns}
                    title="Profit Margin (Cost + Variation + Project)"
                />
            ),
            cell: ({ getValue, row, column }) => {
                return renderValue(
                    getValue(),
                    column.columnDef?.meta?.type,
                    column.columnDef?.meta?.permissions,
                    row.original
                )
            },
            size: 100,
            minSize: 100,
            maxSize: 100,
            meta: {
                type: 'percent',
                title: 'Profit Margin (Cost + Variation + Project)',
                visible: false,
                permissions: (row) =>
                    canViewProjectInvoices(
                        SessionStore.user,
                        ProjectCollection.projectsById[row?.projectId] || row
                    ) &&
                    canViewProjectStaffCost(
                        SessionStore.user,
                        ProjectCollection.projectsById[row?.projectId] || row
                    ) &&
                    canViewProjectExpenses(
                        SessionStore.user,
                        ProjectCollection.projectsById[row?.projectId] || row
                    ),
            },
            aggregationFn: 'mean'
        },
        {
            accessorKey: 'markupVariationProject',
            header: ({ column }) => (
                <DataTableColumnHeader
                    column={column} setIsAlertChangeColumns={setIsAlertChangeColumns}
                    title="Markup (Cost + Variation + Project)"
                />
            ),
            cell: ({ getValue, row, column }) => {
                return renderValue(
                    getValue(),
                    column.columnDef?.meta?.type,
                    column.columnDef?.meta?.permissions,
                    row.original
                )
            },
            size: 100,
            minSize: 100,
            maxSize: 100,
            meta: {
                type: 'percent',
                title: 'Markup (Cost + Variation + Project)',
                visible: false,
                permissions: (row) =>
                    canViewProjectStaffCost(
                        SessionStore.user,
                        ProjectCollection.projectsById[row?.projectId] || row
                    ) &&
                    canViewProjectExpenses(
                        SessionStore.user,
                        ProjectCollection.projectsById[row?.projectId] || row
                    ),
            },
            aggregationFn: 'mean'
        },
        {
            accessorKey: 'expenseVsRevenueVariationProject',
            header: ({ column }) => (
                <DataTableColumnHeader
                    column={column} setIsAlertChangeColumns={setIsAlertChangeColumns}
                    title="Expenses / Revenue (Cost + Variation + Project)"
                />
            ),
            cell: ({ getValue, row, column }) => {
                return renderValue(
                    getValue(),
                    column.columnDef?.meta?.type,
                    column.columnDef?.meta?.permissions,
                    row.original
                )
            },
            size: 240,
            minSize: 240,
            maxSize: 240,
            meta: {
                type: 'progressBar',
                title: 'Expenses / Revenue (Cost + Variation + Project)',
                visible: false,
                format: (v) => Formatter.currency(v, { decimals: 0 }),
                permissions: (row) =>
                    canViewProjectStaffCost(
                        SessionStore.user,
                        ProjectCollection.projectsById[row?.projectId] || row
                    ) &&
                    canViewProjectExpenses(
                        SessionStore.user,
                        ProjectCollection.projectsById[row?.projectId] || row
                    ) &&
                    canViewProjectInvoices(
                        SessionStore.user,
                        ProjectCollection.projectsById[row?.projectId] || row
                    ),
            },
            sortingFn: 'sortingProgressFn',
            aggregationFn: 'progressBar'
        },
        {
            accessorKey: 'revenueVsExpenseVariationProject',
            header: ({ column }) => (
                <DataTableColumnHeader
                    column={column} setIsAlertChangeColumns={setIsAlertChangeColumns}
                    title="Revenue / Expenses (Cost + Variation + Project)"
                />
            ),
            cell: ({ getValue, row, column }) => {
                return renderValue(
                    getValue(),
                    column.columnDef?.meta?.type,
                    column.columnDef?.meta?.permissions,
                    row.original
                )
            },
            size: 240,
            minSize: 240,
            maxSize: 240,
            meta: {
                type: 'progressBar',
                title: 'Revenue / Expenses (Cost + Variation + Project)',
                visible: false,
                format: (v) => Formatter.currency(v, { decimals: 0 }),
                permissions: (row) =>
                    canViewProjectInvoices(
                        SessionStore.user,
                        ProjectCollection.projectsById[row?.projectId] || row
                    ) &&
                    canViewProjectStaffCost(
                        SessionStore.user,
                        ProjectCollection.projectsById[row?.projectId] || row
                    ) &&
                    canViewProjectExpenses(
                        SessionStore.user,
                        ProjectCollection.projectsById[row?.projectId] || row
                    ),
            },
            sortingFn: 'sortingProgressFn',
            aggregationFn: 'progressBar'
        },
        {
            accessorKey: 'profitVsRevenueVariationProject',
            header: ({ column }) => (
                <DataTableColumnHeader
                    column={column} setIsAlertChangeColumns={setIsAlertChangeColumns}
                    title="Profit / Revenue (Cost + Variation + Project)"
                />
            ),
            cell: ({ getValue, row, column }) => {
                return renderValue(
                    getValue(),
                    column.columnDef?.meta?.type,
                    column.columnDef?.meta?.permissions,
                    row.original
                )
            },
            size: 240,
            minSize: 240,
            maxSize: 240,
            meta: {
                type: 'progressBar',
                title: 'Profit / Revenue (Cost + Variation + Project)',
                visible: false,
                format: (v) => Formatter.currency(v, { decimals: 0 }),
                permissions: (row) =>
                    canViewProjectInvoices(
                        SessionStore.user,
                        ProjectCollection.projectsById[row?.projectId] || row
                    ) &&
                    canViewProjectStaffCost(
                        SessionStore.user,
                        ProjectCollection.projectsById[row?.projectId] || row
                    ) &&
                    canViewProjectExpenses(
                        SessionStore.user,
                        ProjectCollection.projectsById[row?.projectId] || row
                    ),
            },
            sortingFn: 'sortingProgressFn',
            aggregationFn: 'progressBar'
        },
        {
            accessorKey: 'profitVsExpenseVariationProject',
            header: ({ column }) => (
                <DataTableColumnHeader
                    column={column} setIsAlertChangeColumns={setIsAlertChangeColumns}
                    title="Profit / Expenses (Cost + Variation + Project)"
                />
            ),
            cell: ({ getValue, row, column }) => {
                return renderValue(
                    getValue(),
                    column.columnDef?.meta?.type,
                    column.columnDef?.meta?.permissions,
                    row.original
                )
            },
            size: 240,
            minSize: 240,
            maxSize: 240,
            meta: {
                type: 'progressBar',
                title: 'Profit / Expenses (Cost + Variation + Project)',
                visible: false,
                format: (v) => Formatter.currency(v, { decimals: 0 }),
                permissions: (row) =>
                    canViewProjectStaffCost(
                        SessionStore.user,
                        ProjectCollection.projectsById[row?.projectId] || row
                    ) &&
                    canViewProjectExpenses(
                        SessionStore.user,
                        ProjectCollection.projectsById[row?.projectId] || row
                    ),
            },
            sortingFn: 'sortingProgressFn',
            aggregationFn: 'progressBar'
        },
        {
            accessorKey: 'serviceFee',
            header: ({ column }) => (
                <DataTableColumnHeader column={column} setIsAlertChangeColumns={setIsAlertChangeColumns} title="Service Fee" />
            ),
            cell: ({ getValue, row, column }) => {
                return renderValue(
                    getValue(),
                    column.columnDef?.meta?.type,
                    column.columnDef?.meta?.permissions,
                    row.original
                )
            },
            size: 100,
            minSize: 100,
            maxSize: 100,
            meta: {
                type: 'currency',
                title: 'Service Fee',
                visible: false,
                permissions: (row) =>
                    canViewProjectFees(
                        SessionStore.user,
                        ProjectCollection.projectsById[row?.projectId] || row
                    ) &&
                    canViewProjectExpenses(
                        SessionStore.user,
                        ProjectCollection.projectsById[row?.projectId] || row
                    ),
            },
        },
        {
            accessorKey: 'serviceProfit',
            header: ({ column }) => (
                <DataTableColumnHeader column={column} setIsAlertChangeColumns={setIsAlertChangeColumns} title="Service Profit" />
            ),
            cell: ({ getValue, row, column }) => {
                return renderValue(
                    getValue(),
                    column.columnDef?.meta?.type,
                    column.columnDef?.meta?.permissions,
                    row.original
                )
            },
            size: 100,
            minSize: 100,
            maxSize: 100,
            meta: {
                type: 'currency',
                title: 'Service Profit',
                visible: false,
                permissions: (row) =>
                    canViewProjectFees(
                        SessionStore.user,
                        ProjectCollection.projectsById[row?.projectId] || row
                    ) &&
                    canViewProjectStaffCost(
                        SessionStore.user,
                        ProjectCollection.projectsById[row?.projectId] || row
                    ) &&
                    canViewProjectExpenses(
                        SessionStore.user,
                        ProjectCollection.projectsById[row?.projectId] || row
                    ),
            },
        },
        {
            accessorKey: 'serviceProfitMargin',
            header: ({ column }) => (
                <DataTableColumnHeader
                    column={column} setIsAlertChangeColumns={setIsAlertChangeColumns}
                    title="Service Profit Margin"
                />
            ),
            cell: ({ getValue, row, column }) => {
                return renderValue(
                    getValue(),
                    column.columnDef?.meta?.type,
                    column.columnDef?.meta?.permissions,
                    row.original
                )
            },
            size: 100,
            minSize: 100,
            maxSize: 100,
            meta: {
                type: 'percent',
                title: 'Service Profit Margin',
                visible: false,
                permissions: (row) =>
                    canViewProjectFees(
                        SessionStore.user,
                        ProjectCollection.projectsById[row?.projectId] || row
                    ) &&
                    canViewProjectStaffCost(
                        SessionStore.user,
                        ProjectCollection.projectsById[row?.projectId] || row
                    ) &&
                    canViewProjectExpenses(
                        SessionStore.user,
                        ProjectCollection.projectsById[row?.projectId] || row
                    ),
            },
            aggregationFn: 'mean'
        },
    ]
}
