import { observable, computed, action, makeObservable } from 'mobx'
import PhaseCollection from '../Collections/PhaseCollection'
import ProjectCollection from '../Collections/ProjectCollection'
import ExpenseRowCollection from '../Collections/ExpenseRowCollection'
import Model from './Model'
import MonthlyExpenseCellCollection from '../Collections/MonthlyExpenseCellCollection'
import _ from 'lodash'
import bind from 'bind-decorator'
import {
    addMonths,
    differenceInBusinessDays,
    endOfMonth,
    format,
    parse,
    startOfMonth,
    subMonths,
    subYears,
} from 'date-fns'
import RoleCollection from '../Collections/RoleCollection'
import StaffCollection from '../Collections/StaffCollection'
import { computedFn } from 'mobx-utils'
import ProjectExpenseCollection from '../Collections/ProjectExpenseCollection'

const cellsByGroup = {
    status: () => MonthlyExpenseCellCollection.cellsByStatus,
    project: () => MonthlyExpenseCellCollection.cellsByProjectId,
    phase: () => MonthlyExpenseCellCollection.cellsByPhaseId,
    expense: () => MonthlyExpenseCellCollection.cellsByExpenseName,
}

const propByGroup = {
    status: 'status',
    project: 'projectId',
    phase: 'phaseId',
    expense: 'expenseName',
}

const titlePropByGroup = {
    project: 'title',
    phase: 'title',
    staff: 'fullName',
    role: 'name',
    expense: 'expenseName',
}

class ExpenseRowModel extends Model {
    @observable label = null
    @observable fee = 0
    @observable hoursBudget = 0
    @observable expenseBudget = 0
    @observable expenses = {}
    @observable editedExpenses = {}
    @observable status = null
    @observable projectId = null
    @observable phaseId = null
    @observable expenseName = null
    @observable expenseStartDate = null
    @observable expenseEndDate = null
    @observable startDate = null
    @observable endDate = null
    @observable groups = ['status']

    constructor(data, options) {
        super()
        makeObservable(this)
        this.collection = ExpenseRowCollection
        this.init(data, options)
    }
    @computed
    get title() {
        const lastGroup = this.groups[this.groups.length - 1]
        if (!this.label || this.label === '') {
            const titleProp = titlePropByGroup[lastGroup]
            return titleProp ? this[lastGroup]?.[titleProp] : this[lastGroup]
        } else {
            return this.label
        }
    }

    @computed
    get isLeaf() {
        return (
            this.groups.includes('phase') &&
            this.groups.includes('project') &&
            this.groups.includes('status') &&
            this.groups.includes('expense')
        )
    }

    @computed
    get leafRows() {
        return this.isLeaf
            ? []
            : ExpenseRowCollection.leafRows.filter((r) => {
                  const matcher = this.matchers
                  return (
                      (!('phaseId' in matcher) || r.phaseId === this.phaseId) &&
                      (!('projectId' in matcher) ||
                          r.projectId === this.projectId) &&
                      (!('status' in matcher) || r.status === this.status) &&
                      (!('expense' in matcher) ||
                          r.expenseName === this.expenseName)
                  )
              })
    }

    @computed
    get level() {
        return this.groups.length - 1
    }

    @computed
    get childPhases() {
        return this.phase
            ? [this.phase]
            : [...new Set(this.leafRows.flatMap((c) => c.childPhases))].filter(
                  (p) => p
              )
    }

    @computed
    get childPhaseIds() {
        return this.phaseId
            ? [this.phaseId]
            : [
                  ...new Set(this.leafRows.flatMap((c) => c.childPhaseIds)),
              ].filter((p) => p)
    }

    @computed
    get childProjects() {
        return this.project
            ? [this.project]
            : [
                  ...new Set(this.leafRows.flatMap((c) => c.childProjects)),
              ].filter((p) => p)
    }

    @computed
    get childProjectIds() {
        return this.projectId
            ? [this.projectId]
            : [
                  ...new Set(this.leafRows.flatMap((c) => c.childProjectIds)),
              ].filter((p) => p)
    }

    @computed
    get childProjectExpenses() {
        return this.projectExpense
            ? [this.projectExpense]
            : [
                  ...new Set(
                      this.leafRows.flatMap((c) => c.childProjectExpenses)
                  ),
              ].filter((p) => p)
    }

    @computed
    get phase() {
        return PhaseCollection.phasesById[this.phaseId]
    }

    @computed
    get project() {
        return ProjectCollection.projectsById[this.projectId]
    }

    @computed
    get projectExpense() {
        return ProjectExpenseCollection.expensesByName[this.expenseName]
    }

    @computed
    get matchers() {
        let matchers = {}
        if (this.groups.includes('phase')) {
            matchers.phaseId = this.phaseId
        }
        if (this.groups.includes('project')) {
            matchers.projectId = this.projectId
        }
        if (this.groups.includes('status')) {
            matchers.status = this.status
        }
        if (this.groups.includes('expense')) {
            matchers.expenseName = this.expenseName
        }
        return matchers
    }

    @computed
    get expenseMonths() {
        if (!this.isLeaf && this.leafRows.length) {
            return _.uniq(this.leafRows.flatMap((r) => r.revenueMonths))
        }
        return _.uniq([
            ...Object.keys(this.expenses),
            ...Object.keys(this.editedExpenses),
        ])
    }

    getExpenseInMonth = computedFn(
        (month, expenseData = 'actualsProjected') => {
            if (!this.isLeaf && this.leafRows.length) {
                return _.sum(
                    this.leafRows.flatMap((r) =>
                        r.getExpenseInMonth(month, expenseData)
                    )
                )
            }
            return this.editedExpenses[month] ?? this.expenses[month] ?? 0
        }
    )

    getExpenseToDateInMonth = computedFn(
        (month, expenseData = 'actualsProjected') => {
            const prevExpenseMonths = this.expenseMonths.filter(
                (m) => m <= month
            )
            const expenseToDate = _.sum(
                prevExpenseMonths.map((m) =>
                    this.getExpenseInMonth(m, expenseData)
                )
            )
            return expenseToDate
        }
    )

    @bind
    getProgressInMonth(month, expenseData = 'actualsProjected') {
        return this.getExpenseToDateInMonth(month, expenseData) / this.budget
    }

    getTotalExpense = computedFn((expenseData = 'actualsProjected') => {
        return this.getExpenseToDateInMonth(
            format(addYears(new Date(), 100), 'yyyy-MM'),
            expenseData
        )
    })

    @action.bound
    setExpenseInMonth(month, v, expenseData = 'actualsProjected') {
        if (this.isLeaf) {
            this.editedExpenses[month] = v
            this.update({ editedExpenses: { ...this.editedExpenses } })
        }
        const leafRows = this.leafRows
        const leafRowsWithExpensesInMonth = leafRows.filter(
            (r) => r.getExpenseInMonth(month, expenseData) > 0
        )
        const existingExpenseInMonth = _.sum(
            leafRowsWithExpensesInMonth.map((r) =>
                r.getExpenseInMonth(month, expenseData)
            )
        )
        if (existingExpenseInMonth) {
            const ratio = v / existingExpenseInMonth
            leafRowsWithExpensesInMonth.forEach((r) =>
                r.setExpenseInMonth(
                    month,
                    r.getExpenseInMonth(month, expenseData) * ratio
                )
            )
        } else {
            leafRows.forEach((r) =>
                r.getExpenseInMonth(month, v / leafRows.length)
            )
        }
    }

    serialize() {
        return {
            id: this.id,
            editedExpenses: this.editedExpenses,
            projectId: this.projectId,
            phaseId: this.phaseId,
            expenseName: this.expenseName,
        }
    }
    serializeUpdates() {
        return this.serialize()
    }
}

export default ExpenseRowModel
