import { observable, computed, action, makeObservable } from 'mobx'
import OrganisationHolidayCollection from '../Collections/OrganisationHolidayCollection'
import Model from './Model'

class OrganisationHolidayModel extends Model {
    @observable name = null
    @observable startDate = null
    @observable endDate = null

    constructor(data, options) {
        super()
        makeObservable(this)
        this.collection = OrganisationHolidayCollection
        this.init(data, options)
    }
}

export default OrganisationHolidayModel
