import { observable, computed, action, makeObservable } from 'mobx'
import Collection from './Collection'
import InvoiceDescriptionTemplateModel from '../Models/InvoiceDescriptionTemplateModel'

class InvoiceDescriptionTemplateCollection extends Collection {
    constructor() {
        super({
            collection: 'invoiceDescriptionTemplates',
            modelClass: InvoiceDescriptionTemplateModel,
        })
        makeObservable(this)
    }
    get descriptionTemplatesById() {
        return this.modelsById
    }
    get descriptionTemplates() {
        return this.models
    }
    @action.bound
    addDescriptionTemplate(data) {
        this.add(data)
    }
    @action.bound
    addDescriptionTemplates(data) {
        this.addMany(data)
    }
}
export default new InvoiceDescriptionTemplateCollection()
export const InvoiceDescriptionTemplateCollectionClass =
    InvoiceDescriptionTemplateCollection
