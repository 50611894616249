import * as React from 'react'
import { flexRender, type Table as TanstackTable } from '@tanstack/react-table'

import { cn } from '@2/lib/utils'
import {
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableHeader,
    TableRow,
} from '@2/components/ui/table'
import LoadingSpinner from '@/Components/LoadingSpinner'

interface DataTableProps<TData> extends React.HTMLAttributes<HTMLDivElement> {
    table: TanstackTable<TData>
    floatingBar?: React.ReactNode | null
    /**
     * Whether to show the totals row
     */
    showTotals?: boolean
    loadingReportData?: boolean
}

export function DataTable<TData>({
    table,
    floatingBar = null,
    children,
    className,
    showTotals = false,
    loadingReportData = false,
    ...props
}: DataTableProps<TData>) {
    const totalWidth = table
        .getAllColumns()
        .filter((column) => column.getIsVisible())
        .reduce((acc, column) => acc + column.getSize(), 0)

    const rows = table.getRowModel().rows
    return (
        <>
            <div className="sticky left-0">{children}</div>
            {loadingReportData ? (
                <LoadingSpinner />
            ) : (
                <Table
                    className={cn(
                        `border-b border-border min-w-full print:!w-full relative table-fixed`
                    )}
                    style={{
                        width: `${totalWidth}px`,
                    }}
                >
                    <TableHeader className="sticky top-[-1px] bg-primary">
                        {table.getHeaderGroups().map((headerGroup) => (
                            <TableRow key={headerGroup.id}>
                                {headerGroup.headers.map((header) => (
                                    <TableHead
                                        key={header.id}
                                        style={{
                                            width: `${Math.round((header.column.getSize() / totalWidth) * 100)}%`,
                                        }}
                                    >
                                        {header.isPlaceholder
                                            ? null
                                            : flexRender(
                                                header.column.columnDef
                                                    .header,
                                                header.getContext()
                                            )}
                                    </TableHead>
                                ))}
                            </TableRow>
                        ))}
                    </TableHeader>
                    <TableBody>
                        {showTotals && (
                            <TableRow className="font-medium bg-muted">
                                {rows[0].getVisibleCells().map((cell) => {
                                    const aggregationFn =
                                        cell.column.getAggregationFn()
                                    const getValue = () =>
                                        aggregationFn
                                            ? aggregationFn(
                                                cell.column.id,
                                                rows,
                                                rows
                                            )
                                            : null
                                    return (
                                        <TableCell
                                            key={'total-' + cell.column.id}
                                            style={{
                                                width: `${Math.round((cell.column.getSize() / totalWidth) * 100)}%`,
                                            }}
                                        >
                                            {flexRender(
                                                cell.column.columnDef.id === 'expand' ? null : cell.column.columnDef.cell,
                                                {
                                                    ...cell.getContext(),
                                                    getValue,
                                                }
                                            )}
                                        </TableCell>
                                    )
                                })}
                            </TableRow>
                        )}
                        {table.getRowModel().rows?.length ? (
                            table.getRowModel().rows.map((row) => (
                                <TableRow
                                    key={row.id}
                                    data-state={
                                        row.getIsSelected() && 'selected'
                                    }
                                >
                                    {row.getVisibleCells().map((cell) => {
                                        return (
                                            <TableCell
                                                key={cell.id}
                                                className={cn({
                                                    'pr-0':
                                                        cell.column.id ===
                                                        'expand',
                                                })}
                                                style={{
                                                    width: `${Math.round((cell.column.getSize() / totalWidth) * 100)}%`,
                                                }}
                                            >
                                                {flexRender(
                                                    cell.column.columnDef.cell,
                                                    cell.getContext()
                                                )}
                                            </TableCell>
                                        )
                                    })}
                                </TableRow>
                            ))
                        ) : (
                            <TableRow>
                                <TableCell
                                    colSpan={table.getAllColumns().length}
                                    className="h-24 text-center"
                                >
                                    No results.
                                </TableCell>
                            </TableRow>
                        )}
                    </TableBody>
                </Table>
            )}
        </>
    )
}
