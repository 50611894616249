import { createFileRoute } from '@tanstack/react-router'
import CostCentreListPageHeader from '../../Pages/CostCentreListPage/CostCentreListPageHeader'
import CostCentreListPage from '../../Pages/CostCentreListPage/CostCentreListPage'
import PageBody from '../../Pages/Layout/PageBody'
import fetchData from '../../Queries/fetchData'
import LoadingSpinner from '../../Components/LoadingSpinner'
import ErrorComponent from '../../Components/ErrorComponent'
import { canViewRoute } from '../../State/Permissions/HasPermissions'
import SessionStore from '../../State/SessionStore'
import PermissionDenied from '../../Pages/PermissionDenied'

export const Route = createFileRoute('/_private/cost-centres/')({
    beforeLoad: ({ params }) => {
        if (!canViewRoute(SessionStore.user, 'costCentres', params))
            throw new Error('Permission Denied')
    },
    errorComponent: ({ error, reset }) => {
        if (error.message === 'Permission Denied') return <PermissionDenied />
        return <ErrorComponent error={error} reset={reset} />
    },
    loader: async () => await loader(),
    pendingComponent: LoadingSpinner,
    component: Wrapper,
})

const loader = async () => {
    const collections = [
        {
            collection: 'costCentres',
            fields: ['name', 'isBillable'],
        },
    ].filter(Boolean)
    return Promise.all(collections.map(fetchData))
}

function Wrapper() {
    return (
        <>
            <CostCentreListPageHeader />
            <PageBody>
                <CostCentreListPage />
            </PageBody>
        </>
    )
}
