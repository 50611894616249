import { observable, computed, action, makeObservable } from 'mobx'
import Collection from './Collection'
import ContactModel from '../Models/ContactModel'

class ContactCollection extends Collection {
    constructor() {
        super({ collection: 'contacts', modelClass: ContactModel })
        this.addLookup('contactsByAccountingSystemId', 'uniqueByKey', {
            key: (t) => t.accountingSystemContactId,
            filter: (t) => !t.deletedAt,
        })
        makeObservable(this)
    }
    get contactsById() {
        return this.modelsById
    }
    get contacts() {
        return this.models
    }
    @action.bound
    addContact(data) {
        this.add(data)
    }
    @action.bound
    addContacts(data) {
        this.addMany(data)
    }
    @action.bound
    createContact(options) {
        const contact = this.add({ trackUpdates: true, ...options })
        return contact
    }
}

export default new ContactCollection()
export const ContactCollectionClass = ContactCollection
