import React, { useEffect, useState } from 'react'
import { observer } from 'mobx-react'
import Modal from '../../Components/Modal'
import RenderOnQueries from '../Layout/RenderOnQueries'
import StaffCollection from '../../State/Collections/StaffCollection'
import TimeEntryCollection from '../../State/Collections/TimeEntryCollection'
import { Selector, TaskSelector } from '../../Components/Selector'
import { FilterTextBox } from '../../widgets'
import _ from 'lodash'
import classNames from 'classnames'
import InvoiceLineItemCollection from '../../State/Collections/InvoiceLineItemCollection'
import { format } from 'date-fns'
import getCombinedRateAtDate from '../../Utils/getCombinedRateAtDate'
import is from '@sindresorhus/is'

const dropdownOptions = [
    { label: 'any', value: null },
    { label: 'yes', value: true },
    { label: 'no', value: false },
]

const addSingleLineItem = (invoice, phase, timeEntries, position) => {
    const totalChargeOut = _.sum(timeEntries.map((te) => te.chargeOut))
    const totalHours = _.sum(timeEntries.map((te) => te.hours))
    const staff = [...new Set(timeEntries.map((te) => te.staff))]
    InvoiceLineItemCollection.add(
        {
            projectId: invoice.projectId,
            contactId: invoice.contactId,
            invoiceId: invoice.id,
            phaseId: phase?.id,
            billingType: timeEntries.every((te) => te.isVariation)
                ? 'variation'
                : 'agreedFee',
            lineItemType: 'timesheets',
            description: '[phase] hours',
            unitQuantity: totalHours,
            unitCost:
                totalChargeOut / totalHours ||
                _.mean(
                    staff.map((st) => {
                        return getCombinedRateAtDate(
                            {
                                project: invoice.project,
                                phase: phase,
                                staff: st,
                                role: st.role,
                            },
                            'chargeOut',
                            new Date()
                        )
                    })
                ),
            timesheetIds: timeEntries.map((te) => te.id),
            staffIds: staff.map((st) => st.id),
            isTaxed: true,
            position,
        },
        { trackUpdates: true }
    )
    timeEntries.forEach((te) => te.update({ beenInvoiced: true }))
}

const addPerStaffLineItem = (
    invoice,
    phase,
    timeEntries,
    selectedStaff,
    position
) => {
    const staff = [...new Set(selectedStaff)]
    staff.forEach((st) => {
        const staffTimeEntries = timeEntries.filter((te) => te.staff === st)
        const totalChargeOut = _.sum(staffTimeEntries.map((te) => te.chargeOut))
        const totalHours = _.sum(staffTimeEntries.map((te) => te.hours))
        InvoiceLineItemCollection.add(
            {
                projectId: invoice.projectId,
                contactId: invoice.contactId,
                invoiceId: invoice.id,
                phaseId: phase?.id,
                billingType: staffTimeEntries.every((te) => te.isVariation)
                    ? 'variation'
                    : 'agreedFee',
                lineItemType: 'timesheets',
                description: '[phase] - [staff]',
                unitQuantity: totalHours,
                unitCost:
                    totalChargeOut / totalHours ||
                    getCombinedRateAtDate(
                        {
                            project: invoice.project,
                            phase: phase,
                            staff: st,
                            role: st.role,
                        },
                        'chargeOut',
                        new Date()
                    ),
                timesheetIds: staffTimeEntries.map((te) => te.id),
                staffIds: [st.id],
                isTaxed: true,
                position,
            },
            { trackUpdates: true }
        )
        timeEntries.forEach((te) => te.update({ beenInvoiced: true }))
        position++
    })
}

const addPerTimeEntryLineItem = (invoice, phase, timeEntries, position) => {
    timeEntries
        .filter((te) => te.numMinutes > 0)
        .sort((a, b) => a.date - b.date)
        .forEach((te) => {
            InvoiceLineItemCollection.add(
                {
                    projectId: invoice.projectId,
                    contactId: invoice.contactId,
                    invoiceId: invoice.id,
                    phaseId: phase?.id,
                    billingType: te.isVariation ? 'variation' : 'agreedFee',
                    lineItemType: 'timesheets',
                    description:
                        `${format(te.date, 'dd/MM/yyyy')}: [phase] - [staff]` +
                        (te.notes?.length ? `\n${te.notes}` : ''),
                    unitQuantity: te.hours,
                    unitCost:
                        te.chargeOutRate ||
                        getCombinedRateAtDate(
                            {
                                project: te.project,
                                phase: te.phase,
                                staff: te.staff,
                                role: te.role,
                            },
                            'chargeOut',
                            te.date || new Date()
                        ),
                    timesheetIds: [te.id],
                    staffIds: [te.staffId],
                    isTaxed: true,
                    position,
                },
                { trackUpdates: true }
            )
            position++
        })
    timeEntries.forEach((te) => te.update({ beenInvoiced: true }))
}

export default observer(
    ({ modalId, invoice, project, phase, timeEntries, position }) => {
        const [task, setTask] = useState(null)
        const [billable, setBillable] = useState(true)
        const [variation, setVariation] = useState(null)
        const [staffFilter, setStaffFilter] = useState('')
        const filteredTimeEntries = []
        const timeEntryStaff = []
        const timeEntriesByStaffId = {}
        const timeEntryHoursByStaffId = {}
        timeEntries.forEach((te) => {
            if (
                !te.deletedAt &&
                !te.beenInvoiced &&
                te.staff &&
                te.project === project &&
                (phase ? te.phase === phase : true) &&
                te.date <= invoice.endDate &&
                te.date >= invoice.startDate &&
                (task ? te.task === task : true) &&
                (billable != null ? te.isBillable === billable : true) &&
                (variation != null ? te.isVariation === variation : true)
            ) {
                filteredTimeEntries.push(te)
                timeEntryStaff.push(te.staff)
                timeEntriesByStaffId[te.staffId] ??= []
                timeEntriesByStaffId[te.staffId].push(te)
                timeEntryHoursByStaffId[te.staffId] ??= 0
                timeEntryHoursByStaffId[te.staffId] += te.hours
            }
        })
        const staff = [
            ...new Set(
                [...StaffCollection.activeStaff, ...timeEntryStaff].filter(
                    (st) =>
                        staffFilter?.length
                            ? st.fullName
                                  .toLowerCase()
                                  .includes(staffFilter.toLowerCase())
                            : true
                )
            ),
        ]
        const [selectedStaff, setSelectedStaff] = useState(
            new Set(staff.filter((st) => timeEntryHoursByStaffId[st.id]))
        )
        return (
            <Modal
                modalId={modalId}
                heading="Add Timesheet Line Item"
                saveLabel={'Add Line Item'}
                saveOptions={[
                    { id: 'single', label: 'Grouping All Entries' },
                    { id: 'perStaff', label: 'Per Staff Member' },
                    { id: 'perTime', label: 'Per Time Entry' },
                ]}
                defaultSaveOption={'perStaff'}
                onSave={(saveOption) => {
                    switch (saveOption.id) {
                        case 'single':
                            return addSingleLineItem(
                                invoice,
                                phase,
                                filteredTimeEntries.filter((te) =>
                                    selectedStaff.has(te.staff)
                                ),
                                position
                            )
                        case 'perStaff':
                            return addPerStaffLineItem(
                                invoice,
                                phase,
                                filteredTimeEntries.filter((te) =>
                                    selectedStaff.has(te.staff)
                                ),
                                selectedStaff,
                                position
                            )
                        default:
                            return addPerTimeEntryLineItem(
                                invoice,
                                phase,
                                filteredTimeEntries.filter((te) =>
                                    selectedStaff.has(te.staff)
                                ),
                                position
                            )
                    }
                }}
                style={{ height: Math.min(staff.length, 15) * 3 + 17 + 'em' }}
                bodyStyle={{ padding: 0 }}
            >
                <RenderOnQueries
                    queryIds={[
                        {
                            collection: 'staff',
                            filters: ['isArchived != true'],
                            fields: [
                                'firstName',
                                'lastName',
                                'isArchived',
                                'roleId',
                                'inheritPayRate',
                                'inheritOvertimeRate',
                                'inheritCostRate',
                                'inheritChargeOutRate',
                            ],
                        },
                        {
                            collection: 'roles',
                            filters: ['isArchived != true'],
                            fields: ['name'],
                        },
                        {
                            collection: 'staffRates',
                            fields: [
                                'staffId',
                                'date',
                                'payRate',
                                'chargeOutRate',
                                'costRate',
                                'weeklyAvailability',
                                'overtimeRate',
                            ],
                        },
                        {
                            collection: 'roles',
                            fields: ['name'],
                        },
                        {
                            collection: 'roleRates',
                            fields: [
                                'roleId',
                                'date',
                                'payRate',
                                'chargeOutRate',
                                'costRate',
                                'overtimeRate',
                            ],
                        },
                        {
                            collection: 'projectRates',
                            filters: [`projectId == "${project.id}"`],
                            fields: [
                                'projectId',
                                'itemType',
                                'itemId',
                                'phaseId',
                                'date',
                                'costRate',
                                'chargeOutRate',
                            ],
                        },
                        {
                            collection: 'tasks',
                            filters: [`projectId == "${project.id}"`],
                            fields: [
                                'name',
                                'phaseId',
                                'projectId',
                                'isVariation',
                                'isBillable',
                            ],
                        },
                    ]}
                >
                    <div className="flex items-center justify-around p-3.5 border-b border-[#ccc]">
                        <div className="flex items-center">
                            <span
                                className="flex-0-0-auto"
                                style={{ marginRight: '1em' }}
                            >
                                Task:
                            </span>
                            <TaskSelector
                                className="task-selector flex-0-0-auto [&_span]:!text-xs"
                                style={{ maxWidth: '100%', width: '10em' }}
                                selectedTask={task}
                                taskOptions={phase.tasks || project.tasks}
                                onChange={setTask}
                                variant="secondary"
                            />
                        </div>
                        <div className="flex items-center">
                            <span
                                className="flex-0-0-auto"
                                style={{ marginRight: '1em' }}
                            >
                                Billable:
                            </span>
                            <Selector
                                selectedOption={dropdownOptions.find(
                                    (o) => o?.value === billable
                                )}
                                onChange={(o) => {
                                    const val = is.object(o) ? o?.value : o
                                    setBillable(val)
                                }}
                                options={dropdownOptions}
                                optionLabel={(o) => o?.label || 'any'}
                                style={{ width: '5em' }}
                                variant="secondary"
                                className="[&_span]:!text-xs"
                            />
                        </div>
                        <div className="flex items-center">
                            <span
                                className="flex-0-0-auto"
                                style={{ marginRight: '1em' }}
                            >
                                Variation:
                            </span>
                            <Selector
                                selectedOption={dropdownOptions.find(
                                    (o) => o.value === variation
                                )}
                                onChange={(o) => {
                                    const val = is.object(o) ? o?.value : o
                                    setVariation(val)
                                }}
                                options={dropdownOptions}
                                optionLabel={(o) => o?.label || 'any'}
                                style={{ width: '5em' }}
                                variant="secondary"
                                className="[&_span]:!text-xs"
                            />
                        </div>
                    </div>

                    <div className="p-3.5 border-b border-[#ccc]">
                        <FilterTextBox
                            value={staffFilter}
                            onChange={setStaffFilter}
                            placeholder="Filter Staff"
                            className="[&_input]:border-[#ccc] [&_input]:!text-black lg:!w-full"
                        />
                    </div>
                    <div
                        style={{
                            maxHeight: '40em',
                            overflowY: 'auto',
                        }}
                    >
                        {staff
                            .sort((a, b) =>
                                a.fullName?.localeCompare?.(b.fullName)
                            )
                            .map((st) => (
                                <label
                                    key={st.id}
                                    style={{
                                        borderBottom: 'solid 1px #eee',
                                    }}
                                    className={classNames(
                                        'column-selector__column',
                                        'column-select-list-item',
                                        'flex items-center justify-between mb-[7px] px-3.5 py-[7px] text-left cursor-pointer [&_.disabled]:cursor-default text-sm',
                                        selectedStaff.has(st)
                                            ? 'bg-[#f9dd72] hover:bg-[#f5c510]'
                                            : 'bg-white hover:bg-[#fdf5d4]'
                                    )}
                                >
                                    <div>
                                        <input
                                            type="checkbox"
                                            className="flex-0-0-auto"
                                            checked={selectedStaff.has(st)}
                                            onChange={(e) => {
                                                selectedStaff.has(st)
                                                    ? selectedStaff.delete(st)
                                                    : selectedStaff.add(st)
                                                setSelectedStaff(
                                                    new Set(selectedStaff)
                                                )
                                            }}
                                        />
                                        <span
                                            style={{ marginLeft: '1em' }}
                                            className="flex-0-0-auto"
                                        >
                                            {st.fullName}
                                        </span>
                                    </div>
                                    <span style={{ float: 'right' }}>
                                        ({timeEntryHoursByStaffId[st.id] || 0}{' '}
                                        hours)
                                    </span>
                                </label>
                            ))}
                    </div>
                </RenderOnQueries>
            </Modal>
        )
    }
)
