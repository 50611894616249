import { createFileRoute } from '@tanstack/react-router'
import PageBody from '../../Pages/Layout/PageBody'
import fetchData from '../../Queries/fetchData'
import { canViewRoute } from '../../State/Permissions/HasPermissions'
import SessionStore from '../../State/SessionStore'
import PermissionDenied from '../../Pages/PermissionDenied'
import TimesheetsDailyTab from '../../Pages/TimesheetsPage/TimesheetsDailyTab'
import TimesheetsPageHeader from '../../Pages/TimesheetsPage/TimesheetsPageHeader'
import TimesheetsWeeklyTab from '../../Pages/TimesheetsPage/TimesheetsWeeklyTab'
import LoadingSpinner from '../../Components/LoadingSpinner'
import ErrorComponent from '../../Components/ErrorComponent'

export const Route = createFileRoute('/_private/timesheets/')({
    beforeLoad: ({ params, search }) => {
        const activeTab = search?.tab || 'weekly'
        if (
            !canViewRoute(SessionStore.user, 'timesheets', params) ||
            !canViewRoute(SessionStore.user, `timesheets-${activeTab}`, params)
        )
            throw new Error('Permission Denied')
    },
    loaderDeps: ({ search: { tab, date, staffId } }) => ({
        tab,
        date,
        staffId,
    }),
    errorComponent: ({ error, reset }) => {
        if (error.message === 'Permission Denied') return <PermissionDenied />
        return <ErrorComponent error={error} reset={reset} />
    },
    component: Wrapper,
    pendingComponent: LoadingSpinner,
})

function Wrapper() {
    const { tab, date, staffId } = Route.useSearch()
    const params = Route.useParams()
    const activeTab = tab || 'weekly'
    const tabs = {
        daily: {
            label: 'Daily',
            id: 'daily',
            page: ({ date, staffId }) => (
                <TimesheetsDailyTab date={date} staffId={staffId} />
            ),
            permissions: () =>
                canViewRoute(SessionStore.user, `timesheets-daily`, params),
        },

        weekly: {
            label: 'Weekly',
            id: 'weekly',
            page: ({ date, staffId }) => (
                <TimesheetsWeeklyTab date={date} staffId={staffId} />
            ),
            permissions: () =>
                canViewRoute(SessionStore.user, `timesheets-weekly`, params),
        },
    }
    return (
        <>
            <TimesheetsPageHeader
                tabs={Object.values(tabs).filter((tab) => tab.permissions())}
                selectedTab={activeTab}
                date={date}
                staffId={staffId}
            />
            <PageBody>{tabs[activeTab].page({ date, staffId })}</PageBody>
        </>
    )
}
