import _ from 'lodash'

const getCombinedRateAtDate = (models, rateType, date) => {
    date ??= new Date()
    let { project, phase, staff, role } = models
    const projectId = project?.id
    const phaseId = phase?.id
    const staffId = staff?.id
    const roleId = role?.id || staff?.role?.id
    role ??= staff?.role
    const matchingProjectRates = (project?.rates || [])
        .filter(
            (x) =>
                x.date <= date &&
                (!x.phaseId || x.phase?.isRootPhase || x.phaseId === phaseId) &&
                (!x.itemId || staffId === x.itemId || roleId === x.itemId)
        )
        .sort((a, b) => b.date - a.date)
    const perfectPhaseMatches = matchingProjectRates.filter(
        (x) => x.phaseId === phaseId
    )
    const perfectPhaseStaffMatches = perfectPhaseMatches.filter(
        (x) => x.itemId === staffId
    )
    const perfectPhaseRoleMatches = perfectPhaseMatches.filter(
        (x) => x.itemId === roleId
    )
    const perfectStaffMatches = matchingProjectRates.filter(
        (x) => x.itemId === staffId
    )
    const perfectRoleMatches = matchingProjectRates.filter(
        (x) => x.itemId === roleId
    )
    const perfectPhaseMatch = perfectPhaseMatches[0] || null
    const perfectPhaseStaffMatch = perfectPhaseStaffMatches[0] || null
    const perfectPhaseRoleMatch = perfectPhaseRoleMatches[0] || null
    const perfectStaffMatch = perfectStaffMatches[0] || null
    const perfectRoleMatch = perfectRoleMatches[0] || null
    const projectMatch = matchingProjectRates[0] || null

    const latestProjectRate =
        perfectPhaseStaffMatch ||
        perfectPhaseRoleMatch ||
        perfectPhaseMatch ||
        perfectStaffMatch ||
        perfectRoleMatch ||
        projectMatch
    if (
        latestProjectRate &&
        ['cost', 'chargeOut'].includes(rateType) &&
        latestProjectRate?.[rateType + 'Rate'] > 0
    ) {
        return latestProjectRate?.[rateType + 'Rate'] || 0
    }
    if ((staffId && staff.inheritRateType(rateType)) || (!staffId && roleId)) {
        if (!staffId && role.avgRateType(rateType)) {
            return _.mean(
                role.staff.map((s) => {
                    return (
                        getCombinedRateAtDate(
                            {
                                project,
                                phase,
                                staff: s,
                                role,
                            },
                            rateType,
                            date
                        ) || 0
                    )
                })
            )
        } else {
            const matchingRoleRates = (role?.rates || []).filter(
                (x) => x.date <= date
            )
            const latestRoleRate = matchingRoleRates.sort(
                (a, b) => b.date - a.date
            )[0]
            return latestRoleRate?.[rateType + 'Rate'] || 0
        }
    } else {
        const matchingStaffRates = (staff?.rates || []).filter(
            (x) => x.date <= date
        )
        const latestStaffRate = matchingStaffRates.sort(
            (a, b) => b.date - a.date
        )[0]
        return latestStaffRate?.[rateType + 'Rate'] || 0
    }
}

export default getCombinedRateAtDate
