import { observer } from 'mobx-react'
import React, { Suspense, useCallback, useEffect } from 'react'
import PageHeader from '../Layout/PageHeader'

export default observer(({ label }) => {
    return (
        <PageHeader
            heading={
                <div>
                    <div>{label}</div>
                </div>
            }
        />
    )
})
