import { observable, computed, action, makeObservable, reaction } from 'mobx'
import { makeRequest } from '../Queries/makeRequest'
import * as Sentry from '@sentry/react'

class BatchDataStore {
    @observable batchData = []
    @observable savingBatchData = []
    @observable saveState = 'idle' // idle, saving, error
    // {id: {
    //     collection
    //     filters
    //     data
    // }}
    constructor(props) {
        makeObservable(this)
    }
    @computed
    get needsSaving() {
        return this.batchData.length > 0
    }
    @action.bound
    clearData() {
        this.batchData = []
    }
    @action.bound
    addData(data) {
        this.batchData.push(data)
    }
    @action.bound
    async startSave(afterSave) {
        if (
            this.needsSaving &&
            this.saveState !== 'saving' &&
            this.saveState !== 'error'
        ) {
            try {
                this.saveState = 'saving'
                this.savingBatchData = this.batchData
                this.batchData = []
                for (const data of this.savingBatchData) {
                    await makeRequest({
                        path: `api/v1.5/batch-save`,
                        method: 'post',
                        data: data,
                    })
                }
                this.saveState = 'success'
                if (this.needsSaving) {
                    this.startSave()
                } else {
                    afterSave && afterSave()
                    setTimeout(() => {
                        if (this.saveState === 'success')
                            this.saveState = 'idle'
                    }, 1000)
                }
            } catch (e) {
                Sentry.captureException(e)
                console.error(e.message)
                this.saveState = 'error'
                this.batchData = [...this.savingBatchData, ...this.batchData]
                throw e
            }
        }
        return
    }
}

export default new BatchDataStore()
