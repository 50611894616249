import { observer } from 'mobx-react'
import React from 'react'
import LayoutStore from '../../State/LayoutStore'
import Table from '../../Components/Table'
import StaffCollection from '../../State/Collections/StaffCollection'
import { capitalCase } from 'change-case'
import BillingPageStore from './BillingPageStore'
import SessionStore from '../../State/SessionStore'
import { FormatCurrency } from '../../Utils/Localisation/CurrencyFormatter'
import { FormatPercent } from '../../Utils/Localisation/PercentFormatter'
import { FormatDate } from '../../Utils/Localisation/DateFormatter'
import { makeRequest } from '../../Queries/makeRequest'
import BillingEmailModal from './BillingEmailModal'
import BillingPermissionsModal from './BillingPermissionsModal'
import * as Sentry from '@sentry/react'
import LoadingSpinner from '../../Components/LoadingSpinner'

const unpaidInvoiceColumns = [
    {
        id: 'label',
        label: 'Unpaid Invoices',
        type: 'text',
        width: 20,
        value: (row) => {
            return row.name
        },
    },
    {
        id: 'date',
        label: '',
        type: 'date',
        width: 15,
        value: (row) => {
            return new Date(row.date)
        },
    },
    {
        id: 'amount',
        label: '',
        type: 'currency',
        width: 25,
        value: (row) => {
            return row.amount
        },
    },
    {
        id: 'payButton',
        label: '',
        type: 'text',
        width: 10,
        value: (row) => {
            return 'pay...'
        },
    },
]

const paymentDetailColumns = [
    {
        id: 'label',
        label: 'Payment Details',
        type: 'text',
        width: 20,
        value: (row) => {
            return row.label
        },
    },
    {
        id: 'value',
        label: '',
        type: 'text',
        width: 50,
        value: (row) => {
            return row.value
        },
        component: ({ value, stores }) => {
            return value
        },
    },
]

const nextPaymentColumns = [
    {
        id: 'label',
        label: 'Next Payment',
        type: 'text',
        width: 20,
        style: (row) => ({
            fontWeight: row?.label === 'Total' ? 'bold' : 'normal',
        }),
        value: (row) => {
            return row.label
        },
    },
    {
        id: 'perecent',
        label: '',
        type: 'text',
        width: 25,
        style: (row) => ({
            fontWeight: row?.label === 'Total' ? 'bold' : 'normal',
        }),
        value: (row) => {
            return row.percent
        },
    },
    {
        id: 'amount',
        label: '',
        type: 'currency',
        format: (v) => FormatCurrency(v, { decimals: 2 }),
        width: 25,
        style: (row) => ({
            fontWeight: row?.label === 'Total' ? 'bold' : 'normal',
        }),
        value: (row) => {
            return row.amount
        },
        component: ({ value, formattedValue, stores }) => {
            return value !== null ? formattedValue : ''
        },
    },
]

const staffColumns = [
    {
        id: 'label',
        label: 'Staff Breakdown',
        type: 'text',
        width: 20,
        style: (row) => ({
            opacity: row?.permissionLevel === 'None' ? 0.65 : 1,
        }),
        value: (row) => {
            return `${row.index}. ${row.fullName}`
        },
    },
    {
        id: 'level',
        label: '',
        type: 'text',
        width: 40,
        style: (row) => ({
            opacity: row?.permissionLevel === 'None' ? 0.65 : 1,
        }),
        value: (row) => {
            return row.permissionLevel
        },
        component: ({ value, stores }) => {
            return (
                <div
                    style={{
                        display: 'flex',
                        width: '100%',
                        textAlign: 'right',
                    }}
                >
                    <div style={{ flex: '1 1 auto', padding: '0 1em' }}>
                        {value}
                    </div>
                    <div
                        style={{
                            borderLeft: 'solid 1px #eee',
                            padding: '0 1em',
                            flex: '0 0 auto',
                            cursor: 'pointer',
                        }}
                        onClick={() => {
                            LayoutStore.openModal(
                                <BillingPermissionsModal
                                    staff={stores.row.rowObject.staff}
                                    modalId={'billing-permissions'}
                                />
                            )
                        }}
                    >
                        edit...
                    </div>
                </div>
            )
        },
    },
    {
        id: 'fee',
        label: '',
        type: 'currency',
        width: 10,
        format: (v) => FormatCurrency(v, { decimals: 2 }),
        style: (row) => ({
            opacity: row?.permissionLevel === 'None' ? 0.65 : 1,
        }),
        value: (row) => {
            return row.fee
        },
    },
]

export default observer(() => {
    if (!SessionStore.subscription) {
        return null
    }
    return (
        <div className="mx-auto">
            {BillingPageStore.isSubscribing ? (
                <div
                    style={{
                        height: '100%',
                        position: 'fixed',
                        left: 0,
                        width: '100%',
                        background: '#FFFFFFaa',
                        textAlign: 'center',
                        zIndex: 1050,
                        // paddingTop: '8rem',
                    }}
                >
                    <div
                        style={{
                            backgroundColor: 'white',
                            width: '30rem',
                            height: '20rem',
                            display: 'inline-flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            justifyContent: 'center',
                            borderRadius: '1rem',
                            boxShadow: '0 0 2rem #00000033',
                        }}
                    >
                        <LoadingSpinner />
                        <div
                            style={{
                                fontWeight: 'bold',
                                marginTop: '-1em',
                                zIndex: 100,
                            }}
                        >
                            Processing Subscription
                        </div>
                    </div>
                </div>
            ) : null}
            {SessionStore.subscription.hasSubscription ? (
                <Table
                    style={{ margin: '3em 0' }}
                    columns={paymentDetailColumns}
                    rows={[
                        {
                            label: 'Organisation',
                            value: SessionStore.organisation.name,
                        },
                        {
                            label: 'Card',
                            value: (
                                <div
                                    style={{
                                        display: 'flex',
                                        justifyContent: 'space-between',
                                        width: '100%',
                                    }}
                                >
                                    <div>
                                        {SessionStore.subscription.cardBrand}
                                    </div>
                                    <div
                                        style={{
                                            borderLeft: 'solid 1px #eee',
                                            padding: '0 1em',
                                        }}
                                    >
                                        ...{SessionStore.subscription.cardLast4}
                                    </div>
                                    <div
                                        style={{
                                            borderLeft: 'solid 1px #eee',
                                            padding: '0 1em',
                                        }}
                                    >
                                        {SessionStore.subscription.cardExpDate
                                            ? FormatDate(
                                                  SessionStore.subscription
                                                      .cardExpDate
                                              )
                                            : null}
                                    </div>
                                    <div
                                        style={{
                                            borderLeft: 'solid 1px #eee',
                                            padding: '0 1em',
                                            cursor: 'pointer',
                                        }}
                                        onClick={() => {
                                            let handler =
                                                window.StripeCheckout.configure(
                                                    {
                                                        key: process.env
                                                            .REACT_APP_STRIPE_PUBLISHABLE_KEY,
                                                        panelLabel: 'Update',
                                                        token: function (
                                                            token
                                                        ) {
                                                            try {
                                                                makeRequest({
                                                                    path: `api/v1.5/subscription/update-card`,
                                                                    method: 'post',
                                                                    data: {
                                                                        stripeToken:
                                                                            token.id,
                                                                    },
                                                                }).then(
                                                                    ({
                                                                        data,
                                                                    }) => {
                                                                        SessionStore.subscription.update(
                                                                            data
                                                                                .organisationSubscriptions[0]
                                                                        )
                                                                    }
                                                                )
                                                            } catch (e) {
                                                                Sentry.captureException(
                                                                    e
                                                                )
                                                                console.error(e)
                                                            }
                                                        },
                                                    }
                                                )
                                            handler.open({
                                                name: 'Update Card',
                                                description:
                                                    'Update your payment details.',
                                                email: SessionStore.subscription
                                                    .receiptEmail,
                                                label: 'Update',
                                            })
                                        }}
                                    >
                                        edit...
                                    </div>
                                </div>
                            ),
                        },
                        {
                            label: 'Receipt Email',
                            value: (
                                <div
                                    style={{
                                        display: 'flex',
                                        justifyContent: 'space-between',
                                        width: '100%',
                                    }}
                                >
                                    <div>
                                        {SessionStore.subscription.receiptEmail}
                                    </div>
                                    <div
                                        style={{
                                            borderLeft: 'solid 1px #eee',
                                            padding: '0 1em',
                                            cursor: 'pointer',
                                        }}
                                        onClick={() => {
                                            LayoutStore.openModal(
                                                <BillingEmailModal
                                                    modalId={'billing-email'}
                                                />
                                            )
                                        }}
                                    >
                                        edit...
                                    </div>
                                </div>
                            ),
                        },
                        {
                            label: 'Credit',
                            value: FormatCurrency(
                                SessionStore.subscription.credit / 100,
                                { decimals: 2 }
                            ),
                        },
                    ]}
                />
            ) : null}
            {SessionStore.subscription.unpaidInvoices?.length ? (
                <Table
                    style={{ margin: '3em 0' }}
                    columns={unpaidInvoiceColumns}
                    rows={SessionStore.subscription.unpaidInvoices}
                />
            ) : null}

            <Table
                style={{ margin: '3em 0' }}
                columns={nextPaymentColumns}
                rows={[
                    {
                        label: 'Date',
                        percent: SessionStore.subscription.nextPaymentDate
                            ? FormatDate(
                                  new Date(
                                      SessionStore.subscription.nextPaymentDate
                                  )
                              )
                            : null,
                        amount: null,
                    },
                    {
                        label: 'Amount Due',
                        percent: '',
                        amount: BillingPageStore.total,
                    },
                    BillingPageStore.discountDecimal
                        ? {
                              label: 'Discount',
                              percent: FormatPercent(
                                  BillingPageStore.discountDecimal
                              ),
                              amount:
                                  BillingPageStore.total *
                                  BillingPageStore.discountDecimal *
                                  -1,
                          }
                        : null,
                    {
                        label: 'Tax',
                        percent: FormatPercent(BillingPageStore.taxDecimal),
                        amount:
                            BillingPageStore.total *
                            (1 - BillingPageStore.discountDecimal) *
                            BillingPageStore.taxDecimal,
                    },
                    SessionStore.subscription.credit
                        ? {
                              label: 'Credit',
                              percent: '',
                              amount:
                                  (SessionStore.subscription.credit / 100) * -1,
                          }
                        : null,
                    {
                        label: 'Total',
                        percent: '',
                        amount: BillingPageStore.grandTotal,
                    },
                ].filter((r) => r)}
            />
            <Table
                style={{ margin: '3em 0' }}
                columns={staffColumns}
                rows={BillingPageStore.pricingPerStaff}
            />
        </div>
    )
})
