import { observer } from 'mobx-react'
import React, { useEffect } from 'react'
import { StatusSelector } from '../../Components/Selector'
import Table from '../../Components/Table'
import ProjectCollection from '../../State/Collections/ProjectCollection'
import { canEditProject } from '../../State/Permissions/HasPermissions'
import SessionStore from '../../State/SessionStore'
import { statusByPriority, statusPriorities } from '../../Utils/statusPriority'
import _ from 'lodash'

const statusColumns = [
    {
        label: 'Phase',
        width: 40,
        type: 'phase',
        value: (r) => r,
    },
    {
        label: 'Status',
        width: 40,
        type: 'status',
        editable: (r) => canEditProject(SessionStore.user, r.project),
        value: (p) => p.status,
        onChange: (r) => (v) => r.update({ status: v }),
    },
    {
        label: '% Likelihood',
        id: '%likelihood',
        width: 20,
        type: 'number',
        editable: (r) => {
            return (
                canEditProject(SessionStore.user, r.project) &&
                ['prospective', 'onHold'].includes(r.status)
            )
        },
        value: (r) =>
            ['prospective', 'onHold'].includes(r.status)
                ? r.percentLikelihood
                : 100,
        onChange: (r) => (v) => r.update({ percentLikelihood: v }),
    },
]

export default observer(({ id }) => {
    const project = ProjectCollection.projectsById[id]
    useEffect(() => {
        ;(project?.phases || []).forEach((cli, i) => (cli.position = i))
    }, [project])
    if (!project) return null
    return (
        <div>
            {canEditProject(SessionStore.user, project) && (
                <div
                    style={{
                        padding: '1em',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'end',
                    }}
                >
                    <div style={{ marginRight: '1em' }}>
                        Set All Statuses to:{' '}
                    </div>
                    <StatusSelector
                        selectedStatus={
                            statusByPriority[
                                _.min(
                                    project.phases
                                        .filter((ph) => !ph.deletedAt)
                                        .map(
                                            (ph) => statusPriorities[ph.status]
                                        )
                                )
                            ]
                        }
                        onChange={(v) => {
                            project.updatePhaseValues({ status: v })
                        }}
                        variant="secondary"
                        className="max-w-max"
                    />
                </div>
            )}
            <Table
                columns={statusColumns.filter(
                    (c) =>
                        canEditProject(SessionStore.user, project) ||
                        c.id !== '%likelihood'
                )}
                rows={project.phases.filter((ph) => !ph?.isRootPhase)}
                sortBy={[[(ph) => ph.startDate, 'asc']]}
            />
        </div>
    )
})
