import React from 'react'
import { observer } from 'mobx-react'

export default observer(({ title, subTitle }) => {
    return (
        <div
            style={{
                textAlign: 'center',
                padding: '1em',
                borderBottom: '1px solid #ccc',
                flex: '0 0 auto',
            }}
        >
            <div
                style={{
                    fontSize: '1.2em',
                    fontWeight: 'bold',
                }}
            >
                {title}
            </div>
            {subTitle ? <div style={{}}>{subTitle}</div> : null}
        </div>
    )
})
