import _ from 'lodash'
import React from 'react'
import {
    addDays,
    differenceInBusinessDays,
    endOfMonth,
    format,
    startOfMonth,
} from 'date-fns'
import { capitalCase } from 'change-case'
import OverheadExpenseCollection from '../../State/Collections/OverheadExpenseCollection'
import { FormatCurrency } from '../../Utils/Localisation/CurrencyFormatter'

const cellStyles = (row, stores, monthStart, isPastMonth) => {
    return {
        ...(isPastMonth
            ? {
                  backgroundImage: `url(${new URL(
                      '~/public/diag.png',
                      import.meta.url
                  )})`,
              }
            : {}),
    }
}

//overhead rows are name strings
export const RevenueForecastReportOverheadColumns = (store) => ({
    title: (monthStartDate) => ({
        id: 'title',
        label: 'Title',
        type: 'text',
        width: 18,
        editable: (row) => false,
        value: (row, stores) => {
            return capitalCase(row)
        },
        component: ({ value, group, stores }) => {
            const { row, table } = stores
            if (row.group === 'totals') return 'Total'
            return (
                <div style={{ paddingLeft: `${1 * row.groupLevel}rem` }}>
                    {value}
                </div>
            )
        },
    }),
    cost: (monthStartDate) => {
        const monthStart = startOfMonth(monthStartDate)
        const monthEnd = endOfMonth(monthStart)
        const dayAfterMonthEnd = addDays(monthEnd, 1)
        const isPastMonth = monthEnd < startOfMonth(new Date())
        return {
            label: format(monthStartDate, 'MMM yy'),
            type: 'currency',
            width: 8,
            value: (row) => {
                const overheadsWithSameName =
                    OverheadExpenseCollection.overheadExpenses.filter(
                        (o) => o.name === row
                    )
                return _.sum(
                    overheadsWithSameName.map((o) =>
                        o.valueInDateRange(monthStart, monthEnd)
                    )
                )
            },
            // data: (row) => {
            //     return [...revenueCell.data(row)]
            // },
            editable: () => false,
            style: (row, stores) => {
                return cellStyles(row, stores, monthStart, isPastMonth)
            },
            aggregate: 'sum',
            format: (v) => FormatCurrency(v, { decimals: 0 }),
        }
    },
    total: (monthStartDate, store) => {
        return {
            id: 'total',
            label: (
                <span>
                    Total
                    <span
                        style={{ fontSize: '1.5em', float: 'right' }}
                        onClick={(e) => e.stopPropagation()}
                    >
                        <i
                            className={`fa fa-caret-left`}
                            style={{ cursor: 'pointer' }}
                            onClick={() => store.shiftDates(-1)}
                        />
                        <i
                            className={`fa fa-caret-right`}
                            style={{ marginRight: 0, cursor: 'pointer' }}
                            onClick={() => store.shiftDates(1)}
                        />
                    </span>
                </span>
            ),
            type: 'text',
            width: 18,
            editable: (row) => false,
            value: (row) => {
                return ''
            },
        }
    },
})
