import _ from 'underscore'
import { makeObservable, observable, computed, action } from 'mobx'
import SessionStore, { settingDefaults } from '../../State/SessionStore'
import StaffCollection from '../../State/Collections/StaffCollection'
import { isCompanyEmail } from 'free-email-domains-list'

class SettingsPageStore {
    @observable settings = {}
    constructor() {
        makeObservable(this)
        this.settings = {
            ...settingDefaults,
            ...(SessionStore.organisation?.settings || {}),
        }
    }

    @action.bound
    changeSetting(name, value) {
        this.settings[name] = value
        SessionStore.organisation.update({ settings: this.settings })
    }

    @computed
    get emailDomainOptions() {
        return [
            ...new Set([
                ...(StaffCollection.activeStaff || [])
                    .filter((staff) => isCompanyEmail(staff.email))
                    .map((staff) => staff.email.split('@')[1]),
                ...(this.settings.emailDomains || []),
            ]),
        ]
    }
}

export default SettingsPageStore
