import {
    addMonths,
    addWeeks,
    endOfMonth,
    endOfWeek,
    startOfMonth,
    startOfWeek,
    subMonths,
    subWeeks,
} from 'date-fns'

export const dateUnitLookup = {
    weeks: {
        add: addWeeks,
        sub: subWeeks,
        startOf: startOfWeek,
        endOf: endOfWeek,
    },
    months: {
        add: addMonths,
        sub: subMonths,
        startOf: startOfMonth,
        endOf: endOfMonth,
    },
}

export const isDateBetweenRange = (date, dateRange) => {
    return (
        (dateRange[0] ? date > dateRange[0] : true) &&
        (dateRange[1] ? date < dateRange[1] : true)
    )
}

export const isDateOnOrBetweenRange = (date, dateRange) => {
    return (
        (dateRange[0] ? date >= dateRange[0] : true) &&
        (dateRange[1] ? date <= dateRange[1] : true)
    )
}

export const isDateRangeOverlapping = (dateRange1, dateRange2) => {
    return dateRange2[0] <= dateRange1[1] && dateRange2[1] >= dateRange1[0]
}
