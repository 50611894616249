import { observable, computed, action, makeObservable, toJS } from 'mobx'
import PhaseCollection from '../Collections/PhaseCollection'
import ProjectCollection from '../Collections/ProjectCollection'
import RevenueRowCollection from '../Collections/RevenueRowCollection'
import Model from './Model'
import _ from 'lodash'
import bind from 'bind-decorator'
import {
    addMonths,
    addYears,
    differenceInBusinessDays,
    endOfMonth,
    format,
    parse,
    set,
    startOfMonth,
    subMonths,
    subYears,
} from 'date-fns'
import RoleCollection from '../Collections/RoleCollection'
import StaffCollection from '../Collections/StaffCollection'
import { computedFn } from 'mobx-utils'

const propByGroup = {
    status: 'status',
    project: 'projectId',
    phase: 'phaseId',
}

class RevenueRowModel extends Model {
    @observable label = null
    @observable fee = 0
    @observable actualRevenue = {}
    @observable actualVariationRevenue = {}
    @observable actualReimbursementRevenue = {}
    @observable projectedRevenue = {}
    @observable editedRevenue = {}
    @observable editedExpense = {}
    @observable expenses = {}
    @observable status = null
    @observable projectId = null
    @observable phaseId = null
    @observable phaseTitle = null
    @observable revenueStartDate = null
    @observable revenueEndDate = null
    @observable startDate = null
    @observable endDate = null
    @observable groups = ['status']
    @observable store = null
    @observable parent = null
    @observable children = []
    @observable isRootPhase = null

    constructor(data, options) {
        super()
        makeObservable(this)
        this.collection = RevenueRowCollection
        this.init(data, options)
    }

    @action
    setStore(store) {
        this.children.forEach((r) => r.setStore(store))
        if (this.store === store) return
        this.store = store
    }

    @action.bound
    setParent(parent) {
        this.parent = parent
    }

    @action.bound
    setChildren(children = []) {
        this.children = children
    }

    @computed
    get totalFee() {
        if (this.children.length) {
            return _.sum(this.children.map((r) => r.totalFee))
        }
        return this.fee || this.phase?.fee || this.project?.fee || 0
    }

    @computed
    get revenueData() {
        return (
            this.store?.report?.filters?.revenueData ||
            this.store?.report?.filters?.expenseData ||
            this.store?.report?.filters?.hoursData ||
            'actualsProjected'
        )
    }

    @computed
    get phase() {
        return PhaseCollection.phasesById[this.phaseId]
    }

    @computed
    get project() {
        return ProjectCollection.projectsById[this.projectId]
    }

    @computed
    get childPhases() {
        return this.phase
            ? [this.phase]
            : [...new Set(this.children.flatMap((c) => c.childPhases))].filter(
                  (p) => p
              )
    }

    @computed
    get childPhaseIds() {
        return this.phaseId
            ? [this.phaseId]
            : [
                  ...new Set(this.children.flatMap((c) => c.childPhaseIds)),
              ].filter((p) => p)
    }

    @computed
    get childProjects() {
        return this.project
            ? [this.project]
            : [
                  ...new Set(this.children.flatMap((c) => c.childProjects)),
              ].filter((p) => p)
    }

    @computed
    get level() {
        return this.groups.length - 1
    }

    @computed
    get matchers() {
        let matchers = {}
        if (this.groups.includes('staff')) {
            matchers.staffId = this.staffId
        }
        if (this.groups.includes('role')) {
            matchers.roleId = this.roleId
        }
        if (this.groups.includes('phase')) {
            matchers.phaseId = this.phaseId
        }
        if (this.groups.includes('project')) {
            matchers.projectId = this.projectId
        }
        if (this.groups.includes('status')) {
            matchers.status = this.status
        }
        return matchers
    }

    @computed
    get revenueMonths() {
        if (this.children.length) {
            return _.uniq(this.children.flatMap((r) => r.revenueMonths))
        }
        return _.uniq([
            ...Object.keys(this.actualRevenue),
            ...Object.keys(this.projectedRevenue),
            ...Object.keys(this.editedRevenue),
        ])
    }

    @computed
    get expenseMonths() {
        if (this.children.length) {
            return _.uniq(this.children.flatMap((r) => r.expenseMonths))
        }
        return _.uniq([
            ...Object.keys(this.expenses),
            ...Object.keys(this.editedExpense),
        ])
    }

    getRevenueInMonth = computedFn((month) => {
        if (this.children.length) {
            return _.sum(
                this.children.flatMap((r) => r.getRevenueInMonth(month))
            )
        }
        if (this.revenueData === 'actual') {
            return this.actualRevenue[month] ?? 0
        }
        if (this.revenueData === 'projected') {
            return (
                this.editedRevenue[month] ?? this.projectedRevenue[month] ?? 0
            )
        }
        if (this.revenueData === 'actualsProjected') {
            if (month < format(new Date(), 'yyyy-MM')) {
                return this.actualRevenue[month] ?? 0
            } else if (month > format(new Date(), 'yyyy-MM')) {
                return (
                    this.editedRevenue[month] ??
                    this.projectedRevenue[month] ??
                    0
                )
            } else {
                return Math.max(
                    this.actualRevenue[month] ?? 0,
                    this.editedRevenue[month] ??
                        this.projectedRevenue[month] ??
                        0
                )
            }
        }
    })

    getVariationRevenueInMonth = computedFn((month) => {
        if (this.children.length) {
            return _.sum(
                this.children.flatMap((r) =>
                    r.getVariationRevenueInMonth(month)
                )
            )
        }
        if (this.revenueData === 'projected') {
            return 0
        }
        return this.actualVariationRevenue[month] ?? 0
    })

    getExpenseInMonth = computedFn((month) => {
        if (this.children.length)
            return _.sum(
                this.children.flatMap((r) => r.getExpenseInMonth(month))
            )
        if (this.revenueData === 'actual') {
            return this.expenses[month] || 0
        }
        if (this.revenueData === 'projected') {
            return this.editedExpense[month] || this.expenses[month] || 0
        }
        if (this.revenueData === 'actualsProjected') {
            if (month < format(new Date(), 'yyyy-MM')) {
                return this.expenses[month] || 0
            } else if (month > format(new Date(), 'yyyy-MM')) {
                return this.editedExpense[month] || this.expenses[month] || 0
            } else {
                return Math.max(
                    this.expenses[month] || 0,
                    this.editedExpense[month] || this.expenses[month] || 0
                )
            }
        }
    })

    getRevenueToDateInMonth = computedFn((month) => {
        const prevRevenueMonths = this.revenueMonths.filter((m) => m <= month)
        const revenueToDate = _.sum(
            prevRevenueMonths.map((m) => this.getRevenueInMonth(m))
        )
        return revenueToDate
    })

    getVariationRevenueToDateInMonth = computedFn((month) => {
        const prevRevenueMonths = this.revenueMonths.filter((m) => m <= month)
        const revenueToDate = _.sum(
            prevRevenueMonths.map((m) => this.getVariationRevenueInMonth(m))
        )
        return revenueToDate
    })

    getExpenseToDateInMonth = computedFn((month) => {
        const prevExpenseMonths = this.expenseMonths.filter((m) => m <= month)
        const expensesToDate = _.sum(
            prevExpenseMonths.map((m) => this.getExpenseInMonth(m))
        )
        return expensesToDate
    })

    @bind
    getProgressInMonth(month) {
        if (!this.totalFee) return null
        return this.getRevenueToDateInMonth(month) / this.totalFee
    }

    @bind
    getExpenseProgressInMonth(month) {
        return this.getExpenseToDateInMonth(month) / this.expenseBudget
    }

    getTotalRevenue = computedFn(() => {
        return this.getRevenueToDateInMonth(
            format(addYears(new Date(), 100), 'yyyy-MM')
        )
    })

    getTotalExpense = computedFn(() => {
        return this.getExpenseToDateInMonth(
            format(addYears(new Date(), 100), 'yyyy-MM')
        )
    })

    @action.bound
    setProgressInMonth(month, progress) {
        progress ||= 0
        const monthDate = parse(month, 'yyyy-MM', new Date())
        const prevMonth = format(subMonths(monthDate, 1), 'yyyy-MM')
        const prevProgress = this.getProgressInMonth(prevMonth) || 0
        const diffProgress = Math.max(progress, prevProgress) - prevProgress
        const diffRevenue = diffProgress * this.totalFee
        return this.setRevenueInMonth(month, diffRevenue)
    }

    @action.bound
    setExpenseProgressInMonth(month, progress) {
        progress ||= 0
        const monthDate = parse(month, 'yyyy-MM', new Date())
        const prevMonth = format(subMonths(monthDate, 1), 'yyyy-MM')
        const prevProgress = this.getExpenseProgressInMonth(prevMonth) || 0
        const diffProgress = Math.max(progress, prevProgress) - prevProgress
        const diffExpense = diffProgress * this.expenseBudget
        return this.setExpenseInMonth(month, diffExpense)
    }

    @action.bound
    setRevenueInMonth(month, v) {
        if (!this.children.length) {
            this.editedRevenue[month] = v
            this.update({ editedRevenue: { ...this.editedRevenue } })
        }
        const children = this.children
        const childrenWithRevenueInMonth = children.filter(
            (r) => r.getRevenueInMonth(month) > 0
        )
        const existingRevenueInMonth = _.sum(
            childrenWithRevenueInMonth.map((r) => r.getRevenueInMonth(month))
        )
        if (existingRevenueInMonth) {
            const ratio = v / existingRevenueInMonth
            childrenWithRevenueInMonth.forEach((r) =>
                r.setRevenueInMonth(month, r.getRevenueInMonth(month) * ratio)
            )
        } else {
            children.forEach((r) =>
                r.setRevenueInMonth(month, v / children.length)
            )
        }
    }

    serialize() {
        return {
            id: this.id,
            editedRevenue: this.editedRevenue,
            reportFilters: {
                ...(this.store?.report?.filters || {}),
                ...this.matchers,
            },
        }
    }

    serializeUpdates() {
        return this.serialize()
    }
}

export default RevenueRowModel
