import classNames from 'classnames'
import { observer } from 'mobx-react'
import React, { Suspense, useEffect, useRef, useState } from 'react'
import SessionStore from '../State/SessionStore'

export default observer(() => {
    return (
        <div className="flex items-center justify-center text-center w-full h-full">
            <section className={'flex items-center justify-center flex-col'}>
                <header className="flex flex-col justify-center items-center">
                    <div className="logo2 s75 size-[10em] flex items-center justify-center">
                        <img
                            src={
                                new URL(
                                    '/public/coincraft_logo_130.png',
                                    import.meta.url
                                )
                            }
                            alt="Coincraft Logo"
                        />
                    </div>
                    <h3>Permission Denied</h3>
                </header>
                <div className="body space-y-[1em] text-[13px] text-[#666] leading-5">
                    <div>You do not have permission to view this page.</div>
                    <div style={{ marginTop: '1em' }}>
                        Please contact your system admin to correct this.
                    </div>
                    <div>
                        Alternatively you can contact use via the chat or{' '}
                        <a
                            href="mailto:support@coincraft.co"
                            style={{ textDecoration: 'underline' }}
                        >
                            support@coincraft.co
                        </a>
                        .
                    </div>
                    <div style={{ marginTop: '1em' }}>
                        <div
                            style={{
                                display: 'inline-block',
                                marginRight: '1em',
                            }}
                        >
                            <a
                                href={`/`}
                                style={{ textDecoration: 'underline' }}
                            >
                                Home
                            </a>
                        </div>
                        <div style={{ display: 'inline-block' }}>
                            <a
                                href="/login"
                                style={{ textDecoration: 'underline' }}
                                onClick={() => SessionStore.logout()}
                            >
                                Login
                            </a>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    )
})
