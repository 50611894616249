export default {
    name: 'New Report',
    type: 'invoice',
    columns: ['description', 'project', 'issuedOn', 'amountIncTax', 'synced'],
    filters: [
        {
            column: 'projectStatus',
            operator: 'in',
            value: ['active'],
            group: 'project',
        },
    ],
    defaultGroup: 'invoice',
    groupBy: [],
    sortBy: [['issuedOn', 'desc']],
    options: {
        dateRange: 'allTime',
    },
}
