import { observable, computed, action, makeObservable } from 'mobx'
import Collection from '../Collections/Collection'
import Aggregate from './Aggregate'
import RevenueTargetAggregateModel from './RevenueTargetAggregateModel'

class RevenueTargetAggregate extends Aggregate {
    constructor() {
        super({ collectionClass: RevenueTargetAggregateCollection })
        makeObservable(this)
    }
}

class RevenueTargetAggregateCollection extends Collection {
    constructor() {
        super({
            collection: 'revenueTargets',
            modelClass: RevenueTargetAggregateModel,
        })
        this.addLookup('revenueTargetsByProjectId', 'manyByKey', {
            key: (t) => t.projectId,
            filter: (t) => !t.deletedAt,
        })
        this.addLookup('revenueTargetsByPhaseId', 'manyByKey', {
            key: (t) => t.phaseId,
            filter: (t) => !t.deletedAt,
        })
        this.addLookup('revenueTargetsByStatus', 'manyByKey', {
            key: (t) => t.status,
            filter: (t) => !t.deletedAt,
        })
        makeObservable(this)
    }
    get revenueTargetsById() {
        return this.modelsById
    }
    get revenueTargets() {
        return this.models
    }
    get revenueTargetsById() {
        return this.modelsById
    }
    @action.bound
    addRevenueTarget(data) {
        this.add(data)
    }
    @action.bound
    addRevenueTargets(data) {
        this.addMany(data)
    }
}

export default new RevenueTargetAggregate()
export const RevenueTargetAggregateCollectionClass =
    RevenueTargetAggregateCollection
