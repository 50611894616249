import React from 'react'
import Modal from './Modal'

export default ({ modalId, heading, message }) => {
    return (
        <Modal modalId={modalId} heading={heading}>
            <div
                className="error"
                style={{
                    color: 'red',
                    fontSize: '1.05em',
                    padding: '1em 1em',
                    margin: '1em 2em',
                    backgroundColor: '#ff00001c',
                    borderRadius: '1em',
                }}
            >
                {message}
            </div>
        </Modal>
    )
}
