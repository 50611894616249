import _ from 'lodash'
import { queryFilters } from '../../Queries/queryFilters'
import {
    canViewFlexi,
    canViewRemote,
    canViewPrimaryContact,
    canViewProjectExpenseBudgets,
    canViewProjectFees,
    canViewProjectInvoices,
    canViewRevenueTargets,
    canViewStaffChargeOutRate,
    canViewStaffCostRate,
    canViewStaffPayRate,
} from '../../State/Permissions/HasPermissions'
import SessionStore from '../../State/SessionStore'
import { format, parse } from 'date-fns'

export const TimeReportColumns = (report) => ({
    project: {
        id: 'project',
        label: 'Project',
        type: 'project',
        width: 20,
        value: (row) => row.project,
        format: (project) => {
            return project
        },
    },
    projectPhase: {
        id: 'projectPhase',
        label: 'Phase',
        type: 'text',
        width: 20,
        value: (row) => {
            return row.projectPhase
        },
    },
    projectStatus: {
        id: 'projectStatus',
        label: 'Project Status',
        type: 'status',
        width: 20,
        value: (row) => {
            return row.projectStatus
        },
    },
    phaseStatus: {
        id: 'phaseStatus',
        label: 'Phase Status',
        type: 'status',
        width: 20,
        value: (row) => {
            return row.phaseStatus
        },
    },
    staffMember: {
        id: 'staffMember',
        label: 'Staff',
        type: 'staffMember',
        width: 20,
        value: (row) => {
            return row.staffMember
        },
        format: (staff) => staff,
    },
    task: {
        id: 'task',
        label: 'Task',
        type: 'text',
        width: 20,
        value: (row) => {
            return row.task
        },
    },
    date: {
        id: 'date',
        label: 'Date',
        type: 'date',
        width: 10,
        aggregate: 'same',
        value: (row) => {
            return row.date ? new Date(row.date) : null
        },
    },
    numMinutes: {
        id: 'numMinutes',
        label: 'Hours',
        type: 'number',
        width: 10,
        aggregate: 'sum',
        value: (row) => {
            return row.numMinutes
        },
    },
    billableHours: {
        id: 'billableHours',
        label: 'Billable Hours',
        type: 'number',
        width: 10,
        aggregate: 'sum',
        value: (row) => {
            return row.billableHours
        },
    },
    nonBillableHours: {
        id: 'nonBillableHours',
        label: 'Non-Billable Hours',
        type: 'number',
        width: 10,
        aggregate: 'sum',
        value: (row) => {
            return row.nonBillableHours
        },
    },
    percentBillable: {
        id: 'percentBillable',
        label: 'Percent Billable',
        type: 'percent',
        width: 10,
        aggregate: 'avg',
        value: (row) => {
            return row.percentBillable
        },
    },
    notes: {
        id: 'notes',
        label: 'Notes',
        type: 'text',
        width: 30,
        value: (row) => {
            return row.notes
        },
    },
    costCentre: {
        id: 'costCentre',
        label: 'Cost Centre',
        type: 'costCentre',
        width: 15,
        value: (row) => {
            return row.costCentre
        },
        format: (costCentre) => costCentre,
    },
    staffCostCentre: {
        id: 'staffCostCentre',
        label: 'Staff Cost Centre',
        type: 'costCentre',
        width: 15,
        value: (row) => {
            return row.staffCostCentre
        },
        format: (costCentre) => costCentre,
    },
    projectOwner: {
        id: 'projectOwner',
        label: 'Project Owner',
        type: 'staffMember',
        width: 20,
        value: (row) => {
            return row.projectOwner
        },
        format: (staff) => staff,
    },
    projectContact: {
        id: 'projectContact',
        label: 'Project Client',
        type: 'contact',
        width: 20,
        value: (row) => {
            return row.projectContact
        },
        format: (contact) => contact,
    },
    projectInvoiceContact: {
        id: 'projectInvoiceContact',
        label: 'Project Primary Contact',
        type: 'contact',
        width: 20,
        value: (row) => {
            return row.projectInvoiceContact
        },
        format: (contact) => contact,
        permissions: (row) => canViewPrimaryContact(),
    },
    projectCode: {
        id: 'projectCode',
        label: 'Project Code',
        type: 'text',
        width: 20,
        value: (row) => {
            return row.projectCode
        },
    },
    projectPhaseCode: {
        id: 'projectPhaseCode',
        label: 'Phase Code',
        type: 'text',
        width: 20,
        value: (row) => {
            return row.projectPhaseCode
        },
    },
    staffMemberFirstName: {
        id: 'staffMemberFirstName',
        label: 'Staff First Name',
        type: 'text',
        width: 20,
        value: (row) => {
            return row.staffMemberFirstName
        },
    },
    staffMemberLastName: {
        id: 'staffMemberLastName',
        label: 'Staff Last Name',
        type: 'text',
        width: 20,
        value: (row) => {
            return row.staffMemberLastName
        },
    },
    staffRole: {
        id: 'staffRole',
        label: 'Staff Role',
        type: 'role',
        width: 20,
        value: (row) => {
            return row.staffRole
        },
        format: (v) => v,
    },
    monthIndex: {
        id: 'monthIndex',
        label: 'Month',
        type: 'text',
        width: 20,
        format: (month) =>
            month && format(parse(month, 'yyyy-MM', new Date()), 'MMM yyyy'),
        value: (row) => {
            return row?.monthIndex
        },
    },
    isBillable: {
        id: 'isBillable',
        label: 'Is Billable',
        type: 'boolean',
        width: 20,
        value: (row) => {
            return row.isBillable
        },
    },
    isVariation: {
        id: 'isVariation',
        label: 'Is Variation',
        type: 'boolean',
        width: 20,
        value: (row) => {
            return row.isVariation
        },
    },
    isOvertime: {
        id: 'isOvertime',
        label: 'Is Overtime',
        type: 'boolean',
        width: 20,
        value: (row) => {
            return row.isOvertime
        },
    },
    isLocked: {
        id: 'isLocked',
        label: 'Is Locked',
        type: 'boolean',
        width: 20,
        value: (row) => {
            return row.isLocked
        },
    },
    beenInvoiced: {
        id: 'beenInvoiced',
        label: 'Has Been Invoiced',
        type: 'boolean',
        width: 20,
        value: (row) => {
            return row.beenInvoiced
        },
    },
    flexi: {
        id: 'flexi',
        label: 'Flexi',
        type: 'boolean',
        width: 20,
        value: (row) => {
            return row.flexi
        },
        queryFilters: ({ operator, value }) => {
            return queryFilters.boolean[operator]('flexi', value)
        },
        queries: (report) => [
            {
                collection: 'timeEntries',
                fields: ['flexi'],
            },
        ],
        permissions: (row) => canViewFlexi(),
    },
    remote: {
        id: 'remote',
        label: 'Remote',
        type: 'boolean',
        width: 20,
        value: (row) => {
            return row.remote
        },
        permissions: (row) => canViewRemote(),
    },
    fee: {
        id: 'fee',
        label: 'Fee',
        type: 'currency',
        width: 20,
        aggregate: 'sum',
        value: (row) => {
            return row.fee
        },
        permissions: (r) => canViewProjectFees(SessionStore.user, r?.project),
    },

    revenue: {
        id: 'revenue',
        label: 'Revenue',
        type: 'currency',
        width: 20,
        aggregate: 'sum',
        value: (row) => {
            return row.revenue
        },
        permissions: (r) =>
            canViewProjectFees(SessionStore.user, r?.project) &&
            canViewProjectInvoices(SessionStore.user, r?.project),
    },
    remainingFee: {
        id: 'remainingFee',
        label: 'Remaining Fee',
        type: 'currency',
        width: 20,
        aggregate: 'sum',
        value: (row) => {
            return row.remainingFee
        },
        permissions: (r) =>
            canViewProjectFees(SessionStore.user, r?.project) &&
            canViewProjectInvoices(SessionStore.user, r?.project),
    },
    budget: {
        id: 'budget',
        label: 'Expense Budget',
        type: 'currency',
        width: 20,
        aggregate: 'sum',
        value: (row) => {
            return row.budget
        },
        permissions: (r) =>
            canViewProjectExpenseBudgets(SessionStore.user, r?.project),
    },
    hoursBudget: {
        id: 'hoursBudget',
        label: 'Hours Budget',
        type: 'number',
        width: 20,
        aggregate: 'sum',
        value: (row) => {
            return row.hoursBudget
        },
    },
    staffHoursBudget: {
        id: 'staffHoursBudget',
        label: 'Staff Hours Budget',
        type: 'number',
        width: 20,
        aggregate: 'sum',
        value: (row) => {
            return row.staffHoursBudget
        },
    },
    actualVsStaffHoursBudget: {
        id: 'actualVsStaffHoursBudget',
        label: 'Actual vs Staff Hours Budget',
        type: 'progressBar',
        width: 20,
        aggregate: 'sum',
        value: (row) => {
            return row.actualVsStaffHoursBudget
        },
    },
    startMinutes: {
        id: 'startMinutes',
        label: 'Start Time',
        type: 'time',
        width: 20,
        value: (row) => {
            return row.startMinutes
        },
    },
    endMinutes: {
        id: 'endMinutes',
        label: 'End Time',
        type: 'time',
        width: 20,
        value: (row) => {
            return row.endMinutes
        },
    },
    labourExpense: {
        id: 'labourExpense',
        label: 'Labour Expense',
        type: 'currency',
        width: 10,
        aggregate: 'sum',
        value: (row) => {
            return row.pay
        },
        permissions: (r) => canViewStaffPayRate(SessionStore.user),
    },
    cost: {
        id: 'cost',
        label: 'Expense (cost)',
        type: 'currency',
        width: 10,
        aggregate: 'sum',
        value: (row) => {
            return row.cost
        },
        permissions: (r) => canViewStaffCostRate(SessionStore.user),
    },
    chargeOut: {
        id: 'chargeOut',
        label: 'Charge Out',
        type: 'currency',
        width: 10,
        aggregate: 'sum',
        value: (row) => {
            return row.chargeOut
        },
        permissions: (r) => canViewStaffChargeOutRate(SessionStore.user),
    },
    chargeOutRate: {
        id: 'chargeOutRate',
        label: 'Charge Out Rate',
        type: 'currency',
        width: 20,
        aggregate: 'sum',
        value: (row) => {
            return row.chargeOut / row.hours
        },
        permissions: (r) => canViewStaffChargeOutRate(SessionStore.user),
    },
    hasNotes: {
        id: 'hasNotes',
        label: 'Has Notes',
        type: 'boolean',
        width: 10,
        value: (row) => {
            return row.hasNotes
        },
    },
})
