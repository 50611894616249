import { observable, computed, action, makeObservable } from 'mobx'
import Collection from './Collection'
import DailyAllocationModel from '../Models/DailyAllocationModel'
import tuple from 'immutable-tuple'

class DailyAllocationCollection extends Collection {
    constructor() {
        super({
            collection: 'dailyAllocations',
            modelClass: DailyAllocationModel,
        })
        this.addLookup('dailyAllocationsByProjectId', 'manyByKey', {
            key: (t) => t.projectId,
            filter: (t) => !t.deletedAt,
        })
        this.addLookup('dailyAllocationsByPhaseId', 'manyByKey', {
            key: (t) => t.phaseId,
            filter: (t) => !t.deletedAt,
        })
        this.addLookup('dailyAllocationsByStaffId', 'manyByKey', {
            key: (t) => t.staffId,
            filter: (t) => !t.deletedAt,
        })
        this.addLookup('dailyAllocationsByRoleId', 'manyByKey', {
            key: (t) => t.roleId || t.staff?.roleId,
            filter: (t) => !t.deletedAt,
        })
        this.addLookup('dailyAllocationsByTaskId', 'manyByKey', {
            key: (t) => t.taskId,
            filter: (t) => !t.deletedAt,
        })
        this.addLookup('dailyAllocationsByCostCentreId', 'manyByKey', {
            key: (t) => t.costCentreId,
            filter: (t) => !t.deletedAt,
        })
        this.addLookup('dailyAllocationsByStaffIdDate', 'manyByKey', {
            key: (t) => tuple(t.staffId, t.date.getTime()),
            filter: (t) => !t.deletedAt,
        })
        this.addLookup('dailyAllocationsByStaffIdWeek', 'manyByKey', {
            key: (t) => {
                return tuple(t.staffId, t.week)
            },
            filter: (t) => !t.deletedAt,
        })
        makeObservable(this)
    }
    get dailyAllocationsById() {
        return this.modelsById
    }
    get dailyAllocations() {
        return this.models
    }
    get dailyAllocationsById() {
        return this.modelsById
    }
    @action.bound
    addDailyAllocation(data, options) {
        this.add(data, options)
    }
    @action.bound
    addDailyAllocations(data, options) {
        this.addMany(data, options)
    }
}

export default new DailyAllocationCollection()
export const DailyAllocationCollectionClass = DailyAllocationCollection
