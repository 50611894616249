import React, { useEffect, useState } from 'react'
import { observer } from 'mobx-react'
import RenderOnQueries from '../Layout/RenderOnQueries'
import ResourceScheduleStore from './ResourceScheduleStore'
import SessionStore from '../../State/SessionStore'
import PhaseCollection from '../../State/Collections/PhaseCollection'
import ProjectCollection from '../../State/Collections/ProjectCollection'
import { queryClient } from '../../App'
import { format, set } from 'date-fns'
import {
    canEditRevenueTargets,
    canEditStaffAllocations,
    canViewProjectFees,
    canViewRevenueTargets,
    canViewStaffAllocations,
    canViewStaffCostRate,
    shouldUpdateHoursFromRevenue,
    shouldUpdateRevenueFromHours,
} from '../../State/Permissions/HasPermissions'
import { Checkbox } from 'antd'
import RevenueProgressInput from '../../Components/ForecastSidebar/RevenueProgressInput'
import _ from 'lodash'
import ExpenseProgressInput from '../../Components/ForecastSidebar/ExpenseProgressInput'
import UtilisationProgressInput from '../../Components/ForecastSidebar/UtilisationProgressInput'
import StaffCollection from '../../State/Collections/StaffCollection'
import getCombinedRateInDateRange from '../../Utils/getCombinedRateInDateRange'
import RoleCollection from '../../State/Collections/RoleCollection'
import SidebarTitle from '../../Components/ForecastSidebar/SidebarTitle'
import SidebarNavigation from '../../Components/ForecastSidebar/SidebarNavigation'
import { action, computed, makeObservable, observable, toJS } from 'mobx'
import { makeRequest } from '../../Queries/makeRequest'
import { FormatCurrency } from '../../Utils/Localisation/CurrencyFormatter'
import { Link } from '@tanstack/react-router'
import sortPhases from '../../Utils/sortPhases'

export default observer(() => {
    const [store, setStore] = useState(ResourceScheduleStore)
    const period =
        store.selectedPeriod && store.startOfPeriod(store.selectedPeriod)
    const project = ProjectCollection.projectsById[store.selectedProjectId]
    const phase = PhaseCollection.phasesById[store.selectedPhaseId]
    const periodId = format(period, store.periodFormat())
    const periodType = store.periodType
    const queryId = `sidebar${periodType}${periodId}${store.selectedProjectId}${
        store.selectedPhaseId
    }${store.selectedStaffId}${
        store.selectedRoleId
    }${store.selectedStaffIds.join(',')}`
    if (store.selectedPeriod && store.selectedObject) {
        return (
            <RenderOnQueries
                queryIds={[
                    {
                        id: queryId,
                        baseURL: process.env.REACT_APP_NODE_SERVER_URL,
                        path: `/project-forecast-sidebar`,
                        method: 'POST',
                        staleTime: 0,
                        data: {
                            projectId: store.selectedProjectId,
                            organisationId: SessionStore.organisationId,
                            userId: SessionStore.user?.id,
                            invoiceType:
                                SessionStore.organisation
                                    .defaultRevenueForecastReport.filters
                                    .invoiceData,
                            periodType: ResourceScheduleStore.periodType,
                            dataType:
                                ResourceScheduleStore.report.filters.hoursData,
                            selectedPeriod: format(
                                new Date(store.selectedPeriod),
                                store.periodFormat()
                            ),
                            phaseId: store.selectedPhaseId,
                            staffIds: store.selectedStaffIds,
                        },
                    },
                    {
                        id: store.selectedProjectId + 'phases',
                        collection: 'phases',
                        fields: [
                            'id',
                            'name',
                            'jobNumber',
                            'projectId',
                            'fee',
                            'isRootPhase',
                            'position',
                            'status',
                        ],
                        filters: [`projectId == "${store.selectedProjectId}"`],
                    },
                    {
                        collection: 'staffRates',
                        fields: [
                            'staffId',
                            'date',
                            'payRate',
                            'chargeOutRate',
                            'costRate',
                            'weeklyAvailability',
                            'overtimeRate',
                        ],
                    },
                    {
                        collection: 'roleRates',
                        fields: [
                            'roleId',
                            'date',
                            'payRate',
                            'chargeOutRate',
                            'costRate',
                            'overtimeRate',
                        ],
                    },
                    {
                        collection: 'projectRates',
                        filters: [`projectId == "${store.selectedProjectId}"`],
                        fields: [
                            'projectId',
                            'itemType',
                            'itemId',
                            'phaseId',
                            'date',
                            'costRate',
                            'chargeOutRate',
                        ],
                    },
                ]}
            >
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        height: '100%',
                    }}
                    key={`sidebar${periodType}${periodId}${
                        store.selectedProjectId
                    }${store.selectedPhaseId}${store.selectedStaffId}${
                        store.selectedRoleId
                    }${store.selectedStaffIds.join(',')}`}
                >
                    <div>
                        <SidebarTitle
                            title={project?.title}
                            subTitle={phase?.title}
                        />
                        <SidebarNavigation
                            title={format(
                                store.selectedPeriod,
                                store.periodDisplayFormat()
                            )}
                            onLeftClick={() => store.shiftSelectedPeriod(-1)}
                            onRightClick={() => store.shiftSelectedPeriod(1)}
                        />
                        <ProjectSidebarContent
                            periodType={periodType}
                            period={period}
                            periodId={periodId}
                            projectId={store.selectedProjectId}
                            phaseId={store.selectedPhaseId}
                            staffId={store.selectedStaffId}
                            roleId={store.selectedRoleId}
                            staffIds={store.selectedStaffIds}
                            queryId={queryId}
                            onUpdateHours={(hoursData) => {
                                const groups =
                                    ResourceScheduleStore.report.filters.groups
                                const hoursByRow = new Map()
                                hoursData.forEach((hd) => {
                                    let row
                                    const {
                                        period: periodFromData,
                                        hours,
                                        ...models
                                    } = hd
                                    models.owner = models.project?.owner
                                    groups.forEach((group, i) => {
                                        const children = row
                                            ? row.children
                                            : ResourceScheduleStore.queryData
                                        const groupsBefore = groups.slice(
                                            0,
                                            i + 1
                                        )
                                        const key = groupsBefore
                                            .map(
                                                (g) =>
                                                    models[g]?.id ||
                                                    models[g] ||
                                                    'null'
                                            )
                                            .join('||')
                                        row = children.find(
                                            (r) => r.key === key
                                        )
                                    })
                                    if (row) {
                                        if (hoursByRow.has(row)) {
                                            const oldHours = hoursByRow.get(row)
                                            hoursByRow.set(
                                                row,
                                                hours + oldHours
                                            )
                                        } else {
                                            hoursByRow.set(row, hours)
                                        }
                                    }
                                })
                                hoursByRow.forEach((hours, row) => {
                                    ResourceScheduleStore.setHours(
                                        row,
                                        periodId,
                                        hours,
                                        false
                                    )
                                })
                            }}
                        />
                    </div>
                </div>
            </RenderOnQueries>
        )
    }
})

class SidebarStore {
    @observable sidebarId = null
    @observable period = null
    @observable periodType = 'month'
    @observable projectId = null
    @observable queryData = {
        revenueData: [],
        hoursData: [],
        phases: [],
        staff: [],
        roles: [],
    }
    @observable updateRevenueWithExpenses = null
    @observable updateExpensesWithRevenue = null
    @observable showAllPhases = false
    constructor() {
        makeObservable(this)
    }
    @action.bound
    setQueryData(data, projectId, periodType, period, sidebarId) {
        this.queryData = data
        this.periodType = periodType
        this.period = period
        this.projectId = projectId
        this.sidebarId = sidebarId
        this.updateRevenueWithExpenses ??= shouldUpdateRevenueFromHours()
        this.updateExpensesWithRevenue ??= shouldUpdateHoursFromRevenue()
    }
    @action.bound
    setUpdateExpensesWithRevenue(value) {
        this.updateExpensesWithRevenue = value
    }
    @action.bound
    setUpdateRevenueWithExpenses(value) {
        this.updateRevenueWithExpenses = value
    }
    @action.bound
    setShowAllPhases(value) {
        this.showAllPhases = value
    }
    @action.bound
    setHoursInPeriod(
        period,
        projectId,
        phaseId,
        roleId,
        staffId,
        hours,
        phaseIds,
        staffIds,
        periodType
    ) {
        this.queryData ??= {}
        const correctSidebar =
            period === this.period &&
            projectId === this.projectId &&
            this.queryData != null
        const sidebarId = correctSidebar
            ? this.sidebarId
            : [period, projectId, phaseId, roleId, staffId].join(', ')
        let filteredHoursData = correctSidebar
            ? filterHoursData(
                  this.queryData?.hoursData || [],
                  staffId,
                  roleId,
                  projectId,
                  phaseId
              )
            : []
        if (!filteredHoursData.length) {
            filteredHoursData.push({
                projectId,
                phaseId,
                roleId,
                staffId,
                hoursInPeriod: 0,
                costInPeriod: 0,
                payInPeriod: 0,
                chargeOutInPeriod: 0,
            })
        }
        setHoursInPeriod(filteredHoursData, hours, this.showAllPhases)
        if (this.updateRevenueWithExpenses && correctSidebar) {
            setRevenueProgressFromCost(
                this.queryData.revenueData,
                this.queryData.hoursData,
                this.showAllPhases
            )
            // queryData.revenueData.forEach((d) => {
            //     const phase = PhaseCollection.phasesById[d.phaseId]
            //     onUpdateRevenue({
            //         period: periodId,
            //         revenue: d.revenueInPeriod,
            //         project,
            //         phase,
            //     })
            // })
        }
        this.queryData = {
            ...this.queryData,
        }
        saveChanges(sidebarId, {
            period: period,
            periodType: periodType || this.periodType,
            organisationId: SessionStore.organisationId,
            hoursData: filteredHoursData,
            revenueData: correctSidebar ? this.queryData.revenueData : [],
        })
    }
    @action.bound
    setRevenueInPeriod(period, projectId, phaseId, revenue) {
        this.queryData ??= {}
        const correctSidebar =
            period === this.period &&
            projectId === this.projectId &&
            this.queryData != null

        const sidebarId = correctSidebar
            ? this.sidebarId
            : [period, projectId, phaseId].join(', ')
        let filteredRevenueData = correctSidebar
            ? this.queryData?.revenueData.filter(
                  (d) => !phaseId || d.phaseId === phaseId
              ) || []
            : []
        if (!filteredRevenueData.length) {
            filteredRevenueData.push({
                projectId,
                phaseId,
                revenueInPeriod: 0,
            })
        }
        setRevenueInPeriod(filteredRevenueData, revenue, this.showAllPhases)
        if (this.updateExpensesWithRevenue && correctSidebar) {
            setCostProgressFromRevenue(
                this.queryData.revenueData,
                this.queryData.hoursData,
                this.showAllPhases
            )
            // queryData.hoursData.forEach((d) => {
            //     const staff = StaffCollection.staffById[d.staffId]
            //     const role = RoleCollection.rolesById[d.roleId] || staff?.role
            //     const phase = PhaseCollection.phasesById[d.phaseId]
            //     onUpdateHours({
            //         period: periodId,
            //         hours: d.hoursInPeriod,
            //         project,
            //         phase,
            //         staff,
            //         role,
            //     })
            // })
        }
        this.queryData = {
            ...this.queryData,
        }
        saveChanges(sidebarId, {
            period: period,
            periodType: this.periodType,
            organisationId: SessionStore.organisationId,
            revenueData: correctSidebar
                ? this.queryData?.revenueData
                : filteredRevenueData,
            hoursData: correctSidebar ? this.queryData.hoursData : [],
        })
    }
}

export const ProjectSidebarStore = new SidebarStore()

export const ProjectSidebarContent = observer(
    ({
        period,
        periodId,
        periodType,
        projectId,
        phaseId,
        staffId,
        roleId,
        staffIds = [],
        queryId,
        onUpdateHours = () => null,
        onUpdateRevenue = () => null,
    }) => {
        const sidebarId = [
            periodType,
            periodId,
            projectId,
            phaseId,
            staffId,
            roleId,
            staffIds.join(','),
        ].join(', ')
        const project = ProjectCollection.projectsById[projectId]
        const phase = PhaseCollection.phasesById[phaseId]
        useEffect(() => {
            if (phase && phase?.status !== 'active') {
                ProjectSidebarStore.setShowAllPhases(true)
            }
        }, [phase])
        const periodStart = ResourceScheduleStore.startOfPeriod(period)
        const periodEnd = ResourceScheduleStore.endOfPeriod(period)
        const queryData = ProjectSidebarStore.queryData
        const setQueryData = ProjectSidebarStore.setQueryData
        const updateExpensesWithRevenue =
            ProjectSidebarStore.updateExpensesWithRevenue
        const updateRevenueWithExpenses =
            ProjectSidebarStore.updateRevenueWithExpenses
        const setUpdateExpensesWithRevenue =
            ProjectSidebarStore.setUpdateExpensesWithRevenue
        const setUpdateRevenueWithExpenses =
            ProjectSidebarStore.setUpdateRevenueWithExpenses
        const queriesData = queryClient.getQueryData([queryId])?.data
        const showAllPhases = ProjectSidebarStore.showAllPhases
        useEffect(() => {
            setQueryData(
                queriesData,
                projectId,
                periodType,
                periodId,
                sidebarId
            )
            return () => {
                setQueryData({
                    revenueData: [],
                    hoursData: [],
                    phases: [],
                    staff: [],
                    roles: [],
                })
                queryClient.removeQueries([queryId])
            }
        }, [
            projectId,
            phaseId,
            staffId,
            roleId,
            periodType,
            periodId,
            queriesData,
        ])
        const filteredHoursData = filterHoursData(
            queryData?.hoursData || [],
            staffId,
            roleId,
            projectId,
            phaseId
        )
        const showRevenue =
            periodType === 'month' &&
            canViewProjectFees(SessionStore.user, project) &&
            canViewRevenueTargets(SessionStore.user, project)

        const showExpenses =
            canViewStaffAllocations(SessionStore.user, project) &&
            canViewStaffCostRate(SessionStore.user)

        if (!queryData) {
            return null
        }

        return (
            <div key={sidebarId} style={{ padding: '1em' }}>
                <div className="text-right pb-4">
                    <Checkbox
                        onChange={(e) => {
                            ProjectSidebarStore.setShowAllPhases(
                                e.target.checked
                            )
                        }}
                        checked={ProjectSidebarStore.showAllPhases}
                    >
                        Show All Phases
                    </Checkbox>
                </div>
                {showRevenue ? (
                    <>
                        <h4>
                            Revenue
                            {canEditStaffAllocations(SessionStore.user) ? (
                                <Checkbox
                                    onChange={(e) => {
                                        const { checked } = e.target
                                        setUpdateExpensesWithRevenue(checked)
                                    }}
                                    checked={updateExpensesWithRevenue}
                                    style={{
                                        width: '45%',
                                        float: 'right',
                                        fontSize: '0.65em',
                                    }}
                                >
                                    Update Expenses With Revenue
                                </Checkbox>
                            ) : null}
                        </h4>
                        <div style={{ padding: '1em' }}>
                            {!phaseId ? (
                                <RevenueProgressInput
                                    key={'rev' + periodId + projectId}
                                    label={project.title}
                                    editable={canEditRevenueTargets(
                                        SessionStore.user
                                    )}
                                    month={period}
                                    fee={getTotalFee(queryData.revenueData)}
                                    previousRevenue={getRevenueBeforePeriod(
                                        queryData.revenueData
                                    )}
                                    currentRevenue={getRevenueInPeriod(
                                        queryData.revenueData
                                    )}
                                    onChange={(v) => {
                                        setRevenueInPeriod(
                                            queryData.revenueData,
                                            v,
                                            showAllPhases
                                        )
                                        queryData.revenueData.forEach((d) => {
                                            const phase =
                                                PhaseCollection.phasesById[
                                                    d.phaseId
                                                ]
                                            onUpdateRevenue({
                                                period: periodId,
                                                revenue: d.revenueInPeriod,
                                                project,
                                                phase,
                                            })
                                        })
                                        if (updateExpensesWithRevenue) {
                                            setCostProgressFromRevenue(
                                                queryData.revenueData,
                                                queryData.hoursData,
                                                showAllPhases
                                            )
                                            onUpdateHours(
                                                queryData.hoursData.map(
                                                    (hd) => {
                                                        const staff =
                                                            StaffCollection
                                                                .staffById[
                                                                hd.staffId
                                                            ]
                                                        const phase =
                                                            PhaseCollection
                                                                .phasesById[
                                                                hd.phaseId
                                                            ]
                                                        const role =
                                                            RoleCollection
                                                                .rolesById[
                                                                hd.roleId
                                                            ] || staff?.role
                                                        return {
                                                            period: periodId,
                                                            hours: hd.hoursInPeriod,
                                                            project,
                                                            phase,
                                                            staff,
                                                            role,
                                                        }
                                                    }
                                                )
                                            )
                                        }
                                        setQueryData({
                                            ...queryData,
                                        })
                                        saveChanges(sidebarId, {
                                            period: periodId,
                                            periodType,
                                            organisationId:
                                                SessionStore.organisationId,
                                            hoursData: queryData.hoursData,
                                            revenueData: queryData.revenueData,
                                        })
                                    }}
                                />
                            ) : null}
                            <div style={{ padding: '2em 0' }}>
                                {queryData.phases
                                    .filter(
                                        (ph) =>
                                            showAllPhases ||
                                            ph.status === 'active'
                                    )
                                    .sort(sortPhases)
                                    .map((ph) => {
                                        const phase =
                                            PhaseCollection.phasesById[ph.id]
                                        const revData =
                                            queryData.revenueData.filter(
                                                (d) => d.phaseId === ph.id
                                            )
                                        return (
                                            <RevenueProgressInput
                                                key={'rev' + periodId + ph.id}
                                                label={phase.title}
                                                editable={
                                                    canEditRevenueTargets(
                                                        SessionStore.user
                                                    ) &&
                                                    (showAllPhases
                                                        ? phase.status !==
                                                          'archived'
                                                        : phase.status ===
                                                          'active')
                                                }
                                                month={period}
                                                fee={_.sum(
                                                    revData.map((d) => d.fee)
                                                )}
                                                previousRevenue={_.sum(
                                                    revData.map(
                                                        (d) =>
                                                            d.revenueBeforePeriod
                                                    )
                                                )}
                                                currentRevenue={_.sum(
                                                    revData.map(
                                                        (d) => d.revenueInPeriod
                                                    )
                                                )}
                                                onChange={(v) => {
                                                    setRevenueInPeriod(
                                                        revData,
                                                        v,
                                                        showAllPhases
                                                    )
                                                    revData.forEach((d) => {
                                                        const phase =
                                                            PhaseCollection
                                                                .phasesById[
                                                                d.phaseId
                                                            ]
                                                        onUpdateRevenue({
                                                            period: periodId,
                                                            revenue:
                                                                d.revenueInPeriod,
                                                            project,
                                                            phase,
                                                        })
                                                    })
                                                    if (
                                                        updateExpensesWithRevenue
                                                    ) {
                                                        setCostProgressFromRevenue(
                                                            queryData.revenueData,
                                                            queryData.hoursData,
                                                            showAllPhases
                                                        )
                                                        onUpdateHours(
                                                            queryData.hoursData.map(
                                                                (hd) => {
                                                                    const staff =
                                                                        StaffCollection
                                                                            .staffById[
                                                                            hd
                                                                                .staffId
                                                                        ]
                                                                    const phase =
                                                                        PhaseCollection
                                                                            .phasesById[
                                                                            hd
                                                                                .phaseId
                                                                        ]
                                                                    const role =
                                                                        RoleCollection
                                                                            .rolesById[
                                                                            hd
                                                                                .roleId
                                                                        ] ||
                                                                        staff?.role
                                                                    return {
                                                                        period: periodId,
                                                                        hours: hd.hoursInPeriod,
                                                                        project,
                                                                        phase,
                                                                        staff,
                                                                        role,
                                                                    }
                                                                }
                                                            )
                                                        )
                                                    }
                                                    setQueryData({
                                                        ...queryData,
                                                    })
                                                    saveChanges(sidebarId, {
                                                        period: periodId,
                                                        periodType,
                                                        organisationId:
                                                            SessionStore.organisationId,
                                                        hoursData:
                                                            queryData.hoursData,
                                                        revenueData:
                                                            queryData.revenueData,
                                                    })
                                                }}
                                            />
                                        )
                                    })}
                            </div>
                        </div>
                    </>
                ) : null}
                {showExpenses ? (
                    <>
                        {' '}
                        <h4>
                            Expenses
                            {canEditRevenueTargets(
                                SessionStore.user,
                                project
                            ) && showRevenue ? (
                                <Checkbox
                                    onChange={(e) => {
                                        const { checked } = e.target
                                        setUpdateRevenueWithExpenses(checked)
                                    }}
                                    checked={updateRevenueWithExpenses}
                                    style={{
                                        width: '45%',
                                        float: 'right',
                                        fontSize: '0.65em',
                                    }}
                                >
                                    Update Revenue With Expenses
                                </Checkbox>
                            ) : null}
                        </h4>
                        <div style={{ padding: '1em' }}>
                            {!phaseId ? (
                                <ExpenseProgressInput
                                    editable={canEditStaffAllocations(
                                        SessionStore.user,
                                        project
                                    )}
                                    month={period}
                                    onChange={(v) => {
                                        setCostInPeriod(
                                            filteredHoursData,
                                            v,
                                            showAllPhases
                                        )
                                        if (
                                            updateRevenueWithExpenses &&
                                            showRevenue
                                        ) {
                                            setRevenueProgressFromCost(
                                                queryData.revenueData,
                                                queryData.hoursData,
                                                showAllPhases
                                            )
                                            queryData.revenueData.forEach(
                                                (d) => {
                                                    const phase =
                                                        PhaseCollection
                                                            .phasesById[
                                                            d.phaseId
                                                        ]
                                                    onUpdateRevenue({
                                                        period: periodId,
                                                        revenue:
                                                            d.revenueInPeriod,
                                                        project,
                                                        phase,
                                                    })
                                                }
                                            )
                                        }
                                        setQueryData({
                                            ...queryData,
                                        })
                                        onUpdateHours(
                                            filteredHoursData.map((hd) => {
                                                const staff =
                                                    StaffCollection.staffById[
                                                        hd.staffId
                                                    ]
                                                const phase =
                                                    PhaseCollection.phasesById[
                                                        hd.phaseId
                                                    ]
                                                const role =
                                                    RoleCollection.rolesById[
                                                        hd.roleId
                                                    ] || staff?.role
                                                return {
                                                    period: periodId,
                                                    hours: hd.hoursInPeriod,
                                                    project,
                                                    phase,
                                                    staff,
                                                    role,
                                                }
                                            })
                                        )
                                        saveChanges(sidebarId, {
                                            period: periodId,
                                            periodType,
                                            organisationId:
                                                SessionStore.organisationId,
                                            hoursData: filteredHoursData,
                                            revenueData: queryData.revenueData,
                                        })
                                    }}
                                    onTimeChange={(v) => {
                                        setHoursInPeriod(
                                            filteredHoursData,
                                            v,
                                            showAllPhases
                                        )
                                        if (
                                            updateRevenueWithExpenses &&
                                            showRevenue
                                        ) {
                                            setRevenueProgressFromCost(
                                                queryData.revenueData,
                                                queryData.hoursData,
                                                showAllPhases
                                            )
                                            queryData.revenueData.forEach(
                                                (d) => {
                                                    const phase =
                                                        PhaseCollection
                                                            .phasesById[
                                                            d.phaseId
                                                        ]
                                                    onUpdateRevenue({
                                                        period: periodId,
                                                        revenue:
                                                            d.revenueInPeriod,
                                                        project,
                                                        phase,
                                                    })
                                                }
                                            )
                                        }
                                        setQueryData({
                                            ...queryData,
                                        })
                                        onUpdateHours(
                                            filteredHoursData.map((hd) => {
                                                const staff =
                                                    StaffCollection.staffById[
                                                        hd.staffId
                                                    ]
                                                const phase =
                                                    PhaseCollection.phasesById[
                                                        hd.phaseId
                                                    ]
                                                const role =
                                                    RoleCollection.rolesById[
                                                        hd.roleId
                                                    ] || staff?.role
                                                return {
                                                    period: periodId,
                                                    hours: hd.hoursInPeriod,
                                                    project,
                                                    phase,
                                                    staff,
                                                    role,
                                                }
                                            })
                                        )
                                        saveChanges(sidebarId, {
                                            period: periodId,
                                            periodType,
                                            organisationId:
                                                SessionStore.organisationId,
                                            hoursData: filteredHoursData,
                                            revenueData: queryData.revenueData,
                                        })
                                    }}
                                    costBudget={getCostBudget(
                                        filteredHoursData
                                    )}
                                    hoursBudget={getHoursBudget(
                                        filteredHoursData
                                    )}
                                    previousCost={getCostBeforePeriod(
                                        filteredHoursData
                                    )}
                                    previousHours={getHoursBeforePeriod(
                                        filteredHoursData
                                    )}
                                    currentCost={getCostInPeriod(
                                        filteredHoursData
                                    )}
                                    currentHours={getHoursInPeriod(
                                        filteredHoursData
                                    )}
                                    key={'res' + periodId + projectId}
                                    label={project.title}
                                />
                            ) : null}
                            <div style={{ padding: '2em 0' }}>
                                {queryData.phases
                                    .filter(
                                        (ph) =>
                                            showAllPhases ||
                                            ph.status === 'active'
                                    )
                                    .sort(sortPhases)
                                    .map((ph) => {
                                        const phase =
                                            PhaseCollection.phasesById[ph.id]
                                        const hoursData =
                                            filteredHoursData.filter(
                                                (d) => d.phaseId === ph.id
                                            )
                                        return (
                                            <ExpenseProgressInput
                                                editable={
                                                    canEditStaffAllocations(
                                                        SessionStore.user,
                                                        project
                                                    ) &&
                                                    (showAllPhases
                                                        ? phase.status !==
                                                          'archived'
                                                        : phase.status ===
                                                          'active')
                                                }
                                                month={period}
                                                onChange={(v) => {
                                                    setCostInPeriod(
                                                        hoursData,
                                                        v,
                                                        showAllPhases
                                                    )
                                                    if (
                                                        updateRevenueWithExpenses &&
                                                        showRevenue
                                                    ) {
                                                        setRevenueProgressFromCost(
                                                            queryData.revenueData,
                                                            queryData.hoursData,
                                                            showAllPhases
                                                        )
                                                        queryData.revenueData.forEach(
                                                            (d) => {
                                                                const phase =
                                                                    PhaseCollection
                                                                        .phasesById[
                                                                        d
                                                                            .phaseId
                                                                    ]
                                                                onUpdateRevenue(
                                                                    {
                                                                        period: periodId,
                                                                        revenue:
                                                                            d.revenueInPeriod,
                                                                        project,
                                                                        phase,
                                                                    }
                                                                )
                                                            }
                                                        )
                                                    }
                                                    setQueryData({
                                                        ...queryData,
                                                    })

                                                    onUpdateHours(
                                                        hoursData.map((hd) => {
                                                            const staff =
                                                                StaffCollection
                                                                    .staffById[
                                                                    hd.staffId
                                                                ]
                                                            const role =
                                                                RoleCollection
                                                                    .rolesById[
                                                                    hd.roleId
                                                                ] || staff?.role
                                                            return {
                                                                period: periodId,
                                                                hours: hd.hoursInPeriod,
                                                                project,
                                                                phase,
                                                                staff,
                                                                role,
                                                            }
                                                        })
                                                    )
                                                    saveChanges(sidebarId, {
                                                        period: periodId,
                                                        periodType,
                                                        organisationId:
                                                            SessionStore.organisationId,
                                                        hoursData: hoursData,
                                                        revenueData:
                                                            queryData.revenueData,
                                                    })
                                                }}
                                                onTimeChange={(v) => {
                                                    setHoursInPeriod(
                                                        hoursData,
                                                        v,
                                                        showAllPhases
                                                    )
                                                    if (
                                                        updateRevenueWithExpenses &&
                                                        showRevenue
                                                    ) {
                                                        setRevenueProgressFromCost(
                                                            queryData.revenueData,
                                                            queryData.hoursData,
                                                            showAllPhases
                                                        )
                                                        queryData.revenueData.forEach(
                                                            (d) => {
                                                                const phase =
                                                                    PhaseCollection
                                                                        .phasesById[
                                                                        d
                                                                            .phaseId
                                                                    ]
                                                                onUpdateRevenue(
                                                                    {
                                                                        period: periodId,
                                                                        revenue:
                                                                            d.revenueInPeriod,
                                                                        project,
                                                                        phase,
                                                                    }
                                                                )
                                                            }
                                                        )
                                                    }
                                                    setQueryData({
                                                        ...queryData,
                                                    })

                                                    onUpdateHours(
                                                        hoursData.map((hd) => {
                                                            const staff =
                                                                StaffCollection
                                                                    .staffById[
                                                                    hd.staffId
                                                                ]
                                                            const role =
                                                                RoleCollection
                                                                    .rolesById[
                                                                    hd.roleId
                                                                ] || staff?.role
                                                            return {
                                                                period: periodId,
                                                                hours: hd.hoursInPeriod,
                                                                project,
                                                                phase,
                                                                staff,
                                                                role,
                                                            }
                                                        })
                                                    )
                                                    saveChanges(sidebarId, {
                                                        period: periodId,
                                                        periodType,
                                                        organisationId:
                                                            SessionStore.organisationId,
                                                        hoursData: hoursData,
                                                        revenueData:
                                                            queryData.revenueData,
                                                    })
                                                }}
                                                costBudget={getCostBudget(
                                                    hoursData
                                                )}
                                                hoursBudget={getHoursBudget(
                                                    hoursData
                                                )}
                                                previousCost={getCostBeforePeriod(
                                                    hoursData
                                                )}
                                                previousHours={getHoursBeforePeriod(
                                                    hoursData
                                                )}
                                                currentCost={getCostInPeriod(
                                                    hoursData
                                                )}
                                                currentHours={getHoursInPeriod(
                                                    hoursData
                                                )}
                                                key={'res' + periodId + ph.id}
                                                label={phase.title}
                                            />
                                        )
                                    })}
                            </div>
                        </div>
                    </>
                ) : null}
                <RenderOnQueries
                    queryIds={[
                        {
                            id: `staff-util-${project.id}-${periodId}`,
                            baseURL: process.env.REACT_APP_NODE_SERVER_URL,
                            path: `/staff-utilisation/without-project`,
                            method: 'POST',
                            data: {
                                period: periodId,
                                periodType: periodType,
                                projectId: project.id,
                                organisationId: SessionStore.organisationId,
                                userId: SessionStore.user?.id,
                            },
                        },
                    ]}
                >
                    <StaffUtilisation
                        staffIds={staffIds}
                        project={project}
                        period={period}
                        periodId={periodId}
                        periodStart={periodStart}
                        periodEnd={periodEnd}
                        periodType={periodType}
                        filteredHoursData={filteredHoursData}
                        queryData={queryData}
                        sidebarId={sidebarId}
                        projectId={projectId}
                        updateRevenueWithExpenses={updateRevenueWithExpenses}
                        showRevenue={showRevenue}
                        setQueryData={setQueryData}
                        onUpdateHours={onUpdateHours}
                        onUpdateRevenue={onUpdateRevenue}
                        showAllPhases={showAllPhases}
                    />
                </RenderOnQueries>
                <RenderOnQueries
                    queryIds={[
                        {
                            id: `rev-source-${projectId}-${periodId}`,
                            baseURL: process.env.REACT_APP_NODE_SERVER_URL,
                            path: `/revenue-sources/`,
                            method: 'POST',
                            data: {
                                period: periodId,
                                periodType: periodType,
                                projectId: projectId,
                                organisationId: SessionStore.organisationId,
                                userId: SessionStore.user?.id,
                                invoiceDateType:
                                    SessionStore.settings.reportInvoiceDateType,
                            },
                        },
                    ]}
                >
                    <RevenueSources
                        project={project}
                        period={period}
                        periodId={periodId}
                    />
                </RenderOnQueries>
            </div>
        )
    }
)

const StaffUtilisation = observer(
    ({
        staffIds,
        project,
        period,
        periodId,
        periodStart,
        periodEnd,
        periodType,
        filteredHoursData,
        queryData,
        sidebarId,
        projectId,
        updateRevenueWithExpenses,
        showRevenue,
        setQueryData,
        onUpdateHours,
        onUpdateRevenue,
        showAllPhases,
    }) => {
        const staffUtilData = queryClient.getQueryData([
            `staff-util-${project.id}-${periodId}`,
        ])?.data
        const uniqueStaffIds = [
            ...new Set(
                [
                    ...staffIds,
                    ...filteredHoursData.map((d) => d.staffId),
                ].filter((stId) => {
                    const staff = StaffCollection.staffById[stId]
                    const hoursData = filteredHoursData.filter(
                        (d) => d.staffId === staff.id
                    )
                    const totalHours = getHoursInPeriod(hoursData)
                    return totalHours > 0 || staff.isArchived === false
                })
            ),
        ]
        return (
            <>
                <h4>Utilisation</h4>
                <div style={{ padding: '1em' }}>
                    {uniqueStaffIds.filter(Boolean).map((stId) => {
                        const staff = StaffCollection.staffById[stId]
                        const hoursData = filteredHoursData.filter(
                            (d) => d.staffId === staff.id
                        )
                        return (
                            <UtilisationProgressInput
                                key={'util' + staff.id + projectId + periodId}
                                label={staff.fullName}
                                editable={canEditStaffAllocations(
                                    SessionStore.user,
                                    project
                                )}
                                month={period}
                                staff={staff}
                                availability={staff.getAvailableHoursInDateRange(
                                    [periodStart, periodEnd]
                                )}
                                totalHours={
                                    getHoursInPeriod(hoursData) +
                                    (staffUtilData[staff.id]?.hours ?? 0)
                                }
                                selectedHours={getHoursInPeriod(hoursData)}
                                onChange={(v) => {
                                    setHoursInPeriod(
                                        hoursData,
                                        v,
                                        showAllPhases
                                    )
                                    if (
                                        updateRevenueWithExpenses &&
                                        showRevenue
                                    ) {
                                        setRevenueProgressFromCost(
                                            queryData.revenueData,
                                            queryData.hoursData,
                                            showAllPhases
                                        )
                                        queryData.revenueData.forEach((d) => {
                                            const phase =
                                                PhaseCollection.phasesById[
                                                    d.phaseId
                                                ]
                                            onUpdateRevenue({
                                                period: periodId,
                                                revenue: d.revenueInPeriod,
                                                project,
                                                phase,
                                            })
                                        })
                                    }
                                    setQueryData({
                                        ...queryData,
                                    })
                                    onUpdateHours(
                                        hoursData.map((hd) => {
                                            const staff =
                                                StaffCollection.staffById[
                                                    hd.staffId
                                                ]
                                            const phase =
                                                PhaseCollection.phasesById[
                                                    hd.phaseId
                                                ]
                                            const role =
                                                RoleCollection.rolesById[
                                                    hd.roleId
                                                ] || staff?.role
                                            return {
                                                period: periodId,
                                                hours: hd.hoursInPeriod,
                                                project,
                                                phase,
                                                staff,
                                                role,
                                            }
                                        })
                                    )
                                    saveChanges(sidebarId, {
                                        period: periodId,
                                        periodType,
                                        organisationId:
                                            SessionStore.organisationId,
                                        hoursData: hoursData,
                                        revenueData: queryData.revenueData,
                                    })
                                }}
                            />
                        )
                    })}
                </div>
            </>
        )
    }
)

const RevenueSources = observer(({ project, period, periodId }) => {
    const sources = queryClient.getQueryData([
        `rev-source-${project.id}-${periodId}`,
    ])?.data
    if (!sources?.invoices?.length && !sources?.changeLogs?.length) return null
    return (
        <div
            style={{
                borderTop: '1px solid #ccc',
                paddingTop: '1em',
                marginTop: '1em',
            }}
        >
            <h4>Revenue Sources</h4>
            <div style={{ padding: '1em' }}>
                {sources?.invoices?.length ? (
                    <div>
                        <div style={{ fontWeight: 'bold' }}>Invoices</div>
                        {sources?.invoices?.map((source) => {
                            return (
                                <div
                                    key={source.id}
                                    style={{
                                        display: 'flex',
                                        justifyContent: 'space-between',
                                        padding: '0.5em 0',
                                    }}
                                >
                                    <Link to={`/invoices/${source.id}`}>
                                        {' '}
                                        {source.ref}
                                    </Link>
                                    <div>{FormatCurrency(source.amount)}</div>
                                </div>
                            )
                        })}
                    </div>
                ) : null}
                {sources?.changeLogs?.length ? (
                    <div>
                        <div style={{ fontWeight: 'bold' }}>Change Log</div>
                        {sources?.changeLogs?.map((source) => {
                            return (
                                <div
                                    key={source.id}
                                    style={{
                                        display: 'flex',
                                        justifyContent: 'space-between',
                                        padding: '0.5em 0',
                                    }}
                                >
                                    <div>{source.title}</div>
                                    <div>
                                        {FormatCurrency(
                                            (source.revenue || 0) -
                                                (source.expenses || 0)
                                        )}
                                    </div>
                                </div>
                            )
                        })}
                    </div>
                ) : null}
            </div>
        </div>
    )
})

const splitValueAmongstObjects = (objects, value, key) => {
    const currentTotal = _.sum(objects.map((o) => o[key]))
    if (currentTotal === 0) {
        objects.forEach((o) => {
            o[key] = value / objects.length
        })
    } else {
        const ratio = value / currentTotal
        objects.forEach((o) => {
            o[key] = o[key] * ratio
        })
    }
    return objects
}

const filterHoursData = (hoursData, staffId, roleId, projectId, phaseId) => {
    return hoursData.filter((d) => {
        return (
            ((!staffId && !roleId) ||
                (staffId && staffId === d.staffId) ||
                (!staffId && roleId === d.roleId)) &&
            (!phaseId || phaseId === d.phaseId) &&
            (!projectId || projectId === d.projectId)
        )
    })
}

const getTotalFee = (revenueData) => {
    return _.sum(revenueData.map((d) => d.fee || 0))
}
const getRevenueBeforePeriod = (revenueData) => {
    return _.sum(revenueData.map((d) => d.revenueBeforePeriod || 0))
}
const getRevenueInPeriod = (revenueData) => {
    return _.sum(revenueData.map((d) => d.revenueInPeriod || 0))
}
const getCostBeforePeriod = (hoursData) => {
    return _.sum(hoursData.map((d) => d.costBeforePeriod || 0))
}
const getCostInPeriod = (hoursData) => {
    return _.sum(hoursData.map((d) => d.costInPeriod || 0))
}
const getHoursBeforePeriod = (hoursData) => {
    return _.sum(hoursData.map((d) => d.hoursBeforePeriod || 0))
}
const getHoursInPeriod = (hoursData) => {
    return _.sum(hoursData.map((d) => d.hoursInPeriod || 0))
}
const getHoursBudget = (hoursData) => {
    return _.sum(hoursData.map((d) => d.hoursBudget || 0))
}
const getCostBudget = (hoursData) => {
    return _.sum(
        hoursData.map((d) => {
            const project = ProjectCollection.projectsById[d.projectId]
            const phase = PhaseCollection.phasesById[d.phaseId]
            const staff = StaffCollection.staffById[d.staffId]
            const role = RoleCollection.rolesById[d?.roleId] || staff?.role
            const models = {
                staff,
                role,
                project,
                phase,
            }
            const rate = getCombinedRateInDateRange(models, 'cost', [
                phase?.startDate,
                phase?.endDate,
            ])
            return d.hoursBudget * rate || 0
        })
    )
}

const setRevenueInPeriod = (revenueData, value, showAllPhases) => {
    const filteredRevenueData = revenueData.filter((d) => {
        const phase = PhaseCollection.phasesById[d.phaseId]
        return (
            phase &&
            (showAllPhases
                ? phase.status !== 'archived'
                : phase.status === 'active')
        )
    })
    revenueData = filteredRevenueData.length ? filteredRevenueData : revenueData
    splitValueAmongstObjects(revenueData, value, 'revenueInPeriod')
}

const setHoursInPeriod = (hoursData, value, showAllPhases) => {
    const filteredHoursData = hoursData.filter((d) => {
        const phase = PhaseCollection.phasesById[d.phaseId]
        const staff = StaffCollection.staffById[d.staffId]
        const role = RoleCollection.rolesById[d?.roleId] || staff?.role
        return (
            phase &&
            (showAllPhases
                ? phase.status !== 'archived'
                : phase.status === 'active') &&
            (!staff || !staff?.isArchived) &&
            (!role || !role?.isArchived)
        )
    })
    hoursData = filteredHoursData.length ? filteredHoursData : hoursData
    splitValueAmongstObjects(hoursData, value, 'hoursInPeriod')
    hoursData.forEach((d) => {
        const project = ProjectCollection.projectsById[d.projectId]
        const phase = PhaseCollection.phasesById[d.phaseId]
        const staff = StaffCollection.staffById[d.staffId]
        const role = RoleCollection.rolesById[d?.roleId] || staff?.role
        const models = {
            staff,
            role,
            project,
            phase,
        }
        const rate = getCombinedRateInDateRange(models, 'cost', [
            phase?.startDate,
            phase?.endDate,
        ])
        d.costInPeriod = d.hoursInPeriod * rate
        const payRate = getCombinedRateInDateRange(models, 'pay', [
            phase?.startDate,
            phase?.endDate,
        ])
        const chargeOutRate = getCombinedRateInDateRange(models, 'chargeOut', [
            phase?.startDate,
            phase?.endDate,
        ])
        d.payInPeriod = d.hoursInPeriod * payRate
        d.chargeOutInPeriod = d.hoursInPeriod * chargeOutRate
    })
}

const setCostInPeriod = (hoursData, value, showAllPhases) => {
    hoursData = hoursData.filter((d) => {
        const phase = PhaseCollection.phasesById[d.phaseId]
        return (
            phase &&
            (showAllPhases
                ? phase.status !== 'archived'
                : phase.status === 'active')
        )
    })
    splitValueAmongstObjects(hoursData, value, 'costInPeriod')
    hoursData.forEach((d) => {
        const project = ProjectCollection.projectsById[d.projectId]
        const phase = PhaseCollection.phasesById[d.phaseId]
        const staff = StaffCollection.staffById[d.staffId]
        const role = RoleCollection.rolesById[d?.roleId] || staff?.role
        const models = {
            staff,
            role,
            project,
            phase,
        }
        const rate = getCombinedRateInDateRange(models, 'cost', [
            phase?.startDate,
            phase?.endDate,
        ])
        d.hoursInPeriod = d.costInPeriod / rate
        const payRate = getCombinedRateInDateRange(models, 'pay', [
            phase?.startDate,
            phase?.endDate,
        ])
        const chargeOutRate = getCombinedRateInDateRange(models, 'chargeOut', [
            phase?.startDate,
            phase?.endDate,
        ])
        d.payInPeriod = d.hoursInPeriod * payRate
        d.chargeOutInPeriod = d.hoursInPeriod * chargeOutRate
    })
}

const getRevenueProgress = (revenueData) => {
    return (
        (getRevenueBeforePeriod(revenueData) +
            getRevenueInPeriod(revenueData)) /
            getTotalFee(revenueData) || 0
    )
}

const getCostProgress = (hoursData) => {
    return (
        (getCostBeforePeriod(hoursData) + getCostInPeriod(hoursData)) /
            getCostBudget(hoursData) || 0
    )
}

const setRevenueProgress = (revenueData, value, showAllPhases) => {
    revenueData = revenueData.filter((d) => {
        const phase = PhaseCollection.phasesById[d.phaseId]
        return (
            phase &&
            (showAllPhases
                ? phase.status !== 'archived'
                : phase.status === 'active')
        )
    })
    if (!isFinite(value) || !getTotalFee(revenueData)) return
    const previousRevenue = getRevenueBeforePeriod(revenueData)
    const totalRevenue = getTotalFee(revenueData) * value
    const newValue = Math.max(totalRevenue - previousRevenue, 0)
    setRevenueInPeriod(revenueData, newValue, showAllPhases)
}

const setCostProgress = (hoursData, value, showAllPhases) => {
    hoursData = hoursData.filter((d) => {
        const phase = PhaseCollection.phasesById[d.phaseId]
        return (
            phase &&
            (showAllPhases
                ? phase.status !== 'archived'
                : phase.status === 'active')
        )
    })
    if (!isFinite(value) || !getCostBudget(hoursData)) return
    const previousCost = getCostBeforePeriod(hoursData)
    const totalCost = getCostBudget(hoursData) * value
    const newValue = Math.max(totalCost - previousCost, 0)
    setCostInPeriod(hoursData, newValue, showAllPhases)
}

const setRevenueProgressFromCost = (revenueData, hoursData, showAllPhases) => {
    revenueData = revenueData.filter((d) => {
        const phase = PhaseCollection.phasesById[d.phaseId]
        return (
            phase &&
            (showAllPhases
                ? phase.status !== 'archived'
                : phase.status === 'active')
        )
    })
    hoursData = hoursData.filter((d) => {
        const phase = PhaseCollection.phasesById[d.phaseId]
        return (
            phase &&
            (showAllPhases
                ? phase.status !== 'archived'
                : phase.status === 'active')
        )
    })
    revenueData.forEach((rd) => {
        const filteredHoursData = filterHoursData(
            hoursData,
            rd.staffId,
            rd.roleId,
            rd.projectId,
            rd.phaseId
        )
        const costProgress = getCostProgress(filteredHoursData)
        if (isFinite(costProgress)) {
            setRevenueProgress([rd], costProgress, showAllPhases)
        }
    })
}

const setCostProgressFromRevenue = (revenueData, hoursData, showAllPhases) => {
    revenueData = revenueData.filter((d) => {
        const phase = PhaseCollection.phasesById[d.phaseId]
        return (
            phase &&
            phase.status &&
            (showAllPhases
                ? phase.status !== 'archived'
                : phase.status === 'active')
        )
    })
    hoursData = hoursData.filter((d) => {
        const phase = PhaseCollection.phasesById[d.phaseId]
        return (
            phase &&
            phase.status &&
            (showAllPhases
                ? phase.status !== 'archived'
                : phase.status === 'active')
        )
    })
    hoursData.forEach((hd) => {
        const filteredRevenueData = revenueData.filter(
            (rd) => rd.phaseId === hd.phaseId
        )
        const revenueProgress = getRevenueProgress(filteredRevenueData)
        if (isFinite(revenueProgress)) {
            setCostProgress([hd], revenueProgress, showAllPhases)
        }
    })
}

const saveChanges = (id, queryData) => {
    const DataStore = require('../../State/DataStore').default
    DataStore.addSaveFunction(id, async () => {
        await makeRequest({
            baseURL: process.env.REACT_APP_NODE_SERVER_URL,
            path: `/project-forecast-sidebar/save`,
            method: 'POST',
            data: {
                ...queryData,
                organisationId: SessionStore.organisationId,
                userId: SessionStore.user?.id,
            },
        })
    })
}
