import _ from 'lodash'
import { observer } from 'mobx-react'
import React, { useState } from 'react'
import Modal from '../../Components/Modal'
import DataStore from '../../State/DataStore'
import { useNavigate } from '@tanstack/react-router'

export default observer(({ role, modalId }) => {
    const navigate = useNavigate()
    return (
        <Modal
            modalId={modalId}
            heading="Delete Staff Role"
            saveButtons={[
                {
                    label: 'Archive Role',
                    onClick: () => {
                        role.update({ isArchived: true })
                        DataStore.saveModel(role)
                        navigate({ to: '/staff-roles/' })
                    },
                },
                {
                    label: 'Delete Role',
                    onClick: () => {
                        role.update({ deletedAt: new Date() })
                        DataStore.saveModel(role)
                        navigate({ to: '/staff-roles/' })
                    },
                },
            ]}
        >
            <p style={{ margin: '1em' }}>
                Are you sure you wish to delete <em>{role.name}</em>?
            </p>
        </Modal>
    )
})
