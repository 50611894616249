import { jsx, css } from '@emotion/react'
import { observer } from 'mobx-react'
import React, { useEffect, useRef, useState } from 'react'
import TableStore from './TableStore'
import RenderOnQueries from '../Pages/Layout/RenderOnQueries'
import { useMediaQuery } from '@react-hook/media-query'
import useHover from '@react-hook/hover'
import useResizeObserver from '@react-hook/resize-observer'
import _ from 'lodash'
import { DragDropContext, Draggable, Droppable } from '@hello-pangea/dnd'

const useSize = (target) => {
    const [size, setSize] = React.useState()

    React.useLayoutEffect(() => {
        setSize(target.current.getBoundingClientRect())
    }, [target])

    // Where the magic happens
    useResizeObserver(target, (entry) => setSize(entry.contentRect))
    return size
}

function remToPx(rem) {
    const remToPxRatio = 10
    return rem * remToPxRatio
}

export default observer(
    ({
        id,
        tableStore,
        columns,
        rows,
        filters,
        interfaceRows,
        getChildComponent,
        showHeader = true,
        showTotals = false,
        className = '',
        headerClassName = '',
        headerStyle = {},
        style,
        sortBy,
        groupBy,
        expandedGroups,
        newRowDirection = 'desc',
        queryResults,
        onExpand,
        onCollapse,
        topRow,
        expandAll,
        totalsRowStyles,
        totalsCellStyles,
        draggable = false,
        onReorder,
        ...props
    }) => {
        const isPrinting = useMediaQuery('print')
        const [store, setStore] = useState(tableStore || new TableStore())
        useEffect(() => {
            if (tableStore && tableStore !== store) {
                setStore(tableStore)
                tableStore.update({
                    columns,
                    rows,
                    getChildComponent,
                    showHeader,
                    showTotals,
                    sortBy,
                    groupBy,
                    expandedGroups,
                    filters,
                    newRowDirection,
                    onExpand,
                    onCollapse,
                    expandAll,
                    style,
                    draggable,
                })
            }
        }, [tableStore, store])
        const target = React.useRef(null)
        const size = useSize(target)
        const width = _.sum(
            store.columns
                .filter((c) => !isPrinting || c.print)
                .map((c) => c.width)
        )
        const widthRatio = size?.width / (width * 12)
        useEffect(() => {
            store.update({
                columns,
                rows,
                getChildComponent,
                showHeader,
                showTotals,
                sortBy,
                groupBy,
                expandedGroups,
                filters,
                newRowDirection,
                onExpand,
                onCollapse,
                expandAll,
                style,
                draggable,
            })
        }, [rows, columns])

        return (
            <div
                ref={target}
                className={
                    'coincraft-table w-full mx-auto max-h-max mb-auto' +
                    className
                }
                {...props}
                css={css({
                    width: `${remToPx(width)}px`,
                    '@media print': {
                        width: '100%',
                    },
                })}
                style={{
                    ...style,
                }}
            >
                {store.showHeader ? (
                    <TableHeader
                        store={store}
                        className={headerClassName}
                        style={headerStyle}
                        isPrinting={isPrinting}
                        width={width}
                        widthRatio={widthRatio}
                    />
                ) : null}
                {topRow ? topRow : null}
                {store.showTotals ? (
                    <TableTotals
                        store={store}
                        isPrinting={isPrinting}
                        width={width}
                        widthRatio={widthRatio}
                        totalsRowStyles={totalsRowStyles}
                        totalsCellStyles={totalsCellStyles}
                    />
                ) : null}
                <TableRows
                    tableId={id}
                    rows={store.rows}
                    store={store}
                    style={style}
                    isPrinting={isPrinting}
                    width={width}
                    widthRatio={widthRatio}
                    draggable={draggable}
                    onReorder={onReorder}
                />
            </div>
        )
    }
)

const TableHeader = observer(
    ({
        store,
        className = '',
        isPrinting,
        width,
        widthRatio,
        style,
        ...props
    }) => {
        const [isScrolled, setIsScrolled] = useState(false)
        const headerRef = useRef(null)
        const shadowRef = useRef(null)

        useEffect(() => {
            const header = headerRef.current
            const shadow = shadowRef.current
            if (!header || !shadow) return

            const observer = new IntersectionObserver(
                ([entry]) => {
                    setIsScrolled(!entry.isIntersecting)
                },
                { threshold: [0, 1], rootMargin: '-1px 0px 0px 0px' }
            )

            observer.observe(shadow)

            return () => {
                observer.disconnect()
            }
        }, [])

        return (
            <>
                <div ref={shadowRef} className="h-px w-full absolute top-0" />
                <div
                    ref={headerRef}
                    className={`coincraft-table-row header sticky top-0 z-[1] ${
                        isScrolled ? 'shadow-xl' : ''
                    } ${className}`}
                    style={style}
                    {...props}
                >
                    {store.columns
                        .filter((c) => !isPrinting || c.print)
                        .map((c, i) => (
                            <TableHeaderCell
                                store={store}
                                column={c}
                                isPrinting={isPrinting}
                                width={width}
                                widthRatio={widthRatio}
                                style={style}
                                key={'header' + c.id}
                            />
                        ))}
                </div>
            </>
        )
    }
)

const TableHeaderCell = observer(
    ({ store, column, isPrinting, width, widthRatio, style }) => {
        const target = React.useRef(null)
        const isHovering = useHover(target)
        const matchingSort = store.sortBy.find(
            ([col, direction]) => col === column.id
        )
        const showSort = isHovering || matchingSort
        const leftSort = showSort && column.textAlign === 'right'
        const rightSort = showSort && !leftSort
        const direction = !matchingSort
            ? 'asc'
            : isHovering
              ? matchingSort[1] === 'asc'
                  ? 'desc'
                  : 'asc'
              : matchingSort[1]
        return (
            <div
                ref={target}
                className="coincraft-table-cell"
                css={css({
                    width: `${remToPx(column.width)}px`,
                    fontSize: 'inherit',
                    backgroundColor: isHovering ? '#ffde66' : '#ffd333',
                    textAlign: column.textAlign,
                    cursor: 'pointer',
                    ...column.style(),
                    ...style,
                    '@media print': {
                        width: `${(column.width / width) * 100}%`,
                        fontSize: `${10 * widthRatio}px !important`,
                        backgroundColor: 'black',
                        color: 'white',
                    },
                })}
                onClick={() => store.updateSort(column.id, direction)}
            >
                {leftSort ? <SortingCaret direction={direction} /> : null}
                {column.label}
                {rightSort ? <SortingCaret direction={direction} /> : null}
            </div>
        )
    }
)

const SortingCaret = observer(({ direction }) => {
    return (
        <i
            className={`fa fa-caret-${direction === 'desc' ? 'up' : 'down'}`}
            style={{
                marginLeft: '0.5em',
                fontSize: '1.4em',
                position: 'relative',
                top: '0.1em',
            }}
        />
    )
})

const TableTotals = observer(
    ({
        store,
        columns,
        isPrinting,
        width,
        widthRatio,
        totalsRowStyles = {},
        totalsCellStyles = {},
    }) => {
        const row = store.totalsRow
        return (
            <div
                style={{
                    background: '#ffffff',
                    fontSize: '1.01em',
                    fontWeight: 'bold',
                    borderBottom: '1px solid #e0e0e0',
                    ...totalsRowStyles,
                }}
            >
                <TableRow
                    store={store}
                    row={row}
                    isPrinting={isPrinting}
                    width={width}
                    widthRatio={widthRatio}
                    cellStyle={{
                        padding: '1.25em 0.5em',
                        borderColor: '#eee',
                        ...totalsCellStyles,
                    }}
                />
            </div>
        )
    }
)

const DragHandle = ({ dragHandleProps }) => (
    <div
        {...dragHandleProps}
        className="flex items-center justify-center w-8 h-full cursor-grab active:cursor-grabbing group"
    >
        <div className="flex flex-col items-center justify-center w-4 h-4 gap-[2px] opacity-40 group-hover:opacity-80 transition-opacity">
            <div className="w-full h-[2px] bg-gray-600 rounded-full"></div>
            <div className="w-full h-[2px] bg-gray-600 rounded-full"></div>
            <div className="w-full h-[2px] bg-gray-600 rounded-full"></div>
        </div>
    </div>
)

const TableRows = observer(
    ({
        rows,
        store,
        isPrinting,
        width,
        widthRatio,
        style,
        tableId,
        draggable,
        onReorder,
    }) => {
        if (!draggable) {
            return (
                <div>
                    {rows.map((r, i) => (
                        <TableRow
                            store={store}
                            row={r}
                            isPrinting={isPrinting}
                            width={width}
                            widthRatio={widthRatio}
                            style={style}
                            key={r.id + i}
                            index={i}
                            tableId={tableId}
                        />
                    ))}
                </div>
            )
        }

        const handleDragEnd = (result) => {
            if (!result.destination || !onReorder) return

            const sourceIndex = result.source.index
            const destinationIndex = result.destination.index

            onReorder(sourceIndex, destinationIndex)
        }

        return (
            <DragDropContext onDragEnd={handleDragEnd}>
                <Droppable droppableId="table-rows">
                    {(provided) => (
                        <div
                            {...provided.droppableProps}
                            ref={provided.innerRef}
                        >
                            {rows.map((r, i) => (
                                <Draggable
                                    key={r.id}
                                    draggableId={r.id}
                                    index={i}
                                >
                                    {(provided, snapshot) => (
                                        <div
                                            ref={provided.innerRef}
                                            {...provided.draggableProps}
                                            className={
                                                snapshot.isDragging
                                                    ? 'shadow-lg'
                                                    : ''
                                            }
                                        >
                                            <TableRow
                                                store={store}
                                                row={r}
                                                isPrinting={isPrinting}
                                                width={width}
                                                widthRatio={widthRatio}
                                                style={{
                                                    ...style,
                                                    backgroundColor:
                                                        snapshot.isDragging
                                                            ? '#f5f5f5'
                                                            : undefined,
                                                }}
                                                index={i}
                                                tableId={tableId}
                                                dragHandleProps={
                                                    provided.dragHandleProps
                                                }
                                            />
                                        </div>
                                    )}
                                </Draggable>
                            ))}
                            {provided.placeholder}
                        </div>
                    )}
                </Droppable>
            </DragDropContext>
        )
    }
)

const TableRow = observer(
    ({
        store,
        row,
        isPrinting,
        width,
        widthRatio,
        style,
        cellStyle,
        index,
        tableId,
        dragHandleProps,
    }) => {
        const target = React.useRef(null)
        const isHovering = useHover(target)
        const groupLevel = row.group === 'totals' ? 1 : row.groupLevel
        return (
            <>
                <div
                    ref={target}
                    className={`coincraft-table-row row-index-${index}`}
                    data-testid={tableId && `${tableId}-${index}`}
                    css={{
                        background: style?.backgroundColor
                            ? style?.backgroundColor
                            : '#000000' +
                              ((8 * (groupLevel + isHovering * 2)).toString(16)
                                  .length < 2
                                  ? '0' +
                                    (
                                        8 *
                                        (groupLevel + isHovering * 2)
                                    ).toString(16)
                                  : (
                                        8 *
                                        (groupLevel + isHovering * 2)
                                    ).toString(16)),
                        fontSize: row.groupLevel ? '0.99em' : '1em',
                        '@media print': {
                            background: `white`,
                        },
                    }}
                >
                    {store.columns
                        .filter((c) => !isPrinting || c.print)
                        .map((c) => {
                            const cell = row.cells[c.id]
                            return (
                                <TableCell
                                    store={store}
                                    row={row}
                                    column={c}
                                    cell={cell}
                                    key={'cell' + c.id + row.id}
                                    isPrinting={isPrinting}
                                    width={width}
                                    widthRatio={widthRatio}
                                    style={cellStyle}
                                    rowIndex={index}
                                    tableId={tableId}
                                    dragHandleProps={dragHandleProps}
                                />
                            )
                        })}
                </div>
                {row.expanded ? (
                    <RowChildren row={row} store={store} style={cellStyle} />
                ) : null}
            </>
        )
    }
)

const TableCell = observer(
    ({
        column,
        row,
        cell,
        store,
        isPrinting,
        widthRatio,
        style,
        className = '',
        dragHandleProps,
        ...props
    }) => {
        return (
            <RenderOnQueries
                queryIds={cell.data?.(row) || []}
                loading={
                    <LoadingCell
                        {...{
                            column,
                            row,
                            cell,
                            store,
                            isPrinting,
                            widthRatio,
                            style,
                            className,
                            ...props,
                        }}
                    />
                }
            >
                <TableCellContent
                    {...{
                        column,
                        row,
                        cell,
                        store,
                        isPrinting,
                        widthRatio,
                        style,
                        className,
                        dragHandleProps,
                        ...props,
                    }}
                />
            </RenderOnQueries>
        )
    }
)

const TableCellContent = observer(
    ({
        column,
        cell,
        store,
        isPrinting,
        width,
        widthRatio,
        style,
        className = '',
        rowIndex,
        tableId,
        dragHandleProps,
        ...props
    }) => {
        console.log('column', column.id)
        if (column.id === 'dragHandle') {
            return (
                <div
                    className={
                        `coincraft-table-cell ` +
                        (!isPrinting && cell.editable
                            ? `coincraft-table-cell--${cell.type}`
                            : '') +
                        ` ${cell.className}` +
                        (cell.selected ? ' selected' : '') +
                        ` row-index-${rowIndex}-${cell.id}`
                    }
                    data-testid={
                        tableId && `${tableId}-${rowIndex}-${column.id}`
                    }
                    css={css({
                        width: `${remToPx(column.width)}px`,
                        fontSize: 'inherit',
                        '@media print': {
                            width: `${(column.width / width) * 100}%`,
                            fontSize: `${10 * widthRatio}px !important`,
                        },
                    })}
                    style={{
                        position: 'relative',
                        textAlign: column.textAlign,
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: column.justifyContent,
                        ...cell.style,
                        ...style,
                    }}
                    {...props}
                >
                    <DragHandle dragHandleProps={dragHandleProps} />
                </div>
            )
        }
        return (
            <div
                className={
                    `coincraft-table-cell ` +
                    (!isPrinting && cell.editable
                        ? `coincraft-table-cell--${cell.type}`
                        : '') +
                    ` ${cell.className}` +
                    (cell.selected ? ' selected' : '') +
                    ` row-index-${rowIndex}-${cell.id}`
                }
                data-testid={tableId && `${tableId}-${rowIndex}-${column.id}`}
                css={css({
                    width: `${remToPx(column.width)}px`,
                    fontSize: 'inherit',
                    '@media print': {
                        width: `${(column.width / width) * 100}%`,
                        fontSize: `${10 * widthRatio}px !important`,
                    },
                })}
                style={{
                    position: 'relative',
                    textAlign: column.textAlign,
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: column.justifyContent,
                    ...cell.style,
                    ...style,
                }}
                {...props}
            >
                {!isPrinting ? cell.component : cell.readOnlyComponent}
            </div>
        )
    }
)

const LoadingCell = observer(
    ({
        store,
        column,
        isPrinting,
        width,
        widthRatio,
        cell,
        style,
        ...props
    }) => {
        return (
            <div
                className={
                    `coincraft-table-cell ` +
                    (!isPrinting && cell.editable
                        ? `coincraft-table-cell--${cell.type}`
                        : '') +
                    ` ${cell.className}` +
                    (cell.selected ? ' selected' : '')
                }
                css={css({
                    width: `${remToPx(column.width)}px`,
                    fontSize: 'inherit',
                    '@media print': {
                        width: `${(column.width / width) * 100}%`,
                        fontSize: `${10 * widthRatio}px !important`,
                    },
                })}
                style={{
                    textAlign: column.textAlign,
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: column.justifyContent,
                    ...cell.style,
                    ...style,
                }}
                {...props}
            >
                loading...
            </div>
        )
    }
)

const RowChildren = observer(({ row, store }) => {
    return row.childRows.length ? (
        <TableRows rows={row.childRows} store={store} />
    ) : (
        row.childComponent
    )
})
