import React, { useEffect, useState } from 'react'
import { observer } from 'mobx-react'
import Modal from '../../Components/Modal'
import { Selector } from '../../Components/Selector'
import SessionStore from '../../State/SessionStore'
import { NumberInputContainer } from '../../widgets'
import InvoiceCollection from '../../State/Collections/InvoiceCollection'
import Formula from '../../formulas/Formula'
import { InvoiceFormulaInfo } from '../../invoices/invoiceFormulaInfo'
import {
    beginAccountingOauth,
    fetchAccountingSettings,
} from '../../Queries/accountingOauth'
import Checkbox from '../../Components/Widgets/Checkbox'
import ConnectAccountingButton from '../../Components/ConnectAccountingButton'
import DataStore from '../../State/DataStore'
import * as Sentry from '@sentry/react'

const accountingSettingsDisplay = {
    none: {
        salesAccount: false,
        taxOffTaxType: false,
        taxOnTaxType: false,
        usePhaseCodesAsItemCodes: false,
        useProjectNameAsReference: false,
        taxAmount: true,
        fileLogin: false,
    },
    xero: {
        salesAccount: true,
        taxOffTaxType: true,
        taxOnTaxType: true,
        usePhaseCodesAsItemCodes: true,
        useProjectNameAsReference: true,
        taxAmount: false,
        fileLogin: false,
    },
    'myob-essentials': {
        salesAccount: true,
        taxOffTaxType: true,
        taxOnTaxType: true,
        usePhaseCodesAsItemCodes: false,
        useProjectNameAsReference: false,
        taxAmount: false,
        fileLogin: false,
    },
    'myob-accountright': {
        salesAccount: true,
        taxOffTaxType: true,
        taxOnTaxType: true,
        usePhaseCodesAsItemCodes: false,
        useProjectNameAsReference: false,
        taxAmount: false,
        fileLogin: true,
    },
    quickbooks: {
        salesAccount: false,
        taxOffTaxType: true,
        taxOnTaxType: true,
        usePhaseCodesAsItemCodes: false,
        useProjectNameAsReference: false,
        taxAmount: false,
        fileLogin: false,
    },
}

const accountingDropdownOptions = [
    { label: 'None', value: 'none' },
    { label: 'Xero', value: 'xero' },
    { label: 'Myob Essentials', value: 'myob-essentials' },
    { label: 'Myob AccountRight', value: 'myob-accountright' },
    { label: 'Quickbooks', value: 'quickbooks' },
]

export default observer(({ modalId }) => {
    const [fetchDataStatus, setFetchDataStatus] = useState('idle')
    const fetchData = () => {
        if (SessionStore.organisation?.accountingSystem === 'none') {
            return
        }
        setFetchDataStatus('loading')
        fetchAccountingSettings()
            .then((r) => {
                setFetchDataStatus('success')
            })
            .catch((e) => {
                Sentry.captureException(e)
                setFetchDataStatus('error')
            })
    }
    useEffect(fetchData, [])
    const accountingSystem =
        accountingDropdownOptions.find(
            (o) => o?.value === SessionStore.organisation?.accountingSystem
        ) || accountingDropdownOptions[0]
    const accountingSystemConnected = SessionStore.accountingConnected
    const accountSystemSettings =
        SessionStore.organisation.accountingSystemSettings
    const latestInv =
        InvoiceCollection.invoices[InvoiceCollection.invoices.length - 1]
    const invCodeFormula = new Formula({
        ref: 'inv' + (latestInv?.id || '0'),
        prop: 'invoiceNumber',
        formula:
            SessionStore.organisation.settings?.invoice?.invoiceCode ||
            `format(invNum, "0000")`,
    })
    console.log('🚀 ~ observer ~ invCodeFormula:', invCodeFormula)
    const accountingDisplay =
        accountingSettingsDisplay[SessionStore.organisation.accountingSystem]
    return (
        <Modal
            modalId={modalId}
            heading="Invoice Settings"
            saveLabel="Save Settings"
            onSave={() => {
                DataStore.startSave()
            }}
        >
            <div className="p-3 border-b border-[#ccc]">
                <div className="flex items-center gap-2">
                    <div className="text-[#444] text-sm font-bold w-[30%]">
                        Invoice Code: <InvoiceFormulaInfo />
                    </div>
                    <div className="w-[70%] flex items-center gap-1">
                        <input
                            value={
                                SessionStore.organisation.settings?.invoice
                                    ?.invoiceCode || `format(invNum, "0000")`
                            }
                            style={{
                                width: '18em',
                                textAlign: 'left',
                                margin: '1em 0',
                                border: 'solid 1px #ccc',
                                padding: '0.1em 0.5em',
                            }}
                            onChange={(e) =>
                                SessionStore.organisation.updateInvoiceSettings(
                                    { invoiceCode: e.target.value }
                                )
                            }
                        />
                        <span className="text-444 text-sm">
                            {invCodeFormula?.validAst
                                ? ' eg.' + invCodeFormula?.value
                                : 'invalid code'}
                        </span>
                    </div>
                </div>
                <div className="flex items-center gap-2">
                    <div className="text-[#444] text-sm font-bold w-[30%]">
                        Invoices due:
                    </div>
                    <div className="w-[70%] flex items-center gap-1">
                        <NumberInputContainer
                            value={
                                SessionStore.organisation.settings?.invoice
                                    ?.numDaysBetweenIssueDateAndDueDate
                            }
                            formatFunc={(n) => n.toString()}
                            style={{
                                width: '3em',
                                textAlign: 'center',
                                margin: '1em 0',
                            }}
                            onChange={(e) =>
                                SessionStore.organisation.handleNumDaysBetweenIssueDateAndDueDateChange(
                                    { invoiceCode: e.target.value }
                                )
                            }
                        />
                        <span className="text-444 text-sm">
                            {' days after issue'}
                        </span>
                    </div>
                </div>
            </div>
            <h3 style={{ margin: '1em 0.5em 0.5em' }}>
                Accounting System Settings
            </h3>
            <div className="p-4 flex items-center">
                <span style={{ flex: '0 0 auto' }} className="pr-1">
                    Accounting System:{' '}
                </span>
                <div className="max-w-max">
                    <Selector
                        selectedOption={
                            accountingDropdownOptions.find(
                                (o) =>
                                    o?.value ===
                                    SessionStore.organisation.accountingSystem
                            ) || accountingDropdownOptions[0]
                        }
                        onChange={(o) => {
                            SessionStore.organisation.update({
                                accountingSystem: o,
                            })
                        }}
                        options={accountingDropdownOptions}
                        optionLabel={(o) => o.label}
                        style={{ flex: '1 1 auto' }}
                        variant="secondary"
                    />
                </div>
                <ConnectAccountingButton
                    action="connect"
                    style={{ margin: '0 1em', flex: '1 1 auto' }}
                />
            </div>
            {accountingDisplay?.fileLogin ? (
                <div>
                    <div
                        className="flex flex-align-items-center"
                        style={{
                            padding: '0.5em 3em',
                            justifyContent: 'flex-end',
                        }}
                    >
                        <div style={{ fontWeight: 'bold' }}>
                            Company File Login:
                        </div>
                        <div>
                            <div className="flex flex-align-items-center">
                                <input
                                    value={
                                        SessionStore.organisation
                                            .accountingSystemSettings?.settings
                                            ?.localUsername || ''
                                    }
                                    style={{
                                        textAlign: 'left',
                                        marginLeft: '1em',
                                    }}
                                    onChange={(e) =>
                                        SessionStore.organisation.updateAccountingSystemSettings(
                                            {
                                                settings: {
                                                    ...SessionStore.organisation
                                                        .accountingSystemSettings
                                                        ?.settings,
                                                    localUsername:
                                                        e.target.value,
                                                },
                                            }
                                        )
                                    }
                                />
                                <input
                                    type="password"
                                    value={
                                        SessionStore.organisation
                                            .accountingSystemSettings?.settings
                                            ?.localPassword || ''
                                    }
                                    style={{
                                        textAlign: 'left',
                                        marginLeft: '1em',
                                    }}
                                    onChange={(e) =>
                                        SessionStore.organisation.updateAccountingSystemSettings(
                                            {
                                                settings: {
                                                    ...SessionStore.organisation
                                                        .accountingSystemSettings
                                                        ?.settings,
                                                    localPassword:
                                                        e.target.value,
                                                },
                                            }
                                        )
                                    }
                                />
                            </div>
                        </div>
                    </div>
                </div>
            ) : null}
            <div
                style={{
                    padding: '1em',
                }}
            >
                {accountSystemSettings?.data ? (
                    <AccountingSystemSettings />
                ) : fetchDataStatus === 'error' ? (
                    <div style={{ color: 'red' }}>
                        Error loading settings
                        <button
                            style={{ marginLeft: '1em', color: 'black' }}
                            onClick={() => fetchData()}
                        >
                            <i className="fa fa-refresh" />
                            Retry
                        </button>
                    </div>
                ) : (
                    'Please connect to your accounting system to complete setup.'
                )}
            </div>
        </Modal>
    )
})

const AccountingSystemSettings = observer(() => {
    const accountingDisplay =
        accountingSettingsDisplay[SessionStore.organisation.accountingSystem]
    return (
        <>
            {accountingDisplay.salesAccount && (
                <div
                    className="flex flex-align-items-center"
                    style={{ margin: '1em 0' }}
                >
                    <div className="w-[30%]">
                        <strong>Sales Account</strong>
                    </div>
                    <div className="xero-settings-widget__sales-account-cell w-[70%] max-w-max">
                        <Selector
                            selectedOption={
                                SessionStore.organisation
                                    .accountingSystemSettings?.settings
                                    ?.salesAccount
                            }
                            onChange={(o) =>
                                SessionStore.organisation.updateAccountingSystemSettings(
                                    {
                                        settings: {
                                            ...SessionStore.organisation
                                                .accountingSystemSettings
                                                ?.settings,
                                            salesAccount: o,
                                        },
                                    }
                                )
                            }
                            options={
                                SessionStore.organisation
                                    .accountingSystemSettings?.data?.accounts
                            }
                            optionLabel={(o) => o?.name}
                            optionValue={(o) => o?.id}
                            style={{ flex: '1 1 auto' }}
                            variant="secondary"
                        />
                    </div>
                </div>
            )}
            {accountingDisplay.taxOnTaxType && (
                <div
                    className="flex flex-align-items-center"
                    style={{ margin: '1em 0' }}
                >
                    <div className="w-[30%]">
                        <strong>Taxable Income</strong>
                    </div>
                    <div className="xero-settings-widget__sales-account-cell w-[70%] max-w-max">
                        <Selector
                            selectedOption={
                                SessionStore.organisation
                                    .accountingSystemSettings?.settings
                                    ?.taxOnTaxType
                            }
                            onChange={(o) =>
                                SessionStore.organisation.updateAccountingSystemSettings(
                                    {
                                        settings: {
                                            ...SessionStore.organisation
                                                .accountingSystemSettings
                                                ?.settings,
                                            taxOnTaxType: o,
                                        },
                                    }
                                )
                            }
                            options={
                                SessionStore.organisation
                                    .accountingSystemSettings?.data?.taxTypes
                            }
                            optionLabel={(o) => o?.name}
                            optionValue={(o) => o?.id}
                            style={{ flex: '1 1 auto' }}
                            variant="secondary"
                        />
                    </div>
                </div>
            )}
            {accountingDisplay.taxOffTaxType && (
                <div
                    className="flex flex-align-items-center"
                    style={{ margin: '1em 0' }}
                >
                    <div className="w-[30%]">
                        <strong>Tax Free Income</strong>
                    </div>
                    <div className="xero-settings-widget__sales-account-cell w-[70%] max-w-max">
                        <Selector
                            selectedOption={
                                SessionStore.organisation
                                    .accountingSystemSettings?.settings
                                    ?.taxOffTaxType
                            }
                            onChange={(o) =>
                                SessionStore.organisation.updateAccountingSystemSettings(
                                    {
                                        settings: {
                                            ...SessionStore.organisation
                                                .accountingSystemSettings
                                                ?.settings,
                                            taxOffTaxType: o,
                                        },
                                    }
                                )
                            }
                            options={
                                SessionStore.organisation
                                    .accountingSystemSettings?.data?.taxTypes
                            }
                            optionLabel={(o) => o?.name}
                            optionValue={(o) => o?.id}
                            style={{ flex: '1 1 auto' }}
                            variant="secondary"
                        />
                    </div>
                </div>
            )}
            {accountingDisplay.usePhaseCodesAsItemCodes && (
                <div>
                    <Checkbox
                        value={
                            SessionStore.organisation.accountingSystemSettings
                                ?.settings?.usePhaseCodesAsItemCodes || false
                        }
                        onChange={(v) =>
                            SessionStore.organisation.updateAccountingSystemSettings(
                                {
                                    settings: {
                                        ...SessionStore.organisation
                                            .accountingSystemSettings?.settings,
                                        usePhaseCodesAsItemCodes: v,
                                    },
                                }
                            )
                        }
                        style={{ margin: '1em 0' }}
                        label="Use Phase Codes as Xero Item Codes"
                        variant="secondary"
                    />
                </div>
            )}
            {accountingDisplay.useProjectNameAsReference && (
                <Checkbox
                    value={
                        SessionStore.organisation.accountingSystemSettings
                            ?.settings?.useProjectNameAsReference || false
                    }
                    onChange={(v) =>
                        SessionStore.organisation.updateAccountingSystemSettings(
                            {
                                settings: {
                                    ...SessionStore.organisation
                                        .accountingSystemSettings?.settings,
                                    useProjectNameAsReference: v,
                                },
                            }
                        )
                    }
                    style={{ margin: '1em 0' }}
                    label="Use Project Name as Xero Invoice Reference"
                    variant="secondary"
                />
            )}
            {accountingDisplay.taxAmount && 'Tax Amount'}
        </>
    )
})
