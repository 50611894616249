// import * as React from 'react'

// import { cn } from '@2/lib/utils'

// export interface InputProps
//     extends React.InputHTMLAttributes<HTMLInputElement> {}

// const Input = React.forwardRef<HTMLInputElement, InputProps>(
//     ({ className, type, ...props }, ref) => {
//         return (
//             <input
//                 type={type}
//                 className={cn(
//                     'flex h-10 w-full rounded-md border border-input bg-background px-3 py-2 text-sm ring-offset-background file:border-0 file:bg-transparent file:text-sm file:font-medium placeholder:text-muted-foreground focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-ring disabled:cursor-not-allowed disabled:opacity-50',
//                     className
//                 )}
//                 ref={ref}
//                 {...props}
//             />
//         )
//     }
// )
// Input.displayName = 'Input'

// export { Input }

import * as React from 'react'
import { cn } from '@2/lib/utils'

export interface InputProps
    extends Omit<
        React.InputHTMLAttributes<HTMLInputElement>,
        'prefix' | 'suffix'
    > {
    prefix?: React.ReactNode
    suffix?: React.ReactNode
}

const Input = React.forwardRef<HTMLInputElement, InputProps>(
    ({ className, type, suffix, prefix, ...props }, ref) => {
        return (
            <div className="relative flex items-center">
                {prefix && (
                    <span className="absolute left-0 text-sm text-muted-foreground">
                        {prefix}
                    </span>
                )}
                <input
                    type={type}
                    className={cn(
                        'flex h-10 w-full rounded-md border border-input bg-background px-3 py-2 text-sm ring-offset-background file:border-0 file:bg-transparent file:text-sm file:font-medium placeholder:text-muted-foreground focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-ring disabled:cursor-not-allowed disabled:opacity-50',
                        suffix && 'pr-10',
                        className
                    )}
                    ref={ref}
                    {...props}
                />
                {suffix && (
                    <span className="absolute right-3 text-sm text-muted-foreground">
                        {suffix}
                    </span>
                )}
            </div>
        )
    }
)
Input.displayName = 'Input'

export { Input }
