import { observable, computed, action, makeObservable } from 'mobx'
import PhaseCollection from '../Collections/PhaseCollection'
import ProjectCollection from '../Collections/ProjectCollection'
import ProjectRateCollection from '../Collections/ProjectRateCollection'
import RoleCollection from '../Collections/RoleCollection'
import StaffCollection from '../Collections/StaffCollection'
import Model from './Model'

class ProjectRateModel extends Model {
    @observable projectId = null
    @observable itemType = null
    @observable date = null
    @observable costRate = null
    @observable chargeOutRate = null
    @observable itemId = null
    @observable phaseId = null

    constructor(data, options) {
        super()
        makeObservable(this)
        this.collection = ProjectRateCollection
        this.init(data, options)
    }
    @computed
    get project() {
        return ProjectCollection.modelsById[this.projectId]
    }
    @computed
    get phase() {
        return PhaseCollection.modelsById[this.phaseId]
    }
    @computed
    get staff() {
        return StaffCollection.modelsById[this.itemId]
    }
    @computed
    get role() {
        return RoleCollection.modelsById[this.itemId]
    }
    @computed
    get resource() {
        return this.staff || this.role
    }
}

export default ProjectRateModel
