import _ from 'lodash'
import { makeObservable, observable, computed, action, autorun } from 'mobx'
import {
    addDays,
    addWeeks,
    format,
    getWeek,
    isSameDay,
    parse,
    startOfDay,
    startOfWeek,
    subWeeks,
} from 'date-fns'
import SessionStore from '../../State/SessionStore'
import tuple from 'immutable-tuple'
import StaffCollection from '../../State/Collections/StaffCollection'
import { canEditStaffTime } from '../../State/Permissions/HasPermissions'
import ProjectExpenseItemCollection from '../../State/Collections/ProjectExpenseItemCollection'
import { router } from '../../App'

class TrackExpensesStore {
    @observable extraExpenses = []
    @observable searchParams = {}
    constructor() {
        makeObservable(this)
    }
    @action.bound
    reset() {
        this.extraExpenses = []
    }
    @action.bound
    setSearchParams(params) {
        this.searchParams = params
    }
    @computed get staffId() {
        return canEditStaffTime(SessionStore.user)
            ? this.searchParams?.staffId || SessionStore.userId
            : SessionStore.userId
    }
    @computed get date() {
        return this.searchParams?.date
            ? parse(this.searchParams?.date, 'yyyy-MM-dd', new Date())
            : startOfDay(new Date())
    }
    @action.bound
    addExtraExpenses(expenses) {
        this.extraExpenses.push(...expenses)
    }
    @computed
    get expenses() {
        return [
            ...new Set([
                ...ProjectExpenseItemCollection.projectExpenseItems.filter(
                    (t) =>
                        t.staffId === this.staffId &&
                        isSameDay(t.date, this.date) &&
                        !t.deletedAt
                ),
                ...this.extraExpenses.filter(
                    (t) =>
                        t.staffId === this.staffId &&
                        isSameDay(t.date, this.date) &&
                        !t.deletedAt
                ),
            ]),
        ]
    }
    @computed get costCentres() {
        return [...new Set(this.expenses.map((t) => t.project.costCentre))]
    }
    @computed get projectsByCostCentreId() {
        return _.groupBy(
            [...new Set(this.expenses.map((t) => t.project))],
            (pr) => pr?.costCentreId
        )
    }
    @computed get phasesByProjectId() {
        return _.mapValues(
            _.groupBy(this.expenses, (ph) => ph?.projectId),
            (tes) => [...new Set(tes.map((te) => te.phase))]
        )
    }
    @computed get rowsByPhaseId() {
        return _.groupBy(
            this.expenses.filter((exp) => {
                return (
                    exp.cost ||
                    (exp.description !== '' && exp.description !== null) ||
                    new Date() - (exp.createdAt || exp.updatedAt) <
                        1000 * 60 * 60 * 12 ||
                    (!this.expenses.find(
                        (e) =>
                            e.id !== exp.id &&
                            e.date.getTime() === exp.date.getTime() &&
                            e.expenseId === exp.expenseId &&
                            (e.cost ||
                                (e.description !== '' &&
                                    e.description !== null))
                    ) &&
                        this.expenses.find(
                            (e) =>
                                e.date.getTime() === exp.date.getTime() &&
                                e.expenseId === exp.expenseId
                        ).id === exp.id)
                )
            }),
            (te) => tuple(te.projectId, te.phaseId)
        )
    }
    @computed
    get staff() {
        return StaffCollection.staffsById[this.staffId] || SessionStore.user
    }
    @computed
    get startOfWeek() {
        return startOfWeek(this.date, { weekStartsOn: 1 })
    }
    @computed
    get daysOfWeek() {
        return [...Array(7)].map((v, i) => addDays(this.startOfWeek, i))
    }
    @computed
    get dailyTotals() {
        const totals = this.daysOfWeek.map((day) =>
            _.sum(
                (
                    ProjectExpenseItemCollection.expenseItemsByStaffIdDate[
                        tuple(this.staffId, day.getTime())
                    ] || []
                ).map((te) => te.cost)
            )
        )
        return totals
    }
    @computed
    get currentDayTotal() {
        return _.sum(
            (
                ProjectExpenseItemCollection.expenseItemsByStaffIdDate[
                    tuple(this.staffId, this.date.getTime())
                ] || []
            ).map((te) => te.cost)
        )
    }
    @action.bound
    shiftPrevWeek() {
        router.navigate({
            search: (prev) => ({
                ...prev,
                date: format(subWeeks(this.date, 1), 'yyyy-MM-dd'),
            }),
        })
    }
    @action.bound
    shiftNextWeek() {
        router.navigate({
            search: (prev) => ({
                ...prev,
                date: format(addWeeks(this.date, 1), 'yyyy-MM-dd'),
            }),
        })
    }
    @action.bound
    shiftThisWeek() {
        router.navigate({
            search: (prev) =>
                _.omitBy(
                    {
                        ...prev,
                        date: null,
                    },
                    (v) => v === null
                ),
        })
    }
}

export default new TrackExpensesStore()
