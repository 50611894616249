import { endOfWeek, format, getWeek, parse, startOfWeek } from 'date-fns'
import tuple from 'immutable-tuple'
import { observer } from 'mobx-react'
import React, { Suspense, useCallback, useEffect, useState } from 'react'
import { StaffSelector } from '../../Components/Selector'
import DateValue from '../../Components/Widgets/DateValue'
import LayoutStore from '../../State/LayoutStore'
import SessionStore from '../../State/SessionStore'
import PageHeader from '../Layout/PageHeader'
import { canEditStaffExpenses } from '../../State/Permissions/HasPermissions'
import TrackExpensesStore from './TrackExpensesStore'
import EditExpenseModal from './EditExpenseModal'
import { useNavigate } from '@tanstack/react-router'
import _ from 'lodash'

export default observer(({ date, staffId }) => {
    const [store, setStore] = useState(TrackExpensesStore)
    date = date ? parse(date, 'yyyy-MM-dd', new Date()) : new Date()
    const startDate = startOfWeek(date, { weekStartsOn: 1 })
    const endDate = endOfWeek(date, { weekStartsOn: 1 })
    const navigate = useNavigate()

    return (
        <PageHeader
            heading={`Expenses: ${format(date, 'dd MMM yyyy')}`}
            extraContent={
                <>
                    <div className="flex items-center mt-6">
                        <button
                            className="btn btn-default add-timesheet-entry-button plus-btn"
                            style={{ margin: '0 1em 0 0' }}
                            onClick={() =>
                                LayoutStore.openModal(
                                    <EditExpenseModal
                                        modalId={'addExpense'}
                                        dates={[store.date]}
                                        staff={store.staff}
                                        store={store}
                                    />
                                )
                            }
                        >
                            + Add Expense
                        </button>
                        {canEditStaffExpenses(SessionStore.user) ? (
                            <StaffSelector
                                className="timesheet__staff-selector"
                                selectedStaffMember={store.staff}
                                allowNull={false}
                                onChange={(staff) => {
                                    navigate({
                                        search: (prev) => ({
                                            ...prev,
                                            staffId: staff.id,
                                        }),
                                    })
                                }}
                            />
                        ) : null}
                        <DateValue
                            value={date}
                            onChange={(date) => {
                                navigate({
                                    search: (prev) => ({
                                        ...prev,
                                        date: format(date, 'yyyy-MM-dd'),
                                    }),
                                })
                            }}
                            style={{ width: '10em', marginLeft: '1em' }}
                        />
                    </div>
                </>
            }
        />
    )
})
