export default {
    name: 'New Report',
    type: 'project',
    columns: [
        'name',
        'actualVsBudgetedHours',
        'actualVsBudgetedExpenses',
        'revenueVsFee',
        'profit',
        'profitMargin',
    ],
    filters: [
        {
            column: 'status',
            operator: '!in',
            value: ['archived'],
            group: 'project',
        },
    ],
    // defaultGroup: 'phase',
    // groupBy: ['project'],
    // sortBy: [['name', 'asc']],
    options: {
        dateRange: 'allTime',
    },
}
