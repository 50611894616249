import { action, makeObservable, observable } from 'mobx'
import { computedFn } from 'mobx-utils'

class FetchStoreClass {
    @observable lookup = {}
    constructor() {
        makeObservable(this)
    }
    @action
    setResponse(id, response) {
        this.lookup[id] = response
    }
    getResponse = computedFn((id) => {
        return this.lookup[id]
    })
    @action
    deleteResponse(id) {
        delete this.lookup[id]
    }
}

export default new FetchStoreClass()
