import { observable, computed, action, makeObservable, toJS } from 'mobx'
import {
    dateStringLookup,
    operatorDefaults,
    operatorLabels,
} from '../../Components/Filters'
import { ProjectReportColumns } from '../../reports/Projects/ProjectReportColumns'
import ReportCollection from '../Collections/ReportCollection'
import Model from './Model'
import download from 'downloadjs'
import Papa from 'papaparse'
import TableStore from '../../Components/TableStore'
import { StaffReportColumns } from '../../reports/Staff/StaffReportColumns'
import { InvoiceReportColumns } from '../../reports/Invoices/InvoiceReportColumns'
import PhaseCollection from '../Collections/PhaseCollection'
import DefaultProjectReport from '../../reports/Projects/DefaultProjectReport'
import _ from 'lodash'
import SessionStore from '../SessionStore'
import { TimeReportColumns } from '../../reports/Time/TimeReportColumns'
import DefaultTimeReport from '../../reports/Time/DefaultTimeReport'
import DefaultStaffReport from '../../reports/Staff/DefaultStaffReport'
import DefaultInvoiceReport from '../../reports/Invoices/DefaultInvoiceReport'
import TimeEntryCollection from '../Collections/TimeEntryCollection'
import ProjectCollection from '../Collections/ProjectCollection'
import { isArray } from 'underscore'
import bind from 'bind-decorator'
import DefaultExpenseReport from '../../reports/Expense/DefaultExpenseReport'
import { ExpenseReportColumns } from '../../reports/Expense/ExpenseReportColumns'

const reportDefaults = {
    project: DefaultProjectReport,
    staff: DefaultStaffReport,
    invoice: DefaultInvoiceReport,
    timesheet: DefaultTimeReport,
    expense: DefaultExpenseReport,
}

class ReportModel extends Model {
    @observable name = null
    @observable type = null
    @observable columns = []
    @observable filters = []
    @observable groupBy = []
    @observable sortBy = []
    @observable options = {}
    @observable searchFilter = ''
    @observable queryKey = null
    @observable systemReport = false

    constructor(data, options) {
        super()
        makeObservable(this)
        this.collection = ReportCollection
        if (data.filters) {
            data.filters = data.filters?.map((f) => {
                if (
                    f.column.toLowerCase().includes('date') &&
                    isArray(f.value) &&
                    !(f.value[0] instanceof Date)
                ) {
                    return {
                        ...f,
                        value: f.value.map((d) => (d ? new Date(d) : d)),
                    }
                } else if (
                    f.column.toLowerCase().includes('date') &&
                    ['=', '!=', '>', '<'].includes(f.operator) &&
                    f.value &&
                    !(f.value instanceof Date)
                ) {
                    return { ...f, value: new Date(f.value) }
                }
                return f
            })
        }
        if (
            data.options?.dateRange &&
            isArray(data.options?.dateRange) &&
            !(data.options?.dateRange[0] instanceof Date)
        ) {
            data.options.dateRange = data.options.dateRange.map(
                (d) => new Date(d)
            )
        }
        this.init(
            { ...reportDefaults[data.type], ..._.omitBy(data, _.isNull) },
            options
        )
        this.tableStore = new TableStore()
        this.searchFilter = ''
        this.setQueryKey()
    }

    update(data, options, ignoreQueryKey = false) {
        if (data.filters) {
            data.filters = data.filters?.map((f) => {
                if (
                    f.column.toLowerCase().includes('date') &&
                    isArray(f.value) &&
                    !(f.value[0] instanceof Date)
                ) {
                    return {
                        ...f,
                        value: f.value.map((d) => (d ? new Date(d) : null)),
                    }
                } else if (
                    f.column.toLowerCase().includes('date') &&
                    ['=', '!=', '>', '<'].includes(f.operator) &&
                    f.value &&
                    !(f.value instanceof Date)
                ) {
                    return { ...f, value: new Date(f.value) }
                }
                return f
            })
        }
        if (
            data.options?.dateRange &&
            isArray(data.options?.dateRange) &&
            !(data.options?.dateRange[0] instanceof Date)
        ) {
            data.options.dateRange = data.options.dateRange.map(
                (d) => new Date(d)
            )
        }
        const update = super.update(data, options)
        // this.debouncedSetQueryKey()
        if (!ignoreQueryKey) this.setQueryKey()
        return update
    }

    @computed
    get tableColumns() {
        return [
            ...this.columns.map((c) => ({
                ...this.columnOptionsById[c],
                visible: true,
            })),
            ...(this.groupBy || [])
                .filter((c) => !this.columns.includes(c))
                .map((c) => ({
                    ...this.columnOptionsById[c],
                    visible: false,
                })),
            ...(this.filters || [])
                .filter((f) => !this.columns.includes(f.column))
                .map((f) => ({
                    ...this.columnOptionsById[f.column],
                    visible: false,
                })),
        ].filter((c) => !c.permissions || c.permissions())
    }
    @action.bound
    addFilter() {
        const defaultCol = Object.values(this.columnOptionsById)[0]
        this.filters.push({
            column: defaultCol.id,
            operator: operatorDefaults[defaultCol.type],
            group: 'project',
        })
        this.update({ filters: this.filters }, {}, true)
    }
    @action.bound
    updateFilter(filter, prop, value) {
        filter[prop] = value
        if (prop === 'column') {
            filter.operator =
                operatorDefaults[this.columnOptionsById[value].type]
            filter.value = null
        }
        this.update({ filters: this.filters }, {}, true)
    }
    @action.bound
    removeFilter(filter) {
        this.filters = this.filters.filter((f) => f !== filter)
        this.update({ filters: this.filters }, {}, true)
    }
    @action.bound
    updateOptions(prop, value) {
        this.options[prop] = value
        this.update({ options: this.options }, {}, true)
    }
    @action.bound
    updateColumns(columnIds) {
        this.update({ columns: columnIds }, {}, true)
        this.tableStore.updateColumns(this.tableColumns)
    }
    @computed
    get columnOptionsById() {
        if (this.type === 'project') {
            return ProjectReportColumns(this)
        }
        if (this.type === 'staffMember') {
            return StaffReportColumns(this)
        }
        if (this.type === 'invoice') {
            return InvoiceReportColumns(this)
        }
        if (this.type === 'timesheet') {
            return TimeReportColumns(this)
        }
        if (this.type === 'expense') {
            return ExpenseReportColumns(this)
        }
    }
    @computed
    get rows() {
        if (this.type === 'project') {
            return ProjectCollection.projects.filter((pr) => {
                return (
                    !pr.deletedAt &&
                    pr.title
                        ?.toLowerCase?.()
                        ?.includes?.(this.searchFilter.toLowerCase())
                )
            })
        }
        if (this.type === 'staffMember') {
            return []
        }
        if (this.type === 'invoice') {
            return []
        }
        if (this.type === 'timesheet') {
            return TimeEntryCollection.timeEntries
        }
        if (this.type === 'expense') {
            return []
        }
    }
    @action.bound
    updateSearchFilter(val) {
        this.searchFilter = val
        // this.tableStore.updateRows(this.rows)
    }
    @bind
    downloadCSV({ columns, rows } = {}) {
        download(
            Papa.unparse(this.tableStore.getCsvData({ columns, rows })),
            `${this.name}.csv`,
            'text/csv'
        )
    }
    @action.bound
    makeDefault() {
        if (this.type === 'project') {
            SessionStore.organisation.update({
                defaultProjectReportId: this.id,
            })
        }
        if (this.type === 'staffMember') {
            SessionStore.organisation.update({
                defaultStaffReportId: this.id,
            })
        }
        if (this.type === 'invoice') {
            SessionStore.organisation.update({
                defaultInvoiceReportId: this.id,
            })
        }
        if (this.type === 'timesheet') {
            SessionStore.organisation.update({
                defaultTimeReportId: this.id,
            })
        }
        if (this.type === 'expense') {
            SessionStore.organisation.update({
                defaultExpenseReportId: this.id,
            })
        }
    }
    @action.bound
    removeDefault() {
        if (
            this.type === 'project' &&
            this.id === SessionStore.organisation.defaultProjectReportId
        ) {
            SessionStore.organisation.update({
                defaultProjectReportId: null,
            })
        }
        if (
            this.type === 'staffMember' &&
            this.id === SessionStore.organisation.defaultStaffReportId
        ) {
            SessionStore.organisation.update({
                defaultStaffReportId: null,
            })
        }
        if (
            this.type === 'invoice' &&
            this.id === SessionStore.organisation.defaultInvoiceReportId
        ) {
            SessionStore.organisation.update({
                defaultInvoiceReportId: null,
            })
        }
        if (
            this.type === 'timesheet' &&
            this.id === SessionStore.organisation.defaultTimeReportId
        ) {
            SessionStore.organisation.update({
                defaultTimeReportId: null,
            })
        }
        if (
            this.type === 'expense' &&
            this.id === SessionStore.organisation.defaultExpenseReportId
        ) {
            SessionStore.organisation.update({
                defaultExpenseReportId: null,
            })
        }
    }
    @computed get dateRange() {
        return (
            dateStringLookup[this.options?.dateRange]?.(
                this.options?.fortnightType
            ) || this.options?.dateRange
        )
    }
    @computed
    get needsUpdate() {
        return this.queryKey !== this.makeQueryKey()
    }
    @action.bound
    setQueryKey() {
        this.queryKey = this.makeQueryKey()
    }
    makeQueryKey() {
        return JSON.stringify({
            columns: [...this.columns].sort((a, b) => a.localeCompare(b)),
            filters: [...this.filters]
                .map((f) => ({
                    ...f,
                    value: isArray(f.value)
                        ? f.value.map((v) => v?.id || v)
                        : f.value?.id || f.value,
                }))
                .sort((a, b) => a.column.localeCompare(b.column)),
            groupBy: [...(this.groupBy || [])],
            dateRange: this.options.dateRange,
            fortnightType: this.options.fortnightType,
        })
    }

    // debouncedSetQueryKey = _.debounce(this.setQueryKey, 3000)
}

export default ReportModel
