import { observable, computed, action, makeObservable } from 'mobx'
import SupplierCollection from '../Collections/SupplierCollection'
import Model from './Model'

class SupplierModel extends Model {
    @observable name = null
    constructor(data, options) {
        super()
        makeObservable(this)
        this.collection = SupplierCollection
        this.init(data, options)
    }
}

export default SupplierModel
