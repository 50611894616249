import { observable, computed, action, makeObservable, autorun } from 'mobx'
import React from 'react'
import _ from 'lodash'
import cuid from 'cuid'
import { computedFn } from 'mobx-utils'
import Filters from './Filters'
import is from '@sindresorhus/is'
import { roundNumberToDecimalPlaces } from './GroupFormatter'
import TableCellStore from './TableCellStore'
import bind from 'bind-decorator'

class TableRowStore {
    id = cuid()
    table = {}
    rowObject = {}
    rowOptions = {}
    group = undefined
    _cellsByColumnId = new Map()
    constructor({ id, table, rowObject, childComponent, group, childRows }) {
        makeObservable(this)
        this.id = id ?? this.id
        this.table = table ?? this.table
        this.rowObject = rowObject ?? this.rowObject
        this.childComponent = childComponent
        this.group = group
        this._childRows = childRows || new Set()
    }
    @bind
    getOrCreateCell(column) {
        if (!this._cellsByColumnId.get(column.id || column)) {
            this._cellsByColumnId.set(
                column.id || column,
                new TableCellStore({
                    table: this.table,
                    column,
                    row: this,
                })
            )
        }
        return this._cellsByColumnId.get(column.id || column)
    }
    @action
    updateColumn(column) {
        this._cellsByColumnId.set(
            column.id,
            new TableCellStore({
                table: this.table,
                column,
                row: this,
            })
        )
    }
    @computed
    get cells() {
        const cells = {}
        this.table._columns.forEach((column) => {
            cells[column.id] = this.getOrCreateCell(column)
        })
        return cells
    }
    @computed
    get groupLevel() {
        const groupIndex = this.table.groups.findIndex((g) => g === this.group)
        return groupIndex >= 0 ? groupIndex : this.table.groups.length
    }
    @computed
    get parent() {
        return this.table._rows.filter((r) => r._childRows.has(this))[0]
    }
    @computed
    get expanded() {
        return (
            this.table.expandedRows.has(this) ||
            this.table.expandedGroups.includes(this.group)
        )
    }
    @computed
    get childRows() {
        return _.orderBy(
            [...this._childRows].filter((cr) => cr.visible),
            [
                (r) => r.rowObject.isNew,
                ...this.table.sortBy.map(([prop, direction]) => (r) => {
                    let val
                    if (typeof prop === 'string' && r.cells[prop]) {
                        val = r.cells[prop].value
                    } else if (typeof prop === 'function') {
                        val = prop(r)
                    }
                    return val || Infinity
                }),
                (r) => r.rowObject.initiatedAt,
            ],
            [
                'desc',
                ...this.table.sortBy.map(([prop, direction]) => direction),
                'asc',
            ]
        )
    }
    @computed
    get descendants() {
        const descendants = []
        if (this.childRows.length) {
            this.childRows.forEach((cr) => {
                descendants.push(...cr.descendants)
            })
        } else {
            descendants.push(this)
        }
        return descendants
    }
    @computed
    get ungroupedDescendants() {
        return this.descendants.filter((r) => !r.group)
    }

    @computed
    get visible() {
        return this.table.filters.every(
            ({ column, operator, value, group }) => {
                if (group !== this.group) return true
                const val = this.cells[column]?.value
                if (this.cells[column]?.equalityComparatorValue == null)
                    return false
                const type = this.cells[column]?.type
                const not = operator.includes('!')
                const matchFilter = Filters[type]?.[
                    operator.replace('!', '')
                ]?.(val, value)
                return not ? !matchFilter : matchFilter
            }
        )
    }
}

export default TableRowStore
