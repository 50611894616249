import { jsx, css } from '@emotion/react'
import { observer } from 'mobx-react'
import React, { useEffect, useState } from 'react'
import StaffCollection from '../../State/Collections/StaffCollection'
import { Radio, Switch, Select, Button } from 'antd'
import { capitalCase } from 'change-case'
import {
    ProjectMultiselect,
    CostCentreMultiselect,
} from '../../Components/MultiSelect'
import ProjectCollection from '../../State/Collections/ProjectCollection'
import CostCentreCollection from '../../State/Collections/CostCentreCollection'
import { permissionStructure } from '../../State/Permissions/PermissionStructure'
import { permissionPresets } from '../../State/Permissions/PermissionPresets'
import {
    addProjectPermission,
    canSeeExpenses,
    canSeeFeesAndRevenue,
    canSeeOperationalExpenses,
    canSeeSalaries,
    deleteProjectPermission,
    getAllPermissionCostCentreIds,
    getAllPermissionProjectIds,
    hasPermissionsForAllProjects,
    updateProjectPermission,
    updateProjectPermissionFilter,
    updateStaffPermission,
} from '../../State/Permissions/HasPermissions'
import PermissionCollection from '../../State/Collections/PermissionCollection'
import { Link, redirect, useNavigate } from '@tanstack/react-router'
const { Option, OptGroup } = Select

export default observer(({ id }) => {
    const navigate = useNavigate()
    const staff = StaffCollection.staffsById[id]
    if (!staff) return null

    const [presetOption, setPresetOption] = useState(
        staff.permissionsId || 'custom'
    )

    return (
        <div style={{ width: '58em' }}>
            <div style={{ marginBottom: '5em' }}>
                <h2 className="border-b border-[#aaa]">Presets</h2>
                <div>
                    <PermissionRow label={'Permission Presets'}>
                        <Select
                            showSearch
                            optionFilterProp="children"
                            value={presetOption}
                            bordered
                            onChange={(optionId) => {
                                setPresetOption(optionId)
                                if (
                                    PermissionCollection.permissionsById[
                                        optionId
                                    ]
                                ) {
                                    staff.update({
                                        permissionsId: optionId,
                                        permissions:
                                            PermissionCollection
                                                .permissionsById[optionId]
                                                .settings,
                                    })
                                } else {
                                    staff.update({
                                        permissionsId: null,
                                    })
                                }
                            }}
                            style={{ fontSize: '0.9em', width: '20em' }}
                        >
                            {[
                                ...[...PermissionCollection.permissions]
                                    .sort((a, b) =>
                                        a.name.localeCompare(b.name)
                                    )
                                    .map((p) => ({
                                        label: `${p.name}`,
                                        value: p.id,
                                    })),
                                {
                                    label: 'Custom',
                                    value: 'custom',
                                },
                            ]
                                .filter((c) => c)
                                .map((option) => (
                                    <Option
                                        style={{
                                            fontSize: '12px',
                                        }}
                                        value={option.value}
                                    >
                                        {option.label}
                                    </Option>
                                ))}
                        </Select>
                        {presetOption !== 'custom' ? (
                            <Link to={`/permissions/${presetOption}`}>
                                <Button className="ml-2">Edit...</Button>
                            </Link>
                        ) : (
                            <Button
                                className="ml-2"
                                onClick={() => {
                                    const permission =
                                        PermissionCollection.createPermissionFromStaff(
                                            staff
                                        )
                                    navigate({
                                        to: `/permissions/${permission.id}`,
                                    })
                                }}
                            >
                                Create Preset...
                            </Button>
                        )}
                    </PermissionRow>
                    <PermissionRow label={'Financial Presets'}>
                        <div>
                            <Switch
                                disabled={staff.permissionsId}
                                checked={canSeeFeesAndRevenue(staff)}
                                size="small"
                                onChange={(v) => {
                                    updateStaffPermission(staff, {
                                        canViewRevenueForecast: v,
                                        chargeOutRate: v ? 'view' : 'hidden',
                                        projects: (
                                            staff?.permissions?.projects || []
                                        ).map((p) => ({
                                            ...p,
                                            permissions: {
                                                ...p.permissions,
                                                projectFees: v
                                                    ? 'view'
                                                    : 'hidden',
                                                projectForecastedRevenue: v
                                                    ? 'view'
                                                    : 'hidden',
                                                projectInvoices: v
                                                    ? 'view'
                                                    : 'hidden',
                                            },
                                        })),
                                    })
                                }}
                                checkedChildren="Project Fees & Revenue"
                                unCheckedChildren="Project Fees & Revenue"
                            />{' '}
                            <br />
                            <Switch
                                disabled={staff.permissionsId}
                                checked={canSeeExpenses(staff)}
                                size="small"
                                onChange={(v) => {
                                    updateStaffPermission(staff, {
                                        costRate: v ? 'view' : 'hidden',
                                        projects: (
                                            staff?.permissions?.projects || []
                                        ).map((p) => ({
                                            ...p,
                                            permissions: {
                                                ...p.permissions,
                                                projectExpenses: v
                                                    ? 'view'
                                                    : 'hidden',
                                                projectExpenseBudgets: v
                                                    ? 'view'
                                                    : 'hidden',
                                            },
                                        })),
                                    })
                                }}
                                checkedChildren="Staff Cost & Project Expenses"
                                unCheckedChildren="Staff Cost & Project Expenses"
                            />{' '}
                            <br />
                            <Switch
                                disabled={staff.permissionsId}
                                checked={canSeeSalaries(staff)}
                                size="small"
                                onChange={(v) => {
                                    updateStaffPermission(staff, {
                                        payRate: v ? 'view' : 'hidden',
                                        overtimeRate: v ? 'view' : 'hidden',
                                    })
                                }}
                                checkedChildren="Staff Salaries"
                                unCheckedChildren="Staff Salaries"
                            />{' '}
                            <br />
                            <Switch
                                disabled={staff.permissionsId}
                                checked={canSeeOperationalExpenses(staff)}
                                size="small"
                                onChange={(v) => {
                                    updateStaffPermission(staff, {
                                        operationalExpenses: v
                                            ? 'view'
                                            : 'hidden',
                                    })
                                }}
                                checkedChildren="Operational Expenses"
                                unCheckedChildren="Operational Expenses"
                            />
                        </div>
                    </PermissionRow>
                </div>
            </div>
            <StaffPermissionOptions
                staff={staff}
                disabled={staff.permissionsId}
            />
        </div>
    )
})

export const StaffPermissionOptions = ({ staff, disabled }) => {
    const handleUpdatePermissions = (key, value) => {
        updateStaffPermission(staff, { [key]: value })
    }

    const handleUpdateProjectPermissions = (staff, id, key, value) => {
        updateProjectPermission(staff, id, { [key]: value })
    }

    const handleUpdateFilterPermissions = (staff, id, filter) => {
        updateProjectPermissionFilter(staff, id, filter)
    }
    return permissionStructure.map((group) =>
        group.label !== 'Projects' ? (
            <PermissionGroup
                title={group.label}
                permissions={group.permissions}
                staffPermissions={staff?.permissions || []}
                onChange={handleUpdatePermissions}
                disabled={disabled}
            />
        ) : (
            <div>
                {(staff?.permissions?.projects || []).map(
                    (projectPermissions) => (
                        <ProjectPermissionGroup
                            staffPermissions={staff?.permissions}
                            permissions={group.permissions}
                            projectPermissions={projectPermissions}
                            onChange={(key, value) =>
                                handleUpdateProjectPermissions(
                                    staff,
                                    projectPermissions.id,
                                    key,
                                    value
                                )
                            }
                            onFilterChange={(value) =>
                                handleUpdateFilterPermissions(
                                    staff,
                                    projectPermissions.id,
                                    value
                                )
                            }
                            onDelete={() =>
                                deleteProjectPermission(
                                    staff,
                                    projectPermissions.id
                                )
                            }
                            disabled={disabled}
                        />
                    )
                )}
                {!disabled ? (
                    <button
                        className="plus-btn border-[#d9d9d9] mb-10"
                        onClick={() => addProjectPermission(staff)}
                    >
                        + Add Project Permissions
                    </button>
                ) : null}
            </div>
        )
    )
}

export const ProjectPermissionGroup = ({
    permissions,
    projectPermissions,
    staffPermissions,
    onChange,
    onFilterChange,
    onDelete,
    disabled,
}) => {
    return (
        <div style={{ marginBottom: '4em' }}>
            <div
                style={{
                    borderBottom: '1px solid #ccc',
                    display: 'flex',
                    alignItems: 'center',
                    zIndex: 10,
                    position: 'relative',
                }}
            >
                <h3 style={{ margin: 0 }}>{'Projects'}</h3>
                <ProjectPermissionFilter
                    staffPermissions={staffPermissions}
                    filter={projectPermissions.filter}
                    onChange={onFilterChange}
                    disabled={disabled}
                />
                {!disabled ? (
                    <Button danger size="small" onClick={() => onDelete()}>
                        Delete
                    </Button>
                ) : null}
            </div>
            <PermissionGroup
                permissions={permissions}
                staffPermissions={projectPermissions.permissions}
                onChange={onChange}
                disabled={disabled}
            />
        </div>
    )
}

const ProjectPermissionFilter = ({
    filter,
    onChange,
    staffPermissions,
    disabled,
}) => {
    return (
        <div className="flex-auto flex w-full">
            <div
                style={{
                    flex: filter.type === 'all' ? '1 1 auto' : '0 0 auto',
                    marginLeft: '1em',
                    marginBottom: '0.25em',
                    position: 'relative',
                    zIndex: 10,
                }}
            >
                <Select
                    disabled={disabled}
                    showSearch
                    optionFilterProp="children"
                    value={filter.type}
                    bordered
                    onChange={(filterType) =>
                        onChange({ type: filterType, values: [] })
                    }
                    style={{ fontSize: '0.9em' }}
                >
                    {[
                        !hasPermissionsForAllProjects(staffPermissions) ||
                        filter.type === 'all'
                            ? { label: 'All Projects', value: 'all' }
                            : null,
                        {
                            label: 'Projects with Cost Centre',
                            value: 'costCentre',
                        },
                        { label: 'Specific Projects', value: 'project' },
                    ]
                        .filter((c) => c)
                        .map((option) => (
                            <Radio.Button
                                style={{
                                    fontSize: '12px',
                                }}
                                value={option.value}
                            >
                                {option.label}
                            </Radio.Button>
                        ))}
                </Select>
            </div>
            {filter.type === 'project' ? (
                <ProjectMultiselect
                    disabled={disabled}
                    className="flex-auto"
                    menuPosition="fixed"
                    projectOptions={ProjectCollection.projects.filter(
                        (p) =>
                            !getAllPermissionProjectIds(
                                staffPermissions
                            ).includes(p.id)
                    )}
                    value={filter.values.map(
                        (prId) => ProjectCollection.projectsById[prId]
                    )}
                    onChange={(values) =>
                        onChange({
                            ...filter,
                            values: values.map((pr) => pr.id),
                        })
                    }
                    styles={{
                        container: (provided, state) => ({
                            flex: '1 1 auto',
                            marginLeft: '1em',
                            marginRight: '1em',
                            fontSize: '0.9em',
                            // zIndex: 10,
                            marginBottom: '0.25em',
                        }),
                        input: (provided, state) => ({
                            ...provided,
                            padding: '0',
                        }),
                        // menu: (provided, state) => ({
                        //     ...provided,
                        //     fontSize: '0.9em',
                        //     color: '#444',
                        // }),
                        // menuPortal: ({ left, top, ...provided }, state) => ({
                        //     ...provided,
                        // }),
                    }}
                />
            ) : filter.type === 'costCentre' ? (
                <CostCentreMultiselect
                    disabled={disabled}
                    className="flex-auto"
                    menuPosition="fixed"
                    costCentreOptions={CostCentreCollection.costCentres.filter(
                        (cc) =>
                            !getAllPermissionCostCentreIds(
                                staffPermissions
                            ).includes(cc.id)
                    )}
                    value={filter.values.map(
                        (ccId) => CostCentreCollection.costCentresById[ccId]
                    )}
                    onChange={(values) =>
                        onChange({
                            ...filter,
                            values: values.map((cc) => cc.id),
                        })
                    }
                    styles={{
                        container: (provided, state) => ({
                            flex: '1 1 auto',
                            marginLeft: '1em',
                            marginRight: '1em',
                            fontSize: '0.9em',
                            // zIndex: 10,
                            marginBottom: '0.25em',
                        }),
                        input: (provided, state) => ({
                            ...provided,
                            padding: '0',
                        }),
                        // menu: (provided, state) => ({
                        //     ...provided,
                        //     fontSize: '0.9em',
                        //     color: '#444',
                        // }),
                        // menuPortal: ({ left, top, ...provided }, state) => ({
                        //     ...provided,
                        // }),
                    }}
                />
            ) : null}
        </div>
    )
}

export const PermissionGroup = ({
    title,
    permissions,
    staffPermissions,
    onChange,
    disabled,
}) => {
    return (
        <div style={{ marginBottom: title ? '4em' : 0 }}>
            {title ? (
                <h3 style={{ borderBottom: '1px solid #ccc' }}>{title}</h3>
            ) : null}
            <div>
                {permissions.map(
                    ({ label, key, type, options, display = () => true }) => {
                        if (!display(staffPermissions)) return null
                        const value = staffPermissions?.[key]
                        const localOnChange = (value) => onChange(key, value)
                        return type === 'switch' ? (
                            <PermissionSwitch
                                {...{ label, value, onChange: localOnChange }}
                                disabled={disabled}
                            />
                        ) : (
                            <PermissionRadio
                                {...{
                                    label,
                                    value,
                                    onChange: localOnChange,
                                    options,
                                }}
                                disabled={disabled}
                            />
                        )
                    }
                )}
            </div>
        </div>
    )
}

const PermissionSwitch = ({ label, value = false, onChange, disabled }) => {
    return (
        <PermissionRow label={label}>
            <Switch
                disabled={disabled}
                checked={value}
                size="small"
                onChange={(v) => onChange(v)}
            />
        </PermissionRow>
    )
}

const PermissionRadio = ({
    label,
    value = 'hidden',
    onChange,
    options = ['hidden', 'view', 'edit', 'admin'],
    disabled,
}) => {
    const styleOverrides = {
        hidden: {},
        view: {},
        edit: {},
        admin: {},
    }
    if (value === 'hidden') {
        styleOverrides.hidden = {
            backgroundColor: '#aaa',
            borderColor: '#aaa',
        }
    }
    return (
        <PermissionRow label={label}>
            <Radio.Group
                disabled={disabled}
                buttonStyle="solid"
                value={value}
                size="small"
                onChange={(e) => onChange(e.target.value)}
            >
                {options.map((option) => (
                    <Radio.Button
                        style={{
                            fontSize: '12px',
                            ...styleOverrides[option],
                        }}
                        value={option}
                    >
                        {option === 'admin'
                            ? 'Edit, Create, & Delete'
                            : capitalCase(option)}
                    </Radio.Button>
                ))}
            </Radio.Group>
        </PermissionRow>
    )
}

export const PermissionRow = ({ label, children, className }) => {
    return (
        <div
            className={className}
            css={{
                display: 'grid',
                gridTemplateColumns: '1fr 2fr',
                columnGap: '5em',
                rowGap: '0.5em',
                borderBottom: '1px solid #e9e9e9',
                alignItems: 'center',
                padding: '0.5em 0',
                ':hover': {
                    backgroundColor: '#f2f2f2',
                },
                zIndex: 1,
                position: 'relative',
            }}
        >
            <div style={{ fontWeight: 'bold', textAlign: 'right' }}>
                {label}
            </div>
            <div>{children}</div>
        </div>
    )
}
