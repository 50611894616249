import { createFileRoute, redirect, Outlet } from '@tanstack/react-router'
import PageBody from '../Pages/Layout/PageBody'
import Page from '../Pages/Layout/Page'

export const Route = createFileRoute('/_public')({
    component: Wrapper,
})

function Wrapper() {
    return (
        <Page>
            <PageBody>
                <Outlet />
            </PageBody>
        </Page>
    )
}
