import { observable, computed, action, makeObservable } from 'mobx'
import PermissionCollection from '../Collections/PermissionCollection'
import Model from './Model'
import StaffCollection from '../Collections/StaffCollection'
import { getPermissionKey } from '../../Utils/getPermissionKey'

class PermissionModel extends Model {
    @observable name = null
    @observable settings = null

    constructor(data, options) {
        super()
        makeObservable(this)
        this.collection = PermissionCollection
        this.init(data, options)
    }

    @computed
    get staff() {
        return StaffCollection.staffByPermissionsId[this.id] || []
    }

    @computed
    get key() {
        return getPermissionKey(this.settings)
    }
}

export default PermissionModel
