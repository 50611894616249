import { addDays } from 'date-fns'
import getCombinedRateAtDate from './getCombinedRateAtDate'
import _ from 'lodash'

export default (models, rateType, dateRange) => {
    const now = new Date()
    dateRange[0] ??= now
    dateRange[1] ??= now
    if (dateRange[0]?.getTime() === dateRange[1]?.getTime()) {
        return getCombinedRateAtDate(models, rateType, dateRange[0])
    }
    const businessDays = getBusinessDaysBetweenDates(...dateRange)
    return _.mean(
        businessDays.map((d) => {
            return getCombinedRateAtDate(models, rateType, d) || 0
        })
    )
}

const getBusinessDaysBetweenDates = (startDate, endDate) => {
    const start = new Date(startDate)
    const end = new Date(endDate)
    const businessDays = []
    let currentDate = start
    while (currentDate <= end) {
        const day = currentDate.getDay()
        if (![0, 6].includes(day)) {
            businessDays.push(currentDate)
        }
        currentDate = addDays(currentDate, 1)
    }
    return businessDays
}
