import { useState, useRef, useEffect } from 'react'
import { Button } from '@2/components/ui/button'
import { cn } from '@2/lib/utils'
import { useSpring, animated } from 'react-spring'

export const MarqueeButton = ({
    children,
    className,
    speed = 50,
    ...props
}) => {
    const [isOverflowing, setIsOverflowing] = useState(false)
    const [contentWidth, setContentWidth] = useState(0)
    const [containerWidth, setContainerWidth] = useState(0)
    const containerRef = useRef(null)
    const contentRef = useRef(null)

    const [{ x }, api] = useSpring(() => ({
        x: 0,
        config: { duration: 1000 },
    }))

    useEffect(() => {
        const checkOverflow = () => {
            if (contentRef.current && containerRef.current) {
                const contentWidth = contentRef.current.offsetWidth
                const containerWidth = containerRef.current.offsetWidth
                setContentWidth(contentWidth)
                setContainerWidth(containerWidth)
                setIsOverflowing(contentWidth > containerWidth)
            }
        }
        checkOverflow()
        window.addEventListener('resize', checkOverflow)
        return () => window.removeEventListener('resize', checkOverflow)
    }, [children])

    const handleMouseEnter = () => {
        if (isOverflowing) {
            const duration = (contentWidth / speed) * 1000 // Convert speed to duration

            api.start({
                from: { x: 0 },
                to: { x: -contentWidth },
                loop: true,
                config: { duration },
                reset: true,
            })
        }
    }

    const handleMouseLeave = () => {
        api.stop()
        api.start({ x: 0, config: { duration: 150 } })
    }

    const renderContent = () => {
        if (isOverflowing) {
            return (
                <>
                    <span>{children}</span>
                    <span style={{ marginLeft: '20px' }}>{children}</span>
                </>
            )
        }
        return <span>{children}</span>
    }

    return (
        <Button
            className={cn(className, 'overflow-hidden')}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
            {...props}
        >
            <div
                ref={containerRef}
                className="w-full overflow-hidden text-left"
            >
                <animated.div
                    ref={contentRef}
                    style={{
                        x,
                        whiteSpace: 'nowrap',
                        display: 'inline-block',
                    }}
                >
                    {renderContent()}
                </animated.div>
            </div>
        </Button>
    )
}
