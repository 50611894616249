import tuple from 'immutable-tuple'
import _ from 'lodash'
import { observer } from 'mobx-react'
import React, { useState } from 'react'
import Modal from '../../Components/Modal'
import { Selector } from '../../Components/Selector'
import DataStore from '../../State/DataStore'
import {
    autoAdjustPhaseBudgetAllocations,
    autoAdjustPhaseExpenseAllocations,
    autoAdjustPhaseRevenueTargets,
} from '../../Utils/adjustForecasts'
import { makeRequest } from '../../Queries/makeRequest'
import SessionStore from '../../State/SessionStore'

// tuple (revenue, resourcing)
const labels = new Map([
    [tuple(true, true), 'Revenue Forecast & Resource Schedule'],
    [tuple(true, false), 'Revenue Forecast'],
    [tuple(false, true), 'Resource Schedule'],
    [tuple(false, false), 'Nothing'],
])

const updateAmountOptions = {
    total: 'Total Budget',
    remaining: 'Remaining Budget',
}

const updateFromOptions = {
    startDate: 'Start Date',
    now: 'Now',
    endDate: 'End Date',
}

const updateToOptions = {
    startDate: 'Start Date',
    endDate: 'End Date',
}

export default observer(
    ({
        phaseIds,
        budgetPhaseIds,
        expensePhaseIds,
        modalId,
        revenue,
        hours,
    }) => {
        const [adjustRevenue, setAdjustRevenue] = useState(revenue)
        const [adjustResourcing, setAdjustResourcing] = useState(hours)
        const [updateAmount, setUpdateAmount] = useState(
            SessionStore.organisation.settings?.autoUpdateHours?.budget ||
                'remaining'
        )
        const [updateFrom, setUpdateFrom] = useState(
            SessionStore.organisation.settings?.autoUpdateHours?.start || 'now'
        )
        const [updateTo, setUpdateTo] = useState(
            SessionStore.organisation.settings?.autoUpdateHours?.end ||
                'endDate'
        )
        return (
            <Modal
                modalId={modalId}
                heading="Adjust Forecasts"
                onSave={() => {
                    if (adjustRevenue && phaseIds?.length) {
                        makeRequest({
                            path:
                                process.env.REACT_APP_NODE_SERVER_URL +
                                '/auto-adjust/revenue',
                            method: 'POST',
                            data: {
                                organisationId: SessionStore.organisation.id,
                                userId: SessionStore.user?.id,
                                phaseIds: phaseIds,
                                budgetType: updateAmount,
                                startDateType: updateFrom,
                                endDateType: updateTo,
                                nowTs: Date.now(),
                            },
                        })
                    }
                    if (adjustRevenue && expensePhaseIds?.length) {
                        makeRequest({
                            path:
                                process.env.REACT_APP_NODE_SERVER_URL +
                                '/auto-adjust/expenses',
                            method: 'POST',
                            data: {
                                organisationId: SessionStore.organisation.id,
                                userId: SessionStore.user?.id,
                                phaseIds: expensePhaseIds,
                                budgetType: updateAmount,
                                startDateType: updateFrom,
                                endDateType: updateTo,
                                nowTs: Date.now(),
                            },
                        })
                    }
                    if (adjustResourcing && budgetPhaseIds?.length) {
                        makeRequest({
                            path:
                                process.env.REACT_APP_NODE_SERVER_URL +
                                '/auto-adjust/resources',
                            method: 'POST',
                            data: {
                                organisationId: SessionStore.organisation.id,
                                userId: SessionStore.user?.id,
                                phaseIds: budgetPhaseIds,
                                budgetType: updateAmount,
                                startDateType: updateFrom,
                                endDateType: updateTo,
                                nowTs: Date.now(),
                            },
                        })
                    }
                    DataStore.startSave()
                }}
                saveLabel="Adjust Forecasts"
            >
                <div style={{ padding: '0 1em' }}>
                    {`Would you like to adjust your Project's ${labels.get(
                        tuple(revenue, hours)
                    )} based on your new settings?`}
                </div>
                <div style={{ padding: '1em' }} className="flex items-center">
                    <span>Adjust:</span>
                    <Selector
                        style={{ margin: '0 1em', width: 'auto' }}
                        selectedOption={tuple(adjustRevenue, adjustResourcing)}
                        options={[
                            ...Array.from(labels.keys()).filter(
                                (k) => (revenue || !k[0]) && (hours || !k[1])
                            ),
                        ]}
                        optionLabel={(o) => labels.get(o)}
                        onChange={(tuple) => {
                            setAdjustRevenue(tuple[0])
                            setAdjustResourcing(tuple[1])
                        }}
                        variant="secondary"
                    />
                </div>
                {adjustRevenue || adjustResourcing ? (
                    <div
                        style={{
                            padding: '2em 1em 0 1em',
                            borderTop: '1px solid #eee',
                        }}
                        className="flex items-center"
                    >
                        <span style={{ display: 'inline-block' }}>
                            Distribute
                        </span>
                        <div style={{ margin: '0 1em', width: '12em' }}>
                            <Selector
                                style={{ width: '100%' }}
                                selectedOption={updateAmount}
                                onChange={setUpdateAmount}
                                options={Object.keys(updateAmountOptions)}
                                optionLabel={(o) => updateAmountOptions[o]}
                                variant="secondary"
                            />
                        </div>
                        <span style={{ display: 'inline-block' }}>between</span>
                        <div style={{ margin: '0 1em', width: '9em' }}>
                            <Selector
                                style={{ width: '100%' }}
                                selectedOption={updateFrom}
                                onChange={setUpdateFrom}
                                options={Object.keys(updateFromOptions)}
                                optionLabel={(o) => updateFromOptions[o]}
                                variant="secondary"
                            />
                        </div>
                        <span style={{ display: 'inline-block' }}>{`>>`}</span>
                        <div style={{ margin: '0 1em', width: '9em' }}>
                            <Selector
                                style={{ width: '100%' }}
                                selectedOption={updateTo}
                                onChange={setUpdateTo}
                                options={Object.keys(updateToOptions)}
                                optionLabel={(o) => updateToOptions[o]}
                                variant="secondary"
                            />
                        </div>
                    </div>
                ) : null}
            </Modal>
        )
    }
)
