import { observer } from 'mobx-react'
import React, { Suspense, useCallback, useEffect, useState } from 'react'
import PageHeader from '../Layout/PageHeader'
import ReportCollection from '../../State/Collections/ReportCollection'
import ReportHeader from '../../Components/Reports/ReportHeader'
import ExpenseReportStore from './ExpenseReportStore'
import { Selector } from '../../Components/Selector'
import { format } from 'date-fns'
import HeaderButton from '../../Components/ui/HeaderButton'

function getConsistentValue(arr, key) {
    const uniqueValues = new Set(arr.map((obj) => obj[key]))

    return uniqueValues.size === 1 ? [...uniqueValues][0] || null : null
}

export default observer(() => {
    const report = ExpenseReportStore.report
    const selectedRows = [...ExpenseReportStore.selectedRows]
    return (
        <PageHeader
            heading={
                <div>
                    <div>{'Expense Reports'}</div>
                    <div className="text-sm font-normal px-2.5 py-1.5">
                        {isFinite(report.dateRange[0])
                            ? `(${format(
                                  report.dateRange[0],
                                  'dd MMMM yyyy'
                              )} - ${format(
                                  report.dateRange[1],
                                  'dd MMMM yyyy'
                              )})`
                            : `(All expense prior to: ${format(
                                  new Date(),
                                  'dd MMMM yyyy'
                              )})`}
                    </div>
                </div>
            }
            rightButtons={
                <div className="flex gap-2 items-center">
                    <div className="export-button-dropdown inline-flex items-center dont-print gap-2">
                        <div className="flex items-center">
                            <HeaderButton
                                label="Export"
                                icon={<i className="fa fa-table" />}
                                onClick={() => {
                                    ExpenseReportStore.downloadCSV()
                                }}
                                className="border-r-0 rounded-tr-none rounded-br-none"
                            />
                            <Selector
                                className="px-4 border! border-white bg-transparent hover:bg-white/25 transition-colors rounded-[6px] text-xs rounded-tl-none rounded-bl-none"
                                style={{
                                    maxWidth: '9em',
                                    margin: 0,
                                    fontSize: '1em',
                                }}
                                value={
                                    ExpenseReportStore.groupBy[
                                        ExpenseReportStore.exportLevel
                                    ] || 'expense'
                                }
                                options={[
                                    ...ExpenseReportStore.groupBy,
                                    'expense',
                                ]}
                                optionLabel={function (group) {
                                    return (
                                        <div>
                                            {report.columnOptionsById[group]
                                                ?.label || 'Expense'}
                                        </div>
                                    )
                                }}
                                onChange={(group) => {
                                    const index =
                                        ExpenseReportStore.groupBy.indexOf(
                                            group
                                        )
                                    ExpenseReportStore.setExportLevel(
                                        index >= 0 > 0
                                            ? index
                                            : ExpenseReportStore.groupBy.length
                                    )
                                }}
                            />
                        </div>
                    </div>
                    <HeaderButton
                        label="Print"
                        icon={<i className="fa fa-print" />}
                        onClick={() => window.print()}
                    />
                </div>
            }
            extraContent={
                <div className="flex items-center justify-between">
                    <ReportHeader
                        report={report}
                        reportOptions={
                            ReportCollection.reportsByType['expense']
                        }
                        expandLabel={
                            ExpenseReportStore.expandAll
                                ? '+ Expanding to...'
                                : '+ Expand All to'
                        }
                        expandClick={() => {
                            ExpenseReportStore.toggleExpandAll()
                        }}
                        selector={
                            <Selector
                                className="px-4 border! border-white bg-transparent hover:bg-white/25 transition-colors rounded-[6px] text-xs"
                                style={{ maxWidth: '8em' }}
                                value={
                                    ExpenseReportStore.groupBy[
                                        ExpenseReportStore.expandToLevel
                                    ] || 'expense'
                                }
                                options={[
                                    ...ExpenseReportStore.groupBy,
                                    'expense',
                                ]}
                                optionLabel={function (group) {
                                    return (
                                        <div>
                                            {report.columnOptionsById[group]
                                                ?.label || 'Expense'}
                                        </div>
                                    )
                                }}
                                onChange={(group) => {
                                    const index =
                                        ExpenseReportStore.groupBy.indexOf(
                                            group
                                        )
                                    ExpenseReportStore.setExpandToLevel(
                                        index >= 0 > 0
                                            ? index
                                            : ExpenseReportStore.groupBy.length
                                    )
                                }}
                            />
                        }
                    />
                </div>
            }
        />
    )
})
