import React, { useEffect, useState } from 'react'
import { observer } from 'mobx-react'
import Modal from '../../Components/Modal'
import { Selector } from '../../Components/Selector'
import SessionStore from '../../State/SessionStore'
import {
    beginAccountingOauth,
    fetchAccountingContacts,
} from '../../Queries/accountingOauth'
import Checkbox from '../../Components/Widgets/Checkbox'
import ContactModel from '../../State/Models/ContactModel'
import Table from '../../Components/Table'
import ContactCollection from '../../State/Collections/ContactCollection'
import DataStore from '../../State/DataStore'
import TextValue from '../../Components/Widgets/TextValue'
import TableStore from '../../Components/TableStore'
import { observable } from 'mobx'
import ConnectAccountingButton from '../../Components/ConnectAccountingButton'

const accountingDropdownOptions = [
    { label: 'None', value: 'none' },
    { label: 'Xero', value: 'xero' },
    { label: 'Myob Essentials', value: 'myob-essentials' },
    { label: 'Myob AccountRight', value: 'myob-accountright' },
    { label: 'Quickbooks', value: 'quickbooks' },
]
let selectedState = observable({ contacts: new Set() })
const ContactSelectColumns = [
    {
        id: 'selected',
        label: '',
        type: 'checkbox',
        width: 5,
        value: (row) => {
            return row.selected
        },
        component: ({ value, stores }) => {
            const { row } = stores
            return (
                <Checkbox
                    value={selectedState.contacts.has(row.rowObject)}
                    onChange={(v) => {
                        if (v) {
                            selectedState.contacts.add(row.rowObject)
                        } else {
                            selectedState.contacts.delete(row.rowObject)
                        }
                    }}
                />
            )
        },
    },
    {
        id: 'label',
        label: 'Label',
        type: 'text',
        width: 50,
        value: (row) => {
            return row.label
        },
    },
]

export default observer(({ modalId }) => {
    const [fetchStatus, setFetchStatus] = useState('idle')
    const [contacts, setContacts] = useState([])
    const [contactFilter, setContactFilter] = useState('')
    const accountingSystem =
        accountingDropdownOptions.find(
            (o) => o.value === SessionStore.organisation?.accountingSystem
        ) || accountingDropdownOptions[0]
    const accountingSystemConnected = SessionStore.accountingConnected
    const accountSystemSettings =
        SessionStore.organisation.accountingSystemSettings
    return (
        <Modal
            modalId={modalId}
            heading="Import Contacts"
            saveLabel="Import Contacts"
            onSave={() => {
                selectedState.contacts.forEach((c) => {
                    if (
                        ContactCollection.contactsByAccountingSystemId[
                            c.accountingSystemContactId
                        ]
                    ) {
                        c =
                            ContactCollection.contactsByAccountingSystemId[
                                c.accountingSystemContactId
                            ]
                        c.update({
                            firstName: c.firstName,
                            lastName: c.lastName,
                            organisationName: c.organisationName,
                            phone: c.phone,
                            email: c.email,
                        })
                    }
                    c.attach()
                    DataStore.startSave()
                })
            }}
        >
            <div
                style={{
                    padding: '1em',
                    display: 'flex',
                    alignItems: 'center',
                }}
            >
                <span style={{ flex: '0 0 auto', paddingRight: '1em' }}>
                    Accounting System:{' '}
                </span>
                <Selector
                    selectedOption={
                        accountingDropdownOptions.find(
                            (o) =>
                                o.value ===
                                SessionStore.organisation.accountingSystem
                        ) || accountingDropdownOptions[0]
                    }
                    onChange={(o) =>
                        SessionStore.organisation.update({
                            accountingSystem: o.value,
                        })
                    }
                    options={accountingDropdownOptions}
                    optionLabel={(o) => o.label}
                    style={{ flex: '1 1 auto' }}
                />
                <ConnectAccountingButton
                    action="fetchContacts"
                    onActionSuccess={(r) => {
                        setContacts(
                            r.contacts.map(
                                (c) =>
                                    new ContactModel(c, {
                                        trackUpdates: true,
                                        detached: true,
                                    })
                            )
                        )
                        setFetchStatus('fetched')
                    }}
                    onActionError={(e) => {
                        console.error(e)
                        setFetchStatus('error')
                    }}
                    onActionStart={(r) => {
                        setFetchStatus('fetching')
                    }}
                    style={{ margin: '0 1em', flex: '1 1 auto' }}
                />
            </div>
            {accountingSystemConnected ? (
                <div
                    style={{
                        padding: '1em',
                    }}
                >
                    {fetchStatus === 'fetched' ? (
                        <>
                            <div
                                style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    margin: '0 0.5em 1em',
                                    justifyContent: 'space-between',
                                }}
                            >
                                <Checkbox
                                    variant={'secondary'}
                                    label={'Select All'}
                                    value={contacts
                                        .filter(
                                            (c) =>
                                                !contactFilter.length ||
                                                c.label
                                                    .toLowerCase()
                                                    .includes(
                                                        contactFilter.toLowerCase()
                                                    )
                                        )
                                        .every((c) =>
                                            selectedState.contacts.has(c)
                                        )}
                                    onChange={(v) => {
                                        if (v) {
                                            selectedState.contacts = new Set(
                                                contacts.filter(
                                                    (c) =>
                                                        !contactFilter.length ||
                                                        c.label
                                                            .toLowerCase()
                                                            .includes(
                                                                contactFilter.toLowerCase()
                                                            )
                                                )
                                            )
                                        } else {
                                            selectedState.contacts = new Set()
                                        }
                                    }}
                                />
                                <TextValue
                                    style={{
                                        width: '20em',
                                        textAlign: 'right',
                                    }}
                                    placeholder="Search Contacts"
                                    value={contactFilter}
                                    onChange={(v) => {
                                        setContactFilter(v)
                                    }}
                                />
                            </div>
                            <div
                                style={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                }}
                            >
                                <Table
                                    style={{
                                        width: '100%',
                                        maxHeight: '22em',
                                        overflow: 'auto',
                                    }}
                                    columns={ContactSelectColumns}
                                    rows={contacts.filter(
                                        (c) =>
                                            !contactFilter.length ||
                                            c.label
                                                .toLowerCase()
                                                .includes(
                                                    contactFilter.toLowerCase()
                                                )
                                    )}
                                />
                            </div>
                        </>
                    ) : fetchStatus === 'error' ? (
                        'Error Fetching Contacts'
                    ) : (
                        ''
                    )}
                </div>
            ) : null}
        </Modal>
    )
})
