import classNames from 'classnames'
import { key } from 'localforage'
import { observer } from 'mobx-react'
import React, { useState } from 'react'
import { Link, useRouter } from '@tanstack/react-router'

export default observer(({ tabs, selectedTab, tabClick, defaultTab }) => {
    const { latestLocation } = useRouter()
    return (
        <div
            className={`page-layout__tabs absolute bottom-0 right-11 flex items-center gap-[2px]`}
        >
            {tabs.map((t) => {
                return (
                    <Link
                        className={classNames(
                            'tab text-[#444] text-xs py-2.5 px-6 cursor-pointer',
                            t.id === latestLocation?.search?.tab ||
                                t.id === selectedTab
                                ? 'bg-[#fafafa]'
                                : 'bg-gold'
                        )}
                        to={`${latestLocation.pathname}`}
                        search={(prev) => ({ ...prev, tab: t.id })}
                        key={t.label}
                    >
                        {t.label}
                    </Link>
                )
            })}
        </div>
    )
})
