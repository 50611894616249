import { useState } from 'react'
import { Button } from '@2/components/ui/button'
import {
    DropdownMenu,
    DropdownMenuTrigger,
    DropdownMenuContent,
    DropdownMenuItem,
    DropdownMenuSeparator,
    DropdownMenuSub,
    DropdownMenuSubTrigger,
    DropdownMenuSubContent,
} from '@2/components/ui/dropdown-menu'
import {
    Dialog,
    DialogContent,
    DialogDescription,
    DialogHeader,
    DialogTitle,
    DialogFooter,
    DialogClose,
} from '@2/components/ui/dialog'
import { Input } from '@2/components/ui/input'
import { Column, Row, Table } from '@tanstack/react-table'
import * as cuid from 'cuid'
import {
    useProjectReportStore,
    Fraction,
    ProjectReport,
} from './project-report-store'
import * as React from 'react'
import { FormatDate } from '@/Utils/Localisation/DateFormatter'
import download from 'downloadjs'
import Papa from 'papaparse'
import Formatter from '@/Components/Formatter'
import { trpc } from '@/system/trpc'
import { toast } from '@vendor/sonner'
import SessionStore from '@/State/SessionStore'
import { useNavigate } from '@tanstack/react-router'

interface ProjectReportActionsProps<TData>
    extends React.HTMLAttributes<HTMLDivElement> {
    table: Table<TData>
}

export function ProjectReportActions<TData>({
    table,
}: ProjectReportActionsProps<TData>) {
    const navigate = useNavigate()
    const [dialogContent, setDialogContent] = useState<string | null>(null)
    const [nameReport, setNameReport] = useState<string | null>(null)
    const { projectReport, setProjectReport } = useProjectReportStore(
        (state) => state
    )
    const checkDisableButton =
        !projectReport?.id && !SessionStore.organisation?.defaultProjectReportId

    const handleOpenDialog = (content: string) => {
        setNameReport(null)
        setDialogContent(content)
    }

    const csvFormatter = (
        data: Row<TData>,
        column: Column<TData, unknown>
    ): string => {
        const value = data.getValue(column?.id)
        switch (column.columnDef.meta?.type) {
            case 'progress':
            case 'progressBar':
                const { numerator, denominator } = value as Fraction
                if (numerator || denominator) {
                    return `${Formatter.progress(
                        numerator || 0
                    )} / ${Formatter.progress(denominator || 0)}`
                } else {
                    return ''
                }
            case 'date':
                return data
                    ? FormatDate(new Date(value as string), {
                          year: 'numeric',
                          month: '2-digit',
                          day: '2-digit',
                      })
                    : ''
            default:
                return Formatter[column.columnDef.meta?.type](value) ?? data
        }
    }
    const columns = useProjectReportStore(
        (state) => state.projectReport?.columns
    )

    const handleExportToCSV = (type: string) => {
        const results: Array<string>[] = []
        // Add headers
        const headers = table
            .getAllColumns()
            .filter((item) => columns?.includes(item.id))
        const firstRow: Array<string> = []
        if (type !== 'project') {
            firstRow.push('Project')
        }
        headers.forEach((header) => firstRow.push(header.columnDef.meta?.title))
        results.push(firstRow)
        // Add data
        table.getRowModel().rows.forEach((item: Row<TData>) => {
            if (type == 'project') {
                const row: string[] = []
                headers.forEach((header) => {
                    row.push(csvFormatter(item, header))
                })
                results.push(row)
            } else {
                const phases = item?.subRows
                if (phases.length) {
                    phases.forEach((phase) => {
                        const dataPhase = [item.getValue('name').toString()]
                        headers.forEach((header) => {
                            dataPhase.push(csvFormatter(phase, header))
                        })
                        results.push(dataPhase)
                    })
                }
            }
        })

        download(Papa.unparse(results), `project.csv`, 'text/csv')
    }

    const handleSaveReport = async (payload: Partial<ProjectReport>) => {
        return toast.promise(trpc.projectReport.saveReport.mutate(payload), {
            loading: 'Saving changes...',
            success: (data) => {
                setNameReport(null)
                setProjectReport({
                    ...projectReport,
                    ...data,
                })
                navigate({
                    to: `/projects${data?.id ? '?report=' + data?.id : ''}`,
                })
                return 'All saved'
            },
            error: (errorToast) => {
                console.log(errorToast)
                return (
                    <div className="flex items-center justify-between w-full">
                        <span>Report has not been updated</span>
                        <Button
                            onClick={() => {
                                toast.dismiss()
                                handleSaveReport(payload)
                            }}
                        >
                            Retry
                        </Button>
                    </div>
                )
            },
        })
    }

    const handleSetDefault = async () => {
        toast.promise(
            trpc.projectReport.setDefaultReport.mutate({
                id: projectReport.id,
            }),
            {
                loading: 'Saving changes...',
                success: 'All saved',
                error: (errorToast) => {
                    return (
                        <div className="flex items-center justify-between w-full">
                            <span>Report has not been set default</span>
                            <Button
                                onClick={() => {
                                    toast.dismiss()
                                    handleSetDefault()
                                }}
                            >
                                Retry
                            </Button>
                        </div>
                    )
                },
            }
        )
    }

    const handleDialogSubmit = async (dialogContent: string) => {
        switch (dialogContent) {
            case 'Save as':
                const payloadSaveAs = {
                    id: cuid(),
                    name: nameReport,
                    organisationId: projectReport.organisationId,
                    filters: projectReport.filters,
                    columns: projectReport.columns,
                    options: projectReport.options,
                }
                await handleSaveReport(payloadSaveAs)
                break
            case 'Save':
                const payloadSave = {
                    id: projectReport.id,
                    organisationId: projectReport.organisationId,
                    filters: projectReport.filters,
                    columns: projectReport.columns,
                    options: projectReport.options,
                }
                await handleSaveReport(payloadSave)
                break
            case 'Rename Report':
                if (nameReport !== projectReport.name) {
                    const payloadRename = {
                        id: projectReport.id,
                        organisationId: projectReport.organisationId,
                        name: nameReport,
                    }
                    await handleSaveReport(payloadRename)
                }
                break
            case 'Delete Report':
                const payloadDelete = {
                    id: projectReport.id,
                    organisationId: projectReport.organisationId,
                    deletedAt: new Date(),
                }
                await handleSaveReport(payloadDelete)
                break
            default:
                break
        }
        setDialogContent(null)
    }
    return (
        <>
            <DropdownMenu>
                <DropdownMenuTrigger asChild>
                    <Button
                        aria-label="Toggle columns"
                        variant="outline"
                        size="sm"
                    >
                        Actions
                    </Button>
                </DropdownMenuTrigger>
                <DropdownMenuContent align="end">
                    <DropdownMenuSub>
                        <DropdownMenuSubTrigger>Export</DropdownMenuSubTrigger>
                        <DropdownMenuSubContent sideOffset={4}>
                            <DropdownMenuItem
                                onClick={() => handleExportToCSV('project')}
                                className="cursor-pointer"
                            >
                                Projects
                            </DropdownMenuItem>
                            <DropdownMenuItem
                                onClick={() => handleExportToCSV('phase')}
                                className="cursor-pointer"
                            >
                                Phases
                            </DropdownMenuItem>
                        </DropdownMenuSubContent>
                    </DropdownMenuSub>
                    <DropdownMenuItem
                        className="cursor-pointer"
                        onClick={() => window.print()}
                    >
                        Print
                    </DropdownMenuItem>
                    <DropdownMenuSeparator />
                    <DropdownMenuItem
                        className="cursor-pointer"
                        onClick={() => handleDialogSubmit('Save')}
                    >
                        Update report
                    </DropdownMenuItem>
                    <DropdownMenuItem
                        className="cursor-pointer"
                        onClick={() => handleOpenDialog('Save as')}
                    >
                        Save as
                    </DropdownMenuItem>
                    <DropdownMenuItem
                        disabled={!projectReport?.id}
                        className="cursor-pointer"
                        onClick={handleSetDefault}
                    >
                        Set as default
                    </DropdownMenuItem>
                    <DropdownMenuItem
                        disabled={checkDisableButton}
                        className="cursor-pointer"
                        onClick={() => handleOpenDialog('Rename Report')}
                    >
                        Rename
                    </DropdownMenuItem>
                    <DropdownMenuItem
                        disabled={checkDisableButton}
                        className="cursor-pointer"
                        onClick={() => handleOpenDialog('Delete Report')}
                    >
                        Delete
                    </DropdownMenuItem>
                </DropdownMenuContent>
            </DropdownMenu>

            <Dialog
                open={!!dialogContent}
                onOpenChange={() => setDialogContent(null)}
            >
                <DialogContent className="sm:max-w-[425px]">
                    <DialogHeader>
                        <DialogTitle>{dialogContent}</DialogTitle>
                        <DialogDescription>
                            {dialogContent === 'Update report' &&
                                'Make changes to your report here. Click save when you are done.'}
                            {dialogContent === 'Save as' &&
                                'Please enter new name for this report.'}
                            {dialogContent === 'Set as default' &&
                                'Set this report as the default for future use.'}
                            {dialogContent === 'Rename Report' &&
                                'Please enter new name for this report.'}
                            {dialogContent === 'Delete Report' &&
                                'Are you sure you want to delete this report?'}
                        </DialogDescription>
                    </DialogHeader>
                    {dialogContent && (
                        <div>
                            {(dialogContent === 'Rename Report' ||
                                dialogContent === 'Save as') && (
                                <Input
                                    required
                                    type="text"
                                    placeholder="Enter new name"
                                    className="w-full"
                                    autoFocus
                                    defaultValue={
                                        dialogContent === 'Save as'
                                            ? ''
                                            : projectReport.name
                                    }
                                    value={nameReport}
                                    onChange={(e) =>
                                        setNameReport(e.target.value)
                                    }
                                />
                            )}
                        </div>
                    )}
                    <DialogFooter>
                        <DialogClose asChild>
                            <Button type="button" variant="secondary">
                                Close
                            </Button>
                        </DialogClose>
                        <Button
                            disabled={
                                dialogContent !== 'Delete Report' && !nameReport
                            }
                            onClick={() => handleDialogSubmit(dialogContent)}
                        >
                            {dialogContent === 'Delete Report'
                                ? 'Delete'
                                : 'Save changes'}
                        </Button>
                    </DialogFooter>
                </DialogContent>
            </Dialog>
        </>
    )
}
