import * as React from 'react'
import * as SliderPrimitive from '@radix-ui/react-slider'

const Slider = React.forwardRef(
    (
        {
            className,
            rootStyle = {},
            trackStyle = {},
            rangeStyle = {},
            thumbStyle = {},
            markStyle = {},
            marks = [],
            ...props
        },
        ref
    ) => {
        // Define your styles here using rem units
        const _rootStyle = {
            position: 'relative',
            display: 'flex',
            width: '100%',
            touchAction: 'none',
            userSelect: 'none',
            alignItems: 'center',
        }

        const _trackStyle = {
            position: 'relative',
            height: '0.25rem', // Equivalent to h-1 in Tailwind
            width: '100%',
            flexGrow: 1,
            overflow: 'hidden',
            borderRadius: '9999px', // Equivalent to rounded-full
            background: 'rgba(0, 0, 0, 0.2)', // Replace with your primary color at 20% opacity
        }

        const _rangeStyle = {
            position: 'absolute',
            height: '100%',
            background: 'black', // Replace with your primary color
        }

        const _thumbStyle = {
            display: 'block',
            height: '0.75rem', // Equivalent to h-3 in Tailwind
            width: '0.75rem', // Equivalent to w-3 in Tailwind
            borderRadius: '9999px', // Equivalent to rounded-full
            border: '0.2rem solid rgba(0, 0, 0, 0.5)', // Replace with your primary color at 50% opacity
            background: 'white', // Replace with your background color
            boxShadow: '0 0 0.625rem rgba(0, 0, 0, 0.1)',
            transition: 'color 0.2s',
            outline: 'none',
            ringWidth: '0.2rem', // Equivalent to ring-1 in Tailwind
            // Add additional styles for focus-visible and disabled states
        }

        const _markStyle = (percentage) => ({
            position: 'absolute',
            left: `${percentage}%`,
            top: '50%',
            transform: 'translate(-50%, -50%)',
            width: '0.375rem', // Size of the mark
            height: '0.375rem', // Size of the mark
            borderRadius: '50%', // Circular mark
            backgroundColor: 'black', // Mark color, replace as needed
        })
        return (
            <SliderPrimitive.Root
                ref={ref}
                style={{ ..._rootStyle, ...rootStyle }}
                {...props}
            >
                <SliderPrimitive.Track
                    style={{ ..._trackStyle, ...trackStyle }}
                >
                    <SliderPrimitive.Range
                        style={{ ..._rangeStyle, ...rangeStyle }}
                    />
                </SliderPrimitive.Track>
                {marks &&
                    marks
                        .filter((m) => m)
                        .map((mark) => (
                            <span
                                key={mark}
                                style={{
                                    ..._markStyle((mark / props.max) * 100),
                                    ...markStyle,
                                }}
                            />
                        ))}
                <SliderPrimitive.Thumb
                    style={{ ..._thumbStyle, ...thumbStyle }}
                />
            </SliderPrimitive.Root>
        )
    }
)

Slider.displayName = SliderPrimitive.Root.displayName

export { Slider }
