import { observable, computed, action, makeObservable } from 'mobx'
import Collection from './Collection'
import InvoiceLineItemModel from '../Models/InvoiceLineItemModel'

class InvoiceLineItemCollection extends Collection {
    constructor() {
        super({
            collection: 'invoiceLineItems',
            modelClass: InvoiceLineItemModel,
        })
        this.addLookup('lineItemsByInvoiceId', 'manyByKey', {
            key: (t) => t.invoiceId,
            filter: (t) => !t.deletedAt,
        })
        this.addLookup('lineItemsByProjectId', 'manyByKey', {
            key: (t) => t.projectId,
            filter: (t) => !t.deletedAt,
        })
        this.addLookup('lineItemsByPhaseId', 'manyByKey', {
            key: (t) => t.phaseId,
            filter: (t) => !t.deletedAt,
        })
        this.addLookup('lineItemsByContactId', 'manyByKey', {
            key: (t) => t.contactId,
            filter: (t) => !t.deletedAt,
        })
        this.addLookup('lineItemsByExpenseId', 'manyByKey', {
            key: (t) => t.expenseId,
            filter: (t) => !t.deletedAt,
        })
        this.addLookup('lineItemsByInvoicePhaseId', 'manyByKey', {
            key: (t) =>
                'i' +
                t.invoiceId +
                'ph' +
                (t.phaseId ? t.phaseId : 'undefined'),
            filter: (t) => !t.deletedAt,
        })
        makeObservable(this)
    }
    get invoiceLineItemsById() {
        return this.modelsById
    }
    get invoiceLineItems() {
        return this.models
    }
    @action.bound
    addInvoiceLineItem(data) {
        this.add(data)
    }
    @action.bound
    addInvoiceLineItems(data) {
        this.addMany(data)
    }
}

export default new InvoiceLineItemCollection()
export const InvoiceLineItemCollectionClass = InvoiceLineItemCollection
