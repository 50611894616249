import { observer } from 'mobx-react'
import React from 'react'
import Table from '../../Components/Table'
import ContactCollection from '../../State/Collections/ContactCollection'
import ContactStore from './ContactStore'
import { contactColumns } from './ContactColumns'

export default observer(() => {
    return (
        <>
            <Table
                columns={contactColumns}
                rows={ContactCollection.contacts.filter(
                    (c) =>
                        (ContactStore.searchFilter?.length > 0
                            ? c.label
                                  .toLowerCase()
                                  .includes(
                                      ContactStore.searchFilter.toLowerCase()
                                  )
                            : true) && !c.deletedAt
                )}
            />
        </>
    )
})
