import { observable, computed, action, makeObservable } from 'mobx'
import Collection from './Collection'
import StaffRateModel from '../Models/StaffRateModel'

class StaffRateCollection extends Collection {
    constructor() {
        super({ collection: 'staffRates', modelClass: StaffRateModel })
        this.addLookup('ratesByStaffId', 'manyByKey', {
            key: (t) => t.staffId,
            filter: (t) => !t.deletedAt,
        })
        makeObservable(this)
    }
    get staffRatesById() {
        return this.modelsById
    }
    get staffRates() {
        return this.models
    }
    @action.bound
    addStaffRate(data) {
        this.add(data)
    }
    @action.bound
    addStaffRates(data) {
        this.addMany(data)
    }
}

export default new StaffRateCollection()
export const StaffRateCollectionClass = StaffRateCollection
