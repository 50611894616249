import { NumberFormatter } from '@internationalized/number'
import { getUserLocale } from 'get-user-locale'
import isoLangCodes from 'iso-lang-codes'
import SessionStore from '../../State/SessionStore'
import { constantCase } from 'change-case'

export const FormatPercent = (value, { decimals = 2 } = {}) => {
    const formatter = new NumberFormatter(
        isoLangCodes.findCountryLocales(
            constantCase(SessionStore?.organisationCountry || 'us')
        )[0],
        {
            style: 'percent',
            maximumFractionDigits: decimals,
        }
    )
    return formatter.format(value)
}
