const cloneDate = date => {
	return date ? new Date(date.getTime ? date.getTime() : date) : date;
};

const newDate = () => new Date(1970, 0, 1); 

const defaultDate = (...args) => {
	let date;
	for (const arg of args) {
		date = new Date(arg && arg.getTime ? arg.getTime() : arg);
		if (date instanceof Date && date.getTime()) {
		break
		}
	}
	if (!(date instanceof Date) || !date.getTime()) {
		date = newDate();
	}
	return date;
};

const millisecondsMs = 1;
const secondsMs = 1000;
const minutesMs = secondsMs * 60;
const hoursMs = minutesMs * 60;
const daysMs = hoursMs * 24;
const weeksMs = daysMs * 7;
const yearsMs = daysMs * 365.25;
const monthsMs = yearsMs / 12;

const unitMs = {
    milliseconds: millisecondsMs,
    seconds: secondsMs,
    minutes: minutesMs,
    hours: hoursMs,
    days: daysMs,
    weeks: weeksMs,
    months: monthsMs,
    years: yearsMs,
};

const baseUnits = [
    "milliseconds",
    "seconds",
    "minutes",
    "hours",
    "days",
    "months",
    "years",
];

const standardUnits = [
	"milliseconds",
	"seconds",
	"minutes",
	"hours"
];

const unitGetters = {
    milliseconds: date => date.getMilliseconds(),
    seconds: date => date.getSeconds(),
    minutes: date => date.getMinutes(),
    hours: date => date.getHours(),
    days: date => date.getDate(),
    months: date => date.getMonth(),
    years: date => date.getFullYear(),
};

const unitSetters = {
	milliseconds: (date, val) => defaultDate(date.setMilliseconds(val)),
	seconds: (date, val) => defaultDate(date.setSeconds(val)),
	minutes: (date, val) => defaultDate(date.setMinutes(val)),
	hours: (date, val) => defaultDate(date.setHours(val)),
	days: (date, val) => defaultDate(date.setDate(val)),
	months: (date, val) => defaultDate(date.setMonth(val)),
	years: (date, val) => defaultDate(date.setFullYear(val))
};

const unitChildren = {
	milliseconds: null,
	seconds: "milliseconds",
	minutes: "seconds",
	hours: "minutes",
	days: "hours",
	months: "days",
	years: "months",
};

const unitParents = {
	milliseconds: "seconds",
	seconds: "minutes",
	minutes: "hours",
	hours: "days",
	days: "months",
	months: "years",
	years: null,
};

const isLastUnit = {
    milliseconds: date => date.getMilliseconds() === largestUnitSize.milliseconds(date) - 1,
    seconds: date => date.getSeconds() === largestUnitSize.seconds(date) - 1,
    minutes: date => date.getMinutes() === largestUnitSize.minutes(date) - 1,
    hours: date => date.getHours() === largestUnitSize.hours(date) - 1,
    days: date => date.getDate() === largestUnitSize.days(date),
    months: date => date.getMonth() === largestUnitSize.months(date) - 1,
    years: date => false
};

const largestUnitSize = {
	milliseconds: date => 1000,
	seconds: date => 60,
	minutes: date => 60,
	hours: date => hoursInDay(date),
	days: date => daysInMonth(date),
	months: date => 12,
	years: date => Infinity
};

const smallestUnitSize = {
	milliseconds: 0,
	seconds: 0,
	minutes: 0,
	hours: 0,
	days: 1,
	months: 0,
	years: 1
};

const hoursInDay = date => {
    const startOfDay = new Date(date.getFullYear(), date.getMonth(), date.getDate());
    const dayAfter = new Date(
		date.getFullYear(),
		date.getMonth(),
		date.getDate()+1
	);
    return (dayAfter - startOfDay) / hoursMs;
};

const daysInMonth = date => {
    // next month -1 day
    return new Date(date.getFullYear(), date.getMonth()+1, 0).getDate();
};

const shiftAndCloneDatesForCalculation = (
	startDate,
	endDate,
	unit
) => {
	startDate = defaultDate(startDate);
	endDate = defaultDate(endDate);
	// if a date is at the end of a month and can screw up future calculations
	// as the length of an month can vary - same with day length
	const adjustDay = !unit || unit === "months";
	const adjustHour = !unit || unit === "days";

	// if date is the end of the month we can move backwards 3 days
	// as every month has at least 28 days

	// note: we need to move the date backwards not forwards
	// as 31 Jan -> 28/29 Feb (01 Jan-01 Feb) would end up being 1 month long
	// and will break when adding a duration to a date, ie. 31 Jan + 1 month = 02 March
	let addHours =
		(adjustDay && isLastUnit["days"](startDate) ? -3 * 25 : 0) + // 25 hours to account for dst
		(adjustHour && isLastUnit["hours"](startDate) ? -2 : 0);
	if (addHours) {
		startDate = new Date(startDate.getTime() + addHours * unitMs["hours"]);
		endDate = new Date(endDate.getTime() + addHours * unitMs["hours"]);
	}
	return { startDate, endDate };
};

const moveDateWholeUnitDistance = (
	startDate,
	endDate,
	unit
) => {
    startDate = defaultDate(startDate);
    endDate = defaultDate(endDate);
	let fullUnitEndDate = cloneDate(endDate);
	// to make the end date whole units distance away from the start
	// we make all smaller values equal to the start value
	let childUnit = unitChildren[unit];
	while (childUnit) {
		// end of month/day can a problem here if not adjusted prior
		fullUnitEndDate = unitSetters[childUnit](
			fullUnitEndDate,
			unitGetters[childUnit](startDate)
		);
		childUnit = unitChildren[childUnit];
	}
	// if the endDate unit in the opposite direction to the date direction
	// we will have accidently moved the dates outside the original range
	// and need to adjust by +/- 1 unit to move it back withing the range
	const direction = Math.sign(endDate - startDate) || 1;
	const newDirection = Math.sign(fullUnitEndDate - endDate);
	if (newDirection && direction == newDirection) {
		fullUnitEndDate = unitSetters[unit](
			fullUnitEndDate,
			unitGetters[unit](endDate) - direction
		);
	}
	return fullUnitEndDate;
};

const durationDaysToWeeks = duration => {
	const days = duration.days;
	duration.weeks = Math.trunc(days / 7);
	duration.days = days - duration.weeks * 7;
	return duration;
};

const standardizeDuration = duration => {
	return cleanDuration({ ...standardDuration, ...duration });
};

const cleanDuration = duration => {
	if(duration.dateRef || 'dateRef' in duration) {
		Object.defineProperty(duration, "dateRef", {
			enumerable: false,
			value: duration.dateRef
		});
	}
	return duration;
};

const cloneDuration = duration => cleanDuration({...duration});

const standardDuration = cleanDuration({
	dateRef: null,
	milliseconds: 0,
	seconds: 0,
	minutes: 0,
	hours: 0,
	days: 0,
	weeks: 0,
	months: 0,
	years: 0
});

const addDurationToDate = (date, duration) => {
    date = defaultDate(date);
	duration = standardizeDuration(duration);

	const months = duration.years * 12 + duration.months;
	const partialMonths = months % 1;
	const days = duration.weeks * 7 + duration.days;
	const partialDays = days % 1;
	const partialHours = duration.hours % 1;
	const partialMinutes = duration.minutes % 1;
	const partialSeconds = duration.seconds % 1;

	// set date, month, year will always round to lowest integer
	// so we need to add the milliseconds for partial months
    date.setMonth(date.getMonth() + duration.years * 12 + duration.months);
	date.setDate(date.getDate() + duration.weeks * 7 + duration.days);
	date.setHours(
		date.getHours() + duration.hours,
		date.getMinutes() + duration.minutes,
		date.getSeconds() + duration.seconds,
		date.getMilliseconds() +
			duration.milliseconds +
			partialSeconds * unitMs["seconds"] +
			partialMinutes * unitMs["minutes"] +
			partialHours * unitMs["hours"] +
			partialDays * unitMs["days"] +
			partialMonths * unitMs["months"]
	);
	return date;
};

const subDurationFromDate = (date, duration) => {
	date = defaultDate(date);
	duration = standardizeDuration(duration);

	const months = duration.years * 12 + duration.months;
	const partialMonths = months % 1;
	const days = duration.weeks * 7 + duration.days;
	const partialDays = days % 1;
	const partialHours = duration.hours % 1;
	const partialMinutes = duration.minutes % 1;
	const partialSeconds = duration.seconds % 1;

	// set date, month, year will always round to lowest integer
	// so we need to add the milliseconds for partial months
	date.setMonth(date.getMonth() - duration.years * 12 - duration.months);
	date.setDate(date.getDate() - duration.weeks * 7 - duration.days);
	date.setHours(
		date.getHours() - duration.hours,
		date.getMinutes() - duration.minutes,
		date.getSeconds() - duration.seconds,
		date.getMilliseconds() -
			duration.milliseconds -
			partialSeconds * unitMs["seconds"] -
			partialMinutes * unitMs["minutes"] -
			partialHours * unitMs["hours"] -
			partialDays * unitMs["days"] -
			partialMonths * unitMs["months"]
	);
	return date;
};

const addUnitToDate = (date, unit, value) => {
	let duration = {};
	duration[unit] = value;
	return addDurationToDate(date, duration)
};

const subUnitFromDate = (date, unit, value) => {
	let duration = {};
	duration[unit] = value;
	return subDurationFromDate(date, duration);
};

const wholeUnitsBetween = (
	_startDate,
	_endDate,
	unit,
	multiple = 1
) => {
	_startDate = defaultDate(_startDate);
  	_endDate = defaultDate(_endDate);
	if (unit === "weeks") {
		unit = "days";
		multiple = 7;
	}
	const multiUnit = unitMs[unit] * multiple;

	// everything below is unecessary work for standard units of time
	if (standardUnits.includes(unit)) {
		return Math.trunc((_endDate - _startDate) / multiUnit);
	}

	let { startDate, endDate } = shiftAndCloneDatesForCalculation(
		_startDate,
		_endDate,
		unit
	);
	// move the end date to be whole units away from the start date
	const fullUnitEndDate = moveDateWholeUnitDistance(startDate, endDate, unit);
	// although units like days and months can have inconsistant values
	// we know the end date is whole units away from the start
	// and variance in those values are never large enough to cause issue
	// so we can just round the average value
	const fullUnitMs = fullUnitEndDate - startDate;
	let fullUnits = Math.round(fullUnitMs / unitMs[unit]);
	fullUnits = Math.trunc((fullUnits * unitMs[unit]) / multiUnit);
	return fullUnits;
};

const durationBetween = (_startDate, _endDate) => {
    _startDate = defaultDate(_startDate);
    _endDate = defaultDate(_endDate);
    let { startDate, endDate } = shiftAndCloneDatesForCalculation(
		_startDate,
        _endDate	
    );
    let duration = {...standardDuration};
    duration.dateRef = cloneDate(_startDate);
    const units = [...baseUnits].reverse();
    units.forEach(unit => {
        duration[unit] = wholeUnitsBetween(startDate, endDate, unit);
        startDate = unitSetters[unit](
			startDate,
			unitGetters[unit](startDate) + duration[unit]
		);
    });
    duration = durationDaysToWeeks(duration);
    return duration;
};

const normalizeDuration = (duration, dateRef) => {
    let startDate = defaultDate(dateRef, duration.dateRef);
    let endDate = addDurationToDate(startDate, duration);
    return durationBetween(startDate, endDate)
};

const unitsBetween = (startDate, endDate, unit, multiple = 1) => {
  if (unit === 'weeks') {
    unit = 'days';
    multiple = 7;
  }
	startDate = defaultDate(startDate);
  endDate = defaultDate(endDate);
  const multiUnit = unitMs[unit] * multiple;
  const fullUnits = wholeUnitsBetween(startDate, endDate, unit, multiple);
  const fullUnitMs = fullUnits * multiUnit;
  const partialUnitMs = endDate - startDate - fullUnitMs;
  const partialUnits = partialUnitMs / multiUnit;
  return fullUnits + partialUnits
};

const durationToUnit = (duration, unit, multiple = 1, dateRef) => {
	let startDate = defaultDate(dateRef, duration.dateRef);
	startDate = cloneDate(startDate);
	let endDate = addDurationToDate(startDate, duration);
	return unitsBetween(startDate, endDate, unit, multiple);
};

const largestWholeUnitBetween = (
	_startDate,
	_endDate
) => {
    _startDate = defaultDate(_startDate);
    _endDate = defaultDate(_endDate);
	let duration = {};
	duration.dateRef = cloneDate(_startDate);
	let smallestUnit = null;
	let parentUnit = unitParents["milliseconds"];
	let unitVal = 0;
	while (parentUnit) {
		const units = unitsBetween(_startDate, _endDate, parentUnit);
		if (units && units % 1 === 0) {
			smallestUnit = parentUnit;
			unitVal = units;
			parentUnit = unitParents[parentUnit];
		} else {
			parentUnit = null;
			break;
		}
	}
	// set to milliseconds if no results
	smallestUnit = smallestUnit || "milliseconds";
	unitVal = unitVal || _endDate - _startDate;
	// check weeks
	if (smallestUnit === "days" && unitVal % 7 === 0) {
		smallestUnit = "weeks";
		unitVal = unitVal / 7;
	}
	duration[smallestUnit] = unitVal;
	return cleanDuration(duration);
};

const simplifyDuration = (duration, dateRef) => {
    let startDate = defaultDate(dateRef, duration.dateRef);
    let endDate = addDurationToDate(startDate, duration);
    return largestWholeUnitBetween(startDate, endDate);
};

const matchDurationUnits = (duration1, duration2) => {
    duration1 = simplifyDuration(duration1);
    duration2 = simplifyDuration(duration2);
    const duration1Unit = Object.keys(duration1)[0];
    const duration2Unit = Object.keys(duration2)[0];
    const duration2Amount = durationToUnit(duration2, duration1Unit);
    let newDuration2 = {};
    newDuration2[duration1Unit] = duration2Amount;
    return [duration1, newDuration2]
};

const addDurations = (leftDuration, rightDuration) => {
    leftDuration = standardizeDuration(leftDuration);
    rightDuration = standardizeDuration(rightDuration);
    Object.keys(leftDuration).forEach(
		unit => (leftDuration[unit] += rightDuration[unit])
	);
	leftDuration.dateRef =
		leftDuration.dateRef ||
		rightDuration.dateRef && subDurationFromDate(
			rightDuration.dateRef,
			rightDuration
		);
    return normalizeDuration(leftDuration)
};

const subDurations = (leftDuration, rightDuration) => {
	leftDuration = standardizeDuration(leftDuration);
	rightDuration = standardizeDuration(rightDuration);
	Object.keys(leftDuration).forEach(
		unit => (leftDuration[unit] -= rightDuration[unit])
    );
	leftDuration.dateRef = leftDuration.dateRef || rightDuration.dateRef && addDurationToDate(rightDuration.dateRef, rightDuration);
    return normalizeDuration(leftDuration);
};

const multiplyDurationBy = (duration, multiplier) => {
    duration = cloneDuration(duration);
    Object.keys(duration).forEach(unit => (duration[unit] *= multiplier));
	return normalizeDuration(duration);
};

const divideDurationBy = (duration, divider) => {
	duration = cloneDuration(duration);
	Object.keys(duration).forEach(unit => (duration[unit] /= divider));
	return normalizeDuration(duration);
};

const transformDuration = (duration, transformer = (unit, val) => val ) => {
	duration = cloneDuration(duration);
	Object.keys(duration).forEach(unit => (duration[unit] = transformer(unit, duration[unit])));
	return normalizeDuration(duration);
};

const multiplyDurations = (leftDuration, rightDuration) => {
	const [lDuration, rDuration] = matchDurationUnits(leftDuration, rightDuration);
	const unit = Object.keys(lDuration)[0];
	const amount = lDuration[unit] *= rDuration[unit];
	let duration = {};
	duration[unit] = amount;
	// powerDuration
	return {duration, power: 2};
};

const divideDurations = (leftDuration, rightDuration) => {
	const [lDuration, rDuration] = matchDurationUnits(leftDuration, rightDuration);
	const unit = Object.keys(lDuration)[0];
	return lDuration[unit] / rDuration[unit]
};

const dividePowerDurationByDuration = (powerDuration, duration) => {
	const powerDurationVal = cloneDuration(powerDuration.duration);
	const unit = Object.keys(powerDurationVal)[0];
	const divider = durationToUnit(duration, unit);
	const amount = powerDurationVal[unit] / divider;
	let newDuration = {};
	newDuration[unit] = amount;
	const power = powerDuration.power - 1;
	if (power === 1) {
		return newDuration
	} else if (power === 0) {
		return amount
	} else {
		return { duration: newDuration, power: 2 }
	}
};

const cloneRate = (rate) => ({
		 amount: rate.amount,
		 per: cloneDuration(rate.per)
       });

const standardRate = cloneRate({
	amount: 1,
	per: {hours: 1}
});

const simplifyRate = (rate) => {
    rate = cloneRate(rate);
    rate.per = simplifyDuration(rate.per);
    const durationUnit = Object.keys(rate.per)[0];
    const durationAmount = Object.values(rate.per)[0];
    rate.amount /= durationAmount;
    rate.per[durationUnit] = 1;
    return rate;
};

const matchRateUnits = (rate1, rate2) => {
    rate1 = simplifyRate(rate1);
    rate2 = simplifyRate(rate2);
    const rate1Unit = Object.keys(rate1.per)[0];
    const rate2Divider = durationToUnit(rate2.per, rate1Unit);
    rate2.amount /= rate2Divider;
    rate2.per = rate1.per;
    return [rate1, rate2];
};

const ratePerUnit = (rate, unit) => {
    rate = simplifyRate(rate);
    const divider = durationToUnit(rate.per, unit);
    rate.amount /= divider;
    let duration = {};
    duration[unit] = 1;
    rate.per = duration;
    return rate;
};

const addRates = (leftRate, rightRate) => {
	const [lRate, rRate] = matchRateUnits(leftRate, rightRate);
	lRate.amount += rRate.amount;
    return lRate
};

const subRates = (leftRate, rightRate) => {
	const [lRate, rRate] = matchRateUnits(leftRate, rightRate);
	lRate.amount -= rRate.amount;
	return lRate
};

const divideRates = (leftRate, rightRate) => {
  const [lRate, rRate] = matchRateUnits(leftRate, rightRate);
  const diff = lRate.amount / rRate.amount;
  return diff
};

const multiplyRateBy = (rate, multiplier) => {
    rate = cloneRate(rate);
    rate.amount *= multiplier;
	return rate;
};

const divideRateBy = (rate, divider) => {
	rate = cloneRate(rate);
    rate.amount /= divider;
	return rate;
};

const multiplyRateByDuration = (rate, duration) => {
	rate = simplifyRate(rate);
	duration = simplifyDuration(duration);
	let amount = rate.amount / unitMs[Object.keys(rate.per)[0]]; 
	amount *= unitMs[Object.keys(duration)[0]]; 
	amount *= Object.values(duration)[0]; 
	return amount
};

const divideNumberByRate = (number, rate) => {
	rate = simplifyRate(rate);
	number /= rate.amount;
	const unit = Object.keys(rate.per)[0];
	let duration = {};
	duration[unit] = number;
	return duration
};

const divideNumberByDuration = (number, duration) => {
	return cloneRate({amount: number, duration})
};

const roundDateToNearestUnit = (date, unit, multiple = 1) => {
    date = defaultDate(date);
    let childUnit = unitChildren[unit];
    date = unitSetters[unit](
		date,
		Math.round(unitGetters[unit](date) / multiple) * multiple
	);
    while (childUnit) {
        date = unitSetters[childUnit](date, smallestUnitSize[childUnit]);
        childUnit = unitChildren[childUnit];
    }
    return date;
};

const roundDateToNearestUnitAfter = (date, unit, multiple = 1) => {
	date = defaultDate(date);
	date = unitSetters[unit](
		date,
		Math.ceil(unitGetters[unit](date) / multiple) * multiple
	);
	let childUnit = unitChildren[unit];
	while (childUnit) {
		date = unitSetters[childUnit](date, smallestUnitSize[childUnit]);
		childUnit = unitChildren[childUnit];
	}
    return date;
};

const roundDateToNearestUnitBefore = (date, unit, multiple = 1) => {
	date = defaultDate(date);
	date = unitSetters[unit](
		date,
		Math.floor(unitGetters[unit](date) / multiple) * multiple
	);
	let childUnit = unitChildren[unit];
	while (childUnit) {
		date = unitSetters[childUnit](date, smallestUnitSize[childUnit]);
		childUnit = unitChildren[childUnit];
	}
    return date;
};

const timeFromDate = (
	date
) => {
    date = defaultDate(date);
    let time = cleanDuration({dateRef: date});
    time.milliseconds = date.getMilliseconds();
    time.seconds = date.getSeconds();
    time.minutes = date.getMinutes();
    time.hours = date.getHours();
	return time;
};

const smallestTimeUnit = (duration) => {
    if (duration.milliseconds) return "milliseconds";
    if (duration.seconds) return "seconds";
    if (duration.minutes) return "minutes";
    if (duration.hours) return "hours";
};

const smallestTimeUnitFromDate = (date = newDate()) => {
    return smallestTimeUnit(timeFromDate(date))
};

const unitLessThan = (
	startDate,
	endDate,
	unit
) => {
	startDate = defaultDate(startDate);
	endDate = defaultDate(endDate);
	let unitGetter = unitGetters[unit];
	return unitGetter(startDate) < unitGetter(endDate);
};

const unitLessThanEqual = (
	startDate,
	endDate,
	unit
) => {
	startDate = defaultDate(startDate);
	endDate = defaultDate(endDate);
	let unitGetter = unitGetters[unit];
	return unitGetter(startDate) <= unitGetter(endDate);
};

const unitEqual = (
	startDate,
	endDate,
	unit
) => {
	startDate = defaultDate(startDate);
	endDate = defaultDate(endDate);
	let unitGetter = unitGetters[unit];
	return unitGetter(startDate) === unitGetter(endDate);
};

const unitGreaterThan = (
	startDate,
	endDate,
	unit
) => {
	startDate = defaultDate(startDate);
	endDate = defaultDate(endDate);
	let unitGetter = unitGetters[unit];
	return unitGetter(startDate) > unitGetter(endDate);
};

const unitGreaterThanEqual = (
	startDate,
	endDate,
	unit
) => {
	startDate = defaultDate(startDate);
	endDate = defaultDate(endDate);
	let unitGetter = unitGetters[unit];
	return unitGetter(startDate) >= unitGetter(endDate);
};

export { addDurationToDate, addDurations, addRates, addUnitToDate, baseUnits, cleanDuration, cloneDate, cloneDuration, cloneRate, daysInMonth, defaultDate, divideDurationBy, divideDurations, divideNumberByDuration, divideNumberByRate, dividePowerDurationByDuration, divideRateBy, divideRates, durationBetween, durationDaysToWeeks, durationToUnit, hoursInDay, isLastUnit, largestUnitSize, largestWholeUnitBetween, matchDurationUnits, matchRateUnits, moveDateWholeUnitDistance, multiplyDurationBy, multiplyDurations, multiplyRateBy, multiplyRateByDuration, newDate, normalizeDuration, ratePerUnit, roundDateToNearestUnit, roundDateToNearestUnitAfter, roundDateToNearestUnitBefore, shiftAndCloneDatesForCalculation, simplifyDuration, simplifyRate, smallestTimeUnit, smallestTimeUnitFromDate, smallestUnitSize, standardDuration, standardRate, standardUnits, standardizeDuration, subDurationFromDate, subDurations, subRates, subUnitFromDate, timeFromDate, transformDuration, unitChildren, unitEqual, unitGetters, unitGreaterThan, unitGreaterThanEqual, unitLessThan, unitLessThanEqual, unitMs, unitParents, unitSetters, unitsBetween, wholeUnitsBetween };
