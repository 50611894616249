import { observable, computed, action, makeObservable } from 'mobx'
import Collection from './Collection'
import ProjectExpenseModel from '../Models/ProjectExpenseModel'

class ProjectExpenseCollection extends Collection {
    constructor() {
        super({
            collection: 'projectExpenses',
            modelClass: ProjectExpenseModel,
        })
        this.addLookup('expensesByProjectId', 'manyByKey', {
            key: (t) => t.projectId,
            filter: (t) => !t.deletedAt,
        })
        this.addLookup('expensesByPhaseId', 'manyByKey', {
            key: (t) => t.phaseId,
            filter: (t) => !t.deletedAt,
        })
        this.addLookup('expensesByName', 'manyByKey', {
            key: (t) => t.name,
            filter: (t) => !t.deletedAt,
        })
        makeObservable(this)
    }
    get projectExpensesById() {
        return this.modelsById
    }
    get projectExpenses() {
        return this.models
    }
    @action.bound
    addProjectExpense(data) {
        this.add(data)
    }
    @action.bound
    addProjectExpenses(data) {
        this.addMany(data)
    }
}

export default new ProjectExpenseCollection()
export const ProjectExpenseCollectionClass = ProjectExpenseCollection
