import { parse, parseISO } from 'date-fns'
import DataStore from '../State/DataStore'
import LayoutStore from '../State/LayoutStore'
import SessionStore from '../State/SessionStore'
import { makeRequest } from './makeRequest'
import { router } from '../App'

export const beginAccountingOauth = async (
    accountingSystem = SessionStore.organisation.accountingSystem
) => {
    await DataStore.startSave()
    accountingSystem ??= SessionStore.organisation.accountingSystem
    let r = await makeRequest({
        path: `api/v1.5/accounting/redirect`,
        method: 'post',
        data: {
            accountingSystem,
            timezoneOffset: -1 * new Date().getTimezoneOffset(),
            redirectUri: `${process.env.REACT_APP_CLIENT_URL}/accounting/auth`,
            state:
                router.latestLocation.pathname +
                (LayoutStore.modals.has('invoice-settings') ||
                router.latestLocation?.search?.modal === 'invoice-settings'
                    ? '?modal=invoice-settings'
                    : ''),
        },
    })
    window.location.href = r?.data?.url
}

export const connectAccountingOauth = async (
    accountingSystem = SessionStore.organisation.accountingSystem
) => {
    let r = await makeRequest({
        path: `api/v1.5/accounting/connect`,
        method: 'post',
        data: {
            accountingSystem,
            redirectUri: `${process.env.REACT_APP_CLIENT_URL}/accounting/auth`,
            ...router.latestLocation?.search,
        },
    })
    await SessionStore.saveAccountingExpires(parseISO(r.data.expiresAt))
}

export const syncInvoiceToAccounting = async (
    invoice,
    accountingSystem = SessionStore.organisation.accountingSystem
) => {
    const lineItemDescriptions = {}
    invoice.lineItems.forEach((li) => {
        lineItemDescriptions[li.id] = li.formattedDescription
    })
    let r = await makeRequest({
        path: `api/v1.5/accounting/sync`,
        method: 'post',
        data: {
            accountingSystem,
            redirectUri: `${process.env.REACT_APP_CLIENT_URL}/accounting/auth`,
            invoiceId: invoice.id,
            lineItemDescriptions,
        },
    })
    invoice.accountingSystemId = SessionStore.organisation.accountingSystem
    invoice.accountingSystemInvoiceId =
        r?.data?.accountingSystemInvoiceId || invoice.accountingSystemInvoiceId
}

export const fetchAccountingSettings = async (
    accountingSystem = SessionStore.organisation.accountingSystem
) => {
    let r = await makeRequest({
        path: `api/v1.5/accounting/settings`,
        method: 'post',
        data: {
            accountingSystem,
            redirectUri: `${process.env.REACT_APP_CLIENT_URL}/accounting/auth`,
        },
    })
    SessionStore.organisation.updateAccountingSystemSettings({ data: r.data })
    return r.data
}

export const fetchAccountingContacts = async (
    accountingSystem = SessionStore.organisation.accountingSystem
) => {
    let r = await makeRequest({
        path: `api/v1.5/accounting/contacts`,
        method: 'post',
        data: {
            accountingSystem,
            redirectUri: `${process.env.REACT_APP_CLIENT_URL}/accounting/auth`,
        },
    })
    return r.data
}
