import React, { Suspense, useCallback, useEffect } from 'react'
import Navigation from './Navigation'
import { observer } from 'mobx-react'
import SaveBar from './SaveBar'
import Banner, { UpdateBanner } from './Banner'

export default observer(({ children }) => {
    return (
        <>
            <div
                className="coincraft-page-layout__header flex-none w-full relative"
                style={{ zIndex: 10001 }}
            >
                <UpdateBanner />
                <Banner />
                <Navigation />
                <SaveBar />
            </div>
            {children}
        </>
    )
})
