import { FormatCurrency } from '../../Utils/Localisation/CurrencyFormatter'
import { FormatNumber } from '../../Utils/Localisation/NumberFormatter'
import { getDescriptionCodeText } from '../../State/Models/InvoiceLineItemModel'
import { FormatPercent } from '../../Utils/Localisation/PercentFormatter'

export const columnDefs = {
    description: {
        key: 'description',
        label: 'Description',
        value: (line) => line.formattedDescription,
        width: 3,
        align: 'left',
    },
    quantity: {
        key: 'quantity',
        label: 'Quantity',
        value: (line) =>
            !line.lineItemType !== 'note'
                ? ['progress', 'projectProgress'].includes(line.lineItemType)
                    ? FormatPercent(line.unitQuantity)
                    : FormatNumber(line.unitQuantity)
                : '',
        width: 1,
        align: 'right',
    },
    unitPrice: {
        key: 'unitPrice',
        label: 'Unit Price',
        value: (line) =>
            !line.lineItemType !== 'note' ? FormatCurrency(line.unitCost) : '',
        width: 1,
        align: 'right',
    },
    phase: {
        key: 'phase',
        label: 'Phase',
        value: getDescriptionCodeText.phase,
        width: 3,
        align: 'left',
    },
    previouslyBilled: {
        key: 'previouslyBilled',
        label: 'Previously Billed',
        value: getDescriptionCodeText.previousbilled,
        width: 1,
        align: 'right',
    },
    progress: {
        key: 'progress',
        label: 'Progress',
        value: getDescriptionCodeText.phaseprogress,
        width: 1,
        align: 'left',
    },
    amount: {
        key: 'amount',
        label: 'Amount',
        value: (line) =>
            !line.lineItemType !== 'note' ? FormatCurrency(line.amount) : '',
        width: 1,
        align: 'right',
    },
}
