import { NumberFormatter } from '@internationalized/number'
import { getUserLocale } from 'get-user-locale'
import LocaleCurrency from 'locale-currency'
import isoLangCodes from 'iso-lang-codes'
import SessionStore from '../../State/SessionStore'
import { capitalCase, capitalCaseTransform } from 'change-case'
import { constantCase } from 'change-case'

export const FormatCurrency = (
    value,
    {
        decimals = 2,
        currency = LocaleCurrency.getCurrency(
            isoLangCodes.findCountryLocales(
                constantCase(SessionStore?.organisationCountry || 'us')
            )[0]
        ),
        compact = false,
    } = {}
) => {
    if ([null, undefined, ''].includes(value)) return value
    const formatter = new NumberFormatter(
        isoLangCodes.findCountryLocales(
            constantCase(SessionStore?.organisationCountry || 'us')
        )[0],
        {
            style: 'currency',
            currency: currency || 'USD',
            maximumFractionDigits: decimals,
            notation: compact ? 'compact' : 'standard',
        }
    )
    return formatter.format(value)
}
