import React, { useState } from 'react'
import Modal from '../../Components/Modal'
import LayoutStore from '../../State/LayoutStore'
import { ErrorAlert } from '../../widgets'
import SessionStore from '../../State/SessionStore'
import * as Sentry from '@sentry/react'
import { Link, useNavigate } from '@tanstack/react-router'
import classNames from 'classnames'

export default ({ modalId }) => {
    const navigate = useNavigate()
    const [loginState, setLoginState] = useState('idle')
    const [email, setEmail] = useState(SessionStore?.user?.email || '')
    const [password, setPassword] = useState('')

    const handleSubmit = async (event) => {
        event?.preventDefault?.()
        try {
            setLoginState('pending')
            await SessionStore.login({ email, password })
            setLoginState('success')
        } catch (error) {
            Sentry.captureException(error)
            setLoginState('error')
        }
    }

    return (
        <Modal
            modalId={modalId}
            heading={'Login Expired'}
            saveLabel={'Sign In'}
            onSave={handleSubmit}
            onClose={() => {
                LayoutStore.closeModal(modalId)
                navigate({ to: '/login' })
            }}
        >
            <div className="body">
                <form
                    onSubmit={async (e) => {
                        try {
                            await handleSubmit(e)
                            LayoutStore.closeModal(modalId)
                        } catch (e) {
                            console.error(e)
                        }
                    }}
                >
                    {' '}
                    <div className="form-group mb-5">
                        <div className="input-group input-group-lg">
                            <input
                                value={email || ''}
                                onChange={(e) => setEmail(e.target.value)}
                                type="email"
                                className="form-control input-lg login-widget__email-input input-base w-full h-10 py-2 focus:outline-none hover:border-[#618fb0]"
                                placeholder="Your Email"
                            />
                        </div>
                    </div>
                    <div className="form-group">
                        <div className="input-group input-group-lg">
                            <input
                                type="password"
                                value={password || ''}
                                onChange={(e) => setPassword(e.target.value)}
                                className="form-control input-lg login-widget__password-input input-base w-full h-10 py-2 focus:outline-none hover:border-[#618fb0]"
                                placeholder="Your Password"
                            />
                        </div>
                    </div>
                    <button type="submit" style={{ display: 'none' }} />
                </form>
                {loginState === 'error' && (
                    <ErrorAlert>
                        {`Sorry, we couldn't log you in with those details. Please try again.`}
                    </ErrorAlert>
                )}
                <div className="forgot" style={{ textAlign: 'right' }}>
                    <Link
                        to={`/forgotten-password?email=${email}`}
                        className="forgot"
                    >
                        Forgotten Your Password?
                    </Link>
                </div>
            </div>
        </Modal>
    )
}
