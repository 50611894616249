import _ from 'lodash'
import { makeObservable, observable, computed, action, autorun } from 'mobx'
import SessionStore from '../../State/SessionStore'
import StaffCollection from '../../State/Collections/StaffCollection'
import { capitalCase } from 'change-case'

class BillingPageStore {
    @observable isSubscribing = false
    @observable billingPeriod = 'yearly'
    constructor() {
        makeObservable(this)
    }
    @action.bound
    setIsSubscribing(isSubscribing) {
        this.isSubscribing = isSubscribing
    }
    @action.bound
    setBillingPeriod(billingPeriod) {
        this.billingPeriod = billingPeriod
    }
    @computed
    get adminPrice() {
        return this.billingPeriod === 'yearly'
            ? SessionStore.subscription.yearlyAdminPlanPrice
            : SessionStore.subscription.monthlyAdminPlanPrice
    }
    @computed
    get standardPrice() {
        return this.billingPeriod === 'yearly'
            ? SessionStore.subscription.yearlyStandardPlanPrice
            : SessionStore.subscription.monthlyStandardPlanPrice
    }

    getFeeAtLevel(level) {
        const pricing = {
            admin: this.adminPrice,
            standard: this.standardPrice,
            none: 0,
        }
        return pricing[level] / 100
    }

    @computed
    get pricingPerStaff() {
        const permissionOrder = {
            admin: 1,
            standard: 2,
            none: 3,
        }
        const pricing = {
            admin: this.adminPrice,
            standard: this.standardPrice,
            none: 0,
        }

        return [...StaffCollection.staff]
            .sort(
                (a, b) =>
                    permissionOrder[a.billingLevel] -
                    permissionOrder[b.billingLevel]
            )
            .map((staff, index) => {
                return {
                    index: index + 1,
                    fullName: staff.fullName,
                    permissionLevel: capitalCase(staff.billingLevel),
                    fee: pricing[staff.billingLevel] / 100,
                    staff: staff,
                }
            })
    }

    @computed
    get discountDecimal() {
        return this.billingPeriod === 'yearly'
            ? SessionStore.subscription.yearlyDiscount / 100
            : SessionStore.subscription.monthlyDiscount / 100
    }

    @computed
    get taxDecimal() {
        return SessionStore.subscription.taxPercent / 100
    }

    @computed
    get total() {
        return _.sum(this.pricingPerStaff.map((p) => p.fee))
    }

    @computed
    get grandTotal() {
        return (
            this.total * (1 - this.discountDecimal) * (1 + this.taxDecimal) -
            (SessionStore.subscription.credit || 0) / 100
        )
    }
}

export default new BillingPageStore()
