import { observable, computed, action, makeObservable } from 'mobx'
import Collection from './Collection'
import OrganisationHolidayModel from '../Models/OrganisationHolidayModel'

class OrganisationHolidayCollection extends Collection {
    constructor() {
        super({
            collection: 'organisationHolidays',
            modelClass: OrganisationHolidayModel,
        })
        this.addLookup('activeHolidays', 'list', {
            filter: (t) => !t.deletedAt,
        })
        makeObservable(this)
    }
    get organisationHolidaysById() {
        return this.modelsById
    }
    get organisationHolidays() {
        return this.models
    }
    @action.bound
    addOrganisationHoliday(data) {
        this.add(data)
    }
    @action.bound
    addOrganisationHolidays(data) {
        this.addMany(data)
    }
}

export default new OrganisationHolidayCollection()
export const OrganisationHolidayCollectionClass = OrganisationHolidayCollection
