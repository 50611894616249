import classNames from 'classnames'
import { observer } from 'mobx-react'
import React, { useEffect } from 'react'
import ContactCollection from '../../State/Collections/ContactCollection'
import { canEditContacts } from '../../State/Permissions/HasPermissions'
import SessionStore from '../../State/SessionStore'
import { Link } from '@tanstack/react-router'

export default observer(({ contactId }) => {
    const isAtLeastPM = true
    const isAtLeastAdmin = true
    const contact = ContactCollection.contactsById[contactId]
    if (!contact) return null
    return (
        <div className="flex w-full">
            <div
                className="flex-0-0-auto flex items-center justify-center mx-auto"
                style={{ padding: '0.5em', width: '100%' }}
            >
                <div className="form-horizontal">
                    <ContactField
                        contact={contact}
                        prop={'firstName'}
                        label={'First name'}
                    />
                    <ContactField
                        contact={contact}
                        prop={'lastName'}
                        label={'Last name'}
                    />
                    <ContactField
                        contact={contact}
                        prop={'organisationName'}
                        label={'Organisation name'}
                    />
                    <ContactField
                        contact={contact}
                        prop={'phone'}
                        label={'Phone'}
                    />
                    <ContactField
                        contact={contact}
                        prop={'email'}
                        label={'Email'}
                    />
                    <ContactField
                        contact={contact}
                        prop={'address'}
                        label={'Address'}
                    />
                    <ContactField
                        contact={contact}
                        prop={'notes'}
                        label={'Notes'}
                    />
                </div>
                {contact?.projects?.length ? (
                    <div style={{ margin: '1.5em' }}>
                        <h4>Projects</h4>
                        {contact.projects
                            .sort((a, b) => b.tile - a.title)
                            .map((p) => (
                                <span className="mr-2 text-nowrap inline-block">
                                    <Link to={`/projects/${p.id}`}>
                                        {p.title}
                                    </Link>
                                    {',     '}
                                </span>
                            ))}
                    </div>
                ) : null}
                {contact?.invoices?.length ? (
                    <div style={{ margin: '1.5em', marginTop: '2em' }}>
                        <h4>Invoices</h4>
                        {contact.invoices
                            .sort((a, b) => b.ref - a.ref)
                            .map((i) => (
                                <span className="mr-2 text-nowrap inline-block">
                                    <Link to={`/invoices/${i.id}`}>
                                        {i.ref}
                                    </Link>
                                    {',    '}
                                </span>
                            ))}
                    </div>
                ) : null}
            </div>
        </div>
    )
})

const ContactField = observer(({ contact, label, prop }) => {
    const editable = canEditContacts(SessionStore.user)
    return (
        <div className="form-group mb-5">
            <div className="col-md-3">
                <label>{label}:</label>
            </div>
            <div className={classNames('col-md-8', `contact-form__${prop}`)}>
                {prop === 'notes' || prop === 'address' ? (
                    <textarea
                        cols={60}
                        rows={4}
                        value={contact[prop]}
                        onChange={(e) => {
                            const updates = {}
                            updates[prop] = e.target.value
                            contact.update(updates)
                        }}
                        disabled={!editable}
                        className="input-base"
                    />
                ) : (
                    <input
                        type="text"
                        value={contact[prop] || ''}
                        onChange={(e) => {
                            const updates = {}
                            updates[prop] = e.target.value
                            contact.update(updates)
                        }}
                        disabled={!editable}
                        className="input-base"
                    />
                )}
            </div>
        </div>
    )
})
