import { pascalCase } from 'change-case'
import { observable, computed, action, makeObservable } from 'mobx'
import RoleCollection from '../Collections/RoleCollection'
import RoleRateCollection from '../Collections/RoleRateCollection'
import StaffCollection from '../Collections/StaffCollection'
import Model from './Model'
import _ from 'lodash'
import getRateAtDate from '../../Utils/getRateAtDate'
import getRateInDateRange from '../../Utils/getRateInDateRange'

class RoleModel extends Model {
    @observable isArchived = null
    @observable avgPayRate = null
    @observable avgOvertimeRate = null
    @observable avgCostRate = null
    @observable avgChargeOutRate = null

    constructor(data, options) {
        super()
        makeObservable(this)
        this.collection = RoleCollection
        this.init(data, options)
    }
    @computed
    get rates() {
        return RoleRateCollection.ratesByRoleId[this.id] || []
    }
    @computed
    get payRate() {
        return this.getRateAtDate('pay', new Date())
    }
    @computed
    get overtimeRate() {
        return this.getRateAtDate('overtime', new Date())
    }
    @computed
    get costRate() {
        return this.getRateAtDate('cost', new Date())
    }
    @computed
    get chargeOutRate() {
        return this.getRateAtDate('chargeOut', new Date())
    }
    @computed
    get staffAndRoleRates() {
        const ratesByDate = {}
        this.staff
            .filter((s) => !s.deletedAt && !s.isArchived)
            .flatMap((s) => s.rates)
            .forEach((r) => {
                ratesByDate[r.date] = r
            })
        this.rates.forEach((r) => {
            ratesByDate[r.date] = r
        })
        return Object.values(ratesByDate).sort((a, b) => a.date - b.date)
    }
    @computed
    get staff() {
        return StaffCollection.staffByRoleId[this.id] || []
    }

    avgRateType(rateType) {
        return this['avg' + pascalCase(rateType) + 'Rate']
    }

    getRateInDateRange(rateType, dateRange) {
        if (
            this.staff.filter((s) => !s.inheritRateType(rateType)).length &&
            this.avgRateType(rateType)
        ) {
            return _.mean(
                this.staff
                    .filter(
                        (s) =>
                            !s.inheritRateType(rateType) &&
                            !s.deletedAt &&
                            !s.isArchived
                    )
                    .map((s) => s.getRateInDateRange(rateType, dateRange))
            )
        }
        return getRateInDateRange(this.rates, rateType, dateRange)
    }

    getRateAtDate(rateType, date) {
        if (
            this.staff.filter(
                (s) =>
                    !s.inheritRateType(rateType) &&
                    !s.deletedAt &&
                    !s.isArchived
            ).length &&
            this.avgRateType(rateType)
        ) {
            return _.mean(
                this.staff
                    .filter(
                        (s) =>
                            !s.inheritRateType(rateType) &&
                            !s.deletedAt &&
                            !s.isArchived
                    )
                    .map((s) => s.getRateAtDate(rateType, date))
                    .filter((v) => v)
            )
        }
        return getRateAtDate(this.rates, rateType, date)
    }

    getAvailabilityInDateRange(dateRange) {
        return _.sum(
            this.staff.map((s) => s.getAvailabilityInDateRange(dateRange))
        )
    }

    getAvailabilityAtDate(date) {
        return _.sum(this.staff.map((s) => s.getAvailabilityAtDate(date)))
    }
}

export default RoleModel
