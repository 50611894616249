import { observable, computed, action, makeObservable } from 'mobx'
import Collection from '../Collections/Collection'
import BudgetAggregateModel from './BudgetAggregateModel'
import Aggregate from './Aggregate'

class BudgetAggregate extends Aggregate {
    constructor() {
        super({ collectionClass: BudgetAggregateCollection })
        makeObservable(this)
    }
}

class BudgetAggregateCollection extends Collection {
    constructor() {
        super({
            collection: 'budgetedHours',
            modelClass: BudgetAggregateModel,
        })
        this.addLookup('budgetsByPhaseId', 'manyByKey', {
            key: (t) => t.phaseId,
            filter: (t) => !t.deletedAt,
        })
        this.addLookup('budgetsByStaffId', 'manyByKey', {
            key: (t) => t.staffId,
            filter: (t) => !t.deletedAt,
        })
        this.addLookup('budgetsByRoleId', 'manyByKey', {
            key: (t) => t.roleId,
            filter: (t) => !t.deletedAt,
        })
        this.addLookup('budgetsByProjectId', 'manyByKey', {
            key: (t) => t.projectId,
            filter: (t) => !t.deletedAt,
        })
        makeObservable(this)
    }
    get budgetsById() {
        return this.modelsById
    }
    get budgets() {
        return this.models
    }
    get budgetsById() {
        return this.modelsById
    }
    @action.bound
    addBudget(data) {
        this.add(data)
    }
    @action.bound
    addBudgets(data) {
        this.addMany(data)
    }
}

export default new BudgetAggregate()
export const BudgetAggregateCollectionClass = BudgetAggregateCollection
