export default {
    xero: {
        label: 'Xero',
        getUrl: (id) => `https://go.xero.com/app/invoicing/edit/${id}`,
        style: {
            color: '#fff',
            backgroundColor: '#11b5ea',
        },
    },
    quickbooks: {
        label: 'Quickbooks',
        getUrl: (id) => `https://app.qbo.intuit.com/app/invoice?txnId=${id}`,
        style: {
            color: '#fff',
            backgroundColor: '#2ba01d',
        },
    },
    'myob-essentials': {
        label: 'MYOB',
        getUrl: (id) =>
            `https://essentials.myob.com.au/LA/app.htm#businesses/390816/invoices/${id}`,
        style: {
            color: '#fff',
            backgroundColor: '#6100a5',
        },
    },
    'myob-accountright': {
        label: 'MYOB',
        getUrl: (id) =>
            `https://essentials.myob.com.au/LA/app.htm#businesses/390816/invoices/${id}`,
        style: {
            color: '#fff',
            backgroundColor: '#6100a5',
        },
    },
}
