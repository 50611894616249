import { observer } from 'mobx-react'
import React, { useEffect, useState } from 'react'
import Table from '../../Components/Table'
import StaffCollection from '../../State/Collections/StaffCollection'
import StaffRateCollection from '../../State/Collections/StaffRateCollection'
import Checkbox from '../../Components/Widgets/Checkbox'
import CellComponent from '../../Components/CellComponent'
import {
    canEditStaff,
    canEditStaffChargeOutRate,
    canEditStaffCostRate,
    canEditStaffOvertimeRate,
    canEditStaffPayRate,
    canViewStaffChargeOutRate,
    canViewStaffCostRate,
    canViewStaffOvertimeRate,
    canViewStaffPayRate,
} from '../../State/Permissions/HasPermissions'
import SessionStore from '../../State/SessionStore'

const rateColumns = (staff) => [
    {
        id: 'date',
        label: 'Starting From',
        width: 20,
        type: 'date',
        editable: (r) =>
            r.modelType === 'staffRate' && canEditStaff(SessionStore.user),
        value: (r) => r.date,
        onChange: (r) => (v) => r.update({ date: v }),
    },
    {
        label: 'Pay Rate',
        width: 20,
        type: 'currency',
        permissions: (r) => canViewStaffPayRate(SessionStore.user),
        editable: (r) =>
            r.modelType === 'staffRate' &&
            !staff.inheritPayRate &&
            canEditStaffPayRate(SessionStore.user),
        value: (p) =>
            ((p.modelType === 'staffRate' && !staff.inheritPayRate) ||
                (p.modelType === 'roleRate' && staff.inheritPayRate)) &&
            p.payRate,
        component: (props) => {
            const r = props.stores.row.rowObject
            if (r.modelType === 'staffRate' && staff.inheritPayRate) {
                return <div></div>
            }
            if (r.modelType === 'roleRate' && !staff.inheritPayRate) {
                return <div></div>
            }
            if (isFinite(props.value)) {
                return CellComponent['currency'](props)
            }
        },
        onChange: (r) => (v) => r.update({ payRate: v }),
    },
    {
        label: 'Overtime Rate',
        width: 20,
        type: 'currency',
        permissions: (r) => canViewStaffOvertimeRate(SessionStore.user),
        editable: (r) =>
            r.modelType === 'staffRate' &&
            !staff.inheritOvertimeRate &&
            canEditStaffOvertimeRate(SessionStore.user),
        value: (p) =>
            ((p.modelType === 'staffRate' && !staff.inheritOvertimeRate) ||
                (p.modelType === 'roleRate' && staff.inheritOvertimeRate)) &&
            p.overtimeRate,
        component: (props) => {
            const r = props.stores.row.rowObject
            if (r.modelType === 'staffRate' && staff.inheritOvertimeRate) {
                return <div></div>
            }
            if (r.modelType === 'roleRate' && !staff.inheritOvertimeRate) {
                return <div></div>
            }
            if (isFinite(props.value)) {
                return CellComponent['currency'](props)
            }
        },
        onChange: (r) => (v) => r.update({ overtimeRate: v }),
    },
    {
        label: 'Cost Rate',
        width: 20,
        type: 'currency',
        permissions: (r) => canViewStaffCostRate(SessionStore.user),
        editable: (r) =>
            r.modelType === 'staffRate' &&
            !staff.inheritCostRate &&
            canEditStaffCostRate(SessionStore.user),
        value: (p) =>
            ((p.modelType === 'staffRate' && !staff.inheritCostRate) ||
                (p.modelType === 'roleRate' && staff.inheritCostRate)) &&
            p.costRate,
        component: (props) => {
            const r = props.stores.row.rowObject
            if (r.modelType === 'staffRate' && staff.inheritCostRate) {
                return <div></div>
            }
            if (r.modelType === 'roleRate' && !staff.inheritCostRate) {
                return <div></div>
            }
            if (isFinite(props.value)) {
                return CellComponent['currency'](props)
            }
        },
        onChange: (r) => (v) => r.update({ costRate: v }),
    },
    {
        label: 'Charge Out Rate',
        width: 20,
        type: 'currency',
        permissions: (r) => canViewStaffChargeOutRate(SessionStore.user),
        editable: (r) =>
            r.modelType === 'staffRate' &&
            !staff.inheritChargeOutRate &&
            canEditStaffChargeOutRate(SessionStore.user),
        value: (p) =>
            ((p.modelType === 'staffRate' && !staff.inheritChargeOutRate) ||
                (p.modelType === 'roleRate' && staff.inheritChargeOutRate)) &&
            p.chargeOutRate,
        component: (props) => {
            const r = props.stores.row.rowObject
            if (r.modelType === 'staffRate' && staff.inheritChargeOutRate) {
                return <div></div>
            }
            if (r.modelType === 'roleRate' && !staff.inheritChargeOutRate) {
                return <div></div>
            }
            if (isFinite(props.value)) {
                return CellComponent['currency'](props)
            }
        },
        onChange: (r) => (v) => r.update({ chargeOutRate: v }),
    },
    {
        label: 'Weekly Availability',
        width: 20,
        type: 'durationMinutes',
        editable: (r) =>
            r.modelType === 'staffRate' && canEditStaff(SessionStore.user),
        value: (p) =>
            (p.modelType === 'staffRate' && p.weeklyAvailability) || 0,
        component: (props) => {
            const r = props.stores.row.rowObject
            if (r.modelType === 'roleRate') {
                return <div></div>
            }
            if (isFinite(props.value)) {
                return CellComponent['durationMinutes'](props)
            }
        },
        onChange: (r) => (v) => r.update({ weeklyAvailability: v }),
    },
    {
        label: '',
        width: 4,
        type: 'button',
        permissions: (r) => canEditStaff(SessionStore.user),
        editable: (r) =>
            r.modelType === 'staffRate' && canEditStaff(SessionStore.user),
        value: (p) =>
            p.modelType === 'staffRate' && (
                <i className="fa fa-times" style={{ marginRight: 0 }} />
            ),
        onClick: (r) => () => {
            r.update({ deletedAt: new Date() })
        },
    },
]

const inheritRateColumns = [
    {
        width: 20,
        type: 'text',
        value: () => '',
        style: () => ({
            textAlign: 'right',
            justifyContent: 'flex-end',
        }),
    },
    {
        label: 'Pay Rate',
        width: 20,
        type: 'boolean',
        value: (r) => r.inheritPayRate,
        onChange: (r) => (v) => r.update({ inheritPayRate: v }),
        permissions: (r) => canViewStaffPayRate(SessionStore.user),
        editable: (r) =>
            r.roleId != null &&
            !r.role?.avgPayRate &&
            canEditStaffPayRate(SessionStore.user),
        component: (props) => {
            return (
                <div style={{ display: 'flex' }}>
                    <div
                        style={{
                            opacity: props.editable ? 1 : 0.75,
                            flex: '1 1 auto',
                        }}
                    >
                        Inherit From Role
                    </div>
                    <Checkbox
                        disabled={!props.editable}
                        {...props}
                        style={{
                            ...props.style,
                            flex: '0 0 auto',
                            width: '1em',
                            marginLeft: '0.5em',
                        }}
                    />
                </div>
            )
        },
        style: () => ({
            textAlign: 'right',
            justifyContent: 'flex-end',
        }),
    },
    {
        label: 'Overtime Rate',
        width: 20,
        type: 'boolean',
        value: (r) => r.inheritOvertimeRate,
        onChange: (r) => (v) => r.update({ inheritOvertimeRate: v }),
        permissions: (r) => canViewStaffOvertimeRate(SessionStore.user),
        editable: (r) =>
            r.roleId != null &&
            !r.role?.avgOvertimeRate &&
            canEditStaffOvertimeRate(SessionStore.user),
        component: (props) => {
            return (
                <div style={{ display: 'flex' }}>
                    <div
                        style={{
                            opacity: props.editable ? 1 : 0.75,
                            flex: '1 1 auto',
                        }}
                    >
                        Inherit From Role
                    </div>
                    <Checkbox
                        disabled={!props.editable}
                        {...props}
                        style={{
                            ...props.style,
                            flex: '0 0 auto',
                            width: '1em',
                            marginLeft: '0.5em',
                        }}
                    />
                </div>
            )
        },
        style: () => ({
            textAlign: 'right',
            justifyContent: 'flex-end',
        }),
    },
    {
        label: 'Cost Rate',
        width: 20,
        type: 'boolean',
        value: (r) => r.inheritCostRate,
        onChange: (r) => (v) => r.update({ inheritCostRate: v }),
        permissions: (r) => canViewStaffCostRate(SessionStore.user),
        editable: (r) =>
            r.roleId != null &&
            !r.role?.avgCostRate &&
            canEditStaffCostRate(SessionStore.user),
        component: (props) => {
            return (
                <div style={{ display: 'flex' }}>
                    <div
                        style={{
                            opacity: props.editable ? 1 : 0.75,
                            flex: '1 1 auto',
                        }}
                    >
                        Inherit From Role
                    </div>
                    <Checkbox
                        disabled={!props.editable}
                        {...props}
                        style={{
                            ...props.style,
                            flex: '0 0 auto',
                            width: '1em',
                            marginLeft: '0.5em',
                        }}
                    />
                </div>
            )
        },
        style: () => ({
            textAlign: 'right',
            justifyContent: 'flex-end',
        }),
    },
    {
        label: 'Charge Out Rate',
        width: 20,
        type: 'boolean',
        value: (r) => r.inheritChargeOutRate,
        onChange: (r) => (v) => r.update({ inheritChargeOutRate: v }),
        permissions: (r) => canViewStaffChargeOutRate(SessionStore.user),
        editable: (r) =>
            r.roleId != null &&
            !r.role?.avgChargeOutRate &&
            canEditStaffChargeOutRate(SessionStore.user),
        component: (props) => {
            return (
                <div style={{ display: 'flex' }}>
                    <div
                        style={{
                            opacity: props.editable ? 1 : 0.75,
                            flex: '1 1 auto',
                        }}
                    >
                        Inherit From Role
                    </div>
                    <Checkbox
                        disabled={!props.editable}
                        {...props}
                        style={{
                            ...props.style,
                            flex: '0 0 auto',
                            width: '1em',
                            marginLeft: '0.5em',
                        }}
                    />
                </div>
            )
        },
        style: () => ({
            textAlign: 'right',
            justifyContent: 'flex-end',
        }),
    },
    {
        label: 'Weekly Availability',
        width: 20,
        type: 'text',
        value: () => '',
        style: () => ({
            textAlign: 'right',
            justifyContent: 'flex-end',
        }),
    },
    {
        permissions: (r) => canEditStaff(SessionStore.user),
        width: 4,
        type: 'text',
        value: () => '',
    },
]

export default observer(({ id }) => {
    const staff = StaffCollection.staffsById[id]
    if (!staff) return <div>Staff Member not found.</div>
    const includeRoleRates =
        staff.inheritPayRate ||
        staff.inheritOvertimeRate ||
        staff.inheritCostRate ||
        staff.inheritChargeOutRate
    const memoizedColumns = React.useMemo(() => {
        return rateColumns(staff).filter(
            (c) => !c.permissions || c.permissions()
        )
    }, [staff])
    const [rows, setRows] = useState(
        (includeRoleRates ? staff.staffAndRoleRates : staff.rates).sort(
            (a, b) => a?.date?.getTime() - b?.date?.getTime()
        )
    )
    useEffect(() => {
        setRows(
            (includeRoleRates ? staff.staffAndRoleRates : staff.rates).sort(
                (a, b) => a?.date?.getTime() - b?.date?.getTime()
            )
        )
    }, [includeRoleRates, staff.id])
    return (
        <div>
            <Table
                columns={memoizedColumns}
                rows={rows.filter((r) => !r.deletedAt)}
                topRow={
                    <Table
                        columns={inheritRateColumns.filter(
                            (c) => !c.permissions || c.permissions()
                        )}
                        rows={[staff]}
                        showHeader={false}
                    />
                }
            />
            {canEditStaff(SessionStore.user) ? (
                <button
                    className="btn btn-default plus-btn border-[#ccc]"
                    style={{ marginTop: '2em' }}
                    onClick={() => {
                        const sr = StaffRateCollection.add(
                            { staffId: staff.id, date: new Date() },
                            { trackUpdates: true }
                        )
                        setRows([...rows, sr])
                    }}
                >
                    + Add Rate
                </button>
            ) : null}
        </div>
    )
})
