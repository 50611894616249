import * as React from 'react'
import * as TabsPrimitive from '@radix-ui/react-tabs'

import { cn } from '@2/lib/utils'

const Tabs = TabsPrimitive.Root

type TabsListVariant = 'line' | 'solid'

const TabsListVariantContext = React.createContext<TabsListVariant>('line')

interface TabsListProps
    extends React.ComponentPropsWithoutRef<typeof TabsPrimitive.List> {
    variant?: TabsListVariant
}

const variantStyles: Record<TabsListVariant, string> = {
    line: 'flex items-center justify-start border-b border-gray-200 dark:border-gray-800',
    solid: 'inline-flex h-10 items-center justify-center rounded-md bg-muted p-1 text-muted-foreground',
}

const TabsList = React.forwardRef<
    React.ElementRef<typeof TabsPrimitive.List>,
    TabsListProps
>(({ className, variant = 'line', children, ...props }, ref) => (
    <TabsPrimitive.List
        ref={ref}
        className={cn(variantStyles[variant], className)}
        {...props}
    >
        <TabsListVariantContext.Provider value={variant}>
            {children}
        </TabsListVariantContext.Provider>
    </TabsPrimitive.List>
))
TabsList.displayName = TabsPrimitive.List.displayName

const getVariantStyles = (variant: TabsListVariant) => {
    switch (variant) {
        case 'line':
            return cn(
                'inline-flex items-center justify-center whitespace-nowrap px-3 py-1.5 text-sm font-medium',
                'text-gray-500 dark:text-gray-400',
                'hover:text-gray-700 hover:dark:text-gray-200',
                'border-b-2 border-transparent',
                'data-[state=active]:border-gray-900 data-[state=active]:text-gray-900',
                'data-[state=active]:dark:border-gray-50 data-[state=active]:dark:text-gray-50',
                'disabled:pointer-events-none disabled:opacity-50',
                'print:text-[0.6rem]'
            )
        case 'solid':
            return cn(
                'inline-flex items-center justify-center whitespace-nowrap rounded-sm px-3 py-1.5 text-sm font-medium ring-offset-background transition-all',
                'focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2',
                'disabled:pointer-events-none disabled:opacity-50',
                'data-[state=active]:bg-background data-[state=active]:text-foreground data-[state=active]:shadow-sm'
            )
    }
}

const TabsTrigger = React.forwardRef<
    React.ElementRef<typeof TabsPrimitive.Trigger>,
    React.ComponentPropsWithoutRef<typeof TabsPrimitive.Trigger>
>(({ className, ...props }, ref) => {
    const variant = React.useContext(TabsListVariantContext)
    return (
        <TabsPrimitive.Trigger
            ref={ref}
            className={cn(getVariantStyles(variant), className)}
            {...props}
        />
    )
})
TabsTrigger.displayName = TabsPrimitive.Trigger.displayName

const TabsContent = React.forwardRef<
    React.ElementRef<typeof TabsPrimitive.Content>,
    React.ComponentPropsWithoutRef<typeof TabsPrimitive.Content>
>(({ className, ...props }, ref) => (
    <TabsPrimitive.Content
        ref={ref}
        className={cn(
            'mt-2 ring-offset-background focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2',
            className
        )}
        {...props}
    />
))
TabsContent.displayName = TabsPrimitive.Content.displayName

export { Tabs, TabsList, TabsTrigger, TabsContent }
