import { observable, computed, action, makeObservable } from 'mobx'
import Collection from './Collection'
import OrganisationSubscriptionModel from '../Models/OrganisationSubscriptionModel'

class OrganisationSubscriptionCollection extends Collection {
    constructor() {
        super({
            collection: 'organisationSubscriptions',
            modelClass: OrganisationSubscriptionModel,
        })
        makeObservable(this)
    }
    get organisationSubscriptionsById() {
        return this.modelsById
    }
    get organisationSubscriptions() {
        return this.models
    }
    @action.bound
    addOrganisationSubscription(data) {
        this.add(data)
    }
    @action.bound
    addOrganisationSubscriptions(data) {
        this.addMany(data)
    }
}

export default new OrganisationSubscriptionCollection()
export const OrganisationSubscriptionCollectionClass =
    OrganisationSubscriptionCollection
