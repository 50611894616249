import { observable, computed, action, makeObservable } from 'mobx'
import Collection from './Collection'
import MonthlyExpenseCellModel from '../Models/MonthlyExpenseCellModel'

class MonthlyExpenseCellCollection extends Collection {
    constructor() {
        super({
            collection: 'monthlyExpenseCells',
            modelClass: MonthlyExpenseCellModel,
        })
        this.addLookup('cellsByStatus', 'manyByKey', {
            key: (t) => t.status,
            filter: (t) => !t.deletedAt,
        })
        this.addLookup('cellsByProjectId', 'manyByKey', {
            key: (t) => t.projectId,
            filter: (t) => !t.deletedAt,
        })
        this.addLookup('cellsByPhaseId', 'manyByKey', {
            key: (t) => t.phaseId,
            filter: (t) => !t.deletedAt,
        })
        this.addLookup('cellsByExpenseName', 'manyByKey', {
            key: (t) => t.expenseName,
            filter: (t) => !t.deletedAt,
        })
        this.addLookup('cellsByMonth', 'manyByKey', {
            key: (t) => t.month,
            filter: (t) => !t.deletedAt,
        })
        this.addLookup('cellsByType', 'manyByKey', {
            key: (t) => t.type,
            filter: (t) => !t.deletedAt,
        })
        makeObservable(this)
    }
    get cellsById() {
        return this.modelsById
    }
    get cells() {
        return this.models
    }
    @action.bound
    addCell(data, options) {
        return this.add(data, options)
    }
    @action.bound
    addCells(data, options) {
        return this.addMany(data, options)
    }
}

export default new MonthlyExpenseCellCollection()
export const MonthlyExpenseCellCollectionClass = MonthlyExpenseCellCollection
