import { data } from 'jquery'
import { observer } from 'mobx-react'
import React, { Suspense, useCallback, useEffect } from 'react'
import PageHeader from '../Layout/PageHeader'
import ProjectCollection from '../../State/Collections/ProjectCollection'
import LayoutStore from '../../State/LayoutStore'
import CopyProjectModal from './CopyProjectModal'
import DelayProjectModal from './DelayProjectModal'
import DeleteProjectModal from './DeleteProjectModal'
import {
    canCreateProjects,
    canDeleteProject,
    canEditProjectDates,
    canEditProjectFees,
} from '../../State/Permissions/HasPermissions'
import SessionStore from '../../State/SessionStore'
import ProjectFeeModal from './ProjectFeeModal'
import HeaderButton from '../../Components/ui/HeaderButton'

export default observer(({ extraContent, id, tabs, selectedTab }) => {
    const project = ProjectCollection.projectsById[id]
    if (!project) return null
    return (
        <PageHeader
            heading={
                <div>
                    <div>{project?.title || 'New project'}</div>
                </div>
            }
            rightButtons={
                <div className="flex items-center gap-2">
                    {canEditProjectFees(SessionStore.user, project) ? (
                        <HeaderButton
                            label="% Fee Calculator"
                            onClick={() =>
                                LayoutStore.openModal(
                                    <ProjectFeeModal
                                        project={project}
                                        modalId="projectFeeCalc"
                                    />
                                )
                            }
                        />
                    ) : null}
                    {canEditProjectDates(SessionStore.user, project) ? (
                        <HeaderButton
                            label="Delay"
                            onClick={() =>
                                LayoutStore.openModal(
                                    <DelayProjectModal
                                        project={project}
                                        modalId="delayProject"
                                    />
                                )
                            }
                        />
                    ) : null}
                    {canCreateProjects(SessionStore.user, project) ? (
                        <HeaderButton
                            label="Copy"
                            onClick={() =>
                                LayoutStore.openModal(
                                    <CopyProjectModal
                                        project={project}
                                        modalId="copyProject"
                                    />
                                )
                            }
                        />
                    ) : null}
                    <HeaderButton
                        label="Print"
                        icon={<i className="fa fa-print" />}
                        onClick={() => window.print()}
                    />
                    {canDeleteProject(SessionStore.user, project) ? (
                        <HeaderButton
                            label="Delete"
                            icon={<i className="fa fa-trash" />}
                            onClick={() =>
                                LayoutStore.openModal(
                                    <DeleteProjectModal
                                        project={project}
                                        modalId="deleteProject"
                                    />
                                )
                            }
                            variant="secondary"
                        />
                    ) : null}
                </div>
            }
            extraContent={extraContent}
            tabs={tabs}
            selectedTab={selectedTab}
        />
    )
})
