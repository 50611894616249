import React, { useState, useEffect } from 'react'
import { useOrganization } from '@clerk/clerk-react'
import { Button } from '@2/components/ui/button'
import { Input } from '@2/components/ui/input'
import {
    Card,
    CardHeader,
    CardTitle,
    CardDescription,
    CardContent,
} from '@2/components/ui/card'
import { Switch } from '@2/components/ui/switch'
import { Label } from '@2/components/ui/label'
import { Alert, AlertDescription } from '@2/components/ui/alert'
import { Badge } from '@2/components/ui/badge'
import { Mail, Plus, X, Check, AlertCircle, Loader2, Trash } from 'lucide-react'

const DomainCard = ({ domain, onRemove, processing }) => {
    const [verifyEmail, setVerifyEmail] = useState('')
    const [verifyCode, setVerifyCode] = useState('')
    const [isVerifying, setIsVerifying] = useState(false)
    const [verifyError, setVerifyError] = useState('')
    const [verificationStep, setVerificationStep] = useState('email')
    const [success, setSuccess] = useState('')
    const [isUpdatingEnrollment, setIsUpdatingEnrollment] = useState(false)

    const needsVerification =
        !domain.verification || domain.verification.status === 'unverified'

    const handleEnrollmentChange = async (checked) => {
        setIsUpdatingEnrollment(true)
        setVerifyError('')
        setSuccess('')

        try {
            // Use the correct Clerk API method
            await domain.updateEnrollmentMode({
                enrollment_mode: checked
                    ? 'automatic_invitation'
                    : 'manual_invitation',
            })
            setSuccess('Enrollment mode updated successfully.')
        } catch (err) {
            setVerifyError(err.message || 'Failed to update enrollment mode')
        } finally {
            setIsUpdatingEnrollment(false)
        }
    }

    const handleVerifyEmail = async (e) => {
        e.preventDefault()
        setIsVerifying(true)
        setVerifyError('')
        setSuccess('')

        try {
            await domain.prepareAffiliationVerification({
                affiliationEmailAddress: verifyEmail,
            })
            setVerificationStep('code')
            setSuccess('Verification code sent to your email.')
        } catch (err) {
            setVerifyError(err.message || 'Failed to send verification email')
        } finally {
            setIsVerifying(false)
        }
    }

    const handleVerifyCode = async (e) => {
        e.preventDefault()
        setIsVerifying(true)
        setVerifyError('')
        setSuccess('')

        try {
            await domain.attemptAffiliationVerification({
                code: verifyCode,
            })
            window.location.reload()
        } catch (err) {
            setVerifyError(err.message || 'Invalid verification code')
        } finally {
            setIsVerifying(false)
        }
    }

    const renderVerificationForm = () => {
        if (verificationStep === 'email') {
            return (
                <form onSubmit={handleVerifyEmail} className="space-y-4">
                    <div className="flex space-x-2">
                        <div className="flex-grow">
                            <Input
                                type="email"
                                placeholder={`admin@${domain.name}`}
                                value={verifyEmail}
                                onChange={(e) => setVerifyEmail(e.target.value)}
                                required
                                disabled={isVerifying}
                            />
                        </div>
                        <Button
                            type="submit"
                            disabled={!verifyEmail || isVerifying}
                        >
                            {isVerifying && (
                                <Loader2 className="mr-2 h-4 w-4 animate-spin" />
                            )}
                            Send Code
                        </Button>
                    </div>
                </form>
            )
        }

        return (
            <form onSubmit={handleVerifyCode} className="space-y-4">
                <Alert>
                    <Mail className="h-4 w-4" />
                    <AlertDescription>
                        Enter the verification code sent to {verifyEmail}
                    </AlertDescription>
                </Alert>
                <div className="flex space-x-2">
                    <div className="flex-grow">
                        <Input
                            type="text"
                            placeholder="Enter verification code"
                            value={verifyCode}
                            onChange={(e) => setVerifyCode(e.target.value)}
                            required
                            disabled={isVerifying}
                        />
                    </div>
                    <Button type="submit" disabled={!verifyCode || isVerifying}>
                        {isVerifying && (
                            <Loader2 className="mr-2 h-4 w-4 animate-spin" />
                        )}
                        Verify
                    </Button>
                    <Button
                        type="button"
                        variant="ghost"
                        onClick={() => {
                            setVerificationStep('email')
                            setVerifyCode('')
                            setVerifyError('')
                        }}
                    >
                        Back
                    </Button>
                </div>
            </form>
        )
    }

    return (
        <div className="flex flex-col p-4 border rounded-lg space-y-4">
            <div className="flex items-center justify-between">
                <div className="space-y-2 w-full">
                    <div className="flex items-center justify-between">
                        <div className="flex items-center space-x-2">
                            <p className="font-medium">{domain.name}</p>
                            {domain.verification ? (
                                <Badge
                                    className={
                                        domain.verification.status ===
                                        'verified'
                                            ? 'bg-green-200'
                                            : 'bg-yellow-200'
                                    }
                                >
                                    {domain.verification.status ===
                                    'verified' ? (
                                        <Check className="w-3 h-3 mr-1" />
                                    ) : (
                                        <AlertCircle className="w-3 h-3 mr-1" />
                                    )}
                                    {domain.verification.status === 'verified'
                                        ? 'Verified'
                                        : 'Pending'}
                                </Badge>
                            ) : (
                                <Badge variant="secondary">
                                    <AlertCircle className="w-3 h-3 mr-1" />
                                    Unverified
                                </Badge>
                            )}
                        </div>
                        <Button
                            variant="ghost"
                            size="icon"
                            onClick={() => onRemove(domain)}
                            disabled={processing || isUpdatingEnrollment}
                        >
                            <Trash className="w-4 h-4" />
                        </Button>
                    </div>
                    <div className="flex items-center space-x-2 pt-2">
                        <Switch
                            id={`enrollment-mode-${domain.id}`}
                            checked={
                                domain.enrollmentMode === 'automatic_invitation'
                            }
                            onCheckedChange={handleEnrollmentChange}
                            disabled={
                                isUpdatingEnrollment ||
                                isVerifying ||
                                !domain.verification?.status === 'verified'
                            }
                        />
                        <Label
                            htmlFor={`enrollment-mode-${domain.id}`}
                            className="text-sm text-muted-foreground"
                        >
                            {isUpdatingEnrollment ? (
                                <div className="flex items-center space-x-2">
                                    <Loader2 className="h-3 w-3 animate-spin" />
                                    <span>Updating...</span>
                                </div>
                            ) : (
                                'Automatic enrollment'
                            )}
                        </Label>
                    </div>
                </div>
            </div>

            {needsVerification && (
                <div className="space-y-4">
                    {verifyError && (
                        <Alert variant="destructive">
                            <AlertCircle className="h-4 w-4" />
                            <AlertDescription>{verifyError}</AlertDescription>
                        </Alert>
                    )}

                    {success && (
                        <Alert>
                            <Check className="h-4 w-4" />
                            <AlertDescription>{success}</AlertDescription>
                        </Alert>
                    )}

                    {renderVerificationForm()}
                </div>
            )}
        </div>
    )
}
const DomainVerification = () => {
    const { organization } = useOrganization()
    const [domains, setDomains] = useState([])
    const [loading, setLoading] = useState(true)
    const [showAddForm, setShowAddForm] = useState(false)
    const [newDomain, setNewDomain] = useState('')
    const [enrollmentMode, setEnrollmentMode] = useState('manual_invitation')
    const [verificationStep, setVerificationStep] = useState('domain')
    const [verificationEmail, setVerificationEmail] = useState('')
    const [verificationCode, setVerificationCode] = useState('')
    const [currentDomain, setCurrentDomain] = useState(null)
    const [processing, setProcessing] = useState(false)
    const [error, setError] = useState('')
    const [success, setSuccess] = useState('')

    useEffect(() => {
        if (!organization?.id) return
        loadDomains()
    }, [organization?.id]) // Only depends on organization ID

    const loadDomains = async () => {
        if (!organization?.id) return
        try {
            setLoading(true)
            const domainList = await organization.getDomains()
            setDomains(domainList.data)
            setError('')
        } catch (err) {
            setError('Failed to load domains. Please try again.')
        } finally {
            setLoading(false)
        }
    }

    const handleAddDomain = async (e) => {
        e.preventDefault()
        setProcessing(true)
        setError('')
        setSuccess('')

        try {
            const domain = await organization.createDomain(newDomain)
            setCurrentDomain(domain)
            setVerificationStep('email')
            setNewDomain('')
            setEnrollmentMode('manual_invitation')
            await loadDomains()
            setSuccess(
                'Domain added successfully. Please complete verification.'
            )
        } catch (err) {
            setError(err.message || 'Failed to add domain. Please try again.')
        } finally {
            setProcessing(false)
        }
    }

    const handleVerifyEmail = async (e) => {
        e.preventDefault()
        setProcessing(true)
        setError('')
        setSuccess('')

        try {
            await currentDomain.prepareAffiliationVerification({
                affiliationEmailAddress: verificationEmail,
            })

            setVerificationStep('code')
            setSuccess('Verification email sent. Please check your inbox.')
        } catch (err) {
            setError(
                err.message ||
                    'Failed to send verification email. Please try again.'
            )
        } finally {
            setProcessing(false)
        }
    }

    const handleVerifyCode = async (e) => {
        e.preventDefault()
        setProcessing(true)
        setError('')
        setSuccess('')

        try {
            await currentDomain.attemptAffiliationVerification({
                code: verificationCode,
            })

            await loadDomains()
            setShowAddForm(false)
            setVerificationStep('domain')
            setVerificationEmail('')
            setVerificationCode('')
            setCurrentDomain(null)
            setSuccess('Domain verified successfully.')
        } catch (err) {
            setError(
                err.message || 'Invalid verification code. Please try again.'
            )
        } finally {
            setProcessing(false)
        }
    }

    const handleRemoveDomain = async (domain) => {
        setProcessing(true)
        setError('')
        setSuccess('')

        try {
            await domain.delete()
            await loadDomains()
            setSuccess('Domain removed successfully.')
        } catch (err) {
            setError(
                err.message || 'Failed to remove domain. Please try again.'
            )
        } finally {
            setProcessing(false)
        }
    }

    const renderDomainList = () => (
        <div className="space-y-4">
            {domains.map((domain) => (
                <DomainCard
                    key={domain.id}
                    domain={domain}
                    onRemove={handleRemoveDomain}
                    processing={processing}
                />
            ))}
        </div>
    )

    const renderAddForm = () => (
        <Card>
            <CardHeader>
                <CardTitle>Add domain</CardTitle>
                <CardDescription>
                    Add the domain to verify. Users with email addresses at this
                    domain can join the organization automatically or request to
                    join.
                </CardDescription>
            </CardHeader>
            <CardContent>
                {error && (
                    <Alert variant="destructive" className="mb-4">
                        <AlertCircle className="h-4 w-4" />
                        <AlertDescription>{error}</AlertDescription>
                    </Alert>
                )}

                {success && (
                    <Alert className="mb-4">
                        <Check className="h-4 w-4" />
                        <AlertDescription>{success}</AlertDescription>
                    </Alert>
                )}

                {verificationStep === 'domain' && (
                    <form onSubmit={handleAddDomain} className="space-y-6">
                        <div className="space-y-2">
                            <Label htmlFor="domain">Domain</Label>
                            <Input
                                id="domain"
                                placeholder="example.com"
                                value={newDomain}
                                onChange={(e) => setNewDomain(e.target.value)}
                                required
                                disabled={processing}
                            />
                        </div>
                        <div className="space-y-4">
                            <Label>Enrollment Mode</Label>
                            <div className="flex items-center space-x-2">
                                <Switch
                                    id="enrollment-mode"
                                    checked={
                                        enrollmentMode ===
                                        'automatic_invitation'
                                    }
                                    onCheckedChange={(checked) =>
                                        setEnrollmentMode(
                                            checked
                                                ? 'automatic_invitation'
                                                : 'manual_invitation'
                                        )
                                    }
                                    disabled={processing}
                                />
                                <Label htmlFor="enrollment-mode">
                                    Enable automatic enrollment
                                </Label>
                            </div>
                        </div>
                        <div className="flex space-x-2">
                            <Button
                                type="submit"
                                disabled={!newDomain || processing}
                            >
                                {processing && (
                                    <Loader2 className="mr-2 h-4 w-4 animate-spin" />
                                )}
                                Save
                            </Button>
                            <Button
                                type="button"
                                variant="ghost"
                                onClick={() => {
                                    setShowAddForm(false)
                                    setError('')
                                    setSuccess('')
                                }}
                                disabled={processing}
                            >
                                Cancel
                            </Button>
                        </div>
                    </form>
                )}

                {verificationStep === 'email' && (
                    <form onSubmit={handleVerifyEmail} className="space-y-6">
                        <Alert>
                            <Mail className="w-4 h-4" />
                            <AlertDescription>
                                We couldn't verify the domain automatically.
                                Please provide an email address at{' '}
                                {currentDomain?.name} to verify ownership.
                            </AlertDescription>
                        </Alert>
                        <div className="space-y-2">
                            <Label htmlFor="email">Email address</Label>
                            <Input
                                id="email"
                                type="email"
                                placeholder={`admin@${currentDomain?.name}`}
                                value={verificationEmail}
                                onChange={(e) =>
                                    setVerificationEmail(e.target.value)
                                }
                                required
                                disabled={processing}
                            />
                        </div>
                        <div className="flex space-x-2">
                            <Button
                                type="submit"
                                disabled={!verificationEmail || processing}
                            >
                                {processing && (
                                    <Loader2 className="mr-2 h-4 w-4 animate-spin" />
                                )}
                                Send verification
                            </Button>
                            <Button
                                type="button"
                                variant="ghost"
                                onClick={() => {
                                    setShowAddForm(false)
                                    setError('')
                                    setSuccess('')
                                }}
                                disabled={processing}
                            >
                                Cancel
                            </Button>
                        </div>
                    </form>
                )}

                {verificationStep === 'code' && (
                    <form onSubmit={handleVerifyCode} className="space-y-6">
                        <Alert>
                            <Mail className="w-4 h-4" />
                            <AlertDescription>
                                Please enter the verification code sent to{' '}
                                {verificationEmail}
                            </AlertDescription>
                        </Alert>
                        <div className="space-y-2">
                            <Label htmlFor="code">Verification code</Label>
                            <Input
                                id="code"
                                placeholder="Enter code"
                                value={verificationCode}
                                onChange={(e) =>
                                    setVerificationCode(e.target.value)
                                }
                                required
                                disabled={processing}
                            />
                        </div>
                        <div className="flex space-x-2">
                            <Button
                                type="submit"
                                disabled={!verificationCode || processing}
                            >
                                {processing && (
                                    <Loader2 className="mr-2 h-4 w-4 animate-spin" />
                                )}
                                Verify
                            </Button>
                            <Button
                                type="button"
                                variant="ghost"
                                onClick={() => {
                                    setShowAddForm(false)
                                    setError('')
                                    setSuccess('')
                                }}
                                disabled={processing}
                            >
                                Cancel
                            </Button>
                        </div>
                    </form>
                )}
            </CardContent>
        </Card>
    )

    if (loading) {
        return (
            <div className="flex items-center justify-center p-8">
                <Loader2 className="h-8 w-8 animate-spin" />
            </div>
        )
    }

    return (
        <div className="space-y-6">
            {domains.length > 0 && renderDomainList()}

            {!showAddForm ? (
                <Button
                    onClick={() => setShowAddForm(true)}
                    className="w-full"
                    variant="outline"
                    disabled={processing}
                >
                    <Plus className="w-4 h-4 mr-2" />
                    Add domain
                </Button>
            ) : (
                renderAddForm()
            )}
        </div>
    )
}

export default DomainVerification
