import { observable, computed, action, makeObservable } from 'mobx'
import Collection from '../Collections/Collection'
import Aggregate from './Aggregate'
import ProjectExpenseAggregateModel from './ProjectExpenseAggregateModel'

class ProjectExpenseAggregate extends Aggregate {
    constructor() {
        super({ collectionClass: ProjectExpenseAggregateCollection })
        makeObservable(this)
    }
}

class ProjectExpenseAggregateCollection extends Collection {
    constructor() {
        super({
            collection: 'projectExpenses',
            modelClass: ProjectExpenseAggregateModel,
        })
        this.addLookup('expensesByProjectId', 'manyByKey', {
            key: (t) => t.projectId,
            filter: (t) => !t.deletedAt,
        })
        this.addLookup('expensesByPhaseId', 'manyByKey', {
            key: (t) => t.phaseId,
            filter: (t) => !t.deletedAt,
        })
        this.addLookup('expensesByStatus', 'manyByKey', {
            key: (t) => t.status,
            filter: (t) => !t.deletedAt,
        })
        makeObservable(this)
    }
    get projectExpensesById() {
        return this.modelsById
    }
    get projectExpenses() {
        return this.models
    }
    get projectExpensesById() {
        return this.modelsById
    }
    @action.bound
    addProjectExpense(data) {
        this.add(data)
    }
    @action.bound
    addProjectExpenses(data) {
        this.addMany(data)
    }
}

export default new ProjectExpenseAggregate()
export const ProjectExpenseAggregateCollectionClass =
    ProjectExpenseAggregateCollection
