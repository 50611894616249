import React, { useState } from 'react'
import { observer } from 'mobx-react'
import Modal from '../../Components/Modal'
import {
    PhaseSelector,
    ProjectExpenseSelector,
    ProjectSelector,
    TaskSelector,
} from '../../Components/Selector'
import RenderOnQueries from '../Layout/RenderOnQueries'
import SessionStore from '../../State/SessionStore'
import {
    canEditStaffExpenses,
    canLogTimeAgainstNoPhase,
    canTrackExpenseAgainstPhase,
    canTrackExpenseAgainstProject,
    canTrackExpenseAgainstGeneral,
} from '../../State/Permissions/HasPermissions'
import ProjectCollection from '../../State/Collections/ProjectCollection'
import { queryClient } from '../../App'
import ProjectExpenseItemCollection from '../../State/Collections/ProjectExpenseItemCollection'

export default observer(
    ({
        modalId,
        expenses,
        dates,
        staff,
        store,
        heading,
        onSave,
        placeholders,
        selectDefaults = true,
        defaults,
    }) => {
        const [project, setProject] = useState(
            defaults ? defaults.project : expenses?.[0]?.project
        )
        const [phase, setPhase] = useState(
            defaults ? defaults.phase : expenses?.[0]?.phase
        )
        const [expense, setExpense] = useState(
            defaults ? defaults.expense : expenses?.[0]?.expense
        )
        const getSelectedData = () => {
            return Object.fromEntries(
                [
                    project ? ['costCentreId', project.costCentreId] : null,
                    project ? ['projectId', project.id] : null,
                    phase ? ['phaseId', phase.id] : null,
                    expense ? ['expenseId', expense.id] : null,
                ].filter((v) => v)
            )
        }
        return (
            <Modal
                modalId={modalId}
                width={'45em'}
                heading={
                    heading || (expenses ? 'Edit Expenses' : 'New Expense')
                }
                onSave={() => {
                    if (onSave) {
                        return onSave(getSelectedData())
                    }
                    if (expenses) {
                        expenses.forEach((exp) => {
                            exp.update(getSelectedData())
                        })
                    } else {
                        const expenses = dates.map((d) =>
                            ProjectExpenseItemCollection.add(
                                {
                                    date: d,
                                    staffId: staff.id,
                                    cost: 0,
                                    ...getSelectedData(),
                                },
                                { trackUpdates: true }
                            )
                        )
                        store.addExtraExpenses(expenses)
                    }
                }}
                saveLabel={expenses ? 'Edit Expenses' : 'Add Expense'}
                canSave={() =>
                    project &&
                    (canLogTimeAgainstNoPhase() || phase) &&
                    (canTrackExpenseAgainstGeneral() || expense)
                }
            >
                <div
                    style={{
                        padding: '1em',
                        display: 'grid',
                        gridTemplateColumns: '1fr 4fr',
                        gridGap: '0.5em',
                    }}
                >
                    <div>Project: </div>
                    <div>
                        <ProjectSelector
                            selectedProject={project}
                            placeholderLabel={placeholders?.project}
                            projectOptions={ProjectCollection.projects.filter(
                                (p) =>
                                    canTrackExpenseAgainstProject(
                                        SessionStore.user,
                                        p
                                    )
                            )}
                            onChange={(pr) => {
                                queryClient.invalidateQueries([
                                    `phases-${pr.id}`,
                                ])
                                setProject(pr)
                                setPhase(null)
                                setExpense(null)
                            }}
                            variant="secondary"
                            className="max-w-max"
                        />
                    </div>

                    <div>Phase: </div>
                    <div>
                        <RenderOnQueries
                            queryIds={[
                                project
                                    ? {
                                          id: `phases-${project.id}`,
                                          collection: 'phases',
                                          fields: [
                                              'jobNumber',
                                              'name',
                                              'projectId',
                                              'status',
                                              'startDate',
                                              'endDate',
                                              'isRootPhase',
                                              'position',
                                          ],
                                          filters: [
                                              `projectId == "${project.id}"`,
                                          ],
                                      }
                                    : null,
                                project
                                    ? {
                                          collection: 'projectExpenses',
                                          fields: [
                                              'name',
                                              'projectId',
                                              'phaseId',
                                              'cost',
                                          ],
                                          filters: [
                                              `projectId == "${project?.id}"`,
                                          ],
                                      }
                                    : null,
                            ].filter((v) => v)}
                        >
                            <PhaseSelector
                                key={`phase-selector-${
                                    project?.phases?.length
                                        ? project?.id
                                        : 'null'
                                }`}
                                placeholderLabel={placeholders?.phase}
                                selectedPhase={phase}
                                onChange={(ph) => {
                                    setPhase(ph)
                                }}
                                phaseOptions={
                                    project?.phases?.filter((ph) =>
                                        canTrackExpenseAgainstPhase(
                                            SessionStore.user,
                                            ph
                                        )
                                    ) || []
                                }
                                variant="secondary"
                                className="max-w-max"
                            />
                        </RenderOnQueries>
                    </div>
                    <div>Expense: </div>
                    <div>
                        <RenderOnQueries
                            queryIds={[
                                project
                                    ? {
                                          collection: 'projectExpenses',
                                          fields: [
                                              'name',
                                              'projectId',
                                              'phaseId',
                                              'cost',
                                          ],
                                          filters: [
                                              `projectId == "${project?.id}"`,
                                          ],
                                      }
                                    : null,
                            ].filter((v) => v)}
                        >
                            <ProjectExpenseSelector
                                key={`expense-selector-${
                                    project?.phases?.length
                                        ? project?.id
                                        : 'null'
                                }-${
                                    phase?.expenses?.length ? phase?.id : 'null'
                                }`}
                                selectedProjectExpense={expense}
                                placeholderLabel={
                                    placeholders?.expense || `(General Expense)`
                                }
                                onChange={(expense) => {
                                    setExpense(expense)
                                }}
                                projectExpenseOptions={
                                    phase?.expenses || project?.expenses || []
                                }
                                nullable={true}
                                variant="secondary"
                                className="max-w-max"
                            />
                        </RenderOnQueries>
                    </div>
                </div>
            </Modal>
        )
    }
)
