import { createFileRoute, redirect } from '@tanstack/react-router'
import ResourceSchedulePageHeader from '../../Pages/ResourceSchedulePage/ResourceSchedulePageHeader'
import ResourceSchedulePage from '../../Pages/ResourceSchedulePage/ResourceSchedulePage'
import PageBody from '../../Pages/Layout/PageBody'
import PageSidebar from '../../Pages/Layout/PageSidebar'
import ResourceScheduleSidebar from '../../Pages/ResourceSchedulePage/ResourceScheduleSidebar'
import { qf } from '../../Queries/queryFormatter'
import SessionStore from '../../State/SessionStore'
import { canViewRoute } from '../../State/Permissions/HasPermissions'
import fetchData from '../../Queries/fetchData'
import LoadingSpinner from '../../Components/LoadingSpinner'
import ErrorComponent from '../../Components/ErrorComponent'
import PermissionDenied from '../../Pages/PermissionDenied'

export const Route = createFileRoute('/_private/resource-schedule')({
    beforeLoad: ({ params }) => {
        if (!canViewRoute(SessionStore.user, 'resourceSchedule', params))
            throw new Error('Permission Denied')
    },
    errorComponent: ({ error, reset }) => {
        if (error.message === 'Permission Denied') return <PermissionDenied />
        return <ErrorComponent error={error} reset={reset} />
    },
    component: Wrapper,
    pendingComponent: LoadingSpinner,
    loader: async ({ location }) => await loader(location.search),
    loaderDeps: ({ search: { report } }) => ({
        report,
    }),
})

const loader = async ({ report }) => {
    const reportId =
        report || SessionStore.organisation?.defaultResourceScheduleReportId
    const collections = [
        {
            collection: 'resourceScheduleReports',
            fields: ['name', 'filters'],
        },
        {
            collection: 'staff',
            fields: [
                'firstName',
                'lastName',
                'roleId',
                'inheritPayRate',
                'inheritOvertimeRate',
                'inheritCostRate',
                'inheritChargeOutRate',
            ],
        },
        {
            collection: 'staffRates',

            fields: ['staffId', 'date', 'weeklyAvailability'],
        },
        {
            collection: 'organisationHolidays',
            fields: ['name', 'startDate', 'endDate'],
        },
    ].filter(Boolean)

    return Promise.all(collections.map(fetchData))
}

function Wrapper() {
    return (
        <>
            <ResourceSchedulePageHeader
                {...Route.useParams()}
                {...Route.useSearch()}
            />
            <PageBody>
                <ResourceSchedulePage
                    {...Route.useParams()}
                    {...Route.useSearch()}
                />
            </PageBody>
            <PageSidebar>
                <ResourceScheduleSidebar
                    {...Route.useParams()}
                    {...Route.useSearch()}
                />
            </PageSidebar>
        </>
    )
}
