import { observable, computed, action, makeObservable } from 'mobx'
import PhaseCollection from '../Collections/PhaseCollection'
import ProjectCollection from '../Collections/ProjectCollection'
import ProjectNoteCollection from '../Collections/ProjectNoteCollection'
import Model from './Model'

class ProjectNoteModel extends Model {
    @observable date = null
    @observable description = null
    @observable projectId = null
    @observable phaseId = null

    constructor(data, options) {
        super()
        makeObservable(this)
        this.collection = ProjectNoteCollection
        this.init(data, options)
    }

    @computed
    get phase() {
        return PhaseCollection.phasesById[this.phaseId]
    }

    @computed
    get project() {
        return ProjectCollection.projectsById[this.projectId]
    }
}

export default ProjectNoteModel
