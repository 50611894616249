import { observable, computed, action, makeObservable } from 'mobx'
import StaffCollection from '../Collections/StaffCollection'
import ProjectCollection from '../Collections/ProjectCollection'
import PhaseCollection from '../Collections/PhaseCollection'
import Model from '../Models/Model'
import MonthlyAllocationAggregateCollection from './MonthlyAllocationAggregateCollection'
import { endOfMonth, parse, startOfMonth } from 'date-fns'
import RoleCollection from '../Collections/RoleCollection'

class MonthlyAllocationAggregateModel extends Model {
    @observable staffId = null
    @observable projectId = null
    @observable phaseId = null
    @observable roleId = null

    @observable month = ''
    @observable numMinutes = 0
    @observable pay = 0
    @observable cost = 0
    @observable chargeOut = 0

    constructor(data, options) {
        super()
        makeObservable(this)
        this.collection = MonthlyAllocationAggregateCollection
        this.init(data, options)
    }
    @computed
    get staff() {
        return StaffCollection.modelsById[this.staffId]
    }

    @computed
    get role() {
        return (
            this.staff?.role ||
            RoleCollection.modelsById[this.staffRoleId] ||
            RoleCollection.modelsById[this.roleId]
        )
    }
    @computed
    get project() {
        return ProjectCollection.modelsById[this.projectId]
    }
    @computed
    get phase() {
        return PhaseCollection.modelsById[this.phaseId]
    }
    @computed
    get date() {
        return parse(this.month, 'yyyy-MM', new Date())
    }
    @computed
    get startDate() {
        return startOfMonth(parse(this.month, 'yyyy-MM', new Date()))
    }
    @computed
    get endDate() {
        return endOfMonth(parse(this.month, 'yyyy-MM', new Date()))
    }
    @computed
    get hours() {
        return this.numMinutes / 60
    }
}

export default MonthlyAllocationAggregateModel
