import cuid from 'cuid'
import { observer } from 'mobx-react'
import React from 'react'
import Modal from '../Modal'
import DataStore from '../../State/DataStore'
import TextValue from '../Widgets/TextValue'

export default observer(({ report, modalId }) => {
    return (
        <Modal
            modalId={modalId}
            heading="Rename Report"
            onSave={() => DataStore.saveModel(report)}
            saveLabel="Rename Report"
        >
            <div>Please enter new name for this Report:</div>
            <TextValue
                value={report.name}
                onChange={(v) => report.update({ name: v })}
            />
        </Modal>
    )
})
