import { observer } from 'mobx-react'
import React from 'react'
import Table from '../../Components/Table'
import { Link } from '@tanstack/react-router'
import PermissionCollection from '../../State/Collections/PermissionCollection'
import SessionStore from '../../State/SessionStore'

const columns = [
    {
        id: 'label',
        label: 'Label',
        type: 'text',
        width: 25,
        value: (row) => {
            return row.name
        },
        component: ({ value, stores }) => {
            const { row } = stores
            return (
                <div>
                    <Link to={`/permissions/${row.rowObject.id}`}>{value}</Link>
                    <span>
                        {row.rowObject.id ===
                        SessionStore.organisation.defaultPermissionsId
                            ? ' (Default)'
                            : null}
                    </span>
                </div>
            )
        },
    },
]

export default observer(() => {
    return (
        <>
            <Table
                columns={columns}
                rows={PermissionCollection.permissions}
                sortBy={[['label', 'asc']]}
            />
        </>
    )
})
