import { observable, computed, action, makeObservable } from 'mobx'
import PhaseCollection from '../Collections/PhaseCollection'
import ProjectCollection from '../Collections/ProjectCollection'
import MonthlyRevenueCellCollection from '../Collections/MonthlyRevenueCellCollection'
import Model from './Model'
import { parse, startOfMonth } from 'date-fns'
import RevenueRowCollection from '../Collections/RevenueRowCollection'

class MonthlyRevenueCellModel extends Model {
    @observable month = null
    @observable revenue = 0
    @observable revenueToDate = 0
    @observable status = null
    @observable projectId = null
    @observable phaseId = null
    @observable phaseTitle = null
    @observable ownerId = null
    @observable costCentreId = null
    @observable type = null

    constructor(data, options) {
        super()
        makeObservable(this)
        this.collection = MonthlyRevenueCellCollection
        this.init(data, options)
        const rowId = `('${this.status}', '${this.projectId}', '${this.phaseId}')`
        const row = RevenueRowCollection.rowsById[rowId]
        if (!row) {
            RevenueRowCollection.addRow(
                {
                    id: rowId,
                    status: this.status,
                    projectId: this.projectId,
                    phaseId: this.phaseId,
                    phaseTitle: this.phaseTitle,
                    groups: ['status', 'project', 'phase'],
                },
                { trackUpdates: false }
            )
        }
    }

    @computed
    get phase() {
        return PhaseCollection.phasesById[this.phaseId]
    }

    @computed
    get project() {
        return ProjectCollection.projectsById[this.projectId]
    }

    serialize() {
        return {
            id: this.id,
            month: this.month,
            revenue: this.revenue,
            projectId: this.projectId,
            phaseId: this.phaseId,
        }
    }

    serializeUpdates() {
        return this.serialize()
    }
    @computed
    get date() {
        return startOfMonth(parse(this.month, 'yyyy-MM', new Date()))
    }
}

export default MonthlyRevenueCellModel
