import { observable, computed, action, makeObservable } from 'mobx'
import fetchData from '../../Queries/fetchData'
import { mergeQueries } from '../../Queries/mergeQueries'
import ReportCollection from '../../State/Collections/ReportCollection'
import SessionStore from '../../State/SessionStore'
import TableStore from '../../Components/TableStore'
import download from 'downloadjs'
import Papa from 'papaparse'
import { getQueryPermissionFilters } from '../../State/Permissions/HasPermissions'
import bind from 'bind-decorator'
import FetchStore from '../../Queries/FetchStore'
import sortPhases from '../../Utils/sortPhases'
import ProjectCollection from '../../State/Collections/ProjectCollection'
import { router } from '../../App'

class ProjectListStore {
    @observable queryData = []
    @observable expandAll = false
    @observable exportGroup = 'Projects'
    @observable searchParams = {}
    constructor() {
        makeObservable(this)
    }

    @action.bound
    init(queryData) {
        this.queryData = queryData
        this.expandAll = false
    }

    @action.bound
    setSearchParams(params) {
        this.searchParams = params
    }

    @computed
    get report() {
        return (
            ReportCollection.reportsById[this.searchParams?.report] ||
            SessionStore.organisation.defaultProjectReport
        )
    }
    @action.bound
    toggleExpandAll() {
        this.expandAll = !this.expandAll
    }
    @action.bound
    setExportGroup(group) {
        this.exportGroup = group
    }
    @bind
    async downloadCSV() {
        const group = this.exportGroup
        if (group === 'Projects') {
            this.report.downloadCSV()
        } else {
            const tableStore = new TableStore()
            const csvData = this.report.tableStore.rows.flatMap((row, i) => {
                const phases = row.rowObject.children.sort(sortPhases)
                const project = row.rowObject
                const columns = this.report.tableColumns
                if (!columns.map((c) => c.id).includes('project')) {
                    columns.unshift({
                        id: 'projectName' + project.id,
                        label: 'Project',
                        type: 'text',
                        width: 26,
                        value: (row) => {
                            return ProjectCollection.projectsById[row.projectId]
                                .title
                        },
                    })
                }
                tableStore.update({
                    rows: phases,
                    columns,
                })
                return tableStore.getCsvData({
                    includeHeadings: i === 0,
                })
            })
            download(
                Papa.unparse(csvData),
                `${this.report.name}.csv`,
                'text/csv'
            )
        }
    }
}

export default new ProjectListStore()
