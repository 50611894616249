import { observer } from 'mobx-react'
import React, { Suspense, useCallback, useEffect } from 'react'
import OverheadExpenseCollection from '../../State/Collections/OverheadExpenseCollection'

import PageHeader from '../Layout/PageHeader'
import { canEditOverheadExpenses } from '../../State/Permissions/HasPermissions'
import SessionStore from '../../State/SessionStore'

export default observer(({ extraContent }) => {
    return (
        <PageHeader
            heading={
                <div>
                    <div>{'Overhead Expenses'}</div>
                </div>
            }
            extraContent={
                canEditOverheadExpenses(SessionStore.user) ? (
                    <button
                        className="page-header__button--primary add-org-hol-button plus-btn"
                        onClick={() => {
                            const ph = OverheadExpenseCollection.add(
                                { repeatQuantity: 1, repeatUnit: 'months' },
                                { trackUpdates: true }
                            )
                            ph.isNew = true
                        }}
                        style={{ marginTop: '2em' }}
                    >
                        + Add Overhead Expense
                    </button>
                ) : null
            }
        />
    )
})
