import classNames from 'classnames'
import { observer } from 'mobx-react'
import React, { useState } from 'react'

export default observer(({ children }) => {
    return (
        <div
            className={classNames(
                'coincraft-page-layout__content',
                'flex-1 w-full min-w-0 h-full min-h-0 overflow-hidden'
            )}
        >
            <div className="coincraft-page-layout__page relative h-full w-full overflow-auto print:block print:h-auto grid">
                {children}
            </div>
        </div>
    )
})
