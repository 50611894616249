import React, {useState} from "react"
import copy from "copy-to-clipboard";

export const InvoiceFormulaInfo = props => {
            const {style, ...extraProps } = props;
            const [showDropdown, setShowDropdown] = useState(false)
            const [hoveredKey, setHoveredKey] = useState(null);

			return (
				<div
					style={{
						position: "relative",
						display: "inline-block",
						...style,
					}}
					{...extraProps}
				>
					<i
						onClick={(e) => setShowDropdown(!showDropdown)}
						className="fa fa-question-circle"
					/>
					{showDropdown && (
						<div
							style={{
								position: "absolute",
								right: 0,
								top: "2em",
								textAlign: "right",
								zIndex: 100,
								fontSize: "0.9em",
								border: "1px solid #ddd",
								borderRadius: "0.5em",
								background: "white",
								boxShadow: "9px 7px 17px -4px rgb(0,0,0,0.2)",
							}}
						>
							<div
								style={{
									padding: "0.5em 1em",
									borderBottom: "1px solid #ddd",
								}}
							>
								Description Codes
							</div>
							<div
								style={{ overflowY: "scroll", height: "15em" }}
							>
								{formulaExamples.map((e, i) => {
									const hovering = hoveredKey === i;
									return (
										<div
											style={{
												padding: "0.5em 1em",
												borderBottom: "1px solid #eee",
												fontWeight: "normal",
												background: hovering
													? "#f9f9f9"
													: "white",
												cursor: "pointer",
												position: "relative",
											}}
											onPointerOver={(e) =>
												setHoveredKey(i)
											}
											onPointerOut={(e) =>
												hoveredKey === i &&
												setHoveredKey(null)
											}
										>
											<div>{e.label}</div>
										</div>
									);
								})}
							</div>
						</div>
					)}
					{formulaExamples[hoveredKey] && (
						<div
							style={{
								position: "absolute",
								left: "2.5em",
								top: "4.5em",
								fontSize: "0.9em",
								width: "25em",
								padding: "1em",
								border: "1px solid #ddd",
								borderRadius: "0.5em",
								background: "white",
								boxShadow: "9px 7px 17px -4px rgb(0,0,0,0.2)",
								zIndex: 100,
								fontWeight: "normal",
							}}
						>
							<div>{formulaExamples[hoveredKey].description}</div>
							<div
								style={{
									fontSize: "0.9em",
									fontStyle: "italic",
									fontWeight: "normal",
									marginTop: "1em",
									whiteSpace: "pre-line",
								}}
							>
								<div><b>For Example:</b></div>
								<div style={{marginTop: "-0.25em"}}><span>
									{formulaExamples[hoveredKey].example.code}
								</span></div>
								<div style={{marginTop: "0.5em"}}><b>Results In:</b></div>
								<div style={{marginTop: "-0.25em"}}><span>
									{formulaExamples[hoveredKey].example.result}
								</span></div>
							</div>
						</div>
					)}
				</div>
			);
        };

const formulaExamples = [
	{
		label: `"Text"`,
		description: "Use quotation marks around any text values.",
		example: {
			code: `"Invoice" + "Code"`,
			result: `InvoiceCode`
		}
	},
	{
		label: `invNum`,
		description: "The next invoice number.",
		example: {
			code: `"INV_" + invNum`,
			result: `INV_15`
		}
	},
	{
		label: `format Number`,
		description: "Format numbers to have a certain character length.",
		example: {
			code: `"INV_" + format(invNum, "0000")`,
			result: `INV_0015`
		}
	},
	{
		label: `invNumYear`,
		description: "The next invoice number this year.",
		example: {
			code: `"INV_" + invNumYear`,
			result: `INV_5`
		}
	},
	{
		label: `format Date`,
		description: "Format numbers to have a certain character length.",
		example: {
			code: `"INV_" + format(now, "yy") + format(invNumYear, "000")`,
			result: `INV_21003`
		}
	},
	{
		label: `$project.invNum`,
		description: "The next invoice number for this project.",
		example: {
			code: `"INV_" + $project.invNum`,
			result: `INV_3`
		}
	},
	{
		label: `$project.code`,
		description: "The project number/code for this project.",
		example: {
			code: `"$project.code" + "_" + format($project.invNum, "00)`,
			result: `2003_05`
		}
	},
]