import React from 'react'
import * as PopoverPrimitive from '@radix-ui/react-dialog'
import { MagnifyingGlassIcon } from '@radix-ui/react-icons'
import { Command as CommandPrimitive } from 'cmdk'

// Assuming a simple utility for classNames
function cn(...classes) {
    return classes.filter(Boolean).join(' ')
}

const Dialog = PopoverPrimitive.Root
const DialogContent = PopoverPrimitive.Content

const Command = React.forwardRef(({ className, ...props }, ref) => (
    <CommandPrimitive
        ref={ref}
        className={cn('command-base', className)}
        {...props}
    />
))
Command.displayName = 'Command'

const CommandDialog = ({ children, ...props }) => {
    return (
        <Dialog {...props}>
            <DialogContent className="dialog-content">{children}</DialogContent>
        </Dialog>
    )
}

const CommandInput = React.forwardRef(({ className, ...props }, ref) => (
    <div
        className="command-input-wrapper"
        style={{
            display: 'flex',
            alignItems: 'center',
            width: '100%',
            padding: '0.5rem',
            borderBottom: '1px solid #e5e7eb',
        }}
    >
        <MagnifyingGlassIcon
            className="magnifying-glass-icon"
            style={{ flex: '0 0 auto' }}
        />
        <CommandPrimitive.Input
            style={{
                flex: '1 1 auto',
                width: '100%',
                border: 'none',
                boxShadow: 'none',
            }}
            ref={ref}
            className={cn('focus:outline-none pl-2 text-sm', className)}
            {...props}
        />
    </div>
))
CommandInput.displayName = 'CommandInput'

const CommandList = React.forwardRef(({ className, ...props }, ref) => (
    <CommandPrimitive.List
        ref={ref}
        className={cn('command-list', className)}
        {...props}
    />
))
CommandList.displayName = 'CommandList'

const CommandEmpty = React.forwardRef((props, ref) => (
    <CommandPrimitive.Empty ref={ref} className="command-empty" {...props} />
))
CommandEmpty.displayName = 'CommandEmpty'

const CommandGroup = React.forwardRef(({ className, ...props }, ref) => (
    <CommandPrimitive.Group
        ref={ref}
        className={cn('command-group', className)}
        {...props}
    />
))
CommandGroup.displayName = 'CommandGroup'

const CommandSeparator = React.forwardRef(({ className, ...props }, ref) => (
    <CommandPrimitive.Separator
        ref={ref}
        className={cn('command-separator', className)}
        {...props}
    />
))
CommandSeparator.displayName = 'CommandSeparator'

const CommandItem = React.forwardRef(({ className, ...props }, ref) => (
    <CommandPrimitive.Item
        ref={ref}
        className={cn('hover:bg-[#f5f5f5] cursor-pointer text-xs', className)}
        {...props}
    />
))
CommandItem.displayName = 'CommandItem'

const CommandShortcut = ({ className, ...props }) => {
    return <span className={cn('command-shortcut', className)} {...props} />
}
CommandShortcut.displayName = 'CommandShortcut'

export {
    Command,
    CommandDialog,
    CommandInput,
    CommandList,
    CommandEmpty,
    CommandGroup,
    CommandItem,
    CommandShortcut,
    CommandSeparator,
}
