import { makeRequest } from './makeRequest'
import {
    CollectionLookup,
    AggregateCollectionLookup,
    CollectionClassLookup,
    AggregateCollectionClassLookup,
} from '../State/CollectionLookup'
import SessionStore from '../State/SessionStore'
import _ from 'lodash'
import is from '@sindresorhus/is'
import tuple from 'immutable-tuple'
import { throwError } from '../utils'
import FetchStore from './FetchStore'
import { formatISO } from 'date-fns'
import { getQueryPermissionFilters } from '../State/Permissions/HasPermissions'
import * as Sentry from '@sentry/react'

export default async ({
    collection,
    ids = [],
    fields = [],
    filters = [],
    groupBy = [],
    chain = [],
    sortBy = [],
    limit = null,
    subQueries = [],
    id = null,
    data = {},
} = {}) => {
    if (!collection) console.trace()
    let response = {}
    if (SessionStore.user) {
        chain = chain.map((c) => {
            return {
                ...c,
                filters: [
                    ...(c.filters ?? []),
                    ...getQueryPermissionFilters(
                        c.collection,
                        SessionStore.user
                    ),
                ],
            }
        })
        subQueries = subQueries.map((sq) => {
            return {
                ...sq,
                filters: [
                    ...(sq.filters ?? []),
                    ...getQueryPermissionFilters(
                        sq.collection,
                        SessionStore.user
                    ),
                ],
            }
        })
        response = [
            await makeRequest({
                path: `api/v1.5/fetch/${collection}`,
                method: 'post',
                data: {
                    collection,
                    fields: [...fields], //_.intersection(fields, fieldPermissions[level]),
                    filters: [
                        ...filters,
                        ...getQueryPermissionFilters(
                            collection,
                            SessionStore.user
                        ),
                    ],
                    groupBy: [...groupBy],
                    chain: [...chain],
                    sortBy: [...sortBy],
                    limit,
                    subQueries: [...subQueries],
                    ...data,
                },
            }),
        ]
        let models = {}
        response.forEach((r) => {
            Object.entries(r.data).forEach(([rCollection, modelsData]) => {
                if (rCollection === 'labels') return
                rCollection = r.data.labels?.[rCollection] || rCollection
                try {
                    let model
                    if (!(groupBy.length && collection === rCollection)) {
                        model = CollectionLookup[rCollection].addMany(
                            modelsData,
                            {
                                trackUpdates: false,
                            }
                        )
                        models[rCollection] ??= new CollectionClassLookup[
                            rCollection
                        ]()
                        models[rCollection].addMany(modelsData, {
                            trackUpdates: false,
                        })
                    } else {
                        model = AggregateCollectionLookup[rCollection].addMany(
                            groupBy,
                            modelsData,
                            {
                                trackUpdates: false,
                            }
                        )
                        models[rCollection] ??=
                            new AggregateCollectionClassLookup[rCollection]()
                        models[rCollection].addMany(modelsData, {
                            trackUpdates: false,
                        })
                    }
                } catch (e) {
                    Sentry.captureException(e)
                    console.error(e)
                    throw e
                }
            })
        })
        if (id) {
            FetchStore.setResponse(id, models)
        }
        return models
    } else {
        response = await makeRequest({
            path: `api/v1.5/fetch/${collection}`,
            method: 'post',
            data: {
                collection,
                ids: [...ids],
                fields: [...fields],
                filters: [...filters],
                groupBy: [...groupBy],
                chain: [...chain],
                sortBy: [...sortBy],
                limit,
                subQueries: [...subQueries],
            },
        })
        let models = {}
        Object.entries(response.data).forEach(([rCollection, modelsData]) => {
            if (rCollection === 'labels') return
            rCollection = response.data.labels?.[rCollection] || rCollection
            models[rCollection] ??= []
            try {
                let model
                if (!(groupBy.length && collection === rCollection)) {
                    model = CollectionLookup[rCollection].addMany(modelsData, {
                        trackUpdates: false,
                    })
                } else {
                    model = AggregateCollectionLookup[rCollection].addMany(
                        groupBy,
                        modelsData,
                        {
                            trackUpdates: false,
                        }
                    )
                }
                models[rCollection].push(...model)
            } catch (e) {
                Sentry.captureException(e)
                console.error(e)
                throw e
            }
        })
        if (id) {
            FetchStore.setResponse(id, models)
        }
        return models
    }
}

function timeout(ms) {
    return new Promise((resolve) => setTimeout(resolve, ms))
}
