import { observable, action, computed, makeObservable } from 'mobx'

class StaffRoleListStore {
    @observable showArchived = false
    constructor() {
        makeObservable(this)
    }
    @action.bound
    toggleShowArchived() {
        this.showArchived = !this.showArchived
    }
}

export default new StaffRoleListStore()
