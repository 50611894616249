import { observer } from 'mobx-react'
import React from 'react'
import Table from '../../Components/Table'
import OrganisationHolidayCollection from '../../State/Collections/OrganisationHolidayCollection'
import SessionStore from '../../State/SessionStore'
import { canEditHolidays } from '../../State/Permissions/HasPermissions'

const columns = [
    {
        id: 'name',
        label: 'Name',
        type: 'text',
        width: 25,
        editable: (row) => canEditHolidays(SessionStore.user),
        value: (row) => {
            return row.name
        },
        onChange: (row) => (val) => row.update({ name: val }),
    },
    {
        id: 'startDate',
        label: 'Start Date',
        type: 'date',
        width: 25,
        editable: (row) => canEditHolidays(SessionStore.user),
        value: (row) => {
            return row.startDate
        },
        onChange: (row) => (val) => row.update({ startDate: val }),
    },
    {
        id: 'endDate',
        label: 'End Date',
        type: 'date',
        width: 25,
        editable: (row) => canEditHolidays(SessionStore.user),
        value: (row) => {
            return row.endDate
        },
        onChange: (row) => (val) => row.update({ endDate: val }),
    },
    {
        label: '',
        width: 4,
        type: 'button',
        permissions: (r) => canEditHolidays(SessionStore.user),
        editable: (r) => canEditHolidays(SessionStore.user),
        value: (p) => <i className="fa fa-times" style={{ marginRight: 0 }} />,
        onClick: (r) => () => {
            r.update({ deletedAt: new Date() })
        },
    },
]

export default observer(() => {
    return (
        <>
            <Table
                columns={columns}
                rows={OrganisationHolidayCollection.activeHolidays}
                sortBy={[['startDate', 'desc']]}
                newRowDirection={'asc'}
            />
        </>
    )
})
