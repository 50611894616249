import _ from 'lodash'
import { observer } from 'mobx-react'
import React, { useState } from 'react'
import Modal from '../../Components/Modal'
import DataStore from '../../State/DataStore'
import { useNavigate } from '@tanstack/react-router'

export default observer(({ project, modalId }) => {
    const navigate = useNavigate()
    return (
        <Modal
            modalId={modalId}
            heading="Delete Project"
            saveButtons={[
                {
                    label: 'Archive Project',
                    onClick: () => {
                        project.phases.forEach((phase) => {
                            phase.update({ status: 'archived' })
                        })
                        DataStore.startSave()
                        navigate({ to: '/projects/' })
                    },
                },
                {
                    label: 'Delete Project',
                    onClick: () => {
                        project.update({ deletedAt: new Date() })
                        DataStore.saveModel(project)
                        navigate({ to: '/projects/' })
                    },
                },
            ]}
        >
            <p style={{ margin: '1em' }}>
                Are you sure you wish to delete <em>{project.title}</em>?
            </p>
            <p style={{ margin: '1em' }}>
                We recommend you "Archive" your projects to retain historical
                timesheet and billing information.
            </p>
        </Modal>
    )
})
