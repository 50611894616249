import React from 'react'
import _ from 'lodash'
import { formatPercentage0, middleOfThree } from '../../utils'

export default ({
    numerator,
    denominator,
    nullText = 'N/A',
    formatNumber = (n) => Math.round(n),
    ...props
}) => {
    let value, text
    if (numerator == null) {
        value = NaN
        text = nullText
    } else if (denominator == null || denominator === 0) {
        value = NaN
        text = `${formatNumber(numerator)} / -`
    } else {
        value = numerator / denominator
        text = `${formatNumber(numerator)} / ${formatNumber(
            denominator
        )} (${formatPercentage0((numerator / denominator) * 100)})`
    }

    return <ProgressBar value={value} text={text} {...props} />
}
const ProgressBar = ({ width, height, value, text, className, ...props }) => {
    var val = !isNaN(value) ? value : 0
    var color
    if (val < 0.8) {
        color = '#FFC734'
    } else if (val <= 1.0) {
        color = '#ffb534'
    } else {
        color = '#f05a28'
    }
    var innerWidth = middleOfThree(0, val, 1) * 100 + '%'

    return (
        <svg
            className={className}
            style={{
                width: width,
                height: height,
                display: 'inline-block',
            }}
        >
            <g shapeRendering="crisp-edges">
                <rect
                    x={0}
                    y={0}
                    width="100%"
                    height="100%"
                    fill={!isNaN(value) ? '#888' : '#aaa'}
                    stroke="none"
                />
                {value > 0 ? (
                    <g>
                        <rect
                            x={0}
                            y={0}
                            width={innerWidth}
                            height="100%"
                            fill={color}
                            stroke="none"
                        />
                    </g>
                ) : null}
                {text ? (
                    <text
                        x="50%"
                        y="50%"
                        fill="white"
                        textAnchor="middle"
                        dy="0.4em"
                        style={{
                            fontWeight: 600,
                            textShadow: '1px 1px 2px rgba(0, 0, 0, 0.43)',
                        }}
                    >
                        {text}
                    </text>
                ) : null}
            </g>
        </svg>
    )
}
