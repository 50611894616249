import { Button } from '@2/components/ui/button'
import {
    Tooltip,
    TooltipContent,
    TooltipProvider,
    TooltipTrigger,
} from '@2/components/ui/tooltip'
import { cn } from '@2/lib/utils'

export const NavButton = ({ icon: Icon, label, onClick, isExpanded }) => (
    <TooltipProvider>
        <Tooltip delayDuration={0}>
            <TooltipTrigger asChild>
                <Button
                    variant="ghost"
                    className={cn(
                        'w-full justify-start text-foreground hover:bg-accent hover:text-accent-foreground',
                        !isExpanded && 'justify-center p-2'
                    )}
                    onClick={onClick}
                >
                    <Icon className={cn('h-4 w-4', isExpanded && 'mr-2')} />
                    {isExpanded && <span>{label}</span>}
                </Button>
            </TooltipTrigger>
            {!isExpanded && (
                <TooltipContent
                    side="right"
                    align="center"
                    className="flex items-center"
                >
                    {label}
                </TooltipContent>
            )}
        </Tooltip>
    </TooltipProvider>
)
