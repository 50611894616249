import classNames from 'classnames'
import React from 'react'

import { cn } from '../../lib/utils'
const HeaderButton = React.forwardRef(
    ({ label, icon, onClick, rightIcon, className, ...props }, ref) => {
        const { variant } = props
        const primary =
            'border-white disabled:bg-white text-white disabled:text-[#444] hover:bg-white/25 max-w-max'
        const secondary =
            'hover:bg-[#e0e0e0] text-[#333] hover:border-[#adadad] border-[#ccc] bg-white'
        return (
            <button
                {...props}
                ref={ref}
                onClick={onClick}
                className={cn(
                    'flex items-center py-1.5 gap-2 px-4 border bg-transparent transition-colors rounded-[6px] relative disabled:opacity-50 disabled:cursor-not-allowed disabled:pointer-events-none w-full whitespace-nowrap',
                    variant === 'secondary' ? secondary : primary,
                    className && className
                )}
            >
                {icon}
                <span className="hover:text-black font-normal flex items-center flex-auto overflow-hidden text-ellipsis whitespace-nowrap text-[length:inherit]">
                    {label}
                </span>
                {rightIcon}
            </button>
        )
    }
)

HeaderButton.displayName = 'HeaderButton'

export default HeaderButton
