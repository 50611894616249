export default {
    name: 'New Report',
    type: 'staffMember',
    columns: ['name', 'email', 'recordedHours'],
    filters: [
        {
            column: 'isActive',
            operator: '=',
            value: true,
        },
    ],
    defaultGroup: 'staff',
    groupBy: [],
    sortBy: [['name', 'asc']],
    options: {
        dateRange: 'thisWeek',
    },
}
