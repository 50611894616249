import { observable, computed, action, makeObservable } from 'mobx'
import ContactCollection from '../Collections/ContactCollection'
import InvoiceCollection from '../Collections/InvoiceCollection'
import ProjectCollection from '../Collections/ProjectCollection'
import Model from './Model'

class ContactModel extends Model {
    @observable firstName = null
    @observable lastName = null
    @observable organisationName = null
    @observable address = null
    @observable email = null
    @observable phone = null
    @observable accountingSystemId = null
    @observable accountingSystemContactId = null

    constructor(data, options) {
        super()
        makeObservable(this)
        this.collection = ContactCollection
        this.init(data, options)
    }

    @computed
    get projects() {
        return [
            ...(ProjectCollection.projectsByContactId[this.id] || []),
            ...(ProjectCollection.projectsByPrimaryContactId[this.id] || []),
        ]
    }

    @computed
    get invoices() {
        return InvoiceCollection.invoicesByContactId[this.id] || []
    }

    @computed
    get fullName() {
        return [this.firstName, this.lastName].join(' ')
    }

    @computed
    get label() {
        return this.fullName
            ? this.organisationName
                ? `${this.fullName} (${this.organisationName})`
                : this.fullName
            : this.organisationName
    }
}

export default ContactModel
