import _ from 'lodash'
import { observer } from 'mobx-react'
import React, { useState } from 'react'
import Modal from '../../Components/Modal'
import DataStore from '../../State/DataStore'
import { useNavigate } from '@tanstack/react-router'

export default observer(({ invoice, modalId }) => {
    const navigate = useNavigate()
    return (
        <Modal
            modalId={modalId}
            heading="Delete Invoice"
            onSave={() => {
                invoice.update({ deletedAt: new Date() })
                invoice.lineItems.forEach((li) => {
                    li.timeEntries.forEach((te) => {
                        te.update({ beenInvoiced: false })
                    })
                    li.expenseItems.forEach((ei) => {
                        ei.update({ beenInvoiced: false })
                    })
                    li.update({ deletedAt: new Date() })
                })
                DataStore.startSave()
                navigate({
                    to: '/invoices',
                })
            }}
            saveLabel="Delete Invoice"
        >
            <p style={{ margin: '1em' }}>
                Are you sure you wish to delete invoice <em>{invoice.ref}</em>?
            </p>
        </Modal>
    )
})
