import _ from 'lodash'
import { combineSpanSets } from '../Spanner'
import ratesToSpanSet from './ratesToSpanSet'

export default ({ project, phase, staff, role }, rateType) => {
    let resourceRates
    if (staff && !staff.inheritRateType(rateType)) {
        resourceRates = ratesToSpanSet(
            staff.rates.sort((a, b) => a.date - b.date),
            rateType
        )
    } else if (!staff || staff.inheritRateType(rateType)) {
        role = staff?.role || role
        if (!role) {
            resourceRates = []
        } else if (role.avgRateType(rateType)) {
            resourceRates = combineSpanSets(
                role.staff
                    .filter((s) => !s.inheritRateType(rateType))
                    .map((s) =>
                        ratesToSpanSet(
                            s.rates.sort((a, b) => a.date - b.date),
                            rateType
                        )
                    ),
                (...values) => {
                    return _.mean(values.filter((v) => v))
                }
            )
        } else {
            resourceRates = ratesToSpanSet(
                role.rates.sort((a, b) => a.date - b.date),
                rateType
            )
        }
    }
    const projectRates = ratesToSpanSet(
        (phase?.project?.rates || project?.rates || [])
            .filter((r) => {
                return (
                    (!phase ||
                        !r.phase ||
                        r.phase === r.project.rootPhase ||
                        r.phase?.isRootPhase ||
                        phase.id === r.phase) &&
                    (!r.resource ||
                        (staff && r.staff === staff) ||
                        (!staff && role && r.role === role))
                )
            })
            .sort((a, b) => a.date - b.date),
        rateType
    )
    const phaseRates = ratesToSpanSet(
        phase?.rates
            .filter(
                (r) =>
                    !r.resource ||
                    (staff && r.staff === staff) ||
                    (!staff && role && r.role === role)
            )
            .sort((a, b) => a.date - b.date),
        rateType
    )
    const combinedSpans = combineSpanSets(
        [phaseRates, projectRates, resourceRates],
        (phaseRate, projectRate, resourceRate) => {
            return (
                phaseRate ||
                projectRate ||
                resourceRate ||
                resourceRates[1]?.value ||
                resourceRates[0]?.value // to handle potential NaN before first rate
            )
        }
    )
    return combinedSpans
}
