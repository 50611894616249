import tuple from 'immutable-tuple'
import { isDateOnOrBetweenRange } from './dateHelpers'
import _ from 'lodash'
import RevenueTargetCollection from '../State/Collections/RevenueTargetCollection'

export function editRevenueTargetsInDateRange(
    revenueTargets,
    dateRange,
    value
) {
    //get unique project, phase
    const uniqIds = new Set()
    const matchingRevenueTargets = []
    revenueTargets.forEach((rt) => {
        uniqIds.add(tuple(rt.projectId, rt.phaseId))
        if (
            rt.modelType !== 'revenueTarget' ||
            !isDateOnOrBetweenRange(rt.date, dateRange)
        )
            return
        matchingRevenueTargets.push(rt)
    })
    const totalAmount = _.sum(matchingRevenueTargets.map((rt) => rt.amount))
    if (matchingRevenueTargets.length && totalAmount) {
        const amountRatio = value / totalAmount
        matchingRevenueTargets.forEach((rt) =>
            rt.update({ amount: rt.amount * amountRatio })
        )
    } else {
        const splitValue = value / uniqIds.size
        ;[...uniqIds].forEach((ids) => {
            const rt = RevenueTargetCollection.add(
                {
                    projectId: ids[0],
                    phaseId: ids[1],
                    amount: splitValue,
                    date: dateRange[1],
                },
                { trackUpdates: true }
            )
        })
    }
}
