import { observable, computed, action, makeObservable } from 'mobx'
import Collection from './Collection'
import RoleModel from '../Models/RoleModel'
import RoleRateCollection from './RoleRateCollection'

class RoleCollection extends Collection {
    constructor() {
        super({ collection: 'roles', modelClass: RoleModel })
        makeObservable(this)
    }
    get rolesById() {
        return this.modelsById
    }
    get roles() {
        return this.models
    }
    @action.bound
    addRole(data) {
        this.add(data)
    }
    @action.bound
    addRoles(data) {
        this.addMany(data)
    }
    @action.bound
    createRole() {
        const role = this.add({ name: 'New Role' }, { trackUpdates: true })
        const rate = RoleRateCollection.add(
            {
                roleId: role.id,
                date: '2000-01-01',
                payRate: 0,
                costRate: 0,
                chargeOutRate: 0,
                overtimeRate: 0,
            },
            { trackUpdates: true }
        )
        return role
    }
}

export default new RoleCollection()
export const RoleCollectionClass = RoleCollection
