import { observable, computed, action, makeObservable } from 'mobx'
import Collection from './Collection'
import ProjectNoteModel from '../Models/ProjectNoteModel'
import ChangeLogModel from '../Models/ChangeLogModel'

class ChangeLogCollection extends Collection {
    constructor() {
        super({ collection: 'changeLogItems', modelClass: ChangeLogModel })
        this.addLookup('changeLogItemsByPhaseId', 'manyByKey', {
            key: (t) => t.phaseId,
            filter: (t) => !t.deletedAt,
        })
        this.addLookup('changeLogItemsByProjectId', 'manyByKey', {
            key: (t) => t.projectId,
            filter: (t) => !t.deletedAt,
        })
        makeObservable(this)
    }
    get changeLogItemsById() {
        return this.modelsById
    }
    get changeLogItems() {
        return this.models
    }
    get changeLogItemsById() {
        return this.modelsById
    }
    @action.bound
    addChangeLogItem(data) {
        this.add(data)
    }
    @action.bound
    addChangeLogItems(data) {
        this.addMany(data)
    }
}

export default new ChangeLogCollection()
export const ChangeLogCollectionClass = ChangeLogCollection
