import _ from 'lodash'
import { observer } from 'mobx-react'
import React, { useRef, useState } from 'react'
import Modal from '../../Components/Modal'
import { FormatCurrency } from '../../Utils/Localisation/CurrencyFormatter'
import { action, computed, makeObservable, observable } from 'mobx'
import Formula from '../../formulas/Formula'
import { Input } from 'antd'
import DraggableList from 'react-draggable-list'
import Checkbox from '../../Components/Widgets/Checkbox'
import RenderOnQueries from '../Layout/RenderOnQueries'
import { Selector } from '../../Components/Selector'

class phaseFeeData {
    @observable phase = null
    @observable postition = 0
    @observable feeData = {}
    constructor(phase, index, projectStore) {
        makeObservable(this)
        this.projectStore = projectStore
        this.id = phase.id
        this.phase = phase
        this.position = index
    }
    @action.bound
    initFeeData() {
        const phase = this.phase
        this.feeData = phase.feeData || {
            type: 'percentAgreed',
            visible: true,
            locked: false,
        }
        if (this.feeData.type === 'percentAgreed') {
            this.feeData.value = phase.fee
            this.feeData.percent =
                (phase.fee / this.projectStore?.agreedFee) * 100
        } else {
            this.feeData.value =
                _.sum(this.previousPhases.map((pp) => pp.phase.fee)) + phase.fee
            this.feeData.percent =
                ((_.sum(this.previousPhases.map((pp) => pp.phase.fee)) +
                    phase.fee) /
                    this.projectStore?.agreedFee) *
                100
        }
    }
    @action.bound
    setLocked(newLocked) {
        this.feeData.locked = newLocked
        if (newLocked) {
            if (this.feeData.type === 'percentAgreed') {
                this.setFeeDataValue(
                    this.projectStore?.agreedFee * (this.feeData.percent / 100)
                )
            } else {
                this.setFeeDataValue(
                    this.projectStore?.agreedFee *
                        (this.feeData.percent / 100) -
                        this.previousPhasesAgreedFee
                )
            }
        }
        this.phase.update({ feeData: { ...this.feeData } })
    }
    @action.bound
    setVisible(newVisible) {
        this.feeData.visible = newVisible
        if (!newVisible) {
            if (this.feeData.type === 'percentAgreed') {
                this.setFeeDataValue(
                    this.projectStore?.agreedFee * (this.feeData.percent / 100)
                )
            } else {
                this.setFeeDataValue(
                    this.projectStore?.agreedFee *
                        (this.feeData.percent / 100) -
                        this.previousPhasesAgreedFee
                )
            }
        }
        this.phase.update({ feeData: { ...this.feeData } })
    }
    @action.bound
    setPosition(newPosition) {
        this.position = newPosition
    }
    @action.bound
    setFeeDataType(newFeeType) {
        this.feeData.type = newFeeType
        this.phase.update({ feeData: { ...this.feeData } })
    }
    @action.bound
    setFeeDataValue(newFeeValue) {
        this.feeData.value = newFeeValue
        this.phase.update({ feeData: { ...this.feeData } })
    }
    @action.bound
    setFeeDataPercent(newFeePercent) {
        this.feeData.percent = newFeePercent
        this.phase.update({ feeData: { ...this.feeData } })
    }
    @computed
    get previousPhases() {
        return (
            this.projectStore?.phasesFeeData.filter(
                (ph) => ph.position < this.position && ph.feeData.visible
            ) || []
        )
    }
    @computed
    get previousPhasesAgreedFee() {
        return _.sum(this.previousPhases.map((ph) => ph.agreedFee))
    }
    @computed
    get agreedFee() {
        if (this.feeData.locked || !this.feeData.visible) {
            return this.feeData.value
        } else if (this.feeData.type === 'percentAgreed') {
            return this.projectStore?.agreedFee * (this.feeData.percent / 100)
        } else {
            return (
                this.projectStore?.agreedFee * (this.feeData.percent / 100) -
                this.previousPhasesAgreedFee
            )
        }
    }
}

class projectFeeData {
    @observable project = null
    @observable feeData = {}
    @observable phasesFeeData = []
    constructor(project) {
        makeObservable(this)
        this.project = project
        this.feeData = project.feeData || { value: String(project.fee) }
        this.phasesFeeData = []
        project.phases
            .filter((ph) => !ph.isRootPhase)
            .forEach((ph, i) => {
                this.phasesFeeData.push(new phaseFeeData(ph, i, this))
            })
        this.phasesFeeData.forEach((ph) => ph.initFeeData())
    }
    @action.bound
    reorderPhasesFeeData(newList) {
        newList.forEach((ph, i) => {
            ph.setPosition(i)
        })
        this.phasesFeeData = newList
    }
    @computed
    get agreedFee() {
        return new Formula({
            formula: this.feeData.value,
        }).value
    }
    @action.bound
    setAgreedFee(value) {
        this.feeData.value = value
        this.project.update({ feeData: { ...this.feeData } })
    }
}

export default observer(({ project, modalId }) => {
    const [store, setStore] = useState(new projectFeeData(project))
    const labelWidth = '7em'

    const listContainerRef = useRef()
    return (
        <Modal
            modalId={modalId}
            heading="Project Fee Calculator"
            onSave={() => {
                project.update({ feeData: store.feeData })
                store.phasesFeeData
                    .filter((ph) => ph.feeData.visible)
                    .forEach((ph) =>
                        ph.phase.update({
                            feeData: ph.feeData,
                            fee: ph.agreedFee,
                        })
                    )
            }}
            saveLabel="Save Fees"
        >
            <RenderOnQueries
                queryIds={[
                    {
                        collection: 'projects',
                        fields: ['feeData'],
                        filters: [`id=="${project.id}"`],
                    },
                    {
                        collection: 'phases',
                        fields: ['feeData'],
                        filters: [`projectId=="${project.id}"`],
                    },
                ]}
            >
                <div className="delay-project-form" style={{ padding: '2em' }}>
                    <div className="text-right font-bold text-sm">
                        {FormatCurrency(store.agreedFee)}
                    </div>
                    <div className="flex justify-end mb-7">
                        <div
                            className="mr-2 font-bold text-[13px]"
                            style={{
                                flex: '0 0 auto',
                            }}
                        >
                            Agreed Fee
                        </div>
                        <div style={{ flex: '0 0 auto' }}>
                            <input
                                size="small"
                                value={store.feeData.value || ''}
                                className="w-[150px] text-right border border-[#ccc] py-0 px-2 h-[22px] text-sm"
                                onChange={(e) => {
                                    store.setAgreedFee(e.target.value)
                                }}
                            />
                        </div>
                    </div>
                    <div className="relative" ref={listContainerRef}>
                        <DraggableList
                            itemKey="id"
                            template={PhaseListItem}
                            list={store.phasesFeeData}
                            onMoveEnd={(newList) =>
                                store.reorderPhasesFeeData(newList)
                            }
                            container={() => listContainerRef.current}
                        />
                    </div>
                </div>
            </RenderOnQueries>
        </Modal>
    )
})

const PhaseListItem = observer(
    ({ item: ph, itemSelected, dragHandleProps }) => {
        return (
            <div
                className="flex items-center border-t border-[#eee] py-1.5 px-0"
                style={{
                    opacity: ph.feeData.visible ? 1 : 0.5,
                }}
            >
                <div className="flex flex-1 items-center gap-2.5">
                    <span {...dragHandleProps} style={{ color: 'grey' }}>
                        <i className="fa fa-ellipsis-v" />
                        <i className="fa fa-ellipsis-v" />
                    </span>
                    <Checkbox
                        value={ph.feeData.visible}
                        onChange={(visible) => ph.setVisible(visible)}
                    />
                    {ph.phase.title}
                </div>
                <div style={{ flex: '0 0 auto' }}>
                    <div style={{ textAlign: 'right', fontWeight: 'bold' }}>
                        {FormatCurrency(ph.agreedFee)}
                        <i
                            className={
                                ph.feeData.locked
                                    ? 'fa fa-lock'
                                    : 'fa fa-unlock'
                            }
                            style={{
                                marginLeft: '1rem',
                            }}
                            onClick={() =>
                                ph.feeData.visible &&
                                ph.setLocked(!ph.feeData.locked)
                            }
                        />
                    </div>
                    <input
                        size="small"
                        className="w-[50px] border border-[#ccc] py-0 px-[5px] text-sm text-right rounded-[6px]"
                        value={ph.feeData.percent || ''}
                        onChange={(e) => {
                            ph.setFeeDataPercent(e.target.value)
                        }}
                        disabled={ph.feeData.locked || !ph.feeData.visible}
                    />
                    <div style={{ display: 'inline-block' }}>
                        <Selector
                            value={ph.feeData.type}
                            onChange={(type) => {
                                ph.setFeeDataType(type)
                            }}
                            options={[
                                {
                                    value: 'percentAgreed',
                                    label: '% Agreed Fee',
                                },
                                {
                                    value: 'percentLessPrevious',
                                    label: '% Agreed Less Previous',
                                },
                            ]}
                            style={{
                                width: 160,
                                fontSize: '1rem',
                                padding: '0.25rem',
                            }}
                            isEditable={
                                !ph.feeData.locked && ph.feeData.visible
                            }
                            variant="secondary"
                            className="max-h-[22px] [&_span]:!text-xs"
                        />
                    </div>
                </div>
            </div>
        )
    }
)
