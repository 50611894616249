import { data } from 'jquery'
import { observer } from 'mobx-react'
import React, { Suspense, useCallback, useEffect } from 'react'
import PageHeader from '../Layout/PageHeader'
import ProjectCollection from '../../State/Collections/ProjectCollection'
import LayoutStore from '../../State/LayoutStore'
import StaffCollection from '../../State/Collections/StaffCollection'
import DeleteStaffModal from './DeleteStaffModal'
import { canDeleteStaff } from '../../State/Permissions/HasPermissions'
import SessionStore from '../../State/SessionStore'
import HeaderButton from '../../Components/ui/HeaderButton'

export default observer(({ extraContent, id, tabs, selectedTab }) => {
    const staff = StaffCollection.staffsById[id]
    if (!staff) return null
    return (
        <PageHeader
            heading={
                <div>
                    <div>{staff?.fullName || 'New Staff Member'}</div>
                </div>
            }
            rightButtons={
                <>
                    {canDeleteStaff(SessionStore.user) ? (
                        <HeaderButton
                            label="Delete"
                            onClick={() =>
                                LayoutStore.openModal(
                                    <DeleteStaffModal
                                        staff={staff}
                                        modalId="deleteStaff"
                                    />
                                )
                            }
                            icon={<i className="fa fa-trash" />}
                        />
                    ) : null}
                </>
            }
            extraContent={extraContent}
            tabs={tabs}
            selectedTab={selectedTab}
        />
    )
})
