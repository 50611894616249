import { FormLabel } from '@mui/material'
import { observer } from 'mobx-react'
import React, { useEffect, useState } from 'react'
import Table from '../../Components/Table'
import { FormRow, FormSection } from '../../forms'
import InvoiceCollection from '../../State/Collections/InvoiceCollection'
import ProjectCollection from '../../State/Collections/ProjectCollection'
import RoleCollection from '../../State/Collections/RoleCollection'
import RoleRateCollection from '../../State/Collections/RoleRateCollection'
import StaffCollection from '../../State/Collections/StaffCollection'
import Checkbox from '../../Components/Widgets/Checkbox'
import CellComponent from '../../Components/CellComponent'
import SessionStore from '../../State/SessionStore'
import {
    canViewRoles,
    canEditRoles,
    canEditStaffChargeOutRate,
    canEditStaffCostRate,
    canEditStaffOvertimeRate,
    canEditStaffPayRate,
    canViewStaffChargeOutRate,
    canViewStaffCostRate,
    canViewStaffOvertimeRate,
    canViewStaffPayRate,
} from '../../State/Permissions/HasPermissions'

const rateColumns = (role) => [
    {
        id: 'date',
        label: 'Starting From',
        width: 20,
        type: 'date',
        editable: (r) =>
            r.modelType === 'roleRate' && canEditRoles(SessionStore.user),
        value: (r) => r.date,
        onChange: (r) => (v) => r.update({ date: v }),
    },
    {
        label: 'Pay Rate',
        width: 20,
        type: 'currency',
        permissions: (r) => canViewStaffPayRate(SessionStore.user),
        editable: (r) =>
            !role.avgPayRate &&
            canEditRoles(SessionStore.user) &&
            canEditStaffPayRate(SessionStore.user),
        value: (p) =>
            role.avgPayRate ? role.getRateAtDate('pay', p.date) : p.payRate,
        component: (props) => {
            if (isFinite(props.value)) {
                return CellComponent['currency'](props)
            }
        },
        onChange: (r) => (v) => r.update({ payRate: v }),
    },
    {
        label: 'Overtime Rate',
        width: 20,
        type: 'currency',
        permissions: (r) => canViewStaffOvertimeRate(SessionStore.user),
        editable: (r) =>
            r.modelType === 'roleRate' &&
            !role.avgOvertimeRate &&
            canEditRoles(SessionStore.user) &&
            canEditStaffOvertimeRate(SessionStore.user),
        value: (p) =>
            role.avgOvertimeRate
                ? role.getRateAtDate('overtime', p.date)
                : p.overtimeRate,
        component: (props) => {
            if (isFinite(props.value)) {
                return CellComponent['currency'](props)
            }
        },
        onChange: (r) => (v) => r.update({ overtimeRate: v }),
    },
    {
        label: 'Cost Rate',
        width: 20,
        type: 'currency',
        permissions: (r) => canViewStaffCostRate(SessionStore.user),
        editable: (r) =>
            r.modelType === 'roleRate' &&
            !role.avgCostRate &&
            canEditRoles(SessionStore.user) &&
            canEditStaffCostRate(SessionStore.user),
        value: (p) =>
            role.avgCostRate ? role.getRateAtDate('cost', p.date) : p.costRate,
        component: (props) => {
            if (isFinite(props.value)) {
                return CellComponent['currency'](props)
            }
        },
        onChange: (r) => (v) => r.update({ costRate: v }),
    },
    {
        label: 'Charge Out Rate',
        width: 20,
        type: 'currency',
        permissions: (r) => canViewStaffChargeOutRate(SessionStore.user),
        editable: (r) =>
            r.modelType === 'roleRate' &&
            !role.avgChargeOutRate &&
            canEditRoles(SessionStore.user) &&
            canEditStaffChargeOutRate(SessionStore.user),
        value: (p) =>
            role.avgChargeOutRate
                ? role.getRateAtDate('chargeOut', p.date)
                : p.chargeOutRate,
        component: (props) => {
            if (isFinite(props.value)) {
                return CellComponent['currency'](props)
            }
        },
        onChange: (r) => (v) => r.update({ chargeOutRate: v }),
    },
    {
        label: '',
        width: 4,
        type: 'button',
        permissions: (r) => canEditRoles(SessionStore.user),
        editable: (r) => canEditRoles(SessionStore.user),
        value: (p) => <i className="fa fa-times" style={{ marginRight: 0 }} />,
        onClick: (r) => () => {
            r.update({ deletedAt: new Date() })
        },
    },
]

const avgRateColumns = [
    {
        width: 20,
        type: 'text',
        value: () => '',
        style: () => ({
            textAlign: 'right',
            justifyContent: 'flex-end',
        }),
    },
    {
        label: 'Pay Rate',
        width: 20,
        type: 'boolean',
        value: (r) => r.avgPayRate,
        permissions: (r) => canViewStaffPayRate(SessionStore.user),
        onChange: (r) => (v) => r.update({ avgPayRate: v }),
        editable: (r) =>
            !r.staff.some((s) => s.inheritPayRate) &&
            canEditRoles(SessionStore.user) &&
            canEditStaffPayRate(SessionStore.user),
        component: (props) => {
            return (
                <div style={{ display: 'flex' }}>
                    <div
                        style={{
                            opacity: props.editable ? 1 : 0.75,
                            flex: '1 1 auto',
                        }}
                    >
                        Average From Staff
                    </div>
                    <Checkbox
                        disabled={!props.editable}
                        {...props}
                        style={{
                            ...props.style,
                            flex: '0 0 auto',
                            width: '1em',
                            marginLeft: '0.5em',
                        }}
                    />
                </div>
            )
        },
        style: () => ({
            textAlign: 'right',
            justifyContent: 'flex-end',
        }),
    },
    {
        label: 'Overtime Rate',
        width: 20,
        type: 'boolean',
        value: (r) => r.avgOvertimeRate,
        onChange: (r) => (v) => r.update({ avgOvertimeRate: v }),
        permissions: (r) => canViewStaffOvertimeRate(SessionStore.user),
        editable: (r) =>
            !r.staff.some((s) => s.inheritOvertimeRate) &&
            canEditRoles(SessionStore.user) &&
            canEditStaffOvertimeRate(SessionStore.user),
        component: (props) => {
            return (
                <div style={{ display: 'flex' }}>
                    <div
                        style={{
                            opacity: props.editable ? 1 : 0.75,
                            flex: '1 1 auto',
                        }}
                    >
                        Average From Staff
                    </div>
                    <Checkbox
                        disabled={!props.editable}
                        {...props}
                        style={{
                            ...props.style,
                            flex: '0 0 auto',
                            width: '1em',
                            marginLeft: '0.5em',
                        }}
                    />
                </div>
            )
        },
        style: () => ({
            textAlign: 'right',
            justifyContent: 'flex-end',
        }),
    },
    {
        label: 'Cost Rate',
        width: 20,
        type: 'boolean',
        value: (r) => r.avgCostRate,
        onChange: (r) => (v) => r.update({ avgCostRate: v }),
        permissions: (r) => canViewStaffCostRate(SessionStore.user),
        editable: (r) =>
            !r.staff.some((s) => s.inheritCostRate) &&
            canEditRoles(SessionStore.user) &&
            canEditStaffCostRate(SessionStore.user),
        component: (props) => {
            return (
                <div style={{ display: 'flex' }}>
                    <div
                        style={{
                            opacity: props.editable ? 1 : 0.75,
                            flex: '1 1 auto',
                        }}
                    >
                        Average From Staff
                    </div>
                    <Checkbox
                        disabled={!props.editable}
                        {...props}
                        style={{
                            ...props.style,
                            flex: '0 0 auto',
                            width: '1em',
                            marginLeft: '0.5em',
                        }}
                    />
                </div>
            )
        },
        style: () => ({
            textAlign: 'right',
            justifyContent: 'flex-end',
        }),
    },
    {
        label: 'Charge Out Rate',
        width: 20,
        type: 'boolean',
        value: (r) => r.avgChargeOutRate,
        onChange: (r) => (v) => r.update({ avgChargeOutRate: v }),
        permissions: (r) => canViewStaffChargeOutRate(SessionStore.user),
        editable: (r) =>
            !r.staff.some((s) => s.inheritChargeOutRate) &&
            canEditRoles(SessionStore.user) &&
            canEditStaffChargeOutRate(SessionStore.user),
        component: (props) => {
            return (
                <div style={{ display: 'flex' }}>
                    <div
                        style={{
                            opacity: props.editable ? 1 : 0.75,
                            flex: '1 1 auto',
                        }}
                    >
                        Average From Staff
                    </div>
                    <Checkbox
                        disabled={!props.editable}
                        {...props}
                        style={{
                            ...props.style,
                            flex: '0 0 auto',
                            width: '1em',
                            marginLeft: '0.5em',
                        }}
                    />
                </div>
            )
        },
        style: () => ({
            textAlign: 'right',
            justifyContent: 'flex-end',
        }),
    },
    {
        width: 4,
        type: 'text',
        permissions: (r) => canEditRoles(SessionStore.user),
        value: () => '',
    },
]

export default observer(({ id }) => {
    const isEditable = canEditRoles(SessionStore.user)
    const role = RoleCollection.rolesById[id]
    if (!role) return null
    const memoizedColumns = React.useMemo(() => {
        return rateColumns(role).filter(
            (c) => !c.permissions || c.permissions()
        )
    }, [role])
    const [rows, setRows] = useState(
        role.rates.sort((a, b) => a?.date?.getTime() - b?.date?.getTime())
    )
    useEffect(() => {
        setRows(
            role.rates.sort((a, b) => a?.date?.getTime() - b?.date?.getTime())
        )
    }, [role.id])
    return (
        <div>
            <div className="form-horizontal">
                <FormSection>
                    <FormRow>
                        <FormLabel style={{ fontSize: '1em', width: '7em' }}>
                            Name:
                        </FormLabel>
                        <input
                            className="staff-member-component__first-name-input input-base ml-1"
                            type="text"
                            value={role.name || ''}
                            disabled={!isEditable}
                            onChange={(e) =>
                                role.update({ name: e.target.value })
                            }
                        />
                    </FormRow>
                </FormSection>
            </div>
            <div>
                <Table
                    columns={memoizedColumns}
                    rows={rows.filter((r) => !r.deletedAt)}
                    topRow={
                        <Table
                            columns={avgRateColumns.filter(
                                (c) => !c.permissions || c.permissions()
                            )}
                            rows={[role]}
                            showHeader={false}
                        />
                    }
                />
                {isEditable ? (
                    <button
                        className="btn btn-default plus-btn border-[#ccc]"
                        style={{ marginTop: '2em' }}
                        onClick={() => {
                            const rr = RoleRateCollection.add(
                                { roleId: role.id, date: new Date() },
                                { trackUpdates: true }
                            )
                            setRows([...rows, rr])
                        }}
                    >
                        + Add Rate
                    </button>
                ) : null}
            </div>
        </div>
    )
})
