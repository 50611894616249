import { createFileRoute } from '@tanstack/react-router'
import AccountingAuthPage from '../../Pages/AccountingAuthPage/AccountingAuthPage'
import PageBody from '../../Pages/Layout/PageBody'
import { canViewRoute } from '../../State/Permissions/HasPermissions'
import SessionStore from '../../State/SessionStore'
import PermissionDenied from '../../Pages/PermissionDenied'
import ErrorComponent from '../../Components/ErrorComponent'
import LoadingSpinner from '../../Components/LoadingSpinner'

export const Route = createFileRoute('/_private/accounting/auth')({
    beforeLoad: ({ params }) => {
        if (!canViewRoute(SessionStore.user, 'accountingAuth', params))
            throw new Error('Permission Denied')
    },
    errorComponent: ({ error, reset }) => {
        if (error.message === 'Permission Denied') return <PermissionDenied />
        return <ErrorComponent error={error} reset={reset} />
    },
    component: Wrapper,
    pendingComponent: LoadingSpinner,
})

function Wrapper() {
    return (
        <>
            <PageBody>
                <AccountingAuthPage
                    {...Route.useParams()}
                    {...Route.useSearch()}
                />
            </PageBody>
        </>
    )
}
