import { observer } from 'mobx-react'
import React, { useEffect, useState } from 'react'
import Table from '../../Components/Table'
import CostCentreCollection from '../../State/Collections/CostCentreCollection'
import { canEditCostCentres } from '../../State/Permissions/HasPermissions'
import SessionStore from '../../State/SessionStore'

const columns = [
    {
        id: 'name',
        label: 'Name',
        type: 'text',
        width: 25,
        editable: (row) => canEditCostCentres(SessionStore.user),
        value: (row) => {
            return row.name
        },
        onChange: (row) => (val) => row.update({ name: val }),
    },
    {
        id: 'isBillable',
        label: 'Billable',
        type: 'checkbox',
        width: 25,
        editable: (row) => canEditCostCentres(SessionStore.user),
        value: (row) => {
            return row.isBillable
        },
        onChange: (row) => (val) => row.update({ isBillable: val }),
    },
    {
        label: '',
        width: 4,
        type: 'button',
        permissions: (r) => canEditCostCentres(SessionStore.user),
        editable: (r) => canEditCostCentres(SessionStore.user),
        value: (p) => <i className="fa fa-times" style={{ marginRight: 0 }} />,
        onClick: (r) => () => {
            r.update({ deletedAt: new Date() })
        },
    },
]

export default observer(() => {
    const [costCentres, setCostCentres] = useState([
        ...(CostCentreCollection?.activeCostCentres?.sort((a, b) =>
            a.name?.localeCompare?.(b.name)
        ) || []),
    ])
    useEffect(() => {
        setCostCentres([
            ...(CostCentreCollection?.activeCostCentres?.sort((a, b) =>
                a.name?.localeCompare?.(b.name)
            ) || []),
        ])
    }, [CostCentreCollection?.activeCostCentres?.length])
    return (
        <>
            <Table
                columns={columns}
                rows={costCentres}
                newRowDirection={'asc'}
            />
        </>
    )
})
