import { observable, computed, action, makeObservable } from 'mobx'
import Collection from './Collection'
import ProjectRateModel from '../Models/ProjectRateModel'

class ProjectRateCollection extends Collection {
    constructor() {
        super({ collection: 'projectRates', modelClass: ProjectRateModel })
        this.addLookup('ratesByProjectId', 'manyByKey', {
            key: (t) => t.projectId,
            filter: (t) => !t.deletedAt,
        })
        this.addLookup('ratesByPhaseId', 'manyByKey', {
            key: (t) => t.phaseId,
            filter: (t) => !t.deletedAt,
        })
        makeObservable(this)
    }
    get projectRatesById() {
        return this.modelsById
    }
    get projectRates() {
        return this.models
    }
    @action.bound
    addProjectRate(data) {
        this.add(data)
    }
    @action.bound
    addProjectRates(data) {
        this.addMany(data)
    }
}

export default new ProjectRateCollection()
export const ProjectRateCollectionClass = ProjectRateCollection
