import cuid from 'cuid'
import { observable, computed, action, makeObservable } from 'mobx'
import PhaseCollection from '../Collections/PhaseCollection'
import ProjectCollection from '../Collections/ProjectCollection'
import RevenueTargetCollection from '../Collections/RevenueTargetCollection'
import Model from './Model'

class RevenueTargetModel extends Model {
    @observable projectId = null
    @observable phaseId = null

    @observable date = new Date()
    @observable amount = 0

    constructor(data, options) {
        super()
        makeObservable(this)
        this.collection = RevenueTargetCollection
        this.init(data, options)
    }
    @computed
    get project() {
        return ProjectCollection.modelsById[this.projectId]
    }
    @computed
    get phase() {
        return PhaseCollection.modelsById[this.phaseId]
    }
    @computed
    get amountAdjustedByLikelihood() {
        if (
            ['prospective', 'onHold'].includes(this.phase?.status) &&
            this.phase?.percentLikelihood
        ) {
            return this.amount * (this.phase?.percentLikelihood / 100)
        } else {
            return this.amount
        }
    }
}

export default RevenueTargetModel
