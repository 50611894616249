import _ from 'lodash'
import React from 'react'
import { queryFilters } from '../../Queries/queryFilters'
import { qf } from '../../Queries/queryFormatter'
import { addDays, differenceInBusinessDays, format, startOfDay } from 'date-fns'
import SessionStore from '../../State/SessionStore'
import {
    canViewCostCentres,
    canViewProjectInvoices,
    canViewRoles,
    canViewRoute,
    canViewStaffAllocations,
    canViewStaffChargeOutRate,
    canViewStaffCostRate,
    canViewStaffOvertimeRate,
    canViewStaffPayRate,
} from '../../State/Permissions/HasPermissions'
import OrganisationHolidayCollection from '../../State/Collections/OrganisationHolidayCollection'
import { Link } from '@tanstack/react-router'
import PermissionCollection from '../../State/Collections/PermissionCollection'

export const StaffReportColumns = (report) => ({
    name: {
        id: 'name',
        label: 'Name',
        type: 'text',
        width: 25,
        value: (row) => {
            return row.fullName
        },
        component: ({ value, stores }) => {
            const { row } = stores
            return (
                <div>
                    <Link to={`/staff/${row.rowObject.id}`}>{value}</Link>
                </div>
            )
        },
    },
    staff: {
        id: 'staff',
        label: 'Staff',
        type: 'staff',
        width: 25,
        visible: false,
        value: (row) => {
            return row
        },
    },
    lastName: {
        id: 'lastName',
        label: 'Last name',
        type: 'text',
        width: 15,
        value: (row) => row.lastName,
    },
    firstName: {
        id: 'firstName',
        label: 'First name',
        type: 'text',
        width: 15,
        value: (row) => row.firstName,
    },
    email: {
        id: 'email',
        label: 'Email',
        type: 'text',
        width: 25,
        value: (row) => row.email,
        queryFilters: ({ operator, value }) => {
            return queryFilters.text[operator]('email', value)
        },
        queries: (report) => [{ collection: 'staff', fields: ['email'] }],
    },
    role: {
        id: 'role',
        label: 'Role',
        type: 'role',
        width: 15,
        value: (row) => row.role,
        permissions: (row) => canViewRoles(SessionStore.user),
        queryFilters: ({ operator, value }) => {
            return queryFilters.singleId[operator]('roleId', value)
        },
        queries: (report) => [
            {
                collection: 'staff',
                fields: ['roleId'],
                chain: [
                    {
                        collection: 'roles',
                        join: {
                            staff: 'roleId',
                            roles: 'id',
                        },
                        fields: ['name'],
                    },
                ],
            },
        ],
    },
    costCentre: {
        id: 'costCentre',
        label: 'Cost Centre',
        type: 'costCentre',
        width: 15,
        value: (row) => row.costCentre,
        permissions: (row) => canViewCostCentres(SessionStore.user),
        queryFilters: ({ operator, value }) => {
            return queryFilters.singleId[operator]('costCentreId', value)
        },
        queries: (report) => [
            {
                collection: 'staff',
                fields: ['costCentreId'],
            },
        ],
    },
    permissionName: {
        id: 'permissionName',
        label: 'Permission Name',
        type: 'text',
        width: 20,
        value: (row) => {
            return PermissionCollection.permissionsById[row.permissionsId].name
        },
        permissions: (row) =>
            canViewRoute(SessionStore.user, `staff-permissions`),
        queries: (report) => [
            {
                collection: 'staff',
                fields: ['permissionsId'],
            },
            {
                collection: 'permissions',
                fields: ['name'],
            },
        ],
    },
    isArchived: {
        id: 'isArchived',
        label: 'Is Archived',
        type: 'boolean',
        width: 15,
        value: (row) => row.isArchived,
        queryFilters: ({ operator, value }) => {
            return queryFilters.boolean[operator]('isArchived', value)
        },
        queries: (report) => [{ collection: 'staff', fields: ['isArchived'] }],
    },
    isActive: {
        id: 'isActive',
        label: 'Is Active',
        type: 'boolean',
        width: 15,
        value: (row) => !row.isArchived,
        queryFilters: ({ operator, value }) => {
            return queryFilters.boolean[operator]('not(isArchived)', value)
        },
        queries: (report) => [
            { collection: 'staff', fields: [['isActive', 'not(isArchived)']] },
        ],
    },
    payRate: {
        id: 'payRate',
        label: 'Pay Rate',
        type: 'number',
        width: 15,
        value: (row) => row.payRate,
        permissions: (row) => canViewStaffPayRate(SessionStore.user),
        queryFilters: ({ operator, value }) => {
            return queryFilters.number[operator]('payRate', value)
        },
        queries: (report) => [
            {
                collection: 'staff',
                fields: [
                    'roleId',
                    'inheritPayRate',
                    'inheritOvertimeRate',
                    'inheritCostRate',
                    'inheritChargeOutRate',
                ],
                chain: [
                    {
                        collection: 'roles',
                        join: {
                            staff: 'roleId',
                            roles: 'id',
                        },
                    },
                    {
                        collection: 'staffRates',
                        join: {
                            staff: 'id',
                            staffRates: 'staffId',
                        },
                        fields: ['staffId', 'date', 'payRate', 'overtimeRate'],
                    },
                    {
                        collection: 'roleRates',
                        join: {
                            staff: 'roleId',
                            roleRates: 'roleId',
                        },
                        fields: ['roleId', 'date', 'payRate', 'overtimeRate'],
                    },
                ],
            },
        ],
    },
    overtimeRate: {
        id: 'overtimeRate',
        label: 'Overtime Rate',
        type: 'number',
        width: 15,
        value: (row) => row.overtimeRate,
        permissions: (row) => canViewStaffOvertimeRate(SessionStore.user),
        queryFilters: ({ operator, value }) => {
            return queryFilters.number[operator]('overtimeRate', value)
        },
        queries: (report) => [
            {
                collection: 'staff',
                fields: [
                    'roleId',
                    'inheritPayRate',
                    'inheritOvertimeRate',
                    'inheritCostRate',
                    'inheritChargeOutRate',
                ],
                chain: [
                    {
                        collection: 'roles',
                        join: {
                            staff: 'roleId',
                            roles: 'id',
                        },
                    },
                    {
                        collection: 'staffRates',
                        join: {
                            staff: 'id',
                            staffRates: 'staffId',
                        },
                        fields: ['staffId', 'date', 'overtimeRate'],
                    },
                    {
                        collection: 'roleRates',
                        join: {
                            staff: 'roleId',
                            roleRates: 'roleId',
                        },
                        fields: ['roleId', 'date', 'overtimeRate'],
                    },
                ],
            },
        ],
    },
    costRate: {
        id: 'costRate',
        label: 'Cost Rate',
        type: 'number',
        width: 15,
        value: (row) => row.costRate,
        permissions: (row) => canViewStaffCostRate(SessionStore.user),
        queryFilters: ({ operator, value }) => {
            return queryFilters.number[operator]('costRate', value)
        },

        queries: (report) => [
            {
                collection: 'staff',
                fields: [
                    'roleId',
                    'inheritPayRate',
                    'inheritOvertimeRate',
                    'inheritCostRate',
                    'inheritChargeOutRate',
                ],
                chain: [
                    {
                        collection: 'roles',
                        join: {
                            staff: 'roleId',
                            roles: 'id',
                        },
                    },
                    {
                        collection: 'staffRates',
                        join: {
                            staff: 'id',
                            staffRates: 'staffId',
                        },
                        fields: ['staffId', 'date', 'costRate'],
                    },
                    {
                        collection: 'roleRates',
                        join: {
                            staff: 'roleId',
                            roleRates: 'roleId',
                        },
                        fields: ['roleId', 'date', 'costRate'],
                    },
                ],
            },
        ],
    },
    chargeOutRate: {
        id: 'chargeOutRate',
        label: 'Charge Out Rate',
        type: 'number',
        width: 15,
        value: (row) => row.chargeOutRate,
        permissions: (row) => canViewStaffChargeOutRate(SessionStore.user),
        queryFilters: ({ operator, value }) => {
            return queryFilters.number[operator]('chargeOutRate', value)
        },
        queries: (report) => [
            {
                collection: 'staff',
                fields: [
                    'roleId',
                    'inheritPayRate',
                    'inheritOvertimeRate',
                    'inheritCostRate',
                    'inheritChargeOutRate',
                ],
                chain: [
                    {
                        collection: 'roles',
                        join: {
                            staff: 'roleId',
                            roles: 'id',
                        },
                    },
                    {
                        collection: 'staffRates',
                        join: {
                            staff: 'id',
                            staffRates: 'staffId',
                        },
                        fields: ['staffId', 'date', 'chargeOutRate'],
                    },
                    {
                        collection: 'roleRates',
                        join: {
                            staff: 'roleId',
                            roleRates: 'roleId',
                        },
                        fields: ['roleId', 'date', 'chargeOutRate'],
                    },
                ],
            },
        ],
    },
    weeklyAvailability: {
        id: 'weeklyAvailability',
        label: 'Weekly Availability',
        type: 'number',
        width: 15,
        value: (row) => row.availability / 60,
        queryFilters: ({ operator, value }) => {
            return queryFilters.number[operator](
                'weeklyAvailability / 60',
                value
            )
        },
        queries: (report) => [
            {
                collection: 'staff',
                fields: ['roleId'],
                chain: [
                    {
                        collection: 'staffRates',
                        join: {
                            staff: 'id',
                            staffRates: 'staffId',
                        },
                        fields: ['staffId', 'date', 'weeklyAvailability'],
                    },
                ],
            },
        ],
    },
    allocatedHours: {
        id: 'allocatedHours',
        label: 'Allocated hours',
        type: 'number',
        width: 15,
        value: (row) => row.allocatedHours,
        permissions: (row) => canViewStaffAllocations(SessionStore.user),
        queryFilters: ({ operator, value }) => {
            return queryFilters.number[operator]('allocatedHours', value)
        },
        queries: (report) => [
            {
                collection: 'staff',
                fields: [['allocatedHours', 'monthlyAllocations.hours']],
                subQueries: [
                    {
                        collection: 'monthlyAllocations',
                        join: 'id == monthlyAllocations.staffId',
                        groupBy: ['staffId'],
                        fields: [['hours', 'sum(numMinutes::numeric / 60)']],
                        filters: [
                            ...(isFinite(report.dateRange?.[0])
                                ? [
                                      `month >= ${qf(
                                          format(report.dateRange[0], 'yyyy-MM')
                                      )} AND month <= ${qf(
                                          format(report.dateRange[1], 'yyyy-MM')
                                      )}`,
                                  ]
                                : []),
                        ],
                    },
                ],
            },
        ],
    },
    availableHours: {
        id: 'availableHours',
        label: 'Available hours',
        type: 'number',
        width: 15,
        value: (row) => {
            const holidays = _.sum(
                OrganisationHolidayCollection.organisationHolidays
                    .filter((h) => {
                        return (
                            h.startDate <= report.dateRange[1] &&
                            h.endDate >= report.dateRange[0]
                        )
                    })
                    .map((h) => {
                        const startDate = Math.max(
                            h.startDate,
                            report.dateRange[0]
                        )
                        const endDate = addDays(
                            startOfDay(
                                Math.min(h.endDate, report.dateRange[1])
                            ),
                            1
                        )
                        return differenceInBusinessDays(endDate, startDate)
                    })
            )
            return (
                (row.availability / 60 / 5) *
                (differenceInBusinessDays(
                    addDays(report.dateRange[1], 1),
                    report.dateRange[0]
                ) -
                    holidays)
            )
        },
        queryFilters: ({ operator, value }) => {
            return queryFilters.number[operator](
                `(weeklyAvailability / 60 / 5) * ${differenceInBusinessDays(
                    addDays(report.dateRange[1], 1),
                    report.dateRange[0]
                )}`,
                value
            )
        },
        queries: (report) => [
            {
                collection: 'staff',
                fields: [
                    [
                        'weeklyAvailability',
                        'latestStaffRate.weeklyAvailability',
                    ],
                ],
                subQueries: [
                    {
                        collection: 'staffRates',
                        label: 'latestStaffRate',
                        join: 'id == latestStaffRate.staffId',
                        fields: [
                            ['weeklyAvailability', 'max(weeklyAvailability)'],
                        ],
                        groupBy: ['date', 'staffId'],
                        sortBy: [['date', 'desc']],
                        limit: 1,
                    },
                ],
            },
            {
                collection: 'organisationHolidays',
                fields: ['name', 'startDate', 'endDate'],
                filters: [
                    `startDate <= ${qf(report.dateRange[1])}`,
                    `endDate >= ${qf(report.dateRange[0])}`,
                ],
            },
        ],
    },
    recordedHours: {
        id: 'recordedHours',
        label: 'Recorded hours',
        type: 'number',
        width: 15,
        value: (row) => row.recordedHours,
        queryFilters: ({ operator, value }) => {
            return queryFilters.number[operator]('recordedHours', value)
        },
        queries: (report) => [
            {
                collection: 'staff',
                fields: [['recordedHours', 'timeEntries.recordedHours']],
                subQueries: [
                    {
                        collection: 'timeEntries',
                        join: 'id == timeEntries.staffId',
                        groupBy: ['staffId'],
                        fields: [
                            ['recordedHours', 'sum(numMinutes::numeric / 60)'],
                        ],
                        filters: [
                            ...(isFinite(report.dateRange?.[0])
                                ? [
                                      `date >= ${qf(
                                          report.dateRange[0]
                                      )} AND date <= ${qf(
                                          report.dateRange[1]
                                      )}`,
                                  ]
                                : []),
                        ],
                    },
                ],
            },
        ],
    },
    billableHours: {
        id: 'billableHours',
        label: 'Billable Hours',
        type: 'number',
        width: 15,
        value: (row) => row.billableHours,
        queryFilters: ({ operator, value }) => {
            return queryFilters.number[operator]('billableHours', value)
        },
        queries: (report) => [
            {
                collection: 'staff',
                fields: [['billableHours', 'timeEntries.billableHours']],
                subQueries: [
                    {
                        collection: 'timeEntries',
                        join: 'id == timeEntries.staffId',
                        groupBy: ['staffId'],
                        fields: [
                            [
                                'billableHours',
                                'sum(isBillable ? numMinutes::numeric / 60 : 0)',
                            ],
                        ],
                        filters: [
                            ...(isFinite(report.dateRange?.[0])
                                ? [
                                      `date >= ${qf(
                                          report.dateRange[0]
                                      )} AND date <= ${qf(
                                          report.dateRange[1]
                                      )}`,
                                  ]
                                : []),
                        ],
                    },
                ],
            },
        ],
    },
    nonBillableHours: {
        id: 'nonBillableHours',
        label: 'Non Billable Hours',
        type: 'number',
        width: 15,
        value: (row) => row.nonBillableHours,
        queryFilters: ({ operator, value }) => {
            return queryFilters.number[operator]('nonBillableHours', value)
        },
        queries: (report) => [
            {
                collection: 'staff',
                fields: [['nonBillableHours', 'timeEntries.nonBillableHours']],
                subQueries: [
                    {
                        collection: 'timeEntries',
                        join: 'id == timeEntries.staffId',
                        groupBy: ['staffId'],
                        fields: [
                            [
                                'nonBillableHours',
                                'sum(not(isBillable) ? numMinutes::numeric / 60 : 0)',
                            ],
                        ],
                        filters: [
                            ...(isFinite(report.dateRange?.[0])
                                ? [
                                      `date >= ${qf(
                                          report.dateRange[0]
                                      )} AND date <= ${qf(
                                          report.dateRange[1]
                                      )}`,
                                  ]
                                : []),
                        ],
                    },
                ],
            },
        ],
    },
    percentBillableHours: {
        id: 'percentBillableHours',
        label: 'Percent Billable Hours',
        type: 'percent',
        width: 15,
        value: (row) => {
            return (
                StaffReportColumns(report).billableHours.value(row) /
                StaffReportColumns(report).recordedHours.value(row)
            )
        },
        queryFilters: ({ operator, value }) => {
            return queryFilters.number[operator](
                'billableHours / recordedHours',
                value
            )
        },
        queries: (report) => [
            ...StaffReportColumns(report).billableHours.queries(report),
            ...StaffReportColumns(report).recordedHours.queries(report),
        ],
    },
    overtimeHours: {
        id: 'overtimeHours',
        label: 'Overtime hours',
        type: 'number',
        width: 15,
        value: (row) => row.overtimeHours,
        queryFilters: ({ operator, value }) => {
            return queryFilters.number[operator]('overtimeHours', value)
        },
        queries: (report) => [
            {
                collection: 'staff',
                fields: [['overtimeHours', 'timeEntries.overtimeHours']],
                subQueries: [
                    {
                        collection: 'timeEntries',
                        join: 'id == timeEntries.staffId',
                        groupBy: ['staffId'],
                        fields: [
                            [
                                'overtimeHours',
                                'isOvertime ? sum(numMinutes::numeric / 60) : 0',
                            ],
                        ],
                        filters: [
                            ...(isFinite(report.dateRange?.[0])
                                ? [
                                      `date >= ${qf(
                                          report.dateRange[0]
                                      )} AND date <= ${qf(
                                          report.dateRange[1]
                                      )}`,
                                  ]
                                : []),
                        ],
                    },
                ],
            },
        ],
    },
    invoicedHours: {
        id: 'invoicedHours',
        label: 'Invoiced Hours',
        type: 'number',
        width: 15,
        value: (row) => {
            return row.invoicedHours
        },
        queryFilters: ({ operator, value }) => {
            return queryFilters.number[operator]('invoicedHours', value)
        },
        queries: (report) => [
            {
                collection: 'staff',
                fields: [['invoicedHours', 'timeEntries.invoicedHours']],
                subQueries: [
                    {
                        collection: 'timeEntries',
                        join: 'id == timeEntries.staffId',
                        groupBy: ['staffId'],
                        fields: [
                            [
                                'invoicedHours',
                                'sum(beenInvoiced ? numMinutes::numeric / 60 : 0)',
                            ],
                        ],
                        filters: [
                            ...(isFinite(report.dateRange?.[0])
                                ? [
                                      `date >= ${qf(
                                          report.dateRange[0]
                                      )} AND date <= ${qf(
                                          report.dateRange[1]
                                      )}`,
                                  ]
                                : []),
                        ],
                    },
                ],
            },
        ],
    },
    pay: {
        id: 'pay',
        label: 'Pay',
        type: 'currency',
        width: 15,
        value: (row) => row.pay,
        permissions: (row) => canViewStaffPayRate(SessionStore.user),
        queryFilters: ({ operator, value }) => {
            return queryFilters.number[operator]('pay', value)
        },
        queries: (report) => [
            {
                collection: 'staff',
                fields: [['pay', 'timeEntries.pay']],
                subQueries: [
                    {
                        collection: 'timeEntries',
                        join: 'id == timeEntries.staffId',
                        groupBy: ['staffId'],
                        fields: [['pay', 'sum(pay::numeric / 100)']],
                        filters: [
                            ...(isFinite(report.dateRange?.[0])
                                ? [
                                      `date >= ${qf(
                                          report.dateRange[0]
                                      )} AND date <= ${qf(
                                          report.dateRange[1]
                                      )}`,
                                  ]
                                : []),
                        ],
                    },
                ],
            },
        ],
    },
    cost: {
        id: 'cost',
        label: 'Cost',
        type: 'currency',
        width: 15,
        value: (row) => row.cost,
        permissions: (row) => canViewStaffCostRate(SessionStore.user),
        queryFilters: ({ operator, value }) => {
            return queryFilters.number[operator]('cost', value)
        },
        queries: (report) => [
            {
                collection: 'staff',
                fields: [['cost', 'timeEntries.cost']],
                subQueries: [
                    {
                        collection: 'timeEntries',
                        join: 'id == timeEntries.staffId',
                        groupBy: ['staffId'],
                        fields: [['cost', 'sum(cost::numeric / 100)']],
                        filters: [
                            ...(isFinite(report.dateRange?.[0])
                                ? [
                                      `date >= ${qf(
                                          report.dateRange[0]
                                      )} AND date <= ${qf(
                                          report.dateRange[1]
                                      )}`,
                                  ]
                                : []),
                        ],
                    },
                ],
            },
        ],
    },
    chargeOut: {
        id: 'chargeOut',
        label: 'Charge Out',
        type: 'currency',
        width: 15,
        value: (row) => row.chargeOut,
        permissions: (row) => canViewStaffChargeOutRate(SessionStore.user),
        queryFilters: ({ operator, value }) => {
            return queryFilters.number[operator]('chargeOut', value)
        },
        queries: (report) => [
            {
                collection: 'staff',
                fields: [['chargeOut', 'timeEntries.chargeOut']],
                subQueries: [
                    {
                        collection: 'timeEntries',
                        join: 'id == timeEntries.staffId',
                        groupBy: ['staffId'],
                        fields: [
                            ['chargeOut', 'sum(chargeOut::numeric / 100)'],
                        ],
                        filters: [
                            ...(isFinite(report.dateRange?.[0])
                                ? [
                                      `date >= ${qf(
                                          report.dateRange[0]
                                      )} AND date <= ${qf(
                                          report.dateRange[1]
                                      )}`,
                                  ]
                                : []),
                        ],
                    },
                ],
            },
        ],
    },
    revenueEarned: {
        id: 'revenueEarned',
        label: 'Revenue Earned',
        type: 'currency',
        width: 15,
        value: (row) => {
            return row.revenueEarned
        },
        permissions: (row) =>
            canViewStaffChargeOutRate(SessionStore.user) &&
            canViewProjectInvoices(SessionStore.user),
        queryFilters: ({ operator, value }) => {
            return queryFilters.number[operator]('revenueEarned', value)
        },
        queries: (report) => [
            {
                collection: 'staff',
                fields: [
                    ['revenueEarned', 'allTimeEntries.revenueEarned'],
                    ['revenueHours', 'allTimeEntries.revenueHours'],
                ],
                subQueries: [
                    {
                        label: 'allTimeEntries',
                        collection: 'timeEntries',
                        join: 'id == allTimeEntries.staffId',
                        groupBy: ['staffId'],
                        fields: [
                            [
                                'revenueEarned',
                                'sum(isBillable and timeEntryPhases.chargeOut > 0 ? ((chargeOut::numeric / 100) / timeEntryPhases.chargeOut) * ((timeEntryPhases.revenue != null ? timeEntryPhases.revenue : 0)+(timeEntryPhases.clRevenue != null ? timeEntryPhases.clRevenue : 0)) : 0)',
                            ],
                            [
                                'revenueHours',
                                'sum(isBillable and timeEntryPhases.chargeOut > 0 and (timeEntryPhases.revenue > 0 or timeEntryPhases.clRevenue > 0) ? (numMinutes::numeric / 60) : 0)',
                            ],
                        ],
                        subQueries: [
                            {
                                label: 'timeEntryPhases',
                                collection: 'phases',
                                join: 'phaseId == timeEntryPhases.id',
                                groupBy: ['id'],
                                fields: [
                                    [
                                        'chargeOut',
                                        'sum(phaseTimeEntries.chargeOut)',
                                    ],
                                    [
                                        'revenue',
                                        'sum(phaseInvoiceLineItems.revenue)',
                                    ],
                                    [
                                        'clRevenue',
                                        'sum(phaseChangeLogItems.revenue)',
                                    ],
                                ],
                                subQueries: [
                                    {
                                        label: 'phaseTimeEntries',
                                        collection: 'timeEntries',
                                        join: 'id == phaseTimeEntries.phaseId',
                                        groupBy: ['phaseId'],
                                        fields: [
                                            [
                                                'chargeOut',
                                                'sum(isBillable ? chargeOut::numeric / 100 : 0)',
                                            ],
                                        ],
                                    },
                                    {
                                        label: 'phaseInvoiceLineItems',
                                        collection: 'invoiceLineItems',
                                        join: 'id == phaseInvoiceLineItems.phaseId',
                                        groupBy: ['phaseId'],
                                        fields: [['revenue', 'sum(amount)']],
                                        filters: [
                                            ...(isFinite(report.dateRange?.[0])
                                                ? [
                                                      `invoice.${
                                                          SessionStore.settings
                                                              .reportInvoiceDateType
                                                      } >= ${qf(
                                                          report.dateRange[0]
                                                      )} AND invoice.${
                                                          SessionStore.settings
                                                              .reportInvoiceDateType
                                                      } <= ${qf(
                                                          report.dateRange[1]
                                                      )}`,
                                                  ]
                                                : []),
                                        ],
                                    },
                                    {
                                        label: 'phaseChangeLogItems',
                                        collection: 'changeLogItems',
                                        join: 'id == phaseChangeLogItems.phaseId',
                                        groupBy: ['phaseId'],
                                        fields: [['revenue', 'sum(revenue)']],
                                        filters: [
                                            ...(isFinite(report.dateRange?.[0])
                                                ? [
                                                      `date >= ${qf(
                                                          report.dateRange[0]
                                                      )} AND date <= ${qf(
                                                          report.dateRange[1]
                                                      )}`,
                                                  ]
                                                : []),
                                        ],
                                    },
                                ],
                            },
                        ],
                    },
                ],
            },
        ],
    },
    revenueEarnedPerHour: {
        id: 'revenueEarnedPerHour',
        label: 'Revenue Earned Per Hour',
        type: 'currency',
        width: 15,
        value: (row) => {
            return row.revenueHours ? row.revenueEarned / row.revenueHours : 0
        },
        permissions: (row) =>
            canViewStaffChargeOutRate(SessionStore.user) &&
            canViewProjectInvoices(SessionStore.user),

        queryFilters: ({ operator, value }) => {
            return queryFilters.number[operator](
                'revenueHours > 0 ? revenueEarned / revenueHours : 0',
                value
            )
        },
        queries: (report) => [
            ...StaffReportColumns(report).revenueEarned.queries(report),
        ],
    },
    projects: {
        id: 'projects',
        label: 'Projects',
        type: 'project',
        width: 15,
        value: (row) => {
            return [...new Set(row.budgets.map((b) => b.project))]
        },
        queryFilters: ({ operator, value }) =>
            queryFilters.manyIds[operator]('projectIds', value),
        queries: (report) => [
            {
                collection: 'staff',
                fields: [['projectIds', 'budgetedHours.projectIds']],
                subQueries: [
                    {
                        collection: 'budgetedHours',
                        join: 'id == budgetedHours.staffId',
                        groupBy: ['staffId'],
                        fields: [['projectIds', 'arr(projectId)']],
                    },
                ],
                chain: [
                    {
                        collection: 'budgetedHours',
                        join: {
                            staff: 'id',
                            budgetedHours: 'staffId',
                        },
                        fields: ['projectId', 'staffId'],
                    },
                ],
            },
        ],
    },
})
