import { NumberParser } from '@internationalized/number'
import { getUserLocale } from 'get-user-locale'
import isoLangCodes from 'iso-lang-codes'
import SessionStore from '../../State/SessionStore'
import { constantCase } from 'change-case'

export const ParsePercent = (valueString, decimals = 2) => {
    const parser = new NumberParser(
        isoLangCodes.findCountryLocales(
            constantCase(SessionStore?.organisationCountry || 'us')
        )[0],
        {
            style: 'percent',
            maximumFractionDigits: decimals,
        }
    )
    return parser.parse(String(valueString))
}
