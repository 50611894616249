import { NumberParser } from '@internationalized/number'
import { getUserLocale } from 'get-user-locale'
import LocaleCurrency from 'locale-currency'
import isoLangCodes from 'iso-lang-codes'
import SessionStore from '../../State/SessionStore'
import { constantCase } from 'change-case'
import { evaluate } from 'mathjs'

export const ParseCurrency = (
    valueString,
    {
        currency = LocaleCurrency.getCurrency(
            isoLangCodes.findCountryLocales(
                constantCase(SessionStore?.organisationCountry || 'us')
            )[0]
        ),
    } = {}
) => {
    if ([null, undefined, ''].includes(valueString)) return valueString
    try {
        const noSymbols = valueString.replace(/,/g, '').replace(/\p{Sc}/gu, '')
        return evaluate(noSymbols)
    } catch (e) {
        //if math.eval fails just pass string
        return valueString
    }
    // currency = 'AUD'
    // const parser = new NumberParser(
    //     isoLangCodes.findCountryLocales(
    //         constantCase(SessionStore?.organisationCountry || 'us')
    //     )[0],
    //     {
    //         style: 'currency',
    //         currency: currency || 'USD',
    //     }
    // )
    // return parser.parse(String(valueString))
}
