import { observer } from 'mobx-react'
import React from 'react'
import Table from '../../Components/Table'
import RoleCollection from '../../State/Collections/RoleCollection'
import StaffRoleListStore from './StaffRoleListStore'
import { Link } from '@tanstack/react-router'

const columns = [
    {
        id: 'name',
        label: 'Name',
        type: 'text',
        width: 25,
        value: (row) => {
            return row.name
        },
        component: ({ value, stores }) => {
            const { row } = stores
            return (
                <div>
                    <Link to={`/staff-roles/${row.rowObject.id}`}>{value}</Link>
                </div>
            )
        },
    },
]

export default observer(() => {
    return (
        <>
            <Table
                key={'role-table'}
                columns={columns}
                rows={RoleCollection.roles.filter(
                    (r) =>
                        (StaffRoleListStore.showArchived || !r.isArchived) &&
                        !r.deletedAt
                )}
            />
        </>
    )
})
