import { NumberParser } from '@internationalized/number'
import { getUserLocale } from 'get-user-locale'
import isoLangCodes from 'iso-lang-codes'
import SessionStore from '../../State/SessionStore'
import { constantCase } from 'change-case'
import { evaluate } from 'mathjs'

export const ParseNumber = (valueString) => {
    if ([null, undefined, ''].includes(valueString)) return valueString
    try {
        const noCommas = valueString.replace(/,/g, '')
        return evaluate(noCommas)
    } catch (e) {
        //if math.eval fails just pass string
        return valueString
    }
    // const parser = new NumberParser(
    //     isoLangCodes.findCountryLocales(
    //         constantCase(SessionStore?.organisationCountry || 'us')
    //     )[0],
    //     {
    //         style: 'decimal',
    //     }
    // )
    // return parser.parse(String(valueString))
}
