import { observer } from 'mobx-react'
import React, { useEffect } from 'react'
import CostCentreCollection from '../../State/Collections/CostCentreCollection'

export default observer(({ id }) => {
    const isAtLeastPM = true
    const isAtLeastAdmin = true
    const costCentre = CostCentreCollection.costCentresById[id]
    if (!costCentre) return null
    return <div>{costCentre.name}</div>
})
