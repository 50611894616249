import classNames from 'classnames'
import { observer } from 'mobx-react'
import React, { Suspense, useEffect, useRef, useState } from 'react'
import { makeRequest } from '../Queries/makeRequest'

export default observer(({ redirect }) => {
    useEffect(() => {
        makeRequest({
            path: '/feedback-auth',
            method: 'GET',
        }).then((res) => {
            const token = res.data.token
            window.location.href = `${redirect}?ssoToken=${token}`
        })
    }, [])
    return (
        <div
            className="flex flex-align-items-center flex-justify-content-center"
            style={{
                textAlign: 'center',
                alignSelf: 'center',
            }}
        >
            <div>Authenticating...</div>
        </div>
    )
})
