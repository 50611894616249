import { observable, computed, action, makeObservable } from 'mobx'
import Collection from './Collection'
import RevenueRowModel from '../Models/RevenueRowModel'

class RevenueRowCollection extends Collection {
    constructor() {
        super({
            collection: 'revenueRows',
            modelClass: RevenueRowModel,
        })
        this.addLookup('rowsByPhaseId', 'manyByKey', {
            key: (t) => t.phaseId,
            filter: (t) => !t.deletedAt,
        })
        this.addLookup('rowsByProjectId', 'manyByKey', {
            key: (t) => t.projectId,
            filter: (t) => !t.deletedAt,
        })
        this.addLookup('rowsByStatus', 'manyByKey', {
            key: (t) => t.status,
            filter: (t) => !t.deletedAt,
        })
        this.addLookup('leafRows', 'list', {
            filter: (m) => {
                return m.isLeaf
            },
        })
        makeObservable(this)
    }
    get rowsById() {
        return this.modelsById
    }
    get rows() {
        return this.models
    }
    @action.bound
    addRow(data) {
        return this.add(data)
    }
    @action.bound
    addRows(data) {
        return this.addMany(data)
    }
}

export default new RevenueRowCollection()
export const RevenueRowCollectionClass = RevenueRowCollection
