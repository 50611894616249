import React, { useEffect, useState } from 'react'
import { observer } from 'mobx-react'
import Modal from '../../Components/Modal'
import { ProjectSelector } from '../../Components/Selector'
import Inputs from '../../Components/Inputs'
import DateValue from '../../Components/Widgets/DateValue'
import RenderOnQueries from '../Layout/RenderOnQueries'
import InvoiceCollection from '../../State/Collections/InvoiceCollection'
import ProjectCollection from '../../State/Collections/ProjectCollection'
import { canCreateInvoiceForProject } from '../../State/Permissions/HasPermissions'
import SessionStore from '../../State/SessionStore'
import _ from 'lodash'
import { fNumOr0, qf } from '../../Queries/queryFormatter'
import { min } from 'underscore'
import FetchStore from '../../Queries/FetchStore'
import { subMonths, startOfYear, set } from 'date-fns'
import fetchData from '../../Queries/fetchData'
import { useNavigate } from '@tanstack/react-router'

export default observer(({ modalId }) => {
    const navigate = useNavigate()
    const [project, setProject] = useState(null)
    const [startDate, setStartDate] = useState(null)
    const [endDate, setEndDate] = useState(new Date())
    const [creating, setCreating] = useState(false)
    const earliestInvoice = _.min(
        InvoiceCollection.invoices.map((inv) => inv.updatedAt)
    )
    return (
        <Modal
            modalId={modalId}
            heading="New Invoice"
            canSave={() => project && startDate && endDate}
            saveLabel={creating ? 'Creating...' : 'Create Invoice'}
            onSave={async () => {
                setCreating(true)
                await Promise.all([
                    fetchData({
                        collection: 'organisations',
                        fields: [
                            ['invNum', 'invoices.invNum'],
                            ['invNumYear', 'invoices.invNumYear'],
                            ['invNumProj', 'invoices.invNumProj'],
                        ],
                        subQueries: [
                            {
                                collection: 'invoices',
                                join: `id == invoices.organisationId`,
                                groupBy: ['organisationId'],
                                fields: [
                                    ['invNum', 'sum(1)'],
                                    [
                                        'invNumYear',
                                        `sum(issueDate >= ${qf(
                                            startOfYear(new Date())
                                        )} ? 1 : 0)`,
                                    ],
                                    [
                                        'invNumProj',
                                        `sum(projectId == "${project.id}" ? 1 : 0)`,
                                    ],
                                ],
                            },
                        ],
                    }),
                    fetchData({
                        collection: 'projects',
                        fields: ['contactId', 'primaryContactId'],
                        filters: [`id == "${project.id}"`],
                    }),
                    fetchData({
                        collection: 'phases',
                        fields: [
                            'projectId',
                            'jobNumber',
                            'name',
                            'fee',
                            'position',
                            'isRootPhase',
                            [
                                'previouslyBilled',
                                `${fNumOr0(
                                    'invoiceLineItems.amount'
                                )} + ${fNumOr0('changeLogItems.revenue')}`,
                            ],
                        ],
                        filters: [`projectId == "${project.id}"`],
                        subQueries: [
                            {
                                collection: 'invoiceLineItems',
                                join: `id == invoiceLineItems.phaseId`,
                                groupBy: ['phaseId'],
                                fields: [['amount', 'sum(amount)']],
                                filters: [`billingType == "agreedFee"`],
                            },
                            {
                                collection: 'changeLogItems',
                                join: `id == changeLogItems.phaseId`,
                                groupBy: ['phaseId'],
                                fields: [['revenue', 'sum(revenue)']],
                            },
                        ],
                    }),
                    fetchData({
                        collection: 'projectExpenses',
                        fields: [
                            'projectId',
                            'phaseId',
                            'name',
                            'cost',
                            'billable',
                            'endDate',
                            ['previouslyBilled', 'invoiceLineItems.amount'],
                        ],
                        filters: [`projectId == "${project.id}"`],
                        subQueries: [
                            {
                                collection: 'invoiceLineItems',
                                join: `id == invoiceLineItems.expenseId`,
                                groupBy: ['expenseId'],
                                fields: [['amount', 'sum(amount)']],
                            },
                        ],
                    }),
                    fetchData({
                        collection: 'projectExpenseItems',
                        fields: [
                            'projectId',
                            'phaseId',
                            'expenseId',
                            'cost',
                            'beenInvoiced',
                            'date',
                        ],
                        filters: [`projectId == "${project.id}"`],
                    }),
                ])
                const invoice = InvoiceCollection.createInvoice(
                    project,
                    startDate,
                    endDate
                )
                navigate({
                    to: '/invoices/$id',
                    params: { id: invoice.id },
                })
            }}
        >
            <div className="px-3.5 pt-3.5 pb-20">
                <div className="text-444 font-bold mb-1 text-sm">
                    Select project:
                </div>
                <div className="max-w-max">
                    <ProjectSelector
                        value={project}
                        onChange={setProject}
                        projectOptions={ProjectCollection.projects.filter((p) =>
                            canCreateInvoiceForProject(SessionStore.user, p)
                        )}
                        variant="secondary"
                    />
                </div>
                <div style={{ marginTop: '1em' }}>
                    {project ? (
                        <RenderOnQueries
                            queryIds={[
                                {
                                    id: project.id + 'timeMinDate',
                                    collection: 'timeEntries',
                                    fields: [['minDate', 'min(date)']],
                                    filters: [`projectId == "${project.id}"`],
                                    groupBy: ['projectId'],
                                },
                                {
                                    id: project.id + 'invMaxDate',
                                    collection: 'invoiceLineItems',
                                    fields: [
                                        ['maxDate', 'max(invoice.endDate)'],
                                    ],
                                    filters: [`projectId == "${project.id}"`],
                                    groupBy: ['projectId'],
                                },
                            ]}
                            key={project.id}
                        >
                            <div className="mb-3 font-bold text-sm">
                                Invoicing for work completed:
                            </div>
                            <DateSelectors
                                project={project}
                                startDate={startDate}
                                endDate={endDate}
                                setStartDate={setStartDate}
                                setEndDate={setEndDate}
                            />
                        </RenderOnQueries>
                    ) : null}
                </div>
            </div>
        </Modal>
    )
})

const DateSelectors = observer(
    ({ project, startDate, endDate, setStartDate, setEndDate }) => {
        useEffect(() => {
            const invMaxDate = FetchStore.getResponse(project.id + 'invMaxDate')
                ?.invoiceLineItems?.invoiceLineItems?.[0]?.maxDate
            const timeMinDate = FetchStore.getResponse(
                project.id + 'timeMinDate'
            )?.timeEntries?.timeEntries?.[0]?.minDate
            let newStartDate
            if (
                SessionStore.organisation.id ===
                '97a4070d-0bc0-4a1a-b907-b4e3f612a70c'
            ) {
                newStartDate =
                    startDate ||
                    (invMaxDate || timeMinDate
                        ? new Date(min([invMaxDate, timeMinDate]))
                        : new Date())
            } else {
                newStartDate =
                    startDate ||
                    (invMaxDate || timeMinDate
                        ? new Date(invMaxDate || timeMinDate)
                        : new Date())
            }
            setStartDate(newStartDate)
            if (newStartDate > endDate) setEndDate(newStartDate)
        }, [project.id])

        return (
            <div className="flex fixed">
                <div className="py-1.5 px-3.5">
                    <div className="text-sm">From:</div>
                    <DateValue
                        value={startDate}
                        onChange={(v) => {
                            setStartDate(v)
                            if (v > endDate) setEndDate(v)
                        }}
                    />
                </div>
                <div className="py-1.5 px-3.5">
                    <div className="text-sm">To:</div>
                    <DateValue
                        value={endDate}
                        onChange={(v) => {
                            setEndDate(v)
                            if (v < startDate) setStartDate(v)
                        }}
                    />
                </div>
            </div>
        )
    }
)
