import { observable, computed, action, makeObservable } from 'mobx'
import BudgetAggregateCollection from './BudgetAggregateCollection'
import { addUnitToDate, newDate, unitsBetween } from '@ryki/datemath'
import _ from 'lodash'
import ProjectCollection from '../Collections/ProjectCollection'
import Model from '../Models/Model'
import StaffCollection from '../Collections/StaffCollection'
import PhaseCollection from '../Collections/PhaseCollection'
import RoleCollection from '../Collections/RoleCollection'
import getCombinedRateInDateRange from '../../Utils/getCombinedRateInDateRange'

class BudgetAggregateModel extends Model {
    @observable staffId = null
    @observable roleId = null
    @observable projectId = null
    @observable phaseId = null
    @observable hours = null

    constructor(data, options) {
        super()
        makeObservable(this)
        this.collection = BudgetAggregateCollection
        this.init(data, options)
    }
    @computed
    get resource() {
        return this.staff || this.role
    }

    @computed
    get staff() {
        return StaffCollection.staffsById[this.staffId]
    }

    @computed
    get phase() {
        return PhaseCollection.phasesById[this.phaseId]
    }

    @computed
    get project() {
        return ProjectCollection.projectsById[this.projectId]
    }

    @computed
    get role() {
        return RoleCollection.rolesById[this.roleId]
    }

    @computed
    get costRate() {
        if (!this.resource) return 0
        return getCombinedRateInDateRange(
            {
                project: this.project,
                phase: this.phase,
                staff: this.staff,
                role: this.role,
            },
            'cost',
            [this.startDate, this.endDate]
        )
    }

    @computed
    get chargeOutRate() {
        if (!this.resource) return 0
        return getCombinedRateInDateRange(
            {
                project: this.project,
                phase: this.phase,
                staff: this.staff,
                role: this.role,
            },
            'chargeOut',
            [this.startDate, this.endDate]
        )
    }

    @computed
    get cost() {
        return this.hours * this.costRate
    }

    @computed
    get chargeOut() {
        return this.hours * this.chargeOutRate
    }

    @action.bound
    rateValueToHours(value, rateType) {
        const rateName =
            rateType === 'availability'
                ? 'weeklyAvailability'
                : rateType + 'Rate'
        this.update({ hours: value / this[rateName] })
    }

    @computed
    get rates() {
        return this.resource?.rates.sort((a, b) => a.date - b.date)
    }

    @computed
    get startDate() {
        return this.phase?.startDate
    }

    @computed
    get endDate() {
        return this.phase?.endDate
    }
}

export default BudgetAggregateModel
