import { observer } from 'mobx-react'
import React, { Suspense, useCallback, useEffect } from 'react'

import PageHeader from '../Layout/PageHeader'
import StaffCollection from '../../State/Collections/StaffCollection'
import RoleCollection from '../../State/Collections/RoleCollection'
import Checkbox from '../../Components/Widgets/Checkbox'
import StaffRoleListStore from './StaffRoleListStore'
import { canCreateRoles } from '../../State/Permissions/HasPermissions'
import SessionStore from '../../State/SessionStore'
import { useNavigate } from '@tanstack/react-router'

export default observer(({ extraContent }) => {
    const navigate = useNavigate()
    return (
        <PageHeader
            heading={
                <div>
                    <div>{'Staff Roles'}</div>
                </div>
            }
            extraContent={
                <div style={{ marginTop: '1em' }}>
                    {canCreateRoles(SessionStore.user) ? (
                        <button
                            key={0}
                            className="project-list__add-new-project-button page-header__button--primary plus-btn"
                            onClick={() => {
                                const role = RoleCollection.createRole()
                                navigate({ to: '/staff-roles/' + role.id })
                            }}
                        >
                            + New Staff Role
                        </button>
                    ) : null}
                    <Checkbox
                        style={{ marginLeft: '1.5em' }}
                        label={'Show Archived Roles'}
                        onChange={StaffRoleListStore.toggleShowArchived}
                    />
                </div>
            }
        />
    )
})
