import React, { useState, useEffect } from 'react'
import { clerk } from '../../App'
import DataStore from '../../State/DataStore'

export default ({ staff }) => {
    const [inviteStatus, setInviteStatus] = useState('loading')
    const [errorMessage, setErrorMessage] = useState('')

    useEffect(() => {
        const fetchInviteStatus = async () => {
            try {
                const invitations = await clerk.organization.getInvitations()
                console.log(
                    '🚀 ~ fetchInviteStatus ~ invitations:',
                    invitations
                )
                const staffInvitation = invitations.data.find(
                    (invite) => invite.emailAddress === staff.email
                )

                if (staffInvitation) {
                    setInviteStatus(staffInvitation.status)
                } else {
                    setInviteStatus('not_invited')
                }
                setErrorMessage('')
            } catch (error) {
                console.error('Error fetching invite status:', error)
                setInviteStatus('error')
                setErrorMessage(
                    error.message ||
                        'An error occurred while fetching invite status'
                )
            }
        }

        fetchInviteStatus()
    }, [staff.email])

    const sendInvitation = async () => {
        await DataStore.startSave()
        setInviteStatus('sending')
        setErrorMessage('')
        try {
            console.log('Sending invitation to', staff.email)
            console.log(clerk.organization)
            await clerk.organization
                .inviteMember({
                    emailAddress: staff.email,
                    role: 'org:member',
                })
                .then((res) => {
                    console.log(res)
                    setInviteStatus('pending') // or you could use res.status if available
                })
                .catch((error) => {
                    console.log('An error occurred:', error.errors)
                    throw error // Re-throw to be caught by the outer catch block
                })
        } catch (error) {
            console.error('Error sending invite:', error)
            setInviteStatus('error')
            setErrorMessage(
                error.errors?.[0]?.message ||
                    'An error occurred while sending the invite'
            )
        }
    }

    if (inviteStatus === 'loading') {
        return (
            <button
                className="btn btn-sm cursor-default bg-[#eee] hover:bg-[#eee] hover:border-[inherit]"
                style={{ marginLeft: '1em' }}
                disabled
            >
                Loading...
            </button>
        )
    }

    if (inviteStatus === 'sending') {
        return (
            <button
                className="btn btn-sm cursor-default bg-[#eee] hover:bg-[#eee] hover:border-[inherit]"
                style={{ marginLeft: '1em' }}
                disabled
            >
                Sending...
            </button>
        )
    }

    return (
        <div className="inline-block relative">
            <button
                className="btn btn-sm btn-default"
                style={{ marginLeft: '1em' }}
                onClick={sendInvitation}
            >
                {inviteStatus === 'not_invited'
                    ? 'Invite Staff Member'
                    : 'Resend Invitation'}
            </button>
            {!['loading', 'sending', 'error', 'not_invited'].includes(
                inviteStatus
            ) ? (
                <div
                    className="text-xs absolute text-center w-full text-[grey]"
                    style={{ marginLeft: '1em' }}
                >
                    status: {inviteStatus}
                </div>
            ) : null}
            {errorMessage ? (
                <div className="text-xs absolute text-center w-full text-red-500">
                    Error: {errorMessage}
                </div>
            ) : null}
        </div>
    )
}
