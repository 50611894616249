import { MixIcon, SquareIcon } from '@radix-ui/react-icons'

export type DataTableConfig = typeof dataTableConfig

export const dataTableConfig = {
    textComparisonOperators: [
        { label: 'is equal to', value: '=' as const },
        { label: "isn't equal to", value: '!=' as const },
        { label: 'contains', value: 'contains' as const },
        { label: "doesn't contain", value: '!contains' as const },
        { label: 'starts with', value: 'starts' as const },
        { label: "doesn't start with", value: '!starts' as const },
        { label: 'ends with', value: 'ends' as const },
        { label: "doesn't end with", value: '!ends' as const },
        // { label: 'Is empty', value: 'isNull' as const },
        // { label: 'Is not empty', value: 'isNotNull' as const },
    ],
    numberComparisonOperators: [
        { label: 'is equal to', value: '=' as const },
        { label: "isn't equal to", value: '!=' as const },
        { label: 'is greater than', value: '>' as const },
        { label: 'is less than', value: '<' as const },
        { label: 'is between', value: 'between' as const },
        { label: 'is not between', value: '!between' as const },
    ],
    dateComparisonOperators: [
        { label: 'occurs on', value: '=' as const },
        { label: "didn't occur on", value: '!=' as const },
        { label: 'occurs after', value: '>' as const },
        { label: 'occurs before', value: '<' as const },
        { label: 'occurs during', value: 'between' as const },
        { label: "didn't occur during", value: '!between' as const },
    ],
    selectableOperators: [
        { label: 'is any of', value: 'in' as const },
        { label: "isn't any of", value: '!in' as const },
        // { label: 'Is empty', value: 'isNull' as const },
        // { label: 'Is not empty', value: 'isNotNull' as const },
    ],
    logicalOperators: [
        {
            label: 'And',
            value: 'and' as const,
            description: 'All conditions must be met',
        },
        {
            label: 'Or',
            value: 'or' as const,
            description: 'At least one condition must be met',
        },
    ],
    featureFlags: [
        {
            label: 'Advanced filter',
            value: 'advancedFilter' as const,
            icon: MixIcon,
            tooltipTitle: 'Toggle advanced filter',
            tooltipDescription: 'A notion like query builder to filter rows.',
        },
        // {
        //     label: 'Floating bar',
        //     value: 'floatingBar' as const,
        //     icon: SquareIcon,
        //     tooltipTitle: 'Toggle floating bar',
        //     tooltipDescription:
        //         'A floating bar that sticks to the top of the table.',
        // },
    ],
}
