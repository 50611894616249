import React, { useState } from 'react'
import { observer } from 'mobx-react'
import Modal from './Modal'

export default observer(({ report }) => {
    if (!report?.needsUpdate) return null
    return (
        <div
            className="h-full absolute left-0 top-0 w-full text-center p-8"
            style={{
                background: '#FFFFFFaa',
                zIndex: 1050,
                // paddingTop: '8rem',
            }}
        >
            <div
                style={{
                    backgroundColor: 'white',
                    width: '20rem',
                    height: '13rem',
                    display: 'inline-flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center',
                    borderRadius: '1rem',
                    boxShadow: '0 0 2rem #00000033',
                }}
            >
                <h4>Apply Changes To Report</h4>
                <button
                    className="btn btn-primary"
                    onClick={() => report.setQueryKey()}
                    style={{ marginTop: '2rem' }}
                >
                    Update Report
                </button>
            </div>
        </div>
    )
})
