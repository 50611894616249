import _ from 'lodash'
import React from 'react'
import { endOfMonth, format, startOfMonth } from 'date-fns'
import { canEditRevenueTargets } from '../../State/Permissions/HasPermissions'
import SessionStore from '../../State/SessionStore'
import { titleCase } from 'title-case'
import { FormatCurrency } from '../../Utils/Localisation/CurrencyFormatter'
import { capitalCase } from 'change-case'
import ProjectForecastsStore from '../../Pages/ProjectPage/forecasts/ProjectForecastsStore'
import { ProjectSidebarStore } from '../../Pages/ResourceSchedulePage/ResourceScheduleProjectSidebar'

const cellStyles = (row, stores, monthStart, isPastMonth) => {
    const inPhaseDateRange =
        row?.startDate &&
        row?.endDate &&
        monthStart >= startOfMonth(new Date(row?.startDate)) &&
        monthStart <= endOfMonth(new Date(row?.endDate))
    const hasRevenue = stores?.cell?.value !== 0
    const isNegative = stores?.cell?.value < 0
    const overBudget = row?.getProgressInMonth?.(monthStart) > 1
    return {
        ...(isPastMonth
            ? {
                  backgroundImage: `url(${new URL(
                      '~/public/diag.png',
                      import.meta.url
                  )})`,
              }
            : {}),
        ...(hasRevenue
            ? !(isNegative || overBudget)
                ? { borderBottom: '5px gold solid' }
                : { borderBottom: '5px #f15a29 solid', color: '#f15a29' }
            : inPhaseDateRange
            ? { borderBottom: '3px #d7d7d7 solid' }
            : {}),
    }
}

export const RevenueForecastReportPhaseColumns = (store, keyString = '') => ({
    title: (
        monthStartDate,
        groupBy = [],
        filters = [],
        { status, projectId } = {}
    ) => ({
        id: 'title',
        label: 'Title',
        type: 'text',
        width: 18,
        editable: (row) => false,
        value: (row, stores) => {
            // labels disappear when using title case for some reason
            // checking it exist first works - maybe some observable thing?
            return row.title
        },
        component: ({ value, group, stores }) => {
            const row = stores.row.rowObject
            if (stores.row.group === 'totals') return 'Total'
            return (
                <div style={{ paddingLeft: `${1 * row.groupLevel}rem` }}>
                    {row.groups?.length === 1
                        ? capitalCase(value)
                        : titleCase(value)}
                </div>
            )
        },
    }),
    revenue: (
        monthStartDate,
        groupBy = [],
        filters = [],
        { status, projectId } = {}
    ) => {
        const monthStart = startOfMonth(monthStartDate)
        const monthEnd = endOfMonth(monthStart)
        const currentMonthStart = startOfMonth(new Date())
        const monthType =
            monthStart.getTime() === currentMonthStart.getTime()
                ? 'current'
                : monthStart.getTime() > currentMonthStart.getTime()
                ? 'future'
                : 'past'
        const isPastMonth = monthType === 'past'
        const isFutureMonth = monthType === 'future'
        const isCurrentMonth = monthType === 'current'
        const cellLabel = format(monthStartDate, 'yyyy-MM')
        let queryId = 'revenue'
        if (status) queryId += status
        if (projectId) queryId += projectId
        const showExpenses = store.report.filters.showExpenses
        const group =
            groupBy[groupBy.length - 1] !== 'id'
                ? groupBy[groupBy.length - 1]
                : 'phase'
        return {
            id: cellLabel,
            label: format(monthStartDate, 'MMM yy'),
            type: 'currency',
            width: 8,
            value: (row) => {
                return store.getRevenueInMonth(
                    keyString ? `${keyString},${row.key}` : row.key,
                    cellLabel
                )
            },
            editable: () => {
                return (
                    (isFutureMonth ||
                        isCurrentMonth ||
                        store.report.filters.revenueData === 'projected') &&
                    keyString?.split(',').filter((k) => k !== '').length >= 1 &&
                    canEditRevenueTargets(SessionStore.user)
                )
            },
            style: (row, stores) => {
                return cellStyles(row, stores, monthStart, isPastMonth)
            },
            format: (v) => FormatCurrency(v, { decimals: 0 }),
            aggregate: 'sum',
            onClick: (row) => () => {
                store.selectCell(
                    monthStart,
                    row,
                    keyString ? `${keyString},${row.key}` : row.key
                )
            },
            selected: (row) => {
                return (
                    store.selectedObject ===
                        (row.phaseId ? row : row.project) &&
                    format(store.selectedMonth, 'MMM yy') ===
                        format(monthStart, 'MMM yy')
                )
            },
            onChange: (r) => (v) => {
                v = v || 0
                store.setEditedRevenueData(
                    keyString ? `${keyString},${r.key}` : r.key,
                    format(monthStartDate, 'yyyy-MM'),
                    v,
                    (period, newRevenue, projectId, phaseId) => {
                        ProjectSidebarStore.setRevenueInPeriod(
                            format(monthStartDate, 'yyyy-MM'),
                            projectId,
                            phaseId || null,
                            newRevenue
                        )
                    }
                )
            },
        }
    },

    revenueVsFee: (monthStartDate, groupBy = [], filters = []) => {
        const currentMonthStart = startOfMonth(new Date())
        const currentMonthEnd = endOfMonth(currentMonthStart)
        return {
            id: 'revenueVsFee',
            label: (
                <span>
                    Total
                    <span
                        style={{ fontSize: '1.5em', float: 'right' }}
                        onClick={(e) => e.stopPropagation()}
                    >
                        <i
                            className={`fa fa-caret-left`}
                            style={{ cursor: 'pointer' }}
                            onClick={() => store.shiftDates(-1)}
                        />
                        <i
                            className={`fa fa-caret-right`}
                            style={{ marginRight: 0, cursor: 'pointer' }}
                            onClick={() => store.shiftDates(1)}
                        />
                    </span>
                </span>
            ),
            type: 'progress',
            width: 18,
            editable: (row) => false,
            format: (v) => FormatCurrency(v, { decimals: 0 }),
            style: (row, stores) => {
                return {
                    ...(stores?.cell?.value?.numerator /
                        stores?.cell?.value?.denominator >
                    1
                        ? { color: '#f15a29' }
                        : {}),
                }
            },
            value: (r) => {
                return {
                    numerator:
                        store.getTotalRevenue(
                            keyString ? `${keyString},${r.key}` : r.key
                        ) || 0,
                    denominator:
                        store.getTotalFee(
                            keyString ? `${keyString},${r.key}` : r.key
                        ) || 0,
                }
            },
        }
    },
})
