import _ from 'lodash'
import { observer } from 'mobx-react'
import React, { useState } from 'react'
import Modal from '../../Components/Modal'
import DataStore from '../../State/DataStore'
import { useNavigate } from '@tanstack/react-router'
import SessionStore from '../../State/SessionStore'

export default observer(({ permission, modalId }) => {
    const navigate = useNavigate()
    return (
        <Modal
            modalId={modalId}
            heading="Delete Permission"
            saveLabel={'Delete Permission'}
            onSave={async () => {
                permission.update({ deletedAt: new Date() })
                if (
                    permission.id ===
                    SessionStore.organisation.defaultPermissionsId
                ) {
                    SessionStore.organisation.update({
                        defaultPermissionsId: null,
                    })
                }
                permission.staff.forEach((s) => {
                    s.update({ permissionsId: null })
                })
                await DataStore.startSave()
                await DataStore.saveModel(permission)
                navigate({ to: '/permissions' })
            }}
        >
            <p style={{ margin: '1em' }}>
                Are you sure you wish to delete <em>{permission.name}</em>?
            </p>
        </Modal>
    )
})
