import { getUserLocale } from 'get-user-locale'
import isoLangCodes from 'iso-lang-codes'
import { FormatDate } from './DateFormatter'
import { parse } from 'date-fns'
import * as locales from 'date-fns/locale'
import SessionStore from '../../State/SessionStore'
import { constantCase } from 'change-case'

const knownDate = new Date(2015, 10, 30)
const knownFormat = () =>
    FormatDate(knownDate, {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
    })
const knownYearFormat = () => FormatDate(knownDate, { year: 'numeric' })
const knownMonthFormat = () => FormatDate(knownDate, { month: '2-digit' })
const knownDayFormat = () => FormatDate(knownDate, { day: '2-digit' })
const knownFormatWithoutNumbers = () =>
    knownFormat()
        .replace(knownYearFormat(), '#')
        .replace(knownMonthFormat(), '#')
        .replace(knownDayFormat(), '#')
const defaultSeparator = () => knownFormatWithoutNumbers().split('#')[1]
const defaultFormat = () =>
    knownFormat()
        .replace(knownYearFormat(), 'yyyy')
        .replace(knownMonthFormat(), 'MM')
        .replace(knownDayFormat(), 'dd')

const supportedSeparators = () => {
    const seps = ['/', '.', '-', ' ']
    if (!seps.includes(defaultSeparator())) {
        seps.unshift(defaultSeparator())
    }
    return seps
}
const supportedYears = ['yyyy', 'yy']
const supportedMonths = ['MM', 'M', 'MMM', 'MMMM']
const supportedDays = ['dd', 'd']

// all combinations of defaultFormat replacing year, month, day, and separators with supported formats
const supportedFormats = () =>
    supportedYears.flatMap((year) => {
        return supportedMonths.flatMap((month) => {
            return supportedDays.flatMap((day) => {
                return supportedSeparators().map((separator) => {
                    return defaultFormat()
                        .replace('yyyy', year)
                        .replace('MM', month)
                        .replace('dd', day)
                        .replaceAll(defaultSeparator(), separator)
                })
            })
        })
    })

export const ParseDate = (valueString) => {
    const localeString = isoLangCodes.findCountryLocales(
        constantCase(SessionStore?.organisationCountry || 'us')
    )[0]
    const localeLang = localeString.split('-')[0]
    const localeCountry = localeString.split('-')[1]
    const locale = locales[localeLang + localeCountry] || locales[localeLang]
    for (let formatString of supportedFormats()) {
        const params = [valueString, formatString, new Date()]
        if (locale) {
            params.push({ locale })
        }
        const date = parse(...params)
        if (!isNaN(date) && date.getFullYear() > 1900) {
            return date
        }
    }
    return null
}
