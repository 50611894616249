import React, { useState } from 'react'
import { observer } from 'mobx-react'
import { FormatPercent } from '../../Utils/Localisation/PercentFormatter'
import Inputs from '../Inputs'
import { Slider } from '../ui/Slider'

export default observer(
    ({
        total,
        maxAmount,
        prevAmount,
        amount,
        height = 4,
        editable = true,
        onChange,
    }) => {
        const maxProgress = maxAmount / (total || 1)
        const prevProgress = prevAmount / (total || 1)
        const currentProgress = (prevAmount + amount) / (total || 1)
        const [value, setValue] = useState(currentProgress * 100)

        let progressGradient = { '0%': '#108ee9' }
        let finalColour = '#108ee9'
        progressGradient[`${Math.round((0.5 / currentProgress) * 100)}%`] =
            '#87d068'
        if (currentProgress > 0.4) {
            finalColour = '#87d068'
        }
        if (currentProgress > 0.8) {
            progressGradient[`${Math.round((0.8 / currentProgress) * 100)}%`] =
                '#ffc200'
            finalColour = '#ffc200'
        }
        if (currentProgress > 1) {
            progressGradient[`${Math.round((1 / currentProgress) * 100)}%`] =
                '#ff5800'
            finalColour = '#ff5800'
        }
        const [sliderFocused, setSliderFocused] = useState(false)

        return (
            <div className="sidebar-slider">
                {total ? (
                    <div style={{ textAlign: 'right', margin: '0.9em 0 0' }}>
                        <div
                            style={{
                                width: '18%',
                                marginLeft: '0.5em',
                                display: 'inline-block',
                                float: 'right',
                                marginTop: '-1.5em',
                            }}
                        >
                            <Inputs.percent
                                disabled={!editable}
                                formatter={(v) =>
                                    FormatPercent(v, { decimals: 0 })
                                }
                                value={currentProgress}
                                onChange={(v) => {
                                    if (
                                        v * 100 ===
                                        Math.round(prevProgress * 100)
                                    ) {
                                        return onChange(0)
                                    }
                                    if (
                                        v * 100 ===
                                        Math.round(maxProgress * 100)
                                    )
                                        return onChange(maxAmount - prevAmount)
                                    return onChange(
                                        Math.max(v, prevProgress) * total -
                                            prevAmount
                                    )
                                }}
                                style={{
                                    textAlign: 'right',
                                    borderRadius: '0.5em',
                                    border: '1px solid #ddd',
                                    backgroundColor: '#f8f8f8',
                                    boxShadow: 'none',
                                    padding: '0.1em 0.35em',
                                    minHeight: '0',
                                    lineHeight: '0',
                                }}
                            />
                        </div>
                    </div>
                ) : null}
                <Slider
                    value={total ? [currentProgress * 100] : []}
                    max={
                        total ? Math.max(maxProgress, currentProgress) * 100 : 0
                    }
                    onValueChange={(v) => {
                        if (!sliderFocused || !total) return
                        if (v === Math.round(prevProgress * 100)) {
                            return onChange(0)
                        }
                        if (v === Math.round(maxProgress * 100))
                            return onChange(maxAmount - prevAmount)
                        return onChange(
                            Math.max(v / 100, prevProgress) * total - prevAmount
                        )
                    }}
                    rangeStyle={{
                        background: total
                            ? `linear-gradient(to right, ${Object.entries(
                                  progressGradient
                              )
                                  .map(([key, value]) => `${value} ${key}`)
                                  .join(', ')})`
                            : '#f5f5f5',
                        height: `${height}px`,
                    }}
                    thumbStyle={{
                        borderColor: finalColour,
                    }}
                    marks={total ? [prevProgress * 100] : []}
                    disabled={!editable || !total}
                    onFocus={() => setSliderFocused(true)}
                    onBlur={() => setSliderFocused(false)}
                />
                <div style={{ textAlign: 'right', margin: '-0.25em 0 0' }}>
                    <div
                        style={{
                            width: '32%',
                            marginLeft: '0.5em',
                            display: 'inline-block',
                            textAlign: 'left',
                        }}
                    >
                        <div
                            style={{
                                fontSize: '0.8em',
                                fontWeight: 'bold',
                                paddingLeft: '0.5em',
                            }}
                        >
                            Revenue
                        </div>
                        <Inputs.currency
                            disabled={!editable}
                            value={amount}
                            onChange={onChange}
                            style={{
                                border: '1px solid #ddd',
                                textAlign: 'right',
                                borderRadius: '0.5em',
                                backgroundColor: '#f8f8f8',
                                boxShadow: 'none',
                                padding: '0.1em 0.35em',
                                minHeight: '0',
                                lineHeight: '0',
                            }}
                        />
                    </div>
                </div>
            </div>
        )
    }
)
