import React, { useRef, useState } from 'react'
import Select, { components } from 'react-select'
import {
    SortableContainer,
    SortableElement,
    SortableHandle,
} from 'react-sortable-hoc'

function arrayMove(array, from, to) {
    const slicedArray = array.slice()
    slicedArray.splice(
        to < 0 ? array.length + to : to,
        0,
        slicedArray.splice(from, 1)[0]
    )
    return slicedArray
}

const SortableMultiValue = SortableElement((props) => {
    const onMouseDown = (e) => {
        e.preventDefault()
        e.stopPropagation()
    }
    const innerProps = { ...props.innerProps, onMouseDown }
    return <components.MultiValue {...props} innerProps={innerProps} />
})

const SortableMultiValueLabel = SortableHandle((props) => (
    <components.MultiValueLabel {...props} />
))

const SortableSelect = SortableContainer(Select)

export default function SortableMultiselect({
    selectedOptions,
    options,
    onChange,
    groupBy,
    sortGroups,
    styles,
    ...props
}) {
    const [menuIsOpen, setMenuIsOpen] = useState(false)
    const selectRef = useRef(null)

    const getGroupedAndSortedOptions = () => {
        if (!groupBy) return options

        const groupedOptions = Object.entries(
            options.reduce((groups, option) => {
                const groupKey = groupBy(option)
                groups[groupKey] = groups[groupKey] || []
                groups[groupKey].push(option)
                return groups
            }, {})
        ).map(([groupLabel, groupOptions]) => ({
            label: groupLabel,
            options: groupOptions,
        }))

        return sortGroups ? groupedOptions.sort(sortGroups) : groupedOptions
    }

    const onSortEnd = ({ oldIndex, newIndex }) => {
        const newValue = arrayMove(selectedOptions, oldIndex, newIndex)
        onChange(newValue)
    }

    const customStyles = {
        control: (provided) => ({
            ...provided,
            position: 'relative',
        }),
        menu: (provided) => {
            if (!selectRef.current) return provided
            const { bottom, left, width } =
                selectRef.current.getBoundingClientRect()
            return {
                ...provided,
                position: 'fixed',
                top: `${bottom}px`,
                left: `${left}px`,
                width: `${width}px`,
                zIndex: 9999,
            }
        },
    }

    return (
        <div
            ref={selectRef}
            className={props.className}
            styles={{
                ...(styles || {}),
                ...customStyles,
            }}
        >
            <SortableSelect
                useDragHandle
                axis="xy"
                onSortEnd={onSortEnd}
                distance={4}
                getHelperDimensions={({ node }) => node.getBoundingClientRect()}
                isMulti
                options={getGroupedAndSortedOptions()}
                value={selectedOptions}
                onChange={onChange}
                components={{
                    MultiValue: SortableMultiValue,
                    MultiValueLabel: SortableMultiValueLabel,
                }}
                closeMenuOnSelect={false}
                isClearable={false}
                styles={{
                    ...(styles || {}),
                    ...customStyles,
                }}
                menuIsOpen={menuIsOpen}
                onMenuOpen={() => setMenuIsOpen(true)}
                onMenuClose={() => setMenuIsOpen(false)}
                {...props}
            />
        </div>
    )
}
