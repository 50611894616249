import { observer } from 'mobx-react'
import React, { Suspense, useCallback, useEffect } from 'react'
import PageHeader from '../Layout/PageHeader'
import CostCentreCollection from '../../State/Collections/CostCentreCollection'

export default observer(({ extraContent, id }) => {
    const costCentre = CostCentreCollection.costCentresById[id]
    if (!costCentre) return null
    return (
        <PageHeader
            heading={
                <div>
                    <div>{costCentre?.name || 'New Cost Centre'}</div>
                </div>
            }
            rightButtons={
                <>
                    <button
                        className="btn btn-default edit-controls__delete-button"
                        onClick={() => null}
                    >
                        <i className="fa fa-trash" />
                        Delete
                    </button>
                </>
            }
            extraContent={extraContent}
        />
    )
})
