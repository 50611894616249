import { observable, computed, action, makeObservable } from 'mobx'
import RoleCollection from '../Collections/RoleCollection'
import RoleRateCollection from '../Collections/RoleRateCollection'
import Model from './Model'

class RoleRateModel extends Model {
    @observable roleId = null
    @observable date = null
    @observable payRate = null
    @observable costRate = null
    @observable chargeOutRate = null
    @observable overtimeRate = null

    constructor(data, options) {
        super()
        makeObservable(this)
        this.collection = RoleRateCollection
        this.init(data, options)
    }
    @computed
    get role() {
        return RoleCollection.modelsById[this.roleId]
    }
}

export default RoleRateModel
