import { observable, computed, action, makeObservable } from 'mobx'
import Collection from './Collection'
import TimeEntryModel from '../Models/TimeEntryModel'
import tuple from 'immutable-tuple'

class TimeEntryCollection extends Collection {
    constructor() {
        super({ collection: 'timeEntries', modelClass: TimeEntryModel })
        this.addLookup('timeEntriesByProjectId', 'manyByKey', {
            key: (t) => t?.projectId,
            filter: (t) => !t?.deletedAt,
        })
        this.addLookup('timeEntriesByPhaseId', 'manyByKey', {
            key: (t) => t?.phaseId,
            filter: (t) => !t?.deletedAt,
        })
        this.addLookup('timeEntriesByStaffId', 'manyByKey', {
            key: (t) => t?.staffId,
            filter: (t) => !t?.deletedAt,
        })
        this.addLookup('timeEntriesByRoleId', 'manyByKey', {
            key: (t) => t?.roleId || t?.staff?.roleId,
            filter: (t) => !t?.deletedAt,
        })
        this.addLookup('timeEntriesByTaskId', 'manyByKey', {
            key: (t) => t?.taskId,
            filter: (t) => !t?.deletedAt,
        })
        this.addLookup('timeEntriesByCostCentreId', 'manyByKey', {
            key: (t) => t?.costCentreId,
            filter: (t) => !t?.deletedAt,
        })
        this.addLookup('timeEntriesByStaffIdDate', 'manyByKey', {
            key: (t) => tuple(t?.staffId, t?.date?.getTime()),
            filter: (t) => !t?.deletedAt,
        })
        this.addLookup('timeEntriesByStaffIdWeek', 'manyByKey', {
            key: (t) => {
                return tuple(t?.staffId, t?.week)
            },
            filter: (t) => !t?.deletedAt,
        })
        makeObservable(this)
    }
    get timeEntriesById() {
        return this.modelsById
    }
    get timeEntries() {
        return this.models
    }
    @action.bound
    addTimeEntry(data) {
        this.add(data)
    }
    @action.bound
    addTimeEntries(data) {
        this.addMany(data)
    }
}

export default new TimeEntryCollection()
export const TimeEntryCollectionClass = TimeEntryCollection
