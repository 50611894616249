import { observer } from 'mobx-react'
import React, { useEffect, useMemo, useState } from 'react'
import LoadingSpinner from '../../Components/LoadingSpinner'
import Table from '../../Components/Table'
import DefaultProjectReport from '../../reports/Projects/DefaultProjectReport'
import ReportCollection from '../../State/Collections/ReportCollection'
import SessionStore from '../../State/SessionStore'
import RenderOnQueries from '../Layout/RenderOnQueries'
import _ from 'lodash'
import { addYears, format } from 'date-fns'
import ProjectListStore from './ProjectListStore'
import UpdateReportModal from '../../Components/UpdateReportModal'
import { queryClient } from '../../App'
import { dateStringLookup } from '../../Components/Filters'
import sortPhases from '../../Utils/sortPhases'

export default observer((props) => {
    useEffect(() => {
        ProjectListStore.setSearchParams(props)
    }, [props])
    const report =
        ReportCollection.reportsById[props.report] ||
        SessionStore.organisation.defaultProjectReport

    return (
        <RenderOnQueries
            key={'project-table' + report.queryKey}
            loading={<LoadingSpinner />}
            queryIds={[
                {
                    id: report.queryKey,
                    path:
                        process.env.REACT_APP_NODE_SERVER_URL +
                        '/project-report',
                    method: 'POST',
                    staleTime: 0,
                    cacheTime: 0,
                    data: {
                        organisationId: SessionStore.organisationId,
                        userId: SessionStore.user?.id,
                        columns: report.columns.filter(
                            (c) =>
                                !report.columnOptionsById[c]?.permissions ||
                                report.columnOptionsById[c].permissions()
                        ),
                        filters: report.filters.map((f) => {
                            if (
                                report.columnOptionsById[f.column]?.type ===
                                'date'
                            ) {
                                let value =
                                    dateStringLookup[f.value]?.() || f.value
                                if (Array.isArray(value)) {
                                    value = value.map((d) =>
                                        d ? format(d, 'yyyy-MM-dd') : null
                                    )
                                } else {
                                    value = value
                                        ? format(value, 'yyyy-MM-dd')
                                        : null
                                }
                                return {
                                    ...f,
                                    value,
                                }
                            } else {
                                return f
                            }
                        }),
                        groupBy: ['projectId'],
                        sortBy: report.sortBy,
                        dateRange: report.dateRange.map((d) =>
                            isFinite(d) ? format(d, 'yyyy-MM-dd') : null
                        ),
                        invoiceDateType:
                            SessionStore.settings.reportInvoiceDateType,
                    },
                },
            ]}
        >
            <ProjectTable
                key={'project-table' + report.queryKey}
                id={props.report}
            />
        </RenderOnQueries>
    )
})

const ProjectTable = observer(({ id }) => {
    const store = ProjectListStore
    const report =
        ReportCollection.reportsById[id] ||
        SessionStore.organisation.defaultProjectReport
    const sortBy = report.sortBy?.length
        ? report.sortBy
        : DefaultProjectReport.sortBy
    const expandAll = ProjectListStore.expandAll
    const queryData = queryClient.getQueryData([report.queryKey])?.data
    useEffect(() => {
        store.init(queryData)
    }, [report.queryKey])
    const rows = useMemo(() => {
        return [...(store.queryData || [])]
            .filter((r) => {
                return r.name
                    ?.toLowerCase()
                    ?.includes?.(report.searchFilter.toLowerCase())
            })
            .sort((a, b) => {
                return a.name?.localeCompare?.(b.name)
            })
    }, [store.queryData, report.searchFilter])
    return (
        <>
            <UpdateReportModal report={report} />
            <Table
                key={'project-table' + report.queryKey}
                tableStore={report.tableStore}
                columns={report.tableColumns}
                rows={rows.filter((r) => !r.deletedAt)}
                // sortBy={sortBy}
                // filters={report.filters}
                showTotals={true}
                getChildComponent={(r) => {
                    return (
                        <PhaseSubTable
                            project={r}
                            report={report}
                            rows={r.children}
                        />
                    )
                }}
                expandAll={expandAll}
            />
        </>
    )
})

const PhaseSubTable = observer(({ project, report, sortBy, rows }) => {
    const expandAll = ProjectListStore.expandAll
    const tableRows = useMemo(() => {
        return [...rows].sort(sortPhases)
    }, [project.id, rows])
    return (
        <Table
            key={
                'project-table' +
                (!expandAll ? project.id : 'all') +
                report.queryKey
            }
            columns={[
                {
                    label: '',
                    width: 3,
                    type: 'text',
                    value: (r) => '',
                    onChange: (r) => (v) => null,
                },
                ...report.tableColumns,
                // add startDate column if not in tableColumns
                ...(!report.tableColumns.find((c) => c.id === 'startDate')
                    ? [
                          {
                              id: 'startDate',
                              label: 'Start Date',
                              visible: false,
                              type: 'date',
                              value: (r) =>
                                  r ? r.startDate : addYears(new Date(), 100),
                              onChange: (r) => (v) => null,
                          },
                      ]
                    : []),
            ]}
            rows={tableRows}
            sortBy={report.tableStore.sortBy}
            showHeader={false}
            style={{
                boxShadow: 'none',
                background: `linear-gradient(180deg, rgb(230,230,230) 1px, rgb(247,247,247) 8px)`,
                fontSize: '1.2rem',
            }}
        />
    )
})
