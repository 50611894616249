import { observable, computed, action, makeObservable } from 'mobx'
import CostCentreCollection from '../Collections/CostCentreCollection'
import OverheadExpenseCollection from '../Collections/OverheadExpenseCollection'
import ProjectCollection from '../Collections/ProjectCollection'
import StaffCollection from '../Collections/StaffCollection'
import Model from './Model'

class CostCentreModel extends Model {
    @observable name = null
    @observable isBillable = null
    constructor(data, options) {
        super()
        makeObservable(this)
        this.collection = CostCentreCollection
        this.init(data, options)
    }

    @computed
    get overheadExpenses() {
        return OverheadExpenseCollection.overheadExpensesByCostCentreId[
            this.costCentreId
        ]
    }

    @computed
    get projects() {
        return ProjectCollection.allocationsByCostCentreId[this.id]
    }
    @computed
    get staff() {
        return StaffCollection.staffByCostCentreId[this.id]
    }
}

export default CostCentreModel
