import { observable, computed, action, makeObservable } from 'mobx'
import Collection from './Collection'
import ReportModel from '../Models/ReportModel'

class ReportCollection extends Collection {
    constructor() {
        super({
            collection: 'reports',
            modelClass: ReportModel,
        })
        this.addLookup('reportsByType', 'manyByKey', {
            key: (t) => t.type,
            filter: (t) => !t.deletedAt,
        })
        makeObservable(this)
    }
    get reportsById() {
        return this.modelsById
    }
    get reports() {
        return this.models
    }
    @action.bound
    addReport(data) {
        this.add(data)
    }
    @action.bound
    addReports(data) {
        this.addMany(data)
    }
}

export default new ReportCollection()
export const ReportCollectionClass = ReportCollection
