import { observable, computed, action, makeObservable } from 'mobx'
import StaffCollection from '../Collections/StaffCollection'
import ProjectCollection from '../Collections/ProjectCollection'
import PhaseCollection from '../Collections/PhaseCollection'
import TaskCollection from '../Collections/TaskCollection'
import Model from './Model'
import AllocationCollection from '../Collections/AllocationCollection'
import CostCentreCollection from '../Collections/CostCentreCollection'
import { getMonth, getWeek } from 'date-fns'
import RoleCollection from '../Collections/RoleCollection'

class AllocationModel extends Model {
    @observable staffId = null
    @observable roleId = null
    @observable projectId = null
    @observable phaseId = null

    @observable date = new Date()
    @observable numMinutes = 0
    @observable startMinutes = 0

    @observable pay = 0
    @observable cost = 0
    @observable charge_out = 0

    constructor(data, options) {
        super()
        makeObservable(this)
        this.collection = AllocationCollection
        this.init(data, options)
    }
    @computed
    get staff() {
        return StaffCollection.staffsById[this.staffId]
    }
    @computed
    get role() {
        return RoleCollection.rolesById[this.roleId] || this.staff?.role
    }
    @computed
    get costCentre() {
        return this.project.costCentre
    }
    @computed
    get project() {
        return ProjectCollection.modelsById[this.projectId]
    }
    @computed
    get phase() {
        return PhaseCollection.modelsById[this.phaseId]
    }
    @computed
    get hours() {
        return this.numMinutes / 60
    }
    @computed
    get payRate() {
        return this.pay / this.hours
    }
    @computed
    get costRate() {
        return this.cost / this.hours
    }
    @computed
    get chargeOutRate() {
        return this.chargeOut / this.hours
    }
    @computed
    get week() {
        return getWeek(this.date, { weekStartsOn: 1 })
    }
    @computed
    get month() {
        return getMonth(this.date)
    }
    @computed
    get likelihood() {
        return this.phase?.likelihood || 100
    }
}

export default AllocationModel
