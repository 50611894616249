import { observable, computed, action, makeObservable } from 'mobx'
import Collection from './Collection'
import RevenueForecastReportModel from '../Models/RevenueForecastReportModel'

class RevenueForecastReportCollection extends Collection {
    constructor() {
        super({
            collection: 'revenueForecastReports',
            modelClass: RevenueForecastReportModel,
        })
        makeObservable(this)
    }
    get revenueForecastReportsById() {
        return this.modelsById
    }
    get revenueForecastReports() {
        return this.models
    }
    @action.bound
    addRevenueForecastReport(data) {
        this.add(data)
    }
    @action.bound
    addRevenueForecastReports(data) {
        this.addMany(data)
    }
}

export default new RevenueForecastReportCollection()
export const RevenueForecastReportCollectionClass =
    RevenueForecastReportCollection
