import React, { useEffect, useState } from 'react'
import Modal from '../../Components/Modal'
import * as Sentry from '@sentry/react'
import { makeRequest } from '../../Queries/makeRequest'

export default ({ modalId, staff }) => {
    const [inviteCode, setInviteCode] = useState(null)
    useEffect(() => {
        makeRequest({
            path: process.env.REACT_APP_SERVER_URL + '/api/v1.5/user/get-token',
            method: 'post',
            data: { id: staff.id },
        })
            .then((response) => {
                setInviteCode(response.data.token)
            })
            .catch((error) => {
                Sentry.captureException(error)
                setInviteCode(false)
            })
    }, [])
    const inviteURL = `${process.env.REACT_APP_CLIENT_URL}/invite?token=${inviteCode}`
    return (
        <Modal
            modalId={modalId}
            heading={'Invite Staff'}
            saveLabel={'Email Invite Code to Staff Member'}
            onSave={() => {
                makeRequest({
                    path:
                        process.env.REACT_APP_SERVER_URL +
                        '/api/v1.5/user/send-invite',
                    method: 'post',
                    data: {
                        email: staff.email,
                        token: inviteCode,
                    },
                })
            }}
        >
            {inviteCode ? (
                <div>
                    Please send the following link to your staff member to
                    activate their account.{' '}
                    <a href={inviteURL} style={{ color: '#1890ff' }}>
                        {inviteURL}
                    </a>
                </div>
            ) : inviteCode === null ? (
                <div>Fetching activation link...</div>
            ) : (
                <div>
                    There was an error retrieving an activation link for your
                    staff member. Please contact us to resolve the issue.
                </div>
            )}
        </Modal>
    )
}
