import _ from 'underscore'
import React from 'react'
import CreateReactClass from 'create-react-class'
import { isNumber } from '../utils.js'
import { numberInputContainerMixin } from './numberInputContainerMixin.js'
import PropTypes from 'prop-types'

export var NumberInputContainer = CreateReactClass({
    propTypes: {
        value: PropTypes.number,

        allowNegative: PropTypes.bool,
        allowNull: PropTypes.bool,

        // Should have either `onChange` or `action` (which uses `actionMixin`).
        onChange: PropTypes.func,
        formatFunc: PropTypes.func,
        className: PropTypes.any,
        isEditable: PropTypes.bool,
        nullReadOnlyValue: PropTypes.any,
    },

    mixins: [numberInputContainerMixin()],

    getDefaultProps: function () {
        return {
            isEditable: true,
            formatFunc: (n) => (isNumber(n) ? n.toFixed(2) : ''),
            allowNegative: false,
            allowNull: false,
        }
    },

    render: function () {
        let {
            isEditable,
            nullReadOnlyValue,
            value,
            style,
            formatFunc: _formatFunc,
            onChange: _onChange,
            onBlur: _onBlur,
            actionArgs: _actionArgs,
            executeActionOn: _executeActionOn,
            allowNull: _allowNull,
            allowNegative: _allowNegative,
            ...props
        } = this.props

        if (!isEditable && value == null) {
            value = nullReadOnlyValue
        } else {
            value = this.state.value
        }

        const defaultStyles = {
            textAlign: 'right',
        }

        const disabledStyles = {
            border: 'none',
            boxShadow: 'none',
        }

        const errorStyles = {
            color: '#dc2d11',
            border: '2px solid #dc2d11',
        }

        return (
            <input
                className="input-base"
                autoComplete="off"
                ref="input"
                type="text"
                disabled={!isEditable}
                style={{
                    ...defaultStyles,
                    // `isValid` comes from `numberInputContainerMixin`
                    ...(isEditable && value !== '' && !this.isValid(value)
                        ? errorStyles
                        : {}),
                    ...(!isEditable ? disabledStyles : {}),
                    ...style,
                }}
                value={value || ''}
                onBlur={this.handleBlur} // from `numberInputContainerMixin`
                onChange={this.handleChange} // from `numberInputContainerMixin`
                onKeyPress={this.handleKeyPress} // from `numberInputContainerMixin`
                {...props}
            />
        )
    },
})
