import { observer } from 'mobx-react'
import React, { useEffect, useState } from 'react'
import { connectAccountingOauth } from '../../Queries/accountingOauth'
import { useNavigate } from '@tanstack/react-router'

export default observer(({ state }) => {
    const navigate = useNavigate()
    const [error, setError] = useState(null)

    useEffect(() => {
        const connect = async () => {
            try {
                await connectAccountingOauth()
                navigate({ to: state })
            } catch (e) {
                setError(e)
            }
        }

        connect()
    }, [])

    if (error)
        return (
            <div>
                <h4>There was an error connecting to your accounting system</h4>
                <div>{error.message}</div>
                <button
                    onClick={() =>
                        navigate({
                            to: state || '/',
                        })
                    }
                >
                    Go Back
                </button>
            </div>
        )

    return (
        <div>
            <style>
                {`.loading::after {
                    display: inline-block;
                    animation: dotty steps(1,end) 1s infinite;
                    content: '   ';
                }
                
                @keyframes dotty {
                    0%   { content: '   '; }
                    25%  { content: '.  '; }
                    50%  { content: '.. '; }
                    75%  { content: '...'; }
                    100% { content: '   '; }
                }`}
            </style>
            <h4>Connecting to Accounting System</h4>
            <div className="loading">Please Wait</div>
        </div>
    )
})
