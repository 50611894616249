import _ from 'lodash'
import { makeObservable, observable, computed, action, autorun } from 'mobx'
import TimeEntryCollection from '../../State/Collections/TimeEntryCollection'
import {
    addDays,
    addWeeks,
    format,
    getWeek,
    parse,
    startOfDay,
    startOfWeek,
    subWeeks,
} from 'date-fns'
import SessionStore from '../../State/SessionStore'
import tuple from 'immutable-tuple'
import TimeEntryModel from '../../State/Models/TimeEntryModel'
import StaffCollection from '../../State/Collections/StaffCollection'
import { canEditStaffTime } from '../../State/Permissions/HasPermissions'
import { router } from '../../App'

class TimesheetsWeeklyStore {
    @observable extraTime = []
    @observable searchParams = {}
    constructor() {
        makeObservable(this)
    }
    @action.bound
    reset() {
        this.extraTime = []
    }
    @action.bound
    setSearchParams(params) {
        this.searchParams = params
    }
    get staffId() {
        return canEditStaffTime(SessionStore.user)
            ? this.searchParams?.staffId || SessionStore.userId
            : SessionStore.userId
    }
    get date() {
        return this.searchParams?.date
            ? parse(this.searchParams?.date, 'yyyy-MM-dd', new Date())
            : startOfDay(new Date())
    }
    @action.bound
    addExtraTime(timeEntries) {
        this.extraTime.push(...timeEntries)
    }
    get timeEntries() {
        return [
            ...new Set([
                ...TimeEntryCollection.timeEntries.filter((t) => {
                    return (
                        t.staffId === this.staffId &&
                        t.week === getWeek(this.date, { weekStartsOn: 1 }) &&
                        !t.deletedAt
                    )
                }),
                ...this.extraTime.filter(
                    (t) =>
                        t.staffId === this.staffId &&
                        t.week === getWeek(this.date, { weekStartsOn: 1 }) &&
                        !t.deletedAt
                ),
            ]),
        ]
    }
    @action.bound
    clearBlankTimeEntries() {
        this.timeEntries
            .filter((te) => !te.numMinutes && !te.notes)
            .forEach((te) => te.update({ deletedAt: new Date() }))
    }
    @computed
    get costCentres() {
        return [
            ...new Set(
                this.timeEntries.map(
                    (t) =>
                        t.project?.costCentre || {
                            id: t.costCentreId,
                            name: 'Missing Cost Centre',
                        }
                )
            ),
        ]
    }
    @computed
    get projects() {
        return [...new Set(this.timeEntries.map((t) => t.project))]
    }
    @computed
    get projectsByCostCentreId() {
        return _.groupBy(
            [...new Set(this.timeEntries.map((t) => t.project))],
            (pr) => pr?.costCentreId
        )
    }
    @computed
    get phasesByProjectId() {
        return _.mapValues(
            _.groupBy(this.timeEntries, (ph) => ph?.projectId),
            (tes) => [
                ...new Set(tes.map((te) => te.phase || te.project?.rootPhase)),
            ]
        )
    }
    @computed
    get rowsByPhaseId() {
        const timeEntriesByPhaseId = _.groupBy(this.timeEntries, (te) =>
            tuple(
                te.projectId,
                te.phase ? te.phaseId : te.project?.rootPhase?.id
            )
        )
        let rowsByPhaseId = {}
        for (const [projectPhaseId, timeEntries] of Object.entries(
            timeEntriesByPhaseId
        )) {
            const filteredTimeEntries = timeEntries.filter((te) => {
                return (
                    te.numMinutes ||
                    (te.notes !== '' && te.notes !== null) ||
                    new Date() - (te.createdAt || te.updatedAt) <
                        1000 * 60 * 60 * 12 ||
                    (!timeEntries.find(
                        (t) =>
                            t.id !== te.id &&
                            t.date.getTime() === te.date.getTime() &&
                            t.taskId === te.taskId &&
                            t.isBillable === te.isBillable &&
                            t.isVariation === te.isVariation &&
                            t.isOvertime === te.isOvertime &&
                            t.isLocked === te.isLocked &&
                            (t.numMinutes ||
                                (t.notes !== '' && t.notes !== null))
                    ) &&
                        timeEntries.find(
                            (t) =>
                                t.date.getTime() === te.date.getTime() &&
                                t.taskId === te.taskId &&
                                t.isBillable === te.isBillable &&
                                t.isVariation === te.isVariation &&
                                t.isOvertime === te.isOvertime &&
                                t.isLocked === te.isLocked
                        ).id === te.id)
                )
            })
            const possibleRows = _.groupBy(filteredTimeEntries, (te) =>
                tuple(
                    te.taskId,
                    te.isBillable,
                    te.isVariation,
                    te.isOvertime,
                    te.isLocked
                )
            )
            rowsByPhaseId[projectPhaseId] = []
            for (const [key, timeEntries] of Object.entries(possibleRows)) {
                let entriesToAdd = [...timeEntries]
                while (entriesToAdd.length > 0) {
                    const entry = entriesToAdd.shift()
                    let rowIndex = 0
                    while (
                        rowsByPhaseId[projectPhaseId][rowIndex]?.[
                            entry.date.getTime()
                        ]
                    ) {
                        rowIndex++
                    }
                    rowsByPhaseId[projectPhaseId][rowIndex] ??= {
                        entries: [],
                        costCentreId: entry.costCentreId,
                        projectId: entry.projectId,
                        phaseId: entry.phaseId,
                        taskId: entry.taskId,
                        isBillable: entry.isBillable,
                        isVariation: entry.isVariation,
                        isOvertime: entry.isOvertime,
                        isLocked: entry.isLocked,
                    }
                    rowsByPhaseId[projectPhaseId][rowIndex].entries.push(entry)
                    rowsByPhaseId[projectPhaseId][rowIndex][
                        entry.date.getTime()
                    ] = entry
                }
                rowsByPhaseId[projectPhaseId].forEach((row) => {
                    this.daysOfWeek.forEach((day) => {
                        row[day.getTime()] ??= new TimeEntryModel(
                            {
                                date: day,
                                costCentreId: row.costCentreId,
                                projectId: row.projectId,
                                phaseId: row.phaseId,
                                taskId: row.taskId,
                                isBillable: row.isBillable,
                                isVariation: row.isVariation,
                                isOvertime: row.isOvertime,
                                isLocked: row.isLocked,
                                staffId: this.staffId || SessionStore.userId,
                                numMinutes: 0,
                            },
                            { trackUpdates: true, detached: true }
                        )
                    })
                })
            }
        }
        return rowsByPhaseId
    }

    @computed
    get staff() {
        return StaffCollection.staffsById[this.staffId] || SessionStore.user
    }
    @computed
    get startOfWeek() {
        return startOfWeek(this.date, { weekStartsOn: 1 })
    }
    @computed
    get daysOfWeek() {
        return [...Array(7)].map((v, i) => addDays(this.startOfWeek, i))
    }

    @computed
    get dailyTotals() {
        const totals = this.daysOfWeek.map((day) =>
            _.sum(
                (
                    TimeEntryCollection.timeEntriesByStaffIdDate[
                        tuple(this.staffId, day.getTime())
                    ] || []
                ).map((te) => te.numMinutes)
            )
        )
        return totals
    }
    @action.bound
    shiftPrevWeek() {
        router.navigate({
            search: (prev) => ({
                ...prev,
                date: format(subWeeks(this.date, 1), 'yyyy-MM-dd'),
            }),
        })
    }
    @action.bound
    shiftNextWeek() {
        router.navigate({
            search: (prev) => ({
                ...prev,
                date: format(addWeeks(this.date, 1), 'yyyy-MM-dd'),
            }),
        })
    }
    @action.bound
    shiftThisWeek() {
        router.navigate({
            search: (prev) =>
                _.omitBy(
                    {
                        ...prev,
                        date: null,
                    },
                    (v) => v === null
                ),
        })
    }
}

export default new TimesheetsWeeklyStore()
