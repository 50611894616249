import { data } from 'jquery'
import { observer } from 'mobx-react'
import React, { Suspense, useCallback, useEffect, useState } from 'react'
import PageHeader from '../Layout/PageHeader'
import LayoutStore from '../../State/LayoutStore'
import InvoiceCollection from '../../State/Collections/InvoiceCollection'
import { Dropdown3, Dropdown3ListItem } from '../../widgets'
import {
    beginAccountingOauth,
    syncInvoiceToAccounting,
} from '../../Queries/accountingOauth'
import SessionStore from '../../State/SessionStore'
import { capitalCase } from 'change-case'
import wait from 'wait'
import DeleteInvoiceModal from './DeleteInvoiceModal'
import InvoiceSettingsModal from '../InvoiceListPage/InvoiceSettingsModal'
import ConnectAccountingButton from '../../Components/ConnectAccountingButton'
import {
    canCreateInvoiceForProject,
    canEditInvoiceSettings,
    canEditProjectInvoices,
    canViewInvoiceSettings,
} from '../../State/Permissions/HasPermissions'
import DataStore from '../../State/DataStore'
import { useNavigate } from '@tanstack/react-router'
import { toJS } from 'mobx'

export default observer(({ extraContent, id }) => {
    const [syncState, setSyncState] = useState('idle')
    const navigate = useNavigate()
    const invoice =
        InvoiceCollection.invoicesById[id] ||
        InvoiceCollection.invoicesByOldId[id]
    if (!invoice) return null
    const project = invoice.project
    const canEditInvoice = canEditProjectInvoices(SessionStore.user, project)
    const canDeleteInvoice = canCreateInvoiceForProject(
        SessionStore.user,
        project
    )
    const canViewSettings = canViewInvoiceSettings(SessionStore.user)
    const canEditSettings = canEditInvoiceSettings(SessionStore.user)
    const accountingSystem = capitalCase(
        SessionStore.organisation.accountingSystem || ''
    )
    const accountingSystemConnected = SessionStore.accountingConnected
    return (
        <PageHeader
            heading={
                <div>
                    <div>
                        {invoice?.ref
                            ? `Invoice: ${invoice?.ref}`
                            : 'New Invoice'}
                    </div>
                </div>
            }
            rightButtons={
                <>
                    {canEditInvoice ? (
                        <ConnectAccountingButton
                            action="syncInvoice"
                            actionProps={{ invoice }}
                            style={{ margin: '0 1em' }}
                            onActionStart={async () => {
                                await DataStore.startSave()
                                if (DataStore.saveState === 'error') {
                                    throw 'Save Failed'
                                }
                            }}
                        />
                    ) : null}
                    <button
                        className="btn btn-default invoice-page__pdf-button flex-0-0-auto"
                        onClick={() => {
                            DataStore.startSave()
                            navigate({
                                to: '/invoices/$id/pdf',
                                params: { id: invoice.id },
                            })
                        }}
                        style={{ margin: '0 1em 0 0' }}
                    >
                        <i className="fa fa-print mr-2.5" />
                        PDF (Beta)
                    </button>
                    {canEditInvoice || canEditSettings || canDeleteInvoice ? (
                        <Dropdown3
                            toggleElement={
                                <button className="btn btn-default flex-0-0-auto invoice__settings-button">
                                    <i
                                        style={{ margin: 0 }}
                                        className="fa fa-cog"
                                    />
                                </button>
                            }
                            dropLeft={true}
                            contentStyle={{
                                width: '16em',
                                marginTop: '0.5em',
                                color: '#444',
                            }}
                            containerStyle={{ float: 'right' }}
                        >
                            {canEditInvoice ? (
                                <Dropdown3ListItem
                                    className="invoice__accounting-system-settings-button"
                                    style={{
                                        padding: '0.5em 1em',
                                        height: '2.5em',
                                    }}
                                    onClick={() => invoice.updateCachedData()}
                                >
                                    <div>
                                        <i className="fa fa-refresh fa-fw" />
                                        Update Invoice Data
                                    </div>
                                </Dropdown3ListItem>
                            ) : null}
                            {canEditSettings ? (
                                <Dropdown3ListItem
                                    className="invoice__accounting-system-settings-button"
                                    style={{
                                        padding: '0.5em 1em',
                                        height: '2.5em',
                                    }}
                                    onClick={() => {
                                        LayoutStore.openModal(
                                            <InvoiceSettingsModal
                                                modalId={'invoice-settings'}
                                            />
                                        )
                                    }}
                                >
                                    <div>
                                        <i className="fa fa-cog fa-fw" />
                                        Accounting Settings
                                    </div>
                                </Dropdown3ListItem>
                            ) : null}
                            {canDeleteInvoice ? (
                                <Dropdown3ListItem
                                    className="invoice__delete-button"
                                    style={{
                                        padding: '0.5em 1em',
                                        height: '2.5em',
                                    }}
                                    onClick={() =>
                                        LayoutStore.openModal(
                                            <DeleteInvoiceModal
                                                invoice={invoice}
                                                modalId="deleteInvoice"
                                            />
                                        )
                                    }
                                >
                                    <i className="fa fa-times fa-fw" />
                                    Delete Invoice
                                </Dropdown3ListItem>
                            ) : null}
                        </Dropdown3>
                    ) : null}
                </>
            }
            extraContent={extraContent}
        />
    )
})
