import { observable, computed, action, makeObservable } from 'mobx'
import Collection from './Collection'
import ProjectExpenseItemModel from '../Models/ProjectExpenseItemModel'
import { tuple } from 'immutable-tuple'

class ProjectExpenseItemCollection extends Collection {
    constructor() {
        super({
            collection: 'projectExpenseItems',
            modelClass: ProjectExpenseItemModel,
        })
        this.addLookup('expenseItemsByExpenseId', 'manyByKey', {
            key: (t) => t.expenseId,
            filter: (t) => !t.deletedAt,
        })
        this.addLookup('expenseItemsByProjectId', 'manyByKey', {
            key: (t) => t.projectId,
            filter: (t) => !t.deletedAt,
        })
        this.addLookup('expenseItemsByPhaseId', 'manyByKey', {
            key: (t) => t.phaseId,
            filter: (t) => !t.deletedAt,
        })
        this.addLookup('expenseItemsByStaffIdDate', 'manyByKey', {
            key: (t) => tuple(t?.staffId, t?.date?.getTime()),
            filter: (t) => !t?.deletedAt,
        })
        makeObservable(this)
    }
    get projectExpenseItemsById() {
        return this.modelsById
    }
    get projectExpenseItems() {
        return this.models
    }
    @action.bound
    addProjectExpenseItem(data) {
        this.add(data)
    }
    @action.bound
    addProjectExpenseItems(data) {
        this.addMany(data)
    }
}

export default new ProjectExpenseItemCollection()
export const ProjectExpenseItemCollectionClass = ProjectExpenseItemCollection
