import { addDays, differenceInBusinessDays, parse } from 'date-fns'
import { observable, computed, action, makeObservable } from 'mobx'
import PhaseCollection from '../Collections/PhaseCollection'
import ProjectCollection from '../Collections/ProjectCollection'
import ProjectExpenseAllocationCollection from '../Collections/ProjectExpenseAllocationCollection'
import ProjectExpenseCollection from '../Collections/ProjectExpenseCollection'
import Model from './Model'
import _ from 'lodash'
import InvoiceLineItemCollection from '../Collections/InvoiceLineItemCollection'
import SupplierCollection from '../Collections/SupplierCollection'
import ProjectExpenseItemCollection from '../Collections/ProjectExpenseItemCollection'

class ProjectExpenseModel extends Model {
    @observable projectId = null
    @observable phaseId = null
    @observable supplierId = null
    @observable name = null
    @observable quantity = null
    @observable unitCost = null
    @observable billable = null
    @observable cost = null
    @observable startDate = null
    @observable endDate = null
    @observable beenInvoiced = null
    @observable previousBilled = null

    constructor(data, options) {
        super()
        makeObservable(this)
        this.collection = ProjectExpenseCollection
        this.init(data, options)
    }
    @computed
    get project() {
        return ProjectCollection.modelsById[this.projectId]
    }
    @computed
    get phase() {
        return PhaseCollection.modelsById[this.phaseId]
    }
    @computed
    get supplier() {
        return SupplierCollection.modelsById[this.supplierId]
    }
    @computed
    get allocations() {
        return (
            ProjectExpenseAllocationCollection.expenseAllocationsByExpenseId[
                this.id
            ] || []
        )
    }
    @computed
    get invoiceLineItems() {
        return InvoiceLineItemCollection.lineItemsByExpenseId[this.id] || []
    }
    @computed
    get businessDays() {
        return _.max([
            differenceInBusinessDays(addDays(this.endDate, 1), this.startDate),
            1,
        ])
    }
    @computed
    get expenseItems() {
        return (
            ProjectExpenseItemCollection.expenseItemsByExpenseId[this.id] || []
        )
    }
    costInDateRange(dateRange) {
        if (dateRange[0] > this.endDate) return 0
        if (dateRange[1] < this.startDate) return 0
        const businessDays = differenceInBusinessDays(
            addDays(_.min([dateRange[1], this.endDate]), 1),
            _.max([dateRange[0], this.startDate])
        )
        const businessDayRatio = _.min([businessDays / this.businessDays, 1])
        return businessDayRatio * this.cost
    }
    allocatedCostInDateRange(dateRange) {
        return _.sum(
            this.allocations
                .filter((a) => a.date >= dateRange[0] && a.date <= dateRange[1])
                .map((a) => a.amount)
        )
    }
    allocatedCostInDateRangeAdjustedByLikelihood(dateRange) {
        const value = this.allocatedCostInDateRange(dateRange)
        if (
            ['prospective', 'onHold'].includes(this.phase?.status) &&
            this.phase?.percentLikelihood != undefined
        ) {
            return (
                value *
                Math.min((this.phase?.percentLikelihood ?? 100) / 100, 1)
            )
        } else {
            return value
        }
    }
    amountBilledInDateRange(dateRange) {
        return _.sum(
            this.invoiceLineItems
                .filter((a) => a.date >= dateRange[0] && a.date <= dateRange[1])
                .map((a) => a.amount)
        )
    }
}

export default ProjectExpenseModel
