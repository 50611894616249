import _ from 'lodash'
import React from 'react'
import {
    addDays,
    differenceInBusinessDays,
    endOfMonth,
    format,
    startOfMonth,
} from 'date-fns'
import { qf } from '../../Queries/queryFormatter'
import FetchStore from '../../Queries/FetchStore'
import { FormatCurrency } from '../../Utils/Localisation/CurrencyFormatter'
import StaffModel from '../../State/Models/StaffModel'

const cellStyles = (row, stores, monthStart, isPastMonth) => {
    return {
        ...(isPastMonth
            ? {
                  backgroundImage: `url(${new URL(
                      '~/public/diag.png',
                      import.meta.url
                  )})`,
              }
            : {}),
    }
}

export const RevenueForecastReportStaffColumns = (store) => ({
    title: (monthStartDate) => ({
        id: 'title',
        label: 'Title',
        type: 'text',
        width: 18,
        editable: (row) => false,
        value: (row, stores) => {
            return row.fullName
        },
        component: ({ value, group, stores }) => {
            const { row, table } = stores
            if (row.group === 'totals') return 'Total'
            return (
                <div style={{ paddingLeft: `${1 * row.groupLevel}rem` }}>
                    {value}
                </div>
            )
        },
    }),
    cost: (monthStartDate) => {
        const monthStart = startOfMonth(monthStartDate)
        const monthEnd = endOfMonth(monthStart)
        const dayAfterMonthEnd = startOfMonth(addDays(monthEnd, 1))
        const isPastMonth = monthEnd < startOfMonth(new Date())
        return {
            label: format(monthStartDate, 'MMM yy'),
            type: 'currency',
            width: 8,
            value: (row) => {
                if (
                    store.report?.filters.contractorExpense ===
                        'availableHours' ||
                    row.staffType === 'employee'
                ) {
                    return (
                        (row.getAvailabilityInDateRange([
                            monthStart,
                            monthEnd,
                        ]) /
                            60 /
                            5) *
                        differenceInBusinessDays(dayAfterMonthEnd, monthStart) *
                        row.getRateInDateRange('pay', [monthStart, monthEnd])
                    )
                } else {
                    const id = row.id
                    const resourceRow = FetchStore.getResponse(
                        'staffResourceRows' + row.id
                    )?.resourceRows?.rows?.[0]
                    return (
                        (resourceRow?.getHoursInMonth(
                            format(monthStartDate, 'yyyy-MM'),
                            store.report.filters.revenueData
                        ) || 0) *
                        row.getRateInDateRange('pay', [monthStart, monthEnd])
                    )
                }
            },
            data: (row) => {
                const staff = row?.rowObject
                if (
                    staff instanceof StaffModel &&
                    store.report?.filters.contractorExpense !==
                        'availableHours' &&
                    staff.staffType !== 'employee'
                ) {
                    return [
                        {
                            id: 'staffResourceRows' + staff.id,
                            collection: 'resourceRows',
                            data: {
                                dateRange: [
                                    qf(store.startDate),
                                    qf(store.endDate),
                                ],
                                groupBy: ['staff', 'role'],
                                period: 'monthly',
                                reportFilters: {
                                    staffId: staff.id,
                                    hoursData: store.report.filters.revenueData,
                                    futureRows: false,
                                },
                            },
                            staleTime: 1000 * 60,
                        },
                    ]
                } else {
                    return []
                }
            },
            editable: () => false,
            style: (row, stores) => {
                return cellStyles(row, stores, monthStart, isPastMonth)
            },
            format: (v) => FormatCurrency(v, { decimals: 0 }),
            aggregate: 'sum',
        }
    },
    total: (monthStartDate, store) => {
        return {
            id: 'total',
            label: (
                <span>
                    Total
                    <span
                        style={{ fontSize: '1.5em', float: 'right' }}
                        onClick={(e) => e.stopPropagation()}
                    >
                        <i
                            className={`fa fa-caret-left`}
                            style={{ cursor: 'pointer' }}
                            onClick={() => store.shiftDates(-1)}
                        />
                        <i
                            className={`fa fa-caret-right`}
                            style={{ marginRight: 0, cursor: 'pointer' }}
                            onClick={() => store.shiftDates(1)}
                        />
                    </span>
                </span>
            ),
            type: 'text',
            width: 18,
            editable: (row) => false,
            value: (row) => {
                return ''
            },
        }
    },
})
