import { observable, computed, action, makeObservable } from 'mobx'
import PhaseCollection from '../Collections/PhaseCollection'
import ProjectCollection from '../Collections/ProjectCollection'
import ProjectExpenseAllocationCollection from '../Collections/ProjectExpenseAllocationCollection'
import ProjectExpenseCollection from '../Collections/ProjectExpenseCollection'
import Model from './Model'

class ProjectExpenseAllocationModel extends Model {
    @observable projectId = null
    @observable phaseId = null
    @observable expenseId = null
    @observable date = null
    @observable amount = null

    constructor(data, options) {
        super()
        makeObservable(this)
        this.collection = ProjectExpenseAllocationCollection
        this.init(data, options)
    }
    @computed
    get project() {
        return ProjectCollection.modelsById[this.projectId]
    }
    @computed
    get phase() {
        return PhaseCollection.modelsById[this.phaseId]
    }
    @computed
    get expense() {
        return ProjectExpenseCollection.modelsById[this.expenseId]
    }
}

export default ProjectExpenseAllocationModel
