export function mergeQueries(queries) {
    const mergedQuery = {
        collection: '',
        fields: [],
        filters: [],
        subQueries: [],
        groupBy: [],
        chain: [],
    }

    for (const query of queries) {
        if (!query) return
        if (query.id) mergedQuery.id = query.id
        if (!mergedQuery.collection) {
            mergedQuery.collection = query.collection
        }

        mergedQuery.fields = makeUnique([
            ...mergedQuery.fields,
            ...(query.fields || []),
        ])
        mergedQuery.filters = makeUnique([
            ...mergedQuery.filters,
            ...(query.filters || []),
        ])
        mergedQuery.groupBy = makeUnique([
            ...mergedQuery.groupBy,
            ...(query.groupBy || []),
        ])

        if (query.join) {
            mergedQuery.join = query.join
        }
        if (query.limit) {
            mergedQuery.limit = query.limit
        }
        if (query.label) {
            mergedQuery.label = query.label
        }

        for (const subQuery of query.subQueries || []) {
            const existingSubQueryIndex = mergedQuery.subQueries.findIndex(
                (sq) =>
                    (sq.label || sq.collection) ===
                    (subQuery.label || subQuery.collection)
            )
            if (existingSubQueryIndex !== -1) {
                mergedQuery.subQueries[existingSubQueryIndex] = mergeQueries([
                    mergedQuery.subQueries[existingSubQueryIndex],
                    subQuery,
                ])
            } else {
                mergedQuery.subQueries.push(subQuery)
            }
        }
        for (const chainQuery of query.chain || []) {
            const existingSubQueryIndex = mergedQuery.chain.findIndex(
                (sq) => sq['collection'] === chainQuery['collection']
            )

            if (existingSubQueryIndex !== -1) {
                mergedQuery.chain[existingSubQueryIndex] = mergeQueries([
                    mergedQuery.chain[existingSubQueryIndex],
                    chainQuery,
                ])
            } else {
                mergedQuery.chain.push(chainQuery)
            }
        }
    }

    return mergedQuery
}
// Usage:
const inputQueries = [
    {
        collection: 'projects',
        fields: ['name', 'jobNumber'],
        filters: [],
        subQueries: [
            {
                collection: 'phases',
                join: 'id == phases.projectId',
                groupBy: ['projectId'],
                fields: ['name', 'jobNumber'],
                filters: [],
            },
        ],
    },
    {
        collection: 'projects',
        fields: [['fee', 'phases.fee']],
        subQueries: [
            {
                collection: 'phases',
                join: 'id == phases.projectId',
                groupBy: ['projectId'],
                fields: [['fee', 'sum(fee)']],
            },
        ],
    },
    {
        collection: 'projects',
        fields: [['revenue', 'phases.revenue']],
        subQueries: [
            {
                collection: 'phases',
                join: 'id == phases.projectId',
                groupBy: ['projectId'],
                fields: [['revenue', 'sum(agreedFeeLineItems.amount)']],
                subQueries: [
                    {
                        label: 'agreedFeeLineItems',
                        collection: 'invoiceLineItems',
                        join: 'id == agreedFeeLineItems.phaseId',
                        groupBy: ['phaseId'],
                        fields: [['amount', 'sum(amount)']],
                        filters: ["billingType == 'agreedFee'"],
                    },
                ],
            },
        ],
    },
    {
        collection: 'projects',
        fields: [['revenueVariation', 'phases.revenueVariation']],
        subQueries: [
            {
                collection: 'phases',
                join: 'id == phases.projectId',
                groupBy: ['projectId'],
                fields: [
                    ['revenueVariation', 'sum(variationLineItems.amount)'],
                ],
                subQueries: [
                    {
                        label: 'variationLineItems',
                        collection: 'invoiceLineItems',
                        join: 'id == variationLineItems.phaseId',
                        groupBy: ['phaseId'],
                        fields: [['amount', 'sum(amount)']],
                        filters: ["billingType == 'variation'"],
                    },
                ],
            },
        ],
    },
    {
        collection: 'projects',
        filters: ["status == 'active'"],
        subQueries: [
            {
                collection: 'phases',
                join: 'id == phases.projectId',
                groupBy: ['projectId'],
                filters: ['revenue > 0'],
            },
        ],
    },
]

// const mergedQuery = mergeQueries(inputQueries)
// console.log(mergedQuery)

function makeUnique(arr) {
    const uniqueArr = arr.filter((item, index, self) => {
        return (
            self.findIndex(
                (t) => JSON.stringify(t) === JSON.stringify(item)
            ) === index
        )
    })
    return uniqueArr
}
