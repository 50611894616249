import cuid from 'cuid'
import { observer } from 'mobx-react'
import React, { Suspense, useEffect, useRef, useState } from 'react'
import Modal from '../../Components/Modal'
import LayoutStore from '../../State/LayoutStore'
import InvoiceSettingsModal from '../InvoiceListPage/InvoiceSettingsModal'
export default observer(({ modal }) => {
    return [
        ...LayoutStore.modals.values(),
        ...(modal === 'invoice-settings'
            ? [<InvoiceSettingsModal modalId={'invoice-settings'} />]
            : []),
    ]
})
