import { observer } from 'mobx-react'
import React from 'react'
import DataStore from '../../State/DataStore'
import HeadShake from 'react-reveal/HeadShake'
import Pulse from 'react-reveal/Pulse'
import classNames from 'classnames'

const messages = {
    needsSaving: 'You have some unsaved changes.',
    saving: 'Saving changes...',
    error: "Oh no! We couldn't save your changes.",
    success: 'All saved :)',
    idle: 'Nothing to save.',
}

const colors = {
    needsSaving: '#009a81',
    saving: '#009a81',
    error: '#dc2d11',
    success: '#009a81',
    idle: '#009a81',
}

const icons = {
    needsSaving: '',
    saving: 'fa-spinner fa-pulse',
    error: 'fa-times',
    success: 'fa-check',
    idle: '',
}

const buttonText = {
    needsSaving: 'Save',
    error: 'Try Again',
}

const buttonFunc = {
    needsSaving: DataStore.startSave,
    error: DataStore.retrySave,
}
export default observer(() => {
    const Animation = DataStore.saveState === 'error' ? HeadShake : Pulse
    return (
        <Animation>
            <div
                className={classNames(
                    'fixed top-14 w-full z-[1000] text-center pointer-events-none',
                    DataStore.saveState !== 'idle' ? 'block' : 'hidden'
                )}
            >
                <div
                    className="save-bar dont-print bg-[#009a81] text-white w-[35%] mx-auto inline-block p-[0.5em] font-medium text-sm rounded-[0.5em]"
                    style={{ backgroundColor: colors[DataStore.saveState] }}
                >
                    <strong>
                        <i
                            className={'fa fa-fw ' + icons[DataStore.saveState]}
                        />
                        {messages[DataStore.saveState]}
                    </strong>
                    {['needsSaving', 'error'].includes(DataStore.saveState) ? (
                        <button className="save-bar__save-button px-[0.5em] rounded-[6px] bg-white border border-[#ccc] mx-[1em] text-444 cursor-pointer hover:bg-[#f0f0f0] pointer-events-auto">
                            <span onClick={buttonFunc[DataStore.saveState]}>
                                {buttonText[DataStore.saveState]}
                            </span>
                        </button>
                    ) : null}
                </div>
            </div>
        </Animation>
    )
})
