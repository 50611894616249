import { capitalCase } from 'change-case'
import { observer } from 'mobx-react'
import React, { useEffect, useState } from 'react'
import { Selector } from '../../Components/Selector'
import Table from '../../Components/Table'
import OverheadExpenseCollection from '../../State/Collections/OverheadExpenseCollection'
import { canEditOverheadExpenses } from '../../State/Permissions/HasPermissions'
import SessionStore from '../../State/SessionStore'

const columns = [
    {
        id: 'name',
        label: 'Name',
        type: 'text',
        width: 20,
        editable: (row) => canEditOverheadExpenses(SessionStore.user),
        value: (row) => {
            return row.name
        },
        onChange: (row) => (val) => row.update({ name: val }),
    },
    {
        id: 'costCentre',
        label: 'Cost Centre',
        type: 'costCentre',
        width: 20,
        editable: (row) => canEditOverheadExpenses(SessionStore.user),
        value: (row) => {
            return row.costCentre
        },
        onChange: (row) => (val) => row.update({ costCentreId: val.id }),
    },
    {
        id: 'value',
        label: 'Cost',
        type: 'currency',
        width: 10,
        editable: (row) => canEditOverheadExpenses(SessionStore.user),
        value: (row) => {
            return row.value
        },
        onChange: (row) => (val) => row.update({ value: val }),
    },
    {
        id: 'startDate',
        label: 'Start Date',
        type: 'date',
        width: 15,
        editable: (row) => canEditOverheadExpenses(SessionStore.user),
        value: (row) => {
            return row.startDate
        },
        onChange: (row) => (val) => row.update({ startDate: val }),
    },
    {
        id: 'endDate',
        label: 'End Date',
        type: 'date',
        width: 15,
        editable: (row) => canEditOverheadExpenses(SessionStore.user),
        value: (row) => {
            return row.endDate
        },
        onChange: (row) => (val) => row.update({ endDate: val }),
    },
    {
        id: 'hasRepeat',
        label: 'Repeats',
        type: 'checkbox',
        width: 8,
        editable: (row) => canEditOverheadExpenses(SessionStore.user),
        value: (row) => {
            return row.hasRepeat
        },
        onChange: (row) => (val) => row.update({ hasRepeat: val }),
    },
    {
        id: 'repeatQuantity',
        label: 'Every',
        type: 'number',
        width: 8,
        editable: (row) =>
            row.hasRepeat && canEditOverheadExpenses(SessionStore.user),
        value: (row) => {
            return row.hasRepeat ? row.repeatQuantity : '-'
        },
        onChange: (row) => (val) => row.update({ repeatQuantity: val }),
    },

    {
        id: 'repeatUnit',
        label: 'Period',
        width: 15,
        type: 'dropdown',
        editable: (r) =>
            r.hasRepeat && canEditOverheadExpenses(SessionStore.user),
        value: (r) => r.repeatUnit,
        onChange: (r) => (v) => r.update({ repeatUnit: v }),
        component: ({ value, editable, onChange, stores }) => {
            if (!editable)
                return (
                    <div>
                        {capitalCase(
                            stores.row.rowObject?.hasRepeat && value
                                ? value
                                : ''
                        )}
                    </div>
                )
            return (
                <Selector
                    selectedOption={value}
                    options={['days', 'weeks', 'months', 'years']}
                    optionLabel={(opt) => capitalCase(opt || '')}
                    onChange={onChange}
                    editable={editable}
                    variant="secondary"
                />
            )
        },
    },
    {
        label: '',
        width: 4,
        type: 'button',
        permissions: (r) => canEditOverheadExpenses(SessionStore.user),
        editable: (r) => canEditOverheadExpenses(SessionStore.user),
        value: (p) => <i className="fa fa-times" style={{ marginRight: 0 }} />,
        onClick: (r) => () => {
            r.update({ deletedAt: new Date() })
        },
    },
]

export default observer(() => {
    const [rows, setRows] = useState(
        OverheadExpenseCollection.activeOverheads.sort(
            (a, b) => b?.startDate?.getTime() - a?.startDate?.getTime()
        )
    )
    useEffect(() => {
        setRows(
            OverheadExpenseCollection.activeOverheads.sort(
                (a, b) => b?.startDate?.getTime() - a?.startDate?.getTime()
            )
        )
    }, [OverheadExpenseCollection.activeOverheads.length])
    return (
        <>
            <Table
                columns={columns}
                rows={rows.filter((r) => !r.deletedAt)}
                newRowDirection={'asc'}
            />
        </>
    )
})
