import { observer } from 'mobx-react'
import React, { Suspense, useCallback, useEffect } from 'react'
import OrganisationHolidayCollection from '../../State/Collections/OrganisationHolidayCollection'

import PageHeader from '../Layout/PageHeader'
import { canEditHolidays } from '../../State/Permissions/HasPermissions'
import SessionStore from '../../State/SessionStore'

export default observer(({ extraContent }) => {
    return (
        <PageHeader
            heading={
                <div>
                    <div>{'Organisation Holidays'}</div>
                </div>
            }
            extraContent={
                canEditHolidays(SessionStore.user) ? (
                    <button
                        className="btn btn-default add-timesheet-entry-button plus-btn mt-6"
                        onClick={() => {
                            const ph = OrganisationHolidayCollection.add(
                                {},
                                { trackUpdates: true }
                            )
                            ph.isNew = true
                        }}
                    >
                        + Add Holiday
                    </button>
                ) : null
            }
        />
    )
})
