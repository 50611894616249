import { observable, computed, action, makeObservable } from 'mobx'
import Collection from './Collection'
import PhaseModel from '../Models/PhaseModel'
import TaskCollection from './TaskCollection'
import { getDefaultTasks } from '../Permissions/HasPermissions'

class PhaseCollection extends Collection {
    constructor() {
        super({ collection: 'phases', modelClass: PhaseModel })
        this.addLookup('phasesByProjectId', 'manyByKey', {
            key: (t) => t.projectId,
            filter: (t) => !t.deletedAt,
        })
        makeObservable(this)
    }
    get phasesById() {
        return this.modelsById
    }
    get phases() {
        return this.models
    }
    @action.bound
    addPhase(data) {
        this.add(data)
    }
    @action.bound
    addPhases(data) {
        this.addMany(data)
    }
    @action.bound
    createPhase(projectId) {
        const phase = this.add(
            {
                name: 'New Phase',
                projectId: projectId,
                status: 'active',
            },
            { trackUpdates: true }
        )
        const task = TaskCollection.add(
            {
                name: '(No task)',
                projectId: projectId,
                phaseId: phase.id,
                isDefault: true,
                isBillable: phase.project?.costCentre?.isBillable ?? true,
                isVariation: false,
            },
            { trackUpdates: true }
        )
        getDefaultTasks().forEach((task) => {
            TaskCollection.add(
                {
                    projectId: projectId,
                    phaseId: phase.id,
                    isDefault: false,
                    ...task,
                },
                { trackUpdates: true }
            )
        })
        return phase
    }
    @action.bound
    createRootPhase(projectId) {
        const phase = this.add(
            {
                name: '(No Phase)',
                projectId: projectId,
                status: 'active',
                isRootPhase: true,
            },
            { trackUpdates: true }
        )
        return phase
    }
}

export default new PhaseCollection()
export const PhaseCollectionClass = PhaseCollection
