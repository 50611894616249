import { Link } from '@tanstack/react-router'

export const contactColumns = [
    {
        id: 'label',
        label: 'Label',
        type: 'text',
        width: 25,
        value: (row) => {
            return row.label
        },
        component: ({ value, stores }) => {
            const { row } = stores
            return (
                <div>
                    <Link to={`/contacts/${row.rowObject.id}`}>{value}</Link>
                </div>
            )
        },
    },
    {
        id: 'email',
        label: 'Email',
        type: 'text',
        width: 25,
        value: (row) => {
            return row.email
        },
    },
    {
        id: 'phone',
        label: 'Phone',
        type: 'text',
        width: 25,
        value: (row) => {
            return row.phone
        },
    },
    {
        id: 'address',
        label: 'Address',
        type: 'text',
        width: 25,
        value: (row) => {
            return row.address
        },
    },
]
