import React from 'react'
import { Slot } from '@radix-ui/react-slot'

// Simplified style object to replace Tailwind classes
const baseButtonStyle = {
    display: 'inline-flex',
    alignItems: 'center',
    justifyContent: 'center',
    whiteSpace: 'nowrap',
    borderRadius: '0.375rem', // Equivalent to rounded-md in Tailwind
    fontSize: '1.2rem', // Equivalent to text-sm in Tailwind
    fontWeight: 'medium', // Equivalent to font-medium in Tailwind
    transition: 'colors 0.2s', // Simplified version of transition-colors
    outline: 'none',
    cursor: 'pointer',
    opacity: 1, // Default state
    overflow: 'hidden',
}

// Basic button component without variant logic
const Button = React.forwardRef(
    ({ asChild = false, className, style, ...props }, ref) => {
        const Comp = asChild ? Slot : 'button'
        const finalStyle = { ...baseButtonStyle, ...style } // Combine base style with any passed inline style
        return (
            <Comp
                className={className}
                ref={ref}
                style={finalStyle} // Apply combined styles
                {...props}
            />
        )
    }
)
Button.displayName = 'Button'

export { Button }
