import { observer } from 'mobx-react'
import React, { Suspense, useRef, useState } from 'react'
import LayoutStore from '../State/LayoutStore'
import { Selector } from './Selector'

export default observer(
    ({
        modalId,
        style,
        bodyStyle,
        children,
        width,
        onClose,
        onSave,
        saveLabel,
        saveOptions,
        saveButtons,
        defaultSaveOption,
        heading,
        canSave = () => true,
        ...props
    }) => {
        const [selectedSaveOption, setSelectedSaveOption] = useState(
            saveOptions?.length
                ? saveOptions.find((o) => o.id === defaultSaveOption) ||
                      saveOptions[0]
                : null
        )
        return (
            <div className="bg-black/50 z-[10003] fixed inset-0 overflow-y-scroll">
                <div
                    className="flex max-h-[75%] my-[5em] mx-auto p-0 flex-col text-444 bg-white"
                    style={{
                        width: width || '40%',
                        ...style,
                    }}
                >
                    <ModalHeader>{heading}</ModalHeader>
                    <ModalBody style={bodyStyle}>{children}</ModalBody>
                    <ModalFooter
                        onClose={() => {
                            onClose && onClose()
                            LayoutStore.closeModal(modalId)
                        }}
                        onSave={async () => {
                            try {
                                if (onSave) {
                                    await onSave(selectedSaveOption)
                                }
                                LayoutStore.closeModal(modalId)
                            } catch (e) {
                                console.error(e)
                            }
                        }}
                        {...{
                            showSave: !!onSave,
                            saveLabel,
                            saveButtons,
                            saveOptions,
                            selectedSaveOption,
                            setSelectedSaveOption,
                            canSave,
                            closeModal: () => LayoutStore.closeModal(modalId),
                        }}
                    />
                </div>
            </div>
        )
    }
)

const ModalHeader = observer(({ children }) => {
    return (
        <div className="flex-grow-0 flex-shrink-0 basis-auto py-1.5 px-4 border-b border-b-[#ccc] relative h-[53px] p-2.5">
            <h3 className="mt-0">{children}</h3>
        </div>
    )
})

const ModalBody = observer(({ children, style }) => {
    return (
        <div
            className="modal-body flex-1 p-[1em] relative overflow-y-auto"
            style={{
                ...style,
            }}
        >
            {children}
        </div>
    )
})

const ModalFooter = observer(
    ({
        onClose,
        onSave,
        saveLabel = 'Save',
        saveButtons = [],
        saveOptions,
        selectedSaveOption,
        setSelectedSaveOption,
        showSave,
        closeModal,
        canSave,
    }) => {
        return (
            <div className="modal-footer flex-0-0-auto px-[1em] text-right border-t border-t-[#ddd] flex items-center justify-end">
                <button
                    className="btn btn-btn-default text-sm py-3 px-4 leading-8 whitespace-nowrap"
                    onClick={onClose}
                >
                    Cancel
                </button>
                {saveButtons.map(({ label, onClick }) => (
                    <button
                        className="btn btn-success"
                        key={label}
                        style={{ marginLeft: '1em' }}
                        onClick={() => {
                            onClick()
                            closeModal()
                        }}
                    >
                        {label}
                    </button>
                ))}
                {showSave && (
                    <button
                        disabled={!canSave()}
                        className="btn btn-success"
                        onClick={onSave}
                        style={{
                            marginLeft: '1em',
                            borderRadius: saveOptions?.length
                                ? '4px 0 0 4px'
                                : null,
                        }}
                    >
                        {saveLabel}
                    </button>
                )}
                {saveOptions?.length ? (
                    <Selector
                        disabled={!canSave()}
                        dropLeft={true}
                        options={saveOptions}
                        optionLabel={(o) => o.label}
                        selectedOption={selectedSaveOption}
                        onChange={setSelectedSaveOption}
                        style={{
                            borderRadius: saveOptions?.length
                                ? '0 4px 4px 0'
                                : null,
                        }}
                        className="max-w-max"
                        variant="secondary"
                    />
                ) : null}
            </div>
        )
    }
)
