export default {
    name: 'New Report',
    type: 'timesheet',
    columns: [
        'staffMember',
        'project',
        'projectPhase',
        'date',
        'task',
        'numMinutes',
        'cost',
        'chargeOut',
        'notes',
    ],
    filters: [
        {
            column: 'project',
            operator: 'in',
            value: [],
        },
        {
            column: 'staffMember',
            operator: 'in',
            value: [],
        },
    ],
    groupBy: ['project', 'projectPhase'],
    sortBy: [['label', 'asc']],
    options: {
        dateRange: 'thisMonth',
    },
}
