import {
    addDays,
    addMonths,
    addWeeks,
    addYears,
    differenceInDays,
    differenceInMonths,
    differenceInWeeks,
    differenceInYears,
} from 'date-fns'
import { observable, computed, action, makeObservable } from 'mobx'
import CostCentreCollection from '../Collections/CostCentreCollection'
import OverheadExpenseCollection from '../Collections/OverheadExpenseCollection'
import Model from './Model'

class OverheadExpenseModel extends Model {
    @observable name = null
    @observable value = null
    @observable startDate = null
    @observable endDate = null
    @observable hasRepeat = null
    @observable repeatQuantity = null
    @observable repeatUnit = null
    @observable costCentreId = null

    constructor(data, options) {
        super()
        makeObservable(this)
        this.collection = OverheadExpenseCollection
        this.init(data, options)
    }

    @computed
    get costCentre() {
        return CostCentreCollection.modelsById[this.costCentreId]
    }

    valueInDateRange(startDate, endDate) {
        if (!this.startDate) return 0
        if (this.startDate > endDate) return 0
        if (this.endDate && this.endDate < startDate) return 0

        const adjustedEndDate =
            this.endDate && this.endDate <= endDate ? this.endDate : endDate
        if (!this.hasRepeat || !this.repeatQuantity) {
            if (
                this.startDate >= startDate &&
                this.startDate <= adjustedEndDate
            ) {
                return this.value
            }
            return 0
        }

        const differenceLookup = {
            days: differenceInDays,
            weeks: differenceInWeeks,
            months: differenceInMonths,
            years: differenceInYears,
        }

        const addLookup = {
            days: addDays,
            weeks: addWeeks,
            months: addMonths,
            years: addYears,
        }

        const currentRepeatUnitDifference = differenceLookup[this.repeatUnit]
        const currentRepeatUnitAdd = addLookup[this.repeatUnit]

        const diff = currentRepeatUnitDifference(addDays(endDate, 1), startDate)
        const startDiff = currentRepeatUnitDifference(startDate, this.startDate)

        if (diff < 0) return 0

        let numRepeatsInRange = 0
        let currentRepeatDate = this.startDate
        while (currentRepeatDate <= adjustedEndDate) {
            if (currentRepeatDate >= startDate) {
                numRepeatsInRange++
            }
            currentRepeatDate = currentRepeatUnitAdd(
                currentRepeatDate,
                this.repeatQuantity
            )
        }

        return numRepeatsInRange * this.value
    }
}
export default OverheadExpenseModel
