import { observable, computed, action, makeObservable } from 'mobx'
import Collection from './Collection'
import WeeklyAllocationModel from '../Models/WeeklyAllocationModel'
import tuple from 'immutable-tuple'
import { addDays, isWeekend } from 'date-fns'

class WeeklyAllocationCollection extends Collection {
    constructor() {
        super({
            collection: 'weeklyAllocations',
            modelClass: WeeklyAllocationModel,
        })
        this.addLookup('allocationsByProjectId', 'manyByKey', {
            key: (t) => t.projectId,
            filter: (t) => !t.deletedAt,
        })
        this.addLookup('allocationsByPhaseId', 'manyByKey', {
            key: (t) => t.phaseId,
            filter: (t) => !t.deletedAt,
        })
        this.addLookup('allocationsByStaffId', 'manyByKey', {
            key: (t) => t.staffId,
            filter: (t) => !t.deletedAt,
        })
        this.addLookup('allocationsByRoleId', 'manyByKey', {
            key: (t) => t.roleId || t.staff?.roleId,
            filter: (t) => !t.deletedAt,
        })
        this.addLookup('allocationsByTaskId', 'manyByKey', {
            key: (t) => t.taskId,
            filter: (t) => !t.deletedAt,
        })
        this.addLookup('allocationsByCostCentreId', 'manyByKey', {
            key: (t) => t.costCentreId,
            filter: (t) => !t.deletedAt,
        })
        this.addLookup('allocationsByStaffIdDate', 'manyByKey', {
            key: (t) => tuple(t.staffId, t.date.getTime()),
            filter: (t) => !t.deletedAt,
        })
        this.addLookup('allocationsByStaffIdWeek', 'manyByKey', {
            key: (t) => {
                return tuple(t.staffId, t.week)
            },
            filter: (t) => !t.deletedAt,
        })
        makeObservable(this)
    }
    get allocationsById() {
        return this.modelsById
    }
    get allocations() {
        return this.models
    }
    get allocationsById() {
        return this.modelsById
    }
    @action.bound
    addAllocation(data, options) {
        this.add(data, options)
    }
    @action.bound
    addAllocations(data, options) {
        this.addMany(data, options)
    }
    @action.bound
    addAllocationsAcrossDateRange(dateRange, data, options) {
        const workingDays = []
        let date = dateRange[0]
        while (date <= dateRange[1]) {
            if (!isWeekend(date)) {
                workingDays.push(date)
            }
            date = addDays(date, 1)
        }
        const numMinutes = data.numMinutes / workingDays.length
        this.addMany(
            workingDays.map((date) => ({
                ...data,
                date,
                numMinutes,
            })),
            options
        )
    }
}

export default new WeeklyAllocationCollection()
export const WeeklyAllocationCollectionClass = WeeklyAllocationCollection
