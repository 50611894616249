import _ from 'lodash'
import { observer } from 'mobx-react'
import React, { useState } from 'react'
import Modal from '../../Components/Modal'
import DataStore from '../../State/DataStore'
import { useNavigate } from '@tanstack/react-router'

export default observer(({ contact, modalId }) => {
    const navigate = useNavigate()
    return (
        <Modal
            modalId={modalId}
            heading="Delete Contact"
            saveLabel={'Delete Contact'}
            onSave={() => {
                contact.update({ deletedAt: new Date() })
                DataStore.saveModel(contact)
                navigate({ to: '/contacts' })
            }}
        >
            <p style={{ margin: '1em' }}>
                Are you sure you wish to delete <em>{contact.label}</em>?
            </p>
        </Modal>
    )
})
