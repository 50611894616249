import { capitalCase } from 'change-case'
import { observer } from 'mobx-react'
import React, { useState } from 'react'
import wait from 'wait'
import InvoiceSettingsModal from '../Pages/InvoiceListPage/InvoiceSettingsModal'
import {
    beginAccountingOauth,
    syncInvoiceToAccounting,
    fetchAccountingSettings,
    fetchAccountingContacts,
} from '../Queries/accountingOauth'
import SessionStore from '../State/SessionStore'
import LayoutStore from '../State/LayoutStore'
import ErrorModal from './ErrorModal'
import * as Sentry from '@sentry/react'

const accountingActions = {
    connect: {
        getLabel: (accountingSystem) => `Connect To ${accountingSystem}`,
        icon: 'fa-plug',
        action: () => beginAccountingOauth,
        pendingLabel: 'Connecting...',
    },
    syncInvoice: {
        getLabel: (accountingSystem) => `Sync To ${accountingSystem}`,
        icon: 'fa-exchange',
        action: ({ invoice }) => syncInvoiceToAccounting(invoice),
        pendingLabel: 'Syncing Invoice...',
    },
    fetchSettings: {
        getLabel: (accountingSystem) => `Get ${accountingSystem} Settings`,
        icon: 'fa-cog',
        action: () => fetchAccountingSettings(),
        pendingLabel: 'Fetching Options...',
    },
    fetchContacts: {
        getLabel: (accountingSystem) => `Get ${accountingSystem} Contacts`,
        icon: 'fa-users',
        action: () => fetchAccountingContacts(),
        pendingLabel: 'Fetching Contacts...',
    },
}

const accountingSystemOptions = {
    xero: {
        id: 'xero',
        label: 'Xero',
        requiredData: ['salesAccount', 'taxOffTaxType', 'taxOnTaxType'],
        color: '#11b5ea',
        getInvoiceUrl: (id) => `https://go.xero.com/app/invoicing/edit/${id}`,
    },
    quickbooks: {
        id: 'quickbooks',
        label: 'Quickbooks',
        requiredData: ['taxOffTaxType', 'taxOnTaxType'],
        color: '#2ba01d',
        getInvoiceUrl: (id) =>
            `https://app.qbo.intuit.com/app/invoice?txnId=${id}`,
    },
    'myob-essentials': {
        id: 'myob-essentials',
        label: 'MYOB Essentials',
        requiredData: ['salesAccount', 'taxOffTaxType', 'taxOnTaxType'],
        color: '#6100a5',
        getInvoiceUrl: (id) =>
            `https://essentials.myob.com.au/LA/app.htm#businesses/390816/invoices/${id}`,
    },
    'myob-accountright': {
        id: 'myob-accountright',
        label: 'MYOB AccountRight',
        requiredData: ['salesAccount', 'taxOffTaxType', 'taxOnTaxType'],
        color: '#6100a5',
        getInvoiceUrl: (id) =>
            `https://accountright.myob.com.au/LA/app.htm#businesses/390816/invoices/${id}`,
    },
    none: {
        id: 'none',
        requiredData: [],
        getInvoiceUrl: () => '',
    },
}

export default observer(
    ({
        action = 'connect',
        actionProps = {},
        style = {},
        onActionSuccess = () => null,
        onActionError = () => null,
        onActionStart = () => null,
    }) => {
        const accountingSystemId =
            SessionStore.organisation.accountingSystem || 'none'
        const accountingSystem = accountingSystemOptions[accountingSystemId]
        const accountingSystemConnected = SessionStore.accountingConnected
        const accountingSystemSettings =
            SessionStore.organisation.accountingSystemSettings
        const accountingAction = accountingActions[action]
        const hasRequiredData = accountingSystem.requiredData.every(
            (key) => accountingSystemSettings?.settings?.[key]
        )
        const needsSettings =
            (accountingSystemId === 'none' || !hasRequiredData) &&
            action !== 'connect'
        const [actionState, setActionState] = useState('idle')
        if (needsSettings) {
            return (
                <button
                    className="btn btn-primary btn-connect"
                    onClick={() => {
                        LayoutStore.openModal(
                            <InvoiceSettingsModal
                                modalId={'invoice-settings'}
                            />
                        )
                    }}
                    style={{
                        backgroundColor: accountingSystem.color || '#428bca',
                        borderColor: 'white',
                        ...style,
                    }}
                >
                    <i className="fa fa-plug mr-2.5" />
                    Connect To {accountingSystem.label || 'Accounting System'}
                </button>
            )
        }
        if (action === 'connect' && accountingSystemId === 'none') {
            return null
        }
        if (!accountingSystemConnected) {
            return (
                <button
                    className="btn btn-primary btn-connect"
                    onClick={async () => {
                        try {
                            await onActionStart()
                            await beginAccountingOauth()
                        } catch (e) {
                            console.error(e)
                        }
                    }}
                    style={{
                        backgroundColor: accountingSystem.color || '#428bca',
                        borderColor: 'white',
                        ...style,
                    }}
                >
                    <i className="fa fa-plug mr-2.5" />
                    Connect To {accountingSystem.label || 'Accounting System'}
                </button>
            )
        }
        if (action === 'connect') {
            return (
                <button
                    className="btn btn-primary btn-connect"
                    style={{
                        backgroundColor: accountingSystem.color || '#428bca',
                        borderColor: 'red',
                        ...style,
                    }}
                    disabled={true}
                >
                    <i className="fa fa-check mr-2.5" />
                    Connected To {accountingSystem.label || 'Accounting System'}
                </button>
            )
        }
        return (
            <button
                className="btn btn-primary btn-connect"
                style={{
                    backgroundColor: accountingSystem.color || '#428bca',
                    borderColor: 'white',
                    ...style,
                }}
                onClick={async () => {
                    if (actionState !== 'idle') return null
                    setActionState('syncing')
                    try {
                        await onActionStart()
                        accountingAction
                            .action(actionProps)
                            .then((data) => {
                                setActionState('success')
                                onActionSuccess(data)
                                wait(1000).then(() => setActionState('idle'))
                            })
                            .catch((e) => {
                                Sentry.captureException(e)
                                console.warn(e?.response?.data)
                                setActionState('error')
                                onActionError(e)

                                LayoutStore.openModal(
                                    <ErrorModal
                                        modalId={'accounting-error'}
                                        heading={
                                            e?.response?.data?.name ||
                                            'Accounting System Error'
                                        }
                                        message={
                                            e?.response?.data?.message ||
                                            e.message
                                        }
                                    />
                                )
                                wait(1000).then(() => setActionState('idle'))
                            })
                    } catch (e) {
                        setActionState('idle')
                    }
                }}
            >
                <i
                    className={`fa ${
                        actionState === 'success'
                            ? 'fa-check'
                            : actionState === 'error'
                              ? 'fa-times'
                              : actionState === 'syncing'
                                ? 'fa-spinner fa-spin'
                                : accountingAction.icon
                    }`}
                />
                {actionState === 'success'
                    ? 'Success!'
                    : actionState === 'error'
                      ? 'Error!'
                      : actionState === 'syncing'
                        ? accountingAction.pendingLabel
                        : accountingAction.getLabel(accountingSystem.label)}
            </button>
        )
    }
)
