import { spanSetValueAtPoint } from '../Spanner'
import ratesToSpanSet from './ratesToSpanSet'

export default (rates, rateType, date) => {
    date ??= new Date()
    if (!rates?.length || !rateType) return 0
    rates = rates.filter((r) => r.date <= date).sort((a, b) => b.date - a.date)
    const rateName =
        rateType === 'availability' ? 'weeklyAvailability' : rateType + 'Rate'
    if (!rates.length) return 0
    return rates[0][rateName] ?? 0
}
