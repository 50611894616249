import React from 'react'
import { observer } from 'mobx-react'

export default observer(({ title, onLeftClick, onRightClick }) => {
    return (
        <div className="flex-1 overflow-y-auto border-b border-[#ccc] p-[0.5em] mb-[1em]">
            <div className="flex items-center justify-between">
                <div
                    style={{ cursor: 'pointer', padding: '0 1em' }}
                    onClick={() => onLeftClick()}
                >
                    <i className="fa fa-chevron-left" style={{ margin: 0 }} />
                </div>
                <span style={{ fontWeight: 'bold' }}>{title}</span>
                <div
                    style={{ cursor: 'pointer', padding: '0 1em' }}
                    onClick={() => onRightClick()}
                >
                    <i className="fa fa-chevron-right" style={{ margin: 0 }} />
                </div>
            </div>
        </div>
    )
})
