export const statusPriorities = {
    active: 1,
    onHold: 2,
    prospective: 3,
    archived: 4,
}

export const statusByPriority = {
    1: 'active',
    2: 'onHold',
    3: 'prospective',
    4: 'archived',
}
