import { endOfWeek, format, getWeek, parse, startOfWeek } from 'date-fns'
import tuple from 'immutable-tuple'
import { observer } from 'mobx-react'
import React, { Suspense, useCallback, useEffect, useState } from 'react'
import { StaffSelector } from '../../Components/Selector'
import DateValue from '../../Components/Widgets/DateValue'
import StaffCollection from '../../State/Collections/StaffCollection'
import TimeEntryCollection from '../../State/Collections/TimeEntryCollection'
import LayoutStore from '../../State/LayoutStore'
import SessionStore from '../../State/SessionStore'
import PageHeader from '../Layout/PageHeader'
import EditTimeEntryModal from './EditTimeEntryModal'
import TimesheetsDailyStore from './TimesheetsDailyStore'
import TimesheetsWeeklyStore from './TimesheetsWeeklyStore'
import { canEditStaffTime } from '../../State/Permissions/HasPermissions'
import { useNavigate } from '@tanstack/react-router'
import _ from 'lodash'

export default observer(({ date, tab, staffId, tabs, selectedTab }) => {
    const [store, setStore] = useState(
        selectedTab === 'daily' ? TimesheetsDailyStore : TimesheetsWeeklyStore
    )
    useEffect(() => {
        store.setSearchParams({ date, staffId })
    }, [date, staffId, tab])
    const startDate = startOfWeek(store.date, { weekStartsOn: 1 })
    const endDate = endOfWeek(store.date, { weekStartsOn: 1 })
    const navigate = useNavigate()
    return (
        <PageHeader
            heading={
                selectedTab === 'daily'
                    ? `Timesheets: ${format(store.date, 'dd MMM yyyy')}`
                    : `Timesheets: ${format(
                          startDate,
                          'dd MMM yyyy'
                      )} - ${format(endDate, 'dd MMM yyyy')}`
            }
            rightButtons={
                <button
                    className="btn btn-danger py-1.5 px-3 border bg-danger border-danger text-white rounded text-sm"
                    onClick={() => store.clearBlankTimeEntries()}
                >
                    <i className="fa fa-times mr-2.5" />
                    Clear Blank Time Entries
                </button>
            }
            extraContent={
                <>
                    <div className="text-white">
                        {'Try our mobile timesheet app at '}
                        <a
                            href="https://mobile.coincraft.co"
                            target="_blank"
                            className="!text-gold"
                        >
                            mobile.coincraft.co
                        </a>
                    </div>
                    <div className="flex gap-2 items-center mt-4">
                        <button
                            className="btn btn-default add-timesheet-entry-button plus-btn"
                            onClick={() =>
                                LayoutStore.openModal(
                                    <EditTimeEntryModal
                                        modalId={'addEntry'}
                                        dates={store.daysOfWeek}
                                        staff={store.staff}
                                        store={store}
                                    />
                                )
                            }
                        >
                            + Add time entry
                        </button>
                        {canEditStaffTime(SessionStore.user) ? (
                            <StaffSelector
                                className="timesheet__staff-selector"
                                selectedStaffMember={store.staff}
                                allowNull={false}
                                onChange={(staff) => {
                                    navigate({
                                        search: (prev) => ({
                                            ...prev,
                                            staffId: staff.id,
                                        }),
                                    })
                                }}
                            />
                        ) : null}
                        <DateValue
                            value={store.date}
                            onChange={(date) => {
                                navigate({
                                    search: (prev) => ({
                                        ...prev,
                                        date: format(date, 'yyyy-MM-dd'),
                                    }),
                                })
                            }}
                            style={{ width: '10em' }}
                        />
                    </div>
                </>
            }
            tabs={tabs}
            selectedTab={selectedTab}
        />
    )
})
