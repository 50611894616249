import { observable, computed, action, makeObservable } from 'mobx'
import StaffCollection from '../Collections/StaffCollection'
import ProjectCollection from '../Collections/ProjectCollection'
import PhaseCollection from '../Collections/PhaseCollection'
import TaskCollection from '../Collections/TaskCollection'
import Model from './Model'
import TimeEntryCollection from '../Collections/TimeEntryCollection'
import CostCentreCollection from '../Collections/CostCentreCollection'
import { format, getMonth, getWeek } from 'date-fns'
import RoleCollection from '../Collections/RoleCollection'

class TimeEntryModel extends Model {
    @observable staffId = null
    @observable roleId = null
    @observable costCentreId = null
    @observable projectId = null
    @observable phaseId = null
    @observable taskId = null

    @observable date = null
    @observable numMinutes = 0
    @observable startMinutes = 0

    @observable pay = 0
    @observable cost = 0
    @observable charge_out = 0

    @observable isBillable = null
    @observable isVariation = null
    @observable isOvertime = null
    @observable beenInvoiced = null
    @observable flexi = null
    @observable remote = null
    @observable isLocked = null

    @observable notes = ''
    @observable deleted = false

    constructor(data, options) {
        super()
        makeObservable(this)
        this.collection = TimeEntryCollection
        this.init(data, options)
    }
    @computed
    get staff() {
        return StaffCollection.modelsById[this.staffId]
    }
    @computed
    get role() {
        return RoleCollection.rolesById[this.roleId] || this.staff?.role
    }
    @computed
    get costCentre() {
        return CostCentreCollection.modelsById[this.costCentreId]
    }
    @computed
    get project() {
        return ProjectCollection.modelsById[this.projectId]
    }
    @computed
    get phase() {
        return PhaseCollection.modelsById[this.phaseId]
    }
    @computed
    get task() {
        return TaskCollection.modelsById[this.taskId]
    }
    @computed
    get endMinutes() {
        return this.startMinutes + this.numMinutes
    }
    @computed
    get hours() {
        return this.numMinutes / 60
    }
    @computed
    get payRate() {
        return this.pay / this.hours
    }
    @computed
    get costRate() {
        return this.cost / this.hours
    }
    @computed
    get chargeOutRate() {
        return this.chargeOut / this.hours
    }
    // @computed
    // get pay() {
    //     return this.payRate * this.hours
    // }
    // @computed
    // get cost() {
    //     return this.costRate * this.hours
    // }
    // @computed
    // get chargeOut() {
    //     return this.chargeOutRate * this.hours
    // }
    @computed
    get week() {
        return getWeek(this.date, { weekStartsOn: 1 })
    }
    @computed
    get month() {
        return format(this.date, 'yyyy-MM')
    }
}

export default TimeEntryModel
