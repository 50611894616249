import { observer } from 'mobx-react'
import React from 'react'
import PageHeader from '../Layout/PageHeader'
import PermissionCollection from '../../State/Collections/PermissionCollection'
import { canEditStaff } from '../../State/Permissions/HasPermissions'
import SessionStore from '../../State/SessionStore'
import DeletePermissionModal from './DeletePermissionModal'
import LayoutStore from '../../State/LayoutStore'

export default observer(({ id, extraContent }) => {
    const permission = PermissionCollection.permissionsById[id]
    if (!permission) return null
    return (
        <PageHeader
            heading={
                <div>
                    <div>
                        {permission?.name || 'New Permission'}
                        {permission.id ===
                        SessionStore.organisation.defaultPermissionsId
                            ? ' (Default)'
                            : null}
                    </div>
                </div>
            }
            rightButtons={
                <>
                    {canEditStaff(SessionStore.user) ? (
                        <button
                            className="btn btn-default edit-controls__delete-button mr-4"
                            onClick={() =>
                                SessionStore.organisation.update({
                                    defaultPermissionsId: permission.id,
                                })
                            }
                        >
                            <i className="fa fa-cog mr-1" />
                            Set as Default
                        </button>
                    ) : null}
                    {canEditStaff(SessionStore.user) ? (
                        <button
                            className="btn btn-default edit-controls__delete-button"
                            onClick={() =>
                                LayoutStore.openModal(
                                    <DeletePermissionModal
                                        permission={permission}
                                        modalId="deletePermission"
                                    />
                                )
                            }
                        >
                            <i className="fa fa-trash mr-1" />
                            Delete
                        </button>
                    ) : null}
                </>
            }
            extraContent={extraContent}
        />
    )
})
