import React from 'react'
import * as PopoverPrimitive from '@radix-ui/react-popover'

// Assuming a simple utility for classNames similar to the one you might be using
function cn(...classes) {
    return classes.filter(Boolean).join(' ')
}

const Popover = PopoverPrimitive.Root

const PopoverTrigger = PopoverPrimitive.Trigger

const PopoverAnchor = PopoverPrimitive.Anchor

// Inline styles as an alternative to Tailwind classes
const popoverStyles = {
    zIndex: 50,
    width: 'auto', // equivalent to w-72 in Tailwind
    maxWidth: '358px',
    borderRadius: '0.375rem', // equivalent to rounded-md in Tailwind
    border: '1px solid  #e5e7eb', // add your borderColor
    backgroundColor: '#ffffff', // bg-popover, replace with your color
    // padding: '1rem', // p-4 in Tailwind
    color: '#000000', // text-popover-foreground, replace with your color
    boxShadow:
        '0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06)', // shadow-md in Tailwind
    outline: 'none',

    minWidth: 'var(--radix-popover-trigger-width)',
    maxHeight: 'var(--radix-popover-content-available-height)',
    // Add animation and transition styles as necessary
}

const PopoverContent = React.forwardRef(
    ({ className, align = 'center', sideOffset = 4, style, ...props }, ref) => (
        <PopoverPrimitive.Portal>
            <PopoverPrimitive.Content
                ref={ref}
                align={align}
                sideOffset={sideOffset}
                className={cn(className)}
                style={{ ...popoverStyles, ...style }}
                {...props}
            />
        </PopoverPrimitive.Portal>
    )
)
PopoverContent.displayName = 'PopoverContent'

export { Popover, PopoverTrigger, PopoverContent, PopoverAnchor }
