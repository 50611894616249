import { observable, computed, action, makeObservable } from 'mobx'
import DefaultResourceScheduleReport from '../../reports/ResourceSchedule/DefaultResourceScheduleReport'
import ResourceScheduleReportCollection from '../Collections/ResourceScheduleReportCollection'
import Model from './Model'
import { isArray } from 'underscore'
import SessionStore from '../SessionStore'

class ResourceScheduleReportModel extends Model {
    @observable name = null
    @observable filters = null
    @observable queryKey = null
    @observable systemReport = false

    constructor(data, options) {
        super()
        makeObservable(this)
        this.collection = ResourceScheduleReportCollection
        data.filters = {
            ...DefaultResourceScheduleReport.filters,
            ...data.filters,
        }
        this.init(data, options)
        this.setQueryKey()
    }

    update(data, options, ignoreQueryKey = false) {
        super.update(data, options)
        if (!ignoreQueryKey) {
            this.setQueryKey()
        }
    }
    @action.bound
    updateFilters(data) {
        this.update({ filters: { ...this.filters, ...data } }, {}, true)
    }
    @computed
    get needsUpdate() {
        return this.queryKey !== this.makeQueryKey()
    }
    @action.bound
    setQueryKey() {
        this.queryKey = this.makeQueryKey()
    }
    makeQueryKey() {
        return JSON.stringify({
            filters: this.filters,
        })
    }

    @action.bound
    makeDefault() {
        SessionStore.organisation.update({
            defaultResourceScheduleReportId: this.id,
        })
    }
    @action.bound
    removeDefault() {
        SessionStore.organisation.update({
            defaultResourceScheduleReportId: null,
        })
    }

    // debouncedSetQueryKey = _.debounce(this.setQueryKey, 1000)
}

export default ResourceScheduleReportModel
