import { observable, computed, action, makeObservable } from 'mobx'
import PhaseAggregateCollection from './PhaseAggregateCollection'
import { addUnitToDate, newDate, unitsBetween } from '@ryki/datemath'
import _ from 'lodash'
import ProjectCollection from '../Collections/ProjectCollection'
import Model from '../Models/Model'

class PhaseAggregateModel extends Model {
    @observable jobNumber = null
    @observable name = null
    @observable projectId = null
    @observable startDate = null
    @observable endDate = null
    @observable fee = null
    @observable expenseBudget = null
    @observable hoursBudget = null
    @observable status = null
    @observable percentLikelihood = null
    @observable feeLinked = null
    @observable expenseBudgetLinked = null
    @observable hoursBudgetLinked = null
    @observable durationUnit = null

    constructor(data, options) {
        super()
        makeObservable(this)
        this.collection = PhaseAggregateCollection
        this.init(data, options)
    }
    @computed
    get title() {
        return this.jobNumber && this.name
            ? `${this.jobNumber}: ${this.name}`
            : this.name || this.jobNumber
    }
    @computed
    get project() {
        return ProjectCollection.modelsById[this.projectId]
    }

    @computed
    get duration() {
        return unitsBetween(this.startDate, this.endDate, this.durationUnit)
    }

    @computed
    get costCentre() {
        return this.project?.costCentre
    }

    @computed
    get contact() {
        return this.project?.contact
    }

    @computed
    get primaryContact() {
        return this.project?.primaryContact
    }

    @computed
    get owner() {
        return this.project?.owner
    }

    @computed
    get hasDates() {
        return this.startDate && this.endDate
    }

    @computed
    get isCurrent() {
        return (
            (!this.startDate && this.endDate >= new Date()) ||
            (!this.endDate && this.startDate <= new Date()) ||
            (this.endDate >= new Date() && this.startDate <= new Date())
        )
    }
    @computed
    get hasEnded() {
        return this.hasDates && !this.isCurrent
    }

    @computed
    get feeAdjustedByLikelihood() {
        if (
            ['prospective', 'onHold'].includes(this.status) &&
            this.percentLikelihood
        ) {
            return this.fee * (this.percentLikelihood / 100)
        } else {
            return this.fee
        }
    }
}

export default PhaseAggregateModel
