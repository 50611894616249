import { capitalCase } from 'change-case'

class HistoryEvent {
    model = null
    timestamp = null
    userId = null
    field = null
    value = null

    constructor(parentModel, event) {
        this.model = parentModel
        this.timestamp = event.timestamp
        this.userId = event.userId
        this.field = event.field
        this.value = event.value
    }

    get modelLabel() {
        return this.model.title
    }

    get date() {
        return new Date(this.timestamp)
    }

    get user() {
        const StaffCollection =
            require('../Collections/StaffCollection').default
        return StaffCollection.staffsById[this.userId]
    }

    get fieldLabel() {
        return capitalCase(this.field)
    }
}

export default HistoryEvent
