import { observable, computed, action, makeObservable } from 'mobx'
import StaffCollection from '../Collections/StaffCollection'
import StaffRateCollection from '../Collections/StaffRateCollection'
import Model from './Model'

class StaffRateModel extends Model {
    @observable staffId = null
    @observable date = null
    @observable payRate = null
    @observable chargeOutRate = null
    @observable costRate = null
    @observable weeklyAvailability = null
    @observable overtimeRate = null

    constructor(data, options) {
        super()
        makeObservable(this)
        this.collection = StaffRateCollection
        this.init(data, options)
    }
    @computed
    get staff() {
        return StaffCollection.modelsById[this.staffId]
    }
}

export default StaffRateModel
