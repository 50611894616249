import _ from 'lodash'
import { observer } from 'mobx-react'
import React, { useState } from 'react'
import Modal from '../../Components/Modal'
import SessionStore from '../../State/SessionStore'
import DataStore from '../../State/DataStore'
import TextValue from '../../Components/Widgets/TextValue'
import {
    StaffPermissionOptions,
    PermissionRow,
} from '../StaffPage/StaffPermissionsTab'
import { Radio, Switch, Button } from 'antd'
import { capitalCase } from 'change-case'
import { FormatCurrency } from '../../Utils/Localisation/CurrencyFormatter'
import BillingPageStore from './BillingPageStore'
import PermissionCollection from '../../State/Collections/PermissionCollection'
import { Selector } from '@/Components/Selector'

export default observer(({ staff, fee, modalId }) => {
    const [presetOption, setPresetOption] = useState(
        staff.permissionsId || 'custom'
    )
    return (
        <Modal
            modalId={modalId}
            heading={`${staff.fullName}'s Permissions`}
            saveLabel={'Save Email'}
            onSave={() => {
                DataStore.saveModel(staff)
            }}
        >
            <div
                style={{
                    padding: '3em 4em 4em 4em',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                }}
            >
                <h4 style={{ margin: 0 }}>{`Billing Level: ${capitalCase(
                    staff.billingLevel
                )} - ${FormatCurrency(
                    BillingPageStore.getFeeAtLevel(staff.billingLevel),
                    { decimal: 2 }
                )}`}</h4>
                <Switch
                    checked={!staff.isArchived}
                    onChange={(v) => {
                        staff.update({ isArchived: !v })
                    }}
                    checkedChildren="Is Active"
                    unCheckedChildren="Is Archived"
                />
                <Switch
                    checked={staff.hasLogin && !staff.isArchived}
                    onChange={(v) => {
                        staff.update({
                            hasLogin: v,
                            isArchived: staff.isArchived
                                ? !v
                                : staff.isArchived,
                        })
                    }}
                    checkedChildren="Has Login"
                    unCheckedChildren="Can't Login"
                />
            </div>

            <PermissionRow
                label={'Permission Presets'}
                className="mb-4 relative z-[10000000]"
            >
                <Selector
                    variant="secondary"
                    selectedOption={presetOption}
                    bordered
                    onChange={(optionId) => {
                        setPresetOption(optionId)
                        if (PermissionCollection.permissionsById[optionId]) {
                            staff.update({
                                permissionsId: optionId,
                                permissions:
                                    PermissionCollection.permissionsById[
                                        optionId
                                    ].settings,
                            })
                        } else {
                            staff.update({
                                permissionsId: null,
                            })
                        }
                    }}
                    style={{ fontSize: '0.9em', width: '20em' }}
                    options={[
                        ...[...PermissionCollection.permissions]
                            .sort((a, b) => a.name.localeCompare(b.name))
                            .map((p) => ({
                                label: `${p.name}`,
                                value: p.id,
                            })),
                        {
                            label: 'Custom',
                            value: 'custom',
                        },
                    ].filter((c) => c)}
                />
            </PermissionRow>

            <StaffPermissionOptions
                staff={staff}
                disabled={presetOption !== 'custom'}
            />
        </Modal>
    )
})
