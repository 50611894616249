import { observable, computed, action, makeObservable } from 'mobx'
import PhaseCollection from '../Collections/PhaseCollection'
import ProjectCollection from '../Collections/ProjectCollection'
import MonthlyExpenseCellCollection from '../Collections/MonthlyExpenseCellCollection'
import Model from './Model'
import { parse, startOfMonth } from 'date-fns'
import ProjectExpenseCollection from '../Collections/ProjectExpenseCollection'
import ProjectExpenseModel from './ProjectExpenseModel'

class MonthlyExpenseCellModel extends Model {
    @observable month = null
    @observable expense = 0
    @observable expenseToDate = 0
    @observable status = null
    @observable projectId = null
    @observable phaseId = null
    @observable expenseName = null
    @observable ownerId = null
    @observable costCentreId = null
    @observable type = null

    constructor(data, options) {
        super()
        makeObservable(this)
        this.collection = MonthlyExpenseCellCollection
        this.init(data, options)
    }

    @computed
    get phase() {
        return PhaseCollection.phasesById[this.phaseId]
    }

    @computed
    get project() {
        return ProjectCollection.projectsById[this.projectId]
    }

    @computed
    get projectExpense() {
        return (
            ProjectExpenseCollection.expensesByName[this.expenseName] ||
            ProjectExpenseCollection.add({
                projectId: this.projectId,
                phaseId: this.phaseId,
                name: this.expenseName,
            })
        )
    }

    serialize() {
        return {
            id: this.id,
            month: this.month,
            expense: this.expense,
            expenseName: this.expenseName,
            projectId: this.projectId,
            phaseId: this.phaseId,
        }
    }

    serializeUpdates() {
        return this.serialize()
    }
    @computed
    get date() {
        return startOfMonth(parse(this.month, 'yyyy-MM', new Date()))
    }
}

export default MonthlyExpenseCellModel
