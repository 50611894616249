// [{point: single point, span: between points, value}]

export function spanSetFromPointSet(pointValues, defaultBefore) {
    //[{point: single point, value}]
    const spans = [
        {
            span: [-Infinity, pointValues[0].point],
            value: defaultBefore !== undefined ? defaultBefore : 0,
        },
    ]
    pointValues.forEach((pv, i) => {
        spans.push({
            span: [
                pointValues[i].point,
                i !== pointValues.length - 1
                    ? pointValues[i + 1].point
                    : Infinity,
            ],
            value: pointValues[i].value || 0,
        })
    })
    return spans
}

export function pointSetFromSpanSet(spanValues) {
    const points = []
    spanValues.forEach((sv, i) => {
        if (!i) return
        points.push({
            point: sv.span[0],
            value: sv.value || 0,
        })
    })
}

export function pointSetValueAtPoint(pointSet, point, defaultValue) {
    return (
        pointSet
            .filter((p) => p.point <= point)
            .sort((a, b) => b.point - a.point)?.[0].value || defaultValue
    )
}

export function spanSetValueAtPoint(spanSet, point, defaultValue) {
    return (
        spanSet
            .filter((sv) => sv.span[0] <= point && sv.span[1] >= point)
            .sort((a, b) => b.span[0] - a.span[0])?.[0]?.value || defaultValue
    )
}

export function avgSpanSet(
    spanSet,
    multiplyFunc = (v, r) => v * r,
    addFunc = (l, r) => l + r
) {
    const totalLength = lengthFromSpan(spanFromSpanSet(spanSet))
    let avgValue
    spanSet.forEach((sv, i) => {
        const length = lengthFromSpan(sv.span)
        const lengthRatio = length / totalLength
        const adjustedValue = multiplyFunc(sv.value, lengthRatio)
        if (!i) {
            avgValue = adjustedValue
        } else {
            avgValue = addFunc(avgValue, adjustedValue)
        }
    })
    return avgValue
}

export function clampPointSet(pointSet, clamp) {
    let clampedPointSet = [
        ...pointSet
            .filter((p) => p.point >= clamp[0] && p.point <= clamp[1])
            .sort((a, b) => a.point - b.point),
    ]
    if (clampedPointSet[0].point > clamp[0]) {
        clampedPointSet.unshift({
            point: clamp[0],
            value: pointSetValueAtPoint([...pointSet], clamp[0]) || 0,
        })
    }
    return clampedPointSet
}

export function spanSetBetweenSpan(spanSet, span, clamp) {
    const newSpanSet = []
    spanSet.forEach((sv) => {
        if (sv.span[1] >= span[0] && sv.span[0] <= span[1]) {
            newSpanSet.push({
                span: [
                    clamp && sv.span[0] < clamp[0] ? clamp[0] : sv.span[0],
                    clamp && sv.span[1] > clamp[1] ? clamp[1] : sv.span[1],
                ],
                value: sv.value || 0,
            })
        }
    })
    return newSpanSet.sort((a, b) => a.span[0] - b.span[0])
}

function lengthFromSpan(span) {
    return span[1] - span[0]
}

// function averageSpanSetValueBetweenSpan(
//     spanSet,
//     span,
//     defaultBefore,
//     defaultAfter
// ) {
//     let spansForCalc = spanSetBetweenSpan(spanSet, span, span).sort(
//         (a, b) => a.span[0] - b.span[0]
//     )
//     if (spansForCalc[0].span[0] >= span[0]) {
//         spansForCalc.unshift({
//             span: [span[0], spanSet[0].span[0]],
//             value: defaultBefore !== undefined ? defaultBefore : null,
//         })
//     }
//     spansForCalc = spansForCalc.sort((a, b) => b.span[1] - a.span[1])
//     if (spansForCalc[spansForCalc.length - 1].span[1] <= span[1]) {
//         spansForCalc.push({
//             span: [spanSet[0].span[1], span[1]],
//             value: defaultAfter !== undefined ? defaultAfter : null,
//         })
//     }
//     const totalLength = lengthFromSpan(span)
//     let value = 0
//     spansForCalc.forEach((sv) => {
//         const length = lengthFromSpan(sv.span)
//         const ratio = length / totalLength
//         value += ratio * sv.svalue
//     })
//     return value
// }

function pointsFromSpanSet(spanSet) {
    const points = new Set()
    spanSet.forEach((sv) => {
        if (!(sv.span?.[0] && sv.span?.[1])) return
        points.add(sv.span[0])
        points.add(sv.span[1])
    })
    return [...points]
}

function spanFromSpanSet(spanSet) {
    let span = null
    spanSet.forEach((sv, i) => {
        if (!(sv.span?.[0] && sv.span?.[1])) return
        if (!i) {
            span = [...sv.span]
        } else {
            if (span[0] > sv.span[0]) span[0] = sv.span[0]
            if (span[1] < sv.span[1]) span[1] = sv.span[1]
        }
    })
    return span
}

function splitSpanSetAtPoints(spanSet, points, defaultBefore, defaultAfter) {
    const newSpanSet = []
    const allPoints = [
        ...new Set([...points, ...pointsFromSpanSet(spanSet)]),
    ].sort((a, b) => a - b)
    let spanSetSpan = spanFromSpanSet(spanSet) || [Infinity, -Infinity]
    let spanIndex = 0
    allPoints.forEach((p, i) => {
        const p2 = points[i + 1]
        if (i !== allPoints.length - 1) {
            while (
                p >= spanSetSpan[0] &&
                p2 <= spanSetSpan[1] &&
                !(
                    p >= spanSet[spanIndex].span[0] &&
                    p2 <= spanSet[spanIndex].span[1]
                ) &&
                spanIndex < spanSet.length
            ) {
                spanIndex++
            }
            newSpanSet.push({
                span: [p, p2],
                value:
                    p < spanSetSpan[0]
                        ? defaultBefore
                        : p >= spanSetSpan[1]
                        ? defaultAfter
                        : spanSet[spanIndex].value,
            })
        }
    })
    return newSpanSet
}

export function combineSpanSets(
    spanSets,
    combineFunc = (v) => v,
    defaultBefore,
    defaultAfter,
    clamp
) {
    spanSets = spanSets.filter((ss) => ss.length)
    const uniquePoints = uniquePointsFromSpanSets(spanSets, clamp)
    const splitSpanSets = spanSets.map((ss) =>
        splitSpanSetAtPoints(ss, uniquePoints, defaultBefore, defaultAfter)
    )
    const combinedSet = []
    const firstSet = splitSpanSets[0]
    firstSet?.forEach((sss, i) => {
        combinedSet.push({
            span: sss.span,
            value: combineFunc(...splitSpanSets.map((ss) => ss[i].value)) || 0,
        })
    })
    return combinedSet
}

function uniquePointsFromSpanSets(spanSets, clamp) {
    const uniquePoints = new Set()
    if (clamp) {
        uniquePoints.add(clamp[0])
        uniquePoints.add(clamp[1])
    }
    spanSets.flat().forEach((sv) => {
        if (!(sv.span?.[0] && sv.span?.[1])) return
        if (!clamp) {
            uniquePoints.add(sv.span[0])
            uniquePoints.add(sv.span[1])
        } else {
            if (sv.span[0] >= clamp[0] && sv.span[0] <= clamp[1])
                uniquePoints.add(sv.span[0])
            if (sv.span[1] >= clamp[0] && sv.span[1] <= clamp[1])
                uniquePoints.add(sv.span[1])
        }
    })
    return [...uniquePoints].sort((a, b) => a - b)
}
