import { observer } from 'mobx-react'
import React, { Suspense, useCallback, useEffect } from 'react'
import ContactCollection from '../../State/Collections/ContactCollection'
import LayoutStore from '../../State/LayoutStore'

import PageHeader from '../Layout/PageHeader'
import ImportContactsModal from './ImportContactsModal'
import { canEditContacts } from '../../State/Permissions/HasPermissions'
import SessionStore from '../../State/SessionStore'
import { FilterTextBox } from '../../widgets'
import ContactStore from './ContactStore'
import HeaderButton from '../../Components/ui/HeaderButton'
import { useNavigate } from '@tanstack/react-router'
import { contactColumns } from './ContactColumns'
import download from 'downloadjs'
import Papa from 'papaparse'

const downloadCSV = () => {
    const rows = [contactColumns.map((c) => c.label)]
    ContactCollection.contacts.forEach((contact) => {
        rows.push(contactColumns.map((c) => c.value(contact)))
    })
    download(Papa.unparse(rows), 'contacts.csv', 'text/csv')
}

export default observer(() => {
    const navigate = useNavigate()
    return (
        <PageHeader
            heading={
                <div>
                    <div>{'Contacts'}</div>
                </div>
            }
            filter={
                <FilterTextBox
                    style={{ width: '19em' }}
                    value={ContactStore.searchFilter || ''}
                    onChange={(val) => ContactStore.updateSearchFilter(val)}
                    placeholder={'Find Contact...'}
                />
            }
            extraContent={
                <div className="mt-6">
                    {canEditContacts(SessionStore.user)
                        ? [
                              <button
                                  key={0}
                                  className="project-list__add-new-project-button page-header__button--primary plus-btn"
                                  onClick={() => {
                                      const contact =
                                          ContactCollection.createContact()

                                      navigate({
                                          to: '/contacts/$id',
                                          params: { id: contact.id },
                                      })
                                  }}
                              >
                                  + New Contact
                              </button>,
                              <button
                                  key={0}
                                  className="project-list__add-new-project-button page-header__button--primary plus-btn ml-4"
                                  onClick={() => {
                                      LayoutStore.openModal(
                                          <ImportContactsModal
                                              modalId={'import-contacts'}
                                          />
                                      )
                                  }}
                              >
                                  + Import From Accounting System
                              </button>,
                          ]
                        : null}
                </div>
            }
            rightButtons={
                <div className="flex items-center gap-2">
                    <HeaderButton
                        label="Export"
                        onClick={() => downloadCSV()}
                        icon={<i className="fa fa-table" />}
                    />
                    <HeaderButton
                        label="Print"
                        onClick={() => window.print()}
                        icon={<i className="fa fa-print" />}
                    />
                </div>
            }
            // rightButtons={
            //     <div style={{ display: 'inline-block' }}>
            //         <button
            //             className="export-button page-header__button"
            //             onClick={() => report.downloadCSV()}
            //         >
            //             <i className="fa fa-table" />
            //             Export
            //         </button>
            //         <button
            //             className="print-button page-header__button"
            //             onClick={() => window.print()}
            //         >
            //             <i className="fa fa-print" />
            //             Print
            //         </button>
            //     </div>
            // }
            // filter={
            //     <ReportSearch
            //         report={report}
            //         placeholder="Filter by staff name"
            //     />
            // }
            // extraContent={
            //     <ReportHeader
            //         report={report}
            //         reportOptions={
            //             ReportCollection.reportsByType['staffMember']
            //         }
            //         baseUrl={'/staff'}
            //     />
            // }
        />
    )
})
