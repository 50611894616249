import { observer } from 'mobx-react'
import React, { useState, useRef, useEffect } from 'react'

export default observer(({ className, style, children, isLoading }) => {
    const [isContentWider, setIsContentWider] = useState(false)
    const containerRef = useRef(null)
    const contentRef = useRef(null)

    useEffect(() => {
        const checkWidth = () => {
            if (containerRef.current && contentRef.current && !isLoading) {
                setIsContentWider(
                    contentRef.current.scrollWidth >
                        containerRef.current.clientWidth
                )
            }
        }

        // Initial check
        checkWidth()

        // Set up a MutationObserver to watch for changes in the content
        const observer = new MutationObserver(checkWidth)
        if (contentRef.current) {
            observer.observe(contentRef.current, {
                childList: true,
                subtree: true,
            })
        }

        // Check on window resize
        window.addEventListener('resize', checkWidth)

        return () => {
            observer.disconnect()
            window.removeEventListener('resize', checkWidth)
        }
    }, [isLoading])

    return (
        <div
            ref={containerRef}
            className={`coincraft-page-layout__page-body relative w-full h-full shadow-md print:shadow-none print:h-auto print:block flex-auto bg-neutral-50 flex overflow-auto p-8 pb-28 ${isLoading || !isContentWider ? 'justify-center' : 'justify-start'}`}
            style={style}
        >
            <div
                ref={contentRef}
                className={`relative ${!isLoading && isContentWider ? 'mr-auto' : ''}`}
            >
                {children}
                <div className="h-28 dont-print"></div>
            </div>
        </div>
    )
})
