import { observable, computed, action, makeObservable } from 'mobx'
import Collection from './Collection'
import TaskModel from '../Models/TaskModel'

class TaskCollection extends Collection {
    constructor() {
        super({ collection: 'tasks', modelClass: TaskModel })
        this.addLookup('tasksByPhaseId', 'manyByKey', {
            key: (t) => t.phaseId,
            filter: (t) => !t.deletedAt,
        })
        this.addLookup('tasksByProjectId', 'manyByKey', {
            key: (t) => t.projectId,
            filter: (t) => !t.deletedAt,
        })
        makeObservable(this)
    }
    get tasksById() {
        return this.modelsById
    }
    get tasks() {
        return this.models
    }
    @action.bound
    addTask(data) {
        this.add(data)
    }
    @action.bound
    addTasks(data) {
        this.addMany(data)
    }
}

export default new TaskCollection()
export const TaskCollectionClass = TaskCollection
