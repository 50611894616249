import React, { useState } from 'react'
import { observer } from 'mobx-react'
import Modal from '../../Components/Modal'
import TimeEntryCollection from '../../State/Collections/TimeEntryCollection'
import { Selector, TaskSelector } from '../../Components/Selector'
import { FilterTextBox } from '../../widgets'
import _ from 'lodash'
import classNames from 'classnames'
import InvoiceLineItemCollection from '../../State/Collections/InvoiceLineItemCollection'
import { format } from 'date-fns'
import Checkbox from '../../Components/Widgets/Checkbox'
import useHover from '@react-hook/hover'
import { Set } from 'immutable'

export default observer(({ modalId, lineItem }) => {
    const timeEntries = new Set(
        TimeEntryCollection.timeEntries.filter((te) => {
            return (
                (te.hours || te.notes) &&
                !te.deletedAt &&
                te.staff &&
                te.project === lineItem.project &&
                te.phase == lineItem.phase &&
                te.date <= lineItem.invoice.endDate &&
                te.date >= lineItem.invoice.startDate
            )
        })
    )
    const usedTimeEntries = new Set(
        lineItem.invoice.timeEntries.filter((te) => te.phase == lineItem.phase)
    )
    const initialSelectedTimeEntries = new Set(lineItem.timeEntries)
    let [selectedTimeEntries, setSelectedTimeEntries] = useState(
        new Set(initialSelectedTimeEntries)
    )
    return (
        <Modal
            modalId={modalId}
            heading="Billing for the following time entries."
            saveLabel={'Save Billed Time Entries'}
            onSave={() => {
                lineItem.update({
                    timesheetIds: [...selectedTimeEntries].map((te) => te.id),
                })
                initialSelectedTimeEntries.forEach(
                    (te) => te.update({ beenInvoiced: false }) // will be set back to true if still selected
                )
                selectedTimeEntries.forEach((te) =>
                    te.update({ beenInvoiced: true })
                )
            }}
            bodyStyle={{ padding: 0 }}
        >
            <div
                style={{
                    maxHeight: '20em',
                    overflowY: 'auto',
                }}
            >
                {[...timeEntries]
                    .sort((a, b) => b.date - a.date)
                    .map((te) => (
                        <TimesheetRow
                            {...{
                                te,
                                selectedTimeEntries,
                                setSelectedTimeEntries,
                                usedTimeEntries,
                                initialSelectedTimeEntries,
                            }}
                        />
                    ))}
            </div>
        </Modal>
    )
})

const TimesheetRow = ({
    te,
    selectedTimeEntries,
    setSelectedTimeEntries,
    usedTimeEntries,
    initialSelectedTimeEntries,
}) => {
    const target = React.useRef(null)
    const isHovering = useHover(target, { enterDelay: 200, leaveDelay: 200 })
    const usedInThisInvoice =
        usedTimeEntries.has(te) &&
        !initialSelectedTimeEntries.has(te) &&
        !selectedTimeEntries.has(te)
    const usedInAnotherInvoice =
        te.beenInvoiced &&
        !initialSelectedTimeEntries.has(te) &&
        !selectedTimeEntries.has(te)
    const disabled = usedInThisInvoice || usedInAnotherInvoice
    return (
        <div
            ref={target}
            style={{
                borderBottom: '1px solid #eee',
                background: isHovering && !disabled ? '#eee' : 'transparent',
                cursor: 'pointer',
                color: !disabled ? '#444' : '#888',
                padding: '0.25em 1em',
            }}
            onClick={() => {
                if (!disabled) {
                    if (!selectedTimeEntries.has(te)) {
                        setSelectedTimeEntries(
                            new Set(selectedTimeEntries.add(te))
                        )
                    } else {
                        setSelectedTimeEntries(
                            new Set(selectedTimeEntries.delete(te))
                        )
                    }
                }
            }}
        >
            <div style={{ display: 'flex', padding: '0.5em', width: '100%' }}>
                <div
                    style={{
                        marginRight: '2em',
                        display: 'flex',
                        flex: '0 0 auto',
                    }}
                >
                    <Checkbox
                        style={{ marginRight: '1em' }}
                        value={
                            disabled
                                ? usedTimeEntries.has(te)
                                : selectedTimeEntries.has(te)
                        }
                        onChange={() => null}
                        disabled={disabled}
                    />
                    <div>{format(te.date, 'dd/MM/yyyy')}</div>
                </div>
                <div style={{ flex: '1 1 auto' }}>
                    <div
                        style={{
                            display: 'flex',
                            justifyContent: 'space-between',
                        }}
                    >
                        <div style={{ marginRight: '1em' }}>{`${
                            te.staff.fullName
                        } ${
                            disabled
                                ? `(billed ${
                                      usedInThisInvoice
                                          ? 'in this invoice'
                                          : 'in another invoice'
                                  })`
                                : ''
                        }`}</div>
                        <div
                            style={{ textAlign: 'right' }}
                        >{`${te.hours} hours`}</div>
                    </div>
                    <div>{te.notes}</div>
                </div>
            </div>
        </div>
    )
}
