import { observer } from 'mobx-react'
import React from 'react'
import ProjectCollection from '../../State/Collections/ProjectCollection'
import TaskCollection from '../../State/Collections/TaskCollection'
import { Checkbox, SmallDeleteButton } from '../../widgets'
import { canEditPhasesAndTasks } from '../../State/Permissions/HasPermissions'
import SessionStore from '../../State/SessionStore'

export default observer(({ id }) => {
    const project = ProjectCollection.projectsById[id]
    const isEditable = canEditPhasesAndTasks(SessionStore.user, project)
    if (!project) return null
    return (
        <div style={{ width: '60em' }}>
            {project.phases
                .filter((ph) => !ph?.isRootPhase)
                .map((phase) => (
                    <div key={phase.id}>
                        <h3 className="border-b border-[#ccc]">
                            {phase.title}
                        </h3>
                        <div style={{ margin: '1em 0 2em 0' }}>
                            {phase.tasks.map((task) => (
                                <div
                                    className="flex items-center justify-between border-b border-[#ddd] p-[0.5em]"
                                    key={task.id}
                                >
                                    {!task.isDefault && isEditable ? (
                                        <input
                                            type="text"
                                            placeholder="task name"
                                            value={task.name || ''}
                                            onChange={(event) =>
                                                task.update({
                                                    name: event.target.value,
                                                })
                                            }
                                            className="w-[18em] input-base"
                                        />
                                    ) : (
                                        <div className="inline-block w-[18em]">
                                            {task.name}
                                        </div>
                                    )}
                                    <div className="inline-block ml-[1em]">
                                        <Checkbox
                                            className="staff-member-name"
                                            value={task.isBillable}
                                            label="Billable"
                                            disabled={!isEditable}
                                            onChange={(checked) =>
                                                task.update({
                                                    isBillable: checked,
                                                })
                                            }
                                        />
                                    </div>
                                    <div
                                        style={{
                                            display: 'inline-block',
                                            marginLeft: '1em',
                                        }}
                                    >
                                        <Checkbox
                                            className="staff-member-name"
                                            value={task.isVariation}
                                            label="Variation"
                                            disabled={!isEditable}
                                            onChange={(checked) =>
                                                task.update({
                                                    isVariation: checked,
                                                })
                                            }
                                        />
                                    </div>
                                    <SmallDeleteButton
                                        onClick={() =>
                                            task.update({
                                                deletedAt: new Date(),
                                            })
                                        }
                                        disabled={!isEditable || task.isDefault}
                                    />
                                </div>
                            ))}
                        </div>
                        {isEditable ? (
                            <div style={{ padding: '0.5em' }}>
                                <button
                                    style={{
                                        padding: '0.5em 1.5em',
                                        fontSize: '0.85em',
                                        marginTop: '0.5em',
                                    }}
                                    className="btn btn-default plus-btn border-[#ccc]"
                                    onClick={() => {
                                        const b = TaskCollection.add(
                                            {
                                                projectId: phase.projectId,
                                                phaseId: phase.id,
                                                isBillable:
                                                    phase.project?.costCentre
                                                        ?.isBillable ?? true,
                                            },
                                            { trackUpdates: true }
                                        )
                                        b.position = phase.tasks.length
                                    }}
                                >
                                    + Add Task
                                </button>
                            </div>
                        ) : null}
                    </div>
                ))}
        </div>
    )
})
