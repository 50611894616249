import { format, parse, parseISO } from 'date-fns'
import SessionStore from '../State/SessionStore'
import { makeRequest } from './makeRequest'
import PhaseCollection from '../State/Collections/PhaseCollection'
import { canEnterTimeAgainstPhase } from '../State/Permissions/HasPermissions'
import ProjectCollection from '../State/Collections/ProjectCollection'
import TaskCollection from '../State/Collections/TaskCollection'

export const getPreviousWeekTime = async (staffId, beforeDate = new Date()) => {
    let r = await makeRequest({
        path: `api/v1.5/previous-time/weekly`,
        method: 'post',
        data: {
            staffId,
            beforeDate: format(beforeDate, 'yyyy-MM-dd'),
        },
    })
    const phases = r.data?.phases || []
    PhaseCollection.addMany(phases, { trackUpdates: false })
    const tasks = r.data?.tasks || []
    TaskCollection.addMany(tasks, { trackUpdates: false })
    return (r.data?.timeEntries || []).filter((te) => {
        const phase = PhaseCollection.phasesById[te.phaseId]
        const project = ProjectCollection.projectsById[te.projectId]
        if (!project) return false
        return canEnterTimeAgainstPhase(SessionStore.user, phase || project)
    })
}
export const getPreviousDayTime = async (staffId, beforeDate = new Date()) => {
    let r = await makeRequest({
        path: `api/v1.5/previous-time/daily`,
        method: 'post',
        data: {
            staffId,
            beforeDate: format(beforeDate, 'yyyy-MM-dd'),
        },
    })
    const phases = r.data?.phases || []
    PhaseCollection.addMany(phases, { trackUpdates: false })
    const tasks = r.data?.tasks || []
    TaskCollection.addMany(tasks, { trackUpdates: false })
    return (r.data?.timeEntries || []).filter((te) => {
        const phase = PhaseCollection.phasesById[te.phaseId]
        const project = ProjectCollection.projectsById[te.projectId]
        if (!project) return false
        return canEnterTimeAgainstPhase(SessionStore.user, phase || project)
    })
}
