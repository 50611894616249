import { observable, computed, action, makeObservable } from 'mobx'
import Collection from './Collection'
import RevenueTargetModel from '../Models/RevenueTargetModel'

class RevenueTargetCollection extends Collection {
    constructor() {
        super({ collection: 'revenueTargets', modelClass: RevenueTargetModel })
        this.addLookup('revenueTargetsByProjectId', 'manyByKey', {
            key: (t) => t.projectId,
            filter: (t) => !t.deletedAt,
        })
        this.addLookup('revenueTargetsByPhaseId', 'manyByKey', {
            key: (t) => t.phaseId,
            filter: (t) => !t.deletedAt,
        })
        makeObservable(this)
    }
    get revenueTargetsById() {
        return this.modelsById
    }
    get revenueTargets() {
        return this.models
    }
    @action.bound
    addRevenueTarget(data, options) {
        return this.add(data, options)
    }
    @action.bound
    addRevenueTargets(data, options) {
        return this.addMany(data, options)
    }
}

export default new RevenueTargetCollection()
export const RevenueTargetCollectionClass = RevenueTargetCollection
