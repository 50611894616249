import type { DataTableFilterOption, Option } from '@2/types'
import { CheckIcon } from '@radix-ui/react-icons'
import type { Column } from '@tanstack/react-table'

import { cn } from '@2/lib/utils'
import {
    Command,
    CommandEmpty,
    CommandGroup,
    CommandInput,
    CommandItem,
    CommandList,
    CommandSeparator,
} from '@2/components/ui/command'

interface DataTableAdvancedFacetedFilterProps<TData, TValue> {
    column?: Column<TData, TValue>
    title?: string
    options: Option[]
    selectedValues: Set<string>
    setSelectedOptions: React.Dispatch<
        React.SetStateAction<DataTableFilterOption<TData>[]>
    >
    groupBy?: (item: TData) => string
    sortGroups?: (a: any, b: any) => number
}

export function DataTableAdvancedFacetedFilter<TData, TValue>({
    column,
    title,
    options,
    selectedValues,
    setSelectedOptions,
    groupBy,
    sortGroups,
}: DataTableAdvancedFacetedFilterProps<TData, TValue>) {
    const groupedOptions = groupBy
        ? Object.entries(
              options.reduce((groups, option) => {
                  const groupKey = groupBy(option)
                  groups[groupKey] = groups[groupKey] || []
                  groups[groupKey].push(option)
                  return groups
              }, {})
          ).map(([groupLabel, groupOptions]: [string, Option[]]) => ({
              label: groupLabel,
              options: groupOptions,
          }))
        : [{ label: '', options }]

    const listOptions = sortGroups
        ? groupedOptions.sort(sortGroups)
        : groupedOptions

    const filteredListOptions = listOptions.map((group) => ({
        ...group,
        options: group.options.filter(
            (option: Option) => !selectedValues.has(option.value)
        ),
    }))

    return (
        <Command className="p-1">
            <div className="flex h-9 w-full rounded-md border border-input bg-transparent px-3 py-1 text-sm shadow-sm [&_[cmdk-input-wrapper]]:border-0 [&_[cmdk-input-wrapper]]:px-0">
                <CommandInput
                    placeholder={title}
                    className="h-full border-0 pl-0 ring-0"
                    autoFocus
                />
            </div>
            <CommandList>
                <CommandEmpty>No results found.</CommandEmpty>

                {Array.from(selectedValues).map((value) => {
                    const selectedItem = options.find(
                        (option) => option.value === value
                    )
                    if (!selectedItem) return null

                    return (
                        <CommandItem
                            key={selectedItem.value}
                            onSelect={() => {
                                selectedValues.delete(selectedItem.value)
                                const filterValues = Array.from(selectedValues)
                                // column?.setFilterValue(
                                //     filterValues.length
                                //         ? filterValues
                                //         : undefined
                                // )
                                setSelectedOptions((prev) =>
                                    prev.map((item) =>
                                        item.value === column?.id
                                            ? {
                                                  ...item,
                                                  filterValues,
                                              }
                                            : item
                                    )
                                )
                            }}
                        >
                            <div
                                className={cn(
                                    'mr-2 flex size-4 items-center justify-center rounded-sm border border-primary bg-primary text-primary-foreground'
                                )}
                            >
                                <CheckIcon
                                    className="size-4"
                                    aria-hidden="true"
                                />
                            </div>
                            {selectedItem.label}
                        </CommandItem>
                    )
                })}

                <CommandSeparator />

                {filteredListOptions.map((listOption: any) => {
                    const { label, options } = listOption
                    return (
                        <CommandGroup
                            className="px-0"
                            heading={
                                label &&
                                label.charAt(0).toUpperCase() +
                                    label.slice(1).toLowerCase()
                            }
                            key={label}
                        >
                            {options.map((option: any) => {
                                const isSelected = selectedValues.has(
                                    option.value
                                )

                                return (
                                    <CommandItem
                                        key={option.value}
                                        onSelect={() => {
                                            if (isSelected) {
                                                selectedValues.delete(
                                                    option.value
                                                )
                                            } else {
                                                selectedValues.add(option.value)
                                            }
                                            const filterValues =
                                                Array.from(selectedValues)
                                            // column?.setFilterValue(
                                            //     filterValues.length
                                            //         ? filterValues
                                            //         : undefined
                                            // )
                                            setSelectedOptions((prev) =>
                                                prev.map((item) =>
                                                    item.value === column?.id
                                                        ? {
                                                              ...item,
                                                              filterValues,
                                                          }
                                                        : item
                                                )
                                            )
                                        }}
                                    >
                                        <div
                                            className={cn(
                                                'mr-2 flex size-4 items-center justify-center rounded-sm border border-primary',
                                                isSelected
                                                    ? 'bg-primary text-primary-foreground'
                                                    : 'opacity-50 [&_svg]:invisible'
                                            )}
                                        >
                                            <CheckIcon
                                                className="size-4"
                                                aria-hidden="true"
                                            />
                                        </div>
                                        {option.icon && (
                                            <option.icon
                                                className="mr-2 size-4 text-muted-foreground"
                                                aria-hidden="true"
                                            />
                                        )}
                                        <span>{option.label}</span>
                                        {option.withCount &&
                                            column
                                                ?.getFacetedUniqueValues()
                                                ?.get(option.value) && (
                                                <span className="ml-auto flex size-4 items-center justify-center font-mono text-xs">
                                                    {column
                                                        ?.getFacetedUniqueValues()
                                                        .get(option.value)}
                                                </span>
                                            )}
                                    </CommandItem>
                                )
                            })}
                        </CommandGroup>
                    )
                })}

                {selectedValues.size > 0 && (
                    <>
                        <CommandSeparator />
                        <CommandGroup>
                            <CommandItem
                                onSelect={() => {
                                    column?.setFilterValue(undefined)
                                    setSelectedOptions((prev) =>
                                        prev.map((item) =>
                                            item.value === column?.id
                                                ? {
                                                      ...item,
                                                      filterValues: [],
                                                  }
                                                : item
                                        )
                                    )
                                }}
                                className="justify-center text-center"
                            >
                                Clear filters
                            </CommandItem>
                        </CommandGroup>
                    </>
                )}
            </CommandList>
        </Command>
    )
}
