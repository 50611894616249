import { data } from 'jquery'
import { observer } from 'mobx-react'
import React, { Suspense, useCallback, useEffect, useState } from 'react'
import ProjectPageHeader from '../ProjectPageHeader'
import ProjectCollection from '../../../State/Collections/ProjectCollection'
import PhaseCollection from '../../../State/Collections/PhaseCollection'
import ProjectForecastsStore from './ProjectForecastsStore'
import SessionStore from '../../../State/SessionStore'
import {
    canViewProjectExpenses,
    canViewProjectFees,
    canViewProjectStaffCost,
    canViewRevenueTargets,
} from '../../../State/Permissions/HasPermissions'
import { PhaseSelector, Selector } from '../../../Components/Selector'
import RenderOnQueries from '../../Layout/RenderOnQueries'
import { queryClient } from '../../../App'
import classNames from 'classnames'

export default observer(({ tabs, selectedTab, id }) => {
    const [weekly, setWeekly] = useState(false)
    const project = ProjectCollection.projectsById[id]
    const canSeeFinancials =
        canViewRevenueTargets(SessionStore.user, project) &&
        canViewProjectFees(SessionStore.user, project)
    const canSeeExpenses =
        canViewProjectExpenses(SessionStore.user, project) &&
        canViewProjectStaffCost(SessionStore.user, project)
    const [store, setStore] = useState(ProjectForecastsStore)
    if (!project) return null
    return (
        <ProjectPageHeader
            id={id}
            tabs={tabs}
            selectedTab={selectedTab}
            extraContent={
                <div className="report-selector dont-print flex mt-4">
                    <PhaseSelector
                        value={store.filterByPhase}
                        options={project.phases}
                        onChange={(phase) => store.setFilterByPhase(phase)}
                    />
                    <Selector
                        style={{ marginRight: '1em' }}
                        value={store.graphType}
                        onChange={(graphType) => store.setGraphType(graphType)}
                        options={[
                            ...(canSeeFinancials
                                ? [
                                      {
                                          label: 'Revenue vs. Expenses (cumulative)',
                                          value: 'revenueVsExpenses',
                                      },
                                  ]
                                : []),
                            ...(canSeeExpenses
                                ? [
                                      {
                                          label: 'Expense Budget (cumulative)',
                                          value: 'expenseBudget',
                                      },
                                  ]
                                : []),
                            {
                                label: 'Hours Budget (cumulative)',
                                value: 'hoursBudget',
                            },
                            ...(canSeeFinancials
                                ? [
                                      {
                                          label: 'Revenue vs. Expenses (monthly)',
                                          value: 'revenueVsExpensesMonthly',
                                      },
                                  ]
                                : []),
                            ...(canSeeExpenses
                                ? [
                                      {
                                          label: 'Expense Budget (monthly)',
                                          value: 'expenseBudgetMonthly',
                                      },
                                  ]
                                : []),
                            {
                                label: 'Hours Budget (monthly)',
                                value: 'hoursBudgetMonthly',
                            },
                        ]}
                    />

                    <button
                        className={classNames(
                            'py-2 px-4 border border-white text-xs m-0',
                            store.periodType === 'month'
                                ? 'bg-white text-black'
                                : 'text-white bg-transparent'
                        )}
                        style={{
                            borderRadius: '0.5rem 0 0 0.5rem',
                        }}
                        onClick={() => store.setPeriodType('month')}
                    >
                        Monthly
                    </button>
                    <button
                        className={classNames(
                            'py-2 px-4 border border-white text-xs m-0',
                            store.periodType === 'week'
                                ? 'bg-white text-black'
                                : 'text-white bg-transparent'
                        )}
                        style={{ borderRadius: '0 0.5rem 0.5rem 0' }}
                        onClick={() => store.setPeriodType('week')}
                    >
                        Weekly
                    </button>
                    {/* <Selector
                        value={store.graphData}
                        onChange={(graphData) => store.setGraphData(graphData)}
                        options={[
                            {
                                label: 'Actuals + Projected',
                                value: 'actualsProjected',
                            },
                            { label: 'Actuals', value: 'actual' },
                            { label: 'Projected', value: 'projected' },
                        ]}
                    /> */}
                </div>
            }
        />
    )
})
