export const permissionPresets = {
    masterAdmin: {
        canViewRevenueForecast: true,
        canViewStaffExpenses: true,
        canViewOperationalExpenses: true,
        canViewResourceSchedule: true,
        canViewWeeklyPlanner: true,
        projects: [
            {
                filter: { type: 'all' },
                permissions: {
                    projectPermissions: 'admin',
                    projectFees: 'edit',
                    projectExpenseBudgets: 'edit',
                    projectHoursBudgets: 'edit',
                    projectStaffBudgets: 'admin',
                    projectDates: 'edit',
                    projectPhasesTasks: 'admin',
                    projectExpenses: 'admin',
                    projectForecastedRevenue: 'edit',
                    projectForecastedHours: 'edit',
                    projectInvoices: 'admin',
                    projectRates: 'admin',
                    projectNotes: 'admin',
                    projectTimesheets: true,
                },
            },
        ],

        staffPermissions: 'admin',
        rolePermissions: 'admin',
        editStaffTime: true,
        payRate: 'admin',
        overtimeRate: 'admin',
        costRate: 'admin',
        chargeOutRate: 'admin',
        contacts: 'admin',
        costCentres: 'admin',
        operationalExpenses: 'admin',
        holidays: 'admin',
        invoiceSettings: 'edit',
        organisationSettings: 'edit',
        billing: 'edit',
    },
    limitedAdmin: {
        canViewRevenueForecast: true,
        canViewStaffExpenses: true,
        canViewOperationalExpenses: true,
        canViewResourceSchedule: true,
        canViewWeeklyPlanner: true,
        projects: [
            {
                filter: { type: 'all' },
                permissions: {
                    projectPermissions: 'admin',
                    projectFees: 'edit',
                    projectExpenseBudgets: 'edit',
                    projectHoursBudgets: 'edit',
                    projectStaffBudgets: 'admin',
                    projectDates: 'edit',
                    projectPhasesTasks: 'admin',
                    projectExpenses: 'admin',
                    projectForecastedRevenue: 'edit',
                    projectForecastedHours: 'edit',
                    projectInvoices: 'admin',
                    projectRates: 'admin',
                    projectNotes: 'admin',
                    projectTimesheets: true,
                },
            },
        ],

        staffPermissions: 'view',
        rolePermissions: 'view',
        editStaffTime: true,
        payRate: 'view',
        overtimeRate: 'view',
        costRate: 'view',
        chargeOutRate: 'view',
        contacts: 'admin',
        costCentres: 'hidden',
        operationalExpenses: 'hidden',
        holidays: 'view',
        invoiceSettings: 'hidden',
        organisationSettings: 'hidden',
        billing: 'hidden',
    },
    projectManager: {
        canViewRevenueForecast: true,
        canViewStaffExpenses: false,
        canViewOperationalExpenses: false,
        canViewResourceSchedule: true,
        canViewWeeklyPlanner: true,
        projects: [
            {
                filter: { type: 'all' },
                permissions: {
                    projectPermissions: 'edit',
                    projectFees: 'view',
                    projectExpenseBudgets: 'view',
                    projectHoursBudgets: 'view',
                    projectStaffBudgets: 'admin',
                    projectDates: 'edit',
                    projectPhasesTasks: 'view',
                    projectExpenses: 'admin',
                    projectForecastedRevenue: 'edit',
                    projectForecastedHours: 'edit',
                    projectInvoices: 'view',
                    projectRates: 'view',
                    projectNotes: 'admin',
                    projectTimesheets: true,
                },
            },
        ],
        staffPermissions: 'hidden',
        rolePermissions: 'hidden',
        editStaffTime: false,
        payRate: 'hidden',
        overtimeRate: 'hidden',
        costRate: 'view',
        chargeOutRate: 'view',
        contacts: 'view',
        costCentres: 'view',
        operationalExpenses: 'hidden',
        holidays: 'view',
        invoiceSettings: 'hidden',
        organisationSettings: 'hidden',
        billing: 'hidden',
    },
    viewer: {
        canViewRevenueForecast: true,
        canViewStaffExpenses: false,
        canViewOperationalExpenses: false,
        canViewResourceSchedule: true,
        canViewWeeklyPlanner: true,
        projects: [
            {
                filter: { type: 'all' },
                permissions: {
                    projectPermissions: 'view',
                    projectFees: 'view',
                    projectExpenseBudgets: 'view',
                    projectHoursBudgets: 'view',
                    projectStaffBudgets: 'view',
                    projectDates: 'view',
                    projectPhasesTasks: 'view',
                    projectExpenses: 'view',
                    projectForecastedRevenue: 'view',
                    projectForecastedHours: 'view',
                    projectInvoices: 'view',
                    projectRates: 'hidden',
                    projectNotes: 'view',
                    projectTimesheets: true,
                },
            },
        ],
        staffPermissions: 'hidden',
        rolePermissions: 'hidden',
        editStaffTime: false,
        payRate: 'hidden',
        overtimeRate: 'hidden',
        costRate: 'view',
        chargeOutRate: 'view',
        contacts: 'view',
        costCentres: 'view',
        operationalExpenses: 'hidden',
        holidays: 'view',
        invoiceSettings: 'hidden',
        organisationSettings: 'hidden',
        billing: 'hidden',
    },
    timesheet: {
        canViewRevenueForecast: false,
        canViewStaffExpenses: false,
        canViewOperationalExpenses: false,
        canViewResourceSchedule: false,
        canViewWeeklyPlanner: false,
        projects: [
            {
                filter: { type: 'all' },
                permissions: {
                    projectPermissions: 'hidden',
                    projectFees: 'hidden',
                    projectExpenseBudgets: 'hidden',
                    projectHoursBudgets: 'hidden',
                    projectStaffBudgets: 'hidden',
                    projectDates: 'hidden',
                    projectPhasesTasks: 'hidden',
                    projectExpenses: 'hidden',
                    projectForecastedRevenue: 'hidden',
                    projectForecastedHours: 'hidden',
                    projectInvoices: 'hidden',
                    projectRates: 'hidden',
                    projectNotes: 'hidden',
                    projectTimesheets: true,
                },
            },
        ],
        staffPermissions: 'hidden',
        rolePermissions: 'hidden',
        editStaffTime: false,
        payRate: 'hidden',
        overtimeRate: 'hidden',
        costRate: 'hidden',
        chargeOutRate: 'hidden',
        contacts: 'hidden',
        costCentres: 'hidden',
        operationalExpenses: 'hidden',
        holidays: 'hidden',
        invoiceSettings: 'hidden',
        organisationSettings: 'hidden',
        billing: 'hidden',
    },
}
