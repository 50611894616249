import React from 'react'
import * as ProgressPrimitive from '@radix-ui/react-progress'

const Progress = React.forwardRef((props, ref) => {
    const { value, fill, ...otherProps } = props

    // Inline styles to replace Tailwind classes
    const rootStyle = {
        position: 'relative',
        height: '5px',
        width: '100%',
        overflow: 'hidden',
        borderRadius: '9999px',
        background: 'rgba(0, 0, 0, 0.2)', // Replace with your primary color
    }

    const indicatorStyle = {
        height: '100%',
        width: `${Math.min(value, 100)}%`, // Width is now based on the value prop
        background: fill || 'black',
        transition: 'width 0.3s ease', // Transitioning the width
    }

    return (
        <ProgressPrimitive.Root ref={ref} style={rootStyle} {...otherProps}>
            <ProgressPrimitive.Indicator style={indicatorStyle} />
        </ProgressPrimitive.Root>
    )
})

Progress.displayName = 'Progress'

export { Progress }
