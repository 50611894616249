import { observable, computed, action, makeObservable } from 'mobx'
import PhaseCollection from '../Collections/PhaseCollection'
import ProjectCollection from '../Collections/ProjectCollection'
import ProjectExpenseItemCollection from '../Collections/ProjectExpenseItemCollection'
import ProjectExpenseCollection from '../Collections/ProjectExpenseCollection'
import Model from './Model'

class ProjectExpenseItemModel extends Model {
    @observable projectId = null
    @observable phaseId = null
    @observable staffId = null
    @observable expenseId = null
    @observable date = null
    @observable cost = null
    @observable quantity = null
    @observable description = null
    @observable isLocked = null
    @observable beenInvoiced = null

    constructor(data, options) {
        super()
        makeObservable(this)
        this.collection = ProjectExpenseItemCollection
        this.init(data, options)
    }
    @computed
    get project() {
        return ProjectCollection.modelsById[this.projectId]
    }
    @computed
    get phase() {
        return PhaseCollection.modelsById[this.phaseId]
    }
    @computed
    get expense() {
        return ProjectExpenseCollection.modelsById[this.expenseId]
    }
}

export default ProjectExpenseItemModel
