import { observer } from 'mobx-react'
import React, { useEffect } from 'react'
import Table from '../../Components/Table'
import ProjectCollection from '../../State/Collections/ProjectCollection'
import _ from 'lodash'

const historyColumns = [
    {
        id: 'date',
        label: 'Date',
        width: 15,
        type: 'date',
        style: (r) => ({
            textAlign: 'left',
            justifyContent: 'flex-start',
        }),
        value: (r) => r.date,
    },
    {
        id: 'item',
        label: 'Item',
        width: 15,
        type: 'text',
        value: (r) => r.model?.title,
    },
    {
        label: 'Event',
        width: 70,
        type: 'custom',
        value: (r) => r,
        component: ({ value }) => (
            <span>
                <b>{`${value?.user?.fullName}`}</b>
                {` changed `}
                <b>{`${value?.fieldLabel}`}</b>
                {` to `}
                <b>{`${value?.value}`}</b>
            </span>
        ),
    },
]

export default observer(({ id }) => {
    const project = ProjectCollection.projectsById[id]
    if (!project) return null
    return (
        <div>
            <Table
                columns={historyColumns}
                rows={[
                    ...project.history,
                    ...project.phases.map((p) => p.history).flat(),
                ]}
                sortBy={[['date', 'desc']]}
            />
        </div>
    )
})
