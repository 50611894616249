import React from 'react'

export default ({ text }) => {
    return (
        <div className="flex items-center justify-center w-full h-full">
            <div className="bg-transparent text-center self-center py-[4em] px-[6.5em] opacity-90">
                <div>
                    <i
                        className="fa fa-circle-o-notch fa-spin fa-3x fa-fw coincraft-gold text-gold"
                        style={{ marginRight: 0 }}
                    />
                    {text ? (
                        <h5 className="mt-[0.75em] mb-0 text-[#333]">{text}</h5>
                    ) : null}
                </div>
            </div>
        </div>
    )
}
