import { create } from 'zustand'
import { immer } from 'zustand/middleware/immer'
import { devtools } from 'zustand/middleware'

interface Fraction {
    numerator: number
    denominator: number
}

interface Project {
    projectId: string
    title: string
    startDate: string
    isRootPhase: boolean | null
    name: string
    actualVsBudgetedHours: Fraction
    actualVsBudgetedExpenses: Fraction
    revenueVsFee: Fraction
    profit: number
    profitMargin: number | null
    status: string
    hoursBudgeted: number
    recordedHoursAllTime: number
    expensesAllTime: number
    expenseBudget: number
    revenueAllTime: number
    fee: number
    revenue: number
    expensesProject: number
    expenses: number
    projectExpenses: number
}

interface ReportData extends Project {
    children: Project[]
    key: string
    label: string
}

interface Filter {
    column: string
    operator: string
    value?: any[]
    group?: string
}

interface SortBy {
    column: string
    order: 'asc' | 'desc'
}

interface Options {
    dateRange: string
}

interface ProjectReport {
    oldId?: string
    id: string
    history?: any[]
    organisationId: string
    name: string
    data: Record<string, string>
    type: string
    columns: string[]
    filters?: Filter[]
    groupBy?: string[]
    sortBy?: SortBy[]
    options: Options
    dateRange: string[]
    invoiceDateType: string
}

interface ProjectReportState {
    reportData: ReportData[] | []
    projectReport: ProjectReport
    isLoadingReportData: boolean
    isHideColumn: boolean
    columns: string[]
    setReportData: (newReportData: ReportData[]) => void
    setProjectReport: (newProjectReport: ProjectReport) => void
    setIsLoadingReportData: (isLoading: boolean) => void
    setIsHideColumn: (isHideColumn: boolean) => void
    setColumns: (newColumns: string[]) => void
}

export const useProjectReportStore = create<ProjectReportState>()(
    devtools(
        immer((set, get) => ({
            reportData: null,
            projectReport: null,
            isLoadingReportData: false,
            isHideColumn: false,
            columns: null,
            setProjectReport: (newProjectReport: ProjectReport) =>
                set({ projectReport: newProjectReport }),
            setReportData: (newReportData: ReportData[]) =>
                set({ reportData: newReportData }),
            setIsLoadingReportData: (isLoading: boolean) =>
                set({ isLoadingReportData: isLoading }),
            setIsHideColumn: (isHide: boolean) => 
                set({ isHideColumn: isHide }),
            setColumns: (newColumns: string[]) =>
                set((state) => {
                    if (state.projectReport) {
                        state.projectReport.columns = newColumns
                    }
                }),
        }))
    )
)

export { ReportData, Fraction, ProjectReport }
