import { observable, computed, action, makeObservable } from 'mobx'
import Collection from './Collection'
import RoleRateModel from '../Models/RoleRateModel'

class RoleRateCollection extends Collection {
    constructor() {
        super({ collection: 'roleRates', modelClass: RoleRateModel })
        this.addLookup('ratesByRoleId', 'manyByKey', {
            key: (t) => t.roleId,
            filter: (t) => !t.deletedAt,
        })
        makeObservable(this)
    }
    get roleRatesById() {
        return this.modelsById
    }
    get roleRates() {
        return this.models
    }
    @action.bound
    addRoleRate(data) {
        this.add(data)
    }
    @action.bound
    addRoleRates(data) {
        this.addMany(data)
    }
}

export default new RoleRateCollection()
export const RoleRateCollectionClass = RoleRateCollection
