import { observer } from 'mobx-react'
import React, { Suspense, useCallback, useEffect, useState } from 'react'
import PageHeader from '../Layout/PageHeader'
import ReportCollection from '../../State/Collections/ReportCollection'
import SessionStore from '../../State/SessionStore'
import LayoutStore from '../../State/LayoutStore'
import ReportHeader, {
    ReportSearch,
} from '../../Components/Reports/ReportHeader'
import NewInvoiceModal from './NewInvoiceModal'
import {
    canCreateInvoiceForProject,
    canCreateInvoices,
} from '../../State/Permissions/HasPermissions'
import { format } from 'date-fns'
import HeaderButton from '../../Components/ui/HeaderButton'

export default observer(({ id }) => {
    const report =
        ReportCollection.reportsById[id] ||
        SessionStore.organisation.defaultInvoiceReport
    return (
        <PageHeader
            heading={
                <div>
                    <div>{'Invoices'}</div>
                    <div className="text-sm font-normal px-2.5 py-1.5">
                        {isFinite(report.dateRange[0])
                            ? `(${format(
                                  report.dateRange[0],
                                  'dd MMMM yyyy'
                              )} - ${format(
                                  report.dateRange[1],
                                  'dd MMMM yyyy'
                              )})`
                            : `(All time prior to: ${format(
                                  new Date(),
                                  'dd MMMM yyyy'
                              )})`}
                    </div>
                </div>
            }
            leftButtons={[
                canCreateInvoices(SessionStore.user) ? (
                    <button
                        key={0}
                        className="project-list__add-new-project-button page-header__button--primary plus-btn"
                        onClick={() => {
                            LayoutStore.openModal(
                                <NewInvoiceModal modalId={'new-invoice'} />
                            )
                        }}
                    >
                        + New Invoice
                    </button>
                ) : null,
            ]}
            rightButtons={
                <div className="flex items-center gap-2">
                    <HeaderButton
                        label="Export"
                        onClick={() => report.downloadCSV()}
                        icon={<i className="fa fa-table" />}
                    />
                    <HeaderButton
                        label="Print"
                        onClick={() => window.print()}
                        icon={<i className="fa fa-print" />}
                    />
                </div>
            }
            filter={
                <ReportSearch
                    report={report}
                    placeholder="Filter by Invoice / Project"
                />
            }
            extraContent={
                <ReportHeader
                    report={report}
                    reportOptions={ReportCollection.reportsByType['invoice']}
                    baseUrl={'/invoice'}
                />
            }
        />
    )
})
