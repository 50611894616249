import { observable, computed, action, makeObservable } from 'mobx'
import bind from 'bind-decorator'
class Aggregate {
    @observable collectionsByGroup = {}
    collectionClass = null
    constructor({ collectionClass }) {
        this.collectionClass = collectionClass
        makeObservable(this)
    }
    get collection() {
        return new this.collectionClass().collection
    }
    get modelType() {
        return new this.collectionClass().modelType
    }
    @bind
    groupByModelId(groupBy, modelData) {
        return (
            this.modelType +
            String(groupBy.sort().map((prop) => modelData[prop]))
        )
    }
    @bind
    groupById(groupBy) {
        return this.modelType + String(groupBy.sort())
    }
    @bind
    getGroupCollection(groupBy) {
        const groupId = this.groupById(groupBy)
        this.collectionsByGroup[groupId] ??= new this.collectionClass()
        return this.collectionsByGroup[groupId]
    }
    @action.bound
    add(groupBy, modelData, options) {
        const collection = this.getGroupCollection(groupBy)
        const modelId = this.groupByModelId(groupBy, modelData)
        modelData.id = modelId
        modelData.id = modelId
        return collection.add(modelData, options)
    }
    @action.bound
    addMany(groupBy, modelDataArray, options) {
        return modelDataArray.map((md) => this.add(groupBy, md, options))
    }
    @action.bound
    clearGroupCollection(groupBy) {
        const groupId = this.groupById(groupBy)
        delete this.collectionsByGroup[groupId]
    }
    @action.bound
    clear() {
        const collections = Object.values(this.collectionsByGroup)
        collections.forEach((collection) => collection.clear())
        this.collectionsByGroup = {}
    }
}

export default Aggregate
