import { createFileRoute } from '@tanstack/react-router'
import TimesheetReportsPageHeader from '../../Pages/TimesheetReportsPage/TimesheetReportsPageHeader'
import TimesheetReportsPage from '../../Pages/TimesheetReportsPage/TimesheetReportsPage'
import PageBody from '../../Pages/Layout/PageBody'
import LoadingSpinner from '../../Components/LoadingSpinner'
import ErrorComponent from '../../Components/ErrorComponent'
import fetchData from '../../Queries/fetchData'
import { canViewRoute } from '../../State/Permissions/HasPermissions'
import SessionStore from '../../State/SessionStore'
import PermissionDenied from '../../Pages/PermissionDenied'
import { qf } from '../../Queries/queryFormatter'

export const Route = createFileRoute('/_private/timesheets/report')({
    beforeLoad: ({ params }) => {
        if (!canViewRoute(SessionStore.user, 'timesheetReports', params))
            throw new Error('Permission Denied')
    },
    errorComponent: ({ error, reset }) => {
        if (error.message === 'Permission Denied') return <PermissionDenied />
        return <ErrorComponent error={error} reset={reset} />
    },
    pendingComponent: LoadingSpinner,
    loader: async ({ location }) => await loader(location.search),
    loaderDeps: ({ search: { report } }) => ({
        report,
    }),
    component: Wrapper,
})

const loader = async ({ report }) => {
    const collections = [
        report
            ? {
                  collection: 'reports',
                  fields: [
                      'name',
                      'type',
                      'columns',
                      'filters',
                      'groupBy',
                      'sortBy',
                      'options',
                  ],
                  filters: [`id == ${qf(report)}`],
              }
            : null,
        {
            collection: 'reports',
            fields: ['name', 'type'],
        },
        {
            collection: 'contacts',
            fields: ['firstName', 'lastName', 'organisationName'],
        },
    ].filter(Boolean)

    return await Promise.all(collections.map(fetchData))
}

function Wrapper() {
    return (
        <>
            <TimesheetReportsPageHeader
                {...Route.useParams()}
                {...Route.useSearch()}
            />
            <PageBody>
                <TimesheetReportsPage
                    {...Route.useParams()}
                    {...Route.useSearch()}
                />
            </PageBody>
        </>
    )
}
