import { observable, computed, action, makeObservable } from 'mobx'
import Collection from './Collection'
import ResourceScheduleReportModel from '../Models/ResourceScheduleReportModel'

class ResourceScheduleReportCollection extends Collection {
    constructor() {
        super({
            collection: 'resourceScheduleReports',
            modelClass: ResourceScheduleReportModel,
        })
        makeObservable(this)
    }
    get resourceSchedulesById() {
        return this.modelsById
    }
    get resourceSchedules() {
        return this.models
    }
    @action.bound
    addResourceSchedule(data) {
        this.add(data)
    }
    @action.bound
    addResourceSchedules(data) {
        this.addMany(data)
    }
}

export default new ResourceScheduleReportCollection()
export const ResourceScheduleReportCollectionClass =
    ResourceScheduleReportCollection
