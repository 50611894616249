import { observer } from 'mobx-react'
import React, { Suspense, useCallback, useEffect, useState } from 'react'
import PageHeader from '../Layout/PageHeader'
import ReportCollection from '../../State/Collections/ReportCollection'
import SessionStore from '../../State/SessionStore'
import ReportHeader, {
    ReportSearch,
} from '../../Components/Reports/ReportHeader'
import StaffCollection from '../../State/Collections/StaffCollection'
import { canCreateStaff } from '../../State/Permissions/HasPermissions'
import { format } from 'date-fns'
import HeaderButton from '../../Components/ui/HeaderButton'
import { useNavigate } from '@tanstack/react-router'

export default observer(({ id }) => {
    const navigate = useNavigate()
    const report =
        ReportCollection.reportsById[id] ||
        SessionStore.organisation.defaultStaffReport
    return (
        <PageHeader
            heading={
                <div>
                    <div>{'Staff'}</div>
                    <div className="text-sm font-normal px-2.5 py-1.5">
                        {isFinite(report.dateRange[0])
                            ? `(${format(
                                  report.dateRange[0],
                                  'dd MMMM yyyy'
                              )} - ${format(
                                  report.dateRange[1],
                                  'dd MMMM yyyy'
                              )})`
                            : `(All time prior to: ${format(
                                  new Date(),
                                  'dd MMMM yyyy'
                              )})`}
                    </div>
                </div>
            }
            leftButtons={[
                canCreateStaff(SessionStore.user) ? (
                    <button
                        key={0}
                        className="project-list__add-new-project-button page-header__button--primary plus-btn"
                        onClick={() => {
                            const staff = StaffCollection.createStaff()
                            navigate({ to: '/staff/' + staff.id })
                        }}
                    >
                        + New Staff Member
                    </button>
                ) : null,
            ]}
            rightButtons={
                <div className="flex items-center gap-2">
                    <HeaderButton
                        label="Export"
                        icon={<i className="fa fa-table" />}
                        onClick={() => {
                            report.downloadCSV()
                        }}
                    />
                    <HeaderButton
                        label="Print"
                        icon={<i className="fa fa-print" />}
                        onClick={() => window.print()}
                    />
                </div>
            }
            filter={
                <ReportSearch
                    report={report}
                    placeholder="Filter by staff name"
                />
            }
            extraContent={
                <ReportHeader
                    report={report}
                    reportOptions={
                        ReportCollection.reportsByType['staffMember']
                    }
                    baseUrl={'/staff'}
                />
            }
        />
    )
})
