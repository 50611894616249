import _ from 'lodash'
import {
    canViewPrimaryContact,
    canViewProjectExpenseBudgets,
    canViewProjectInvoices,
    canViewStaffCostRate,
} from '../../State/Permissions/HasPermissions'
import SessionStore from '../../State/SessionStore'
import { format, parse } from 'date-fns'
import Formatter from '../../Components/Formatter'

export const ExpenseReportColumns = (report) => ({
    project: {
        id: 'project',
        label: 'Project',
        type: 'project',
        width: 20,
        value: (row) => row.project,
        format: (project) => {
            return project
        },
    },
    projectPhase: {
        id: 'projectPhase',
        label: 'Phase',
        type: 'text',
        width: 20,
        value: (row) => {
            return row.projectPhase
        },
    },
    projectStatus: {
        id: 'projectStatus',
        label: 'Project Status',
        type: 'status',
        width: 20,
        value: (row) => {
            return row.projectStatus
        },
    },
    phaseStatus: {
        id: 'phaseStatus',
        label: 'Phase Status',
        type: 'status',
        width: 20,
        value: (row) => {
            return row.phaseStatus
        },
    },
    staffMember: {
        id: 'staffMember',
        label: 'Staff',
        type: 'staffMember',
        width: 20,
        value: (row) => {
            return row.staffMember
        },
        format: (staff) => staff,
    },
    date: {
        id: 'date',
        label: 'Date',
        type: 'date',
        width: 10,
        aggregate: 'same',
        value: (row) => {
            return row.date ? new Date(row.date) : null
        },
    },
    notes: {
        id: 'notes',
        label: 'Notes',
        type: 'text',
        width: 30,
        value: (row) => {
            return row.notes
        },
    },
    costCentre: {
        id: 'costCentre',
        label: 'Cost Centre',
        type: 'costCentre',
        width: 15,
        value: (row) => {
            return row.costCentre
        },
        format: (costCentre) => costCentre,
    },
    staffCostCentre: {
        id: 'staffCostCentre',
        label: 'Staff Cost Centre',
        type: 'costCentre',
        width: 15,
        value: (row) => {
            return row.staffCostCentre
        },
        format: (costCentre) => costCentre,
    },
    projectOwner: {
        id: 'projectOwner',
        label: 'Project Owner',
        type: 'staffMember',
        width: 20,
        value: (row) => {
            return row.projectOwner
        },
        format: (staff) => staff,
    },
    projectContact: {
        id: 'projectContact',
        label: 'Project Client',
        type: 'contact',
        width: 20,
        value: (row) => {
            return row.projectContact
        },
        format: (contact) => contact,
    },
    projectInvoiceContact: {
        id: 'projectInvoiceContact',
        label: 'Project Primary Contact',
        type: 'contact',
        width: 20,
        value: (row) => {
            return row.projectInvoiceContact
        },
        format: (contact) => contact,
        permissions: (row) => canViewPrimaryContact(),
    },
    projectCode: {
        id: 'projectCode',
        label: 'Project Code',
        type: 'text',
        width: 20,
        value: (row) => {
            return row.projectCode
        },
    },
    projectPhaseCode: {
        id: 'projectPhaseCode',
        label: 'Phase Code',
        type: 'text',
        width: 20,
        value: (row) => {
            return row.projectPhaseCode
        },
    },
    staffRole: {
        id: 'staffRole',
        label: 'Staff Role',
        type: 'role',
        width: 20,
        value: (row) => {
            return row.staffRole
        },
        format: (v) => v,
    },
    monthIndex: {
        id: 'monthIndex',
        label: 'Month',
        type: 'text',
        width: 20,
        format: (month) =>
            month && format(parse(month, 'yyyy-MM', new Date()), 'MMM yyyy'),
        value: (row) => {
            return row?.monthIndex
        },
    },
    isLocked: {
        id: 'isLocked',
        label: 'Is Locked',
        type: 'boolean',
        width: 10,
        value: (row) => {
            return row.isLocked
        },
    },
    beenInvoiced: {
        id: 'beenInvoiced',
        label: 'Has Been Invoiced',
        type: 'boolean',
        width: 10,
        value: (row) => {
            return row.beenInvoiced
        },
    },
    expenseName: {
        id: 'expenseName',
        label: 'Expense Name',
        type: 'text',
        width: 20,
        value: (row) => {
            return row.expenseName
        },
    },
    recordedExpense: {
        id: 'recordedExpense',
        label: 'Recorded Expense',
        type: 'currency',
        width: 10,
        aggregate: 'sum',
        value: (row) => {
            return row.recordedExpense
        },
    },
    plannedExpense: {
        id: 'plannedExpense',
        label: 'Planned Expense',
        type: 'currency',
        width: 10,
        aggregate: 'sum',
        value: (row) => {
            return row.plannedExpense
        },
    },
    invoicedExpense: {
        id: 'invoicedExpense',
        label: 'Invoiced Expense',
        type: 'currency',
        width: 10,
        value: (row) => {
            return row.beenInvoiced
        },
    },
    // recordedVsPlannedExpense: {
    //     id: 'recordedVsPlannedExpense',
    //     label: 'Recorded vs Planned Expense',
    //     type: 'progressBar',
    //     format: (v) => Formatter.currency(v, { decimals: 0 }),
    //     width: 30,
    //     aggregate: 'sum',
    //     value: (row) => {
    //         return row.recordedVsPlannedExpense
    //     },
    // },
    // invoicedVsPlannedExpense: {
    //     id: 'invoicedVsPlannedExpense',
    //     label: 'Invoiced vs Planned Expense',
    //     type: 'progressBar',
    //     format: (v) => Formatter.currency(v, { decimals: 0 }),
    //     width: 30,
    //     aggregate: 'sum',
    //     value: (row) => {
    //         return row.invoicedVsPlannedExpense
    //     },
    // },
    // invoicedVsRecordedExpense: {
    //     id: 'invoicedVsRecordedExpense',
    //     label: 'Invoiced vs Recorded Expense',
    //     type: 'progressBar',
    //     format: (v) => Formatter.currency(v, { decimals: 0 }),
    //     width: 30,
    //     aggregate: 'sum',
    //     value: (row) => {
    //         return row.invoicedVsRecordedExpense
    //     },
    // },
    supplier: {
        id: 'supplier',
        label: 'Supplier',
        type: 'text',
        width: 20,
        value: (row) => {
            return row.supplier
        },
        format: (supplier) => supplier,
    },
})
