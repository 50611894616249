import { observable, computed, action, makeObservable } from 'mobx'
import Collection from './Collection'
import ProjectModel from '../Models/ProjectModel'
import CostCentreCollection from './CostCentreCollection'
import PhaseCollection from './PhaseCollection'

class ProjectCollection extends Collection {
    constructor() {
        super({ collection: 'projects', modelClass: ProjectModel })
        this.addLookup('projectsByOwnerId', 'manyByKey', {
            key: (t) => t.ownerId,
            filter: (t) => !t.deletedAt,
        })
        this.addLookup('projectsByContactId', 'manyByKey', {
            key: (t) => t.contactId,
            filter: (t) => !t.deletedAt,
        })
        this.addLookup('projectsByPrimaryContactId', 'manyByKey', {
            key: (t) => t.primaryContactId,
            filter: (t) => !t.deletedAt,
        })
        this.addLookup('projectsByCostCentreId', 'manyByKey', {
            key: (t) => t.costCentre,
            filter: (t) => !t.deletedAt,
        })
        makeObservable(this)
    }
    get projectsById() {
        return this.modelsById
    }
    get projects() {
        return this.models
    }
    @action.bound
    addProject(data) {
        return this.add(data)
    }
    @action.bound
    addProjects(data) {
        return this.addMany(data)
    }
    @action.bound
    createProject() {
        const project = this.add(
            {
                name: 'New Project',
                costCentreId:
                    CostCentreCollection.activeBillableCostCentres[0]?.id ||
                    CostCentreCollection.activeCostCentres[0]?.id ||
                    CostCentreCollection.costCentres[0]?.id,
                milestoneType: 'monthly',
            },
            { trackUpdates: true }
        )
        const phase = PhaseCollection.createPhase(project.id)
        const rootPhase = PhaseCollection.createRootPhase(project.id)
        project.update({
            rootPhaseId: rootPhase.id,
        })
        return project
    }
}

export default new ProjectCollection()
export const ProjectCollectionClass = ProjectCollection
