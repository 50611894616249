import { createFileRoute } from '@tanstack/react-router'
import ProjectListPage from '../../Pages/ProjectListPage/ProjectListPage'
import ProjectListPageHeader from '../../Pages/ProjectListPage/ProjectListPageHeader'
import PageBody from '../../Pages/Layout/PageBody'
import SessionStore from '../../State/SessionStore'
import fetchData from '../../Queries/fetchData'
import { qf } from '../../Queries/queryFormatter'
import {
    canViewRoute,
    migratedToV2,
    usingNewLogin,
} from '../../State/Permissions/HasPermissions'
import PermissionDenied from '../../Pages/PermissionDenied'
import LoadingSpinner from '../../Components/LoadingSpinner'
import ErrorComponent from '../../Components/ErrorComponent'
import { trpc } from '../../system/trpc'
import { ProjectReportPage } from '@2/pages/project-report/project-report-page'
import { useProjectReportStore } from '@2/pages/project-report/project-report-store'
import { ProjectReportColumns } from '../../reports/Projects/ProjectReportColumns'
import { dateStringLookup } from '../../Components/Filters'

export const Route = createFileRoute('/_private/projects/')({
    beforeLoad: async ({ params }) => {
        if (!canViewRoute(SessionStore.user, 'projectList', params))
            throw new Error('Permission Denied')
    },
    errorComponent: ({ error, reset }) => {
        if (error.message === 'Permission Denied') return <PermissionDenied />
        return <ErrorComponent error={error} reset={reset} />
    },
    loader: async ({ location }) => await loader(location.search),
    loaderDeps: ({ search: { report } }) => ({
        report,
    }),
    pendingComponent: LoadingSpinner,
    component: Wrapper,
})

const loader = async ({ report }) => {
    const reportId = report || SessionStore.organisation?.defaultProjectReportId
    const collections = [
        reportId
            ? {
                  collection: 'reports',
                  fields: [
                      'name',
                      'type',
                      'columns',
                      'filters',
                      'groupBy',
                      'sortBy',
                      'options',
                  ],
                  filters: [`id == ${qf(reportId)}`],
              }
            : null,
        {
            collection: 'reports',
            fields: ['name', 'type'],
        },
        {
            collection: 'contacts',
            fields: ['firstName', 'lastName', 'organisationName'],
        },
    ].filter(Boolean)
    if (usingNewLogin()) {
        const res = reportId
            ? await trpc.projectReport.getProjectReports.query({
                  reportId,
              })
            : null

        const organisationReport =
            res || SessionStore.organisation.defaultProjectReport
        let dateRange = [null, null]
        if (organisationReport.options?.dateRange) {
            dateRange =
                dateStringLookup[organisationReport.options?.dateRange]?.(
                    organisationReport.options?.fortnightType
                ) || organisationReport.options?.dateRange
        }
        const { columns, filters, groupBy, sortBy, options, name } =
            organisationReport
        useProjectReportStore.getState().setProjectReport({
            columns,
            filters,
            groupBy,
            organisationId: SessionStore.organisationId,
            sortBy,
            dateRange,
            invoiceDateType: SessionStore.settings.reportInvoiceDateType,
            id: reportId,
            options,
            name,
        })
        const data = await trpc.projectReport.getReportsData.query({
            columns: organisationReport?.columns.filter(
                (c) =>
                    !ProjectReportColumns(reportId)[c]?.permissions ||
                    ProjectReportColumns(reportId)[c].permissions()
            ),
            filters: organisationReport?.filters.map((f) => {
                if (ProjectReportColumns(reportId)[f.column]?.type === 'date') {
                    let value = dateStringLookup[f.value]?.() || f.value
                    if (Array.isArray(value)) {
                        value = value.map((d) =>
                            d ? format(d, 'yyyy-MM-dd') : null
                        )
                    } else {
                        value = value ? format(value, 'yyyy-MM-dd') : null
                    }
                    return {
                        ...f,
                        value,
                    }
                } else {
                    return f
                }
            }),
            groupBy: ['projectId'],
            sortBy: organisationReport?.sortBy || [],
            dateRange,
            invoiceDateType: SessionStore.settings.reportInvoiceDateType,
        })

        useProjectReportStore.getState().setReportData(data)
    }

    return await Promise.all(collections.map(fetchData))
}

function Wrapper() {
    if (migratedToV2()) {
        return <ProjectReportPage />
    }
    return (
        <>
            <ProjectListPageHeader
                {...Route.useParams()}
                {...Route.useSearch()}
            />
            <PageBody>
                <ProjectListPage
                    {...Route.useParams()}
                    {...Route.useSearch()}
                />
            </PageBody>
        </>
    )
}
