import React from 'react'
import { Button } from '@2/components/ui/button'
import { Avatar, AvatarFallback } from '@2/components/ui/avatar'
import {
    DropdownMenu,
    DropdownMenuContent,
    DropdownMenuItem,
    DropdownMenuTrigger,
} from '@2/components/ui/dropdown-menu'
import { Tabs, TabsList, TabsTrigger } from '@2/components/ui/tabs'
import {
    PanelLeftIcon,
    MoreVertical,
    PlusCircle,
    PencilRuler,
    LineChart,
    Clock,
} from 'lucide-react'
import { SearchBox } from './search-box' // Import the new SearchBox component
import { useRouter, useNavigate } from '@tanstack/react-router'

export const TopBar = () => {
    const { latestLocation } = useRouter()
    const navigate = useNavigate()

    return (
        <div className="w-full bg-background">
            <div className="border-b">
                <div className="flex items-center justify-between px-6 mx-auto">
                    <div className="">
                        <div className="font-semibold text-lg flex items-center space-x-2 my-4  print:text-[1rem]">
                            <Button
                                className="print:hidden"
                                variant="ghost"
                                size="icon"
                            >
                                <PanelLeftIcon className="h-5 w-5" />
                            </Button>
                            <span>Project Awesome</span>
                        </div>

                        <div className="flex justify-center -mt-2">
                            <Tabs
                                defaultValue="details"
                                className="inline-block"
                                onValueChange={(value) => {
                                    navigate({
                                        to: latestLocation.pathname,
                                        search: (prev) => ({
                                            ...prev,
                                            tab: value,
                                        }),
                                    })
                                }}
                            >
                                <TabsList className="w-full justify-center">
                                    <TabsTrigger value="details">
                                        Details
                                    </TabsTrigger>
                                    <TabsTrigger value="forecasts">
                                        Forecasts
                                    </TabsTrigger>
                                    <TabsTrigger value="tasks">
                                        Tasks
                                    </TabsTrigger>
                                    <TabsTrigger value="changelog">
                                        Changelog
                                    </TabsTrigger>
                                    <TabsTrigger value="rates">
                                        Rates
                                    </TabsTrigger>
                                    <TabsTrigger value="notes">
                                        Notes
                                    </TabsTrigger>
                                    <TabsTrigger value="history">
                                        History
                                    </TabsTrigger>
                                    <TabsTrigger value="status">
                                        Status
                                    </TabsTrigger>
                                </TabsList>
                            </Tabs>
                        </div>
                    </div>

                    <div className="flex flex-1 justify-end space-x-2 py-4 print:hidden">
                        <div className="mx-4 flex-1 max-w-2xl">
                            <SearchBox />
                        </div>

                        <Button
                            variant="outline"
                            className="flex items-center space-x-2"
                        >
                            <PlusCircle className="h-4 w-4" />
                            <span>Create Project</span>
                        </Button>

                        <Button
                            variant="outline"
                            className="flex items-center space-x-2"
                        >
                            <PlusCircle className="h-4 w-4" />
                            <span>Create Report</span>
                        </Button>
                        <Button
                            variant="secondary"
                            className="flex items-center space-x-2 mx-4"
                        >
                            <Clock className="h-4 w-4" />
                            <span>
                                {new Date().toLocaleTimeString('en-US', {
                                    hour: '2-digit',
                                    minute: '2-digit',
                                    second: '2-digit',
                                    hour12: false,
                                })}
                            </span>
                        </Button>
                    </div>
                </div>
            </div>
        </div>
    )
}
