import moment from 'moment'
import _ from 'underscore'
import React, { useEffect, useRef, useState } from 'react'
import CreateReactClass from 'create-react-class'
import ReactDOM from 'react-dom'
import classNames from 'classnames'
import { DropdownList } from 'react-widgets'
import PropTypes from 'prop-types'
export { NumberInputContainer } from './NumberInputContainer.js'
export { FilterTextBox } from './FilterTextBox.js'
export { Checkbox } from './Checkbox.js'

export var RadioButton = CreateReactClass({
    /**
    <RadioButton
      valueLink={this.linkState('variable')}
      identifier="value1"
    />

    is a shortcut for

    <RadioButton
      value={this.state.variable === 'value1'}
      onChange={function(checked) {
        if (checked) {
          self.setState({variable: 'value1'});
        }
      }}
    />
  */

    propTypes: {
        label: PropTypes.node,

        value: PropTypes.bool,

        // `onChange` and `onSelected` are identical; `onSelected` is a better name
        // but `onChange` still exists for backward compatibility.
        onChange: PropTypes.func,
        onSelected: PropTypes.func,

        valueLink: PropTypes.object,
        identifier: PropTypes.any,

        className: PropTypes.string,
        name: PropTypes.string,

        disabled: PropTypes.bool,
    },

    getDefaultProps: function () {
        return {
            className: '',
            disabled: false,
        }
    },

    getInitialState: function () {
        return {}
    },

    render: function () {
        let value =
            this.props.valueLink != null
                ? this.props.valueLink.value === this.props.identifier
                : this.props.value
        let disabled = this.props.disabled
        //TODO-project_architect make this accessible
        return (
            <label className={`coincraft-radio-button ${this.props.className}`}>
                <div
                    className={classNames('coincraft-radio-button__square', {
                        checked: value,
                    })}
                    style={{ cursor: disabled ? 'default' : 'pointer' }}
                >
                    <input
                        className="coincraft-radio-button__input"
                        type="radio"
                        name={this.props.name}
                        checked={value || ''}
                        onChange={this.handleChange}
                        required={true}
                        disabled={disabled}
                    />
                    <ins className="coincraft-radio-button__helper" />
                </div>
                {this.props.label}
            </label>
        )
    },

    handleChange: function (event) {
        if (!this.props.disabled) {
            if (this.props.valueLink != null) {
                this.props.valueLink.requestChange(this.props.identifier)
            } else {
                if (this.props.onChange != null) {
                    this.props.onChange(event.target.checked, event)
                }
                if (this.props.onSelected != null) {
                    this.props.onSelected(event.target.checked, event)
                }
            }
        }
    },
})

export var SmallDeleteButton = CreateReactClass({
    propTypes: {
        className: PropTypes.string,
    },

    getDefaultProps: function () {
        return {
            className: '',
        }
    },

    render: function () {
        var { className, ...props } = this.props
        return (
            <button
                className={classNames(
                    'btn',
                    'btn-default',
                    'btn-sm',
                    'delete-button',
                    'size-[30px] flex items-center justify-center disabled:text-[#ccc]',
                    className
                )}
                {...props}
            >
                <i className="fa fa-times" style={{ marginRight: 0 }} />
            </button>
        )
    },
})

export const Dropdown3 = ({
    isExpanded: propsIsExpanded,
    onToggle,
    contentStyle,
    containerStyle,
    onItemClick,
    dropLeft,
    toggleElement,
    children,
    className,
}) => {
    const [isExpanded, setIsExpanded] = useState(propsIsExpanded || false)
    const dropdownRef = useRef(null)
    const menuRef = useRef(null)

    useEffect(() => {
        if (propsIsExpanded !== undefined) {
            setIsExpanded(propsIsExpanded)
        }
    }, [propsIsExpanded])

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (
                dropdownRef.current &&
                !dropdownRef.current.contains(event.target)
            ) {
                setIsExpanded(false)
                onToggle(false)
            }
        }

        if (isExpanded) {
            document.addEventListener('mousedown', handleClickOutside)
        }

        return () => {
            document.removeEventListener('mousedown', handleClickOutside)
        }
    }, [isExpanded, onToggle])

    useEffect(() => {
        if (isExpanded && menuRef.current) {
            const rect = dropdownRef.current.getBoundingClientRect()
            menuRef.current.style.top = `${rect.bottom}px`
            menuRef.current.style.left = dropLeft ? 'auto' : `${rect.left}px`
            menuRef.current.style.right = dropLeft
                ? `${window.innerWidth - rect.right}px`
                : 'auto'
        }
    }, [isExpanded, dropLeft])

    const handleToggle = (event) => {
        event.stopPropagation()
        const newIsExpanded = !isExpanded
        setIsExpanded(newIsExpanded)
        onToggle(newIsExpanded)
    }

    const handleItemClick = (identifier, event) => {
        if (onItemClick) {
            onItemClick(identifier, event)
        }
        setIsExpanded(false)
        onToggle(false)
    }

    return (
        <div
            ref={dropdownRef}
            style={{
                display: 'inline-block',
                ...containerStyle,
            }}
            className={className}
        >
            {React.cloneElement(toggleElement, { onClick: handleToggle })}
            {isExpanded && (
                <div
                    ref={menuRef}
                    style={{
                        position: 'fixed',
                        zIndex: 1000,
                        backgroundColor: 'white',
                        boxShadow: '0 6px 12px rgba(0,0,0,.175)',
                        border: '1px solid rgba(0,0,0,.15)',
                        ...contentStyle,
                    }}
                >
                    {React.Children.map(
                        children,
                        (child, index) =>
                            child && (
                                <Dropdown3ListItemWrapper
                                    key={index}
                                    onClick={(event) =>
                                        handleItemClick(
                                            child.props.identifier,
                                            event
                                        )
                                    }
                                    disabled={child.props.disabled}
                                    item={child}
                                />
                            )
                    )}
                </div>
            )}
        </div>
    )
}

Dropdown3.propTypes = {
    isExpanded: PropTypes.bool,
    onToggle: PropTypes.func,
    contentStyle: PropTypes.object,
    containerStyle: PropTypes.object,
    onItemClick: PropTypes.func,
    dropLeft: PropTypes.bool,
    toggleElement: PropTypes.element.isRequired,
    children: PropTypes.node,
    className: PropTypes.string,
}

Dropdown3.defaultProps = {
    onToggle: () => {},
    dropLeft: false,
}

const Dropdown3ListItemWrapper = ({ onClick, item, disabled }) => {
    const handleClick = (event) => {
        if (!disabled) {
            onClick(event)
        }
    }

    return <div onClick={handleClick}>{item}</div>
}

Dropdown3ListItemWrapper.propTypes = {
    onClick: PropTypes.func.isRequired,
    item: PropTypes.node.isRequired,
    disabled: PropTypes.bool,
}

export const Dropdown3ListItem = ({
    children,
    style,
    disabled,
    onClick,
    ...props
}) => {
    const [isHovered, setIsHovered] = useState(false)

    const handleMouseOver = () => setIsHovered(true)
    const handleMouseOut = () => setIsHovered(false)

    const handleClick = () => {
        if (!disabled && onClick) {
            onClick()
        }
    }

    return (
        <div
            onMouseOver={handleMouseOver}
            onMouseOut={handleMouseOut}
            style={{
                backgroundColor: isHovered ? '#eaeaea' : null,
                color: disabled ? '#aaa' : null,
                cursor: 'pointer',
                padding: '0.5em 1em',
                ...style,
            }}
            onClick={handleClick}
            {...props}
        >
            {children}
        </div>
    )
}

Dropdown3ListItem.propTypes = {
    identifier: PropTypes.any,
    disabled: PropTypes.bool,
    children: PropTypes.node,
    style: PropTypes.object,
    onClick: PropTypes.func,
}

Dropdown3ListItem.defaultProps = {
    disabled: false,
}
