import { observable, computed, action, makeObservable } from 'mobx'
import Collection from './Collection'
import SupplierModel from '../Models/SupplierModel'

class SupplierCollection extends Collection {
    constructor() {
        super({ collection: 'suppliers', modelClass: SupplierModel })
        this.addLookup('activeSuppliers', 'list', {
            filter: (t) => !t.deletedAt,
        })
        makeObservable(this)
    }
    get suppliersById() {
        return this.modelsById
    }
    get suppliers() {
        return this.models
    }
    @action.bound
    addSupplier(data) {
        this.add(data)
    }
    @action.bound
    addSuppliers(data) {
        this.addMany(data)
    }
}

export default new SupplierCollection()
export const SupplierCollectionClass = SupplierCollection
