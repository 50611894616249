export default {
    name: 'New Report',
    filters: {
        costCentres: [],
        futureRows: false,
        groups: ['role', 'staff', 'project', 'phase'],
        hoursData: 'actualsProjected',
        percentData: 'utilisation',
        phaseStatus: false,
        projectOwners: [],
        projectStatus: [],
        projects: [],
        roles: [],
        staff: [],
        totalData: 'budgetUse',
        period: 'month',
        activePhases: false,
    },
}
