import { observable, computed, action, makeObservable } from 'mobx'
import ContactCollection from '../Collections/ContactCollection'
import InvoiceCollection from '../Collections/InvoiceCollection'
import InvoiceLineItemAggregateCollection from './InvoiceLineItemAggregateCollection'
import PhaseCollection from '../Collections/PhaseCollection'
import ProjectCollection from '../Collections/ProjectCollection'
import Model from '../Models/Model'

class InvoiceLineItemAggregateModel extends Model {
    @observable projectId = null
    @observable contactId = null
    @observable phaseId = null

    @observable billingType = null

    @observable amount = null

    constructor(data, options) {
        super()
        makeObservable(this)
        this.collection = InvoiceLineItemAggregateCollection
        this.init(data, options)
    }

    @computed
    get invoice() {
        return InvoiceCollection.invoicesById[this.invoiceId]
    }

    @computed
    get project() {
        return ProjectCollection.projectsById[this.projectId]
    }

    @computed
    get phase() {
        return PhaseCollection.phasesById[this.phaseId]
    }

    @computed
    get contact() {
        return ContactCollection.contactsById[this.contactId]
    }
}

export default InvoiceLineItemAggregateModel
