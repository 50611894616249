import { observable, computed, action, makeObservable } from 'mobx'
import Collection from '../Collections/Collection'
import Aggregate from './Aggregate'
import InvoiceLineItemAggregateModel from './InvoiceLineItemAggregateModel'

class InvoiceLineItemAggregate extends Aggregate {
    constructor() {
        super({ collectionClass: InvoiceLineItemAggregateCollection })
        makeObservable(this)
    }
}

class InvoiceLineItemAggregateCollection extends Collection {
    constructor() {
        super({
            collection: 'invoiceLineItems',
            modelClass: InvoiceLineItemAggregateModel,
        })
        this.addLookup('lineItemsByInvoiceId', 'manyByKey', {
            key: (t) => t.invoiceId,
            filter: (t) => !t.deletedAt,
        })
        this.addLookup('lineItemsByProjectId', 'manyByKey', {
            key: (t) => t.projectId,
            filter: (t) => !t.deletedAt,
        })
        this.addLookup('lineItemsByPhaseId', 'manyByKey', {
            key: (t) => t.phaseId,
            filter: (t) => !t.deletedAt,
        })
        this.addLookup('lineItemsByContactId', 'manyByKey', {
            key: (t) => t.contactId,
            filter: (t) => !t.deletedAt,
        })
        this.addLookup('lineItemsByExpenseId', 'manyByKey', {
            key: (t) => t.expenseId,
            filter: (t) => !t.deletedAt,
        })
        this.addLookup('lineItemsByInvoicePhaseId', 'manyByKey', {
            key: (t) =>
                'i' +
                t.invoiceId +
                'ph' +
                (t.phaseId ? t.phaseId : 'undefined'),
            filter: (t) => !t.deletedAt,
        })
        this.addLookup('lineItemsByStatus', 'manyByKey', {
            key: (t) => t.status,
            filter: (t) => !t.deletedAt,
        })
        makeObservable(this)
    }
    get invoiceLineItemsById() {
        return this.modelsById
    }
    get invoiceLineItems() {
        return this.models
    }
    get invoiceLineItemsById() {
        return this.modelsById
    }
    @action.bound
    addInvoiceLineItem(data) {
        this.add(data)
    }
    @action.bound
    addInvoiceLineItems(data) {
        this.addMany(data)
    }
}

export default new InvoiceLineItemAggregate()
export const InvoiceLineItemAggregateCollectionClass =
    InvoiceLineItemAggregateCollection
