import { addDays, subDays } from 'date-fns'
import { dateUnitLookup, isDateOnOrBetweenRange } from './dateHelpers'
import _ from 'lodash'

export function getRevenueValuesInDateRange(
    projectOrPhase,
    dateRange,
    period,
    dataType,
    invoiceDateProp,
    billingTypes,
    includeExpenses,
    expenseNames,
    adjustLikelihood = true
) {
    return getValuesInDateRange({
        model: projectOrPhase,
        dateRange,
        period,
        dataType,
        getActualValues: (projectOrPhase2, dateRange2) =>
            getActualRevenueValuesInDateRange(
                projectOrPhase2,
                dateRange2,
                invoiceDateProp,
                billingTypes,
                includeExpenses,
                expenseNames
            ),
        getProjectedValues: (projectOrPhase2, dateRange2) =>
            getProjectedRevenueValuesInDateRange(
                projectOrPhase2,
                dateRange2,
                includeExpenses,
                expenseNames,
                adjustLikelihood
            ),
    })
}

export function getResourceValuesInDateRange(
    allocationOrTimeEntry,
    dateRange,
    period,
    dataType,
    valueType = 'hours',
    adjustLikelihood = true
) {
    return getValuesInDateRange({
        model: allocationOrTimeEntry,
        dateRange,
        period,
        dataType,
        getActualValues: (allocationOrTimeEntry2, dateRange2) =>
            getActualResourceValuesInDateRange(
                allocationOrTimeEntry2,
                dateRange2,
                valueType,
                adjustLikelihood
            ),
        getProjectedValues: (allocationOrTimeEntry2, dateRange2) =>
            getProjectedResourceValuesInDateRange(
                allocationOrTimeEntry2,
                dateRange2,
                valueType,
                adjustLikelihood
            ),
    })
}

export function getExpenseValuesInDateRange(
    projectExpenseOrChangeLog,
    dateRange,
    period,
    dataType,
    adjustLikelihood = true
) {
    return getValuesInDateRange({
        model: projectExpenseOrChangeLog,
        dateRange,
        period,
        dataType,
        getActualValues: (allocationOrTimeEntry2, dateRange2) =>
            getActualExpenseValuesInDateRange(
                allocationOrTimeEntry2,
                dateRange2
            ),
        getProjectedValues: (allocationOrTimeEntry2, dateRange2) =>
            getProjectedExpenseValuesInDateRange(
                allocationOrTimeEntry2,
                dateRange2,
                adjustLikelihood
            ),
    })
}

function getValuesInDateRange({
    model,
    getActualValues,
    getProjectedValues,
    dateRange,
    period = 'months',
    dataType = 'actualsProjected',
} = {}) {
    const { startOf, endOf } = dateUnitLookup[period]
    const startDate = dateRange[0] && startOf(dateRange[0])
    const endDate = dateRange[1] && endOf(dateRange[1])
    const currentPeriodStart = startOf(new Date())
    const currentPeriodEnd = endOf(new Date())

    const useProjected = ['projected', 'actualsProjected'].includes(dataType)
    const useActuals = ['actuals', 'actualsProjected'].includes(dataType)

    let pastValues = 0
    let currentValues = 0
    let futureValues = 0

    if (!startDate || startDate < currentPeriodStart) {
        const pastRange = [
            startDate,
            _.min([endDate, subDays(currentPeriodStart, 1)]),
        ]
        pastValues = useActuals
            ? getActualValues(model, pastRange)
            : getProjectedValues(model, pastRange)
    }

    if (!endDate || endDate > currentPeriodEnd) {
        const futureRange = [
            _.max([startDate, addDays(currentPeriodEnd, 1)]),
            endDate,
        ]
        futureValues = useProjected
            ? getProjectedValues(model, futureRange)
            : getActualValues(model, futureRange)
    }

    if (
        (!startDate || startDate <= currentPeriodEnd) &&
        (!endDate || endDate >= currentPeriodStart)
    ) {
        const currentRange = [
            _.max([startDate, currentPeriodStart]),
            _.min([endDate, currentPeriodEnd]),
        ]
        currentValues = _.max([
            useProjected ? getProjectedValues(model, currentRange) : 0,
            useActuals ? getActualValues(model, currentRange) : 0,
        ])
    }
    return pastValues + futureValues + currentValues
}

export function getActualRevenueValuesInDateRange(
    projectOrPhase,
    dateRange,
    invoiceDateProp = 'issueDate',
    billingTypes = ['agreedFee'],
    includeExpenses = true,
    expenseNames = []
) {
    return (
        _.sum(
            projectOrPhase.invoiceLineItems
                .filter(
                    (li) =>
                        isDateOnOrBetweenRange(
                            li[invoiceDateProp],
                            dateRange
                        ) && billingTypes.includes(li.billingType)
                )
                .map((li) => li.amount)
        ) +
        _.sum(
            projectOrPhase.changeLog
                .filter((cli) => isDateOnOrBetweenRange(cli.date, dateRange))
                .map((cli) => cli.revenue)
        ) -
        (includeExpenses
            ? _.sum(
                  projectOrPhase.expenses.map((e) =>
                      getActualExpenseValuesInDateRange(
                          e,
                          dateRange,
                          expenseNames
                      )
                  )
              ) +
              _.sum(
                  projectOrPhase.changeLog.map((cli) =>
                      getActualExpenseValuesInDateRange(
                          cli,
                          dateRange,
                          expenseNames
                      )
                  )
              )
            : 0)
    )
}

export function getProjectedRevenueValuesInDateRange(
    projectOrPhase,
    dateRange,
    includeExpenses = true,
    expenseNames = [],
    adjustLikelihood = true
) {
    if (projectOrPhase.status === 'archived') return 0
    return (
        _.sum(
            projectOrPhase.revenueTargets
                .filter((rt) => isDateOnOrBetweenRange(rt.date, dateRange))
                .map((rt) => {
                    if (adjustLikelihood) {
                        return rt.amountAdjustedByLikelihood
                    } else {
                        return rt.amount
                    }
                })
        ) -
        (includeExpenses
            ? _.sum(
                  projectOrPhase.expenses.map((e) =>
                      getProjectedExpenseValuesInDateRange(
                          e,
                          dateRange,
                          expenseNames
                      )
                  )
              )
            : 0)
    )
}

export function getActualResourceValuesInDateRange(
    allocationOrTimeEntry,
    dateRange,
    valueType = 'hours'
) {
    if (allocationOrTimeEntry.modelType === 'allocation') return 0
    if (!isDateOnOrBetweenRange(allocationOrTimeEntry.date, dateRange)) return 0
    return allocationOrTimeEntry[valueType]
}

export function getProjectedResourceValuesInDateRange(
    allocationOrTimeEntry,
    dateRange,
    valueType = 'hours',
    adjustLikelihood = true
) {
    if (allocationOrTimeEntry.modelType === 'timeEntry') return 0
    if (!isDateOnOrBetweenRange(allocationOrTimeEntry.date, dateRange)) return 0
    if (adjustLikelihood) {
        return (
            allocationOrTimeEntry[valueType] *
            (allocationOrTimeEntry.likelihood / 100)
        )
    } else {
        return allocationOrTimeEntry[valueType]
    }
}

export function getActualExpenseValuesInDateRange(
    projectExpenseOrChangeLog,
    dateRange,
    expenseNames = []
) {
    if (
        projectExpenseOrChangeLog.modelType === 'projectExpense' &&
        (expenseNames.length
            ? expenseNames.includes(projectExpenseOrChangeLog.name)
            : true)
    )
        return projectExpenseOrChangeLog.costInDateRange(dateRange)
    if (
        projectExpenseOrChangeLog.modelType === 'changeLogItem' &&
        (expenseNames.length
            ? expenseNames.includes(projectExpenseOrChangeLog.title)
            : true) &&
        isDateOnOrBetweenRange(projectExpenseOrChangeLog.date, dateRange)
    )
        return projectExpenseOrChangeLog.expenses
    return 0
}

export function getProjectedExpenseValuesInDateRange(
    projectExpenseOrChangeLog,
    dateRange,
    adjustLikelihood = true,
    expenseNames = []
) {
    if (
        projectExpenseOrChangeLog.modelType !== 'projectExpense' ||
        !(expenseNames.length
            ? expenseNames.includes(projectExpenseOrChangeLog.name)
            : true)
    )
        return 0
    if (adjustLikelihood) {
        return projectExpenseOrChangeLog.allocatedCostInDateRange(dateRange)
    } else {
        return projectExpenseOrChangeLog.allocatedCostInDateRangeAdjustedByLikelihood(
            dateRange
        )
    }
}
