import { initParser } from './core/initParser'
import { invoiceProperties } from './objectProps/invoice'
import { projectProperties } from './objectProps/project'
import { contactProperties } from './objectProps/contact'
import InvoiceCollection from '../State/Collections/InvoiceCollection'
import SessionStore from '../State/SessionStore'
import ProjectCollection from '../State/Collections/ProjectCollection'
import { isSameYear } from 'date-fns'
import ContactCollection from '../State/Collections/ContactCollection'

const getObjs = () => {
    return {
        ...Object.fromEntries(
            InvoiceCollection.invoices.map((i) => [
                'inv' + i.id,
                { ...i.serialize(), type: 'invoice' },
            ])
        ),
        ...Object.fromEntries(
            ProjectCollection.projects.map((i) => [
                'pr' + i.id,
                { ...i.serialize(), type: 'project' },
            ])
        ),
        ...Object.fromEntries(
            ContactCollection.contacts.map((i) => [
                'con' + i.id,
                { ...i.serialize(), type: 'contact' },
            ])
        ),
        inv0: {
            type: 'invoice',
            projectId: ProjectCollection.projects[0]?.id || -1,
        },
    }
}

const getHashObj = (ref, contextRef) => {
    const contextObj = getContextObj(contextRef)
    const refObj = contextObj?.siblingsByRef?.[ref] || getObjs()[ref]
    return refObj
}
const getAtObj = (ref, contextRef) => {
    const contextObj = getContextObj(contextRef)
    const refObj = contextObj?.siblingsByRef?.[ref] || getObjs()[ref]
    return refObj
}
const getContextObj = ([ref, prop]) => {
    return getObjs()[ref]
}

export default initParser({
    getHashObj,
    getAtObj,
    getContextObj,
    constants: {
        invNum: () => {
            return {
                type: 'number',
                value:
                    (SessionStore.organisation.invNum || 0) +
                    1 +
                    (SessionStore.organisation.settings.invoiceNumber
                        ?.numAdjustor || 0),
            }
        },
        projNum: () => ({
            type: 'number',
            value:
                ProjectCollection.projects.length +
                1 +
                (SessionStore.organisation.settings.projectNumber
                    ?.numAdjustor || 0),
        }),
        invNumYear: () => ({
            type: 'number',
            value:
                (SessionStore.organisation.invNumYear || 0) +
                1 +
                (SessionStore.organisation.settings.invoiceNumber
                    ?.numYearAdjustors?.[new Date().getFullYear()] || 0),
        }),
        projNumYear: () => ({
            type: 'number',
            value:
                ProjectCollection.projects.filter((i) =>
                    isSameYear(i.createdAt, new Date())
                ).length +
                1 +
                (SessionStore.organisation.settings.projectNumber
                    ?.numYearAdjustors?.[new Date().getFullYear()] || 0),
        }),
    },
    functions: {
        // hello: {
        // 	number: (args, context) => ({
        // 		type: "text",
        // 		value: "hi",
        // 	}),
        // },
    },
    objectProps: {
        invoice: {
            propertyLookup: invoiceProperties,
            methodLookup: {},
        },
        project: {
            propertyLookup: projectProperties,
            methodLookup: {},
        },
        contact: {
            propertyLookup: contactProperties,
            methodLookup: {},
        },
    },
})
