import { observable, computed, action, makeObservable } from 'mobx'
import PhaseCollection from '../Collections/PhaseCollection'
import ProjectCollection from '../Collections/ProjectCollection'
import ResourceRowCollection from '../Collections/ResourceRowCollection'
import Model from './Model'
import _ from 'lodash'
import bind from 'bind-decorator'
import {
    addDays,
    addMonths,
    addYears,
    differenceInBusinessDays,
    endOfMonth,
    format,
    parse,
    startOfDay,
    startOfMonth,
    subMonths,
    subYears,
} from 'date-fns'
import RoleCollection from '../Collections/RoleCollection'
import StaffCollection from '../Collections/StaffCollection'
import { computedFn } from 'mobx-utils'
import getCombinedRateInDateRange from '../../Utils/getCombinedRateInDateRange'
import { matcher } from 'underscore'
import OrganisationHolidayCollection from '../Collections/OrganisationHolidayCollection'
import { setCostInMonth } from '../../Utils/forecastHelpers'

const propByGroup = {
    status: 'status',
    project: 'projectId',
    phase: 'phaseId',
    staff: 'staffId',
    role: 'staffRoleId',
}

const titlePropByGroup = {
    project: 'title',
    phase: 'title',
    staff: 'fullName',
    role: 'name',
}

class ResourceRowModel extends Model {
    @observable label = null
    @observable budget = 0
    @observable actualHours = {}
    @observable projectedHours = {}
    @observable prospectiveHours = {}
    @observable actualCost = {}
    @observable projectedCost = {}
    @observable prospectiveCost = {}
    @observable editedHours = {}
    @observable editedCost = {}
    @observable status = null
    @observable projectId = null
    @observable phaseId = null
    @observable phaseTitle = null
    @observable staffRoleId = null
    @observable staffId = null
    @observable hoursStartDate = null
    @observable hoursEndDate = null
    @observable groups = ['status']
    @observable store = null
    @observable parent = null
    @observable children = []
    @observable isRootPhase = null

    constructor(data, options) {
        super()
        makeObservable(this)
        this.collection = ResourceRowCollection
        this.init(data, options)
    }

    @action.bound
    setStore(store) {
        this.children.forEach((r) => r.setStore(store))
        if (this.store === store) return
        this.store = store
    }

    @action.bound
    setParent(parent) {
        this.parent = parent
    }

    @action.bound
    setChildren(children = []) {
        this.children = children
    }

    @computed
    get leaves() {
        if (this.children.length) {
            return this.children.flatMap((c) => c.leaves)
        }
        return [this]
    }

    @computed
    get hoursData() {
        return (
            this.store?.report?.filters?.hoursData ||
            this.store?.report?.filters?.revenueData ||
            this.store?.report?.filters?.expenseData ||
            'actualsProjected'
        )
    }

    @computed
    get title() {
        const lastGroup = this.groups[this.groups.length - 1]
        if (!this.label || this.label === '') {
            const titleProp = titlePropByGroup[lastGroup]
            return titleProp ? this[lastGroup]?.[titleProp] : this[lastGroup]
        } else {
            return this.label
        }
    }

    @computed
    get hoursBudget() {
        if (!('staffId' in this.matchers || 'staffRoleId' in this.matchers)) {
            return (
                this.budget ||
                this.phase?.hoursBudget ||
                this.project?.hoursBudget
            )
        } else {
            return this.budget || _.sum(this.children.map((c) => c.hoursBudget))
        }
    }

    @computed
    get costBudget() {
        if (!('staffId' in this.matchers || 'staffRoleId' in this.matchers)) {
            return (
                this.hoursBudget * this.costRate ||
                this.phase?.expenseBudget ||
                this.project?.expenseBudget
            )
        } else {
            return (
                this.hoursBudget * this.costRate ||
                _.sum(this.children.map((c) => c.costBudget))
            )
        }
    }

    @computed
    get chargeOutBudget() {
        if (!('staffId' in this.matchers || 'staffRoleId' in this.matchers)) {
            return (
                this.hoursBudget * this.chargeOutRate ||
                this.phase?.fee ||
                this.project?.fee
            )
        } else {
            return (
                this.hoursBudget * this.chargeOutRate ||
                _.sum(this.children.map((c) => c.chargeOutBudget))
            )
        }
    }

    @computed
    get level() {
        return this.groups.length - 1
    }

    @computed
    get childPhases() {
        return this.phase
            ? [this.phase]
            : [...new Set(this.children.flatMap((c) => c.childPhases))].filter(
                  (p) => p
              )
    }

    @computed
    get childPhaseIds() {
        return this.phaseId
            ? [this.phaseId]
            : [
                  ...new Set(this.children.flatMap((c) => c.childPhaseIds)),
              ].filter((p) => p)
    }

    @computed
    get childProjects() {
        return this.project
            ? [this.project]
            : [
                  ...new Set(this.children.flatMap((c) => c.childProjects)),
              ].filter((p) => p)
    }

    @computed
    get childProjectIds() {
        return this.projectId
            ? [this.projectId]
            : [
                  ...new Set(this.children.flatMap((c) => c.childProjectIds)),
              ].filter((p) => p)
    }

    @computed
    get childProjectRows() {
        return this.project
            ? [this]
            : [...new Set(this.children.flatMap((c) => c.childProjectRows))]
    }

    @computed
    get childStaff() {
        return this.staff
            ? [this.staff]
            : [...new Set(this.children.flatMap((c) => c.childStaff))].filter(
                  (s) => s
              )
    }

    @computed
    get childRoles() {
        return this.role
            ? [this.role]
            : [...new Set(this.children.flatMap((c) => c.childRoles))]
    }

    @computed
    get phase() {
        return PhaseCollection.phasesById[this.matchers.phaseId]
    }

    @computed
    get project() {
        return ProjectCollection.projectsById[this.matchers.projectId]
    }

    @computed
    get role() {
        return RoleCollection.rolesById[this.matchers.staffRoleId]
    }

    @computed
    get staff() {
        return StaffCollection.staffById[this.matchers.staffId]
    }

    @computed
    get allStaff() {
        return (
            (this.groups.includes('staff')
                ? [this.staff]
                : this.groups.includes('role')
                ? this.role?.staff ||
                  StaffCollection.staffByRoleId[this.staffRoleId]
                : StaffCollection.staff) || []
        ).filter((s) => s && !s?.deletedAt)
    }

    @computed
    get matchers() {
        let matchers = {}
        if (this.groups.includes('staff')) {
            matchers.staffId = this.staffId
        }
        if (this.groups.includes('role')) {
            matchers.staffRoleId = this.staffRoleId
        }
        if (this.groups.includes('phase')) {
            matchers.phaseId = this.phaseId
        }
        if (this.groups.includes('project')) {
            matchers.projectId = this.projectId
        }
        if (this.groups.includes('status')) {
            matchers.status = this.status
        }
        return matchers
    }

    @bind
    getAvailabilityInMonth(month, store) {
        const monthDate = startOfMonth(parse(month, 'yyyy-MM', new Date()))
        const start = startOfMonth(monthDate)
        const end = endOfMonth(monthDate)
        const dayAfterEnd = startOfMonth(addDays(end, 1))
        const holidayDays = _.sum(
            OrganisationHolidayCollection.organisationHolidays
                .filter((h) => h.startDate <= end && h.endDate >= start)
                .map((h) => {
                    const startDate = Math.max(h.startDate, start)
                    const endDate = addDays(
                        startOfDay(Math.min(h.endDate, end)),
                        1
                    )
                    return differenceInBusinessDays(endDate, startDate)
                })
        )
        return (
            _.sum(
                this.allStaff
                    .filter((s) => !store || store.staff.includes(s))
                    .map(
                        (s) =>
                            s.getAvailabilityInDateRange([start, end]) / 60 / 5
                    )
            ) *
            (differenceInBusinessDays(dayAfterEnd, start) - holidayDays)
        )
    }

    @bind
    getTotalAvailability(store) {
        const endMonth = startOfMonth(this.hoursEndDate || new Date())
        let startMonth = startOfMonth(this.hoursStartDate || new Date())
        const months = []
        while (startMonth <= endMonth) {
            months.push(format(startMonth, 'yyyy-MM'))
            startMonth = startOfMonth(addMonths(startMonth, 1))
        }
        return (
            _.sum(months.map((m) => this.getAvailabilityInMonth(m, store))) || 0
        )
    }

    @computed
    get hoursMonths() {
        if (this.children.length) {
            return _.uniq(this.children.flatMap((r) => r.hoursMonths))
        }
        return _.uniq([
            ...Object.keys(this.actualHours),
            ...Object.keys(this.projectedHours),
            ...Object.keys(this.editedHours),
        ])
    }

    getHoursInMonth = (month, hoursData = this.hoursData) => {
        if (this.children.length) {
            return _.sum(this.children.map((r) => r.getHoursInMonth(month)))
        }
        if (hoursData === 'actual') {
            return this.actualHours[month] ?? 0
        }
        if (hoursData === 'projected') {
            if (this.staff?.isArchived) return 0
            return this.editedHours[month] ?? this.projectedHours[month] ?? 0
        }
        if (hoursData === 'actualsProjected') {
            if (month < format(new Date(), 'yyyy-MM')) {
                return this.actualHours[month] ?? 0
            } else if (month > format(new Date(), 'yyyy-MM')) {
                return (
                    this.editedHours[month] ?? this.projectedHours[month] ?? 0
                )
            } else {
                return Math.max(
                    this.actualHours[month] ?? 0,
                    this.editedHours[month] ?? this.projectedHours[month] ?? 0
                )
            }
        }
        if (hoursData === 'remainingProjected') {
            return (
                (this.editedHours[month] ?? this.projectedHours[month] ?? 0) -
                (this.actualHours[month] ?? 0)
            )
        }
        if (hoursData === 'remainingProjectedCapped') {
            return Math.max(
                (this.editedHours[month] ?? this.projectedHours[month] ?? 0) -
                    (this.actualHours[month] ?? 0),
                0
            )
        }
    }

    getProspectiveHoursInMonth = computedFn((month) => {
        if (this.children.length) {
            return _.sum(
                this.children.map((r) => r.getProspectiveHoursInMonth(month))
            )
        }
        if (this.hoursData === 'actual') {
            return 0
        }
        if (this.hoursData === 'projected') {
            if (this.staff?.isArchived) return 0
            return this.prospectiveHours[month] ?? 0
        }
        if (this.hoursData === 'actualsProjected') {
            if (month < format(new Date(), 'yyyy-MM')) {
                return 0
            } else if (month >= format(new Date(), 'yyyy-MM')) {
                return this.prospectiveHours[month] ?? 0
            }
        }
    })

    getCostInMonth = computedFn((month) => {
        if (this.children.length) {
            return _.sum(this.children.map((r) => r.getCostInMonth(month)))
        }
        if (this.hoursData === 'actual') {
            return this.actualCost[month] ?? 0
        }
        if (this.hoursData === 'projected') {
            return this.editedCost[month] ?? this.projectedCost[month] ?? 0
        }
        if (this.hoursData === 'actualsProjected') {
            if (month < format(new Date(), 'yyyy-MM')) {
                return this.actualCost[month] ?? 0
            } else if (month > format(new Date(), 'yyyy-MM')) {
                return this.editedCost[month] ?? this.projectedCost[month] ?? 0
            } else {
                return Math.max(
                    this.actualCost[month] ?? 0,
                    this.editedCost[month] ?? this.projectedCost[month] ?? 0
                )
            }
        }
    })

    getHoursToDateInMonth = computedFn((month) => {
        const prevHoursMonths = this.hoursMonths.filter((m) => m <= month)
        const hoursToDate = _.sum(
            prevHoursMonths.map((m) => this.getHoursInMonth(m))
        )
        return hoursToDate
    })

    getCostToDateInMonth = computedFn((month) => {
        const prevHoursMonths = this.hoursMonths.filter((m) => m <= month)
        const costToDate = _.sum(
            prevHoursMonths.map((m) => this.getCostInMonth(m))
        )
        return costToDate
    })

    @bind
    getProgressInMonth(month) {
        return this.getHoursToDateInMonth(month) / this.hoursBudget
    }

    getTotalHours = computedFn(() => {
        return this.getHoursToDateInMonth(
            format(addYears(new Date(), 100), 'yyyy-MM')
        )
    })

    @bind
    getCostProgressInMonth(month) {
        return this.getCostToDateInMonth(month) / this.costBudget
    }

    getTotalCost = computedFn(() => {
        return this.getCostToDateInMonth(
            format(addYears(new Date(), 100), 'yyyy-MM')
        )
    })

    @computed
    get resource() {
        return this.staff || this.role
    }

    @computed
    get costRate() {
        if (this.children.length) {
            {
                return _.mean(this.children.map((r) => r.costRate))
            }
        }
        return getCombinedRateInDateRange(
            {
                project: this.project,
                phase: this.phase,
                staff: this.staff,
                role: this.role,
            },
            'cost',
            [this.startDate, this.endDate]
        )
    }

    @computed
    get chargeOutRate() {
        if (this.children.length) {
            {
                return _.mean(this.children.map((r) => r.chargeOutRate))
            }
        }
        return getCombinedRateInDateRange(
            {
                project: this.project,
                phase: this.phase,
                staff: this.staff,
                role: this.role,
            },
            'chargeOut',
            [this.startDate, this.endDate]
        )
    }

    getCostRateInMonth(month) {
        const costInMonth = this.getCostInMonth(month)
        const hoursInMonth = this.getHoursInMonth(month)
        if (hoursInMonth) {
            return costInMonth / hoursInMonth
        }
        if (this.children.length) {
            return _.mean(this.children.map((r) => r.getCostRateInMonth(month)))
        }
        const monthDate = startOfMonth(parse(month, 'yyyy-MM', new Date()))
        const monthEndDate = endOfMonth(monthDate)
        return getCombinedRateInDateRange(
            {
                project: this.project,
                phase: this.phase,
                staff: this.staff,
                role: this.role,
            },
            'cost',
            [monthDate, monthEndDate]
        )
    }

    getChargeOutRateInMonth(month) {
        const chargeOutInMonth = this.getChargeOutInMonth(month)
        const hoursInMonth = this.getHoursInMonth(month)
        if (hoursInMonth) {
            return chargeOutInMonth / hoursInMonth
        }
        if (this.children.length) {
            return _.mean(
                this.children.map((r) => r.getChargeOutRateInMonth(month))
            )
        }
        const monthDate = startOfMonth(parse(month, 'yyyy-MM', new Date()))
        const monthEndDate = endOfMonth(monthDate)
        return getCombinedRateInDateRange(
            {
                project: this.project,
                phase: this.phase,
                staff: this.staff,
                role: this.role,
            },
            'chargeOut',
            [monthDate, monthEndDate]
        )
    }

    @action.bound
    setHoursProgressInMonth(month, progress) {
        progress ||= 0
        const monthDate = parse(month, 'yyyy-MM', new Date())
        const prevMonth = format(subMonths(monthDate, 1), 'yyyy-MM')
        const prevProgress = this.getProgressInMonth(prevMonth) || 0
        const diffProgress = Math.max(progress, prevProgress) - prevProgress
        const diffHours = diffProgress * this.hoursBudget
        return this.setHoursInMonth(month, diffHours)
    }

    @action.bound
    setCostProgressInMonth(month, progress) {
        progress ||= 0
        const monthDate = parse(month, 'yyyy-MM', new Date())
        const prevMonth = format(subMonths(monthDate, 1), 'yyyy-MM')
        const prevProgress = this.getCostProgressInMonth(prevMonth) || 0
        const diffProgress = Math.max(progress, prevProgress) - prevProgress
        const diffCost = diffProgress * this.costBudget
        return this.setCostInMonth(month, diffCost)
    }

    @action
    setHoursInMonth(month, v, options) {
        const costRate = this.getCostRateInMonth(month)
        if (!this.children.length) {
            this.editedHours[month] = v
            this.update({ editedHours: { ...this.editedHours } }, options)
            this.editedCost[month] = v * costRate
        }
        const children = this.children
        const childrenWithHoursInMonth = children.filter(
            (r) => r.getHoursInMonth(month) > 0
        )
        const existingHoursInMonth = _.sum(
            childrenWithHoursInMonth.map((r) => r.getHoursInMonth(month))
        )
        if (existingHoursInMonth) {
            const ratio = v / existingHoursInMonth
            childrenWithHoursInMonth.forEach((r) =>
                r.setHoursInMonth(
                    month,
                    r.getHoursInMonth(month) * ratio,
                    options
                )
            )
        } else {
            children.forEach((r) =>
                r.setHoursInMonth(month, v / children.length, options)
            )
        }
    }

    @action
    setCostInMonth(month, v, options) {
        const costRate = this.getCostRateInMonth(month)
        const hours = costRate ? v / costRate : 0
        this.setHoursInMonth(month, hours, options)
    }

    serialize() {
        return {
            id: this.id,
            editedHours: this.editedHours,
            reportFilters: {
                ...(this.store?.report?.filters || {}),
                ...this.matchers,
            },
        }
    }

    serializeUpdates() {
        return this.serialize()
    }
}

export default ResourceRowModel
