import { createTRPCProxyClient, httpBatchLink } from '@trpc/client'
import type { AppRouter } from '../../../server_node/src/trpc/router'
import { clerk } from '../App'
import { usingNewLogin } from '../State/Permissions/HasPermissions'

export const trpc = createTRPCProxyClient<AppRouter>({
    links: [
        httpBatchLink({
            url: `${process.env.REACT_APP_NODE_SERVER_URL}/trpc`,
            async headers() {
                const headers = {}
                if (usingNewLogin()) {
                    const token = await clerk?.session?.getToken?.({
                        template: 'coincraft',
                    })
                    headers.Authorization = token ? `Bearer ${token}` : ''
                }
                return headers
            },
            fetch(url, options) {
                return fetch(url, {
                    ...options,
                    credentials: 'include', // This is equivalent to withCredentials: true
                })
            },
        }),
    ],
})
