import { createFileRoute, notFound } from '@tanstack/react-router'
import ProjectDetailsTab from '../../Pages/ProjectPage/ProjectDetailsTab'
import ProjectPageHeader from '../../Pages/ProjectPage/ProjectPageHeader'
import PageBody from '../../Pages/Layout/PageBody'
import { idf, qf } from '../../Queries/queryFormatter'
import fetchData from '../../Queries/fetchData'
import LoadingSpinner from '../../Components/LoadingSpinner'
import ErrorComponent from '../../Components/ErrorComponent'
import {
    canViewRoute,
    migratedToV2,
} from '../../State/Permissions/HasPermissions'
import SessionStore from '../../State/SessionStore'
import PermissionDenied from '../../Pages/PermissionDenied'
import ProjectForecastsTab from '../../Pages/ProjectPage/forecasts/ProjectForecastsTab'
import ProjectForecastsSidebar from '../../Pages/ProjectPage/forecasts/ProjectForecastsSidebar'
import ProjectForecastHeader from '../../Pages/ProjectPage/forecasts/ProjectForecastHeader'
import ProjectTasksTab from '../../Pages/ProjectPage/ProjectTasksTab'
import ProjectChangeLogTab from '../../Pages/ProjectPage/ProjectChangeLogTab'
import ProjectRatesTab from '../../Pages/ProjectPage/ProjectRatesTab'
import ProjectNotesTab from '../../Pages/ProjectPage/ProjectNotesTab'
import ProjectStatusTab from '../../Pages/ProjectPage/ProjectStatusTab'
import PageSidebar from '../../Pages/Layout/PageSidebar'
import ProjectHistoryTab from '../../Pages/ProjectPage/ProjectHistoryTab'
import DataStore from '../../State/DataStore'
import { router } from '../../App'
import ProjectCollection from '../../State/Collections/ProjectCollection'

const tabs = {
    details: {
        label: 'Project Details',
        id: 'details',
        page: (props) => <ProjectDetailsTab {...props} />,
        collections: (id) => {
            return [
                {
                    collection: 'projects',
                    filters: [idf(id)],
                    fields: [
                        'contactId',
                        'primaryContactId',
                        'costCentreId',
                        'ownerId',
                        'milestoneType',
                        'durationUnit',
                        'feeData',
                    ],
                },
                {
                    collection: 'phases',
                    filters: [idf(id, 'project')],
                    fields: [
                        'startDate',
                        'endDate',
                        'fee',
                        'expenseBudget',
                        'hoursBudget',
                        'projectId',
                        'durationUnit',
                        'feeLinked',
                        'expenseBudgetLinked',
                        'hoursBudgetLinked',
                        'isRootPhase',
                        'position',
                        'feeData',
                    ],
                },
                {
                    collection: 'projectExpenses',
                    filters: [idf(id, 'project')],
                    fields: [
                        'projectId',
                        'phaseId',
                        'supplierId',
                        'name',
                        'quantity',
                        'unitCost',
                        'billable',
                        'cost',
                        'startDate',
                        'endDate',
                        'beenInvoiced',
                        [
                            'previousBilled',
                            `expenseLineItems.previousAmount != null ? expenseLineItems.previousAmount : 0`,
                        ],
                        ['remainingCost', 'cost - previousBilled'],
                    ],
                    subQueries: [
                        {
                            label: 'expenseLineItems',
                            collection: 'invoiceLineItems',
                            join: 'id == expenseLineItems.expenseId',
                            groupBy: ['expenseId'],
                            fields: [['previousAmount', 'sum(amount)']],
                        },
                    ],
                },
                {
                    collection: 'budgetedHours',
                    filters: [idf(id, 'project')],
                    fields: [
                        'projectId',
                        'phaseId',
                        'staffId',
                        'roleId',
                        'hours',
                    ],
                },
                {
                    collection: 'suppliers',
                    fields: ['name'],
                },
            ]
        },
        permissions: (params) =>
            canViewRoute(SessionStore.user, 'project-details', params),
    },
    forecasts: {
        label: 'Forecasts',
        id: 'forecasts',
        page: (props) => <ProjectForecastsTab {...props} />,
        header: (props) => <ProjectForecastHeader {...props} />,
        sidebar: (props) => <ProjectForecastsSidebar {...props} />,
        collections: () => {
            const reportId =
                SessionStore.organisation?.defaultRevenueForecastReportId
            return [
                {
                    collection: 'revenueForecastReports',
                    fields: ['name', 'filters'],
                    filters: [`id == ${qf(reportId)}`],
                },
            ]
        },
        permissions: (params) =>
            canViewRoute(SessionStore.user, 'project-forecasts', params),
    },
    tasks: {
        label: 'Tasks',
        id: 'tasks',
        page: (props) => <ProjectTasksTab {...props} />,
        collections: (id) => {
            return [
                {
                    collection: 'tasks',
                    filters: [idf(id, 'project')],
                    fields: [
                        'name',
                        'phaseId',
                        'isBillable',
                        'isVariation',
                        'isDefault',
                    ],
                },
            ]
        },
        permissions: (params) =>
            canViewRoute(SessionStore.user, 'project-tasks', params),
    },
    changelog: {
        label: 'Changelog',
        id: 'changelog',
        page: (props) => <ProjectChangeLogTab {...props} />,
        collections: (id) => {
            return [
                {
                    collection: 'changeLogItems',
                    filters: [idf(id, 'project')],
                    fields: [
                        'date',
                        'title',
                        'revenue',
                        'revenueType',
                        'expenses',
                        'percentProgress',
                        'projectId',
                        'phaseId',
                    ],
                },
                {
                    collection: 'invoices',
                    filters: [idf(id, 'project')],
                    fields: ['ref', 'taxRatePercent', 'projectId', 'issueDate'],
                },
                {
                    collection: 'invoiceLineItems',
                    filters: [idf(id, 'project')],
                    fields: [
                        'invoiceId',
                        'projectId',
                        'phaseId',
                        'billingType',
                        'unitQuantity',
                        'unitCost',
                        'isTaxed',
                    ],
                },
                {
                    collection: 'projectExpenses',
                    filters: [idf(id, 'project')],
                    fields: [
                        'projectId',
                        'phaseId',
                        'name',
                        'quantity',
                        'unitCost',
                        'billable',
                        'cost',
                        'startDate',
                    ],
                },
            ]
        },
        permissions: (params) =>
            canViewRoute(SessionStore.user, 'project-changelog', params),
    },
    rates: {
        label: 'Rates',
        id: 'rates',
        page: (props) => <ProjectRatesTab {...props} />,
        collections: (id) => {
            return [
                {
                    collection: 'projectRates',
                    filters: [idf(id, 'project')],
                    fields: [
                        'projectId',
                        'itemType',
                        'itemId',
                        'phaseId',
                        'date',
                        'costRate',
                        'chargeOutRate',
                    ],
                },
            ]
        },
        permissions: (params) =>
            canViewRoute(SessionStore.user, 'project-rates', params),
    },
    notes: {
        label: 'Notes',
        id: 'notes',
        page: (props) => <ProjectNotesTab {...props} />,
        collections: (id) => {
            return [
                {
                    collection: 'projectNotes',
                    filters: [idf(id, 'project')],
                    fields: ['date', 'description', 'projectId', 'phaseId'],
                },
            ]
        },
        permissions: (params) =>
            canViewRoute(SessionStore.user, 'project-notes', params),
    },
    history: {
        label: 'History',
        id: 'history',
        page: (props) => <ProjectHistoryTab {...props} />,
        collections: (id) => {
            return [
                {
                    collection: 'projects',
                    filters: [idf(id)],
                    fields: ['history'],
                    chain: [
                        {
                            collection: 'phases',
                            join: {
                                projects: 'id',
                                phases: 'projectId',
                            },
                            fields: ['history'],
                        },
                    ],
                },
            ]
        },
        permissions: (params) =>
            canViewRoute(SessionStore.user, 'project-history', params),
    },
    status: {
        label: 'Status',
        id: 'status',
        page: (props) => <ProjectStatusTab {...props} />,
        collections: (id) => {
            return [
                {
                    collection: 'projects',
                },
                {
                    collection: 'phases',
                    filters: [idf(id, 'project')],
                    fields: [
                        'status',
                        'percentLikelihood',
                        'startDate',
                        'projectId',
                        'isRootPhase',
                        'position',
                    ],
                },
            ]
        },
        permissions: (params) =>
            canViewRoute(SessionStore.user, 'project-status', params),
    },
}

export const Route = createFileRoute('/_private/projects/$id')({
    beforeLoad: ({ params, search }) => {
        const activeTab = search?.tab || 'details'
        if (
            !canViewRoute(SessionStore.user, 'project', params) ||
            !canViewRoute(SessionStore.user, `project-${activeTab}`, params)
        )
            throw new Error('Permission Denied')
    },
    errorComponent: ({ error, reset }) => {
        if (error.message === 'Permission Denied') return <PermissionDenied />
        return <ErrorComponent error={error} reset={reset} />
    },
    loaderDeps: ({ search: { tab } }) => ({
        tab,
    }),
    loader: async ({ params, location }) =>
        await loader(params.id, location.search),
    pendingComponent: LoadingSpinner,
    component: Wrapper,
    onLeave: (match) => {
        const { id } = match.params
        const project = ProjectCollection.projectsById[id]
        if (
            !project?.createdAt &&
            match.pathname !== router.latestLocation.pathname
        ) {
            DataStore.destroyNewItems('projectId', id)
        }
    },
})

const loader = async (id, search) => {
    const { tab } = search
    const activeTab = tab || 'details'
    let collections = [
        {
            collection: 'projects',
            fields: ['jobNumber', 'name', 'rootPhaseId'],
            filters: [idf(id)],
        },
        {
            collection: 'phases',
            filters: [idf(id, 'project')],
            fields: [
                'jobNumber',
                'name',
                'projectId',
                'isRootPhase',
                'position',
                'status',
                'startDate',
                'endDate',
                'fee',
                'expenseBudget',
                'hoursBudget',
                'status',
                'percentLikelihood',
                'feeLinked',
                'expenseBudgetLinked',
                'hoursBudgetLinked',
                'durationUnit',
            ],
        },
        {
            collection: 'tasks',
            filters: [idf(id, 'project')],
            fields: [
                'name',
                'phaseId',
                'isBillable',
                'isVariation',
                'isDefault',
            ],
        },
        {
            collection: 'staff',
            filters: [`isArchived == false`],
            fields: [
                'firstName',
                'lastName',
                'roleId',
                'inheritCostRate',
                'inheritChargeOutRate',
            ],
        },
        {
            collection: 'roles',
            filters: [`isArchived == false`],
            fields: ['name', 'avgChargeOutRate', 'avgCostRate'],
        },
        {
            collection: 'staffRates',

            fields: [
                'staffId',
                'date',
                'costRate',
                'chargeOutRate',
                'weeklyAvailability',
            ],
        },
        {
            collection: 'roleRates',

            fields: ['roleId', 'date', 'costRate', 'chargeOutRate'],
        },
        {
            collection: 'projectRates',
            filters: [idf(id, 'project')],
            fields: [
                'projectId',
                'itemType',
                'itemId',
                'phaseId',
                'date',
                'costRate',
                'chargeOutRate',
            ],
        },
        {
            collection: 'contacts',
            fields: [
                'firstName',
                'lastName',
                'organisationName',
                'accountingSystemContactId',
            ],
        },
        {
            collection: 'costCentres',
            fields: ['name', 'isBillable'],
        },
    ]

    collections = collections.concat(tabs[activeTab].collections(id))
    const data = await Promise.all(collections.filter(Boolean).map(fetchData))
    const project = ProjectCollection.projectsById[id]
    if (!project) {
        throw notFound()
    }
    return data
}

function Wrapper() {
    const { tab } = Route.useSearch()
    const params = Route.useParams()
    const activeTab = tab || 'details'
    if (migratedToV2()) {
        return <div>{tabs[activeTab].page(params)}</div>
    }

    return (
        <>
            {tabs[activeTab].header ? (
                tabs[activeTab].header({
                    tabs: Object.values(tabs).filter((t) =>
                        t.permissions(params)
                    ),
                    selectedTab: activeTab,
                    ...params,
                })
            ) : (
                <ProjectPageHeader
                    tabs={Object.values(tabs).filter((t) =>
                        t.permissions(params)
                    )}
                    selectedTab={activeTab}
                    {...params}
                />
            )}
            <PageBody>{tabs[activeTab].page(params)}</PageBody>

            {tabs[activeTab].sidebar && (
                <PageSidebar>
                    {tabs[activeTab].sidebar({
                        ...params,
                    })}
                </PageSidebar>
            )}
        </>
    )
}
