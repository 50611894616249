import _ from 'lodash'
import { observer } from 'mobx-react'
import React, { useState } from 'react'
import Modal from '../../Components/Modal'
import SessionStore from '../../State/SessionStore'
import DataStore from '../../State/DataStore'
import TextValue from '../../Components/Widgets/TextValue'

export default observer(({ modalId }) => {
    const [email, setEmail] = useState(SessionStore.subscription.receiptEmail)
    return (
        <Modal
            modalId={modalId}
            heading="Update Billing Email"
            saveLabel={'Save Email'}
            style={{ minHeight: '5em' }}
            onSave={() => {
                SessionStore.subscription.update({ receiptEmail: email })
                DataStore.saveModel(SessionStore.subscription)
            }}
        >
            <TextValue
                style={{ width: '100%' }}
                value={email}
                onChange={(v) => setEmail(v)}
            />
        </Modal>
    )
})
