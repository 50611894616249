import { observable, computed, action, makeObservable } from 'mobx'
import Collection from './Collection'
import OrganisationModel from '../Models/OrganisationModel'

class OrganisationCollection extends Collection {
    constructor() {
        super({ collection: 'organisations', modelClass: OrganisationModel })
        makeObservable(this)
    }
    get organisationsById() {
        return this.modelsById
    }
    get organisations() {
        return this.models
    }
    @action.bound
    addOrganisation(data) {
        this.add(data)
    }
    @action.bound
    addOrganisations(data) {
        this.addMany(data)
    }
}

export default new OrganisationCollection()
export const OrganisationCollectionClass = OrganisationCollection
