import { NumberFormatter } from '@internationalized/number'
import { getUserLocale } from 'get-user-locale'
import isoLangCodes from 'iso-lang-codes'
import SessionStore from '../../State/SessionStore'
import { constantCase } from 'change-case'

export const FormatNumber = (value, { decimals = 2, compact = false } = {}) => {
    if ([null, undefined, ''].includes(value)) return value
    const formatter = new NumberFormatter(
        isoLangCodes.findCountryLocales(
            constantCase(SessionStore?.organisationCountry || 'us')
        )[0],
        {
            style: 'decimal',
            maximumFractionDigits: decimals,
            notation: compact ? 'compact' : 'standard',
        }
    )
    return formatter.format(value)
}
