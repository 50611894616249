import { observable, computed, action, makeObservable } from 'mobx'
import InvoiceDescriptionTemplateCollection from '../Collections/InvoiceDescriptionTemplateCollection'
import Model from './Model'

class InvoiceDescriptionTemplateModel extends Model {
    @observable name = null
    @observable description = null

    constructor(data, options) {
        super()
        makeObservable(this)
        this.collection = InvoiceDescriptionTemplateCollection
        this.init(data, options)
    }
}
export default InvoiceDescriptionTemplateModel
