import React, { useState } from 'react'
import { observer } from 'mobx-react'
import Modal from '../../Components/Modal'
import { Selector } from '../../Components/Selector'
import RenderOnQueries from '../Layout/RenderOnQueries'
import Table from '../../Components/Table'
import { observable, computed, action, makeObservable } from 'mobx'
import InvoiceLineItemCollection from '../../State/Collections/InvoiceLineItemCollection'
import DataStore from '../../State/DataStore'
import DateValue from '../../Components/Widgets/DateValue'

export default observer(({ modalId, invoice }) => {
    const [startDate, setStartDate] = useState(invoice.startDate)
    const [endDate, setEndDate] = useState(invoice.endDate)
    return (
        <Modal
            modalId={modalId}
            heading="Billing for Work Carried-Out During"
            bodyStyle={{ padding: 0 }}
            saveLabel={'Save Dates'}
            onSave={() => {
                invoice.update({ startDate, endDate })
                DataStore.saveModel(invoice)
            }}
        >
            <div style={{ padding: '2em' }}>
                <div style={{ height: 40 }}>
                    <div style={{ display: 'inline-block', width: 140 }}>
                        From:
                    </div>
                    <div
                        style={{
                            display: 'inline-block',
                            position: 'fixed',
                            zIndex: 1,
                        }}
                    >
                        <DateValue value={startDate} onChange={setStartDate} />
                    </div>
                </div>
                <div style={{ height: 40 }}>
                    <div style={{ display: 'inline-block', width: 140 }}>
                        To:
                    </div>
                    <div
                        style={{
                            display: 'inline-block',
                            position: 'fixed',
                        }}
                    >
                        <DateValue value={endDate} onChange={setEndDate} />
                    </div>
                </div>
            </div>
        </Modal>
    )
})
