import { observer } from 'mobx-react'
import React, { Suspense, useCallback, useEffect } from 'react'
import PermissionCollection from '../../State/Collections/PermissionCollection'

import PageHeader from '../Layout/PageHeader'
import { canEditStaff } from '../../State/Permissions/HasPermissions'
import SessionStore from '../../State/SessionStore'
import { useNavigate } from '@tanstack/react-router'

export default observer(() => {
    const navigate = useNavigate()
    return (
        <PageHeader
            heading={
                <div>
                    <div>{'Permissions'}</div>
                </div>
            }
            extraContent={
                <div className="mt-6">
                    {canEditStaff(SessionStore.user)
                        ? [
                              <button
                                  key={0}
                                  className="project-list__add-new-project-button page-header__button--primary plus-btn"
                                  onClick={() => {
                                      const permission =
                                          PermissionCollection.createPermission()

                                      navigate({
                                          to: '/permissions/$id',
                                          params: { id: permission.id },
                                      })
                                  }}
                              >
                                  + New Permission
                              </button>,
                          ]
                        : null}
                </div>
            }
        />
    )
})
