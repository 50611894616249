export default {
    name: 'New Report',
    filters: {
        costCentres: [],
        expenses: [],
        invoiceData: 'issuedOn',
        projectStatus: [],
        projects: [],
        revenueData: 'actualsProjected',
        staff: [],
        projectOwners: [],
        totalData: 'revenueTotalFee',
        profitDataType: 'margin',
        contractorExpense: 'availableHours',
        showExpenses: true,
        statusGroup: 'phase',
    },
}
