import { observable, computed, action, makeObservable } from 'mobx'
import ContactCollection from '../Collections/ContactCollection'
import InvoiceCollection from '../Collections/InvoiceCollection'
import RevenueTargetAggregateCollection from './RevenueTargetAggregateCollection'
import PhaseCollection from '../Collections/PhaseCollection'
import ProjectCollection from '../Collections/ProjectCollection'
import Model from '../Models/Model'

class RevenueTargetAggregateModel extends Model {
    @observable projectId = null
    @observable phaseId = null

    @observable amount = null

    constructor(data, options) {
        super()
        makeObservable(this)
        this.collection = RevenueTargetAggregateCollection
        this.init(data, options)
    }

    @computed
    get project() {
        return ProjectCollection.projectsById[this.projectId]
    }

    @computed
    get phase() {
        return PhaseCollection.phasesById[this.phaseId]
    }
}

export default RevenueTargetAggregateModel
