import { observable, computed, action, makeObservable } from 'mobx'
import Collection from './Collection'
import ProjectNoteModel from '../Models/ProjectNoteModel'

class ProjectNoteCollection extends Collection {
    constructor() {
        super({ collection: 'projectNotes', modelClass: ProjectNoteModel })
        this.addLookup('notesByPhaseId', 'manyByKey', {
            key: (t) => t.phaseId,
            filter: (t) => !t.deletedAt,
        })
        this.addLookup('notesByProjectId', 'manyByKey', {
            key: (t) => t.projectId,
            filter: (t) => !t.deletedAt,
        })
        makeObservable(this)
    }
    get notesById() {
        return this.modelsById
    }
    get notes() {
        return this.models
    }
    @action.bound
    addNote(data) {
        this.add(data)
    }
    @action.bound
    addNotes(data) {
        this.addMany(data)
    }
}

export default new ProjectNoteCollection()
export const ProjectNoteCollectionClass = ProjectNoteCollection
