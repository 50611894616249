import React from 'react'
import CreateReactClass from 'create-react-class'

import PropTypes from 'prop-types'
import classNames from 'classnames'

export var FilterTextBox = CreateReactClass({
    propTypes: {
        value: PropTypes.string,
        onChange: PropTypes.func,
        placeholder: PropTypes.string,
        className: PropTypes.string,
    },

    render: function () {
        return (
            <div
                style={{ ...this.props.style }}
                className={classNames(
                    'relative flex items-center lg:w-[250px]',
                    this.props.className
                )}
            >
                <i className="fa fa-search absolute left-[1em] top-[calc(50%-0.5em)] p-0 m-0 h-[0.5em] w-[0.5em]" />
                <input
                    type="search"
                    className="bg-transparent py-2 pl-9 pr-4 border border-white text-xs rounded-[6px] w-full text-white focus:outline-none cursor-text hover:bg-white/10"
                    value={this.props.value || ''}
                    onChange={this.handleInputChange}
                    placeholder={this.props.placeholder}
                />
                {this.props.value !== '' ? (
                    <button
                        className="text-xs absolute right-1.5 top-1/2 -translate-y-1/2 border px-2 py-1 rounded-[6px] hover:bg-white/10"
                        onClick={this.handleClearButtonClick}
                    >
                        Clear
                    </button>
                ) : null}
            </div>
        )
    },

    handleInputChange: function (event) {
        this.props.onChange(event.target.value)
    },

    handleClearButtonClick: function () {
        this.props.onChange('')
    },
})
