import {
    addMonths,
    differenceInBusinessDays,
    endOfMonth,
    format,
    parse,
    set,
    startOfDay,
    startOfMonth,
} from 'date-fns'
import _ from 'lodash'
import RevenueTargetAggregateCollection from '../State/Aggregates/RevenueTargetAggregateCollection'
import InvoiceLineItemAggregateCollection from '../State/Aggregates/InvoiceLineItemAggregateCollection'
import ProjectExpenseAggregateCollection from '../State/Aggregates/ProjectExpenseAggregateCollection'
import TimeEntryAggregateCollection from '../State/Aggregates/TimeEntryAggregateCollection'
import AllocationAggregateCollection from '../State/Aggregates/AllocationAggregateCollection'
import DateRangeDataStore from '../State/DateRangeDataStore'
import { qf } from '../Queries/queryFormatter'
import RevenueTargetCollection from '../State/Collections/RevenueTargetCollection'
import AllocationCollection from '../State/Collections/AllocationCollection'
import BudgetCollection from '../State/Collections/BudgetCollection'
import { get } from 'underscore'
import StaffCollection from '../State/Collections/StaffCollection'
import MonthlyResourceCellCollection from '../State/Collections/MonthlyResourceCellCollection'
import ResourceScheduleStore from '../Pages/ResourceSchedulePage/ResourceScheduleStore'
import fetchData from '../Queries/fetchData'
import FetchStore from '../Queries/FetchStore'
import { queryClient } from '../App'
import ResourceRowCollection from '../State/Collections/ResourceRowCollection'
import MonthlyRevenueCellCollection from '../State/Collections/MonthlyRevenueCellCollection'
import RevenueRowCollection from '../State/Collections/RevenueRowCollection'
import RevenueForecastStore from '../Pages/RevenueForecastPage/RevenueForecastStore'
import RoleCollection from '../State/Collections/RoleCollection'
import MonthlyExpenseCellCollection from '../State/Collections/MonthlyExpenseCellCollection'
import ExpenseRowCollection from '../State/Collections/ExpenseRowCollection'

export const formatMonth = (month) => {
    return typeof month === 'string' ? month : format(month, 'yyyy-MM')
}
export const getIsPastMonth = (month) => {
    return formatMonth(month) < format(new Date(), 'yyyy-MM')
}
export const getIsFutureMonth = (month) => {
    return formatMonth(month) > format(new Date(), 'yyyy-MM')
}
export const getIsCurrentMonth = (month) => {
    return formatMonth(month) === format(new Date(), 'yyyy-MM')
}
export const getAvailableHoursInDateRange = ({ dateRange, staff, role }) => {
    const startDate = dateRange[0]
    const endDate = dateRange[1]
    const allStaff = staff
        ? [staff]
        : role
        ? role.staff.filter((s) => !s.deletedAt)
        : StaffCollection.staffByRoleId[null].filter((s) => !s.deletedAt) || []
    return (
        _.sum(
            allStaff.map(
                (s) =>
                    s.getAvailabilityInDateRange([startDate, endDate]) / 60 / 5
            )
        ) * differenceInBusinessDays(endDate, startDate)
    )
}

export const getResourceCells = ({ matchers = {} }) => {
    const matchingCells = [
        'status' in matchers &&
            (MonthlyResourceCellCollection.cellsByStatus[
                matchers.status || null
            ] ||
                []),
        'projectId' in matchers &&
            (MonthlyResourceCellCollection.cellsByProjectId[
                matchers.projectId || null
            ] ||
                []),
        'phaseId' in matchers &&
            (MonthlyResourceCellCollection.cellsByPhaseId[
                matchers.phaseId || null
            ] ||
                []),
        'staffId' in matchers &&
            (MonthlyResourceCellCollection.cellsByStaffId[
                matchers.staffId || null
            ] ||
                []),
        'roleId' in matchers &&
            (MonthlyResourceCellCollection.cellsByRoleId[
                matchers.roleId || null
            ] ||
                []),
    ].filter((c) => c)
    return _.intersection(...matchingCells)
}

export const getActualResourceCellsInMonth = ({ month, matchers = {} }) => {
    return getResourceCells({ matchers }).filter(
        (c) => c.month === formatMonth(month) && c.type === 'actual'
    )
}

export const getProjectedResourceCellsInMonth = ({ month, matchers = {} }) => {
    return getResourceCells({ matchers }).filter(
        (c) => c.month === formatMonth(month) && c.type === 'projected'
    )
}

export const getResourceCellsInMonth = ({
    month,
    matchers = {},
    hoursData = 'actualsProjected',
}) => {
    const monthDate = startOfMonth(parse(month, 'yyyy-MM', new Date()))
    const todayMonth = startOfMonth(new Date())
    if (
        hoursData === 'projected' ||
        (hoursData === 'actualsProjected' && monthDate > todayMonth)
    ) {
        return getProjectedResourceCellsInMonth({ month, matchers })
    } else if (
        hoursData === 'actual' ||
        (hoursData === 'actualsProjected' && monthDate < todayMonth)
    ) {
        return getActualResourceCellsInMonth({ month, matchers })
    } else {
        const projectedCells = getProjectedResourceCellsInMonth({
            month,
            matchers,
        })
        const actualCells = getActualResourceCellsInMonth({ month, matchers })
        const projectedHours = _.sum(projectedCells.map((c) => c.hours))
        const actualHours = _.sum(actualCells.map((c) => c.hours))
        return actualHours > projectedHours ? actualCells : projectedCells
    }
}

export const getHoursInMonth = ({
    month,
    matchers = {},
    hoursData = 'actualsProjected',
}) => {
    const cells = getResourceCellsInMonth({
        month,
        matchers,
        hoursData,
    })
    return _.sum(cells.map((c) => c.hours)) || 0
}

export const getHoursBeforeMonth = ({
    month,
    matchers = {},
    hoursData = 'actualsProjected',
}) => {
    const formattedMonth = formatMonth(month)
    const monthsWithHours = _.uniq(
        getResourceCells({ matchers })
            .map((rt) => rt.month)
            .filter((m) => m < formattedMonth)
    )
    return (
        _.sum(
            monthsWithHours.map((m) =>
                getHoursInMonth({
                    month: m,
                    matchers,
                    hoursData,
                })
            )
        ) || 0
    )
}

export const getCostInMonth = ({
    month,
    matchers = {},
    hoursData = 'actualsProjected',
}) => {
    const cells = getResourceCellsInMonth({
        month,
        matchers,
        hoursData,
    })
    return _.sum(cells.map((c) => c.cost)) || 0
}

export const getCostBeforeMonth = ({
    month,
    matchers = {},
    hoursData = 'actualsProjected',
}) => {
    const formattedMonth = formatMonth(month)
    const monthsWithCost = _.uniq(
        getResourceCells({ matchers })
            .map((rt) => rt.month)
            .filter((m) => m < formattedMonth)
    )
    return (
        _.sum(
            monthsWithCost.map((m) =>
                getCostInMonth({
                    month: m,
                    matchers,
                    hoursData,
                })
            )
        ) || 0
    )
}

export const setHoursInMonth = ({
    hours,
    month,
    matchers = {},
    updateRevenue = false,
}) => {
    const projectedCells = getResourceCellsInMonth({
        month,
        matchers,
        hoursData: 'projected',
    })
    const actualCells = getResourceCellsInMonth({
        month,
        matchers,
        hoursData: 'actual',
    })
    const previousHours = _.sum(projectedCells.map((da) => da.hours))
    const actualHours = _.sum(actualCells.map((da) => da.hours))
    if (hours <= actualHours) hours = actualHours
    hours = Math.max(hours, 0)
    if (previousHours === hours || !isFinite(hours)) return
    if (!previousHours) {
        const childResourceRows = getChildResourceRows({ matchers })
        childResourceRows.forEach((budget) => {
            const newCell = {
                id: makeResourceCellId({
                    month: format(month, 'yyyy-MM'),
                    status: budget.status,
                    projectId: budget.projectId,
                    phaseId: budget.phaseId,
                    roleId: budget.staff?.roleId || budget.staffRoleId,
                    staffId: budget.staffId,
                    type: 'projected',
                }),
                month: format(month, 'yyyy-MM'),
                hours: hours / childResourceRows.length,
                status: budget.status,
                projectId: budget.projectId,
                phaseId: budget.phaseId,
                staffRoleId: budget.staff?.roleId || budget.staffRoleId,
                staffId: budget.staffId,
                type: 'projected',
            }
            const cell = MonthlyResourceCellCollection.addCell(newCell, {
                trackUpdates: true,
            })
            cell.update({
                cost: cell.hours * cell.costRate,
                chargeOut: cell.hours * cell.chargeOutRate,
            })
            if (updateRevenue) {
                setFeeProgressFromHoursBudgetProgress({
                    month,
                    matchers: {
                        status: budget.status,
                        projectId: budget.projectId,
                        phaseId: budget.phaseId,
                    },
                })
            }
        })
    } else {
        const ratio = hours / previousHours
        projectedCells.forEach((da) => {
            da.update({
                hours: da.hours * ratio,
                cost: da.cost * ratio,
                chargeOut: da.chargeOut * ratio,
            })
            if (updateRevenue) {
                setFeeProgressFromHoursBudgetProgress({
                    month,
                    matchers: {
                        status: da.status,
                        projectId: da.projectId,
                        phaseId: da.phaseId,
                    },
                })
            }
        })
    }
}

export const setCostInMonth = ({
    cost,
    month,
    matchers = {},
    updateRevenue,
}) => {
    const projectedCells = getResourceCellsInMonth({
        month,
        matchers,
        hoursData: 'projected',
    })
    const actualCells = getResourceCellsInMonth({
        month,
        matchers,
        hoursData: 'actual',
    })
    const previousCost = _.sum(projectedCells.map((da) => da.cost))
    const actualCost = _.sum(actualCells.map((da) => da.cost))
    if (cost <= actualCost) cost = actualCost
    cost = Math.max(cost, 0)
    if (previousCost === cost || !isFinite(cost)) return
    if (!previousCost) {
        const childResourceRows = getChildResourceRows({ matchers })
        childResourceRows.forEach((budget) => {
            const newCell = {
                id: makeResourceCellId({
                    month: format(month, 'yyyy-MM'),
                    status: budget.status,
                    projectId: budget.projectId,
                    phaseId: budget.phaseId,
                    roleId: budget.staff?.roleId || budget.staffRoleId || null,
                    staffId: budget.staffId,
                    type: 'projected',
                }),
                month: format(month, 'yyyy-MM'),
                cost: cost / childResourceRows.length,
                status: budget.status,
                projectId: budget.projectId,
                phaseId: budget.phaseId,
                staffRoleId: budget.staff?.roleId || budget.staffRoleId || null,
                staffId: budget.staffId,
                type: 'projected',
            }
            const cell = MonthlyResourceCellCollection.addCell(newCell, {
                trackUpdates: true,
            })
            cell.update({
                hours: cell.cost / cell.costRate,
                chargeOut: (cell.cost / cell.costRate) * cell.chargeOutRate,
            })
            if (updateRevenue) {
                setFeeProgressFromExpenseBudgetProgress({
                    month,
                    matchers: {
                        status: budget.status,
                        projectId: budget.projectId,
                        phaseId: budget.phaseId,
                    },
                })
            }
        })
    } else {
        const ratio = cost / previousCost
        projectedCells.forEach((da) => {
            da.update({
                hours: da.hours * ratio,
                cost: da.cost * ratio,
                chargeOut: da.chargeOut * ratio,
            })
            if (updateRevenue) {
                setFeeProgressFromExpenseBudgetProgress({
                    month,
                    matchers: {
                        status: da.status,
                        projectId: da.projectId,
                        phaseId: da.phaseId,
                    },
                })
            }
        })
    }
}

export const getChildResourceRows = ({ matchers }) => {
    const matchingRows = [ResourceRowCollection.leafRows]
    if ('projectId' in matchers) {
        matchingRows.push(
            ResourceRowCollection.rowsByProjectId[matchers.projectId || null] ||
                []
        )
    }
    if ('phaseId' in matchers) {
        matchingRows.push(
            ResourceRowCollection.rowsByPhaseId[matchers.phaseId || null] || []
        )
    }
    if ('staffId' in matchers) {
        matchingRows.push(
            ResourceRowCollection.rowsByStaffId[matchers.staffId || null] || []
        )
    }
    if ('roleId' in matchers) {
        matchingRows.push(
            ResourceRowCollection.rowsByRoleId[matchers.roleId || null] || []
        )
    }
    if ('status' in matchers) {
        matchingRows.push(
            ResourceRowCollection.rowsByStatus[matchers.status || null] || []
        )
    }
    return _.intersection(...matchingRows)
}

export const getRevenueCells = ({ matchers = {} }) => {
    const matchingCells = [
        'status' in matchers &&
            (MonthlyRevenueCellCollection.cellsByStatus[
                matchers.status || null
            ] ||
                []),
        'projectId' in matchers &&
            (MonthlyRevenueCellCollection.cellsByProjectId[
                matchers.projectId || null
            ] ||
                []),
        'phaseId' in matchers &&
            (MonthlyRevenueCellCollection.cellsByPhaseId[
                matchers.phaseId || null
            ] ||
                []),
    ].filter((c) => c)
    return _.intersection(...matchingCells)
}

export const getActualRevenueCellsInMonth = ({ month, matchers = {} }) => {
    return getRevenueCells({ matchers }).filter(
        (c) => c.month === formatMonth(month) && c.type === 'actual'
    )
}

export const getProjectedRevenueCellsInMonth = ({ month, matchers = {} }) => {
    return getRevenueCells({ matchers }).filter(
        (c) => c.month === formatMonth(month) && c.type === 'projected'
    )
}

export const getRevenueCellsInMonth = ({
    month,
    matchers = {},
    revenueData = 'actualsProjected',
}) => {
    const monthDate = startOfMonth(parse(month, 'yyyy-MM', new Date()))
    const todayMonth = startOfMonth(new Date())
    if (
        revenueData === 'projected' ||
        (revenueData === 'actualsProjected' && monthDate > todayMonth)
    ) {
        return getProjectedRevenueCellsInMonth({ month, matchers })
    } else if (
        revenueData === 'actual' ||
        (revenueData === 'actualsProjected' && monthDate < todayMonth)
    ) {
        return getActualRevenueCellsInMonth({ month, matchers })
    } else {
        const projectedCells = getProjectedRevenueCellsInMonth({
            month,
            matchers,
        })
        const actualCells = getActualRevenueCellsInMonth({ month, matchers })
        const projectedRevenue = _.sum(projectedCells.map((c) => c.revenue))
        const actualRevenue = _.sum(actualCells.map((c) => c.revenue))
        return actualRevenue > projectedRevenue ? actualCells : projectedCells
    }
}

export const getRevenueInMonth = ({
    month,
    matchers = {},
    revenueData = 'actualsProjected',
}) => {
    const cells = getRevenueCellsInMonth({
        month,
        matchers,
        revenueData,
    })
    return _.sum(cells.map((c) => c.revenue)) || 0
}

export const getRevenueBeforeMonth = ({
    month,
    matchers = {},
    revenueData = 'actualsProjected',
}) => {
    const formattedMonth = formatMonth(month)
    const monthsWithRevenue = _.uniq(
        getRevenueCells({ matchers })
            .map((rt) => rt.month)
            .filter((m) => m < formattedMonth)
    )
    return (
        _.sum(
            monthsWithRevenue.map((m) =>
                getRevenueInMonth({
                    month: m,
                    matchers,
                    revenueData,
                })
            )
        ) || 0
    )
}

export const setRevenueInMonth = ({
    revenue,
    month,
    matchers = {},
    updateHours = false,
    updateCost = false,
}) => {
    const projectedCells = getRevenueCellsInMonth({
        month,
        matchers,
        revenueData: 'projected',
    })
    const actualCells = getRevenueCellsInMonth({
        month,
        matchers,
        revenueData: 'actual',
    })
    const previousRevenue = _.sum(projectedCells.map((da) => da.revenue))
    const actualRevenue = _.sum(actualCells.map((da) => da.revenue))
    if (revenue <= actualRevenue) revenue = actualRevenue
    revenue = Math.max(revenue, 0)
    if (previousRevenue === revenue || !isFinite(revenue)) return
    if (!previousRevenue) {
        const childRevenueRows = getChildRevenueRows({ matchers })
        childRevenueRows.forEach((budget) => {
            const newCell = {
                id: makeRevenueCellId({
                    month: format(month, 'yyyy-MM'),
                    status: budget.status,
                    projectId: budget.projectId,
                    phaseId: budget.phaseId,
                    type: 'projected',
                }),
                month: format(month, 'yyyy-MM'),
                revenue: revenue / childRevenueRows.length,
                status: budget.status,
                projectId: budget.projectId,
                phaseId: budget.phaseId,
                type: 'projected',
            }
            const cell = MonthlyRevenueCellCollection.addCell(newCell, {
                trackUpdates: true,
            })
            if (updateHours) {
                setHoursBudgetProgressFromFeeProgress({
                    month,
                    matchers: {
                        status: budget.status,
                        projectId: budget.projectId,
                        phaseId: budget.phaseId,
                    },
                })
            } else if (updateCost) {
                setExpenseBudgetProgressFromFeeProgress({
                    month,
                    matchers: {
                        status: budget.status,
                        projectId: budget.projectId,
                        phaseId: budget.phaseId,
                    },
                })
            }
        })
    } else {
        const ratio = revenue / previousRevenue
        projectedCells.forEach((da) => {
            da.update({ revenue: da.revenue * ratio })
            if (updateHours) {
                setHoursBudgetProgressFromFeeProgress({
                    month,
                    matchers: {
                        status: da.status,
                        projectId: da.projectId,
                        phaseId: da.phaseId,
                    },
                })
            } else if (updateCost) {
                setExpenseBudgetProgressFromFeeProgress({
                    month,
                    matchers: {
                        status: da.status,
                        projectId: da.projectId,
                        phaseId: da.phaseId,
                    },
                })
            }
        })
    }
}

export const getChildRevenueRows = ({ matchers }) => {
    const matchingRows = [RevenueRowCollection.leafRows]
    if ('projectId' in matchers) {
        matchingRows.push(
            RevenueRowCollection.rowsByProjectId[matchers.projectId || null] ||
                []
        )
    }
    if ('phaseId' in matchers) {
        matchingRows.push(
            RevenueRowCollection.rowsByPhaseId[matchers.phaseId || null] || []
        )
    }
    if ('status' in matchers) {
        matchingRows.push(
            RevenueRowCollection.rowsByStatus[matchers.status || null] || []
        )
    }
    return _.intersection(...matchingRows)
}
export const setRoleHoursInMonth = ({
    hours,
    month,
    matchers = {},
    updateRevenue = false,
}) => {
    const role = RoleCollection.rolesById[matchers.roleId]
    const staff = role.staff.filter((s) => !s.deletedAt)
    const existingHours = getHoursInMonth({
        month,
        matchers,
    })
    const diffHours = hours - existingHours
    // const hoursPerStaff = hours / staff.length
    const existingStaffHours = {}
    staff.forEach((s) => {
        existingStaffHours[s.id] = getHoursInMonth({
            month,
            matchers: { ...matchers, staffId: s.id },
        })
    })
    let remainingHours = diffHours
    if (diffHours > 0) {
        // maybe make percentage utilisation
        const existingStaffHourValues = Object.values(existingStaffHours).sort(
            (a, b) => a - b
        )
        existingStaffHourValues.forEach((sh) => {
            const staffWithHoursLessThan = staff.filter(
                (s) => existingStaffHours[s.id] <= sh
            )
            if (staffWithHoursLessThan.length && remainingHours > 0) {
                const hoursPerStaff =
                    remainingHours / staffWithHoursLessThan.length
                staffWithHoursLessThan.forEach((s) => {
                    setStaffHoursInMonth({
                        hours: existingStaffHours[s.id] + hoursPerStaff,
                        month,
                        matchers: { ...matchers, staffId: s.id },
                        updateRevenue,
                    })
                })
                remainingHours -= hoursPerStaff
            }
        })
        if (remainingHours > 0) {
            staff.forEach((s) => {
                setStaffHoursInMonth({
                    hours:
                        existingStaffHours[s.id] +
                        remainingHours / staff.length,
                    month,
                    matchers: { ...matchers, staffId: s.id },
                    updateRevenue,
                })
            })
        }
    } else {
        const existingStaffHourValues = Object.values(existingStaffHours).sort(
            (a, b) => b - a
        )
        existingStaffHourValues.forEach((sh) => {
            const staffWithHoursGreaterThan = staff.filter(
                (s) => existingStaffHours[s.id] >= sh
            )
            if (staffWithHoursGreaterThan.length && remainingHours < 0) {
                const hoursPerStaff =
                    remainingHours / staffWithHoursGreaterThan.length
                staffWithHoursGreaterThan.forEach((s) => {
                    setStaffHoursInMonth({
                        hours: existingStaffHours[s.id] + hoursPerStaff,
                        month,
                        matchers: { ...matchers, staffId: s.id },
                    })
                })
                remainingHours -= hoursPerStaff
            }
        })
        if (remainingHours < 0) {
            staff.forEach((s) => {
                setStaffHoursInMonth({
                    hours:
                        existingStaffHours[s.id] +
                        remainingHours / staff.length,
                    month,
                    matchers: { ...matchers, staffId: s.id },
                    updateRevenue,
                })
            })
        }
    }
}

export const setStaffHoursInMonth = ({
    hours,
    month,
    matchers = {},
    updateRevenue = false,
}) => {
    setHoursInMonth({
        hours,
        month,
        matchers,
        updateRevenue,
    })
}

export const setProjectHoursInMonth = ({
    hours,
    month,
    matchers = {},
    updateRevenue = false,
}) => {
    setHoursInMonth({
        hours,
        month,
        matchers,
        updateRevenue,
    })
}

export const setPhaseHoursInMonth = ({
    hours,
    month,
    matchers = {},
    updateRevenue = false,
}) => {
    setHoursInMonth({
        hours,
        month,
        matchers,
        updateRevenue,
    })
}

export const setFeeProgressFromHoursBudgetProgress = ({
    month,
    matchers = {},
}) => {
    waitToFetch({
        fetchFuncs: [fetchRevenueCellsInMonth, fetchRevenueRows],
        month,
        matchers,
    }).then((res) => {
        return setFeeProgressInMonth({
            month,
            progress: getHoursBudgetProgressInMonth({ month, matchers }),
            matchers,
        })
    })
}

export const setFeeProgressFromExpenseBudgetProgress = ({
    month,
    matchers = {},
}) => {
    waitToFetch({
        fetchFuncs: [fetchRevenueCellsInMonth, fetchRevenueRows],
        month,
        matchers,
    }).then((res) => {
        return setFeeProgressInMonth({
            month,
            progress: getExpenseBudgetProgressInMonth({ month, matchers }),
            matchers,
        })
    })
}

export const setHoursBudgetProgressFromFeeProgress = ({
    month,
    matchers = {},
}) => {
    waitToFetch({
        fetchFuncs: [fetchResourceCellsInMonth, fetchResourceRows],
        month,
        matchers,
    }).then((res) => {
        return setHoursBudgetProgressInMonth({
            month,
            progress: getFeeProgressInMonth({ month, matchers }),
            matchers,
        })
    })
}

export const setExpenseBudgetProgressFromFeeProgress = ({
    month,
    matchers = {},
}) => {
    waitToFetch({
        fetchFuncs: [fetchResourceCellsInMonth, fetchResourceRows],
        month,
        matchers,
    }).then((res) => {
        return setExpenseBudgetProgressInMonth({
            month,
            progress: getFeeProgressInMonth({ month, matchers }),
            matchers,
        })
    })
}

export const getHoursBudgetProgressInMonth = ({ month, matchers = {} }) => {
    const hours = getHoursInMonth({ month, matchers })
    const revenueRows = getChildRevenueRows({ matchers })
    const hoursBudget = _.sum(revenueRows.map((rr) => rr.hoursBudget))
    return hours / hoursBudget
}

export const getExpenseBudgetProgressInMonth = ({ month, matchers = {} }) => {
    const cost = getCostInMonth({ month, matchers })
    const revenueRows = getChildRevenueRows({ matchers })
    const expenseBudget = _.sum(revenueRows.map((rr) => rr.expenseBudget))
    return cost / expenseBudget
}

export const getFeeProgressInMonth = ({ month, matchers = {} }) => {
    const revenue = getRevenueInMonth({ month, matchers })
    const revenueRows = getChildRevenueRows({ matchers })
    const fee = _.sum(revenueRows.map((rr) => rr.fee))
    return revenue / fee
}

export const setHoursBudgetProgressInMonth = ({
    month,
    progress,
    matchers = {},
    updateRevenue = false,
}) => {
    const revenueRows = getChildRevenueRows({ matchers })
    const hoursBudget = _.sum(revenueRows.map((rr) => rr.hoursBudget))
    if (!hoursBudget && !isFinite(progress)) return
    return setHoursInMonth({
        month,
        matchers,
        hours: hoursBudget * progress,
        updateRevenue,
    })
}

export const setExpenseBudgetProgressInMonth = ({
    month,
    progress,
    matchers = {},
    updateRevenue = false,
}) => {
    const revenueRows = getChildRevenueRows({ matchers })
    const expenseBudget = _.sum(revenueRows.map((rr) => rr.expenseBudget))
    if (!expenseBudget && !isFinite(progress)) return
    return setCostInMonth({
        month,
        matchers,
        cost: expenseBudget * progress,
        updateRevenue,
    })
}

export const setFeeProgressInMonth = ({
    month,
    progress,
    matchers = {},
    updateHours = false,
    updateCost = false,
}) => {
    const revenueRows = getChildRevenueRows({ matchers })
    const fee = _.sum(revenueRows.map((rr) => rr.fee))
    if (!fee && !isFinite(progress)) return
    return setRevenueInMonth({
        month,
        matchers,
        revenue: fee * progress,
        updateHours,
        updateCost,
    })
}

export const waitToFetch = async ({ fetchFuncs, month, matchers = {} }) => {
    const fetches = fetchFuncs.map((f) => f({ month, matchers }))
    return await Promise.all(fetches)
}

export const fetchResourceCellsInMonth = async ({ month }) => {
    const query = {
        id: 'rc' + format(month, 'yyyy-MM'),
        collection: 'monthlyResourceCells',
        data: {
            dateRange: [qf(startOfMonth(month)), qf(endOfMonth(month))],
            period: 'monthly',
            hoursData: ResourceScheduleStore.report.filters.hoursData,
            futureRows: ResourceScheduleStore.report.filters.futureRows,
        },
    }
    return await queryClient.fetchQuery({
        queryKey: [query.id],
        queryFn: async () => fetchData(query),
    })
}

export const fetchRevenueCellsInMonth = async ({ month }) => {
    const query = {
        id: 'rv' + format(month, 'yyyy-MM'),
        collection: 'monthlyRevenueCells',
        data: {
            groupBy: ['project', 'phase', 'status'],
            dateRange: [qf(startOfMonth(month)), qf(endOfMonth(month))],
            period: 'monthly',
            revenueData: RevenueForecastStore.report.filters.revenueData,
            invoiceData: RevenueForecastStore.report.filters.invoiceData,
        },
    }
    return await queryClient.fetchQuery({
        queryKey: [query.id],
        queryFn: async () => fetchData(query),
    })
}

export const fetchResourceRows = async ({ matchers = {} }) => {
    const query = ResourceScheduleStore.getChildRowQueryOld({
        ...matchers,
        filterDates: false,
    })
    return await queryClient.fetchQuery({
        queryKey: [query.id],
        queryFn: async () => fetchData(query),
    })
}

export const fetchRevenueRows = async ({ matchers = {} }) => {
    const query = RevenueForecastStore.getChildRowQuery(matchers)
    const res = await queryClient.fetchQuery({
        queryKey: [query.id],
        queryFn: async () => fetchData(query),
    })
    return res
}

export const makeResourceCellId = ({
    month,
    status,
    projectId,
    phaseId,
    roleId,
    staffId,
    type,
}) => {
    return `('${status}', '${roleId}', '${staffId}', '${projectId}', '${phaseId}')_${month}_${type}`
}

export const makeRevenueCellId = ({
    month,
    status,
    projectId,
    phaseId,
    type,
}) => {
    return `('${status}', '${projectId}', '${phaseId}')_${month}_${type}`
}

export const getChildExpenseRows = ({ matchers }) => {
    const matchingRows = [ExpenseRowCollection.leafRows]
    if ('projectId' in matchers) {
        matchingRows.push(
            ExpenseRowCollection.rowsByProjectId[matchers.projectId || null] ||
                []
        )
    }
    if ('phaseId' in matchers) {
        matchingRows.push(
            ExpenseRowCollection.rowsByPhaseId[matchers.phaseId || null] || []
        )
    }
    if ('status' in matchers) {
        matchingRows.push(
            ExpenseRowCollection.rowsByStatus[matchers.status || null] || []
        )
    }
    if ('expenseName' in matchers) {
        matchingRows.push(
            ExpenseRowCollection.rowsByExpenseName[
                matchers.expenseName || null
            ] || []
        )
    }
    return _.intersection(...matchingRows)
}

export const makeExpenseCellId = ({
    month,
    status,
    projectId,
    phaseId,
    expenseName,
    type,
}) => {
    return `('${status}', '${projectId}', '${phaseId}', '${expenseName}')_${month}_${type}`
}

export const getExpenseCells = ({ matchers = {} }) => {
    const matchingCells = [
        'status' in matchers &&
            (MonthlyExpenseCellCollection.cellsByStatus[
                matchers.status || null
            ] ||
                []),
        'projectId' in matchers &&
            (MonthlyExpenseCellCollection.cellsByProjectId[
                matchers.projectId || null
            ] ||
                []),
        'phaseId' in matchers &&
            (MonthlyExpenseCellCollection.cellsByPhaseId[
                matchers.phaseId || null
            ] ||
                []),
        'expenseName' in matchers &&
            (MonthlyExpenseCellCollection.cellsByExpenseName[
                matchers.expenseName || null
            ] ||
                []),
    ].filter((c) => c)
    return _.intersection(...matchingCells)
}

export const getActualExpenseCellsInMonth = ({ month, matchers = {} }) => {
    return getExpenseCells({ matchers }).filter(
        (c) => c.month === formatMonth(month) // && c.type === 'actual'
    )
}

export const getProjectedExpenseCellsInMonth = ({ month, matchers = {} }) => {
    return getExpenseCells({ matchers }).filter(
        (c) => c.month === formatMonth(month) // && c.type === 'projected'
    )
}

export const getExpenseCellsInMonth = ({
    month,
    matchers = {},
    expenseData = 'actualsProjected',
}) => {
    const monthDate = startOfMonth(parse(month, 'yyyy-MM', new Date()))
    const todayMonth = startOfMonth(new Date())
    if (
        expenseData === 'projected' ||
        (expenseData === 'actualsProjected' && monthDate > todayMonth)
    ) {
        return getProjectedExpenseCellsInMonth({ month, matchers })
    } else if (
        expenseData === 'actual' ||
        (expenseData === 'actualsProjected' && monthDate < todayMonth)
    ) {
        return getActualExpenseCellsInMonth({ month, matchers })
    } else {
        const projectedCells = getProjectedExpenseCellsInMonth({
            month,
            matchers,
        })
        const actualCells = getActualExpenseCellsInMonth({ month, matchers })
        const projectedExpense = _.sum(projectedCells.map((c) => c.expense))
        const actualExpense = _.sum(actualCells.map((c) => c.expense))
        return actualExpense > projectedExpense ? actualCells : projectedCells
    }
}

export const getExpenseInMonth = ({
    month,
    matchers = {},
    expenseData = 'actualsProjected',
}) => {
    const cells = getExpenseCellsInMonth({
        month,
        matchers,
        expenseData,
    })
    return _.sum(cells.map((c) => c.expense)) || 0
}

export const getExpenseBeforeMonth = ({
    month,
    matchers = {},
    expenseData = 'actualsProjected',
}) => {
    const formattedMonth = formatMonth(month)
    const monthsWithExpense = _.uniq(
        getExpenseCells({ matchers })
            .map((rt) => rt.month)
            .filter((m) => m < formattedMonth)
    )
    return (
        _.sum(
            monthsWithExpense.map((m) =>
                getExpenseInMonth({
                    month: m,
                    matchers,
                    expenseData,
                })
            )
        ) || 0
    )
}

export const setExpenseInMonth = ({ expense, month, matchers = {} }) => {
    const projectedCells = getExpenseCellsInMonth({
        month,
        matchers,
        expenseData: 'projected',
    })
    const actualCells = getExpenseCellsInMonth({
        month,
        matchers,
        expenseData: 'actual',
    })
    const previousExpense = _.sum(projectedCells.map((da) => da.expense))
    const actualExpense = _.sum(actualCells.map((da) => da.expense))
    if (expense <= actualExpense) expense = actualExpense
    expense = Math.max(expense, 0)
    if (previousExpense === expense || !isFinite(expense)) return
    if (!previousExpense) {
        const childExpenseRows = getChildExpenseRows({ matchers })
        childExpenseRows.forEach((budget) => {
            const newCell = {
                id: makeExpenseCellId({
                    month: format(month, 'yyyy-MM'),
                    status: budget.status,
                    projectId: budget.projectId,
                    phaseId: budget.phaseId,
                    type: 'projected',
                }),
                month: format(month, 'yyyy-MM'),
                expense: expense / childExpenseRows.length,
                status: budget.status,
                projectId: budget.projectId,
                phaseId: budget.phaseId,
                type: 'projected',
            }
            const cell = MonthlyExpenseCellCollection.addCell(newCell, {
                trackUpdates: true,
            })
        })
    } else {
        const ratio = expense / previousExpense
        projectedCells.forEach((da) => {
            da.update({ expense: da.expense * ratio })
        })
    }
}
