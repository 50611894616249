import { format } from 'date-fns'
import pluralize from 'pluralize'
import _ from 'lodash'
import { duration } from 'moment'
import { durationToUnit } from '@ryki/datemath'
import { statusByPriority, statusPriorities } from '../Utils/statusPriority'

export default {
    // boolean: {
    //     sum: (values) => values.every((v) => v),
    //     avg: (values) => Math.round(_.mean(values)),
    //     max: (values) => _.max(values),
    //     min: (values) => _.min(values),
    // },
    // text: { sum: (values) => [...new Set(values.filter((v) => v))] },
    number: {
        sum: (values) => _.sum(values.filter((v) => isFinite(v))),
        avg: (values) => _.mean(values.filter((v) => isFinite(v))),
        max: (values) => _.max(values.filter((v) => isFinite(v))),
        min: (values) => _.min(values.filter((v) => isFinite(v))),
    },
    currency: {
        sum: (values) => _.sum(values.filter((v) => isFinite(v))),
        avg: (values) => _.mean(values.filter((v) => isFinite(v))),
        max: (values) => _.max(values.filter((v) => isFinite(v))),
        min: (values) => _.min(values.filter((v) => isFinite(v))),
    },
    percent: {
        sum: (values) => _.sum(values.filter((v) => isFinite(v))),
        avg: (values) => _.mean(values.filter((v) => isFinite(v))),
        max: (values) => _.max(values.filter((v) => isFinite(v))),
        min: (values) => _.min(values.filter((v) => isFinite(v))),
    },
    durationHours: {
        sum: (values) => _.sum(values.filter((v) => isFinite(v))),
        avg: (values) => _.mean(values.filter((v) => isFinite(v))),
        max: (values) => _.max(values.filter((v) => isFinite(v))),
        min: (values) => _.min(values.filter((v) => isFinite(v))),
    },
    date: {
        avg: (values) =>
            new Date(
                _.mean(
                    values
                        .filter((v) => v instanceof Date && !isNaN(v))
                        .map((v) => v.getTime())
                )
            ),
        max: (values) =>
            new Date(
                _.max(
                    values
                        .filter((v) => v instanceof Date && !isNaN(v))
                        .map((v) => v.getTime())
                )
            ),
        min: (values) =>
            new Date(
                _.min(
                    values
                        .filter((v) => v instanceof Date && !isNaN(v))
                        .map((v) => v.getTime())
                )
            ),
        same: (values) => {
            const firstValue = values[0]
            return values.every(
                (v) =>
                    v instanceof Date &&
                    !isNaN(v) &&
                    v.getTime() === firstValue.getTime()
            )
                ? firstValue
                : null
        },
    },
    duration: {
        sum: (values) => ({
            value: durationToUnit(
                _.sum(
                    values.map((v) => durationToUnit(v, 'milliseconds') || 0)
                ),
                values[0]?.unit
            ),
            unit: values[0]?.unit,
        }),
        avg: (values) => ({
            value: durationToUnit(
                _.mean(
                    values.map((v) => durationToUnit(v, 'milliseconds') || 0)
                ),
                values[0]?.unit
            ),
            unit: values[0]?.unit,
        }),
        max: (values) => ({
            value: durationToUnit(
                _.max(
                    values.map((v) => durationToUnit(v, 'milliseconds') || 0)
                ),
                values[0]?.unit
            ),
            unit: values[0]?.unit,
        }),
        min: (values) => ({
            value: durationToUnit(
                _.min(
                    values.map((v) => durationToUnit(v, 'milliseconds') || 0)
                ),
                values[0]?.unit
            ),
            unit: values[0]?.unit,
        }),
    },
    progress: {
        sum: (values) => {
            return {
                numerator: _.sum(
                    values.map((v) =>
                        isFinite(v?.numerator) ? v.numerator : 0
                    )
                ),
                denominator: _.sum(
                    values.map((v) =>
                        isFinite(v?.numerator) ? v.denominator : 0
                    )
                ),
            }
        },
        avg: (values) => ({
            numerator: _.avg(
                values.map((v) => (isFinite(v?.numerator) ? v.numerator : 0))
            ),
            denominator: _.avg(
                values.map((v) => (isFinite(v?.numerator) ? v.denominator : 0))
            ),
        }),
        //TODO - not correct
        // max: (values) => ({
        //     numerator: _.max(values.map((v) => v.numerator)),
        //     denominator: _.max(values.map((v) => v.denominator)),
        // }),
        // min: (values) => ({
        //     numerator: _.min(values.map((v) => v.numerator)),
        //     denominator: _.min(values.map((v) => v.denominator)),
        // }),
    },
    progressBar: {
        sum: (values) => {
            return {
                numerator: _.sum(
                    values.map((v) =>
                        isFinite(v?.numerator) ? v.numerator : 0
                    )
                ),
                denominator: _.sum(
                    values.map((v) =>
                        isFinite(v?.numerator) ? v.denominator : 0
                    )
                ),
            }
        },
        avg: (values) => ({
            numerator: _.avg(
                values.map((v) => (isFinite(v?.numerator) ? v.numerator : 0))
            ),
            denominator: _.avg(
                values.map((v) => (isFinite(v?.numerator) ? v.denominator : 0))
            ),
        }),
        //TODO - not correct
        // max: (values) => ({
        //     numerator: _.max(values.map((v) => v.numerator)),
        //     denominator: _.max(values.map((v) => v.denominator)),
        // }),
        // min: (values) => ({
        //     numerator: _.min(values.map((v) => v.numerator)),
        //     denominator: _.min(values.map((v) => v.denominator)),
        // }),
    },
    // project: { sum: (values) => [...new Set(values.filter((v) => v))] },
    // phase: { sum: (values) => [...new Set(values.filter((v) => v))] },
    // costCentre: { sum: (values) => [...new Set(values.filter((v) => v))] },
    // contact: { sum: (values) => [...new Set(values.filter((v) => v))] },
    // staff: { sum: (values) => [...new Set(values.filter((v) => v))] },
    // staffMember: { sum: (values) => [...new Set(values.filter((v) => v))] },
    // task: { sum: (values) => [...new Set(values.filter((v) => v))] },
    // role: { sum: (values) => [...new Set(values.filter((v) => v))] },
    status: {
        max: (values) => {
            const statusPriority = _.min(values.map((v) => statusPriorities[v]))
            return statusByPriority[statusPriority]
        },
    },
}
